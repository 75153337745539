/**
 * Enum for Shmdata roles
 * @readonly
 * @memberof enums
 * @enum {string}
 */
const ShmdataRoleEnum = Object.freeze({
  WRITER: 'writer',
  READER: 'reader',
  FOLLOWER: 'follower'
})

export default ShmdataRoleEnum

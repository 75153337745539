import { observable, action, computed, reaction, makeObservable } from 'mobx'

import InitStateEnum from '@models/common/InitStateEnum'
import MenuCollection from '@models/menus/MenuCollection'
import MenuItem from '@models/menus/MenuItem'

import ConfigStore from '@stores/common/ConfigStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import KindStore from '@stores/quiddity/KindStore'
import Store from '@stores/Store'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the QuiddityMenuStore
 * @memberof stores.QuiddityMenuStore
 */
export const LOG = logger.child({ store: 'QuiddityMenuStore' })

/**
 * @classdesc Stores the state of all quiddity menus
 *
 * It defines a `menu` and generates a `userMenu` from it:
 * the `menu` is built once whereas the `userMenu` is updated on all quiddity updates
 * @extends stores.Store
 * @memberof stores
 */
class QuiddityMenuStore extends Store {
  /** @property {Map<string, Function>} menuBindings - Function used to create specific quiddity with the menu */
  menuBindings = new Map()

  /**
   * @property {Map<string, string>} kindLabelBindings - Label bindings use to prettify quiddity kinds
   * It overrides and complements the menu labels (exemple: ndiInput that is defined by the Executor)
   */
  kindLabelBindings = new Map()

  /** @property {Set<string>} openedCollections - Set of all opened collections in the UI */
  openedCollections = new Set()

  /** @property {Set<string>} openedMenus - Set of all opened menus in the UI */
  openedMenus = new Set()

  /** @property {models.MenuCollection[]} menus - Menus defined by configuration or quiddity kinds */
  menus = []

  /** @property {Map<string, models.MenuCollection>} userMenus - All menus available to the user */
  userMenus = new Map()

  /** @property {string[]} menuIds - All IDs of the menu tree */
  get menuIds () {
    const ids = new Set()
    for (const menu of this.menus) {
      menu.ids.forEach(id => ids.add(id))
    }

    return ids
  }

  /** @property {Array<modules:models/menus.MenuCollection>} showableMenus - All the menus that are not `exclusive` or `hidden` */
  get showableMenus () {
    return this.menus.map(m => m.show()).filter(m => !!m)
  }

  /** @property {Map<string, string>} kindLabels - All menus labels hashed by quiddity kinds */
  get kindLabels () {
    const labels = new Map()

    for (const menu of this.menus) {
      if (menu instanceof MenuCollection) {
        menu.menuItems.forEach(m => labels.set(m.kindId, m.name))
      } else if (menu instanceof MenuItem) {
        labels.set(menu.kindId, menu.name)
      }
    }

    return new Map([...labels].concat([...this.kindLabelBindings]))
  }

  /**
   * Instantiates a new Store which manages all menus used to create quiddities
   * @param {stores.SocketStore} socketStore - Stores and manages the current event-driven socket
   * @param {stores.ConfigStore} configStore - Configuration manager
   * @param {stores.KindStore} kindStore - Stores all quiddity kinds
   * @param {stores.QuiddityStore} quiddityStore - Quiddity manager
   * @constructor
   */
  constructor (socketStore, configStore, kindStore, quiddityStore) {
    super(socketStore)

    makeObservable(this, {
      openedCollections: observable,
      openedMenus: observable,
      menus: observable,
      userMenus: observable,
      menuIds: computed,
      showableMenus: computed,
      kindLabels: computed,
      setMenus: action,
      addUserMenu: action,
      toggleCollection: action,
      toggleMenu: action,
      clearCollection: action,
      clearAllMenus: action,
      clear: action
    })

    if (configStore instanceof ConfigStore) {
      /** @property {module:stores/common.ConfigStore} configStore - Stores and manages the app's configuration */
      this.configStore = configStore
    } else {
      throw new Error('QuiddityMenuStore must be instantiated with a ConfigStore')
    }

    if (quiddityStore instanceof QuiddityStore) {
      /** @property {module:stores/quiddity.QuiddityStore} quiddityStore - Stores and manages all quiddities */
      this.quiddityStore = quiddityStore
    } else {
      throw new Error('QuiddityMenuStore must be instantiated with a QuiddityStore')
    }

    if (kindStore instanceof KindStore) {
      /** @property {module:stores/quiddity.KindStore} kindStore - Stores all quiddity kinds */
      this.kindStore = kindStore
    } else {
      throw new TypeError('QuiddityStore requires a KindStore')
    }

    reaction(
      () => this.quiddityStore.usedKinds,
      () => this.populateUserMenus()
    )

    reaction(
      () => this.quiddityStore.initState,
      state => this.handleQuiddityStoreInitialization(state)
    )
  }

  /**
   * Initializes the userMenu from configuration or from quiddity kinds
   * @returns {boolean} Flags true if store is well initialized
   */
  initialize () {
    const { INITIALIZING, INITIALIZED } = InitStateEnum
    const { configStore: { menuConfiguration } } = this

    if (!this.quiddityStore.isInitialized() || !this.isNotInitialized()) return false

    this.setInitState(INITIALIZING)

    // @todo [swIO] build menu from real kinds
    this.setMenus(MenuCollection.fromConfig(menuConfiguration))

    this.populateUserMenus()

    this.setInitState(INITIALIZED)

    LOG.info({
      msg: 'QuiddityMenuStore is initialized',
      menu: this.menus
    })

    return this.isInitialized()
  }

  /**
   * Initializes or clears the store when the quiddityStore initialization state changes
   * @param {models.InitStateEnum} initState - State of the quiddityStore's initialization
   */
  handleQuiddityStoreInitialization (initState) {
    const { INITIALIZED, NOT_INITIALIZED } = InitStateEnum

    if (initState === INITIALIZED) {
      this.initialize()
    } else if (initState === NOT_INITIALIZED) {
      this.clear()
    }
  }

  /**
   * Handles quiddity creation request from the quiddity menu
   * @param {string} kindId - Kind of the requested quiddity
   * @returns {Function} Quiddity creation async function
   */
  handleQuiddityRequest (kindId) {
    const { quiddityStore } = this

    return async () => {
      if (this.menuBindings.has(kindId)) {
        this.menuBindings.get(kindId)()
      } else {
        await quiddityStore.applyQuiddityCreation(kindId)
      }
    }
  }

  /** Populates the userMenu after each quiddity update */
  populateUserMenus () {
    this.updateMenus()

    for (const menu of this.showableMenus) {
      this.addUserMenu(menu)
    }
  }

  /** Updates the menu with the created quiddities */
  updateMenus () {
    const { usedKindIds } = this.quiddityStore

    for (const kindId of this.menuIds) {
      const isUsed = usedKindIds.has(kindId)

      this.menus.forEach(menu => {
        menu.updateItem(kindId, { created: isUsed })
      })
    }
  }

  /**
   * Add a new quiddity menu binding
   * @param {string} kindId - Quiddity kind ID to bind a function to
   * @param {Function} boundFunction - Function to call when creating associated quiddity kind
   */
  addMenuBinding (kindId, boundFunction) {
    this.menuBindings.set(kindId, boundFunction)

    LOG.info({
      msg: 'Added new quiddity menu binding',
      kind: kindId
    })
  }

  /**
   * Removes a quiddity menu binding
   * @param {string} kindId - The binded quiddity kind ID
   */
  removeMenuBinding (kindId) {
    this.menuBindings.delete(kindId)

    LOG.info({
      msg: 'Deleted quiddity menu binding',
      kind: kindId
    })
  }

  /**
   * Adds a label for a quiddity kind that is not defined in the menus
   * @param {string} kindId - ID of the quiddity kind
   * @param {string} label - Label used to display the kind
   */
  addKindLabelBindings (kindId, label) {
    this.kindLabelBindings.set(kindId, label)
  }

  /**
  * Makes the subtitle of the destination head
  * @param {models.MatrixEntry} entry - A matrix entry
  * @returns {string} Returns the subtitle of a destination head matrix entry
  */
  makeDestinationHeadSubtitle (entry) {
    let $subtitle

    if (entry.isContact) {
      $subtitle = entry.contact.uri
    } else {
      $subtitle = this.kindLabels.get(entry.kindId) || entry.kindId
    }

    return $subtitle
  }

  /**
   * Sets new quiddity menus by replacing all old menus
   * @param {Array.<(models.MenuCollection|models.MenuItem)>} menus - The current quiddity menu
   */
  setMenus (menus) {
    this.menus.replace(menus)
  }

  /**
   * Adds a new user menu in a MobX action
   * @param {(models.MenuCollection|models.MenuItem)} menu - A menu model
   */
  addUserMenu (menu) {
    this.userMenus.set(menu.name, menu)
  }

  /**
   * Toggle a collection to display or hide
   * A collection represents a collection of menus and sub-menus.
   * @param {string} collectionKey - Unique key of the collection
   */
  toggleCollection (collectionKey) {
    const isCollectionOpened = this.openedCollections.has(collectionKey)

    if (!isCollectionOpened) {
      this.openedCollections.clear()
      this.openedCollections.add(collectionKey)
    } else {
      this.openedCollections.delete(collectionKey)
    }

    this.openedMenus.clear()
  }

  /**
   * Toggle a menu or a sub-menu to display or hide
   * @param {string} menuKey - Unique key of the menu
   */
  toggleMenu (menuKey) {
    const isMenuOpened = this.openedMenus.has(menuKey)

    if (!isMenuOpened) {
      this.openedMenus.clear()
      this.openedMenus.add(menuKey)
    } else {
      this.openedMenus.delete(menuKey)
    }
  }

  /**
   * Clears a collection by closing it and all of its menus
   * @param {string} collectionKey - Unique key of the collection
   */
  clearCollection (collectionKey) {
    this.openedCollections.delete(collectionKey)
    this.openedMenus.clear()
  }

  /** Clears all collections and all menus */
  clearAllMenus () {
    this.openedMenus.clear()
    this.openedCollections.clear()
  }

  /** Cleans the user menu */
  clear () {
    const { NOT_INITIALIZED } = InitStateEnum
    this.setInitState(NOT_INITIALIZED)

    this.userMenus.clear()

    LOG.debug({
      msg: 'Successfully cleared user menu'
    })
  }
}

export default QuiddityMenuStore

import {
  NDI_SNIFFER_KIND_ID,
  RTMP_KIND_ID,
  SIP_KIND_ID,
  SYSTEM_USAGE_KIND,
  USER_TREE_KIND_ID
} from '@models/quiddity/specialQuiddities'

import { PREVIEW_KIND_ID } from '@stores/timelapse/PreviewStore'
import { THUMBNAIL_KIND_ID } from '@stores/timelapse/ThumbnailStore'

/**
 * Creates creation bindings for quiddity-related Stores and sets them inside the QuiddityStore
 * @todo [swIO] retry the special quiddities
 * @returns {Map<string, Function>} All bindings that create quiddities
 */
function populateStoreCreationBindings ({
  quiddityStore,
  ndiStore,
  systemUsageStore,
  sipStore,
  thumbnailStore,
  previewStore,
  userTreeStore
}) {
  return new Map([
    [NDI_SNIFFER_KIND_ID, async () => { await ndiStore.initialize() }],
    [SYSTEM_USAGE_KIND, async () => { await systemUsageStore.initialize() }],
    [SIP_KIND_ID, async () => { await sipStore.initialize() }],
    [THUMBNAIL_KIND_ID, async () => { await thumbnailStore.initialize() }],
    [PREVIEW_KIND_ID, async () => { await previewStore.initialize() }],
    [USER_TREE_KIND_ID, async () => { await userTreeStore.initialize() }]
  ])
}

/**
 * Creates creation bindings for modal-related Stores and sets them inside the QuiddityStore
 * @todo [swIO] retry the special quiddities
 * @returns {Map<string, Function>} All bindings that display contextual modals
 */
function populateModalCreationBindings ({ quiddityMenuStore, ndiStore, rtmpStore }) {
  return new Map([
    [NDI_SNIFFER_KIND_ID, () => ndiStore.setNdiInputRequestFlag(true)],
    [RTMP_KIND_ID, () => rtmpStore.setRtmpRequestFlag(true)]
  ])
}

export {
  populateStoreCreationBindings,
  populateModalCreationBindings
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import HelpWrapper from '@components/wrappers/HelpWrapper'

import { Inputs } from '@sat-mtl/ui-components'

const { Field, InputText, InputPassword } = Inputs

/**
 * Field component that lets users input a string
 * @memberof module:components/fields
 * @param {string} id - Unique ID of the field
 * @param {string} title - Title of the field
 * @param {string} value - Value of the field
 * @param {boolean} disabled - Flag a disabled value
 * @param {function} onChange - Function triggered when the property is changed by the user
 * @param {string} description - Description of the field
 * @param {string} status - Status of the form
 * @param {boolean} [isPassword=false] - Flags a password credential
 * @returns {external:ui-component/Inputs.Field} A field component
 */
function StringField ({ title, value, disabled, onChange, description, status, isPassword }) {
  const [editedValue, editValue] = useState(value)
  const { t } = useTranslation()

  const defaultLabel = t(
    'Input alphanumeric value for "{{title}}" property',
    { title: t(title) }
  )

  const inputProps = {
    status: status,
    disabled: disabled,
    value: editedValue,
    placeholder: t('Input a value'),
    onChange: e => editValue(e.target.value),
    onBlur: () => {
      if (value !== editedValue) {
        onChange(editedValue)
      }
    },
    onPressEnter: () => {
      if (value !== editedValue) {
        onChange(editedValue)
      }
    }
  }

  return (
    <div className='StringField'>
      <HelpWrapper message={description ? t(description) : defaultLabel}>
        <Field
          disabled={disabled}
          title={t(title)}
          status={status}
          description={description ? t(description) : defaultLabel}
        >
          {isPassword ? <InputPassword {...inputProps} /> : <InputText {...inputProps} />}
        </Field>
      </HelpWrapper>
    </div>
  )
}

export default StringField

import { observable, action, makeObservable } from 'mobx'
import PageEnum from '@models/common/PageEnum'

import Store from '@stores/Store'

/**
 * @classdesc Stores all pages
 * @extends stores.Store
 * @memberof stores
 */
class PageStore extends Store {
 /** @property {models.PageEnum} activePage - The currently active page */
 activePage = PageEnum.MATRIX

 constructor (socketStore) {
   super(socketStore)

   makeObservable(this, {
     activePage: observable,
     setActivePage: action
   })
 }

 /**
  * Set the new active page
  * @param {models.PageEnum} activePage - The active page
  */
 setActivePage (activePage) {
   this.activePage = activePage
 }
}

export default PageStore

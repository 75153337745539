import {
  NDI_OUTPUT_KIND_ID,
  RTMP_KIND_ID,
  SIP_KIND_ID
} from '@models/quiddity/specialQuiddities'

/**
 * Enum for all categories of the matrix
 * @readonly
 * @memberof models
 * @enum {string}
 */
const MatrixCategoryEnum = Object.freeze({
  COMMON: 'common',
  SIP: 'sip',
  NDI: 'ndi',
  RTMP: 'rtmp'
})

/**
 * Gets the corresponding quiddity kind from a matrix category
 * @param {module:models/matrix.MatrixCategoryEnum} matrixCategory - A matrix category
 * @returns {?string} A corresponding quiddity kind
 */
function toDestinationKind (matrixCategory) {
  switch (matrixCategory) {
    case MatrixCategoryEnum.SIP:
      return SIP_KIND_ID
    case MatrixCategoryEnum.NDI:
      return NDI_OUTPUT_KIND_ID
    case MatrixCategoryEnum.RTMP:
      return RTMP_KIND_ID
    default:
      return null
  }
}

/**
 * Gets the corresponding category from quiddity kind
 * @param {string} kindId - A quiddity kind ID
 * @returns {?string} A corresponding destination category
 */
function toMatrixCategory (kindId) {
  switch (kindId) {
    case SIP_KIND_ID:
      return MatrixCategoryEnum.SIP
    case NDI_OUTPUT_KIND_ID:
      return MatrixCategoryEnum.NDI
    case RTMP_KIND_ID:
      return MatrixCategoryEnum.RTMP
    default:
      return MatrixCategoryEnum.COMMON
  }
}

const specialMatrixCategories = Object.values(MatrixCategoryEnum)
  .filter(category => category === MatrixCategoryEnum.NDI || category === MatrixCategoryEnum.RTMP)

export default MatrixCategoryEnum
export { toDestinationKind, toMatrixCategory, specialMatrixCategories }

import SwitcherAPI from '@api/switcher/SwitcherAPI'
import SessionAPI from '@api/switcher/SessionAPI'

import QuiddityAPI from '@api/quiddity/QuiddityAPI'
import NicknameAPI from '@api/quiddity/NicknameAPI'
import PropertyAPI from '@api/quiddity/PropertyAPI'
import MethodAPI from '@api/quiddity/MethodAPI'

import UserTreeAPI from '@api/tree/UserTreeAPI'
import InfoTreeAPI from '@api/tree/InfoTreeAPI'
import ConnectionSpecsAPI from '@api/tree/ConnectionSpecsAPI'

import ImageAPI from '@api/image/ImageAPI'

/**
 * Creates all the app's APIs
 * @todo [swIO] rebind legacy apis
 * @returns {object} Object with all APIs used by Scenic
 */
function populateAPIs (socket) {
  return {
    switcherAPI: new SwitcherAPI(socket),
    methodAPI: new MethodAPI(socket),
    nicknameAPI: new NicknameAPI(socket),
    propertyAPI: new PropertyAPI(socket),
    quiddityAPI: new QuiddityAPI(socket),
    userTreeAPI: new UserTreeAPI(socket),
    infoTreeAPI: new InfoTreeAPI(socket),
    connectionSpecsAPI: new ConnectionSpecsAPI(socket),
    sessionAPI: new SessionAPI(socket),
    imageAPI: new ImageAPI(socket)
  }
}

export default populateAPIs

import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { AppStoresContext } from '@components/App'

import { LANGUAGE_SETTING_ID, DISPLAY_THUMBNAILS_ID, DISPLAY_ENCODERS_ID } from '@stores/common/SettingStore'
import SelectionField from '@components/fields/SelectionField'
import BooleanField from '@components/fields/BooleanField'

/**
 * The language select input
 * @memberof module:components/fields
 * @returns {module:components/fields.SelectionField} A selection field
 */
const LanguageSelectField = observer(() => {
  const { settingStore } = useContext(AppStoresContext)

  return (
    <SelectionField
      title='Language'
      description="Choose the app's display language"
      option={settingStore.userLanguageOption}
      options={settingStore.userLanguageOptions}
      onChange={setting => {
        settingStore.setSetting(LANGUAGE_SETTING_ID, setting)
      }}
    />
  )
})

/**
 * The thumbnail display field
 * @memberof module:components/fields
 * @property {boolean} isDisplayThumbnails - Flags if thumbnail is displayed or not
 * @property {function} onToggle - Function triggered when the user clicks the switch
 * @returns {module:components/fields.BooleanField} A boolean field
 */
const DisplayThumbnailsField = observer(() => {
  const { settingStore } = useContext(AppStoresContext)
  const isDisplayThumbnails = settingStore.displayThumbnails

  return (
    <BooleanField
      id='DisplayThumbnailsField'
      title='Display Thumbnails'
      description='Show/Hide thumbnails'
      value={isDisplayThumbnails}
      onChange={toggle => {
        settingStore.setSetting(DISPLAY_THUMBNAILS_ID, toggle)
      }}
    />
  )
})

/**
 * The encoders display field
 * @memberof module:components/fields
 * @property {boolean} isDisplayEncoders - Flags if encoder is displayed or not
 * @property {function} onToggle - Function triggered when the user clicks the switch
 * @returns {module:components/fields.BooleanField} A boolean field
 */
const DisplayEncodersField = observer(() => {
  const { settingStore } = useContext(AppStoresContext)
  const isDisplayEncoders = settingStore.displayEncoders

  return (
    <BooleanField
      id='DisplayEncodersField'
      title='Display Encoders'
      description='Show/Hide encoders'
      value={isDisplayEncoders}
      onChange={toggle => {
        settingStore.setSetting(DISPLAY_ENCODERS_ID, toggle)
      }}
    />
  )
})

export { LanguageSelectField, DisplayThumbnailsField, DisplayEncodersField }

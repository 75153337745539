import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { fromBuddyStatus, fromSendStatus, fromRecvStatus } from '@models/common/StatusEnum'

import { Feedback, Context, Layout, Common } from '@sat-mtl/ui-components'

import '@styles/tooltips/SipContactInformationTooltip.scss'

const { Tooltip } = Feedback
const { ThemeContext } = Context
const { FlexRow } = Layout
const { Tag } = Common

/**
 * Renders the availability status of a SIP contact
 * @param {object} buddyStatus - A SIP buddy status
 * @returns {external:react/Component} A HTML description item
 */
const AvailabilityStatus = ({ buddyStatus }) => {
  const { t } = useTranslation()

  return (
    <FlexRow key='availability'>
      <dt>{t('Availability')}</dt>
      <dd>
        <Tag status={fromBuddyStatus(buddyStatus)}>
          {t(buddyStatus)}
        </Tag>
      </dd>
    </FlexRow>
  )
}

/**
 * Renders the sending status of a SIP contact
 * @param {object} sendStatus - A SIP sending status
 * @returns {external:react/Component} A HTML description item
 */
export const SendingStatus = ({ sendStatus }) => {
  const { t } = useTranslation()

  return (
    <FlexRow key='sending'>
      <dt>{t('Sending')}</dt>
      <dd>
        <Tag status={fromSendStatus(sendStatus)}>
          {t(sendStatus)}
        </Tag>
      </dd>
    </FlexRow>
  )
}

/**
 * Renders the receiving status of a SIP contact
 * @param {object} recvStatus - A SIP receiving status
 * @returns {external:react/Component} A HTML description item
 */
export const ReceivingStatus = ({ recvStatus }) => {
  const { t } = useTranslation()

  return (
    <FlexRow key='receiving'>
      <dt>{t('Receiving')}</dt>
      <dd>
        <Tag status={fromRecvStatus(recvStatus)}>
          {t(recvStatus)}
        </Tag>
      </dd>
    </FlexRow>
  )
}

/**
 * Renders the SIP contact information
 * @param {object} buddyStatus - A SIP buddy status
 * @param {object} sendStatus - A SIP sending status
 * @param {object} recvStatus - A SIP receiving status
 * @returns {external:react/Component} A HTML description list
 */
const Information = ({ buddyStatus, sendStatus, recvStatus }) => {
  return (
    <dl>
      <AvailabilityStatus buddyStatus={buddyStatus} />
      {sendStatus && <SendingStatus sendStatus={sendStatus} />}
      {recvStatus && <ReceivingStatus recvStatus={recvStatus} />}
    </dl>
  )
}

/** Tooltip that displays all contact informations */
const SipContactInformationTooltip = observer(({ buddyStatus, sendStatus, recvStatus, children }) => {
  const theme = useContext(ThemeContext)

  const classes = classNames(
    'SipContactInformationTooltip',
    `tooltip-sip-${theme}`
  )

  return (
    <div className={classes}>
      <Tooltip side='left' content={<Information buddyStatus={buddyStatus} sendStatus={sendStatus} recvStatus={recvStatus} />}>
        {children}
      </Tooltip>
    </div>
  )
})

export default SipContactInformationTooltip

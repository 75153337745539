import { observable, action, makeObservable } from 'mobx'

import Store from '@stores/Store'
import Notification from '@models/common/Notification'

/**
 * @classdesc Stores all notifications
 * @extends module:stores/common.Store
 * @memberof module:stores/common
 */
class NotificationStore extends Store {
  /** @property {Set<module:models/common.Notification>} userNotifications - The displayed notification */
  userNotifications = new Set()

  /** @property {Set<string>} expiredNotifications - All expired notification IDs */
  expiredNotifications = new Set()

  /** @property {Set<string>} muteClasses - All classes that aren't published as notifications */
  muteClasses = new Set()

  constructor (socketStore) {
    super(socketStore)

    makeObservable(this, {
      userNotifications: observable,
      expiredNotifications: observable,
      muteClasses: observable,
      addUserNotification: action,
      addExpiredNotification: action,
      removeNotification: action,
      addMuteClass: action,
      removeMuteClass: action,
      clear: action
    })
  }

  /**
   * Add a notification from a logging event
   * @param {external:pino/level} logLevel - The level of the logging
   * @param {external:pino/logEvent} logEvent - An event transmitted by the logger
   */
  applyNotificationLog (logLevel, logEvent) {
    for (const log of logEvent.messages) {
      if (log.notification) {
        this.applyNotificationPush(
          Notification.fromLog(logLevel, log, logEvent.bindings)
        )
      }
    }
  }

  /**
   * Pushes a notification in the queue and sets a timeout on it
   * @param {module:models/common.Notification} notification - The notification to push
   */
  applyNotificationPush (notification) {
    const isMute = this.muteClasses.has(notification.class)

    if (!isMute) {
      this.addUserNotification(notification)
      this.applyDurationTimeout(notification)
    }
  }

  /**
   * Applies a timeout with the notification duration
   * @param {module:models/common.Notification} notification - The notification to timeout
   */
  applyDurationTimeout (notification) {
    if (this.userNotifications.has(notification) && !notification.isPermanent) {
      setTimeout(
        () => this.addExpiredNotification(notification.id),
        notification.duration
      )
    }
  }

  /**
   * Checks if a notification is expired
   * @param {module:models/common.Notification} notification - The notification to check
   * @returns {boolean} Flags an expired notifiaction
   */
  isExpired (notification) {
    return this.expiredNotifications.has(notification.id)
  }

  /**
   * Adds a new notification
   * @param {module:models/common.Notification} notification - A new notification
   */
  addUserNotification (notification) {
    if (this.userNotifications.has(notification)) {
      this.removeNotification(notification)
    }

    this.userNotifications.add(notification)
  }

  /**
   * Sets an expired notification
   * @param {string} notificationId - ID of the notification
   */
  addExpiredNotification (notificationId) {
    if (this.expiredNotifications.has(notificationId)) {
      this.expiredNotifications.delete(notificationId)
    }

    this.expiredNotifications.add(notificationId)
  }

  /**
   * Removes a notification from the queue and the expired notifications
   * @param {module:models/common.Notification} notification - The notification to remove
   */
  removeNotification (notification) {
    this.userNotifications.delete(notification)
    this.expiredNotifications.delete(notification.id)
  }

  /**
   * Mute a notification class
   * @param {string} notificationClass - Class to mute, usually a store name
   */
  addMuteClass (notificationClass) {
    this.muteClasses.add(notificationClass)
  }

  /**
   * Unmute a notification class
   * @param {string} notificationClass - Class to unmute, usually a store name
   */
  removeMuteClass (notificationClass) {
    this.muteClasses.delete(notificationClass)
  }

  /** Clears all notification structures */
  clear () {
    this.userNotifications.clear()
    this.expiredNotifications.clear()
  }
}

export default NotificationStore

import { observable, action, makeObservable } from 'mobx'

import Store from '@stores/Store'

export const DRAWER_WIDTH = 500

/**
 * @classdesc Stores all drawers
 * @extends stores.Store
 * @memberof stores
 */
class DrawerStore extends Store {
  /** @property {Set<string>} drawers - The Set of all registered drawers */
  drawers = new Set()

  /** @property {?string} activeDrawer - The currently active drawer */
  activeDrawer = null

  /** @property {Map<string, function>} drawerProps - Contextual properties of the drawer by drawer ID */
  drawerProps = new Map()

  constructor (socketStore) {
    super(socketStore)

    makeObservable(this, {
      drawers: observable,
      activeDrawer: observable,
      drawerProps: observable,
      setActiveDrawer: action,
      clearActiveDrawer: action,
      addDrawer: action,
      removeDrawer: action,
      setDrawerProps: action
    })
  }

  /**
   * Cleans an active drawer
   * @param {string} drawerId - The drawer to clean
   */
  cleanActiveDrawer (drawerId) {
    if (this.activeDrawer === drawerId) {
      this.clearActiveDrawer()
    }
  }

  /**
   * Toggles the visibility of a drawer
   * @param {string} drawerId - ID of the drawer
   */
  toggleActiveDrawer (drawerId) {
    if (this.activeDrawer !== drawerId) {
      this.setActiveDrawer(drawerId)
    } else {
      this.cleanActiveDrawer(drawerId)
    }
  }

  /**
   * Sets the new active drawer
   * @param {string} drawerId - The active drawer's ID
   */
  setActiveDrawer (drawerId) {
    if (this.drawers.has(drawerId)) {
      this.activeDrawer = drawerId
    }
  }

  /** Clears the active drawer */
  clearActiveDrawer () {
    this.activeDrawer = null
  }

  /**
   * Registers a new drawer
   * @param {string} drawerId - The new drawer to register
   */
  addDrawer (drawerId) {
    this.drawers.add(drawerId)
  }

  /**
   * Unregisters a drawer
   * @param {string} drawerId - The drawer's ID to unregister
   */
  removeDrawer (drawerId) {
    this.drawers.delete(drawerId)
    this.drawerProps.delete(drawerId)
  }

  /**
   * Sets a drawer binding
   * @param {string} drawerId - The drawer's ID to bind
   * @param {Function} props - The props for the drawer
   */
  setDrawerProps (drawerId, props = Function.prototype) {
    if (this.drawers.has(drawerId)) {
      this.drawerProps.set(drawerId, props)
    }
  }
}

export default DrawerStore

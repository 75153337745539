/**
 * Enum for all capability groups
 * @readonly
 * @memberof models
 * @enum {string}
 */
const CapabilityGroupEnum = Object.freeze({
  DIMENSIONS: 'dimensions',
  OTHERS: 'others'
})

export default CapabilityGroupEnum

import React, { useContext, createContext } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'

import { Layout, Context } from '@sat-mtl/ui-components'

import ConnectionBox from '@components/matrix/ConnectionBox'
import Source, { EmptySource } from '@components/matrix/Source'
import DestinationHead from '@components/matrix/DestinationHead'
import SourceSeparator from '@components/matrix/SourceSeparator'
import MatrixCategoryEnum from '@models/matrix/MatrixCategoryEnum'
import { ColumnSeparator } from '@components/matrix/DestinationSeparator'

import LayoutHelper from '@stores/matrix/LayoutHelper'
import { AppStoresContext } from '@components/App'

import { useTranslation } from 'react-i18next'

import '@styles/matrix/MatrixLayout.scss'

const { FlexColumn, FlexRow, FlexBox } = Layout
const { ThemeContext } = Context

/**
 * @constant {external:react/Context} LayoutHelperContext - Dispatches the layout helper
 * @memberof module:components/matrix.Matrix
 */
export const LayoutHelperContext = createContext(null)

/**
 * @constant {number} MATRIX_INNER_GAP - Inner gap for the matrix elements
 * @memberof module:components/matrix.Matrix
 */
export const MATRIX_INNER_GAP = 2

/**
 * @constant {Object} MATRIX_BOX_PROPS - Properties shared by all boxes
 * @memberof module:components/matrix.Matrix
 */
export const MATRIX_BOX_PROPS = {
  width: 70,
  height: 50,
  padding: 20
}

/**
 * Renders all sources of a category
 * @memberof module:components/matrix
 * @param {string} categoryId - The source category
 * @returns {external:mobx-react/ObserverComponent} All rendered sources wrapped in an area
 */
const SourceColumnArea = observer(({ categoryId }) => {
  const { matrixStore, filterStore } = useContext(AppStoresContext)
  const layoutHelper = useContext(LayoutHelperContext)

  const areaKey = layoutHelper.getSourceAreaKey(categoryId)
  const areaStyle = layoutHelper.getGridAreaStyle(areaKey)

  const sourceEntries = matrixStore.sourceCategories.get(categoryId) || []
  const $columns = []

  for (const sourceEntry of sourceEntries) {
    if (filterStore.applyEntryFilter(sourceEntry)) {
      $columns.push(
        <Source
          key={sourceEntry.id}
          entry={sourceEntry}
        />
      )
    }
  }

  return (
    <div id={areaKey} style={areaStyle}>
      <FlexColumn rowGap={MATRIX_INNER_GAP}>
        {$columns}
      </FlexColumn>
    </div>
  )
})

/**
 * Renders a column area without source
 * @returns {external:react/Component} An empty column
 */
function EmptySourceColumnArea () {
  const layoutHelper = useContext(LayoutHelperContext)
  const emptyKey = layoutHelper.getSourceAreaKey('emptySource')

  return (
    <div id={emptyKey}>
      <EmptySource />
    </div>
  )
}

/**
 * Renders all source areas
 * @memberof module:components/matrix
 * @returns {external:mobx-react/ObserverComponent} All rendered source areas
 */
const SourceColumn = observer(() => {
  const { matrixStore, contactStore } = useContext(AppStoresContext)
  const { sourceCategories } = matrixStore
  const layoutHelper = useContext(LayoutHelperContext)
  const { t } = useTranslation()
  let $column = (
    <EmptySourceColumnArea />
  )

  if (sourceCategories.size > 0) {
    $column = []
    for (const sourceCategory of sourceCategories.keys()) {
      if (sourceCategory !== MatrixCategoryEnum.COMMON) {
        const areaKey = layoutHelper.getSourceSeparatorAreaKey(sourceCategory)
        const areaStyle = layoutHelper.getGridAreaStyle(areaKey)
        $column.push(
          <SourceSeparator
            key={areaKey}
            style={areaStyle}
            header={t(contactStore.getCallerLabelFromURI(sourceCategory))}

          />)
      }
      $column.push(
        <SourceColumnArea
          key={sourceCategory}
          categoryId={sourceCategory}
        />
      )
    }
  }
  return $column
})

/**
 * Renders all destinations of a category
 * @memberof module:components/matrix
 * @param {string} categoryId - The destination category
 * @returns {external:mobx-react/ObserverComponent} The rendered destinations wrapped in an area
 */
const DestinationRowArea = observer(({ categoryId }) => {
  const { matrixStore, filterStore } = useContext(AppStoresContext)
  const layoutHelper = useContext(LayoutHelperContext)

  const areaKey = layoutHelper.getDestinationAreaKey(categoryId)
  const areaStyle = layoutHelper.getGridAreaStyle(areaKey)

  const destinationEntries = matrixStore.destinationCategories.get(categoryId) || []
  const $rows = []

  for (const destinationEntry of destinationEntries) {
    if (filterStore.applyEntryFilter(destinationEntry)) {
      $rows.push(
        <DestinationEntry
          key={destinationEntry.id}
          entry={destinationEntry}
        />
      )
    }
  }

  return (
    <div id={areaKey} style={areaStyle}>
      <FlexRow alignItems='flex-end' columnGap={MATRIX_INNER_GAP}>
        {$rows}
      </FlexRow>
    </div>
  )
})

/**
 * Renders all destination areas (separators and connections)
 * @memberof module:components/matrix
 * @returns {external:mobx-react/ObserverComponent} All destination areas
 */
const DestinationRows = observer(() => {
  const layoutHelper = useContext(LayoutHelperContext)
  const { matrixStore } = useContext(AppStoresContext)
  const { destinationCategories } = matrixStore
  const $areas = []

  for (const [destinationCategory] of destinationCategories) {
    if (destinationCategory !== MatrixCategoryEnum.COMMON) {
      const areaKey = layoutHelper.getDestinationSeparatorAreaKey(destinationCategory)
      const areaStyle = layoutHelper.getGridAreaStyle(areaKey)

      $areas.push(
        <div className='DestinationSeparatorContainer' key={`${destinationCategory}-separator`}>
          <DestinationsTitle
            header={destinationCategory}
            style={areaStyle}
          />
        </div>
      )
    }

    $areas.push(
      <div className='DestinationRowAreaContainer' key={`${destinationCategory}-area`}>
        <DestinationRowArea
          categoryId={destinationCategory}
        />
      </div>
    )
  }

  return $areas
})

/**
 * Renders a zone for all connections that connects all sources to all destinations according to specific categories
 * @memberof module:components/matrix
 * @param {string} sourceCategory - A category of sources
 * @param {string} destinationCategory - A category of destinations
 * @returns {external:mobx-react/ObserverComponent} An area of connection boxes
 */
const ConnectionArea = observer(({ sourceCategory, destinationCategory }) => {
  const { matrixStore, filterStore } = useContext(AppStoresContext)
  const { destinationCategories, sourceCategories } = matrixStore
  const layoutHelper = useContext(LayoutHelperContext)

  const areaKey = layoutHelper.getConnectionAreaKey(sourceCategory, destinationCategory)
  const areaStyle = layoutHelper.getGridAreaStyle(areaKey)

  const sourceEntries = sourceCategories.get(sourceCategory) || []
  const $rows = []

  for (const sourceEntry of sourceEntries) {
    const destinationEntries = destinationCategories.get(destinationCategory) || []
    const $columns = []

    for (const destinationEntry of destinationEntries) {
      if (filterStore.applyCellFilter(sourceEntry, destinationEntry)) {
        $columns.push(
          <MatrixCell
            key={`${sourceEntry.id}-${destinationEntry.id}`}
            destinationEntry={destinationEntry}
            sourceEntry={sourceEntry}
          />
        )
      }
    }

    $columns.length > 0 && $rows.push(
      <FlexRow key={sourceEntry.id} columnGap={MATRIX_INNER_GAP}>
        {$columns}
      </FlexRow>
    )
  }

  return (
    <div key={areaKey} id={areaKey} style={areaStyle}>
      <FlexColumn className='connection-table' rowGap={MATRIX_INNER_GAP}>
        {$rows}
      </FlexColumn>
    </div>
  )
})

/**
 * Renders the component that matches the destination type
 * @memberof module:components/matrix
 * @param {module:models/matrix.MatrixEntry} entry - Matrix entry for a destination
 * @returns {external:react/Component} The rendered destination UI
 */
function DestinationEntry ({ entry }) {
  const stores = useContext(AppStoresContext)

  return (
    <DestinationHead
      entry={entry}
      {...stores}
    />
  )
}

/**
 * Renders all connection areas for each destination and source categories
 * @memberof module:components/matrix
 * @returns {external:mobx-react/ObserverComponent} All the rendered connection areas
 */
const ConnectionTable = observer(() => {
  const { matrixStore } = useContext(AppStoresContext)
  const { destinationCategories, sourceCategories } = matrixStore

  const $table = []

  for (const [sourceCategory] of sourceCategories) {
    for (const [destinationCategory] of destinationCategories) {
      $table.push(
        <ConnectionArea
          key={`${sourceCategory}-${destinationCategory}`}
          sourceCategory={sourceCategory}
          destinationCategory={destinationCategory}
        />
      )
    }
  }

  return $table
})

/**
 * Renders a connection
 * @memberof module:components/matrix
 * @param {module:models/matrix.MatrixEntry} sourceEntry - Matrix entry of the source
 * @param {module:models/matrix.MatrixEntry} destinationEntry - Matrix entry of the destination
 * @returns {external:react/Component} A cell of the matrix
 */
function MatrixCell ({ sourceEntry, destinationEntry }) {
  const stores = useContext(AppStoresContext)

  return (
    <ConnectionBox
      sourceEntry={sourceEntry}
      destinationEntry={destinationEntry}
      {...stores}
    />
  )
}

/**
 * Renders the title of the destination row
 * @memberof module:components/matrix
 * @returns {external:mobx-react/ObserverComponent} The rendered title
 */
const DestinationsTitle = observer(({ header }) => {
  const { quiddityStore } = useContext(AppStoresContext)

  let title = null

  if (quiddityStore.destinations.length > 0) {
    title = (
      <div className='DestinationsTitle'>
        <ColumnSeparator header={header} color='transparent' />
      </div>
    )
  }

  return title
})

/**
 * Renders the title of the source column
 * @memberof module:components/matrix
 * @returns {external:mobx-react/ObserverComponent} The rendered title
 */
const SourcesTitle = observer(() => {
  const { quiddityStore } = useContext(AppStoresContext)
  const theme = useContext(ThemeContext)
  const classes = classNames('SourcesTitle', `MatrixTitle-${theme}`)

  let title = null

  if (quiddityStore.sources.length > 0) {
    title = (
      <FlexBox alignItems='center' justifyContent='center' className={classes}>
        sources
      </FlexBox>
    )
  }

  return title
})

/**
 * Renders the header of the matrix
 * @memberof module:components/matrix
 * @returns {external:react/Component} The rendered header wrapped in an area
 */
function MatrixHeader () {
  const layoutHelper = useContext(LayoutHelperContext)
  const areaKey = layoutHelper.getMatrixHeaderAreaKey()

  return (
    <div id={areaKey} style={layoutHelper.getGridAreaStyle(areaKey)}>
      <FlexBox alignItems='flex-end' justifyContent='flex-end'>
        <SourcesTitle />
        <DestinationsTitle header='destinations' />
      </FlexBox>
    </div>
  )
}

/**
 * Renders the layout of the matrix
 * @selector `#MatrixLayout`
 * @memberof module:components/matrix
 * @returns {external:mobx-react/ObserverComponent} The rendered matrix wrapped in a grid layout
 */
const MatrixLayout = observer(() => {
  const layoutHelper = useContext(LayoutHelperContext)
  const theme = useContext(ThemeContext)
  const classes = classNames(`MatrixLayout-${theme}`)

  return (
    <div id='MatrixLayout' style={layoutHelper.gridLayoutStyle} className={classes}>
      <MatrixHeader />
      <DestinationRows />
      <SourceColumn />
      <ConnectionTable />
    </div>
  )
})

/**
 * Renders the matrix
 * @todo Resolve all unit tests for every Matrix sub-components
 * @memberof module:components/matrix
 * @returns {external:react/Component} The matrix component
 */
function Matrix () {
  const { matrixStore } = useContext(AppStoresContext)
  const layoutHelper = new LayoutHelper(matrixStore, MATRIX_INNER_GAP)

  return (
    <div id='Matrix'>
      <LayoutHelperContext.Provider value={layoutHelper}>
        <MatrixLayout />
      </LayoutHelperContext.Provider>
    </div>
  )
}

export default Matrix

export {
  MatrixLayout,
  EmptySourceColumnArea
}

import { observable, computed, action, makeObservable } from 'mobx'

import Store from '@stores/Store'
import SipCredentials from '@models/sip/SipCredentials'
import { toString, isStringEmpty } from '@utils/stringTools'
import { getCookie } from '../../utils/cookies'

/**
 * @constant {number} SIP_DEFAULT_PORT - Default port of the SIP
 * @memberof stores.SipCredentialStore
 */
export const SIP_DEFAULT_PORT = 5060

/**
 * @constant {Map<string, string>} DEFAULT_SIP_CREDENTIALS - Default credential for the SIP
 * @memberof stores.SipCredentialStore
 */
export const DEFAULT_SIP_CREDENTIALS = new Map([
  ['sipServer', ''],
  ['sipUser', ''],
  ['sipPassword', ''],
  ['sipPort', SIP_DEFAULT_PORT],
  ['turnServer', ''],
  ['turnUser', ''],
  ['turnPassword', ''],
  ['stunServer', ''],
  ['isSameStunTurnLogin', true],
  ['sipDestinationPort', '5060']
])

/**
 * Store for all SIP credentials
 * @extends stores.Store
 * @memberof {stores}
 */
class SipCredentialStore extends Store {
  /** @property {Map<string, string>} credentials - The credentials as a Map  */
  credentials = DEFAULT_SIP_CREDENTIALS

  constructor (socketStore) {
    super(socketStore)

    makeObservable(this, {
      credentials: observable,
      sipCredentials: computed,
      errors: observable,
      areCredentialsValid: computed,
      setCredential: action,
      setError: action,
      cleanError: action,
      cleanCredentials: action
    })

    this.getCredentials()
  }

  /** @property {models.SipCredentials} sipCredentials - The SipCredential model computed from the credential map */
  get sipCredentials () {
    if (this.credentials.get('isSameStunTurnLogin')) {
      return new SipCredentials(
        this.credentials.get('sipUser'),
        this.credentials.get('sipServer'),
        this.credentials.get('sipPassword'),
        this.credentials.get('sipUser'),
        this.credentials.get('sipServer'),
        this.credentials.get('sipServer'),
        this.credentials.get('sipPassword'),
        this.credentials.get('sipPort'),
        this.credentials.get('sipDestinationPort')
      )
    } else {
      return new SipCredentials(
        this.credentials.get('sipUser'),
        this.credentials.get('sipServer'),
        this.credentials.get('sipPassword'),
        this.credentials.get('turnUser'),
        this.credentials.get('turnServer'),
        this.credentials.get('stunServer'),
        this.credentials.get('turnPassword'),
        this.credentials.get('sipPort'),
        this.credentials.get('sipDestinationPort')
      )
    }
  }

  /** @property {Map<string, string>} errors - Map of all credentials error messages */
  errors = new Map()

  /** @property {boolean} areCredentialsValid - Flags valid credentials */
  get areCredentialsValid () {
    return this.applyAllValidationBindings()
  }

  /**
   * Applies all validation bindings
   * @returns {boolean} Returns true if credentials are valid
   */
  applyAllValidationBindings () {
    let areValid = true
    try {
      this.applyValidationBinding('sipServer')
      this.applyValidationBinding('sipUser')
      this.applyValidationBinding('sipPassword')
      this.applyValidationBinding('sipPort')

      if (!this.credentials.get('isSameStunTurnLogin')) {
        this.applyValidationBinding('turnUser')
        this.applyValidationBinding('turnServer')
        this.applyValidationBinding('turnPassword')
        this.applyValidationBinding('stunServer')
      }
    } catch (error) {
      areValid = false
    }

    return areValid
  }

  /** @property {Map<string, function>} validationBindings - Map of all bindings that are checking the credentials */
  validationBindings = new Map([
    ['sipServer', sipServer => {
      if (isStringEmpty(sipServer)) {
        throw new Error('The SIP server address is required')
      }
    }],
    ['sipUser', sipUser => {
      if (isStringEmpty(sipUser)) {
        throw new Error('The SIP user name is required')
      }
    }],
    ['sipPassword', sipPassword => {
      if (isStringEmpty(sipPassword)) {
        throw new Error('The SIP password is required')
      }
    }],
    ['sipPort', sipPort => {
      const parsed = Number.parseInt(sipPort)

      if (isStringEmpty(`${sipPort}`)) {
        throw new Error('The SIP port is required')
      } else if (Number.isNaN(parsed)) {
        throw new Error('The SIP port must be a number')
      }
    }],
    ['turnServer', turnServer => {
      if (isStringEmpty(turnServer)) {
        throw new Error('The TURN server address is required')
      }
    }],
    ['turnUser', turnUser => {
      if (isStringEmpty(turnUser)) {
        throw new Error('The TURN user name is required')
      }
    }],
    ['turnPassword', turnPassword => {
      if (isStringEmpty(turnPassword)) {
        throw new Error('The TURN password is required')
      }
    }],
    ['stunServer', stunServer => {
      if (isStringEmpty(stunServer)) {
        throw new Error('The STUN server address is required')
      }
    }]
  ])

  /**
   * Applies a binding for a credential
   * @param {string} credentialId - ID of the credential
   */
  applyValidationBinding (credentialId) {
    if (this.validationBindings.has(credentialId)) {
      this.validationBindings.get(credentialId)(this.credentials.get(credentialId))
    }
  }

  /**
   * Applies a validation for a credential
   * @param {string} credentialId - ID of the credential
   */
  applyCredentialValidation (credentialId) {
    if (this.credentials.has(credentialId)) {
      const value = this.credentials.get(credentialId)

      if (typeof value === 'string') {
        const trimedValue = toString(value).trim()

        if (value !== trimedValue) {
          this.setCredential(credentialId, trimedValue)
        }
      }

      try {
        this.applyValidationBinding(credentialId)
        this.cleanError(credentialId)
      } catch (error) {
        this.setError(credentialId, error.message)
      }
    }
  }

  /**
   * Applies the SameStunTurnLogin toggle
   * @param {boolean} isSameStunTurnLoginToggle - Flags a login with the same STUN and TURN login than the SIP server
   */
  applySameStunTurnLoginToggle (isSameStunTurnLoginToggle) {
    if (isSameStunTurnLoginToggle) {
      this.cleanError('turnServer')
      this.cleanError('turnUser')
      this.cleanError('turnPassword')
      this.cleanError('stunServer')
    }

    this.setCredential('isSameStunTurnLogin', isSameStunTurnLoginToggle)
  }

  /**
   * Sets a new credential
   * @param {string} credentialId - ID of the credential
   * @param {string} credentialValue - Value of the credential
   */
  setCredential (credentialId, credentialValue) {
    if (this.credentials.has(credentialId)) {
      this.credentials.set(credentialId, credentialValue)
    }
  }

  /**
   * Sets an error for a credential
   * @param {string} credentialId - ID of the credential
   * @param {string} errorMessage - Value of the credential
   */
  setError (credentialId, errorMessage) {
    if (this.credentials.has(credentialId)) {
      this.errors.set(credentialId, errorMessage)
    }
  }

  /**
   * Cleans an error for a credential
   * @param {string} credentialId - ID of the credential
   */
  cleanError (credentialId) {
    if (this.errors.has(credentialId)) {
      this.errors.delete(credentialId)
    }
  }

  /** Cleans all credentials */
  cleanCredentials () {
    this.credentials = DEFAULT_SIP_CREDENTIALS
    this.errors = new Map()
  }

  /**
  * Get credentials saved in cookies and set them in respective fields if non empty
  */
  getCredentials () {
    const credentials = this.getSearchParams()[0] ? this.getSearchParams()[1] : this.getSipCookies()

    for (const property in credentials) {
      if (credentials[property] !== '') this.setCredential(property, credentials[property])
    }
  }

  /**
  * Get sip credentials cookies and return the values in an object
  * @returns {Object} Object with the sip credentials values
  */
  getSipCookies () {
    const sipCredentials = { sipServer: '', sipPort: 5060, sipUser: '', sipPassword: '' }

    for (const property in sipCredentials) {
      sipCredentials[property] = getCookie(property)
    }

    return sipCredentials
  }

  /**
  * Get sip credentials from search parameters and return the values in an object
  * @returns {Object} Object with the sip credentials values
  */
  getSearchParams () {
    const sipCredentials = { sipServer: '', sipPort: 5060, sipUser: '', sipPassword: '' }
    const url = new URLSearchParams(window.location.search)
    const creds = url.get('sip')

    if (creds) {
      const [user, password, server, port] = creds.split(/:|@/)
      sipCredentials.sipUser = user
      sipCredentials.sipPassword = password
      sipCredentials.sipServer = server
      sipCredentials.sipPort = port
    }
    return [creds, sipCredentials]
  }
}

export default SipCredentialStore

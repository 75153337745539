import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { AppStoresContext } from '@components/App'
import { Feedback, Common } from '@sat-mtl/ui-components'

const { Button } = Common
const { Modal } = Feedback

/**
 * @constant {string} CONFIGURATION_MODAL_ID - ID of the configuration error modal
 * @memberof ConfigurationModal
 */
export const CONFIGURATION_MODAL_ID = 'ConfigurationModal'

/**
 * Renders the Reload Button for the configuration Modal
 * @memberof module:components/modals.ConfigurationModal
 * @returns {external:react/Component} The reload button of the Modal
 */
export const ReloadButton = () => {
  const { socketStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <Button
      className='ReloadButton'
      type='primary'
      shape='rectangle'
      onClick={() => socketStore.applyConnection()}
    >
      {t('Reload')}
    </Button>
  )
}

/** Scenic modal used to warn user of an invalid configuration */
const ConfigurationModal = observer(() => {
  const { modalStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  useEffect(() => {
    modalStore.addModal(CONFIGURATION_MODAL_ID)

    return () => {
      modalStore.removeModal(CONFIGURATION_MODAL_ID)
    }
  })

  return (
    <Modal
      id='ConfigurationModal'
      header={t('Invalid user configuration')}
      visible={modalStore.activeModal === CONFIGURATION_MODAL_ID}
      footer={(
        <ReloadButton />
      )}
    >
      {t('Scenic initialization aborted.')}
    </Modal>
  )
})

export default ConfigurationModal

import { cloneDeep, isEmpty } from 'lodash'

/**
 * Test if a given value is a Javascript Object or not
 * @param {*} value - Value to test
 * @returns {boolean} Flags true if value is an object
 */
function isObject (value) {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

/**
 * Convert an array of object to an object
 * @param {Object[]} array - Array to convert
 * @param {string} keyField - Object property which will be the keys of the new object
 * @returns {Object} Produced object
 */
function arrayToObject (array, keyField) {
  return array.reduce((obj, item) => ({ ...obj, [item[keyField]]: item }), {})
}

/**
 * Remove keys from an object whose values are empty arrays
 * @param {Object} object - Object to process
 * @returns {Object} Object without key-value pairs containing empty arrays
 */
function stripEmptyArrays (object) {
  const keys = Object.keys(object)
  const newObject = {}
  for (const key of keys) {
    const value = object[key]
    if (!Array.isArray(value) || (Array.isArray(value) && value.length !== 0)) {
      newObject[key] = value
    }
  }
  return newObject
}

/**
 * Gets the object situated at the specified switcher path. A switcher path
   is a string of "words.with.periods.and.sometimes.1.numbers". The object is traversed with every
   word between the periods representing a key in an object.
   This returns a copy of the object, mutating it won't affect the original object
 * @param {Object} object - Object to process
   @param {Object} treePath - string of the tree path to use.
 * @returns {Object} The object situated at the end of the string path.
 */
function getFromSwitcherTreePath (object, treePath) {
  const paths = treePath.split('.').filter(elem => elem)
  let subObject = cloneDeep(object)
  for (const path of paths) {
    subObject = subObject[path]
  }
  return subObject
}

/**
 * return a copy of the passed object with the specified assignation.
   the object situated at the specified switcher path to the specified value.
   this will create the missing obects/arrays along the specified path, creating
   object when it needs to set something at a non positive integer key and arrays
   when it needs to set at positive integer keys.

   Example for the creation of non existing objects/arrays : if the object is {}, path
   is some.stuff.1 and the value is "test", this code will first set the some key to an empty object
   because the next path (stuff) is not a positive integer. it will then stuff key of the some object
   to an empty array because the next key is a positive integer and will set the value "test" to
   the index 1 of the array. resulting in the following object :
   {some : {
   stuff:[<empty>,"test"]
   }
   }
 * @param {Object} object - Object to process
   @param {Object} treePath - string of the tree path to use.
 * @returns {Object} The object situated at the end of the string path.
 */
function getMutatedFromSwitcherTreePath (object, treePath, value) {
  const paths = treePath.split('.').filter(elem => elem)
  const cloneObject = cloneDeep(object)
  let subObject = cloneObject
  for (let i = 0; i < paths.length - 1; i++) {
    if (!subObject[paths[i]]) {
      subObject[paths[i]] = /^\d+$/.test(paths[i + 1]) ? [] : {}
    }
    subObject = subObject[paths[i]]
  }
  if (!subObject[paths[paths.length - 1]]) {
    subObject[paths[paths.length - 1]] = /^\d+$/.test(paths[paths.length - 1]) ? [] : {}
  }
  subObject[paths[paths.length - 1]] = value
  return cloneObject
}
/**
 *
   Takes an object and a switcher tree path, clones the object and deletes all the values situated at and after
   the specified path in the clone object. Returns the entire cloned object minus the pruned parts.

 * @param {Object} object - Object to process
   @param {Object} treePath - string of the tree path to prune.
 * @returns {Object} A copy of original object pruned at the specified path.
 */
function getPrunedFromSwitcherTreePath (object, treePath) {
  const paths = treePath.split('.').filter(elem => elem)
  const cloneObject = cloneDeep(object)
  let subObject = cloneObject

  // we need to keep a pointer to the previous subobject in case of a deletion if we would
  // be deleting the last key of the last subobject.
  let previousSubObject = null

  for (let i = 0; i < paths.length - 1; i++) {
    previousSubObject = subObject
    subObject = subObject[paths[i]]
  }
  delete subObject[paths[paths.length - 1]]

  if (isEmpty(subObject)) {
    if (previousSubObject) {
      previousSubObject[paths[paths.length - 2]] = null
    } else {
      subObject = null
    }
  }
  return cloneObject
}

export { isObject, arrayToObject, stripEmptyArrays, getFromSwitcherTreePath, getMutatedFromSwitcherTreePath, getPrunedFromSwitcherTreePath }

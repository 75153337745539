/**
 * Enum for initialization status of stores
 * @readonly
 * @memberof models
 * @enum {number}
 */
const InitStateEnum = Object.freeze({
  NOT_INITIALIZED: 0,
  INITIALIZING: 1,
  INITIALIZED: 2
})

export default InitStateEnum

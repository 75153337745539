/**
 * Checks if the debug mode is enabled for Scenic
 * @returns {boolean} Returns true if the debug mode is enabled
 */
function hasDebugMode () {
  const currentURL = new URL(document.location)
  return !!currentURL.searchParams?.get('debug')
}

/**
 * Gets all store statements in JSON object
 * @param {Object} stores - A map that contains all stores
 * @returns {Object} A JSON object with all stores
 */
function debugStores (stores) {
  return Object.fromEntries(
    Object.entries(stores)
      .filter(([, store]) => {
        return typeof store.toJSON === 'function'
      })
      .map(([key, store]) => {
        return [key, store.toJSON()]
      })
  )
}

export { hasDebugMode, debugStores }

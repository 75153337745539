import Capabilities from '@models/shmdata/Capabilities'

/**
 * Modelizes a Shmdata produced by a Quiddity
 * @memberof models
 */
class Shmdata {
  /**
   * Instantiates a Shmdata model
   * @param {string} path - Path of the shmdata
   * @param {(string|string[])} caps - All gstreamer caps of the shmdata
   * @param {string} category - Category of the shmdata
   * @param {?string} uri - URI of the shmdata caller
   */
  constructor (path, caps, category, uri = null) {
    if (typeof path === 'undefined' || typeof path !== 'string') {
      throw new TypeError('Attribute `path` is required and must be a string')
    }

    if (typeof caps === 'undefined' || (typeof caps !== 'string' && !Array.isArray(caps))) {
      throw new TypeError('Attribute `caps` is required and must be a string or an array')
    }

    if (typeof category === 'undefined' || typeof category !== 'string') {
      throw new TypeError('Attribute `category` is required and must be a string')
    }

    /** @property {string} path - Path of the shmdata */
    this.path = path

    /** @property {string[]} capabilities - Array of the shmdata's Gstreamer caps */
    this.capabilities = Capabilities.fromCaps(caps)

    /** @property {string} category - Category of the shmdata */
    this.category = category

    /** @property {string} uri - URI of the caller that produces the shmdata */
    this.uri = uri
  }

  /** @property {string} caps - The string representation of the GstCaps */
  get caps () {
    return this.capabilities.toCaps()
  }

  /** @property {string} id - ID of the shmdata */
  get id () {
    return this.path.split('/').pop()
  }

  /** @property {string} suffix - Suffix of the shmdata's ID */
  get suffix () {
    return this.path.split('_').pop()
  }

  /** @property {Map<string, string|number|boolean>} capsMap - All properties of capabilities */
  get capsMap () {
    return this.capabilities.properties
  }

  /**
   * @property {string} mediaType - Media type of the shmdata capabilities
   * @see [Media Types documentation]{@link https://gstreamer.freedesktop.org/documentation/plugin-development/advanced/media-types.html}
   */
  get mediaType () {
    return this.capabilities.mediaType
  }

  /** @property {string} callerName - Name of the caller that produces the shmdata */
  get callerName () {
    return this.uri ? this.uri.split('@')[0] : null
  }

  /**
   * Creates a Shmdata model from a JSON object
   * @static
   * @param {string} path - Path of the shmdata
   * @param {Object} json - JSON object that modelizes a shmdata
   * @returns {models.Shmdata}
   */
  static fromJSON (path, json) {
    const { caps, category, uri } = json
    return new Shmdata(path, caps, category, uri)
  }

  /** Exports a Shmdata model to a JSON object */
  toJSON () {
    const { caps, category } = this
    return { caps, category }
  }

  /**
   * Creates multiple shmdatas from a quididty tree
   * @static
   * @param {Object} tree - Tree of a quiddity
   * @returns {models.Shmdata[]}
   */
  static fromTree (tree) {
    const allShmdatas = []
    const { writer, reader } = tree

    if (writer) {
      for (const path of Object.keys(writer)) {
        allShmdatas.push(Shmdata.fromJSON(path, writer[path]))
      }
    }

    if (reader) {
      for (const path of Object.keys(reader)) {
        allShmdatas.push(Shmdata.fromJSON(path, reader[path]))
      }
    }

    return allShmdatas
  }
}

export default Shmdata

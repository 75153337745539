import { observable, action, computed, makeObservable } from 'mobx'

import { io } from 'socket.io-client'

import DEFAULT_CLIENT_CONFIG from '@assets/json/config.json'

import { logger } from '@utils/logger'
import populateAPIs from '@api'
import { toString, isStringEmpty } from '@utils/stringTools'

import packageJson from '~/package.json'

import { v4 as uuidv4 } from 'uuid'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the SocketStore
 * @memberof stores.SocketStore
 */
export const LOG = logger.child({ store: 'SocketStore' })

/**
 * @constant {number} SOCKET_RECONNECTION_DELAY - Time to wait before attempting a new reconnection in milliseconds
 * @memberof stores.SocketStore
 */
const SOCKET_RECONNECTION_DELAY = 100

/**
 * @constant {number} SOCKET_RECONNECTION_DELAY_MAX - Maximum amount of time to wait between reconnections in milliseconds
 * @memberof stores.SocketStore
 */
const SOCKET_RECONNECTION_DELAY_MAX = 5000

/**
 * @constant {number} SOCKET_RECONNECTION_ATTEMPTS - Number of reconnection attempts to execute
 * @memberof stores.SocketStore
 */
const SOCKET_RECONNECTION_ATTEMPTS = 1

/**
 * @classdesc Manages SocketIO connections with Switcher server
 * @memberof stores
 */
class SocketStore {
  /**
   * @property {string} host - URL or IP address of the Switcher instance.
   * The default value will be the value of `hostIP`, defined in `assets/json/config.json`.
   */
  host = toString(DEFAULT_CLIENT_CONFIG.hostIP)

  /**
   * @property {string} port - Port of the Switcher instance.
   * The default value will be the value of `port`, defined in `assets/json/config.json`.
   */
  port = toString(DEFAULT_CLIENT_CONFIG.port)

  /** @property {Function} onConnectionCb - Function called when the socket is successfully connected */
  onConnectionCb = null

  /** @property {Function} onDisconnectionCb - Function called on the socket's disconnection */
  onDisconnectionCb = null

  /** @property {Function} onReconnectionFailedCb - Function called when all (re)connection attempts fail */
  onReconnectionFailedCb = null

  /** @property {?external:socketIO/Socket} candidateSocket - Socket to try and connect with. Is set to `null` when the connection succeeds */
  candidateSocket = null

  /** @property {?external:socketIO/Socket} activeSocket - Current socket communicating with the Switcher instance. Is `null` until a successful connection is established */
  activeSocket = null

  /** @property {Object} APIs - All APIs configured with the active socket */
  APIs = {}

  /** @property {string} sessionId - The unique id that is generated in each session */
  sessionId = null

  /**
   * Instantiates a new socket store
   * @param {external:socketIO/Socket} initSocket - Already connected socket
   */
  constructor (initSocket) {
    makeObservable(this, {
      host: observable,
      port: observable,
      activeSocket: observable,
      sessionId: observable,
      hasActiveSocket: computed,
      endpoint: computed,
      activeHost: computed,
      activePort: computed,
      hasValidEndpoint: computed,
      setSessionId: action,
      setRemote: action,
      setPort: action,
      setHost: action,
      setActiveSocket: action
    })

    // This is used for unit tests
    if (initSocket) {
      this.activeSocket = initSocket
      this.APIs = populateAPIs(this.activeSocket)
    }
  }

  /** @property {boolean} hasActiveSocket - Checks if the active socket exists */
  get hasActiveSocket () {
    return this.activeSocket !== null
  }

  /**
   * @property {string} [endpoint=0.0.0.0:8000] - Endpoint of the Switcher server.
   * This endpoint can be defined as an URL argument like `http://scenic-app.ca?endpoint=1.1.1.1:5000`
   */
  get endpoint () {
    let endpoint = `${this.host}:${this.port}`

    const currentURL = new URL(window.location)
    const urlParameters = currentURL.searchParams
    const urlEndpoint = urlParameters.get('endpoint')

    if (urlEndpoint) {
      endpoint = urlEndpoint
    }

    return endpoint
  }

  /** @property {string} activeHost - Active host IP for the Switcher server */
  get activeHost () {
    return this.endpoint.split(':')[0]
  }

  /** @property {string} activePort - Active port for the Switcher server */
  get activePort () {
    return this.endpoint.split(':')[1]
  }

  /** @property {boolean} hasActiveAPIs - Check if the store has active APIs */
  get hasActiveAPIs () {
    return Object.keys(this.APIs).length > 0
  }

  /**
   * Generates a unique id for each session. This id will be set as the name of the session's log file
   * @returns {string} Returns the unique session id
   */
  makeSessionId () {
    const id = uuidv4()
    const sessionId = `${this.host}_${id}`
    return sessionId
  }

  /**
   * Checks if the endpoint is valid
   * @returns {boolean} Returns true if the endpoint is valid
   */
  get hasValidEndpoint () {
    return !isStringEmpty(this.host) && !isStringEmpty(this.port)
  }

  /**
   * Tries to establish a Websocket connect with a Switcher instance.
   *
   * `onConnectionCb`, `onDisconnectionCb` and `onReconnectionFailedCb` callbacks will be called when
   * their associated Socket.IO's lifecycle events are triggered.
   * @see https://socket.io/docs/client-connection-lifecycle/
   */
  applyConnection () {
    if (this.activeSocket) this.activeSocket = null

    this.candidateSocket = io(`${this.endpoint}/switcherio`, {
      reconnectionDelay: SOCKET_RECONNECTION_DELAY,
      reconnectionDelayMax: SOCKET_RECONNECTION_DELAY_MAX,
      reconnectionAttempts: SOCKET_RECONNECTION_ATTEMPTS,
      query: {
        version: packageJson.version
      }
    })

    this.candidateSocket.io.on('reconnect_failed', () => {
      LOG.warn({
        title: 'Connection to Switcher failed',
        notification: true,
        msg: `Failed to connect with ${this.endpoint}`
      })

      if (this.onReconnectionFailedCb) {
        this.onReconnectionFailedCb()
      }
    })

    this.candidateSocket.on(
      'connect',
      () => this.handleSocketConnection()
    )
  }

  /** Handle the socket connection */
  handleSocketConnection () {
    LOG.info(`Socket is connected with ${this.host}:${this.port}`)

    this.setSessionId(this.makeSessionId())
    this.setActiveSocket(this.candidateSocket)

    this.activeSocket.on(
      'disconnect',
      (reason) => this.handleSocketDisconnection(reason)
    )

    this.onConnectionCb()
  }

  /** Handle the socket disconnection */
  handleSocketDisconnection (reason) {
    LOG.info({
      msg: `Socket is disconnected from ${this.host}:${this.port} with reason ${reason}`
    })

    this.setActiveSocket(null)
    this.onDisconnectionCb()
  }

  /**
   * Sets the session id for the current socket
   * @param {string} sessionId - Unique id of the current session
   */
  setSessionId (sessionId) {
    this.sessionId = sessionId
  }

  /**
   * Sets the remote host to connect with
   * @param {string} host - URL or IP address of the Switcher instance
   * @param {string} port - Port of the Switcher instance
   */
  setRemote (host, port) {
    this.host = host
    this.port = port
  }

  /**
   * Sets the remote port
   * @param {string} port - Port of the Switcher instance
   */
  setPort (port) {
    this.port = port
  }

  /**
   * Sets the remote host
   * @param {string} host - URL or IP address of the Switcher instance
   */
  setHost (host) {
    this.host = host
  }

  /**
   * Sets the active socket
   * @param {external:socketIO/Socket} socket - The connected socket
   */
  setActiveSocket (socket) {
    this.activeSocket = socket

    this.APIs = socket ? populateAPIs(socket) : {}

    if (socket) {
      LOG.debug({
        msg: 'All APIs are configured',
        apis: this.APIs
      })
    }
  }

  /**
   * Sets the callback function called when the socket is successfully connected
   * @param {Function} callback - Function triggered when a 'connect' Socket.IO event is emitted
   */
  onConnection (callback) {
    this.onConnectionCb = callback
  }

  /**
   * Sets the callback function called when the socket is disconnected
   * @param {Function} callback - Function triggered when a 'disconnect' Socket.IO event is emitted
   */
  onDisconnection (callback) {
    this.onDisconnectionCb = callback
  }

  /**
   * Sets the callback function called when the all of the socket's (re)connection attempts fail
   * @param {Function} callback - Function triggered when a 'reconnect_failed' Socket.IO event is emitted
   */
  onReconnectionFailed (callback) {
    this.onReconnectionFailedCb = callback
  }
}

export default SocketStore

import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import StatusEnum from '@models/common/StatusEnum'

import NotificationOverlay from '@components/panels/NotificationOverlay'
import SettingTabBar from '@components/bars/SettingTabBar'
import { ConfirmButton, ResetButton } from '@components/common/Buttons'

import {
  SipServerField,
  SipUserField,
  SipPasswordField,
  SipPortField,
  TurnServerField,
  TurnUserField,
  TurnPasswordField,
  StunServerField,
  SameStunTurnLoginField,
  SipDestinationPortField
} from '@components/fields/SipFields'

import { LanguageSelectField, DisplayThumbnailsField, DisplayEncodersField } from '@components/fields/SettingFields'
import '@styles/pages/SettingsPage.scss'

import { Context, Layout } from '@sat-mtl/ui-components'

const { ThemeContext } = Context
const { FlexColumn, FlexRow } = Layout

/**
 * The section about the general settings
 * @memberof module:components/pages
 * @selector `#GeneralSettingsSection`
 * @returns {external:react/Component} All general settings
 */
function GeneralSettingsSection () {
  const { t } = useTranslation()

  return (
    <section id='GeneralSettingsSection'>
      <h1>
        {t('General settings')}
      </h1>
      <form onSubmit={() => false}>
        <LanguageSelectField />
        <DisplayThumbnailsField />
        <DisplayEncodersField />
      </form>
    </section>
  )
}

/**
 * Subsection that displays all advanced SIP settings
 * @memberof module:components/pages
 * @selector `#AdvancedSipSettingsSection`
 * @property {stores.SipCredentialStore} sipCredentialStore - Store all SIP credentials
 * @type {external:mobx-react/ObserverComponent}
 */
const AdvancedSipSettingsSection = observer(({ sipCredentialStore }) => {
  const { t } = useTranslation()
  const isSameStunTurnLogin = sipCredentialStore.credentials.get('isSameStunTurnLogin')

  return (
    <section id='AdvancedSipSettingsSection'>
      <h2>
        {t('STUN/TURN parameters')}
      </h2>
      <form onSubmit={() => false}>
        <SameStunTurnLoginField
          isSameStunTurnLogin={isSameStunTurnLogin}
          onToggle={toggle => sipCredentialStore.applySameStunTurnLoginToggle(toggle)}
        />
        {!isSameStunTurnLogin && (
          <>
            <TurnServerField
              value={sipCredentialStore.credentials.get('turnServer')}
              error={sipCredentialStore.errors.get('turnServer')}
              onChange={value => sipCredentialStore.setCredential('turnServer', value)}
              onCheck={() => sipCredentialStore.applyCredentialValidation('turnServer')}
              disabled={isSameStunTurnLogin}
            />
            <TurnUserField
              value={sipCredentialStore.credentials.get('turnUser')}
              error={sipCredentialStore.errors.get('turnUser')}
              onChange={value => sipCredentialStore.setCredential('turnUser', value)}
              onCheck={() => sipCredentialStore.applyCredentialValidation('turnUser')}
              disabled={isSameStunTurnLogin}
            />
            <TurnPasswordField
              value={sipCredentialStore.credentials.get('turnPassword')}
              error={sipCredentialStore.errors.get('turnPassword')}
              onChange={value => sipCredentialStore.setCredential('turnPassword', value)}
              onCheck={() => sipCredentialStore.applyCredentialValidation('turnPassword')}
              disabled={isSameStunTurnLogin}
            />
            <StunServerField
              value={sipCredentialStore.credentials.get('stunServer')}
              error={sipCredentialStore.errors.get('stunServer')}
              onChange={value => sipCredentialStore.setCredential('stunServer', value)}
              onCheck={() => sipCredentialStore.applyCredentialValidation('stunServer')}
              disabled={isSameStunTurnLogin}
            />
          </>
        )}
      </form>
    </section>
  )
})

/**
 * Subsection that displays all required SIP settings
 * @memberof module:components/pages
 * @selector `#RequiredSipSettingsSection`
 * @property {stores.SipCredentialStore} sipCredentialStore - Store all SIP credentials
 * @type {external:mobx-react/ObserverComponent}
 */
const RequiredSipSettingsSection = observer(({ sipCredentialStore }) => {
  const { t } = useTranslation()

  return (
    <section id='RequiredSipSettingsSection'>
      <h2>
        {t('SIP parameters')}
      </h2>
      <form onSubmit={() => false}>
        <SipServerField
          value={sipCredentialStore.credentials.get('sipServer')}
          error={sipCredentialStore.errors.get('sipServer')}
          onChange={value => sipCredentialStore.setCredential('sipServer', value)}
          onCheck={() => sipCredentialStore.applyCredentialValidation('sipServer')}
        />
        <SipPortField
          value={sipCredentialStore.credentials.get('sipPort')}
          error={sipCredentialStore.errors.get('sipPort')}
          onChange={value => sipCredentialStore.setCredential('sipPort', value)}
          onCheck={() => sipCredentialStore.applyCredentialValidation('sipPort')}
        />
        <SipDestinationPortField
          value={sipCredentialStore.credentials.get('sipDestinationPort')}
          error={sipCredentialStore.errors.get('sipDestinationPort')}
          onChange={value => sipCredentialStore.setCredential('sipDestinationPort', value)}
          onCheck={() => sipCredentialStore.applyCredentialValidation('sipDestinationPort')}
        />
        <SipUserField
          value={sipCredentialStore.credentials.get('sipUser')}
          error={sipCredentialStore.errors.get('sipUser')}
          onChange={value => sipCredentialStore.setCredential('sipUser', value)}
          onCheck={() => sipCredentialStore.applyCredentialValidation('sipUser')}
        />
        <SipPasswordField
          value={sipCredentialStore.credentials.get('sipPassword')}
          error={sipCredentialStore.errors.get('sipPassword')}
          onChange={value => sipCredentialStore.setCredential('sipPassword', value)}
          onCheck={() => sipCredentialStore.applyCredentialValidation('sipPassword')}
        />
      </form>
    </section>
  )
})

/**
 * Subsection that provides all global SIP actions
 * @memberof module:components/pages
 * @selector `#SipSettingsControlSection`
 * @property {stores.SipStore} sipStore - Manage all SIP actions
 * @property {stores.SipCredentialStore} sipCredentialStore - Store all SIP credentials
 * @type {external:mobx-react/ObserverComponent}
 */
const SipSettingsControlSection = observer(({ sipStore, sipCredentialStore }) => {
  const { t } = useTranslation()

  return (
    <section id='SipSettingsControlSection'>
      <FlexRow className='SipSettingControl' justifyContent='space-around'>
        <ResetButton onReset={() => sipCredentialStore.cleanCredentials()} />
        {sipStore.isConnected && (
          <ConfirmButton
            status={StatusEnum.DANGER}
            onConfirm={() => sipStore.applyLogout()}
          >
            {t('Log out')}
          </ConfirmButton>
        )}
        {!sipStore.isConnected && (
          <ConfirmButton
            disabled={!sipCredentialStore.areCredentialsValid}
            onConfirm={() => sipStore.applyLogin(sipCredentialStore.sipCredentials)}
          >
            {t('Log in')}
          </ConfirmButton>
        )}
      </FlexRow>
    </section>
  )
})

/**
 * Section for all SIP settings
 * @memberof module:components/pages
 * @selector `#SipSettingsSection`
 * @property {stores.SipStore} sipStore - Manage all SIP actions
 * @property {stores.SipCredentialStore} sipCredentialStore - Store all SIP credentials
 * @returns {external:react/Component} A section for all SIP settings
 */
function SipSettingsSection ({ sipStore, sipCredentialStore }) {
  const { t } = useTranslation()

  return (
    <section id='SipSettingsSection'>
      <h1>
        {t('SIP settings')}
      </h1>
      <FlexRow columnGap={64}>
        <FlexColumn>
          <RequiredSipSettingsSection
            sipCredentialStore={sipCredentialStore}
          />
          <SipSettingsControlSection
            sipCredentialStore={sipCredentialStore}
            sipStore={sipStore}
          />
        </FlexColumn>
        <AdvancedSipSettingsSection
          sipCredentialStore={sipCredentialStore}
        />
      </FlexRow>
    </section>
  )
}

/**
 * Page that displays all the settings of the app
 * @memberof module:components/pages
 * @selector `#SettingsPage`
 * @property {store.SettingStore} settingStore - Store all settings
 * @property {stores.SipStore} sipStore - Manage all SIP actions
 * @property {stores.SipCredentialStore} sipCredentialStore - Store all SIP credentials
 * @type {external:mobx-react/ObserverComponent}
 */
const SettingsPage = observer(({ settingStore, sipStore, sipCredentialStore }) => {
  const theme = useContext(ThemeContext)

  return (
    <div id='SettingsPage' className={`page-${theme}`}>
      <nav>
        <SettingTabBar settingStore={settingStore} />
      </nav>
      <main>
        {settingStore.activeGroupId === 'general' && (
          <GeneralSettingsSection
            key='general'
            settingStore={settingStore}
          />
        )}
        {settingStore.activeGroupId === 'sip' && (
          <SipSettingsSection
            key='sip'
            settingStore={settingStore}
            sipStore={sipStore}
            sipCredentialStore={sipCredentialStore}
          />
        )}
      </main>
      <NotificationOverlay />
    </div>
  )
})

export default SettingsPage

/**
 * Enum for SIP buddies' send_status property
 * @readonly
 * @memberof models
 * @enum {string}
 */
export const SendStatusEnum = Object.freeze({
  DISCONNECTED: 'disconnected',
  CONNECTING: 'connecting',
  CALLING: 'calling',
  UNKNOWN: 'unknown'
})

/**
 * Enum for SIP buddies' recv_status property
 * @readonly
 * @memberof models
 * @enum {string}
 */
export const RecvStatusEnum = Object.freeze({
  DISCONNECTED: 'disconnected',
  CONNECTING: 'connecting',
  RECEIVING: 'receiving',
  UNKNOWN: 'unknown'
})

/**
 * Enum for SIP buddies' subscription_state property
 * @readonly
 * @memberof models
 * @enum {string}
 */
export const SubStateEnum = Object.freeze({
  NULL: 'NULL',
  SENT: 'SENT',
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  TERMINATED: 'TERMINATED',
  UNKNOWN: 'UNKNOWN'
})

/**
 * Enum for SIP buddies' status property
 * @readonly
 * @memberof models
 * @enum {string}
 */
export const BuddyStatusEnum = Object.freeze({
  ONLINE: 'online',
  OFFLINE: 'offline',
  AWAY: 'away',
  BUSY: 'busy',
  UNKNOWN: 'unknown'
})

import { observable, computed, action, reaction, makeObservable } from 'mobx'
import Ajv from 'ajv'
import merge from 'deepmerge'

// @todo [swIO] Switch menu for debugging purposes
import DEFAULT_MENUS from '@assets/json/menus.default.json'
import DEFAULT_BUNDLES from '@assets/json/bundles.default.json'
import DEFAULT_SCENIC from '@assets/json/scenic.default.json'

import InitStateEnum from '@models/common/InitStateEnum'
import ConfigEnum, { fromPath } from '@models/common/ConfigEnum'

import ConnectionSchema from '@models/schemas/connection.schema.json'
import Contact from '@models/schemas/contact.schema.json'
import ContactList from '@models/schemas/contactList.schema.json'
import InitQuidditySchema from '@models/schemas/initQuiddity.schema.json'
import MenuItemSchema from '@models/schemas/menuItem.schema.json'
import MenusSchema from '@models/schemas/menus.schema.json'
import SceneSchema from '@models/schemas/scene.schema.json'
import ScenicSchema from '@models/schemas/scenic.schema.json'
import SubMenuSchema from '@models/schemas/subMenu.schema.json'
import UserTreeSchema from '@models/schemas/userTree.schema.json'

import Store from '@stores/Store'
import { USER_TREE_CONFIG } from '@models/quiddity/specialQuiddities'
import Quiddity from '@models/quiddity/Quiddity'

import { logger } from '@utils/logger'
import { isObject } from '@utils/objectTools'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the ConfigStore
 * @memberof stores.ConfigStore
 */
export const LOG = logger.child({ store: 'ConfigStore' })

/**
 * @constant {string} SCENIC_CONFIG_PATH - Path of the user-defined Scenic configuration
 * @memberof stores.ConfigStore
 */
export const SCENIC_CONFIG_PATH = '~/.config/scenic/scenic.json'

/**
 * @classdesc Stores app configuration
 * @extends stores.Store
 * @memberof stores
 */
class ConfigStore extends Store {
  defaultScenic = DEFAULT_SCENIC
  defaultMenus = DEFAULT_MENUS
  defaultBundles = DEFAULT_BUNDLES

  /** @property {Object} userContacts - User-defined contact list fetched from the server */
  userContacts = null

  /** @property {Object} userMenus - User-defined menu configuration fetched from the server */
  userMenus = null

  /** @property {Object} userBundles - User-defined bundle configuration fetched from the server */
  userBundles = null

  /** @property {Object} userScenic - User-defined Scenic configuration fetched from the server */
  userScenic = null

  /** @property {Object} scenicConfiguration - Global Scenic configuration, created by merging the default and user-defined configurations */
  get scenicConfiguration () {
    let config = DEFAULT_SCENIC

    if (this.userScenic) {
      config = merge(config, this.userScenic, { arrayMerge: this.mergeScenicConfigArrays })
    }

    return config
  }

  /** @property {Object} menuConfiguration - Global menu configuration, created by merging the default and user-defined configurations */
  get menuConfiguration () {
    let config = DEFAULT_MENUS

    // Menu config is an Object with a single "customMenus" key; the value is an array containing Objects.
    // Overwrite this default array with the user-defined one. Do not attempt to merge them.
    if (this.userMenus) {
      config = this.userMenus
    }

    return config
  }

  /** @property {Object} bundleConfiguration - Global bundle configuration, created by merging the default and user-defined configurations */
  get bundleConfiguration () {
    let config = DEFAULT_BUNDLES

    // Bundle config is an Object with no arrays. Deep merge the default Object with the user-defined one.
    if (this.userBundles) {
      config = merge(config, this.userBundles)
    }

    return config
  }

  /** @property {Object} defaultUserTree - Default userTree (initial scenes and connections) for the UserTree quiddity */
  get defaultUserTree () {
    return USER_TREE_CONFIG.userTree
  }

  /** @property {module:models/quiddity.Quiddity[]} initQuiddities - All quiddities created during initialization */
  get initQuiddities () {
    return this.scenicConfiguration.initQuiddities.map(
      quid => Quiddity.fromJSON({ ...quid })
    )
  }

  /**
   * Instantiates a new ConfigStore
   * @param {stores.SocketStore} socketStore - Stores and manages the current event-driven socket
   * @constructor
   */
  constructor (socketStore) {
    super(socketStore)

    makeObservable(this, {
      userContacts: observable,
      userMenus: observable,
      userBundles: observable,
      userScenic: observable,
      scenicConfiguration: computed,
      menuConfiguration: computed,
      bundleConfiguration: computed,
      defaultUserTree: computed,
      initQuiddities: computed,
      setUserBundles: action,
      setUserMenus: action,
      setUserContacts: action,
      setUserScenic: action
    })

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )
  }

  /**
   * Initializes Store by fetching user-defined configurations and sending bundles to the server
   *
   * This store handles 4 different configuration files. It handles the user and default configurations differently for each:
   * + **scenic.json**: The user-defined configuration overwrites the default one, *EXCEPT* the `initQuiddities` array.
   *   + The default and user-defined `initQuiddities` arrays will be merged together. If the same quiddity definition (`class` and `id` values are identical) is present in both the default and user-defined `initQuiddities` arrays, both definitions will be deep-merged together.
   * + **bundles.json**: The default and user-defined configurations are deep-merged together.
   * + **menus.json**: The user-defined configuration overwrites the default one; no merging is done.
   * + **contacts.json**: No default contact list exists, so the user-defined list is used as is. No merging is done.
   * @returns {boolean} Flags true if store is well initialized
   * @see [Deepmerge package documentation]{@link https://www.npmjs.com/package/deepmerge}
   * @async
   */
  async initialize () {
    const { INITIALIZED, INITIALIZING, NOT_INITIALIZED } = InitStateEnum

    if (!this.isNotInitialized()) return false

    this.setInitState(INITIALIZING)

    try {
      const paths = await this.fetchConfigurationPaths()

      for (const path of paths) {
        const configName = fromPath(path)

        switch (configName) {
          case ConfigEnum.SCENIC: {
            await this.initializeScenicConfig(configName)
            break
          }
          case ConfigEnum.MENUS: {
            await this.initializeMenusConfig(configName, path)
            break
          }
          case ConfigEnum.CONTACTS: {
            await this.initializeContactsConfig(configName, path)
            break
          }
          case ConfigEnum.BUNDLES: {
            await this.initializeBundlesConfig(configName, path)
            break
          }
          default: {
            LOG.warn({
              msg: 'Unknown configuration is detected but ignored',
              configPath: path
            })
          }
        }
      }

      // Send bundles to server, whether or not user bundles exists
      await this.applySendBundlesToServer(this.bundleConfiguration)

      this.setInitState(INITIALIZED)

      LOG.info({
        msg: 'ConfigStore is initialized'
      })
    } catch (error) {
      this.setInitState(NOT_INITIALIZED)

      LOG.error({
        msg: 'Error while loading configuration',
        error: error
      })
    }

    return this.isInitialized()
  }

  /**
   * Initializes the configuration of scenic
   * @param {string} configName - The name of the scenic configuration
   * @throws {Error}
   */
  async initializeScenicConfig (configName) {
    const scenicConfig = await this.fetchConfiguration(configName)

    if (this.isScenicSchemaValid(scenicConfig)) {
      this.setUserScenic(scenicConfig)
    } else {
      LOG.warn({
        msg: 'Scenic configuration is invalid. Falling back to default configuration.',
        config: scenicConfig
      })
    }
  }

  /**
   * Initializes the configuration of scenic menus
   * @param {string} configName - The name of the menus
   * @param {string} configPath - The path of the configuration
   * @throws {Error}
   */
  async initializeMenusConfig (configName, configPath) {
    const menusConfig = await this.fetchConfiguration(configName)

    if (this.isMenuSchemaValid(menusConfig)) {
      this.setUserMenus(menusConfig)
    } else {
      LOG.warn({
        msg: 'Menu configuration is invalid. Falling back to default menu configuration.',
        configPath: configPath
      })
    }
  }

  /**
   * Initializes the configuration of contacts
   * @param {string} configName - The name of the contacts configuration
   * @param {string} configPath - The path of the configuration
   * @throws {Error}
   */
  async initializeContactsConfig (configName, configPath) {
    const contactConfig = await this.fetchConfiguration(configName)

    if (this.isContactSchemaValid(contactConfig)) {
      this.setUserContacts(contactConfig)
    } else {
      LOG.warn({
        msg: 'Contact list is invalid. Ignoring this configuration.',
        configPath: configPath
      })
    }
  }

  /**
   * Initializes the configuration of bundles
   * Bundles are not validated (this is a Switcher-specific file). Use at your own risks.
   * @param {string} configName - The name of the bundles configuration
   * @throws {Error}
   */
  async initializeBundlesConfig (configName) {
    const bundlesConfig = await this.fetchConfiguration(configName)

    if (bundlesConfig) {
      this.setUserBundles(bundlesConfig)
    }
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()
  }

  /**
   * Checks if a configuration validates against the scenic.schema.json schema or not
   * @param {Object} configuration - JSON configuration to validate
   * @returns {boolean} Flags true if the configuration is valid
   */
  isScenicSchemaValid (configuration) {
    const ajv = new Ajv().addSchema([
      InitQuidditySchema,
      ConnectionSchema,
      SceneSchema,
      UserTreeSchema
    ])

    const isScenicValid = ajv.validate(ScenicSchema, configuration)

    if (isScenicValid) {
      LOG.info({
        msg: 'Scenic configuration file is valid.'
      })
    } else {
      LOG.error({
        msg: 'Scenic configuration file does not validate against schema.',
        error: ajv.errorsText()
      })
    }

    return isScenicValid
  }

  /**
   * Checks if a configuration validates against the menus.schema.json schema or not
   * @param {Object} configuration - JSON configuration to validate
   * @returns {boolean} Flags true if the configuration is valid
   */
  isMenuSchemaValid (configuration) {
    const ajv = new Ajv().addSchema([MenuItemSchema, SubMenuSchema])
    const isMenusValid = ajv.validate(MenusSchema, configuration)

    if (isMenusValid) {
      LOG.info({
        msg: 'Menu configuration file is valid.'
      })
    } else {
      LOG.error({
        msg: 'Menu configuration file does not validate against schema.',
        error: ajv.errorsText()
      })
    }

    return isMenusValid
  }

  /**
   * Checks if a contact list validates against the contactList.schema.json schema or not
   * @param {Object} contacts - JSON contact list to validate
   * @returns {boolean} Flags true if JSON contact list is valid
   */
  isContactSchemaValid (contacts) {
    let isListValid = true

    if (contacts && Object.keys(contacts).length > 0) {
      const ajv = new Ajv().addSchema(Contact)
      isListValid = ajv.validate(ContactList, contacts)

      if (isListValid) {
        LOG.info({
          msg: 'Contact list is valid.'
        })
      } else {
        LOG.error({
          msg: 'Contact list does not validate against schema.',
          error: ajv.errorsText()
        })
      }
    }

    return isListValid
  }

  /**
   * Fetches user-defined extra configuration paths from switcher
   * @param {string} path - Absolute path of the configuration file to fetch on the server
   * @async
   */
  async fetchConfigurationPaths () {
    const { switcherAPI } = this.socketStore.APIs
    let paths = []

    try {
      paths = await switcherAPI.getConfigPaths()

      LOG.info({
        msg: 'Successfully fetched extra configuration paths',
        paths: paths
      })
    } catch (error) {
      LOG.error({
        msg: 'Could not fetch extra configuration paths',
        error: error.message
      })
    }

    return paths
  }

  async fetchConfiguration (configName) {
    const { switcherAPI } = this.socketStore.APIs
    let config = null

    try {
      const file = await switcherAPI.readConfig(configName)
      config = JSON.parse(file)

      LOG.info({
        msg: `Successfully fetched ${configName} configuration`,
        configName: configName,
        configFile: config
      })
    } catch (error) {
      LOG.error({
        msg: `Failed to fetch ${configName} configuration`,
        error: error.msg
      })
    }

    return config
  }

  /**
   * Checks if a quiddity is present in the initQuiddities configuration. Quiddity class and ID must BOTH match.
   * @param {string} kindId - Kind ID of the quiddity to find
   * @param {string} nickname - Name of the quiddity to find
   * @returns {?Object} Corresponding initQuiddities configuration object if it exists, null if it is not defined
   */
  findInitialConfiguration (kindId, nickname) {
    const initConfig = this.scenicConfiguration.initQuiddities
      .find(quid => quid.kindId === kindId && quid.nickname === nickname)

    return initConfig || null
  }

  /**
   * Send bundles definition to server.
   * @param {Object} bundles - JSON bundles definition to send
   * @returns {boolean} Flags true if JSON data was successfully sent
   * @async
   */
  async applySendBundlesToServer (bundles) {
    const { switcherAPI } = this.socketStore.APIs
    let isDone = false

    try {
      isDone = await switcherAPI.sendBundles(bundles)

      if (isDone) {
        LOG.info({
          msg: 'Successfully sent bundles to server',
          bundles: bundles
        })
      } else {
        LOG.error({
          msg: 'Failed to send bundles to server'
        })
      }
    } catch (error) {
      LOG.error({
        msg: 'Error while sending bundles to server',
        error: error.message
      })
    }

    return isDone
  }

  /**
   * Merge an array taken from the default 'scenic.json' configuration with an array from the user-defined configuration.
   *
   * The 'scenic.json' config is an Object with various key-value pairs. Values can be either strings or Arrays.
   * The array values are always strings, except for the 'initQuiddities' array whose values are only Objects.
   *
   * A user-defined 'initQuiddities' array must be merged with the default one.
   * If the same quiddity Object is defined in both the default and user-defined arrays ('class' and 'id' are identical), both Objects must be deep-merged together.
   *
   * For all other arrays, the default array will be overwritten by the user-defined one.
   * @param {Object[]|string[]} defaultArray - Array from the default Scenic configuration
   * @param {Object[]|string[]} userArray - Array from the user Scenic configuration
   * @returns {Object[]|string[]} Array resulting from the merge of the defaultArray and userArray
   */
  mergeScenicConfigArrays (defaultArray, userArray) {
    const findQuiddity = (quid, item) => {
      return quid.id === item.id && quid.kindId === item.kindId
    }

    let mergedArray = []

    // Merge 'initQuiddities' arrays together.
    if (defaultArray.every(isObject) && userArray.every(isObject)) {
      defaultArray.forEach(item => {
        const index = userArray.findIndex(q => findQuiddity(q, item))
        if (index < 0) {
          mergedArray.push(item)
        } else {
          mergedArray.push(merge(item, userArray[index]))
        }
      })

      userArray.forEach(item => {
        if (defaultArray.findIndex(q => findQuiddity(q, item)) < 0) {
          mergedArray.push(item)
        }
      })
    } else {
      // For all other arrays, overwrite the first array with the second one
      mergedArray = userArray
    }

    return mergedArray
  }

  /**
   * Sets new user menu configuration
   * @param {Object} json - User-defined JSON menu configuration
   */
  setUserBundles (json) {
    this.userBundles = json
  }

  /**
   * Sets new user menu configuration
   * @param {Object} json - User-defined JSON menu configuration
   */
  setUserMenus (json) {
    this.userMenus = json
  }

  /**
   * Sets new user contact list
   * @param {Object} json - User-defined JSON contact list
   */
  setUserContacts (json) {
    this.userContacts = json
  }

  /**
   * Sets new user Scenic configuration
   * @param {Object} json - User-defined JSON configuration
   */
  setUserScenic (json) {
    this.userScenic = json
  }

  /**
   * Cleans up ConfigStore
   */
  clear () {
    const { NOT_INITIALIZED } = InitStateEnum
    this.setInitState(NOT_INITIALIZED)

    if (this.userScenic) {
      this.setUserScenic(null)
    }

    if (this.userMenus) {
      this.setUserMenus(null)
    }

    if (this.userBundles) {
      this.setUserBundles(null)
    }

    if (this.userContacts) {
      this.setUserContacts(null)
    }

    LOG.info({
      msg: 'Successfully cleared config store'
    })
  }
}

export default ConfigStore

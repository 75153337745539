import { observable, action, reaction, computed, makeObservable } from 'mobx'

import Contact from '@models/sip/Contact'

import Store from '@stores/Store'
import ContactStore, { BUDDIES_TREE_REGEX } from '@stores/sip/ContactStore'
import StatusEnum, { fromSendStatus } from '@models/common/StatusEnum'
import { SendStatusEnum, BuddyStatusEnum } from '@models/sip/SipEnums'
import SipStore from '@stores/sip/SipStore'
import QuiddityStatusStore from '@stores/quiddity/QuiddityStatusStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the ContactStatusStore
 * @memberof stores.ContactStatusStore
 */
export const LOG = logger.child({ store: 'ContactStatusStore' })

/**
 * @classdesc Store all contact statuses
 * @extends stores.Store
 * @memberof stores
 */
class ContactStatusStore extends Store {
  /** @property {enums.StatusEnum} selfStatus - Status of the logged SIP account */
  selfStatus = StatusEnum.INACTIVE

  /** @property {Map<string, enums.BuddyStatusEnum>} buddyStatuses - All buddy statuses for each contact ID */
  buddyStatuses = new Map()

  /** @property {Map<string, enums.RecvStatusEnum>} receivingStatuses - All receiving statuses for each contact ID */
  receivingStatuses = new Map()

  /** @property {Map<string, enums.SendStatusEnum>} sendingStatuses - All sending statuses for each contact ID */
  sendingStatuses = new Map()

  /** @property {number} calledContacts - All contact IDs that are actively called */
  get calledContacts () {
    return Array.from(this.sendingStatuses)
      .filter(([, value]) => value === SendStatusEnum.CALLING)
      .map(([key]) => key)
  }

  /** @property {Map<string, module:models/sip.Contact>} onlineContacts - All contacts with the buddystatus ONLINE hashed by ID */
  get onlineContacts () {
    const onlineContacts = new Map()
    for (const [, contact] of this.contactStore.contacts) {
      if (contact.status === BuddyStatusEnum.ONLINE) {
        onlineContacts.set(contact.id, contact)
      }
    }
    return onlineContacts
  }

  /** @property {Map<string, module:models/sip.Contact>} unavailableContacts - All contacts with a buddyStatus other than ONLINE hashed by ID */
  get unavailableContacts () {
    const unavailableContacts = new Map()
    for (const [, contact] of this.contactStore.contacts) {
      if (contact.status !== BuddyStatusEnum.ONLINE) {
        unavailableContacts.set(contact.id, contact)
      }
    }
    return unavailableContacts
  }

  /** @property {Map<string, module:models/sip.Contact>} sortedContacts - All contacts with ONLINE contacts first and OFFLINE after them */
  get sortedContacts () {
    return new Map([...this.onlineContacts, ...this.unavailableContacts])
  }

  /**
   * Instantiates a new ContactStatusStore
   * @param {stores.SocketStore} socketStore - The socket manager
   * @param {stores.SipStore} sipStore - Manage the SIP quiddity
   * @param {stores.ContactStore} contactStore - Manage all the SIP contacts
   * @param {stores.QuiddityStatusStore} quiddityStatusStore - Manage all statuses of quiddities
   * @throws {TypeError} Throws an error if the required stores are missing
   * @constructor
   */
  constructor (socketStore, sipStore, contactStore, quiddityStore, quiddityStatusStore) {
    super(socketStore)

    makeObservable(this, {
      selfStatus: observable,
      buddyStatuses: observable,
      receivingStatuses: observable,
      sendingStatuses: observable,
      calledContacts: computed,
      onlineContacts: computed,
      unavailableContacts: computed,
      sortedContacts: computed,
      setSelfStatus: action,
      setBuddyStatus: action,
      setReceivingStatus: action,
      setSendingStatus: action,
      removeStatuses: action,
      clear: action
    })

    if (sipStore instanceof SipStore) {
      this.sipStore = sipStore
    } else {
      throw new TypeError('ContactStatusStore requires a SipStore')
    }

    if (contactStore instanceof ContactStore) {
      this.contactStore = contactStore
    } else {
      throw new TypeError('ContactStatusStore requires a ContactStore')
    }

    if (quiddityStatusStore instanceof QuiddityStatusStore) {
      this.quiddityStatusStore = quiddityStatusStore
    } else {
      throw new TypeError('QuiddityStatusStore requires a ContactStore')
    }

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new TypeError('ContactStatusStore requires a QuiddityStore')
    }

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )

    reaction(
      () => this.sipStore.isConnected,
      () => this.handleSipRegistration()
    )

    reaction(
      () => Array.from(this.contactStore.contacts.values()),
      () => this.handleContactStatusesUpdate()
    )
  }

  /** Handles the SIP registration */
  handleSipRegistration () {
    const { isConnected } = this.sipStore

    if (isConnected) {
      this.setSelfStatus(StatusEnum.ACTIVE)
    } else {
      this.setSelfStatus(StatusEnum.DANGER)
    }
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()

    if (socket && this.socketStore.hasActiveAPIs) {
      const { infoTreeAPI } = this.socketStore.APIs

      infoTreeAPI.onGrafted(
        (quidId, path, value) => this.handleBuddyUpdate(path, value),
        quidId => quidId === this.sipStore.sipId,
        path => Array.isArray(path.match(BUDDIES_TREE_REGEX))
      )
    }
  }

  /**
   * Handles the update of a buddy model
   * @param {string} path - Path of the updated JSON
   * @param {Object} value - Updated value as a JSON object
   */
  handleBuddyUpdate (path, value) {
    // Ignore 'subscription_state=SENT' event. This event does not have 'uri' key needed to recreate Contact and
    // does not update buddy presence.
    if (value?.subscription_state === 'SENT') { return }

    const [, buddyId] = path.match(BUDDIES_TREE_REGEX)
    try {
      const contact = Contact.fromJSON({ id: buddyId, ...value })

      if (this.contactStore.contacts.has(contact.id)) {
        this.setBuddyStatus(buddyId, contact.status)
        this.setReceivingStatus(buddyId, contact.recvStatus)
        this.setSendingStatus(buddyId, contact.sendStatus)
      } else {
        LOG.warn({
          msg: 'Failed to update a contact status',
          reasonWhy: 'The updated contact is not part from the contactStore',
          path: value,
          value: value
        })
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to update a contact status',
        path: path,
        value: value,
        error: error.message
      })
    }
  }

  /** Handles all contacts statuses updates */
  handleContactStatusesUpdate () {
    this.populateContactStatuses()
    this.cleanContactStatuses()
  }

  /** Populates the status for all contacts */
  populateContactStatuses () {
    for (const [buddyId, contact] of this.contactStore.contacts) {
      if (!this.buddyStatuses.has(buddyId)) {
        this.setBuddyStatus(buddyId, contact.status)
        this.setReceivingStatus(buddyId, contact.recvStatus)
        this.setSendingStatus(buddyId, contact.sendStatus)
      }
    }
  }

  /** Cleans statuses for all removed contacts */
  cleanContactStatuses () {
    for (const [buddyId] of this.buddyStatuses) {
      if (!this.contactStore.contacts.has(buddyId)) {
        this.removeStatuses(buddyId)
      }
    }
  }

  /**
   * Gets the current status of the destination entry
   * @param {models.MatrixEntry} entry - A matrix entry
   * @returns {enums.StatusEnum} Status of the quiddity
   */
  getEntryStatus (entry) {
    let status = StatusEnum.INACTIVE

    if (!entry.isContact) {
      status = this.quiddityStatusStore.destinationStatuses.get(entry.quiddityId)
    } else {
      status = fromSendStatus(this.sendingStatuses.get(entry.contactId))
    }

    return status
  }

  /**
   * Sets the status of the registered account
   * @param {models.StatusEnum} status - Status of the logged SIP account
   */
  setSelfStatus (status) {
    if (status !== this.selfStatus) {
      this.selfStatus = status
    }
  }

  /**
   * Checks if the buddy is updated
   * @param {string} buddyId - ID of the buddy
   * @param {models.BuddyStatusEnum} buddyStatus - New status of the buddy
   * @returns {boolean} Returns true if the status has been updated
   */
  isBuddyStatusUpdated (buddyId, buddyStatus) {
    return this.buddyStatuses.get(buddyId) !== buddyStatus
  }

  /**
   * Sets the status of a buddy
   * @param {string} buddyId - ID of the buddy
   * @param {models.BuddyStatusEnum} buddyStatus - New status of the buddy
   */
  setBuddyStatus (buddyId, buddyStatus) {
    if (this.isBuddyStatusUpdated(buddyId, buddyStatus)) {
      this.buddyStatuses.set(buddyId, buddyStatus)
      // this is probably not the right place for that but otherwise it is never updated and
      // there is an inconsistency between the states in this.buddyStatuses and this.contactStatusStore.contacts.status
      this.contactStore.updateContact(buddyId, 'status', buddyStatus)
      LOG.info({
        msg: 'Successfully set a buddy status',
        contact: buddyId,
        status: buddyStatus
      })
    }
  }

  /**
   * Sets the receiving status of a buddy
   * @param {string} buddyId - ID of the buddy
   * @param {models.RecvStatusEnum} receivingStatus - New receiving status of the buddy
   */
  setReceivingStatus (buddyId, receivingStatus) {
    if (this.receivingStatuses.get(buddyId) !== receivingStatus) {
      this.receivingStatuses.set(buddyId, receivingStatus)
      LOG.info({
        msg: 'Successfully set a receiving status',
        contact: buddyId,
        status: receivingStatus
      })
    }
  }

  /**
   * Checks if the sending status of a buddy is updated
   * @param {string} buddyId - ID of the buddy
   * @param {models.SendStatusEnum} sendingStatus - New sending status of the buddy
   * @returns {boolean} Returns true if the sending status is updated
   */
  isSendingStatusUpdated (buddyId, sendingStatus) {
    return this.sendingStatuses.get(buddyId) !== sendingStatus
  }

  /**
   * Sets the sending status of a buddy
   * @param {string} buddyId - ID of the buddy
   * @param {models.SendStatusEnum} sendingStatus - New sending status of the buddy
   */
  setSendingStatus (buddyId, sendingStatus) {
    if (this.isSendingStatusUpdated(buddyId, sendingStatus)) {
      this.sendingStatuses.set(buddyId, sendingStatus)

      LOG.info({
        msg: 'Successfully set a sending status',
        contact: buddyId,
        status: sendingStatus
      })
    }
  }

  /**
   * Removes all statuses of a buddy
   * @param {string} buddyId - ID of the buddy
   */
  removeStatuses (buddyId) {
    this.buddyStatuses.delete(buddyId)
    this.sendingStatuses.delete(buddyId)
    this.receivingStatuses.delete(buddyId)
  }

  /** Clears all stored statuses */
  clear () {
    this.selfStatus = StatusEnum.INACTIVE
    this.buddyStatuses.clear()
    this.sendingStatuses.clear()
    this.receivingStatuses.clear()
  }
}

export default ContactStatusStore

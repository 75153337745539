import API from '@api/API'

/**
 * @classdesc API for managing the user_tree API
 * @memberof module:api
 * @extends module:api.API
 */
class UserTreeAPI extends API {
  /**
   * Gets the user tree of a quiddity with its name
   * @param {string} quidId - ID of the quiddity
   * @param {string} [treePath] - Path of the tree to query
   * @async
   */
  get (quidId, treePath) {
    return new Promise((resolve, reject) => {
      this.socket.emit('user_tree_get', quidId, treePath, (error, result) => {
        if (error) reject(error)
        if (!result || result === 'null' || result === 'None') resolve({})
        if (result) resolve(result)
      })
    })
  }

  /**
   * Grafts the userData of a quiddity
   * @param {string} quidId - ID of the quiddity
   * @param {string} path - Object path of the userData
   * @param {(string|number)} data - Value of the userData to graft
   * @async
   */
  graft (quidId, path, data) {
    return new Promise((resolve, reject) => {
      this.socket.emit('user_tree_graft', quidId, path, data, (error, result) => {
        if (error) {
          reject(error)
        } else {
          resolve(result)
        }
      })
    })
  }

  /**
   * Prunes the userData of a quiddity
   * @param {string} quidId - ID of the quiddity
   * @param {string} path - Object path of the userData
   * @async
   */
  prune (quidId, path) {
    return new Promise((resolve, reject) => {
      this.socket.emit('user_tree_prune', quidId, path, (error, result) => {
        if (error) {
          reject(error)
        } else {
          resolve(result)
        }
      })
    })
  }

  /**
   * Listens to all grafts in user tree
   * @param {function} onGraftAction - Function triggered when the quiddity tree is grafted by Switcher
   * @param {(string|function)} [quiddityMatcher] - ID of the quiddity (if empty, listen to all tree grafts for all quiddities)
   * @param {(string|function)} [pathMatcher] - Match the path of the updated element
   */
  onGrafted (onGraftAction, quiddityMatcher, pathMatcher) {
    this.socket.on('user_tree_grafted', (quidId, path, value) => {
      if (this.match(quidId, quiddityMatcher) && this.match(path, pathMatcher)) {
        onGraftAction(quidId, path, value)
      }
    })
  }

  /**
   * Listens pruned user tree
   * @param {function} onPrunedAction - Function triggered when the quiddity tree is pruned by Switcher
   * @param {(string|function)} [quiddityMatcher] - ID of the quiddity (if empty, listen to all tree grafts for all quiddities)
   * @param {(string|function)} [pathMatcher] - Match the path of the updated element
   */
  onPruned (onPrunedAction, quiddityMatcher, pathMatcher) {
    this.socket.on('user_tree_pruned', (quidId, path) => {
      if (this.match(quidId, quiddityMatcher) && this.match(path, pathMatcher)) {
        onPrunedAction(quidId, path)
      }
    })
  }
}

export default UserTreeAPI

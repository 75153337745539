import { observable, action, reaction, computed, makeObservable } from 'mobx'

import Store from '@stores/Store'

import ShmdataRoleEnum from '@models/shmdata/ShmdataRoleEnum.js'
import Spec from '@models/shmdata/Spec.js'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the SpecStore
 * @memberof module:stores/shmdata.SpecStore
 */
export const LOG = logger.child({ store: 'SpecStore' })

/**
 * @classdesc Stores and manages the connection specs of each quiddity
 * @see [Labeled Shmdata for Quiddity proposal]{@link https://gitlab.com/sat-mtl/tools/switcher/-/issues/10}
 * @extends module:stores/common.Store
 * @memberof module:stores/shmdata
 */
class SpecStore extends Store {
  /** @property {Map<string, Set<module:models/shmdata.Spec>>} writerSpecs - All specs hashed by source IDs */
  writerSpecs = new Map()

  /** @property {Map<string, Set<module:models/shmdata.Spec>>} followerSpecs - All specs hashed by destination IDs */
  followerSpecs = new Map()

  /** @property {Map<string, Set<module:models/shmdata.Spec>>} specs - All specs hashed by quiddity IDs */
  get specs () {
    const specs = new Map(this.writerSpecs)

    for (const [quidId, followerSpecs] of this.followerSpecs) {
      const writerSpecs = specs.get(quidId)

      if (writerSpecs) {
        specs.set(quidId, new Set(...writerSpecs, ...followerSpecs))
      } else {
        specs.set(quidId, followerSpecs)
      }
    }

    return specs
  }

  /** @property {Map<string, Set<string>>} compatibleMediaTypes - All compatible media types hashed by quiddity IDs */
  get compatibleMediaTypes () {
    const mediaTypes = new Map()

    for (const [quidId, followerSpecs] of this.followerSpecs) {
      mediaTypes.set(quidId, new Set([...followerSpecs].map(spec => [...spec.canDo]).flat()))
    }

    return mediaTypes
  }

  /**
   * Instantiates a new SpecStore
   * @param {stores.SocketStore} socketStore - Socket manager
   * @param {stores.QuiddityStore} quiddityStore - Quiddity manager
   * @constructor
   */
  constructor (socketStore) {
    super(socketStore)

    makeObservable(this, {
      writerSpecs: observable,
      followerSpecs: observable,
      specs: computed,
      addWriterSpec: action,
      addFollowerSpec: action,
      removeSpec: action,
      clear: action
    })

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )
  }

  /**
   * Fetches the order of a given quiddity
   * @param {string} quidId - ID of the quiddity
   * @returns {?object} Connection spec of the quiddity, returns null if it fails
   * @async
   */
  async fetchConnectionSpecs (quidId) {
    const { connectionSpecsAPI } = this.socketStore.APIs
    let spec = null

    try {
      spec = await connectionSpecsAPI.get(quidId)
    } catch (error) {
      LOG.error({
        msg: 'Failed to fetch quiddity\'s order',
        quiddity: quidId,
        error: error.message
      })
    }

    return spec
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()
  }

  /**
   * Adds the writer spec of a source quiddity
   * @param {string} quidId - ID of the quiddity
   * @param {object} spec - Connection spec of the quiddity
   */
  addWriterSpec (quidId, spec) {
    if (this.writerSpecs.has(quidId)) {
      this.writerSpecs.get(quidId).add(spec)
    } else {
      this.writerSpecs.set(quidId, new Set([spec]))
    }

    LOG.info({
      msg: 'Successfully add a writer spec',
      quiddity: quidId,
      spec: spec?.label
    })
  }

  /**
   * Adds the follower spec of a destination quiddity
   * @param {string} quidId - ID of the quiddity
   * @param {object} spec - Connection spec of the quiddity
   */
  addFollowerSpec (quidId, spec) {
    if (this.followerSpecs.has(quidId)) {
      this.followerSpecs.get(quidId).add(spec)
    } else {
      this.followerSpecs.set(quidId, new Set([spec]))
    }

    LOG.info({
      msg: 'Successfully add a follower spec',
      quiddity: quidId,
      spec: spec?.label
    })
  }

  /**
   * Adds the some specs of a quiddity
   * @param {string} quidId - ID of the quiddity
   * @param {string} role - Role of the spec
   * @param {object} json - JSON representation of a connection spec
   * @return {?module:models/shmdata.Spec} A modelized spec
   */
  makeSpec (quidId, role, json) {
    let spec = null

    try {
      spec = Spec.fromJSON(json)
    } catch (error) {
      console.trace(error)

      LOG.error({
        msg: `Failed to parse a ${role} spec`,
        error: error.message,
        quiddity: quidId,
        spec: json
      })
    }

    return spec
  }

  /**
   * Adds the all connection specs of a quiddity
   * @param {string} quidId - ID of the quiddity
   * @param {object} connectionSpecs - all JSON representations of connection specs
   */
  addConnectionSpecs (quidId, connectionSpecs) {
    for (const role in connectionSpecs) {
      switch (role) {
        case ShmdataRoleEnum.WRITER: {
          connectionSpecs[role]
            .map(json => this.makeSpec(quidId, role, json))
            .filter(spec => spec !== null)
            .forEach(spec => this.addWriterSpec(quidId, spec))
          break
        }
        case ShmdataRoleEnum.FOLLOWER: {
          connectionSpecs[role]
            .map(json => this.makeSpec(quidId, role, json))
            .filter(spec => spec !== null)
            .forEach(spec => this.addFollowerSpec(quidId, spec))
          break
        }
        default:
          LOG.warn({
            msg: 'Quiddity hasn\'t connection specs',
            quiddity: quidId
          })
      }
    }
  }

  /**
   * Deletes a spec of a quiddity
   * @param {string} quidId - ID of the quiddity
   */
  removeSpec (quidId) {
    this.writerSpecs.delete(quidId)
    this.followerSpecs.delete(quidId)
  }

  /** Cleans all specs */
  clear () {
    this.writerSpecs.clear()
    this.followerSpecs.clear()
  }
}

export default SpecStore

import { observable, action, reaction, computed, makeObservable, observe } from 'mobx'

import Store from '@stores/Store'
import ConfigStore from '@stores/common/ConfigStore'
import SettingEnum from '@models/common/SettingEnum'

import { logger } from '@utils/logger'
import i18n, { DEFAULT_LANGUAGE } from '@utils/i18n'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the SettingStore
 * @memberof stores.SettingStore
 */
export const LOG = logger.child({ store: 'SettingStore' })

export const LANGUAGE_SETTING_ID = 'userLang'

export const DISPLAY_THUMBNAILS_ID = 'displayThumbnails'

export const DISPLAY_ENCODERS_ID = 'displayEncoders'

/**
 * @classdesc Manages all settings
 * @extends stores.Store
 * @memberof stores
 */
class SettingStore extends Store {
  /** @property {Map<string, string|number|boolean>} settings - All settings values mapped with their ids */
  settings = new Map()

  /** @property {string} userLanguage - Get the current user language */
  get userLanguage () {
    const config = this.configStore.scenicConfiguration
    let lang

    if (this.settings.has(LANGUAGE_SETTING_ID)) {
      lang = this.settings.get(LANGUAGE_SETTING_ID)
    } else if (config.defaultLang) {
      lang = config.defaultLang
    } else {
      lang = DEFAULT_LANGUAGE
    }

    return lang
  }

  /** @property {bool} displayThumbnails - Get the current thumbnails display setting */
  get displayThumbnails () {
    let displayThumbnails = this.configStore.scenicConfiguration[DISPLAY_THUMBNAILS_ID]

    if (this.settings.has(DISPLAY_THUMBNAILS_ID)) {
      displayThumbnails = this.settings.get(DISPLAY_THUMBNAILS_ID)
    }

    return displayThumbnails
  }

  /** @property {bool} displayEncoders - Get the current encoders display setting */
  get displayEncoders () {
    let displayEncoders = this.configStore.scenicConfiguration[DISPLAY_ENCODERS_ID]

    if (this.settings.has(DISPLAY_ENCODERS_ID)) {
      displayEncoders = this.settings.get(DISPLAY_ENCODERS_ID)
    }

    return displayEncoders
  }

  /** @property {object[]} userLanguageOptions - Get all available options for the user languages */
  userLanguageOptions = [{
    id: 'en',
    value: 'en',
    label: 'English'
  }, {
    id: 'fr',
    value: 'fr',
    label: 'French'
  }]

  /** @property {object[]} userLanguageOptions - Get the selected option for the user language */
  get userLanguageOption () {
    return this.userLanguageOptions.find(opt => opt.id === this.userLanguage)
  }

  groups = new Map([
    [SettingEnum.GENERAL, 'General'],
    [SettingEnum.SIP, 'SIP']
  ])

  get groupIds () {
    return Array.from(this.groups.keys())
  }

  activeGroupId = this.groupIds[0]

  /**
   * Instantiates a new socket store
   * @param {stores.SocketStore} socketStore - The socket manager
   * @param {stores.ConfigStore} configStore - The config manager
   * @constructor
   */
  constructor (socketStore, configStore) {
    super(socketStore)

    makeObservable(this, {
      settings: observable,
      userLanguage: computed,
      displayThumbnails: computed,
      displayEncoders: computed,
      userLanguageOption: computed,
      groups: observable,
      groupIds: computed,
      activeGroupId: observable,
      setSetting: action,
      setActiveGroup: action
    })

    this.i18n = i18n

    if (configStore instanceof ConfigStore) {
      this.configStore = configStore
    } else {
      throw new TypeError('SettingStore requires a ConfigStore')
    }

    observe(this.settings,
      (change) => {
        if (['add', 'update'].includes(change.type)) {
          this.handleSettingUpdate(change.name, change.newValue)
        }
      }
    )

    reaction(
      () => this.configStore.scenicConfiguration,
      config => this.handleConfigUpdate(config)
    )
  }

  /**
   * Handles each update of the configuration object
   * @param {Object} config - The configuration object
   */
  handleConfigUpdate (config) {
    if (typeof config.defaultLang === 'string') {
      this.setSetting(LANGUAGE_SETTING_ID, config.defaultLang)
    }
  }

  /**
   * Handles all setting updates
   * @param {string} settingId - The updated setting ID
   * @param {string|number|boolean} value - The updated setting value
   */
  handleSettingUpdate (settingId, value) {
    if (settingId === LANGUAGE_SETTING_ID) {
      this.i18n.changeLanguage(value)
    }
  }

  /**
   * Set a new setting value
   * @param {string} settingId - The setting ID
   * @param {string|number|boolean} value - The setting value
   */
  setSetting (settingId, value) {
    this.settings.set(settingId, value)

    LOG.info({
      msg: 'Setting is updated',
      settingId: settingId,
      value: value
    })
  }

  setActiveGroup (groupId) {
    if (this.groupIds.includes(groupId)) {
      this.activeGroupId = groupId
    } else {
      LOG.error({
        msg: 'Failed to set a new active group',
        groupId: groupId
      })
    }
  }
}

export default SettingStore

import React, { useContext } from 'react'
import { observer } from 'mobx-react'

import { getShmdataIconType } from '@models/shmdata/MediaTypeEnum'

import { AppStoresContext } from '@components/App'

import ShmdataInformationTooltip from '@components/tooltips/ShmdataInformationTooltip'

import { Shared, Common } from '@sat-mtl/ui-components'

const { Icon } = Common
const { ShmdataCell } = Shared

/** Displays a Shmdata with its timelapse and its information
 * @memberof module:components/matrix.ShmdataBox
 * @param {module:models/shmdata.Shmdata} shmdata - The shmdata
 * @param {boolean} disabled - Flags a disabled value
 * @param {module:models/MatrixEntry.MatrixEntry} entry - A matrix entry
 * @param {?string} [name=null] - Name of the information tooltip
 * @returns {external:mobx-react/ObserverComponent} A Shmdata box
*/
const ShmdataBox = observer(({ shmdata, disabled, entry, name = null }) => {
  const { thumbnailStore, previewStore } = useContext(AppStoresContext)

  let thumbUrl

  if (shmdata) {
    thumbUrl = thumbnailStore.thumbnailUrls.get(shmdata.id)
  }

  /** Handles user click on the shmdata box */
  const handleShmdataCellClick = () => {
    previewStore.applyPreviewEntry(entry)
  }

  const $cell = (
    <ShmdataCell
      disabled={!shmdata || disabled}
      src={thumbUrl}
      icon={shmdata && !thumbUrl && <Icon type={getShmdataIconType(shmdata.mediaType)} />}
      onClick={() => handleShmdataCellClick()}
    />
  )

  /** Renders the shmdata box */
  return (
    <div>
      {!shmdata && $cell}
      {shmdata && (
        <ShmdataInformationTooltip shmdata={shmdata} disabled={disabled} entry={entry} name={name}>
          {$cell}
        </ShmdataInformationTooltip>
      )}
    </div>
  )
})

export default ShmdataBox

import MenuItem from '@models/menus/MenuItem'

/**
 * @classdesc Collection of menus used to generate custom menus from a JSON configuration
 * @memberof models
 */
class MenuCollection {
  /**
   * Instantiates a new collection of Menus with configurable properties
   * @param {string} name - Name of the menu, used as label
   * @param {(models.MenuItem[]|models.MenuCollection[])} items - Array of MenuItems or MenuCollections
   * @param {boolean} [hidden=false] - Menu property which makes this collection hidden by default
   */
  constructor (name, items = [], hidden = false) {
    if (typeof name === 'undefined' || typeof name !== 'string') {
      throw new TypeError('Attribute `name` is required and must be a string')
    }

    if (typeof items === 'undefined' || !Array.isArray(items)) {
      throw new TypeError('Attribute `items` is required and must be an array of MenuItem or MenuCollection')
    }

    this.name = name
    this.items = items.map(MenuCollection.fromJSONSubMenu)
    this.hidden = hidden
  }

  /** @property {string} collectionKey - Unique key for a collection */
  get collectionKey () {
    return `${this.name}-menu-collection`
  }

  /** @property {string} groupKey - Unique key for a group */
  get groupKey () {
    return `${this.name}-menu-group`
  }

  /**
   * Parses a JSON element to a new MenuCollection
   * @static
   * @param {Object} json - JSON object which is parsed to a MenuCollection
   * @param {string} json.name - Name of the menu, used as label
   * @param {Object[]} json.items - Array of MenuItems or MenuCollections
   * @param {Object[]} [json.subMenus] - Alias of the `items` property
   * @returns {models.MenuCollection} A new MenuCollection which matches with the JSON properties
   */
  static fromJSON ({ name, subMenus, items, hidden }) {
    return new MenuCollection(name, items || subMenus, hidden)
  }

  /**
   * Parses a configuration object to a MenuCollection array
   * @static
   * @param {Object} config - Configuration object for Scenic
   * @returns {models.MenuCollection[]} A MenuCollection array
   */
  static fromConfig (config) {
    const { customMenus } = config
    let menus = []

    if (customMenus) {
      menus = customMenus.map(menu => MenuCollection.fromJSON(menu))
    }

    return menus
  }

  /**
   * Parses a JSON item to a MenuItem or a MenuCollection
   * @param {Object} item - Abstract representation of a menu
   * @returns {(models.MenuItem|models.MenuCollection)} An instantiated menu
   */
  static fromJSONSubMenu (item) {
    if (item instanceof MenuItem) return item
    if (item instanceof MenuCollection) return item

    let menu
    const errors = []

    try {
      menu = MenuItem.fromJSON(item)
    } catch (typeError) {
      errors.push(typeError)
    }

    if (!menu) {
      try {
        menu = MenuCollection.fromJSON(item)
      } catch (typeError) {
        errors.push(typeError)
      }
    }

    if (!menu && errors.length > 0) {
      throw errors[0]
    } else {
      return menu
    }
  }

  /** @property {string[]} ids - Gets all ids of the folded items in an array */
  get ids () {
    const ids = []

    for (const item of this.items) {
      if (item instanceof MenuCollection) {
        ids.push(...item.ids)
      } else if (item instanceof MenuItem) {
        ids.push(item.id)
      }
    }

    return ids
  }

  /** @property {module:models/menus.MenuItem} menuItems - All nested menu items */
  get menuItems () {
    const items = []

    for (const item of this.items) {
      if (item instanceof MenuCollection) {
        items.push(...item.menuItems)
      } else if (item instanceof MenuItem) {
        items.push(item)
      }
    }

    return items
  }

  /**
   * Generates a new MenuItem from the quiddity kind and a new MenuCollection from its category
   * @param {models.Kind} kind - A quiddity kind
   * @todo Creates a documented model of a Quiddity Kind
   */
  addQuiddityKind (kind) {
    if (!kind.category) return

    const { name, category } = kind
    let menuCategory = this.items.find(menu => menu.name === category)

    if (!menuCategory) {
      menuCategory = new MenuCollection(category)
      this.items.push(menuCategory)
    }

    menuCategory.items.push(new MenuItem(name, name, [category]))
  }

  /**
   * Shows the menu collection only if the menu is `showable`
   * @returns {?models.MenuCollection} If the menu is `showable` it returns `this`, else it returns `null`
   */
  show () {
    if (this.showable) {
      const showableSub = this.items.map(m => m.show()).filter(m => !!m)
      return new MenuCollection(this.name, showableSub)
    } else {
      return null
    }
  }

  /**
   * Updates a menu model of the collection when a quiddity state has changed
   * @param {Object} properties - Set of updated properties
   * @param {boolean} [properties.created=false] - Updated creation status of the quiddity
   */
  updateItem (quidId, properties = { created: false }) {
    this.items.forEach(group => group.updateItem(quidId, properties))
  }

  /** @property {string} showable - Flag which notifies if the menu should be displayed or not */
  get showable () {
    const filtered = this.items.filter(item => item.showable)
    return !this.hidden && !(filtered.length === 0)
  }
}

export default MenuCollection

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { HuePicker, AlphaPicker } from 'react-color'
import TinyColor from 'tinycolor2'

import HelpWrapper from '@components/wrappers/HelpWrapper'
import StatusEnum from '@models/common/StatusEnum'

import { Inputs, Layout } from '@sat-mtl/ui-components'

import '@styles/fields/ColorField.scss'

const { FlexColumn } = Layout
const { Field, InputText } = Inputs

/**
 * Color picker for the hue value
 * @memberof module:components/fields
 * @param {string} value - Current color value in hexadecimal format
 * @param {function} onChange - Function triggered when the user change the picker value
 * @returns {external:react-color.HuePicker} A picker for the hue value
 */
function ColorHuePicker ({ value, onChange }) {
  return (
    <HuePicker
      key='hue'
      width='inherit'
      color={value}
      onChange={({ rgb }) => {
        const newColor = new TinyColor(rgb)
        const oldColor = new TinyColor(value)

        if (newColor.getAlpha() === 1) {
          newColor.setAlpha(oldColor.getAlpha())
        }

        onChange(newColor.toHex8())
      }}
    />
  )
}

/**
 * Color picker for the alpha value
 * @memberof module:components/fields
 * @param {string} value - Current color value in hexadecimal format
 * @param {function} onChange - Function triggered when the user change the picker value
 * @returns {external:react-color.AlphaPicker} A picker for the alpha value
 */
function ColorAlphaPicker ({ value, onChange }) {
  return (
    <AlphaPicker
      key='alpha'
      width='inherit'
      color={value}
      onChange={({ rgb }) => {
        const newColor = new TinyColor(rgb)
        onChange(newColor.toHex8())
      }}
    />
  )
}

/**
 * Text input for the color hexadecimal value
 * @memberof module:components/fields
 * @param {string} value - Current color value in hexadecimal format
 * @param {boolean} disabled - Flags a disabled input
 * @param {string} status - Status of the form
 * @param {function} onChange - Function triggered when the user change the picker value
 * @returns {external:ui-component/Inputs.InputText} A text input
 */
function ColorTextInput ({ value, disabled, status, onChange }) {
  const [editedValue, editValue] = useState(value)
  const { t } = useTranslation()

  useEffect(() => editValue(value), [value])

  return (
    <InputText
      disabled={disabled}
      addonBefore='#'
      value={editedValue}
      status={status}
      onChange={e => editValue(e.target.value)}
      placeholder={t('Input a value')}
      onBlur={() => {
        if (value !== editedValue && status !== StatusEnum.DANGER) {
          onChange(editedValue)
        }
      }}
      onPressEnter={() => {
        if (value !== editedValue) {
          onChange(editedValue)
        }
      }}
    />
  )
}

/**
 * Field component that lets users pick a color
 * @todo Update the status when the property update fails
 * @memberof module:components/fields
 * @param {string} title - Title of the field
 * @param {string} description - Description of the field
 * @param {string} value - Value of the property
 * @param {boolean} disabled - Flags a disabled value
 * @param {function} onChange - Function triggered when the property is changed by the user
 * @param {string} status - Status of the form
 * @returns {external:ui-component/Inputs.Field} A field component
 */
function ColorField ({ title, description, value, disabled, onChange, status }) {
  const { t } = useTranslation()

  const defaultLabel = t(
    'Pick a color for "{{title}}" property',
    { title: t(title) }
  )

  return (
    <div className='ColorField'>
      <HelpWrapper message={description ? t(description) : defaultLabel}>
        <Field
          status={status}
          disabled={disabled}
          title={t(title)}
          description={description ? t(description) : defaultLabel}
        >
          <FlexColumn rowGap={8}>
            <ColorTextInput
              value={value}
              status={status}
              disabled={disabled}
              onChange={onChange}
            />
            {!disabled && [
              <ColorHuePicker
                key='ColorHuePicker'
                value={value}
                onChange={onChange}
              />,
              <ColorAlphaPicker
                key='ColorAlphaPicker'
                value={value}
                onChange={onChange}
              />
            ]}
          </FlexColumn>
        </Field>
      </HelpWrapper>
    </div>
  )
}

export default ColorField
export { ColorHuePicker, ColorAlphaPicker, ColorTextInput }

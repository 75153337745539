import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Ressource from '@models/common/Ressource'
import NpmPackage from '~/package.json'

import Authors from '~/AUTHORS.md'
import Testers from '~/TESTERS.md'

import { Layout, Context } from '@sat-mtl/ui-components'

import '@styles/pages/HelpPage.scss'

import SatPng from '@assets/img/sat.png'
import ScenicPng from '@assets/Scenic.png'
import ShmdataPng from '@assets/img/shmdata.png'
import SwitcherPng from '@assets/img/switcher.png'
import SplashPng from '@assets/img/splash.png'
import SatiePng from '@assets/img/satie.png'
import CalimiroPng from '@assets/img/calimiro.png'
import VaRaysPng from '@assets/img/varays.png'
import EisPng from '@assets/img/eis.png'

const { ThemeContext } = Context
const { FlexRow } = Layout

export const HOME_SAT = new Ressource('Société des Arts Technologiques (SAT)', 'https://sat.qc.ca/', SatPng)
export const HOME_SCENIC = new Ressource('What is Scenic?', 'https://telepresence-scenic.ca/en/technology', ScenicPng)
export const HOME_TELEPRESENCE = new Ressource('What is telepresence?', 'https://telepresence-scenic.ca/en')

export const USER_FAQ = new Ressource('FAQ', '')
export const USER_DOC = new Ressource('Documentation', 'https://telepresence-scenic.ca/en/support')
export const USER_TUTORIAL = new Ressource('Learning Resources', '')
export const USER_FORUM = new Ressource('Official Scenic Forum', '')
export const USER_GUIDE = new Ressource('Scenic User Guide', 'http://wiki.telepresence-scenic.ca/fr/GuideScenic4')

export const SCENIC_WIKI = new Ressource('Scenic Wiki', 'http://wiki.telepresence-scenic.ca')

export const FEATURE_REQUEST = new Ressource('suggest a feature', 'https://gitlab.com/sat-mtl/tools/scenic/scenic/-/issues/new?issuable_template=Feature%20Request')
export const BUG_REPORT = new Ressource('Report a bug', 'https://gitlab.com/sat-mtl/tools/scenic/scenic/-/issues/new?issuable_template=Bug%20Resolution%20Request')

export const DEV_DOC = new Ressource("Scenic's internal documentation", 'https://sat-mtl.gitlab.io/tools/scenic/scenic/')
export const DEV_GIT = new Ressource("Scenic's source code", 'https://gitlab.com/sat-mtl/tools/scenic/scenic')
export const DEV_COMMIT = new Ressource(process.env.GIT_SHORT_HASH, `${DEV_GIT.url}/-/commit/${process.env.GIT_SHORT_HASH}`)

export const LICENSE_GPL = new Ressource('GNU General Public License version 3', 'https://www.gnu.org/licenses/gpl-3.0.html')

const TOOLS_URL = 'https://gitlab.com/sat-mtl/tools'

export const TOOLS = [
  new Ressource('Switcher', `${TOOLS_URL}/switcher`, SwitcherPng),
  new Ressource('Shmdata', `${TOOLS_URL}/shmdata`, ShmdataPng),
  new Ressource('Splash', `${TOOLS_URL}/splash/splash`, SplashPng),
  new Ressource('SATIE', `${TOOLS_URL}/satie/SATIE`, SatiePng),
  new Ressource('vaRays', `${TOOLS_URL}/vaRays`, VaRaysPng),
  new Ressource('EditionInSitu', `${TOOLS_URL}/EditionInSitu`, EisPng),
  new Ressource('calimiro', `${TOOLS_URL}/splash/calimiro`, CalimiroPng)
]

/**
 * A link component that opens page in a new tab
 * @param {string} href - URL of the link
 * @param {node} children - Content of the link
 * @returns {external:react/Component} A tweaked HTML link
 */
function Link ({ href, children }) {
  return (
    <a target='_blank' rel='noopener noreferrer' href={href}>
      {children}
    </a>
  )
}

/**
 * The page title
 * @returns {external:react/Component} The page title
 */
function PageTitle () {
  return (
    <h1>
      <Trans>
        Help
      </Trans>
    </h1>
  )
}

/**
 * The Assistance section
 * @memberof module:components/pages
 * @selector `#AssistanceSection`
 * @returns {external:react/Component} The Assistance section
 */
function AssistanceSection () {
  const { t } = useTranslation()

  const $guide = t(USER_GUIDE.title)
  const $forum = USER_FORUM ? t(USER_FORUM.title) : ''
  const $wiki = t(SCENIC_WIKI.title)

  return (
    <section id='AssistanceSection'>
      <h2>
        <span className='SectionIcon' role='img' aria-label='warning'>
          ⚠
        </span>
        <Trans>
          Need assistance?
        </Trans>
      </h2>
      <p>
        <Trans>
          Follow these steps to quickly solve your problem:
        </Trans>
      </p>
      <ol>
        <li>
          <Trans>
            Start by reading the online <Link href={USER_GUIDE.url}>{{ $guide }}</Link>
          </Trans>
        </li>
        <li>
          <Trans>
            Visit <Link href={SCENIC_WIKI.url}>{{ $wiki }}</Link>, our online knowledge base
          </Trans>
        </li>
        {USER_FORUM && USER_FORUM.url && (
          <li>
            <Trans>
              Still having problems? Ask for help on our <Link href={USER_FORUM.url}>{{ $forum }}</Link>
            </Trans>
          </li>
        )}
      </ol>
    </section>
  )
}

/**
 * The Info section
 * @memberof module:components/pages
 * @selector `#InfoSection`
 * @returns {external:react/Component} The Info section
 */
function InfoSection () {
  const { t } = useTranslation()

  const $bug = t(BUG_REPORT.title)
  const $feature = t(FEATURE_REQUEST.title)

  return (
    <section id='InfoSection'>
      <h2>
        <span className='SectionIcon' role='img' aria-label='question'>
          ❔
        </span>
        <Trans>Hungry for more?</Trans>
      </h2>
      <p>
        <Trans>
          Visit <Link href={t(HOME_TELEPRESENCE.url)}>telepresence-scenic.ca</Link>, the ultimate
          telepresence resource on the Web
        </Trans>
      </p>
      <ul>
        <li>
          <Link href={t(HOME_SCENIC.url)}>
            {t(HOME_SCENIC.title)}
          </Link>
        </li>
        <li>
          <Link href={t(HOME_TELEPRESENCE.url)}>
            {t(HOME_TELEPRESENCE.title)}
          </Link>
        </li>
        {USER_FAQ && USER_FAQ.url && (
          <li>
            <Link href={USER_FAQ.url}>
              {t(USER_FAQ.title)}
            </Link>
          </li>
        )}
        <li>
          <Link href={t(USER_DOC.url)}>
            {t(USER_DOC.title)}
          </Link>
        </li>
        {USER_TUTORIAL && USER_TUTORIAL.url && (
          <li>
            <Link href={USER_TUTORIAL.url}>
              {t(USER_TUTORIAL.title)}
            </Link>
          </li>
        )}
        {USER_FORUM && USER_FORUM.url && (
          <li>
            <Link href={USER_FORUM.url}>
              {t(USER_FORUM.title)}
            </Link>
          </li>
        )}
        <li>
          <Trans>
            <Link href={BUG_REPORT.url}>{{ $bug }}</Link> or <Link href={FEATURE_REQUEST.url}>{{ $feature }}</Link> to the developers
          </Trans>
        </li>
      </ul>
    </section>
  )
}

/**
 * The Contributing section
 * @memberof module:components/pages
 * @selector `#ContributingSection`
 * @returns {external:react/Component} The Contributing section
 */
function ContributingSection () {
  const { t } = useTranslation()

  const $home = t(DEV_GIT.title)
  const $doc = t(DEV_DOC.title)

  return (
    <section id='ContributingSection'>
      <h2>
        <span className='SectionIcon' role='img' aria-label='technologist'>
          🧑‍💻
        </span>
        <Trans>Want to become a contributor?</Trans>
      </h2>
      <p>
        <Trans>
          Scenic is an open source project: community contributions are more
          than welcome!
        </Trans>
      </p>
      <ul>
        <li>
          <Trans>
            Explore <Link href={DEV_GIT.url}>{{ $home }}</Link>
          </Trans>
        </li>
        <li>
          <Trans>
            Check out <Link href={DEV_DOC.url}>{{ $doc }}</Link>
          </Trans>
        </li>
      </ul>
    </section>
  )
}

/**
 * The Licence section
 * @memberof module:components/pages
 * @selector `#LicenseSubSection`
 * @returns {external:react/Component} The Licence section
 */
function LicenseSubSection () {
  const { url, title } = LICENSE_GPL
  const $license = title

  return (
    <section id='LicenseSubSection'>
      <h3>
        <span className='SectionIcon' role='img' aria-label='scroll'>
          📜
        </span>
        <Trans>License</Trans>
      </h3>
      <p>
        <Trans>
          Scenic is proudly free and open source under the <Link href={url}>{{ $license }}</Link>.
        </Trans>
      </p>
    </section>
  )
}

/**
 * The Version subsection
 * @memberof module:components/pages
 * @selector `#VersionSection`
 * @returns {external:react/Component} The Version section
 */
function VersionSubSection () {
  const $commit = (
    <Link href={DEV_COMMIT.url}>
      {DEV_COMMIT.title}
    </Link>
  )

  return (
    <section id='VersionSection'>
      <h3>
        <span className='SectionIcon' role='img' aria-label='bookmark'>
          🔖
        </span>
        <Trans>Software version</Trans>
      </h3>
      <ul>
        <li id='ScenicVersion'>Scenic: {NpmPackage.version} ({$commit})</li>
      </ul>
    </section>
  )
}

/**
 * The Contributors section
 * @memberof module:components/pages
 * @selector `#ContributorsSection`
 * @returns {external:react/Component} The Contributors section
 */
function ContributorsSection () {
  return (
    <section id='ContributorsSection'>
      <AuthorsSubSection />
      <TestersSubSection />
    </section>
  )
}

/**
 * The Authors subsection
 * @memberof module:components/pages
 * @selector `#AuthorsSubSection`
 * @returns {external:react/Component} The Contributors section
 */
function AuthorsSubSection () {
  return (
    <section id='AuthorsSubSection'>
      <h3>
        <span className='SectionIcon' role='img' aria-label='pencil'>
          ✏
        </span>
        <Trans>Contributors (In alphabetical order)</Trans>
      </h3>
      <Authors />
    </section>
  )
}

/**
 * The Testers subsection
 * @memberof module:components/pages
 * @selector `#TestersSection`
 * @returns {external:react/Component} The Testers section
 */
function TestersSubSection () {
  return (
    <section id='TestersSubSection'>
      <h3>
        <span className='SectionIcon' role='img' aria-label='pencil'>
          🧪
        </span>
        <Trans>Testers</Trans>
      </h3>
      <Testers />
    </section>
  )
}

/**
 * The Metalab section
 * @memberof module:components/pages
 * @selector `#MetalabSection`
 * @returns {external:react/Component} The Metalab section
 */
function MetalabSection () {
  return (
    <section id='MetalabSection'>
      <p>
        <Trans>
          The SAT maintains an ecosystem of free and open-source software for collective immersion and telepresence.
        </Trans>
      </p>
      <FlexRow columnGap={16} alignItems='center'>
        {TOOLS.map((tool) => (
          <Link href={tool.url} key={tool.title}>
            <img className={tool.title} src={tool.icon} alt={tool.title} />
          </Link>
        ))}
      </FlexRow>
    </section>
  )
}

/**
 * The About section
 * @memberof module:components/pages
 * @selector `#AboutSection`
 * @returns {external:react/Component} The About section
 */
function AboutSection () {
  const $sat = HOME_SAT.title

  return (
    <section id='AboutSection'>
      <h2>
        <span className='SectionIcon' role='img' aria-label='information'>
          ℹ
        </span>
        <Trans>About</Trans>
      </h2>
      <p>
        <Trans>
          Scenic is a stage telepresence software initiated and maintained by
          the <Link href={HOME_SAT.url}>{{ $sat }}</Link> in Montreal, Canada.
        </Trans>
      </p>
      <div className='AboutSubSections'>
        <LicenseSubSection />
        <VersionSubSection />
      </div>
    </section>
  )
}

/**
 * Logo of the SAT
 * @memberof module:components/pages
 * @selector `#HelpPage .SatLogo`
 * @returns {external:react/Component} The SAT logo
 */
function SatLogo () {
  const { title, url, icon } = HOME_SAT

  return (
    <div className='SatLogo'>
      <Link href={url}>
        <img src={icon} alt={title} />
      </Link>
    </div>
  )
}

/**
 * Page that displays the help resources
 * @memberof module:components/pages
 * @selector `#HelpPage`
 * @returns {external:react/Component} The Help page
 */
function HelpPage () {
  const theme = React.useContext(ThemeContext)

  return (
    <div id='HelpPage' className={`page-${theme}`}>
      <header>
        <PageTitle />
        <SatLogo />
      </header>
      <main>
        <div className='LeftColumn'>
          <AssistanceSection />
          <InfoSection />
          <ContributingSection />
        </div>
        <div className='RightColumn'>
          <AboutSection />
          <ContributorsSection />
          <MetalabSection />
        </div>
      </main>
    </div>
  )
}

export default HelpPage

export {
  AssistanceSection,
  InfoSection,
  ContributingSection,
  LicenseSubSection,
  VersionSubSection,
  ContributorsSection,
  AuthorsSubSection,
  TestersSubSection,
  MetalabSection,
  AboutSection,
  SatLogo
}

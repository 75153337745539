import { observable, computed, action, reaction, makeObservable } from 'mobx'

import StatusEnum from '@models/common/StatusEnum'
import QuiddityTagEnum from '@models/quiddity/QuiddityTagEnum'

import Store from '@stores/Store'

import ShmdataStore from '@stores/shmdata/ShmdataStore'
import StatStore from '@stores/shmdata/StatStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import PropertyStore from '@stores/quiddity/PropertyStore'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the QuiddityStatusStore
 * @memberof stores.QuiddityStatusStore
 */
export const LOG = logger.child({ store: 'QuiddityStatusStore' })

/**
 * @classdesc Store all quiddity statuses
 * @extends stores.Store
 * @memberof stores
 */
class QuiddityStatusStore extends Store {
  /** @property {Map<string, enums.StatusEnum>} sourceStatuses - All source quiddity statuses by quiddity IDs */
  sourceStatuses = new Map()

  /** @property {Map<string, enums.StatusEnum>} destinationStatuses - All destination quiddity statuses by quiddity IDs */
  destinationStatuses = new Map()

  /** @property {Map<string, enums.StatusEnum>} quiddityStatuses - All quiddity statuses by quiddity IDs */
  get quiddityStatuses () {
    const statuses = new Map()

    for (const [quidId, status] of this.sourceStatuses) {
      statuses.set(quidId, status)
    }

    for (const [quidId, status] of this.destinationStatuses) {
      if (statuses.has(quidId)) {
        statuses.set(quidId, this.makeGlobalQuiddityStatus(status, statuses.get(quidId)))
      } else {
        statuses.set(quidId, status)
      }
    }

    return statuses
  }

  /**
   * Instantiates a new QuiddityStatusStore
   * @param {stores.SocketStore} socketStore - Socket manager
   * @param {stores.QuiddityStore} quiddityStore - Quiddity manager
   * @param {stores.PropertyStore} propertyStore - PropertyStore
   * @param {stores.ShmdataStore} shmdataStore - Shmdata manager
   * @param {stores.StatStore} statStore - Shmdata stat manager
   * @constructor
   */
  constructor (socketStore, quiddityStore, propertyStore, shmdataStore, statStore) {
    super(socketStore)

    makeObservable(this, {
      sourceStatuses: observable,
      destinationStatuses: observable,
      quiddityStatuses: computed,
      setQuiddityStatus: action,
      removeQuiddityStatus: action,
      clear: action
    })

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new TypeError('QuiddityStatusStore requires a QuiddityStore')
    }

    if (propertyStore instanceof PropertyStore) {
      this.propertyStore = propertyStore
    } else {
      throw new TypeError('QuiddityStatusStore requires a PropertyStore')
    }

    if (shmdataStore instanceof ShmdataStore) {
      this.shmdataStore = shmdataStore
    } else {
      throw new TypeError('StatusStore requires a ShmdataStore')
    }

    if (statStore instanceof StatStore) {
      this.statStore = statStore
    } else {
      throw new TypeError('QuiddityStatusStore requires a StatStore')
    }

    reaction(
      () => this.quiddityStore.userQuiddityIds,
      () => this.handleQuiddityStatusesUpdate()
    )

    reaction(
      () => Array.from(this.statStore.stats.values()),
      () => this.handleQuiddityStatusesUpdate()
    )
  }

  /** Handles all quiddity updates */
  handleQuiddityStatusesUpdate () {
    this.populateQuiddityStatuses()
    this.cleanQuiddityStatuses()
  }

  /** Populates all the quiddity statuses */
  populateQuiddityStatuses () {
    const { quiddityStore } = this

    for (const quiddityId of quiddityStore.sourceIds) {
      const status = this.makeQuiddityStatus(QuiddityTagEnum.SOURCE, quiddityId)
      this.setQuiddityStatus(QuiddityTagEnum.SOURCE, quiddityId, status)
    }

    for (const quiddityId of quiddityStore.destinationIds) {
      const status = this.makeQuiddityStatus(QuiddityTagEnum.DESTINATION, quiddityId)
      this.setQuiddityStatus(QuiddityTagEnum.DESTINATION, quiddityId, status)
    }
  }

  /** Cleans all the quiddity statuses */
  cleanQuiddityStatuses () {
    for (const [quiddityId] of this.sourceStatuses) {
      if (!this.quiddityStore.quiddities.has(quiddityId)) {
        this.removeQuiddityStatus(QuiddityTagEnum.SOURCE, quiddityId)
      }
    }

    for (const [quiddityId] of this.destinationStatuses) {
      if (!this.quiddityStore.quiddities.has(quiddityId)) {
        this.removeQuiddityStatus(QuiddityTagEnum.DESTINATION, quiddityId)
      }
    }
  }

  /**
   * Adds two statuses from quiddities that are a source and a destination
   * @param {models.StatusEnum} sourceSatus - Status of the source
   * @param {models.StatusEnum} destinationStatus - Status of the destination
   * @returns {models.StatusEnum} Global status of the quiddity
   */
  makeGlobalQuiddityStatus (sourceSatus, destinationStatus) {
    const { DANGER, BUSY, INACTIVE, ACTIVE } = StatusEnum

    if (sourceSatus === DANGER || destinationStatus === DANGER) {
      return DANGER
    } else if (sourceSatus === BUSY || destinationStatus === BUSY) {
      return BUSY
    } else if (sourceSatus === ACTIVE || destinationStatus === ACTIVE) {
      return ACTIVE
    } else {
      return INACTIVE
    }
  }

  /**
   * Gets the status of a quiddity
   * It checks the shmdata written or read by the quiddity:
   * + if the quiddity doesn't write or read shmdata, it is INACTIVE
   * + if the quiddity writes or reads shmdata and
   *   - all the stats of the shmdatas are active, the status is ACTIVE
   *   - all the stats of the shmdatas are inactive, the status is ERROR
   *   - some stats of the shmdatas are inactive, the status is WARNING
   * @param {string} quiddityId - ID of the quiddity
   * @returns {enums.StatusEnum} Status of the quiddity
   */
  makeQuiddityStatus (quiddityTag, quiddityId) {
    const shmdataSet = this.shmdataStore.getShmdataSet(quiddityTag, quiddityId)
    const inactiveShmdatas = this.statStore.getInactiveShmdatas(quiddityId)

    let status

    if (shmdataSet.size <= 0) {
      status = StatusEnum.INACTIVE
    } else if (inactiveShmdatas.length === shmdataSet.size) {
      status = StatusEnum.DANGER
    } else if (inactiveShmdatas.length > 0) {
      status = StatusEnum.BUSY
    } else {
      status = StatusEnum.ACTIVE
    }

    return status
  }

  /**
   * Sets a quiddity status
   * @param {models.QuiddityTagEnum} quiddityTag - The tag of the quiddity
   * @param {string} quiddityId - The ID of the quiddity
   * @param {models.StatusEnum} - The status of the quiddity
   */
  setQuiddityStatus (quiddityTag, quiddityId, quiddityStatus) {
    if (quiddityTag === QuiddityTagEnum.SOURCE) {
      this.sourceStatuses.set(quiddityId, quiddityStatus)
    } else if (quiddityTag === QuiddityTagEnum.DESTINATION) {
      this.destinationStatuses.set(quiddityId, quiddityStatus)
    }
  }

  /**
   * Removes a quiddity status
   * @param {models.QuiddityTagEnum} quiddityTag - The tag of the quiddity
   * @param {string} quiddityId - The ID of the quiddity
   */
  removeQuiddityStatus (quiddityTag, quiddityId) {
    if (quiddityTag === QuiddityTagEnum.SOURCE) {
      this.sourceStatuses.delete(quiddityId)
    } else if (quiddityTag === QuiddityTagEnum.DESTINATION) {
      this.destinationStatuses.delete(quiddityId)
    }
  }

  /** Clears all stored statuses */
  clear () {
    this.sourceStatuses.clear()
    this.destinationStatuses.clear()
  }
}

export default QuiddityStatusStore

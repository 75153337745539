import { observable, action, makeObservable } from 'mobx'

import Store from '@stores/Store'

/**
 * @classdesc Stores all modals
 * @extends stores.Store
 * @memberof stores
 */
class ModalStore extends Store {
  /** @property {Set<string>} modals - The Set of all registered modals */
  modals = new Set()

  /** @property {Map<string, function>} modalProps - Contextual properties of the modal by modal ID */
  modalProps = new Map()

  /** @property {?string} activeModal - The currently active modal */
  activeModal = null

  constructor (socketStore) {
    super(socketStore)

    makeObservable(this, {
      modals: observable,
      modalProps: observable,
      activeModal: observable,
      clearActiveModal: action,
      setActiveModal: action,
      addModal: action,
      removeModal: action,
      setModalProps: action
    })
  }

  /**
   * Cleans the active modal
   * @param {string} modalId - The modal to clean
   */
  cleanActiveModal (modalId) {
    if (this.activeModal === modalId) {
      this.clearActiveModal()
    }
  }

  /** Clears the active modal */
  clearActiveModal () {
    this.activeModal = null
  }

  /**
   * Sets the new active modal
   * @param {string} modalId - The active modal's ID
   */
  setActiveModal (modalId) {
    if (this.modals.has(modalId)) {
      this.activeModal = modalId
    }
  }

  /**
   * Registers a new modal
   * @param {string} modalId - The modal's ID to register
   */
  addModal (modalId) {
    this.modals.add(modalId)
  }

  /**
   * Unregisters a modal
   * @param {string} modalId - The modal's ID to unregister
   */
  removeModal (modalId) {
    this.modals.delete(modalId)
    this.modalProps.delete(modalId)
  }

  /**
   * Sets a modal binding
   * @param {string} modalId - The modal's ID to bind
   * @param {function} props - The props for the modal
   */
  setModalProps (modalId, props = Function.prototype) {
    if (this.modals.has(modalId)) {
      this.modalProps.set(modalId, props)
    }
  }
}

export default ModalStore

import React, { useContext } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import HelpWrapper from '@components/wrappers/HelpWrapper'
import { AppStoresContext } from '@components/App'

import { Inputs, Context } from '@sat-mtl/ui-components'

import '@styles/bars/StatusBar.scss'

const { Select } = Inputs
const { ThemeContext } = Context

/**
 * Renders the status bar section for the memory usage
 * @memberof module:components/bars
 * @returns {external:mobx-react/ObserverComponent} The memory usage section
 */
const MemoryUsageSection = observer(() => {
  const { systemUsageStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  return (
    <HelpWrapper message={t('Current memory usage')}>
      <section
        id='MemorySection'
        className={classNames('Status', `Status-${theme}`)}
      >
        <h4>
          {t('Memory')}
        </h4>
        <p>
          {systemUsageStore.memory.readableUsagePercent}
        </p>
      </section>
    </HelpWrapper>
  )
})

/**
 * Renders the status bar section for the CPU usage
 * @memberof module:components/bars
 * @returns {external:mobx-react/ObserverComponent} The CPU usage section
 */
const CpuUsageSection = observer(() => {
  const { systemUsageStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  return (
    <HelpWrapper message={t('Current load for each CPU core')}>
      <section
        id='CpuSection'
        className={classNames('Status', `Status-${theme}`)}
      >
        <h4>
          {t('CPU')}
        </h4>
        <div id='CpuBars'>
          {Array.from(systemUsageStore.cpuCores).map(([id, core]) => (
            <CpuBar
              key={id}
              id={id}
              core={core}
            />
          ))}
        </div>
      </section>
    </HelpWrapper>
  )
})

/**
 * Renders a bar that represents the status of a CPU
 * @param {string} props.id - ID of the CPU core
 * @param {object} props.core - Description of the CPU core
 * @memberof module:components/bars
 * @returns {external:react/Component} A CPU status bar
 */
function CpuBar ({ id, core }) {
  return (
    <div
      data-cpu={id}
      className={`CpuBar CpuBar-${core.status}`}
      style={{
        height: `${core.percent}%`
      }}
    />
  )
}

/**
 * Renders the RX rate of the selected network interface
 * @memberof module:components/bars
 * @returns {external:mobx-react/ObserverComponent} The RX rate
 */
const NetworkInterfaceRx = observer(() => {
  const { systemUsageStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <HelpWrapper message={t('Number of bits received by the selected interface')}>
      <section id='NetworkRx'>
        <h5>
          Rx:
        </h5>
        <p>
          {systemUsageStore.selectedInterface.readableRxRate}
        </p>
      </section>
    </HelpWrapper>
  )
})

/**
 * Renders the TX rate of the selected network interface
 * @memberof module:components/bars
 * @returns {external:mobx-react/ObserverComponent} The TX rate
 */
const NetworkInterfaceTx = observer(() => {
  const { systemUsageStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <HelpWrapper message={t('Number of bits transmitted by the selected interface')}>
      <section id='NetworkTx'>
        <h5>
          Tx:
        </h5>
        <p>
          {systemUsageStore.selectedInterface.readableTxRate}
        </p>
      </section>
    </HelpWrapper>
  )
})

/**
 * Renders the IPv4 address of the selected network interface
 * @memberof module:components/bars
 * @returns {external:mobx-react/ObserverComponent} The IP address
 */
const NetworkInterfaceIp = observer(() => {
  const { systemUsageStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <HelpWrapper message={t('IP address associated with the selected interface')}>
      <section id='NetworkIp'>
        <h5>
          IP:
        </h5>
        <p>
          {systemUsageStore.selectedInterface.ipAddress}
        </p>
      </section>
    </HelpWrapper>
  )
})

/**
 * Renders the select input for network interfaces
 * @memberof module:components/bars
 * @returns {external:mobx-react/ObserverComponent} The network interace select input
 */
const NetworkInterfaceSelect = observer(() => {
  const { systemUsageStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <HelpWrapper message={t('Select the network interface to monitor')}>
      <section id='NetworkInterface'>
        <h5>
          Interface:
        </h5>
        <Select
          id='NetworkInterfaceSelect'
          placeholder={t('Interface')}
          place='top'
          size='tiny'
          options={systemUsageStore.interfaceOptions}
          selected={systemUsageStore.selectedInterfaceOption}
          onSelection={(id) => systemUsageStore.updateInterfaceSelection(id)}
        />
      </section>
    </HelpWrapper>
  )
})

/**
 * Renders the status bar section for the network interfaces
 * @memberof module:components/bars
 * @returns {external:react/Component} The network interface status
 */
function NetworkInterfaceSection () {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const cl = classNames('Status', `Status-${theme}`)

  return (
    <section id='NetworkInterfaceSection' className={cl}>
      <h4>
        {t('Network')}
      </h4>
      <div className={cl}>
        <NetworkInterfaceSelect />
        <NetworkInterfaceIp />
        <NetworkInterfaceRx />
        <NetworkInterfaceTx />
      </div>
    </section>
  )
}

/**
 * Renders the status bar section for the help message
 * @memberof module:components/bars
 * @returns {external:mobx-react/ObserverComponent} The help section
 */
const HelpSection = observer(() => {
  const { helpMessageStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  return (
    <section
      id='HelpSection'
      className={classNames('Status', `Status-${theme}`)}
    >
      {helpMessageStore.helpMessage && (
        <h4>
          {t('Help')}
        </h4>
      )}
      <p>
        {helpMessageStore.helpMessage}
      </p>
    </section>
  )
})

/**
 * Renders the status bar of Scenic
 * @memberof module:components/bars
 * @returns {external:mobx-react/ObserverComponent} The status bar
 */
const StatusBar = observer(() => {
  const { systemUsageStore } = useContext(AppStoresContext)

  return (
    <footer id='StatusBar'>
      {systemUsageStore.hasCpu && (
        <CpuUsageSection />
      )}
      {systemUsageStore.memory && (
        <MemoryUsageSection />
      )}
      {systemUsageStore.hasInterface && (
        <NetworkInterfaceSection />
      )}
      <HelpSection />
    </footer>
  )
})

export default StatusBar

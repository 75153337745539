import React, { useContext } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import PageEnum, { toPageIcon } from '@models/common/PageEnum'
import HelpWrapper from '@components/wrappers/HelpWrapper'

import { Layout, Context, Common } from '@sat-mtl/ui-components'

import scenicSignature from '@assets/Scenic.png'
import '@styles/menus/PageMenu.scss'

import { capitalize } from '@utils/stringTools'

const { FlexColumn, FlexRow } = Layout
const { Button, Icon } = Common
const { ThemeContext } = Context

/**
 * Renders the page's icon
 * @selector `#PageMenu .PageIcon`
 * @param {string} pageId - ID of the page
 * @returns {external:react/Component} Icon of the page
 */
function PageIcon ({ pageId }) {
  return (
    <div className='PageIcon'>
      <Icon type={toPageIcon(pageId)} />
    </div>
  )
}

/**
 * Renders the page's title
 * @selector `#PageMenu .PageTitle`
 * @param {string} pageId - ID of the page
 * @returns {external:react/Component} Title of the page
 */
function PageTitle ({ pageId }) {
  const { t } = useTranslation()

  return (
    <div className='PageTitle'>
      {t(pageId)}
    </div>
  )
}

/**
 * Renders the menu button that triggers the page rendering
 * @selector `#PageMenu .PageButton`
 * @param {string} pageId - ID of the page
 * @param {module:stores/common.PageStore} pageStore - Store all pages
 * @returns {external:mobx-react/ObserverComponent} A button for each page
 */
const PageButton = observer(({ pageId, pageStore }) => {
  const { t } = useTranslation()

  const cl = classNames({ ActivePage: pageStore.activePage === pageId })
  const help = t('Open {{page}} page', { page: capitalize(t(pageId)) })

  return (
    <div className={cl} key={pageId}>
      <HelpWrapper message={help}>
        <Button
          className='PageButton'
          type='heavy'
          onClick={() => pageStore.setActivePage(pageId)}
          shape='rectangle'
        >
          <FlexRow alignItems='center' columnGap={10}>
            <PageIcon pageId={pageId} />
            <PageTitle pageId={pageId} />
          </FlexRow>
        </Button>
      </HelpWrapper>
    </div>
  )
})

/**
 * The signature of Scenic
 * @selector `#PageMenu .ScenicSignature`
 * @returns {external:react/Component} The Scenic logo
 */
function ScenicSignature () {
  return (
    <img
      className='ScenicSignature'
      src={scenicSignature}
      alt='signature'
    />
  )
}

/**
 * Menu that renders all main menus of the Scenic app
 * @selector `#PageMenu`
 * @param {string} pageId - ID of the page
 * @param {module:stores/common.PageStore} pageStore - Store all pages
 * @returns {external:mobx-react/ObserverComponent} The page menu
 */
function PageMenu ({ pageId, pageStore }) {
  const theme = useContext(ThemeContext)

  return (
    <nav id='PageMenu' className={`PageMenu-${theme}`}>
      <ScenicSignature />
      <FlexColumn rowGap={2}>
        {Object.values(PageEnum).map(pageId => (
          <PageButton
            key={pageId}
            pageId={pageId}
            pageStore={pageStore}
          />
        ))}
      </FlexColumn>
    </nav>
  )
}

export default PageMenu

import { dropDecimalUnit, raiseDecimalUnit } from '@utils/numberTools'

/**
 * Enum for all metric units
 * @readonly
 * @memberof models
 * @enum {string}
 */
const UnitEnum = Object.freeze({
  KILOBIT_PER_SECOND: 'kbps', // kilobit per second
  BIT_PER_SECOND: 'bps' // Bit per second
})

/**
 * Converts an unit to a displayable number
 * @param {model.UnitEnum} unit
 * @param {number} value - The value in a specific unit
 * @returns {number} The converted value
 */
function toNumber (unit, value) {
  let convertedValue

  switch (unit) {
    case UnitEnum.KILOBIT_PER_SECOND: {
      convertedValue = dropDecimalUnit(value, 3)
      break
    }

    case UnitEnum.BIT_PER_SECOND: {
      convertedValue = dropDecimalUnit(value, 6)
      break
    }

    default: {
      convertedValue = value
    }
  }

  return convertedValue
}

/**
 * Converts displayed number to its original unit
 * @param {model.UnitEnum} unit
 * @param {number} convertedValue - The value used in the UI
 * @returns {number} The value in its unit
 */
function fromNumber (unit, convertedValue) {
  let realValue

  switch (unit) {
    case UnitEnum.KILOBIT_PER_SECOND: {
      realValue = raiseDecimalUnit(convertedValue, 3)
      break
    }

    case UnitEnum.BIT_PER_SECOND: {
      realValue = raiseDecimalUnit(convertedValue, 6)
      break
    }

    default: {
      realValue = convertedValue
    }
  }

  return realValue
}

export default UnitEnum
export { toNumber, fromNumber }

import React, { useContext, useState, useEffect } from 'react'
import { observer } from 'mobx-react'

import { useTranslation } from 'react-i18next'
import { useHover } from '@utils/uiTools'

import { DEFAULT_NICKNAME } from '@stores/quiddity/NicknameStore'

import {
  DEFAULT_PROPERTY_GROUP_ID,
  DEFAULT_PROPERTY_GROUP_TITLE
} from '@stores/quiddity/PropertyStore'

import StatusEnum from '@models/common/StatusEnum'
import { EXTERNAL_SHMDATA_SOURCE_KIND_ID } from '@models/quiddity/specialQuiddities'

import NumberField from '@components/fields/NumberField'

import StringField from '@components/fields/StringField'
import SelectionField from '@components/fields/SelectionField'
import BooleanField from '@components/fields/BooleanField'
import ColorField from '@components/fields/ColorField'
import { Lock } from '@components/common/LockIcons'

import { DRAWER_WIDTH } from '@stores/common/DrawerStore'
import HelpWrapper from '@components/wrappers/HelpWrapper'

import '@styles/drawers/PropertyInspectorDrawer.scss'

import { Common, Layout, Feedback, Inputs } from '@sat-mtl/ui-components'
import RenameNicknameModal, { RENAME_NICKNAME_MODAL_ID } from '@components/modals/RenameNicknameModal'

import { AppStoresContext } from '@components/App'

const { Drawer, Tooltip } = Feedback
const { InputText } = Inputs
const { FlexColumn, FlexRow } = Layout
const { Icon, Button, Tag, Menu: { Wrapper, Group, Item } } = Common

/**
 * @constant {string} PROPERTY_INSPECTOR_DRAWER_ID - ID of the property inspector drawer
 * @memberof module:components/drawers.PropertyInspectorDrawer
 */
const PROPERTY_INSPECTOR_DRAWER_ID = 'PropertyInspectorDrawer'

/**
 * Renders the Input used to change a quiddity's nickname
 * @selector `.NicknameInput`
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @param {Function} onChange - Function triggered when the user changes the input
 * @param {string} editedNickname - The edited nickname of the scene
 * @param {Function} editNickname - Function called with the new edited nickname state
 * @returns {external:mobx-react/ObserverComponent} The Input component
 */
const NicknameInput = observer(({ quiddityId, onChange }) => {
  const { t } = useTranslation()
  const { nicknameStore } = useContext(AppStoresContext)

  const currentNickname = nicknameStore.nicknames.get(quiddityId)
  const [editedNickname, editNickname] = useState(undefined)

  useEffect(() => {
    editNickname(currentNickname)
  }, [currentNickname])

  return (
    <div className='NicknameInput'>
      <InputText
        placeholder={t('Set a new name')}
        value={editedNickname}
        onChange={e => editNickname(e.target.value)}
        onBlur={() => onChange(editedNickname)}
        onPressEnter={() => onChange(editedNickname)}
      />
    </div>
  )
})

/**
 * Renders the description of the selected quiddity, this description is stored with the quiddity kind
 * @selector `.QuiddityDescription`
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @param {string} quiddityId - Id of the quiddity
 * @returns {external:mobx-react/ObserverComponent} The translated quiddity's description
 */
const QuiddityDescription = observer(({ quiddityId }) => {
  const { t } = useTranslation()
  const { quiddityStore } = useContext(AppStoresContext)
  const kind = quiddityStore.usedKinds.get(quiddityId)

  return (
    <div className='QuiddityDescription'>
      {kind?.description ? t(kind.description) : t('No description')}
    </div>
  )
})

/**
 * Renders the status of the selected quiddity
 * @selector `.QuiddityStatus`
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @param {string} quiddityId - Id of the quiddity
 * @returns {external:mobx-react/ObserverComponent} A Tag component with the quiddity's status
 */
const QuiddityStatus = observer(({ quiddityId }) => {
  const { t } = useTranslation()
  const { quiddityStatusStore } = useContext(AppStoresContext)

  let status = quiddityStatusStore.quiddityStatuses.get(quiddityId)

  if (!status) {
    status = StatusEnum.INACTIVE
  }

  return (
    <Tag className='QuiddityStatus' status={status}>
      {t(status)}
    </Tag>
  )
})

/**
 * Renders the Inspector's header with the selected quiddity informations
 * @todo Change input update method
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @param {string} quiddityId - Id of the quiddity
 * @returns {external:mobx-react/ObserverComponent} The Inspector's header component
 */
const QuiddityHeader = observer(({ quiddityId }) => {
  const { nicknameStore, matrixStore, modalStore } = useContext(AppStoresContext)
  const currentNickname = nicknameStore.nicknames?.get(quiddityId)
  const [renamedNickname, renameNickname] = useState(null)

  return (
    <FlexColumn className='QuiddityHeader' rowGap={8}>
      {renamedNickname && renamedNickname !== currentNickname && <RenameNicknameModal
        newNickname={renamedNickname}
        oldNickname={currentNickname}
        onCancel={() => {
          renameNickname(currentNickname)
          renameNickname(null)
          modalStore.clearActiveModal()
        }}
        onRename={async () => {
          await nicknameStore.applyNicknameUpdate(quiddityId, renamedNickname)
          renameNickname(null)
          modalStore.clearActiveModal()
        }}
                                                                 />}
      <NicknameInput
        quiddityId={quiddityId}
        onChange={newNickname => {
          const currentNickname = nicknameStore.nicknames.get(quiddityId)

          const isDefined = newNickname !== '' && newNickname !== null
          const isDifferent = newNickname !== currentNickname

          if (isDefined && isDifferent) {
            renameNickname(newNickname)
          }
        }}
      />
      <FlexRow alignItems='center' justifyContent='space-between'>
        <QuiddityDescription
          quiddityId={quiddityId}
        />
        <FlexRow>
          {matrixStore.lockedQuiddities.has(quiddityId) && <Lock />}
          <QuiddityStatus
            quiddityId={quiddityId}
          />
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  )
})

/**
 * Renders the Delete button to delete quiddity
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @selector `.DeleteQuiddityButton`
 * @param {string} quiddityId - Id of the quiddity
 * @returns {external:mobx-react/ObserverComponent} A ui-component Button
 */
const DeleteQuiddityButton = observer(({ quiddityId }) => {
  const { quiddityStore, nicknameStore, drawerStore, matrixStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  const nickname = nicknameStore.nicknames.get(quiddityId)
  const isDeletable = !matrixStore.lockedQuiddities.has(quiddityId)
  const quiddityType = quiddityStore.quiddityLabels.get(quiddityId)

  let help = t(
    "Delete '{{nickname}}' {{quiddityType}}",
    { nickname: nickname || t(DEFAULT_NICKNAME), quiddityType }
  )

  if (!isDeletable) {
    help = t(
      'Cannot delete the {{quiddityType}} while some connections are locked.',
      { quiddityType }
    )
  }

  return (
    <HelpWrapper message={help}>
      <Button
        className='DeleteQuiddityButton'
        type={StatusEnum.DANGER}
        shape='rectangle'
        disabled={!isDeletable}
        onClick={() => {
          quiddityStore.applyQuiddityRemoval(quiddityId)
          drawerStore.cleanActiveDrawer(PROPERTY_INSPECTOR_DRAWER_ID)
        }}
      >
        {!isDeletable && <Lock />}
        {t('Delete')}
      </Button>
    </HelpWrapper>
  )
})

/**
 * Renders the Reset button to reset quiddity's properties
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @selector `.ResetPropertiesButton`
 * @param {string} quiddityId - Id of the quiddity
 * @returns {external:mobx-react/ObserverComponent} A ui-component Button
 */
const ResetPropertiesButton = observer(({ quiddityId }) => {
  const { quiddityStore, propertyStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  const isStarted = propertyStore.startedQuiddities.has(quiddityId)
  const quiddityType = quiddityStore.quiddityLabels.get(quiddityId)

  let help = t(
    'Reset {{quiddityType}}\'s parameters to their default values',
    { quiddityType }
  )

  if (isStarted) {
    help = t(
      'Cannot reset the properties while the {{quiddityType}} is started.',
      { quiddityType }
    )
  }

  return (
    <HelpWrapper message={help}>
      <Button
        className='ResetPropertiesButton'
        type='secondary'
        shape='rectangle'
        disabled={isStarted}
        onClick={() => {
          propertyStore.applyPropertyResetAll(quiddityId)
        }}
      >
        {t('Reset')}
      </Button>
    </HelpWrapper>
  )
})

/**
 * Renders all properties of a quiddity in a Menus.Wrapper.
 * If the quiddity has no properties to display, a default message is displayed
 * @see [Menus.Wrapper documentation]{@link https://sat-mtl.gitlab.io/telepresence/ui-components/}
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @param {string} quiddityId - Id of the quiddity
 * @returns {external:react/Component} A Menus.Wrapper component with all properties
 */
function QuiddityProperties ({ quiddityId }) {
  const { t } = useTranslation()
  const { propertyStore } = useContext(AppStoresContext)

  let properties = []

  if (propertyStore.properties.has(quiddityId)) {
    if (propertyStore.isStartable(quiddityId)) {
      properties = [
        <StartedProperty
          key='started'
          quiddityId={quiddityId}
        />
      ]
    } else {
      properties = []
    }

    if (propertyStore.hasGroupedProperties(quiddityId)) {
      properties.push(
        <PropertyGroups
          key='grouped'
          quiddityId={quiddityId}
        />
      )
    } else if (propertyStore.hasOrphanedProperties(quiddityId)) {
      properties.push(
        <PropertyChildren
          key='orphaned'
          quiddityId={quiddityId}
          groupId={DEFAULT_PROPERTY_GROUP_ID}
        />
      )
    }
  }

  let $properties = t('No properties')

  if (properties.length > 0) {
    $properties = (
      <Wrapper width='100%'>
        {properties}
      </Wrapper>
    )
  }

  return $properties
}

/**
 * Renders all property groups
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @param {string} quiddityId - ID of the selected quiddity
 * @returns {external:react/Component} An array of all rendered property groups
 */
function PropertyGroups ({ quiddityId }) {
  const [activeGroupId, setActiveGroupId] = useState(null)
  const { propertyStore } = useContext(AppStoresContext)

  const $groups = []

  for (const [groupId] of propertyStore.groupedProperties.get(quiddityId)) {
    $groups.push(
      <PropertyGroup
        key={groupId}
        quiddityId={quiddityId}
        groupId={groupId}
        isActiveGroup={activeGroupId === groupId}
        onGroupClick={groupId => {
          if (activeGroupId === groupId) {
            setActiveGroupId(null)
          } else {
            setActiveGroupId(groupId)
          }
        }}
      />
    )
  }

  return $groups
}

/**
 * Renders all children of a property group (except the `started` property)
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @param {string} groupId - ID of the property group
 * @param {string} quiddityId - ID of the selected quiddity
 * @see [Menus.Item documentation]{@see https://sat-mtl.gitlab.io/telepresence/ui-components/}
 * @returns {external:react/ObserverComponent} All the properties as Items
 */
const PropertyChildren = observer(({ quiddityId, groupId }) => {
  const { propertyStore } = useContext(AppStoresContext)
  const { groupedProperties } = propertyStore
  return Array.from(groupedProperties.get(quiddityId)?.get(groupId) || [])
    .filter(property => !propertyStore.isStartableProperty(property.id))
    .filter(property => !propertyStore.isHidden(quiddityId, property.id))
    .map(property => (
      <Item key={property.id} type='panel'>
        <CommonProperty
          quiddityId={quiddityId}
          propertyId={property.id}
        />
      </Item>
    ))
})

/**
 * Renders a property Group as a Menus.Group
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @selector `.GroupTitle[data-menu="name of the group"]`
 * @param {string} quiddityId - ID of the quiddity
 * @param {string} groupId - ID of the property group
 * @param {Function} onGroupClick - Function triggered when the user clicks on it
 * @param {boolean} isActiveGroup - Flag a selected group by the user
 * @see [Menus.Group documentation]{@link https://sat-mtl.gitlab.io/telepresence/ui-components/}
 * @returns {external:react/Component} A Menus.Group component mapped on a property group
 */
function PropertyGroup ({ quiddityId, groupId, onGroupClick, isActiveGroup }) {
  const { t } = useTranslation()
  const { propertyStore } = useContext(AppStoresContext)

  let groupTitle = t(DEFAULT_PROPERTY_GROUP_TITLE)

  if (groupId !== DEFAULT_PROPERTY_GROUP_ID) {
    const groupProperty = propertyStore.properties.get(quiddityId).get(groupId)

    if (groupProperty) {
      groupTitle = t(groupProperty.title)
    }
  }

  return (
    <Group
      key={groupId}
      accordeon
      showGroup={isActiveGroup}
      title={groupTitle}
      addonBefore={<span>+</span>}
      dataMenu={groupTitle}
      onClick={() => onGroupClick(groupId)}
    >
      <PropertyChildren
        quiddityId={quiddityId}
        groupId={groupId}
      />
    </Group>
  )
}

/**
 * Renders all common properties
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @param {string} quiddityId - ID of the quiddity
 * @param {string} propertyId - ID of the property to display
 * @returns {external:react/Component} A rendered property
 */
const CommonProperty = observer(({ quiddityId, propertyId }) => {
  const { propertyStore } = useContext(AppStoresContext)

  let $property = (
    <PropertyField
      quiddityId={quiddityId}
      propertyId={propertyId}
    />
  )

  if (!propertyStore.isEnabled(quiddityId, propertyId)) {
    $property = (
      <DisabledPropertyTooltip quiddityId={quiddityId}>
        {$property}
      </DisabledPropertyTooltip>
    )
  }

  return $property
})

/**
 * Renders the started property (returns null if the quiddity is not startable)
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @param {string} quiddityId - ID of the quiddity
 * @returns {?external:react/Component} The started property field
 */
function StartedProperty ({ quiddityId }) {
  const { propertyStore } = useContext(AppStoresContext)
  let $property = null

  if (propertyStore.isStartable(quiddityId)) {
    const property = propertyStore.getStartableProperty(quiddityId)

    $property = (
      <Item key={property.id} type='panel'>
        <PropertyField
          quiddityId={quiddityId}
          propertyId={property.id}
        />
      </Item>
    )
  }

  return $property
}

/**
 * Tooltip displayed when a property is disabled
 * @memberof module:components/drawers.PropertyInspectorDrawer
 * @param {string} quiddityId - ID of the quiddity
 * @param {external:react/Component} children - Node wrapped by the tooltip
 * @returns {external:react/Component} The tooltip
 */
function DisabledPropertyTooltip ({ quiddityId, children }) {
  const { t } = useTranslation()
  const { quiddityStore, propertyStore } = useContext(AppStoresContext)
  const quidTag = quiddityStore.quiddityLabels.get(quiddityId)

  let $content = t('This property is disabled by another property')

  if (propertyStore.isStarted(quiddityId)) {
    $content = t(
      'This property is disabled when the {{quidTag}} is started',
      { quidTag: quidTag }
    )
  }

  return (
    <Tooltip
      side='left'
      content={(
        <FlexRow alignItems='center'>
          <div className='DisabledTooltipIcon'>
            <Icon type='alert' />
          </div>
          {$content}
        </FlexRow>
      )}
    >
      {children}
    </Tooltip>
  )
}

/**
 * Renders the Field used to change a property according to its type
 * @selector `.PropertyField[data-quiddity="quiddityId"]`
 * @selector `.PropertyField[data-nickname="quiddityNickname"]`
 * @selector `.PropertyField[data-property="propertyId"]`
 * @param {string} quiddityId - ID of the quiddity
 * @param {string} propertyId - ID of the property
 * @returns {?external:mobx-react/ObserverComponent} The property field
 * There is an edge case that enables the property for a locked quiddity when:
 * - It is a destination
 * - And it is a started property
 * Because, there is no other way to deactivate the RTMP and the NDI destinations
 */
const PropertyField = observer(({ quiddityId, propertyId }) => {
  const { propertyStore, matrixStore, quiddityStore, nicknameStore } = useContext(AppStoresContext)

  const property = propertyStore.properties.get(quiddityId)?.get(propertyId)
  const value = propertyStore.values.get(quiddityId)?.get(propertyId)
  const error = propertyStore.errors.get(quiddityId)?.get(propertyId)

  const isEnabled = propertyStore.isEnabled(quiddityId, propertyId)
  const isLocked = matrixStore.lockedQuiddities.has(quiddityId)
  const isDestination = quiddityStore.destinationIds.includes(quiddityId)
  const isStartedProperty = propertyStore.isStartableProperty(propertyId)

  const quiddityNickname = nicknameStore.nicknames.get(quiddityId)

  let $field = null

  const fieldProperties = {
    ...property.toFieldProps(),
    // We need to add a key attribute here because otherwise, when displaying properties
    // of two quiddities of the same kind, react will reuse the same instance of the components
    // of the properties with the same type. By putting a key, we are telling react that the components
    // should be distinct even though they are of the same type and they are at the same location in the dom.
    key: `${quiddityId}-${property.id}`,
    status: error ? StatusEnum.DANGER : StatusEnum.FOCUS,
    value: value !== undefined ? value : property.value,
    description: error || property.description,
    disabled: !isEnabled || (isLocked && !(isDestination && isStartedProperty)),
    onChange: (value) => propertyStore.applyNewPropertyValue(quiddityId, propertyId, value)
  }

  if (property.isNumber()) {
    $field = (
      <NumberField {...fieldProperties} />
    )
  } else if (property.isPassword()) {
    $field = (
      <StringField isPassword {...fieldProperties} />
    )
  } else if (property.isString()) {
    $field = (
      <StringField {...fieldProperties} />
    )
  } else if (property.isSelection()) {
    $field = (
      <SelectionField
        option={property.getSelection(value)}
        {...fieldProperties}
      />
    )
  } else if (property.isBoolean()) {
    $field = (
      <BooleanField {...fieldProperties} />
    )
  } else if (property.isColor()) {
    $field = (
      <ColorField {...fieldProperties} />
    )
  }

  if ($field) {
    $field = (
      <div
        className='PropertyField'
        data-quiddity={quiddityId}
        data-nickname={quiddityNickname}
        data-property={propertyId}
      >
        {$field}
      </div>
    )
  }

  return $field
})

/**
 * Drawer that displays all property of a selected quiddity
 * @selector `#PropertyInspectorDrawer`
 * @returns {?external:mobx-react/ObserverComponent} The property inspector drawer
 */
const PropertyInspectorDrawer = observer(() => {
  const { t } = useTranslation()
  const { drawerStore, quiddityStore, modalStore } = useContext(AppStoresContext)
  const [hoverRef, isHovered] = useHover()
  const isActive = drawerStore.activeDrawer === PROPERTY_INSPECTOR_DRAWER_ID

  let $header = t('Property Inspector')
  let $content = t('No source or destination selected')
  let $footer = null

  if (isActive && quiddityStore.selectedQuiddity) {
    const quiddityId = quiddityStore.selectedQuiddity.id

    $header = (
      <QuiddityHeader
        quiddityId={quiddityId}
      />
    )

    $content = (
      <QuiddityProperties
        quiddityId={quiddityId}
      />
    )

    $footer = (
      <FlexRow justifyContent='space-between'>
        {quiddityStore.selectedQuiddity.kindId !== EXTERNAL_SHMDATA_SOURCE_KIND_ID && <ResetPropertiesButton quiddityId={quiddityId} />}
        <DeleteQuiddityButton quiddityId={quiddityId} />
      </FlexRow>
    )
  }

  useEffect(() => {
    drawerStore.addDrawer(PROPERTY_INSPECTOR_DRAWER_ID)

    return () => {
      drawerStore.removeDrawer(PROPERTY_INSPECTOR_DRAWER_ID)
    }
  })

  return (
    <div id={PROPERTY_INSPECTOR_DRAWER_ID} ref={hoverRef}>
      {isActive && isHovered && modalStore.activeModal !== RENAME_NICKNAME_MODAL_ID && <div className='DrawerOverflow' />}
      <Drawer
        visible={isActive}
        header={$header}
        footer={$footer}
        width={DRAWER_WIDTH}
        withBackdrop={false}
      >
        {$content}
      </Drawer>
    </div>
  )
})

export default PropertyInspectorDrawer

export {
  PROPERTY_INSPECTOR_DRAWER_ID,
  PropertyField,
  QuiddityHeader,
  NicknameInput,
  QuiddityStatus,
  QuiddityDescription,
  DeleteQuiddityButton,
  ResetPropertiesButton,
  PropertyChildren,
  PropertyGroup,
  PropertyGroups,
  CommonProperty
}

import API from '@api/API'

/**
 * @classdesc API for managing all nicknames
 * @memberof module:api
 * @extends module:api.API
 */
class NicknameAPI extends API {
  /**
   * Gets the nickname of a quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @async
   */
  get (quiddityId) {
    return new Promise((resolve, reject) => {
      this.socket.emit('nickname_get', quiddityId, (error, nickname) => {
        if (error) {
          reject(error)
        } else if (!nickname || typeof nickname !== 'string') {
          reject(new Error(`Cannot get nickname of quiddity ${quiddityId}`))
        } else {
          resolve(nickname)
        }
      })
    })
  }

  /**
   * Sets a nickname of a quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} newNickname - New nickname of the quiddity
   * @async
   */
  set (quiddityId, newNickname) {
    return new Promise((resolve, reject) => {
      this.socket.emit('nickname_set', quiddityId, newNickname, (error) => {
        if (error) {
          reject(error)
        } else {
          resolve(true)
        }
      })
    })
  }

  /**
   * Listens to Switcher for all updates on a nickname
   * @param {Function} onUpdateAction - Function triggered on each update
   * @param {(string|function)} [quiddityMatcher] - Matcher of the updated quiddity ID
   */
  onUpdated (onUpdateAction, quiddityMatcher) {
    this.socket.on('nickname_updated', (quidId, newNickname) => {
      if (this.match(quidId, quiddityMatcher)) {
        onUpdateAction(quidId, newNickname)
      }
    })
  }
}

export default NicknameAPI

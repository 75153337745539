/**
 * @constant
 */
export const SIP_DEFAULT_PORT = 5060

/**
 * @classdesc Model for SIP credentials
 * @memberof models
 */
class SipCredentials {
  /**
   * Instantiates a new SipCredentials model
   * @param {string} [sipUser=''] - SIP username
   * @param {string} [sipServer=''] - SIP server address or domain name
   * @param {string} [sipPassword=''] - SIP password (default is empty string)
   * @param {string} [turnUser=''] - TURN user (default is sipUser)
   * @param {string} [turnServer=''] - TURN server address or domain name (default is sipServer)
   * @param {string} [stunServer=''] - STUN server address or domain name (default is turnServer)
   * @param {string} [turnPassword=''] - TURN password (default is sipPassword)
   * @param {number} [port=5060] - Local SIP port to use for connection
   * @param {number} [port=5060] - Destination SIP port
   */
  constructor (sipUser, sipServer, sipPassword, turnUser, turnServer, stunServer, turnPassword, port, destinationPort) {
    /** @property {string} sipUser - SIP server username */
    this.sipUser = sipUser || ''

    /** @property {string} sipServer - SIP server address or domain name */
    this.sipServer = sipServer || ''

    /** @property {string} sipPassword - SIP server password */
    this.sipPassword = sipPassword || ''

    /** @property {string} turnUser - TURN server user */
    this.turnUser = turnUser || this.sipUser

    /** @property {string} turnServer - TURN server address or domain name */
    this.turnServer = turnServer || this.sipServer

    /** @property {string} stunServer - STUN server address or domain name */
    this.stunServer = stunServer || this.turnServer

    /** @property {string} turnPassword - TURN server password */
    this.turnPassword = turnPassword || this.sipPassword

    /** @property {number} port - Local SIP port used for connection */
    this.port = port || 5060

    /** @property {number} port - Destination SIP port */
    this.destinationPort = destinationPort || 5060
  }

  /** @property {string} uri - SIP login URI (`user@server`) */
  get uri () {
    return this.sipUser + '@' + this.sipServer
  }

  /**
   * Creates a SipCredentials model from a JSON object
   * @static
   * @param {Object} json - JSON object that modelizes SIP credentials
   * @returns {models.SipCredentials}
   */
  static fromJSON (json) {
    const { sipUser, sipServer, sipPassword, turnUser, turnServer, stunServer, turnPassword, port, destinationPort } = json
    return new SipCredentials(sipUser, sipServer, sipPassword, turnUser, turnServer, stunServer, turnPassword, port, destinationPort)
  }

  /**
   * Exports a SipCredentials model to a JSON object
   * @returns {Object} JSON object that modelizes SIP credentials
  */
  toJSON () {
    const { sipUser, sipServer, sipPassword, turnUser, turnServer, stunServer, turnPassword, port, destinationPort } = this

    return {
      sipUser: sipUser,
      sipServer: sipServer,
      sipPassword: sipPassword,
      turnUser: turnUser,
      turnServer: turnServer,
      stunServer: stunServer,
      turnPassword: turnPassword,
      port: Number.parseInt(port),
      destinationPort: Number.parseInt(destinationPort)
    }
  }
}

export default SipCredentials

/**
 * @classdesc Model that displays a help ressource
 * @memberof models
 */
class Ressource {
  /**
   * Instantiates a new ressource model
   * @param {string} [title] - Title of the ressource
   * @param {string} [url] - URL of the ressource
   * @param {string} [icon] - Icon of the ressource
   */
  constructor (title, url, icon) {
    this.title = title
    this.url = url
    this.icon = icon
  }
}

export default Ressource

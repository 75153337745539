import { computed, makeObservable } from 'mobx'

import ShmdataStore from '@stores/shmdata/ShmdataStore'
import SpecStore from '@stores/shmdata/SpecStore'
import ConfigStore from '@stores/common/ConfigStore'

import { logger } from '@utils/logger'
import { RequirementError } from '@utils/errors'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the CapsStore
 * @memberof stores.CapsStore
 */
export const LOG = logger.child({ store: 'CapsStore' })
/**
 * @constant {RegExp} CAPS_PATH_REGEX - Matches all tree paths that represents a shmdata caps update
 * @memberof module:stores/shmdata.CapsStore
 */
export const CAPS_PATH_REGEX = /^\.shmdata\.(writer|follower)\.([\w/-]+)\.?([\w/-]+)?$/

/**
 * @classdesc Stores all caps compatibilities
 * @memberof stores
 * @extends stores.Store
 * @todo Store compatibility by shmdata type and quiddity class instead
 */
class CapsStore {
  /** @property {Map<string, models/shmdata.Capabilities>} - All writer caps hashed by quiddity IDs */
  get writerCaps () {
    const caps = new Map()

    for (const [quidId, shmdatas] of this.shmdataStore.writingShmdatas) {
      caps.set(quidId, new Set([...shmdatas].map(shm => shm.capabilities)))
    }

    return caps
  }

  /** @property {Map<string, models/shmdata.Capabilities>} - All follower caps hashed by quiddity IDs */
  get followerCaps () {
    const caps = new Map()

    for (const [quidId, shmdatas] of this.shmdataStore.followingShmdatas) {
      caps.set(quidId, new Set([...shmdatas].map(shm => shm.capabilities)))
    }

    return caps
  }

  /** @property {Map<string, models/shmdata.Capabilities>} - All caps hashed by quiddity IDs */
  get caps () {
    const caps = new Map([...this.writerCaps])

    for (const [quidId, followerCaps] of this.followerCaps) {
      caps.set(quidId, new Set(caps.has(quidId) ? [...caps.get(quidId), ...followerCaps] : [...followerCaps]))
    }

    return caps
  }

  /**
   * Instantiates a new CapsStore
   * @param {module:stores/shmdata.shmdataStore} shmdataStore - Shmdata manager
   * @param {module:stores/shmdata.SpecStore} specStore - Connection specification manager
   * @constructor
   */
  constructor (shmdataStore, specStore, configStore) {
    makeObservable(this, {
      writerCaps: computed,
      followerCaps: computed
    })

    if (shmdataStore instanceof ShmdataStore) {
      /** @properties {module:stores/shmdata.ShmdataStore} shmdataStore - Shmdata manager */
      this.shmdataStore = shmdataStore
    } else {
      throw new RequirementError(this.constructor.name, 'ShmdataStore')
    }

    if (specStore instanceof SpecStore) {
      /** @properties {module:stores/shmdata.SpecStore} specStore - Connection specification manager */
      this.specStore = specStore
    } else {
      throw new RequirementError(this.constructor.name, 'SpecStore')
    }

    if (configStore instanceof ConfigStore) {
      /** @properties {module:stores/shmdata.configStore} configStore - Connection specification manager */
      this.configStore = configStore
    } else {
      throw new RequirementError(this.constructor.name, 'ConfigStore')
    }
  }

  /**
   * Checks if quiddities are connectable
   * @param {number} srcId - ID of the source quiddity
   * @param {number} dstId - ID of the destination quiddity
   * @param {string} mediaType - the media type of the writing shmdata
   * @returns {boolean} - Returns true if the quiddities are connectable
   */
  areConnectable (srcId, dstId, mediaType) {
    const { followerSpecs } = this.specStore
    if (this.writerCaps.has(srcId) && followerSpecs.has(dstId)) {
      for (const writerCaps of this.writerCaps.get(srcId)) {
        for (const followerSpec of followerSpecs.get(dstId)) {
          if (followerSpec.canDo.has(writerCaps.mediaType) && writerCaps.mediaType === mediaType) {
            return true
          }
        }
      }
    }

    return false
  }

  /**
   * Checks if entries are connectable
   * @param {module:models/matrix.MatrixEntry} sourceEntry - The row entry of the matrix
   * @param {module:models/matrix.MatrixEntry} destinationEntry - The column entry of the matrix
   * @returns {boolean} Returns false if the caps are incompatible
   */
  areEntriesConnectable (sourceEntry, destinationEntry) {
    return this.areConnectable(sourceEntry.quiddityId, destinationEntry.quiddityId)
  }

  /**
   * Finds a compatible writer caps between a source and a destination
   * @param {number} srcId - ID of the source
   * @param {number} dstId - ID of the destination
   * @returns {module:models/shmdata.Capabilities|null} - Found compatible caps
   */
  findCompatibleCaps (srcId, dstId) {
    const { compatibleMediaTypes } = this.specStore
    let found = null

    if (this.writerCaps.has(srcId) && compatibleMediaTypes.has(dstId)) {
      found = [...this.writerCaps.get(srcId)]
        .find(caps => compatibleMediaTypes.get(dstId)?.has(caps.mediaType))
    }

    return found
  }

  /**
   * Flags if the capability is hidden
   * @param {string} capsKey - Key of the caps
   * @returns {boolean} Flags a hidden caps
   */
  isHidden (capsKey) {
    const { configStore: { scenicConfiguration } } = this
    const hiddenCapabilities = scenicConfiguration.hiddenCapabilities || []

    return hiddenCapabilities.includes(capsKey)
  }
}

export default CapsStore

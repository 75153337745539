import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { AppStoresContext } from '@components/App'
import { MenuButton } from '@components/common/Buttons'

import { PROPERTY_INSPECTOR_DRAWER_ID } from '@components/drawers/PropertyInspectorDrawer'
import { SIP_CONTACT_DRAWER_ID } from '@components/drawers/SipContactDrawer'
import { SIP_LOGIN_DRAWER_ID } from '@components/drawers/SipLoginDrawer'

import { NEW_CONTACT_MODAL_ID } from '@components/modals/NewContactModal'

import { Layout, Common } from '@sat-mtl/ui-components'

import './MatrixMenuPanel.scss'

const { FlexColumn } = Layout
const { Icon } = Common

/**
 * Renders the button that opens the PropertyInspectorDrawer
 * @selector `#PropertyInspectorMenu`
 * @returns {module.components/common.MenuButton} A menu button
 */
const PropertyInspectorMenu = observer(() => {
  const { drawerStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <MenuButton
      menuName='PropertyInspectorMenu'
      help={t('Open the property inspector')}
      isActive={drawerStore.activeDrawer === PROPERTY_INSPECTOR_DRAWER_ID}
      onClick={() => drawerStore.toggleActiveDrawer(PROPERTY_INSPECTOR_DRAWER_ID)}
    >
      <Icon type='info' />
    </MenuButton>
  )
})

/**
 * Renders the button that opens the Sip drawers
 * @selector `#SipDrawersMenu`
 * @returns {module.components/common.MenuButton} A menu button
 */
const SipDrawersMenu = observer(() => {
  const { drawerStore, sipStore, modalStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <MenuButton
      menuName='SipDrawersMenu'
      help={t('Open the SIP panel')}
      isActive={[SIP_CONTACT_DRAWER_ID, SIP_LOGIN_DRAWER_ID].includes(drawerStore.activeDrawer)}
      onClick={() => {
        if (sipStore.isConnected) {
          drawerStore.toggleActiveDrawer(SIP_CONTACT_DRAWER_ID)
          modalStore.cleanActiveModal(NEW_CONTACT_MODAL_ID)
        } else {
          drawerStore.toggleActiveDrawer(SIP_LOGIN_DRAWER_ID)
        }
      }}
    >
      <Icon type='contacts' />
    </MenuButton>
  )
})

/** Renders all menus that add extra actions with the Scenic matrix */
function MatrixMenuPanel () {
  return (
    <FlexColumn id='MatrixMenuPanel'>
      <PropertyInspectorMenu />
      <SipDrawersMenu />
    </FlexColumn>
  )
}

export default MatrixMenuPanel

export {
  PropertyInspectorMenu,
  SipDrawersMenu
}

import StatusEnum, { fromLogLevel } from '@models/common/StatusEnum'
import { getLogStore } from '@utils/logger'
import { v1 as uuidv1 } from 'uuid'

/**
 * @classdesc Model for making new notifications
 * @memberof module:models/common
 */
class Notification {
  /** @property {string} id - ID of the notification, it must be strings separated by a dash */
  id = null

  /** @property {string} message - Message of the notification */
  message = null

  /** @property {?string} [description=null] - Description of the notification */
  description = null

  /** @property {models.StatusEnum} [status=StatusEnum.FOCUS] - Status of the notification */
  status = StatusEnum.FOCUS

  /** @property {?number} [duration=5000] - Duration of the notification in millisecond (NaN triggers a permanent notification) */
  duration = 5000

  /**
   * Instantiates a new Notification model
   * @param {string} id - ID of the notification it must be strings separated by a dash
   * @param {string} message - Main message of the notification
   * @param {?string} [description=null] - Description of the notification
   * @param {models.StatusEnum} [status=StatusEnum.FOCUS] - Status of the notification
   * @param {?number} [duration=5000] - Duration of the notification in millisecond
   * @param {string} [notificationClass=''] - Class of the notification
   * @throws {TypeError} Throws an error if the required message is missing
   * @constructor
   */
  constructor (message, description = null, status = StatusEnum.FOCUS, duration = 5000, notificationClass = '') {
    this.id = uuidv1()

    if (typeof message === 'undefined' || typeof message !== 'string') {
      throw new TypeError('Attribute `message` is required and must be a string')
    } else {
      this.message = message
    }

    if (typeof description === 'string') {
      this.description = description
    }

    if (Object.values(StatusEnum).includes(status)) {
      this.status = status
    }

    if (typeof notificationClass === 'string') {
      this.class = notificationClass
    }

    if (typeof duration === 'number') {
      this.duration = duration
    }
  }

  /**
   * Gets the class of the notification
   * @returns {string} Returns the class of the notification or an empty string
   */
  get notificationClass () {
    return this.class || ''
  }

  /**
   * Flags if a notification is permanent
   * @returns {boolean} Flag a permanent notification
   */
  get isPermanent () {
    return Number.isNaN(this.duration)
  }

  /**
   * Creates a sample notification
   * @static
   * @param {string} message - Message of the notification
   * @returns {module:models/common.Notification} A new notification
   */
  static fromString (message) {
    return new Notification(message)
  }

  /**
   * Gets a title from a log level
   * @param {external:pino/level} logLevel - Level of logging
   * @returns {string} A generic notification title
   */
  static getLogTitle (logLevel) {
    switch (logLevel) {
      case 'info': {
        return 'Information'
      }
      case 'warn': {
        return 'Warning'
      }
      case 'error': {
        return 'Critical error'
      }
      default: {
        return 'Debug'
      }
    }
  }

  /**
   * Creates a notification from a log object
   * @static
   * @param {external:pino/level} logLevel - Level of the current logger
   * @param {external:pino/mergingObject} logObject - JSON log from the webapp
   * @param {external:pino/logBindings} logBindings - All bindings transmitted by a child logger
   * @returns {module:models/common.Notification} A new notification
   */
  static fromLog (logLevel, logObject, logBindings) {
    return new Notification(
      logObject.title || Notification.getLogTitle(logLevel),
      logObject.msg,
      logObject.status || fromLogLevel(logLevel),
      logObject.class || getLogStore(logBindings)
    )
  }

  /**
   * Transforms the notification to a JSON object
   * @returns {object} A JSON notification
   */
  toJSON () {
    const {
      id,
      message,
      description,
      status,
      duration
    } = this

    return {
      id,
      message,
      description,
      status,
      duration
    }
  }
}

export default Notification

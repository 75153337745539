import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { AppStoresContext } from '@components/App'
import { Feedback, Common, Layout, Inputs } from '@sat-mtl/ui-components'

import HelpWrapper from '@components/wrappers/HelpWrapper'

import { isStringEmpty } from '@utils/stringTools'
import StatusEnum from '@models/common/StatusEnum'

const { Button } = Common
const { Modal } = Feedback
const { Field, InputText } = Inputs
const { FlexColumn } = Layout

/**
 * @constant {string} SWITCHER_CONNECTION_MODAL_ID - ID of the configuration error modal
 * @memberof module:components/modals.SwitcherConnectionModal
 */
export const SWITCHER_CONNECTION_MODAL_ID = 'SwitcherConnectionModal'

/**
 * Renders the InputText for the host URL
 * @selector `.UrlInput`
 * @memberof module:components/modals.SwitcherConnectionModal
 * @returns {external:react/Component} The host URL text input
 */
export const UrlInput = () => {
  const { socketStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const label = t('Host URL')

  const editUrl = e => socketStore.setHost(e.target.value)

  return (
    <InputText
      className='UrlInput'
      placeholder={label}
      value={socketStore.host || ''}
      onBlur={editUrl}
      onChange={editUrl}
      onPressEnter={e => {
        if (socketStore.hasValidEndpoint) {
          socketStore.applyConnection()
        } else {
          editUrl(e)
        }
      }}
    />
  )
}

/**
 * Renders the input field for the host URL
 * @memberof module:components/modals.SwitcherConnectionModal
 * @returns {external:mobx-react/ObserverComponent} The host URL field input
 */
const UrlField = observer(() => {
  const { socketStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const label = t('Host URL')

  const isWrong = isStringEmpty(socketStore.host)

  return (
    <HelpWrapper message={t('Enter the Switcher host URL')}>
      <Field
        label={label}
        status={isWrong ? StatusEnum.DANGER : StatusEnum.FOCUS}
        description={isWrong && t('Switcher host is required')}
      >
        <UrlInput />
      </Field>
    </HelpWrapper>
  )
})

/**
 * Renders the InputText for the host port
 * @selector `.PortInput`
 * @memberof module:components/modals.SwitcherConnectionModal
 * @returns {external:react/Component} The host port text input
 */
export const PortInput = ({ label }) => {
  const { socketStore } = useContext(AppStoresContext)

  const editPort = e => socketStore.setPort(e.target.value)

  return (
    <InputText
      className='PortInput'
      placeholder={label}
      value={socketStore.port || ''}
      onBlur={editPort}
      onChange={editPort}
      onPressEnter={e => {
        if (socketStore.hasValidEndpoint) {
          socketStore.applyConnection()
        } else {
          editPort(e)
        }
      }}
    />
  )
}

/**
 * Renders the field for the host port
 * @memberof module:components/modals.SwitcherConnectionModal
 * @returns {external:mobx-react/ObserverComponent} The host field input
 */
const PortField = observer(() => {
  const { socketStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const label = t('Host port')

  const isWrong = isStringEmpty(socketStore.port)

  return (
    <HelpWrapper message={t('Enter the Switcher host port')}>
      <Field
        label={label}
        status={isWrong ? StatusEnum.DANGER : StatusEnum.FOCUS}
        description={isWrong && t('Switcher port is required')}
      >
        <PortInput label={label} />
      </Field>
    </HelpWrapper>
  )
})

/**
 * Renders the Connect Button for the Modal
 * @selector `.ConnectButton`
 * @memberof module:components/modals.SwitcherConnectionModal
 * @returns {external:mobx-react/ObservedComponent} The connect button of the Modal
 */
export const SwitcherConnectionButton = observer(() => {
  const { socketStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <Button
      type='primary'
      className='ConnectButton'
      disabled={!socketStore.hasValidEndpoint}
      shape='rectangle'
      onClick={() => socketStore.applyConnection()}
    >
      {t('Connect')}
    </Button>
  )
})

/**
 * Scenic modal used to connect to Switcher
 * @selector `#SwitcherConnectionModal`
 * @memberof module:components/modals
 * @returns {external:mobx-react/ObserverComponent} The connection modal
 */
const SwitcherConnectionModal = observer(() => {
  const { socketStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <Modal
      id={SWITCHER_CONNECTION_MODAL_ID}
      visible={!socketStore.hasActiveSocket}
      header={t('Connection to Switcher instance failed')}
      footer={(
        <HelpWrapper message={t('Start the connection to Switcher')}>
          <SwitcherConnectionButton />
        </HelpWrapper>
      )}
    >
      <FlexColumn rowGap={8}>
        <div className='caption'>
          {t("Enter your Switcher host's address")}
        </div>
        <UrlField />
        <PortField />
      </FlexColumn>
    </Modal>
  )
})

export default SwitcherConnectionModal

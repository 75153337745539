import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { AppStoresContext } from '@components/App'

import { PROPERTY_INSPECTOR_DRAWER_ID } from '@components/drawers/PropertyInspectorDrawer'
import { SIP_CONTACT_DRAWER_ID } from '@components/drawers/SipContactDrawer'

import HelpWrapper from '@components/wrappers/HelpWrapper'
import '@styles/matrix/DestinationHead.scss'

import { Shared } from '@sat-mtl/ui-components'

const { ColumnHead } = Shared

/** Displays a destination head for a destination quiddity
* @selector `#DestinationHead[data-quiddity="nickname"]`
 * @param {models.MatrixEntry} entry - A matrix entry
 * @param {?string} [defaultLabel='NO NICKNAME] - Fallback label if there is no label returned
 * @returns {external:mobx-react/ObserverComponent} - The destination head of a quiddity
*/
const DestinationHead = observer(({ entry, defaultLabel = 'NO NICKNAME' }) => {
  const { quiddityStore, drawerStore, contactStatusStore, quiddityMenuStore, nicknameStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  const nickname = nicknameStore.nicknames.get(entry.quiddity.id)
  const isSelected = !entry.isContact ? quiddityStore.selectedQuiddityIds.has(entry.quiddity.id) : false
  const helpMessage = entry.isContact ? t('Click to display all contacts') : t(`Click to inspect the properties of the ${nickname}`)

  const handleQuiddityCheckup = (event) => {
    const isSelected = quiddityStore.selectedQuiddityIds.has(entry.quiddity.id)

    if (!event.shiftKey) {
      quiddityStore.cleanSelectedQuiddities()
    }

    if (!isSelected) {
      quiddityStore.addSelectedQuiddity(entry.quiddity.id)
      drawerStore.setActiveDrawer(PROPERTY_INSPECTOR_DRAWER_ID)
    } else {
      drawerStore.cleanActiveDrawer(PROPERTY_INSPECTOR_DRAWER_ID)
    }
  }

  return (
    <div id='DestinationHead' data-quiddity={nickname}>
      <HelpWrapper message={helpMessage}>
        <ColumnHead
          onClick={(e) => entry.isContact ? drawerStore.setActiveDrawer(SIP_CONTACT_DRAWER_ID) : handleQuiddityCheckup(e)}
          selected={isSelected}
          title={t(nicknameStore.makeDestinationHeadTitle(entry, defaultLabel))}
          subtitle={t(quiddityMenuStore.makeDestinationHeadSubtitle(entry))}
          status={contactStatusStore.getEntryStatus(entry)}
        />
      </HelpWrapper>
    </div>
  )
})

export default DestinationHead

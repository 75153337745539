import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the string tools
 * @memberof utils
 */
export const LOG = logger.child({ util: 'stringTools' })

/**
 * Escape all new lines into an array
 * @param {string} output - A multilines command line output
 * @returns {string[]} Array of escaped strings
 */
export function escapeAsArray (output) {
  return output
    .split(/(\\n|\n|\r)/) // Match all forms of new lines
    .map(s => s.trim())
    .filter(out => !['', '\n', '\\n'].includes(out)) // Remove empty lines
}

/**
 * Gets a version of the string that is sanitized for CSS purposes.
 * We need this function because we use URIs as category id for sip quiddities and the grid css breaks
 * if invalid or unescaped characters are in css properties.
 * see https://www.w3.org/TR/CSS21/syndata.html#characters for the why of the different regexes
 * @params {string} input
 */
export function escapeToSafeCss (input) {
  const alphanumericPlusHyphenRegex = /[^_a-zA-Z0-9-]/g
  const doesNotStartWithNumbersOrHyphensRegex = /^[-0-9]*/g
  return input.replaceAll(alphanumericPlusHyphenRegex, '')?.replaceAll(doesNotStartWithNumbersOrHyphensRegex, '')
}

/**
 * Transform any value to a string, `null` and `undefined` values are converted to an empty string.
 * @param {?boolean|number|string} input - Value to transform into string
 * @returns {string} A string representation of the input
 */
export function toString (input) {
  return input ? `${input}` : ''
}

/**
 * Checks if a string is empty
 * @param {string} value - String to validate
 * @returns {boolean} True, if the value is empty
 */
export function isStringEmpty (value) {
  let isEmpty = true

  if (value) {
    isEmpty = !value.trim()
  }
  return isEmpty
}

/**
 * Cleans the parenthesis in a string
 * @param {string} input - The string to clean
 * @returns {string} A string without parenthesis
 */
export function cleanParenthesis (input) {
  return input.replace('(', '').replace(')', '')
}

/**
 * Sanitizes a string by replacing all matches with another character
 * @param {string} input - The string to sanitize
 * @param {string} [oldStr='.'] - The character to replace
 * @param {string} [newStr='_'] - The character to replace with
 * @returns {string} A string with dots
 */
export function replaceAll (input, oldStr = '.', newStr = '_') {
  return input.split(oldStr).join(newStr)
}

/**
 * Parses a string date to a javascript Date object
 * @param {string} input - A stringified date
 * @returns {Date} A Date object
 * @see [JavaScript Date documentation]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date}
 */
export function parseDate (input) {
  let parsed

  if (isNaN(Date.parse(input))) {
    LOG.warn({
      msg: 'Input date is malformed. Falling back to current date',
      input: input
    })

    parsed = new Date(Date.now())
  } else {
    parsed = new Date(input)
  }

  return parsed
}

/**
 * Capitalizes the input string. Only the very first letter is capitalized.
 * @param {string} input - The string to capitalize
 * @returns {string} A capitalized string
 */
export function capitalize (input) {
  return input[0].toUpperCase() + input.slice(1)
}

/**
 * @classdesc Error triggered when stores failed their initialization
 * @memberof module:stores/common
 */
class RequirementError extends TypeError {
  name = 'RequirementError'

  /**
   * Instantiates a requirement error
   * @param {string} dependentStore - Name of the store that needs the requirement
   * @param {string} requiredStore - Name of the required store
   */
  constructor (dependentStore, requiredStore) {
    super(`${dependentStore} requires a ${requiredStore}`)
  }
}

/**
 * @classdesc Error triggered when stores failed their initialization
 * @memberof module:stores/common
 */
class InitializationError extends Error {
  name = 'InitializationError'

  /**
   * Instantiates an initialization error
   * @param {store} storeName - Name of the deinitialized store
   */
  constructor (storeName) {
    super(`The ${storeName} is not well-initialized`)
  }
}

export {
  RequirementError,
  InitializationError
}

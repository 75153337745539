/**
 * Socket provides the instance that is interacting with the server
 * @external socketIO/Socket
 * @see [Socket.io `Socket` client API]{@link https://socket.io/docs/v3/client-api/#Socket}
 */

import SwitcherAPI from '@api/switcher/SwitcherAPI'
import SessionAPI from '@api/switcher/SessionAPI'

import QuiddityAPI from '@api/quiddity/QuiddityAPI'
import NicknameAPI from '@api/quiddity/NicknameAPI'
import PropertyAPI from '@api/quiddity/PropertyAPI'
import MethodAPI from '@api/quiddity/MethodAPI'

import UserTreeAPI from '@api/tree/UserTreeAPI'
import InfoTreeAPI from '@api/tree/InfoTreeAPI'
import ConnectionSpecsAPI from '@api/tree/ConnectionSpecsAPI'

import populateAPIs from '@api/populateAPIs'

export default populateAPIs

export const switcher = {
  SwitcherAPI,
  SessionAPI
}

export const quiddity = {
  QuiddityAPI,
  NicknameAPI,
  PropertyAPI,
  MethodAPI
}

export const tree = {
  UserTreeAPI,
  InfoTreeAPI,
  ConnectionSpecsAPI
}

/**
 * Set a cookie
 * @param {string} cfield - The field to set
 * @param {string} cvalue - The value to set
 */
function setCookie (cfield, cvalue) {
  document.cookie = cfield + '=' + cvalue + ';'
}

/**
 * Return the value of a cookie
 * @param {string} cfield - The field to look for
 * @returns {string} The value of a field. Returns an empty string if the value is not saved
 */
function getCookie (cfield) {
  const field = cfield + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(field) === 0) {
      return c.substring(field.length, c.length)
    }
  }
  return ''
}

export { setCookie, getCookie }

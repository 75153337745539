import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import StatusEnum from '@models/common/StatusEnum'
import { CancelButton, ConfirmButton } from '@components/common/Buttons'

import { Feedback, Layout, Inputs } from '@sat-mtl/ui-components'

const { Modal } = Feedback
const { Field, InputText } = Inputs
const { FlexColumn, FlexRow } = Layout

/**
 * @constant {string} CREATE_FILE_MODAL_ID - ID of the create file modal
 * @memberof CreateFileModal
 */
export const NEW_FILE_MODAL_ID = 'NewFileModal'

/**
 * Scenic modal used to create a new file
 * @selector `#NewFileModal`
 * @memberof module:components/modals
 * @returns {external:react/Component} The NewFile modal
 */
function NewFileModal ({ fileNameList = [], selectedFileName, visible, onCancel, onConfirm }) {
  const { t } = useTranslation()

  const [newFileName, setNewFileName] = useState(selectedFileName)
  const [status, setStatus] = useState(StatusEnum.FOCUS)

  const $error = t('This file name already exists')
  const $confirm = status === StatusEnum.DANGER ? t('Overwrite') : t('Create')

  useEffect(() => {
    if (fileNameList.includes(newFileName)) {
      setStatus(StatusEnum.DANGER)
    } else if (status !== StatusEnum.FOCUS) {
      setStatus(StatusEnum.FOCUS)
    }
  }, [newFileName, fileNameList])

  useEffect(() => {
    setNewFileName(selectedFileName)
  }, [visible, selectedFileName])

  const updateValue = e => {
    setNewFileName(e?.target?.value)
  }

  return (
    <Modal
      id='NewFileModal'
      visible={visible}
      header={t('Create a new file')}
      footer={(
        <FlexRow justifyContent='space-around'>
          <CancelButton onCancel={onCancel} />
          <ConfirmButton
            status={status === StatusEnum.DANGER ? 'danger' : 'primary'}
            disabled={!newFileName}
            onConfirm={() => onConfirm(newFileName)}
          >
            {$confirm}
          </ConfirmButton>
        </FlexRow>
      )}
    >
      <FlexColumn rowGap={8}>
        <Field
          status={status}
          title={t('Enter the new file name')}
          description={status === StatusEnum.DANGER && $error}
        >
          <InputText
            className='NewFileNameInput'
            placeholder={t('New file name')}
            value={newFileName || ''}
            onBlur={updateValue}
            onChange={updateValue}
            onPressEnter={updateValue}
          />
        </Field>
      </FlexColumn>
    </Modal>
  )
}

export default NewFileModal

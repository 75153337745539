/**
 * Enum for quiddity tags
 * @readonly
 * @memberof models
 * @enum {string}
 */
const QuiddityTagEnum = Object.freeze({
  SOURCE: 'source',
  DESTINATION: 'destination'
})

export default QuiddityTagEnum

import React, { useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { AppStoresContext } from '@components/App'

import { DRAWER_WIDTH } from '@stores/common/DrawerStore'
import { Feedback, Layout } from '@sat-mtl/ui-components'

import '@styles/panels/NotificationOverlay.scss'

const { FlexColumn } = Layout
const { Notification } = Feedback

/**
 * @constant {number} NOTIFICATION_TRANSITION_TIMEOUT - Timeout in milliseconds when a notification is fading.
 * @memberof components/panels.NotificationOverlay
 */
export const NOTIFICATION_TRANSITION_TIMEOUT = 300

/**
 * Renders an overlying notification
 * @memberof module:components/panels.NotificationOverlay
 * @param {module:models/common.Notification} notification - The notification model
 * @returns {external:mobx/ObserverComponent} The rendered notification with a CSS transition
 */
export const OverlyingNotification = observer(({ notification }) => {
  const { t } = useTranslation()
  const { notificationStore } = useContext(AppStoresContext)

  return (
    <CSSTransition
      classNames='OverlyingNotification'
      appear
      in={!notificationStore.expiredNotifications.has(notification.id)}
      timeout={NOTIFICATION_TRANSITION_TIMEOUT}
      onExited={() => notificationStore.removeNotification(notification)}
    >
      <Notification
        id={notification.id}
        message={t(notification.message)}
        description={t(notification.description)}
        status={notification.status}
        onClick={() => notificationStore.addExpiredNotification(notification.id)}
      />
    </CSSTransition>
  )
})

/**
 * Renders all overlying notifications
 * @memberof module:components/panels.NotificationOverlay
 * @returns {external:mobx/ObserverComponent} All the rendered notification
 */
export const OverlyingNotifications = observer(() => {
  const { notificationStore } = useContext(AppStoresContext)
  // This will cleanup expired notifications if they were pushed to the store
  // in a context where this component doesn't render and it renders afterwards.
  // Otherwise, the notifications stayed in the notificationStore for ever and were
  // diplayed and impossible to remove.
  Array.from(notificationStore.userNotifications)
    .filter(notification => notificationStore.expiredNotifications.has(notification.id))
    .forEach(notification => notificationStore.removeNotification(notification))
  return Array
    .from(notificationStore.userNotifications)
    .map(notification => (
      <OverlyingNotification
        key={notification.id}
        notification={notification}
      />
    ))
})

/**
 * Renders the overlay with all notifications
 * @memberof module:components/panels
 * @returns {external:react/Component} The notification overlay
 */
const NotificationOverlay = observer(() => {
  const { drawerStore } = useContext(AppStoresContext)
  let position = 0

  if (drawerStore.activeDrawer) {
    position += DRAWER_WIDTH
  }

  return (
    <div id='NotificationOverlay' style={{ right: position }}>
      <FlexColumn columnGap={2}>
        <OverlyingNotifications />
      </FlexColumn>
    </div>
  )
})

export default NotificationOverlay

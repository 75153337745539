import React, { useContext, useEffect } from 'react'
import Mousetrap from 'mousetrap'
import { observer } from 'mobx-react'

import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import StatusEnum from '@models/common/StatusEnum'

import { DRAWER_WIDTH } from '@stores/common/DrawerStore'
import HelpWrapper from '@components/wrappers/HelpWrapper'
import FileEntry from '@components/entries/FileEntry'

import { AppStoresContext } from '@components/App'

import { ResetButton } from '@components/common/Buttons'
import { TrashCurrentSessionModal, TRASH_CURRENT_SESSION_MODAL_ID } from '@components/modals/SessionModals'
import { Feedback, Layout, Common, Inputs } from '@sat-mtl/ui-components'

import '@styles/drawers/FileDrawers.scss'

const { FlexRow } = Layout
const { Drawer } = Feedback
const { Button } = Common
const { Checkbox } = Inputs

/**
 * @constant {string} FILE_DELETION_DRAWER_ID - ID of the file handling drawer
 * @memberof module:components/drawers.FileDeletionDrawer
 */
const FILE_DELETION_DRAWER_ID = 'FileDeletionDrawer'

/**
 * Renders the entry of a file to delete
 * @memberof module:components/drawers.FileDeletionDrawer
 * @param {module:models/common.FileBridge} props.file - A file to delete
 * @param {Function} props.onTrash - Function triggered if the file is trashed
 * @param {boolean} props.isCurrent - Flags if this is the current session's file
 * @param {boolean} props.isTrashed - Flags if the file is trashed
 * @returns {external:react/Component} A file entry
 */
function FileDeletionEntry ({ file, onTrash, isCurrent, isTrashed }) {
  const { t } = useTranslation()

  const helpMessage = t('Select {{name}}', { name: file.name })
  const onEntryClick = () => onTrash(file)

  const classes = classNames(
    'FileDeletionEntry',
    { 'FileDeletionEntry-selected': isTrashed }
  )

  return (
    <HelpWrapper message={helpMessage}>
      <li className={classes}>
        <FileEntry
          file={file}
          isCurrent={isCurrent}
          status={isCurrent ? StatusEnum.DANGER : StatusEnum.INACTIVE}
          onClick={onEntryClick}
          selected={isTrashed}
        />
        <Checkbox
          checked={isTrashed}
          status={StatusEnum.DANGER}
          onChange={onEntryClick}
        />
      </li>
    </HelpWrapper>
  )
}

/**
 * Renders the list of all files
 * @memberof module:components/drawers.FileDeletionDrawer
 * @param {module:models/common.FileBridge[]} props.files - All files to delete
 * @param {Set<module:models/common.FileBridge>} props.sessionTrash - All trashed files
 * @param {Function} props.onTrash - Function triggered when a file is trashed
 * @param {string} props.currentSessionId - The ID of the current session's file
 * @returns {external:react/Component} The drawer's content
 */
function FileDeletionList ({ files, sessionTrash, onTrash, currentSessionId }) {
  const { t } = useTranslation()
  let $list = t('No sessions found')

  if (files.length > 0) {
    $list = (
      <ul>
        {files.map(f => (
          <FileDeletionEntry
            key={f.id}
            file={f}
            isCurrent={f.id === currentSessionId}
            onTrash={onTrash}
            isTrashed={sessionTrash.has(f.id)}
          />
        ))}
      </ul>
    )
  }

  return $list
}

/**
 * Renders the Delete button
 * @memberof module:components/drawers.FileDeletionDrawer
 * @param {number} props.sessionTrashLength - Number of trashed files
 * @param {Function} props.onDelete - Function triggered when the user deletes the trashed files
 * @returns {external:react/Component} The delete button
 */
function DeleteFileButton ({ sessionTrashLength, onDelete }) {
  const { t } = useTranslation()

  const label = t('Delete', { count: sessionTrashLength })
  const help = t('Delete file', { count: sessionTrashLength })

  const buttonProps = {
    className: 'DeleteButton',
    type: 'danger',
    disabled: sessionTrashLength <= 0,
    onClick: onDelete,
    shape: 'rectangle'
  }

  return (
    <HelpWrapper message={help}>
      <Button {...buttonProps}>
        {label}
      </Button>
    </HelpWrapper>
  )
}

/**
 * Renders an observer Drawer component that deletes session files
 * It gets the DrawerStore as a React.Context
 * @memberof module:components/drawers
 * @returns {external:mobx-react/ObserverComponent} The deletion file Drawer
 */
const FileDeletionDrawer = observer(() => {
  const { t } = useTranslation()
  const { drawerStore, sessionStore, modalStore } = useContext(AppStoresContext)

  useEffect(() => {
    drawerStore.addDrawer(FILE_DELETION_DRAWER_ID)
    modalStore.addModal(TRASH_CURRENT_SESSION_MODAL_ID)

    Mousetrap.bind('ctrl+d', event => {
      event.preventDefault && event.preventDefault()
      drawerStore.toggleActiveDrawer(FILE_DELETION_DRAWER_ID)
    })

    return () => {
      drawerStore.removeDrawer(FILE_DELETION_DRAWER_ID)
      modalStore.removeModal(TRASH_CURRENT_SESSION_MODAL_ID)
      Mousetrap.unbind('ctrl+d')
    }
  })

  useEffect(() => {
    if (drawerStore.activeDrawer === FILE_DELETION_DRAWER_ID) {
      sessionStore.updateSessionList()
    }

    sessionStore.clearSessionTrash()
  }, [drawerStore.activeDrawer])

  return (
    <>
      <TrashCurrentSessionModal
        visible={modalStore.activeModal === TRASH_CURRENT_SESSION_MODAL_ID}
        onCancel={() => {
          modalStore.cleanActiveModal(TRASH_CURRENT_SESSION_MODAL_ID)
        }}
        onConfirm={() => {
          sessionStore.addSessionToTrash(sessionStore.currentSessionId)
          modalStore.cleanActiveModal(TRASH_CURRENT_SESSION_MODAL_ID)
        }}
      />
      <Drawer
        id={FILE_DELETION_DRAWER_ID}
        width={DRAWER_WIDTH}
        visible={drawerStore.activeDrawer === FILE_DELETION_DRAWER_ID}
        header={(
          <>
            <h2>
              {t('Delete existing sessions')}
            </h2>
            <p>
              {t('Select all files to delete')}
            </p>
          </>
        )}
        footer={(
          <FlexRow justifyContent='space-around' alignItems='center'>
            <ResetButton
              disabled={sessionStore.sessionTrash.size === 0}
              onReset={() => sessionStore.clearSessionTrash()}
            />
            <DeleteFileButton
              sessionTrashLength={sessionStore.sessionTrash.size}
              onDelete={() => {
                sessionStore.applyClearTrash()
                sessionStore.updateSessionList()
              }}
            />
          </FlexRow>
        )}
        onBackdropClick={() => {
          drawerStore.clearActiveDrawer()
        }}
      >
        <FileDeletionList
          files={Array.from(sessionStore.sessions.values())}
          sessionTrash={sessionStore.sessionTrash}
          currentSessionId={sessionStore.currentSessionId}
          onTrash={fileBridge => {
            if (sessionStore.sessionTrash.has(fileBridge.id)) {
              sessionStore.removeSessionFromTrash(fileBridge.id)
            } else if (fileBridge.id === sessionStore.currentSessionId) {
              modalStore.setActiveModal(TRASH_CURRENT_SESSION_MODAL_ID)
            } else {
              sessionStore.addSessionToTrash(fileBridge.id)
            }
          }}
        />
      </Drawer>
    </>
  )
})

export default FileDeletionDrawer

export {
  FILE_DELETION_DRAWER_ID,
  FileDeletionEntry,
  FileDeletionList,
  DeleteFileButton
}

import { computed, makeObservable } from 'mobx'

import SpecStore from '@stores/shmdata/SpecStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'

import { RequirementError } from '@utils/errors'
import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the MaxLimitStore
 * @memberof stores.MaxLimitStore
 */
export const LOG = logger.child({ store: 'MaxLimitStore' })

/**
 * @constant {Map<string, number>} restrictedKinds - Map of kinds with custom max limits, hashed by kind id
 * @memberof module:stores/shmdata.MaxLimitStore
 * @todo should be rewritten with the 'maxLimitOverride' setting
 */
export const restrictedKinds = new Map([['xr18Output', 1], ['jackOutput', 1]])

/**
 * @classdesc Stores all max limits of simultaneous connections
 * @memberof module:stores/shmdata
 */
class MaxLimitStore {
  /** @property {Map<string, number>} - All max reader limits by quiddity class ids */
  get maxReaderLimits () {
    const maxReaderLimits = new Map()
    for (const [quidId, followerSpecs] of this.specStore.followerSpecs) {
      const kind = this.quiddityStore.usedKinds.get(quidId)

      if (restrictedKinds.has(kind?.id)) {
        maxReaderLimits.set(quidId, restrictedKinds.get(kind.id))
      } else {
        // @todo we need to change this logic as it will not work for meta followers
        maxReaderLimits.set(quidId, followerSpecs.size)
      }
    }

    return maxReaderLimits
  }

  /**
   * Instantiates a new MaxLimitStore
   * @param {module:stores/common.SocketStore} socketStore - Socket manager
   * @param {module:stores/shmdata.SpecStore} specStore - Spec manager
   * @constructor
   */
  constructor (quiddityStore, specStore) {
    makeObservable(this, {
      maxReaderLimits: computed
    })

    if (quiddityStore instanceof QuiddityStore) {
      /** @property {module:stores/quiddity.QuiddityStore} quiddityStore - Manages all quiddities */
      this.quiddityStore = quiddityStore
    } else {
      throw new RequirementError(this.constructor.name, 'QuiddityStore')
    }

    if (specStore instanceof SpecStore) {
      /** @property {module:stores/shmdata.SpecStore} specStore - Manages all connection specs */
      this.specStore = specStore
    } else {
      throw new RequirementError(this.constructor.name, 'SpecStore')
    }
  }
}

export default MaxLimitStore

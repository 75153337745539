import { observable, action, computed, reaction, makeObservable } from 'mobx'

import Store from '@stores/Store'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import ContactStore from '@stores/sip/ContactStore'
import SipStore from '@stores/sip/SipStore'
import { EXTERNAL_SHMDATA_SOURCE_KIND_ID } from '@models/quiddity/specialQuiddities'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the FilterStore
 * @memberof module:stores/matrix.FilterStore
 */
export const LOG = logger.child({ store: 'FilterStore' })

/**
 * @classdesc Stores all filters
 * @extends module:stores/common.Store
 * @memberof module:stores/matrix
 */
class FilterStore extends Store {
  /** @property {module:stores/quiddity.QuiddityStore} quiddityStore - Stores all quiddities */
  quiddityStore = null

  /** @property {module:stores/sip.ContactStore} contactStore - Stores all SIP contacts */
  contactStore = null

  /** @property {Set<string>} categoryFilters - All the category filters selected by the user */
  categoryFilters = new Set()

  /** @property {Map<string, string>} orderedCategories - All categories ordered by quiddityID */
  orderedCategories = new Map()

  /** @property {Set<string>} categories - All categories available for the filters */
  get userCategories () {
    return new Set(
      Array.from(this.orderedCategories)
        .filter(([quidId]) => this.applyQuiddityFilter(quidId))
        .map(([, category]) => category)
    )
  }

  /**
   * Instantiates a new FilterStore
   * @param {module:stores/common.SocketStore} socketStore - Manage all sockets
   * @param {module:stores/quiddity.QuiddityStore} quiddityStore - Store all quiddities
   * @param {module:stores/sip.ContactStore} contactStore - Store all contacts
   * @throws {TypeError} Throws an error when a required store is missing
   * @constructor
   */
  constructor (socketStore, quiddityStore, contactStore, sipStore) {
    super(socketStore)

    makeObservable(this, {
      categoryFilters: observable,
      orderedCategories: observable,
      userCategories: computed,
      setOrderedCategories: action,
      addCategoryFilter: action,
      removeCategoryFilter: action,
      clearCategoryFilters: action
    })

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new TypeError('FilterStore requires a QuiddityStore')
    }

    if (contactStore instanceof ContactStore) {
      this.contactStore = contactStore
    } else {
      throw new TypeError('FilterStore requires a ContactStore')
    }

    if (sipStore instanceof SipStore) {
      this.sipStore = sipStore
    } else {
      throw new TypeError('FilterStore requires a SipStore')
    }

    reaction(
      () => this.quiddityStore.usedKinds,
      () => this.handleCategoryChange()
    )

    reaction(
      () => this.contactStore.partners.size,
      () => this.handleCategoryChange()
    )
  }

  /** Handles all changes of available categories */
  handleCategoryChange () {
    const { quiddityStore: { usedKinds } } = this

    this.setOrderedCategories(
      new Map(
        Array.from(usedKinds)
          .map(([quidId, quidKind]) => [quidId, quidKind?.category])
          .filter(([, category]) => !!category)
      ).set(this.sipStore.sipId, 'SIP')
    )
  }

  /**
   * Applies a filter on a quiddity
   * @param {string} quiddityId - The ID of the quiddity
   * @returns {boolean} Flags true if the quiddity passes the filters
   */
  applyQuiddityFilter (quiddityId) {
    const {
      quiddityStore: { userQuiddityIds },
      contactStore: { partners }
    } = this

    let isFiltered = userQuiddityIds.includes(quiddityId)

    if (quiddityId === this.sipStore.sipId) {
      isFiltered = partners.size > 0
    }

    return isFiltered
  }

  /**
   * Applies a filter to an entry of the matrix
   * @param {module:models/matrix.MatrixEntry} matrixEntry - A matrix entry
   * @returns {boolean} Flags true if the entry passes the filters
   */
  applyEntryFilter (matrixEntry) {
    const {
      quiddityStore: { usedKinds },
      categoryFilters,
      orderedCategories
    } = this

    let isFiltered = true

    if (this.categoryFilters.size > 0) {
      if (matrixEntry.isContact ||
        (matrixEntry.quiddity.kindId === EXTERNAL_SHMDATA_SOURCE_KIND_ID && this.sipStore.sipShmdatas.has(matrixEntry.defaultShmdata?.path))) {
        isFiltered = categoryFilters.has('SIP') || categoryFilters.has(usedKinds.get(this.sipStore.sipId).category)
      } else {
        isFiltered = categoryFilters.has(
          orderedCategories.get(matrixEntry.quiddityId)
        )
      }
    }

    return isFiltered
  }

  /**
   * Applies a filter for a source and a destination
   * @param {module:models/matrix.MatrixEntry} sourceEntry - Matrix entry for a source
   * @param {module:models/matrix.MatrixEntry} destinationEntry - Matrix entry for a destination
   * @returns {boolean} Flags true if the entries pass the filter
   */
  applyCellFilter (sourceEntry, destinationEntry) {
    return this.applyEntryFilter(sourceEntry) &&
      this.applyEntryFilter(destinationEntry)
  }

  /** Sets the entire map of the categories mapped by quiddity IDs */
  setOrderedCategories (orderedCategories) {
    this.orderedCategories = orderedCategories
  }

  /** Adds a category to filter */
  addCategoryFilter (category) {
    this.categoryFilters.add(category)

    LOG.debug({
      msg: 'A new category is filtered',
      category: category
    })
  }

  /** Deletes a category from the filters */
  removeCategoryFilter (category) {
    this.categoryFilters.delete(category)

    LOG.debug({
      msg: 'A category stopped being filtered',
      category: category
    })
  }

  /** Clears all categories from the filters */
  clearCategoryFilters () {
    this.categoryFilters.clear()

    LOG.debug({
      msg: 'A filtered categories are cleared'
    })
  }
}

export default FilterStore

import React, { useContext, useEffect } from 'react'

import { useHover } from '@utils/uiTools'
import { AppStoresContext } from '@components/App'

/**
 * Wraps each component that displays a help message
 * @memberof module:components/wrappers
 * @param {external:react/Component} children - Any kind of component
 * @param {string} message - The help message
 * @returns {external:react/Component} The wrapped component
 */
function HelpWrapper ({ children, message }) {
  const { helpMessageStore } = useContext(AppStoresContext)
  const [hoverRef, isHovered] = useHover()

  useEffect(() => {
    if (isHovered) {
      helpMessageStore.setHelpMessage(message)
    } else {
      helpMessageStore.cleanHelpMessage()
    }
  }, [isHovered])

  return (
    <span className='HelpWrapper' ref={hoverRef}>
      {children}
    </span>
  )
}

export default HelpWrapper

import API from '@api/API'

function getLogMessage (logEvent) {
  return logEvent.messages[0].msg
}

function getLogLevel (logEvent) {
  return logEvent.level.label
}

/**
 * @classdesc API for managing Switcher-specific interactions
 * @memberof module:api
 * @extends module:api.API
 */
class SwitcherAPI extends API {
  /**
   * Send JSON bundles description to the server and load them
   * @param {string} description - JSON description of bundles to load
   * @returns {Promise} A promise that returns API error or success
   * @async
   */
  sendBundles (description) {
    return new Promise((resolve, reject) => {
      this.socket.emit('switcher_bundles', JSON.stringify(description), (error, success) => {
        if (error) reject(error)
        if (success) resolve(success)
      })
    })
  }

  /**
   * Send log Objects to the server and load them
   * @param {Object} logObject - The log object
   * @returns {Promise} A promise that returns API error or success
   * @async
   */
  sendLog (logLevel = 'info', logMessage) {
    return new Promise((resolve, reject) => {
      this.socket.emit('switcher_log', logLevel, logMessage, (error, success) => {
        if (error) reject(error)
        if (success) resolve(success)
      })
    })
  }

  sendLogObject (logObject) {
    return this.sendLog(
      getLogLevel(logObject),
      getLogMessage(logObject)
    )
  }

  /**
   * Get current version of switcher
   * @returns {Promise} A promise that returns API error or success
   * @async
   */
  getVersion () {
    return new Promise((resolve, reject) => {
      this.socket.emit('switcher_version', (error, success) => {
        if (error) reject(error)
        if (success) resolve(success)
      })
    })
  }

  /**
   * List extra config paths
   * @async
   */
  getConfigPaths () {
    return new Promise((resolve, reject) => {
      this.socket.emit('switcher_extra_config_paths', (error, success) => {
        if (error) {
          reject(error)
        } else {
          resolve(success)
        }
      })
    })
  }

  /**
   * Get the configuration from its path
   * @async
   * @param {string} configName - Name of the config to read
   * @returns {string} The configuration dump
   */
  readConfig (configName) {
    return new Promise((resolve, reject) => {
      this.socket.emit('switcher_extra_config_read', configName, (error, success) => {
        if (error) {
          reject(error)
        } else {
          resolve(success)
        }
      })
    })
  }
}

export default SwitcherAPI

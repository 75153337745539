import { observable, computed, action, reaction, makeObservable, observe } from 'mobx'

import InitStateEnum from '@models/common/InitStateEnum'
import Contact from '@models/sip/Contact'
import MatrixEntry from '@models/matrix/MatrixEntry'
import { SendStatusEnum } from '@models/sip/SipEnums'

import ConfigStore from '@stores/common/ConfigStore'
import SipStore from '@stores/sip/SipStore'
import { SIP_KIND_ID } from '@models/quiddity/specialQuiddities'
import ShmdataStore from '@stores/shmdata/ShmdataStore'
import LockStore from '@stores/matrix/LockStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import Store from '@stores/Store'
import StatusEnum from '@models/common/StatusEnum'
import i18next from 'i18next'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the ContactsStore
 * @memberof stores.ContactsStore
 */
export const LOG = logger.child({ store: 'ContactStore' })

/**
 * @constant {RegExp} BUDDIES_TREE_REGEX - Matches all `buddies` changes of the SIP quiddity
 * @memberof stores.ContactsStore
 */
export const BUDDIES_TREE_REGEX = /^\.buddies\.([0-9]+)$/

/**
 * @constant {RegExp} BUDDIES_UPDATE_REGEX - Matches all changes to a SIP buddy's properties, except for 'uri' and 'connections'
 * @memberof stores.ContactsStore
 */
const BUDDIES_UPDATE_REGEX = /^\.buddies\.[0-9]+\.(?!(?:uri|connections))\w+/

/**
 * @constant {RegExp} BUDDIES_URI_REGEX - Matches all changes to a SIP buddy's URI
 * @memberof stores.ContactsStore
 */
const BUDDIES_URI_REGEX = /^\.buddies\.[0-9]+\.uri/

/**
 * @constant {RegExp} BUDDIES_CONNECTIONS_REGEX - Matches all changes to a SIP buddy's connections
 * @memberof stores.ContactsStore
 */
const BUDDIES_CONNECTIONS_REGEX = /^\.buddies\.[0-9]+\.connections/

/**
 * @constant {RegExp} BUDDY_ID_PROPERTY_REGEX - Matches the ID and updated property of a SIP buddy
 * @memberof stores.ContactsStore
 */
export const BUDDY_ID_PROPERTY_REGEX = /^\.buddies\.([0-9]+)\.(\w+)$/

/**
 * @classdesc Stores SIP contacts
 * @extends stores.Store
 * @memberof stores
 */
class ContactStore extends Store {
  /** @property {Object} fullContactList - JSON object containing the full SIP database */
  fullContactList = {}

  /** @property {boolean} defaultAuthorization - Default authorization of created contacts */
  defaultAuthorization = false

  /** @property {Map<string, module:models/sip.Contact>} pendingContacts - Contacts that don't yet have an id given by switcher. */
  pendingContacts = new Map()

  /** @property {Map<string, module:models/sip.Contact>} contacts - All contacts hashed by their IDs */
  contacts = new Map()

  /** @property {Set<string>} partners - All contact IDs added to the current session */
  partners = new Set()

  /** @property {Map<string, module:models/sip.Contact>} sessionContacts - All contacts in the current SIP session hashed by their IDs */
  get sessionContacts () {
    const sessionContacts = new Map()

    for (const buddyId of this.partners) {
      if (this.contacts.has(buddyId)) {
        sessionContacts.set(buddyId, this.contacts.get(buddyId))
      }
    }

    return sessionContacts
  }

  /** @property {Map<string, module:models/sip.Contact>} sessionContacts - All contacts in the current SIP session hashed by their URIs */
  get contactAddresses () {
    const contactAddresses = new Map()

    for (const [, contact] of this.contacts) {
      contactAddresses.set(contact.uri, contact)
    }

    return contactAddresses
  }

  /** @property {Map<string, Set<string>>} attachedShmdatas - All shmdata paths attached to some contacts, each path hash all its attached contacts URI */
  get attachedShmdatas () {
    const attachedShmdatas = new Map()

    for (const [contactUri, contact] of this.contactAddresses) {
      for (const path of contact.connections) {
        if (attachedShmdatas.has(path)) {
          attachedShmdatas.get(path).add(contactUri)
        } else {
          attachedShmdatas.set(path, new Set([contactUri]))
        }
      }
    }

    return attachedShmdatas
  }

  /** @property {Set<string>} readyPartners - All contact URIs that are ready to be called */
  get readyPartners () {
    const readyPartners = new Set()

    for (const contactId of this.partners) {
      const contact = this.contacts.get(contactId)

      if (contact && contact.connections.length > 0) {
        readyPartners.add(contactId)
      }
    }

    return readyPartners
  }

  /**
   * Instantiates a new ContactStore
   * @param {module:stores/shmdata.SocketStore} socketStore - Socket manager
   * @param {module:stores/common.ConfigStore} configStore - Configuration manager
   * @param {module:stores/quiddity.QuiddityStore} quiddityStore - Store all quiddities
   * @param {module:stores/shmdata.ShmdataStore} shmdataStore - Store all shmdatas
   * @param {module:stores/sip.SipStore} sipStore - SIP quiddity manager
   * @param {module:stores/matrix.LockStore} lockStore - Store all locks
   * @param {boolean} [defaultAuthorization=false] - Flag indicating if contacts not in a SIP session are blacklisted or not
   * @constructor
   */
  constructor (socketStore, configStore, quiddityStore, shmdataStore, sipStore, lockStore, defaultAuthorization = false) {
    super(socketStore)

    makeObservable(this, {
      defaultAuthorization: observable,
      contacts: observable,
      partners: observable,
      sessionContacts: computed,
      contactAddresses: computed,
      attachedShmdatas: computed,
      readyPartners: computed,
      setDefaultAuthorization: action,
      addContact: action,
      removeContact: action,
      addContactToSession: action,
      removeContactFromSession: action,
      clear: action
    })

    if (configStore instanceof ConfigStore) {
      this.configStore = configStore
    } else {
      throw new TypeError('ContactsStore requires a ConfigStore')
    }

    if (sipStore instanceof SipStore) {
      this.sipStore = sipStore
    } else {
      throw new TypeError('ContactsStore requires a SipStore')
    }

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new TypeError('ContactStore requires a QuiddityStore')
    }

    if (shmdataStore instanceof ShmdataStore) {
      this.shmdataStore = shmdataStore
    } else {
      throw new TypeError('ContactStore requires a ShmdataStore')
    }

    if (lockStore instanceof LockStore) {
      this.lockStore = lockStore
    } else {
      throw new TypeError('ContactStore requires a LockStore')
    }

    this.setDefaultAuthorization(defaultAuthorization)

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )

    // When SIP is logged in/out from a server (.self is grafted)
    reaction(
      () => sipStore.isConnected,
      () => this.handleSipRegistration()
    )

    // Updates sessionContacts Map when a contact is deleted from the contacts Map
    reaction(
      () => this.contacts.size,
      () => {
        if (this.sessionContacts.size > 0) {
          this.cleanSessionContacts()
        }
      }
    )

    // Updates all contact authorizations when the blacklist mode is updated
    reaction(
      () => this.defaultAuthorization,
      () => this.applyBlocklist()
    )

    reaction(
      () => this.quiddityStore.quiddityIds,
      () => this.handleQuiddityChanges()
    )

    // Updates contact authorization when it is added or removed from session
    observe(this.partners, change => {
      let authorization, contact

      if (change.type === 'delete') {
        contact = this.contacts.get(change.oldValue)
        authorization = this.defaultAuthorization
      } else {
        contact = this.contacts.get(change.newValue)
        authorization = true
      }

      if (contact) {
        this.applyContactAuthorization(contact, authorization)
      }
    })
    this.lockStore.addLockableKind(SIP_KIND_ID)
  }

  /**
   * Initializes Store by fetching existing contacts, calls and attached shmdatas
   * @returns {boolean} Flags true if store is well initialized
   * @async
   */
  async initialize () {
    const { INITIALIZING, INITIALIZED } = InitStateEnum
    const { currentUri } = this.sipStore

    if (!this.isNotInitialized()) {
      return false
    }

    this.setInitState(INITIALIZING)

    // If SIP quiddity is already logged in, initialize contacts
    if (currentUri) {
      // First, create contacts associated with current logged in SIP user
      this.populateContactListFromConfig(currentUri)

      // Second, fetch existing Switcher buddies
      const fetchedContacts = Contact.fromTree(await this.fetchJSONContacts())
      for (const contact of fetchedContacts) {
        this.handleContactCreation(contact)
        this.toggleCallingContactLock(contact)
      }

      // Finally, apply blocklist settings
      this.applyBlocklist()

      LOG.info({
        msg: 'All contacts are initialized',
        contacts: fetchedContacts.map(c => c.uri)
      })
    }

    this.setInitState(INITIALIZED)

    LOG.info({
      msg: 'Contacts store is initialized'
    })

    return this.isInitialized()
  }

  /**
   * Fetches all existing contacts in the current session in JSON
   * @returns {Object} - The JSON representation of all existing contacts
   * @async
   */
  async fetchJSONContacts () {
    const { infoTreeAPI } = this.socketStore.APIs
    let json = {}

    try {
      // It is returning an empty json object
      json = await infoTreeAPI.get(this.sipStore.sipId, '.buddies')

      if (!json || json === 'null') {
        json = {}
      }

      LOG.info({
        msg: 'Successfully fetched existing SIP contacts',
        contacts: json
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to fetch existing SIP contacts',
        error: error.message
      })
    }

    return json
  }

  /**
   * Checks if contact is part of the sessionContacts Map.
   * @param {module:models/sip.Contact} contact - Contact model
   * @returns {boolean} True if the contact is part of the sessionContacts Map
   */
  hasSessionContact (contact) {
    for (const id of this.sessionContacts.keys()) {
      if (contact.id === id) {
        return true
      }
    }
    return false
  }

  /**
   * Finds a Contact model from the current SIP user's contact list using a URI
   * @param {string} Contact URI
   * @returns {?module:models/sip.Contact} Contact model associated with the given URI, null if it doesn\'t exist
   */
  findUserContactFromUri (uri) {
    const { currentUri } = this.sipStore
    const { userContacts } = this.configStore

    let sipUserContacts = {}
    let userContact = null

    if (userContacts) {
      sipUserContacts = userContacts[currentUri]
    }

    if (sipUserContacts) {
      userContact = sipUserContacts[uri]
    }

    return userContact
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()

    if (socket && this.socketStore.hasActiveAPIs) {
      const { infoTreeAPI } = this.socketStore.APIs
      // When a buddy is deleted
      // Path '.buddies.<id>' is pruned
      infoTreeAPI.onPruned(
        (quidId, path) => this.handleContactRemoval(path),
        quidId => quidId === this.sipStore.sipId,
        path => Array.isArray(path.match(BUDDIES_TREE_REGEX))
      )

      // When a contact is called
      infoTreeAPI.onGrafted(
        (quidId, path, value) => this.handleContactUpdate(path, value),
        quidId => quidId === this.sipStore.sipId,
        path => Array.isArray(path.match(BUDDIES_TREE_REGEX))
      )

      // When the property of a buddy is updated
      // Path '.buddies.<id>.<property>' is grafted
      // Does not listen to 'connections' and 'uri'
      infoTreeAPI.onGrafted(
        (quidId, path, value) => this.handleContactUriUpdate(path, value),
        quidId => quidId === this.sipStore.sipId,
        path => Array.isArray(path.match(BUDDIES_URI_REGEX))
      )

      // When a buddy is created
      // Path '.buddies.<id>.uri' is grafted
      // When buddy is created, '.buddies.<id>.whitelisted' is grafted first, followed by '.buddies.<id>.uri'.
      // Since 'uri' is grafted only once in a buddy's lifetime, we listen to this graft instead of 'whitelisted'.
      // The first 'whitelisted' graft will be ignored. Proper authorization will be set in 'handleContactCreation()'
      infoTreeAPI.onGrafted(
        (quidId, path, value) => this.handleContactPropertyUpdate(path, value),
        quidId => quidId === this.sipStore.sipId,
        path => Array.isArray(path.match(BUDDIES_UPDATE_REGEX))
      )

      // When a shmdata is attached/detached from a buddy
      // Path '.buddies.<id>.connections' is grafted
      infoTreeAPI.onGrafted(
        (quidId, path, value) => this.handleConnectionUpdate(path, value),
        quidId => quidId === this.sipStore.sipId,
        path => Array.isArray(path.match(BUDDIES_CONNECTIONS_REGEX))
      )

      infoTreeAPI.onPruned(
        (quidId, path) => this.handleShmdataRemoval(quidId, path),
        quidId => quidId !== this.sipStore.sipId,
        // we want the pruned path to be the whole shmdata writer. If the split is greater than 4 it means a subelement
        // was pruned and this doesn't interest us.
        path => path.includes('.shmdata.writer.') && path.split('.').length === 4
      )
    }
  }

  /**
   * This handles the case where a quiddity stops writing to a shmdata but the shmdata is
   * still attached to the sip quiddity. Not handling this causes the appearance of ghost sip sources
   * when calling a contact because the sip quiddity sends an empty shmdata.
   *
   * This may cause strange behaviour if you manually instanciate an extshmsrc and point it to a shmpath written by another quiddity.
   * Then again, if you are using extshmsrc like that, please go home and rethink your life.
   *
   * @param quidId: id of the quiddity that has its shmdata removed
   * @param path: path of the shmdata that was pruned.
   */
  async handleShmdataRemoval (quidId, path) {
    const prunedShmdata = path.replace('.shmdata.writer.', '')
    // brute forcedly iterate over all of the attached shmdata to see if one of them matches our prune
    for (const [shmdataPath, contactAddresses] of this.attachedShmdatas) {
      if (shmdataPath === prunedShmdata) {
        /* if we find a match, disconnect that shmdata from all the contacts. */
        for (const contactUri of contactAddresses) {
          await this.applyDetachShmdata(contactUri, shmdataPath)
        }
      }
    }
  }

  /** Handles each quiddity changes and cleans all shmdatas written by removed quiddities */
  async handleQuiddityChanges () {
    const { shmdataStore, quiddityStore } = this

    for (const [shmdataPath, contactAddresses] of this.attachedShmdatas) {
      const quiddityId = shmdataStore.writingQuiddities.get(shmdataPath) || ''
      if (quiddityId && !quiddityStore.quiddities.has(quiddityId)) {
        for (const contactUri of contactAddresses) {
          await this.applyDetachShmdata(contactUri, shmdataPath)
        }
      }
    }
  }

  /**
   * Handles SIP registration status. Create Contact models when logged in, clear everything when logged out.
   * @async
   */
  async handleSipRegistration () {
    const { isConnected } = this.sipStore

    if (isConnected && !this.isInitialized()) {
      await this.initialize()
    } else if (!isConnected) {
      this.clear()
    }
  }

  /**
   * Updates the contact properties from the userTree
   * @param {string} path - Updated path of the userTree
   * @param {object} value - The updated JSON subtree
   */
  handleContactUpdate (path, value) {
    // Ignore 'subscription_state=SENT' event. This event does not have 'uri' key needed to recreate Contact and
    // does not update buddy presence.
    if (value?.subscription_state === 'SENT') { return }

    const [, buddyId] = path.match(BUDDIES_TREE_REGEX)
    const contact = Contact.fromJSON({ id: buddyId, ...value })

    if (this.contacts.has(buddyId)) {
      this.toggleCallingContactLock(contact)
    }
  }

  /**
   * Toggles the lock for a SIP contact
   * @param {module:models/sip.Contact} contact - A SIP contact
   */
  toggleCallingContactLock (contact) {
    const { sipStore: { sipQuiddity } } = this

    this.lockStore.setLock(
      new MatrixEntry(sipQuiddity, contact),
      contact.sendStatus === SendStatusEnum.CALLING
    )
  }

  /**
   * Handles a new contact grafted in the SIP tree
   * @param {string} path - JSON path that was grafted
   * @param {string} value - Grafted value
   */
  handleContactUriUpdate (path, value) {
    const [, buddyId] = path.match(BUDDY_ID_PROPERTY_REGEX)

    if (!this.contacts.has(buddyId) && value) {
      this.handleContactCreation({ id: buddyId, uri: value })
    } else if (!value) {
      LOG.warn({
        msg: 'Failed to create a contact from an unknown uri',
        path: path,
        value: value
      })
    }
  }

  /**
   * Handle a new contact created from a JSON object or a Contact model
   * @param {Object|module:models/sip.Contact} object - The contact template
   */
  handleContactCreation (object) {
    try {
      const isContactObject = (object instanceof Contact)
      const userContact = this.findUserContactFromUri(object.uri)
      let contact
      // Fallback created contact with the configuration
      if (userContact) {
        const jsonContact = isContactObject ? object.toJSON() : object
        contact = Contact.fromJSON({ ...jsonContact, ...userContact })
      } else if (this.pendingContacts.has(object.uri)) {
        // if the contact is pending, gets its representation and removes it from the pending contacts
        contact = this.pendingContacts.get(object.uri)
        // now that we have an id for this contact, it is no longer pending
        contact.id = object.id
        this.pendingContacts.delete(object.uri)
      } else {
        contact = isContactObject ? object : Contact.fromJSON(object)
      }

      this.addContact(contact)
      this.applyContactAuthorization(contact, this.defaultAuthorization)

      // In most cases, this call serves no purpose except making unit tests pass. The naming of the contacts still
      // happens somehow even if this isn't called in this function.
      // The preceding statement is not true for calls coming from the applyCompleteContactCreation function.
      // TODO: investigate why and perhaps refactor the contact creation logic to be much simpler (will probably require backend work)
      // https://gitlab.com/sat-mtl/tools/scenic/scenic/-/issues/351
      if (contact.name) {
        this.applyContactName(contact.uri, contact.name)
      }
      // TODO: see if this call even serves a purpose. If it behaves like the applyContactName, its probably
      // useless.
      this.applyContactAuthorization(contact.uri, this.defaultAuthorization)

      // Add contact to session if we are currently sending to or receiving from it
      if (contact.isInSession) {
        this.addContactToSession(contact)
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to create contact',
        contact: object.id,
        error: error.message
      })
    }
  }

  /**
   * Handles a deleted SIP contact
   * @param {string} path - JSON path that was pruned
   */
  handleContactRemoval (path) {
    const [, buddyId] = path.match(BUDDIES_TREE_REGEX)
    this.removeContact(buddyId)
  }

  /**
   * Handles an update to a SIP contact's property
   * @param {string} path - JSON path that was grafted
   * @param {string|boolean} value - Grafted value
   */
  handleContactPropertyUpdate (path, value) {
    let [, buddyId, property] = path.match(BUDDY_ID_PROPERTY_REGEX)
    const contact = this.contacts.get(buddyId)

    if (contact && property) {
      switch (property) {
        case 'whitelisted':
          property = 'authorized'
          break
        case 'send_status':
          property = 'sendStatus'
          break
        case 'recv_status':
          property = 'recvStatus'
          break
        case 'status_text':
          property = 'statusText'
          break
        case 'subscription_state':
          property = 'subState'
          break
      }

      this.updateContact(buddyId, property, value)
    } else {
      LOG.warn({
        msg: 'Failed to update an unsafe buddy',
        path: path,
        value: value
      })
    }
  }

  /**
   * Handles an update to a SIP contact's shmdata connections
   * @param {string} path - JSON path that was grafted
   * @param {string[]} value - Grafted value
   */
  handleConnectionUpdate (path, value) {
    const [, buddyId] = path.match(BUDDY_ID_PROPERTY_REGEX)

    // Switcher can sometimes return an empty array as a string
    value = value === '[]' ? [] : value

    if (Array.isArray(value)) {
      if (this.contacts.has(buddyId)) {
        // If number of connections is larger than one, add contact to session
        if (value.length > 0) {
          this.addContactToSession(this.contacts.get(buddyId))
        }

        this.updateContact(buddyId, 'connections', value)
      } else {
        LOG.warn({
          msg: 'Skipping shmdata connection update for invalid contact.',
          contact: buddyId
        })
      }
    } else {
      LOG.warn({
        msg: 'Skipping shmdata connection update because grafted value is not an array.',
        contact: buddyId,
        value: value
      })
    }
  }

  /**
   * Removes session contacts not present in contacts Map
   */
  cleanSessionContacts () {
    for (const id of this.partners) {
      if (!this.contacts.has(id)) {
        this.removeContactFromSession(id)
      }
    }
  }

  /**
   * Creates contacts associated with a specific SIP URI from the user configuration
   * @param {string} uri - SIP URI to create contacts for
   * @async
   */
  async populateContactListFromConfig (uri) {
    const { configStore } = this
    let contacts = []

    try {
      if (configStore.userContacts) {
        contacts = Contact.fromUserContacts(configStore.userContacts[uri])
      }

      if (contacts) {
        for (const contact of contacts) {
          // Do not create a contact if it already exists
          if (!this.contactAddresses.has(contact.uri)) {
            this.applyContactCreation(contact, this.defaultAuthorization)
          }
        }
      }
    } catch (error) {
      LOG.error({
        msg: 'Error while populating contact list from configuration',
        error: error
      })
    }
  }

  /**
   * Applies the creation of a contact and adds it to the pendingContacts list
     so that the handleContactCreation function remembers the name we gave to it.
     Right now the authorization parameter gets overwritten by the handleContactCreation function
     but we might need this in the future
     @param uri {string} the uri of the contact
     @param name {string} the name of the contact
     @param authorization {string} the authorization of the contact
     @returns {boolean} true if the contact was sucessfully created, false otherwise.
   */
  async applyCompleteContactCreation (uri, name, authorization) {
    const didCreateContact = await this.applyContactCreation(uri)
    if (didCreateContact) {
      // We add the contact to the pending contacts so that the handleContactCreation function
      // can know the name and authorizations associated with the contact
      const newContact = Contact.fromJSON({ uri: uri, name: name, authorization: authorization })
      this.pendingContacts.set(newContact.uri, newContact)

      LOG.info({
        notification: newContact,
        status: StatusEnum.ACTIVE,
        title: 'Success',
        msg: i18next.t('Contact was created successfully')
      })
    } else {
      LOG.warn({
        notification: !didCreateContact,
        status: StatusEnum.DANGER,
        title: 'Failure',
        msg: i18next.t('Error while creating contact')
      })
    }
    return didCreateContact
  }

  /**
   * Adds a contact in the SIP quiddity
   * @param {string} uri - URI of the new contact
   * @returns {boolean} Flags true if creation was successful
   * @async
   */
  async applyContactCreation (contact) {
    const { methodAPI } = this.socketStore.APIs
    const { currentUri } = this.sipStore
    const { uri, sipUri, sipUser } = contact

    let isAdded = false

    try {
      if (currentUri !== uri) {
        isAdded = await methodAPI.addBuddy(this.sipStore.sipId, sipUri, sipUser)

        if (!isAdded) {
          throw new Error('Failed to add contact')
        }
      } else {
        LOG.warn({
          msg: 'Cannot create contact for self',
          uri: uri
        })
      }
    } catch (error) {
      LOG.error({
        msg: 'Error while creating contact',
        uri: uri,
        error: error.message
      })
    }

    return isAdded
  }

  /**
   * Sets contact name
   * @param {string} uri - SIP URI of the contact to update
   * @param {string} name - New name of the SIP contact
   * @returns {boolean} Flags true if name was successfully set
   * @async
   */
  async applyContactName (uri, name) {
    const { methodAPI } = this.socketStore.APIs
    let isSet = false

    try {
      if (this.contactAddresses.has(uri)) {
        isSet = await methodAPI.invoke(this.sipStore.sipId, 'name_buddy', [name, uri])

        if (isSet) {
          LOG.info({
            msg: 'Successfully set name for contact',
            uri: uri,
            name: name
          })
        } else {
          throw new Error('Could not set contact name')
        }
      } else {
        throw new Error(`Contact ${uri} doesn't exist`)
      }
    } catch (error) {
      LOG.error({
        msg: 'Error while updating contact name',
        error: error.message
      })
    }

    return isSet
  }

  /**
   * Sets contact authorization
   * @param {string} uri - SIP URI of the contact to update
   * @param {boolean} authorization - New authorization value for the SIP contact
   * @returns {boolean} Flags true if authorization was successfully set
   * @async
   */
  async applyContactAuthorization (contact, authorization) {
    const { methodAPI } = this.socketStore.APIs
    let isSet = false
    const { uri, sipUri, sipUser } = contact

    try {
      if (this.contactAddresses.has(uri)) {
        isSet = await methodAPI.authorize(this.sipStore.sipId, sipUri, sipUser, authorization)

        if (isSet) {
          LOG.info({
            msg: 'Successfully set authorization for contact',
            uri: uri,
            authorization: authorization
          })
        } else {
          throw new Error('Could not set authorization for contact')
        }
      } else {
        throw new Error(`Contact ${uri} doesn't exist`)
      }
    } catch (error) {
      LOG.error({
        msg: 'Error while updating contact authorization',
        error: error.message
      })
    }

    return isSet
  }

  /**
   * Attaches shmdata to SIP contact
   * @param {string} uri - URI of the contact
   * @param {string} shmdataPath - Path of a shmdata
   * @returns {boolean} Flags true if attach was successful
   * @async
   */
  async applyAttachShmdata (contact, shmdataPath) {
    const { methodAPI } = this.socketStore.APIs
    let isAttached = false
    const { uri, sipUri, sipUser } = contact

    try {
      if (this.contactAddresses.has(uri)) {
        isAttached = await methodAPI.attachShmdataToContact(this.sipStore.sipId, sipUri, sipUser, shmdataPath)

        if (isAttached) {
          this.addContactToSession(this.contactAddresses.get(uri))

          LOG.info({
            msg: 'Successfully attached shmdata to contact',
            uri: uri,
            shmdata: shmdataPath
          })
        } else {
          throw new Error('Failed to attach shmdata to contact')
        }
      } else {
        throw new Error(`URI ${uri} doesn't exist`)
      }
    } catch (error) {
      LOG.error({
        msg: 'Error while attaching shmdata to contact',
        error: error.message,
        uri: uri,
        shmdata: shmdataPath
      })
    }

    return isAttached
  }

  /**
   * Detaches shmdata from SIP contact
   * @param {string} uri - URI of the contact
   * @param {string} shmdataPath - Path of a shmdata
   * @returns {boolean} Flags true if detach was successful
   * @async
   */
  async applyDetachShmdata (uri, shmdataPath) {
    const { methodAPI } = this.socketStore.APIs
    let isDetached = false
    try {
      if (this.contactAddresses.has(uri)) {
        // the passed uri is of format <sip-user>@<sip-server> and the detachShmdataFromContact expects
        // to have (<sip quid id>, <sip-server>, <sip-user>, <shmpath>)
        isDetached = await methodAPI.detachShmdataFromContact(this.sipStore.sipId, uri.split('@')[1], uri.split('@')[0], shmdataPath)

        if (isDetached) {
          LOG.info({
            msg: 'Successfully detached shmdata from contact',
            uri: uri,
            shmdata: shmdataPath
          })
        } else {
          throw new Error('Failed to detach shmdata from contact')
        }
      } else {
        throw new Error(`URI ${uri} doesn't exist`)
      }
    } catch (error) {
      LOG.error({
        msg: 'Error while detaching shmdata to contact',
        error: error.message,
        uri: uri,
        shmdata: shmdataPath
      })
    }
    return isDetached
  }

  /**
   * Calls a SIP contact
   * @param {string} uri - URI of the contact
   * @returns {boolean} Flags true if call was successfully initiated
   * @async
   */
  async applyCall (uri) {
    const { methodAPI } = this.socketStore.APIs
    const contact = this.contactAddresses.get(uri)
    let isInSession = false

    try {
      if (contact && this.readyPartners.has(contact.id)) {
        isInSession = await methodAPI.send(this.sipStore.sipId, contact.sipUri, contact.sipUser)

        if (isInSession) {
          LOG.info({
            msg: 'Successfully called contact',
            uri: uri
          })
        } else {
          throw new Error('Failed to call contact')
        }
      } else if (!contact) {
        throw new Error('Contact doesn\'t exist')
      } else {
        throw new Error('No shmdatas are attached')
      }
    } catch (error) {
      LOG.error({
        msg: 'Error while calling contact',
        error: error.message,
        uri: uri
      })
    }

    return isInSession
  }

  /**
   * Hangs up an outgoing call to a SIP contact
   * @param {string} uri - URI of the contact
   * @returns {boolean} Flags true if call was successfully terminated
   * @async
   */
  async applyHangup (uri) {
    const { methodAPI } = this.socketStore.APIs
    let isHungUp = false

    try {
      if (this.contactAddresses.has(uri)) {
        isHungUp = await methodAPI.invoke(this.sipStore.sipId, 'hang-up', [uri])

        if (isHungUp) {
          LOG.info({
            msg: 'Successfully hung up call with contact',
            uri: uri
          })
        } else {
          throw new Error('Failed to hang up call with contact')
        }
      } else {
        throw new Error('Contact doesn\'t exist')
      }
    } catch (error) {
      LOG.error({
        msg: 'Error while hanging up call with contact',
        error: error.message,
        uri: uri
      })
    }

    return isHungUp
  }

  /**
   * Hangs up all incoming and outgoing calls to SIP contacts
   * @returns {boolean} Flags true if all calls were successfully terminated
   * @async
   */
  async applyHangupAllCalls () {
    const { methodAPI } = this.socketStore.APIs
    let areHungUp = false

    try {
      areHungUp = await methodAPI.invoke(this.sipStore.sipId, 'hang_up_all', [])

      if (areHungUp) {
        LOG.info({
          msg: 'Successfully hung up calls with all contacts'
        })
      } else {
        throw new Error('Could not hang up all calls')
      }
    } catch (error) {
      LOG.error({
        msg: 'Error while hanging up calls',
        error: error.message
      })
    }

    return areHungUp
  }

  /**
   * Applies authorization status to all contacts except current session contacts.
   * @async
   */
  async applyBlocklist () {
    for (const contact of this.contacts.values()) {
      if (!this.hasSessionContact(contact)) {
        await this.applyContactAuthorization(contact, this.defaultAuthorization)
      }
    }
  }

  /**
   * Makes caller label for received SIP sources
   * @param {string} quidId - The id of the quiddity requesting the caller label
   * @returns {string} callerLabel - The caller label that is made from the uri of the caller
   */
  makeCallerLabel (quidId) {
    let callerLabel = null
    const uri = this.sipStore.getCallerURI(quidId)
    if (uri) {
      callerLabel = this.getCallerLabelFromURI(uri)
    }
    return callerLabel
  }

  /**
   * Gets the name of the caller from its uri
   * @param {uri} uri - The uri of the caller
   * @returns {string} - The name of the caller
   */
  getCallerLabelFromURI (uri) {
    const contact = Array.from(this.sessionContacts.values()).filter(contact => contact.uri === uri)
    return contact[0]?.name || 'Waiting for data stream'
  }

  /**
   * Packs all SIP contacts into a JSON object
   * @returns {Object} All SIP contacts as a JSON object
   */
  getExportableContacts () {
    const { userContacts } = this.configStore
    const { currentUri } = this.sipStore

    let exportedContacts = {}

    if (userContacts && currentUri) {
      let localContactsList = {}

      for (const contact of this.contacts.values()) {
        localContactsList = {
          ...localContactsList,
          [contact.uri]: {
            name: contact.name
          }
        }
      }

      localContactsList = { [currentUri]: localContactsList }
      exportedContacts = { ...userContacts, ...localContactsList }
    } else if (userContacts) {
      exportedContacts = userContacts
    }

    return exportedContacts
  }

  /**
   * Updates a contact in the contacts Map
   * @param {string} buddyId - ID of the contact to update
   * @param {string} property - Property to update
   * @param {string|string[]} value - New value of the property
   */
  updateContact (buddyId, property, value) {
    const contact = this.contacts.get(buddyId)

    // Only update if value is different
    if (contact && contact[property] !== value) {
      const updatedContact = Contact.fromJSON({
        ...this.contacts.get(buddyId).toJSON(),
        [property]: value
      })

      this.contacts.set(buddyId, updatedContact)

      LOG.info({
        msg: 'Successfully updated contact',
        contact: buddyId,
        uri: updatedContact.uri,
        updatedProperty: property,
        updatedValue: updatedContact[property]
      })
    }
  }

  /**
   * Sets new authorization value for contacts. Turns blacklist on or off.
   * @param {boolean} authorization - The new default authorization
   */
  setDefaultAuthorization (authorization) {
    this.defaultAuthorization = authorization
    LOG.info({
      msg: 'Updated default contacts authorization',
      newAuthorization: authorization
    })
  }

  /**
   * Adds a new contact in the contacts Map
   * @param {module:models/sip.Contact} contact - The new contact to add
   */
  addContact (contact) {
    this.contacts.set(contact.id, contact)

    LOG.info({
      msg: 'Added new contact',
      contact: contact.id,
      uri: contact.uri
    })
  }

  /**
   * Deletes a contact from the contacts Map
   * @param {string} contactId - ID of the contact to delete
   */
  removeContact (contactId) {
    this.contacts.delete(contactId)

    LOG.info({
      msg: 'Deleted contact',
      contact: contactId
    })
  }

  /**
   * Adds a new contact in the partners Set
   * @param {module:models/sip.Contact} contact - The new contact to add
   */
  addContactToSession (contact) {
    if (this.contacts.has(contact.id)) {
      this.partners.add(contact.id)

      LOG.info({
        msg: 'Added new contact in SIP session',
        contact: contact.id,
        uri: contact.uri
      })
    } else {
      LOG.error({
        msg: 'Cannot add contact in SIP session. Contact doesn\'t exist',
        contact: contact.id,
        uri: contact.uri
      })
    }
  }

  /**
   * Deletes a contact from the partners Set
   * @param {string} contactId - ID of the contact to delete
   */
  removeContactFromSession (contactId) {
    this.partners.delete(contactId)

    LOG.info({
      msg: 'Deleted contact from SIP session',
      contact: contactId
    })
  }

  /**
   * Cleans all contacts
   */
  clear () {
    const { NOT_INITIALIZED } = InitStateEnum
    this.setInitState(NOT_INITIALIZED)

    this.partners.clear()
    this.contacts.clear()

    LOG.info({
      msg: 'Successfully cleared all contacts'
    })
  }
}

export default ContactStore

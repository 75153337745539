import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LocalesEn from '@assets/locales/en/locale.json'
import LocalesFr from '@assets/locales/fr/locale.json'

export const DEFAULT_LANGUAGE = 'en'

i18n
  .use(initReactI18next)
  .init({
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    keySeparator: '::',
    nsSeparator: ':::',
    resources: {
      en: {
        translation: {
          ...LocalesEn
        }
      },
      fr: {
        translation: {
          ...LocalesFr
        }
      }
    }
  })

export default i18n

import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { AppStoresContext } from '@components/App'
import { CancelButton } from '@components/common/Buttons'
import { Common, Feedback, Layout } from '@sat-mtl/ui-components'

const { Button } = Common
const { Modal } = Feedback
const { FlexRow } = Layout

/**
 * @constant {string} RENAME_SCENE_MODAL_ID - ID of the rename scene modal
 * @memberof RenameSceneModal
 */
const RENAME_SCENE_MODAL_ID = 'RenameSceneModal'

/**
 * Renders the Cancel Button for the rename scene Modal
 * @memberof module:components/modals.SceneModals
 * @returns {external:react/Component} The cancel button of the Modal
 */
export const RenameSceneCancelButton = ({ onCancel }) => {
  return (
    <CancelButton
      onCancel={onCancel}
    />
  )
}

/**
 * Renders the Rename Button for the rename scene Modal
 * @Selector `.RenameButton`
 * @memberof module:components/modals.SceneModals
 * @returns {external:react/Component} The rename button of the Modal
 */
export const RenameSceneButton = ({ onSceneRename }) => {
  const { t } = useTranslation()
  return (
    <Button
      type='danger'
      className='RenameButton'
      shape='rectangle'
      onClick={onSceneRename}
    >
      {t('Rename')}
    </Button>
  )
}

/**
 * Renders the modal used to confirm renaming of a Scene
 * @param {Function} props.onCancel - Function triggered when the user cancels the rename scene action
 * @param {Function} props.onSceneRename - Function triggered when the user confirms the scene action
 * @memberof module:components/modals.SceneModals
 * @selector `#RenameSceneModal`
 * @returns {external:mobx-react/ObserverComponent} The rename scene modal
 */
const RenameSceneModal = observer(({ newSceneName, onSceneRename, onCancel }) => {
  const { modalStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  useEffect(() => {
    modalStore.addModal(RENAME_SCENE_MODAL_ID)
    modalStore.setActiveModal(RENAME_SCENE_MODAL_ID)

    return () => {
      modalStore.removeModal(RENAME_SCENE_MODAL_ID)
    }
  })

  return (
    <Modal
      id='RenameSceneModal'
      visible={modalStore.activeModal === RENAME_SCENE_MODAL_ID}
      footer={(
        <FlexRow justifyContent='space-between'>
          <RenameSceneCancelButton onCancel={onCancel} />
          <RenameSceneButton onSceneRename={onSceneRename} />
        </FlexRow>
      )}
    >
      {t("Rename scene to '{{name}}'?", { name: newSceneName })}
    </Modal>
  )
})

/**
 * @constant {string} DELETE_SCENE_MODAL_ID - ID of the delete scene modal
 * @memberof DeleteSceneModal
 */
const DELETE_SCENE_MODAL_ID = 'DeleteSceneModal'

/**
 * Renders the Cancel Button for the delete scene Modal
 * @memberof module:components/modals.SceneModals
 * @returns {module:components/common.CancelButton} The cancel button of the Modal
 */
export const DeleteSceneCancelButton = () => {
  const { modalStore } = useContext(AppStoresContext)
  return (
    <CancelButton
      onCancel={() => {
        modalStore.cleanActiveModal(DELETE_SCENE_MODAL_ID)
      }}
    />
  )
}

/**
 * Renders the Delete Button for the delete scene Modal
 * @Selector `.DeleteButton`
 * @memberof module:components/modals.SceneModals
 * @returns {external:react/Component} The delete button of the Modal
 */
export const DeleteSceneButton = ({ onSceneDelete }) => {
  const { t } = useTranslation()

  return (
    <Button
      type='danger'
      className='DeleteButton'
      shape='rectangle'
      onClick={onSceneDelete}
    >
      {t('Delete')}
    </Button>
  )
}

/**
 * Renders the modal used to confirm the deletion of a Scene
 * @param {Function} props.onSceneDelete - Function triggered when the user confirms the delete scene action
 * @param {module:models/userTree.Scene} sceneToDelete - Object holding information about the scene that should be deleted
 * @selector `#DeleteSceneModal`
 * @memberof module:components/modals.SceneModals
 * @returns {external:mobx-react/ObserverComponent} The delete scene modal
 */
const DeleteSceneModal = observer(({ sceneToDelete, onSceneDelete }) => {
  const { modalStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  useEffect(() => {
    modalStore.addModal(DELETE_SCENE_MODAL_ID)

    return () => {
      modalStore.removeModal(DELETE_SCENE_MODAL_ID)
    }
  })

  return (
    <Modal
      id='DeleteSceneModal'
      visible={modalStore.activeModal === DELETE_SCENE_MODAL_ID}
      footer={(
        <FlexRow justifyContent='space-between'>
          <DeleteSceneCancelButton />
          <DeleteSceneButton onSceneDelete={onSceneDelete} />
        </FlexRow>
      )}
    >
      {t("Remove scene '{{scene}}'?", { scene: sceneToDelete?.name })}
    </Modal>
  )
})

export {
  RENAME_SCENE_MODAL_ID,
  RenameSceneModal,
  DELETE_SCENE_MODAL_ID,
  DeleteSceneModal
}

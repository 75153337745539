import React, { useEffect, createContext } from 'react'

import { Context } from '@sat-mtl/ui-components'

import PageWrapper from '@components/common/PageWrapper'
import PageMenu from '@components/menus/PageMenu'

import ConfigurationModal from '@components/modals/ConfigurationModal'
import NdiStreamModal from '@components/modals/NdiStreamModal'

import SwitcherConnectionModal from '@components/modals/SwitcherConnectionModal'
import RtmpModal from '@components/modals/RtmpModal'

import { initializeSocket } from '@stores/initializeStores.js'

import '@sat-mtl/ui-components/ui-components.css'
import '@styles/App.scss'

/**
 * @constant {external:react/Context} AppStoresContext - Dispatches all the App stores
 * @memberof components.App
 */
export const AppStoresContext = createContext({})

const { ThemeProvider } = Context

/**
 * Renders the App
 * @memberof components.App
 */
function App ({ stores }) {
  const { pageStore } = stores

  useEffect(() => {
    initializeSocket(stores)
  })

  return (
    <ThemeProvider value='simon'>
      <AppStoresContext.Provider value={stores}>
        <ConfigurationModal />
        <SwitcherConnectionModal />
        <NdiStreamModal />
        <RtmpModal />
        <PageMenu pageStore={pageStore} />
        <PageWrapper />
      </AppStoresContext.Provider>
    </ThemeProvider>
  )
}

export default App

import hash from 'hash-it'
import { escapeAsArray } from '@utils/stringTools'

/**
 * Regex which helps to craft an ID for NDI streams
 * @constant
 * @memberof module:models/quiddity.NdiStream
 */
const NDI_REGEX = /(.*) \((.*)\)/

/**
 * @classdesc Set of properties used by Scenic to manage NDI streams
 * @memberof module:models/quiddity
 */
class NdiStream {
  /**
   * Instantiates a new NDI stream with parameters parsed from its name
   * @param {string} id - Unique ID of the NDI stream
   * @param {string} name - Name of the NDI stream (used as the quiddity name)
   * @param {string} label - Label of the quiddity name (used to display the available streams)
   * @param {string} host - IP address of the host that provides the NDI stream
   * @param {string} [swPort='8000'] - Port used in order to communicate with Switcher
   */
  constructor (id, name, label, host, swPort = '8000') {
    /** @property {id} id - Unique ID generated by Scenic */
    this.id = id

    /** @property {string} label - Default label of NDI streams provided by NewTek NDI */
    this.label = label

    /** @property {string} name - Parsed name from the default label */
    this.name = name

    /** @property {string} host - Parsed host name from the default label */
    this.host = host

    /** @property {string} swPort - Switcher port used to generate shmdata names */
    this.swPort = swPort
  }

  /**
   * Casts the NDI stream to an option for Select component
   * @returns {Object} A selectable option
   */
  toOption () {
    return {
      value: this.name,
      label: this.label
    }
  }

  /**
   * Creates NdiStreams from the ndi2shmdata output
   * @param {string} stdout - Output of ndi2shmdata
   * @param {string} [swPort='8000'] - Port used in order to communicate with Switcher
   * @returns {models.NdiStream[]} All NDI stream parsed in the output
   * @static
   */
  static fromOutput (stdout, swPort = '8000') {
    return escapeAsArray(stdout)
      .map(raw => (NdiStream.fromNdiEntry(raw, swPort)))
  }

  /**
   * Creates a NdiStream from a ndi2shmdata entry
   * @param {string} entry - Entry of a NDI stream provided by ndi2shmdata
   * @param {string} [swPort='8000'] - Port used in order to communicate with Switcher
   * @returns {models.NdiStream} The NDI stream parsed from a ndi2shmdata entry
   * @static
   */
  static fromNdiEntry (entry, swPort = '8000') {
    let ndiStream = null

    if (NDI_REGEX.test(entry)) {
      const [, host, name] = entry.match(NDI_REGEX)
      ndiStream = new NdiStream(`ndi_${hash(entry)}`, name, entry, host, swPort)
    } else {
      throw new Error('Failed to create an NDI® stream model from an invalid NDI® label')
    }

    return ndiStream
  }

  /** @property {number} hash - Unique hash of the NDI label */
  get hash () {
    return this.id.split('_').pop()
  }

  /** @property {string} directory - Name of the directory where the shmdata will be generated */
  get directory () {
    return `/tmp/scenic/${this.id}`
  }

  /** @property {string} commandLine - Executed command line in order to generate shmdatas from the NDI stream */
  get commandLine () {
    return [
      `ndi2shmdata -n '${this.label}'`,
      `-v ${this.directory}/ndi${this.hash}_video`,
      `-a ${this.directory}/ndi${this.hash}_audio`
    ].join(' ')
  }

  /** @property {Object} properties - Init properties of the NDIOutput quiddity */
  get properties () {
    return {
      'Executor/command_line': this.commandLine,
      'Watcher/directory': this.directory
    }
  }
}

export default NdiStream

import { observable, action, reaction, makeObservable } from 'mobx'

import Store from '@stores/Store'
import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the NicknameStore
 * @memberof stores.NicknameStore
 */
export const LOG = logger.child({ store: 'NicknameStore' })

/**
 * @constant {string} DEFAULT_NICKNAME - Nickname used by default for new quiddities
 * @memberof module:stores/quiddity.NicknameStore
 */
export const DEFAULT_NICKNAME = 'UNKNOWN'

/**
 * @classdesc Stores all nicknames from given quiddity IDs
 * @extends module:stores/common
 * @memberof module:stores/quiddity
 */
class NicknameStore extends Store {
  /** @property {Map<string, string>} nicknames - All nicknames hashed by quiddity ID */
  nicknames = new Map()

  /**
   * Instantiates a new NicknameStore
   * @param {module:stores/common.SocketStore} socketStore - Stores and manages the current event-driven socket
   * @param {module:stores/quiddity.QuiddityStore} quiddityStore - Quiddity manager
   * @constructor
   */
  constructor (socketStore) {
    super(socketStore)

    makeObservable(this, {
      nicknames: observable,
      addNickname: action,
      removeNickname: action
    })

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )
  }

  /**
   * Fetch the nickname for a given quiddity ID
   * @param {string} quiddityId - ID of the quiddity
   * @returns {string} The quiddity's nickname
   * @async
   */
  async fetchNickname (quiddityId) {
    const { nicknameAPI } = this.socketStore.APIs
    let nickname = quiddityId

    try {
      nickname = await nicknameAPI.get(quiddityId)
    } catch (error) {
      LOG.warn({
        msg: 'Failed to fetch nickname',
        defaultValue: quiddityId,
        quiddity: quiddityId,
        error: error
      })
    }

    return nickname
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    if (socket && this.socketStore.hasActiveAPIs) {
      const { nicknameAPI } = this.socketStore.APIs

      nicknameAPI.onUpdated(
        (quidId, name) => this.addNickname(quidId, name)
      )
    }
  }

  /**
   * Synchronizes all nicknames for all watched quiddities
   * @param {string[]} [quiddityIds=[]] - All IDs of the created quiddities
   * @todo improve reaction because when created nicknames
   *       are updated but removed before on-created signal
   * @async
   */
  async handleQuiddityChange (quiddityIds) {
    for (const quiddityId of quiddityIds) {
      await this.updateNickname(quiddityId)
    }
  }

  /**
   * Applies a new nickname for a quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} nickname - New nickname for the quiddity
   * @async
   */
  async applyNicknameUpdate (quiddityId, nickname) {
    const { nicknameAPI } = this.socketStore.APIs

    try {
      await nicknameAPI.set(quiddityId, nickname)

      LOG.info({
        msg: 'Successfully set quiddity\'s nickname',
        quiddity: quiddityId,
        newNickname: nickname
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to set quiddity\'s nickname',
        quiddity: quiddityId,
        newNickname: nickname,
        error: error.message
      })
    }
  }

  /**
   * Initializes the nickname Map for a given quiddity ID
   * @param {string} quiddityId - ID of the quiddity we want to nickname
   */
  initializeNickname (quiddityId) {
    if (!this.nicknames.has(quiddityId)) {
      this.updateNickname(quiddityId)
    }
  }

  async updateNickname (quiddityId) {
    try {
      if (!this.nicknames.has(quiddityId)) {
        this.addNickname(quiddityId, quiddityId)
      }

      const nickname = await this.fetchNickname(quiddityId)

      if (this.nicknames.get(quiddityId) !== nickname) {
        this.addNickname(quiddityId, nickname)
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to update nickname',
        quidId: quiddityId,
        error: error.message
      })
    }
  }

  /**
  * Makes the title of the destination head
  * @param {models.MatrixEntry} entry - A matrix entry
  * @param {?string} [defaultLabel='NO NICKNAME] - Fallback label if there is no label returned
  * @returns {string} Returns the contact title or the quiddity nickname
  */
  makeDestinationHeadTitle (entry, defaultLabel) {
    let $title = defaultLabel

    if (entry.isContact) {
      $title = entry.contact.name
    } else if (this.nicknames.has(entry.quiddity.id)) {
      $title = this.nicknames.get(entry.quiddity.id)
    }

    return $title
  }

  /**
   * Updates a nickname
   * @param {string} quiddityId - Quiddity ID
   * @param {string} nickname - New nickname for the quiddity
   */
  addNickname (quiddityId, nickname) {
    this.nicknames.set(quiddityId, nickname)

    LOG.info({
      msg: 'Successfully added nickname ' + nickname + ' for quiddity id ' + quiddityId,
      quiddity: quiddityId,
      nickname: nickname
    })
  }

  /**
   * Deletes a nickname for a quiddity
   * @param {string} quiddityId - Quiddity ID
   */
  removeNickname (quiddityId) {
    this.nicknames.delete(quiddityId)

    LOG.info({
      msg: 'Successfully removed nickname',
      quiddity: quiddityId
    })
  }
}

export default NicknameStore

import React, { useContext } from 'react'
import { Common, Context } from '@sat-mtl/ui-components'
import classNames from 'classnames'

import '@styles/common/LockIcons.scss'

const { Icon } = Common
const { ThemeContext } = Context

/**
 * Renders a lock icon
 * @selector `.Lock`
 * @returns {external:ui-components/Icon} A styled lock icon
 */
function Lock () {
  const theme = useContext(ThemeContext)

  const cl = classNames(
    `Lock-${theme}`,
    'Lock'
  )

  return (
    <div className={cl}>
      <Icon type='lock' />
    </div>
  )
}

/**
 * Renders alock icon that fits with Connection boxes
 * @selector `.ConnectionLock`
 * @returns {external:ui-components/Icon} A styled lock icon
 */
function ConnectionLock () {
  const theme = useContext(ThemeContext)

  const cl = classNames(
    `ConnectionLock-${theme}`,
    'ConnectionLock'
  )

  return (
    <div className={cl}>
      <Icon type='lock' />
    </div>
  )
}

export {
  Lock,
  ConnectionLock
}

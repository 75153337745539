import React, { useContext, useState, useEffect } from 'react'
import { observer } from 'mobx-react'

import { useTranslation } from 'react-i18next'

import HelpWrapper from '@components/wrappers/HelpWrapper'
import { RenameSceneModal } from '@components/modals/SceneModals'

import StatusEnum from '@models/common/StatusEnum'
import { Inputs, Layout } from '@sat-mtl/ui-components'
import { AppStoresContext } from '@components/App'

import '@styles/panels/SceneControlPanel.scss'

const { FlexRow } = Layout
const { InputText, Checkbox } = Inputs

/** The Scene control panel gives all controls for Scene modification (name, status) */
const SceneNameInput = observer(() => {
  const { sceneStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  const [isRenaming, toggleRenaming] = useState(false)
  const [newSceneName, changeName] = useState(null)

  useEffect(() => {
    changeName(sceneStore.selectedScene?.name)
  }, [sceneStore.selectedScene?.name])

  const onRenaming = () => {
    if (newSceneName && newSceneName !== sceneStore.selectedScene?.name) {
      toggleRenaming(true)
    } else {
      changeName(sceneStore.selectedScene?.name)
    }
  }

  return (
    <HelpWrapper message={t('Rename the current scene (press Enter to confirm)')}>
      {isRenaming && (
        <RenameSceneModal
          newSceneName={newSceneName}
          onSceneRename={async () => {
            await sceneStore.applySceneName(sceneStore.selectedScene?.id, newSceneName)
            toggleRenaming(false)
            changeName(null)
          }}
          onCancel={() => {
            toggleRenaming(false)
            changeName(sceneStore.selectedScene?.name)
          }}
        />
      )}
      <InputText
        placeholder={t('Set a new name')}
        value={newSceneName}
        onBlur={onRenaming}
        onPressEnter={onRenaming}
        onChange={(e) => changeName(e.target.value)}
      />
    </HelpWrapper>
  )
})

/**
 * Renders scene activation checkbox
 * @selector `#SceneControlPanel .Checkbox`
 * @returns {external:ui-components/Inputs.Checkbox} The scene activation checkbox
 */
const SceneActivationCheckbox = observer(() => {
  const { sceneStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  const isActive = sceneStore.selectedScene?.isActive || false
  const helpAction = isActive ? t('Deactivate') : t('Activate')
  const msg = t('{{action}} the current Scene', { action: helpAction })

  return (
    <HelpWrapper message={msg}>
      <Checkbox
        status={StatusEnum.ACTIVE}
        shape='circle'
        type='secondary'
        size='normal'
        checked={isActive}
        onChange={() => {
          sceneStore.applyActiveSceneSwitch(sceneStore.selectedScene?.id, !isActive)
        }}
      />
    </HelpWrapper>
  )
})

const SceneControlPanel = observer(() => {
  const { sceneStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return sceneStore.selectedScene && (
    <div id='SceneControlPanel'>
      <p className='SceneControlPanelTitle'>{t('Scene Panel')}</p>
      <FlexRow alignItems='center' columnGap={8}>
        <SceneActivationCheckbox />
        <SceneNameInput />
      </FlexRow>
    </div>
  )
})

export default SceneControlPanel

import { observable, action, computed, makeObservable } from 'mobx'

import Store from '@stores/Store'
import Kind from '@models/quiddity/Kind'
import InitStateEnum from '@models/common/InitStateEnum'

import { logger } from '@utils/logger'

const { INITIALIZING, INITIALIZED, NOT_INITIALIZED } = InitStateEnum

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the KindStore
 * @memberof module:stores/quiddity.KindStore
 */
export const LOG = logger.child({ store: 'KindStore' })

/**
 * @classdesc Stores all classes
 * @extends module:stores/quiddity.KindStore
 * @memberof module:stores/quiddity
 */
class KindStore extends Store {
  /** @property {Map<string, module:models/quiddity>} kinds - All kinds hashed by their names */
  kinds = new Map()

  /** @property {string[]} kindIds - All kind IDs */
  get kindIds () {
    return Array.from(this.kinds.keys())
  }

  constructor (socketStore) {
    super(socketStore)

    makeObservable(this, {
      kinds: observable,
      kindIds: computed,
      addKind: action,
      clear: action
    })
  }

  /**
   * Initializes all available kinds in Switcher
   * @param {boolean} [reloadKinds=false] Force the reloading of all kinds
   * @returns {boolean} Return false if the initialization failed
   */
  async initialize (reloadKinds = false) {
    if (this.isNotInitialized()) {
      this.setInitState(INITIALIZING)

      /* Available quiddity kinds will not be changed during a `switcher` instance
       * so, it fetches all kinds only once */
      if (this.kinds.size === 0 || reloadKinds) {
        await this.initializeKinds()
      }

      this.setInitState(INITIALIZED)
    }

    return this.isInitialized()
  }

  /**
   * Initializes all quiddity kinds
   * @async
   */
  async initializeKinds () {
    const jsonKinds = await this.fetchKinds()

    for (const json of jsonKinds) {
      const newKind = this.makeKindModel(json)

      if (newKind) {
        this.addKind(newKind)
      } else {
        LOG.warn({
          msg: `Skipped initialization of kind ${json.id}`,
          kindId: json.id
        })
      }
    }

    LOG.info({
      msg: 'All quiddity kinds are initialized',
      kinds: this.kindIds
    })
  }

  /**
   * Fetches all quiddity kinds
   * @returns {Promise<Object[]>} Array of all JSON kinds
   * @async
   */
  async fetchKinds () {
    const { quiddityAPI } = this.socketStore.APIs
    let kindModels = []

    try {
      const jsonResult = await quiddityAPI.listKinds()

      if (Array.isArray(jsonResult.kinds)) {
        kindModels = jsonResult.kinds
      } else {
        throw new Error('Failed to fetch all kinds as an array')
      }
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'Resource fetching failure',
        msg: 'A server error occurred while fetching resource kinds.',
        error: error.message,
        kinds: this.kindIds
      })
    }

    return kindModels
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()
  }

  /**
   * Safely makes a kind model from a JSON object
   * @param {Object} json - JSON representation of the kind
   * @returns {module:models/quiddity.QuiddityKind} A kind model
   */
  makeKindModel (json) {
    let model = null

    try {
      model = Kind.fromJSON(json)
    } catch (error) {
      LOG.error({
        msg: 'Failed to add a quiddity with a bad format',
        error: error.message
      })
    }

    return model
  }

  /**
   * Checks if a kind represents a following quiddity
   * @param {string} kindId - Name of the kind
   * @returns {boolean} Returns true if the kind represents a following quiddiy
   */
  isFollower (kindId) {
    return this.kinds.get(kindId).isFollower
  }

  /**
   * Checks if a kind represents a writing quiddity
   * @param {string} kindId - Name of the kind
   * @returns {boolean} Returns true if the kind represents a writing quiddity
   */
  isWriter (kindId) {
    return this.kinds.get(kindId).isWriter
  }

  /**
   * Add a new quiddity kind in the kinds Map
   * @param {module:models/quiddity.Kind} kind - The kind to add
   */
  addKind (kind) {
    this.kinds.set(kind.id, kind)

    LOG.debug({
      msg: 'Added a new quiddity kind',
      kind: kind.id
    })
  }

  /**  Cleans up quiddities */
  clear () {
    this.setInitState(NOT_INITIALIZED)
    this.kinds.clear()

    LOG.debug({
      msg: 'Successfully cleared all kinds'
    })
  }
}

export default KindStore

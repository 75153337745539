import React, { useState, useContext, useEffect } from 'react'
import Mousetrap from 'mousetrap'
import { observer } from 'mobx-react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import StatusEnum from '@models/common/StatusEnum'

import { DRAWER_WIDTH } from '@stores/common/DrawerStore'
import { AppStoresContext } from '@components/App'
import HelpWrapper from '@components/wrappers/HelpWrapper'
import FileEntry from '@components/entries/FileEntry'

import NewFileModal, { NEW_FILE_MODAL_ID } from '@components/modals/NewFileModal'

import { Feedback, Layout, Common, Inputs } from '@sat-mtl/ui-components'

import '@styles/drawers/FileDrawers.scss'

const { FlexRow } = Layout
const { Drawer } = Feedback
const { Button } = Common
const { Checkbox } = Inputs

/**
 * @constant {string} FILE_SAVING_DRAWER_ID - ID of the file loading drawer
 * @memberof module:components/drawers.FileSavingDrawer
 */
const FILE_SAVING_DRAWER_ID = 'FileSavingDrawer'

/**
 * Renders a file entry
 * @memberof module:components/drawers.FileSavingDrawer
 * @param {module:models/common.FileBridge} file - A file model
 * @param {Function} onSelect - Function triggered when a file is selected
 * @param {boolean} isCurrent - Flags if this is the current session's file
 * @param {boolean} isSelected - Flags a selected entry
 * @returns {external:react/Component} The entry
 */
function FileSavingEntry ({ file, onSelect, isCurrent, isSelected }) {
  const { t } = useTranslation()

  const help = t('Save session as {{name}}', { name: file.fileName })
  const onEntryClick = () => onSelect(file)

  const classes = classNames(
    'FileSavingEntry',
    { 'FileSavingEntry-selected': isSelected }
  )

  return (
    <HelpWrapper message={help}>
      <li className={classes}>
        <FileEntry
          file={file}
          isCurrent={isCurrent}
          description={help}
          onClick={onEntryClick}
          status={isCurrent ? StatusEnum.FOCUS : StatusEnum.INACTIVE}
          selected={isSelected}
        />
        <Checkbox
          checked={isSelected}
          status={StatusEnum.FOCUS}
          onChange={onEntryClick}
          shape='circle'
        />
      </li>
    </HelpWrapper>
  )
}

/**
 * Renders a button used to save a file
 * @memberof module:components/drawers.FileSavingDrawer
 * @param {module:models/common.FileBridge} file - A file model
 * @param {Function} onClick - Function triggered when the button is clicked
 * @returns {external:react/Component} A Button
 */
function SaveAsFileButton ({ file, onClick }) {
  const { t } = useTranslation()
  let help = t('No file is selected')

  if (file) {
    help = t('Save current session as {{name}}', { name: file.fileName })
  }

  const buttonProps = {
    className: 'SaveAsButton',
    onClick: onClick,
    disabled: !file,
    shape: 'rectangle'
  }

  return (
    <HelpWrapper message={help}>
      <Button {...buttonProps}>
        {t('Save As')}
      </Button>
    </HelpWrapper>
  )
}

/**
 * Renders the list of all files to write to
 * @memberof module:components/drawers.FileSavingDrawer
 * @param {module:models/common.FileBridge[]} files - An array of file models
 * @param {string} selectedFileId - ID of the selected file
 * @param {string} currentSessionId - ID of the current session's file
 * @param {Function} onSelect - Function triggered when an entry is selected
 * @returns {external:react/Component} List of all file entries
 */
function FileSavingList ({ files, selectedFileId, currentSessionId, onSelect }) {
  const { t } = useTranslation()
  let $files = t('No sessions found')

  if (files.length > 0) {
    $files = (
      <ul>
        {files.map(f => (
          <FileSavingEntry
            key={f.id}
            file={f}
            isCurrent={f.id === currentSessionId}
            isSelected={f.id === selectedFileId}
            onSelect={onSelect}
          />
        ))}
      </ul>
    )
  }

  return $files
}

/**
 * Renders a button that creates a new file
 * @memberof module:components/drawers.FileSavingDrawer
 * @param {Function} onCreate - Function triggered when the button is clicked
 * @returns {external:react/Component} A Button component
 */
function CreateNewFileButton ({ onCreate }) {
  const { t } = useTranslation()

  const help = t('Create a new file on the server')
  const label = t('Create new')

  const buttonProps = {
    className: 'NewFileButton',
    type: 'primary',
    outlined: true,
    onClick: onCreate,
    shape: 'rectangle'
  }

  return (
    <HelpWrapper message={help}>
      <Button {...buttonProps}>
        {label}
      </Button>
    </HelpWrapper>
  )
}

/**
 * The file saving drawer is used to save sessions into files
 * This component observes the stores SessionStore, ModalStore
 * It also observes the store DrawerStore as a React.Context
 * @memberof module:components/drawers
 * @returns {external:mobx-react/ObserverComponent} An observer drawer with all associated modals
 * @see [MobX guide for the Observer components]{@link https://mobx.js.org/react-integration.html}
 * @see [React Context hook]{@link https://reactjs.org/docs/hooks-reference.html#usecontext}
 */
const FileSavingDrawer = observer(() => {
  const { t } = useTranslation()
  const { drawerStore, modalStore, sessionStore } = useContext(AppStoresContext)
  const [selectedFile, selectFile] = useState(null)

  useEffect(() => {
    drawerStore.addDrawer(FILE_SAVING_DRAWER_ID)
    modalStore.addModal(NEW_FILE_MODAL_ID)

    Mousetrap.bind('ctrl+shift+s', event => {
      event.preventDefault && event.preventDefault()
      drawerStore.toggleActiveDrawer(FILE_SAVING_DRAWER_ID)
    })

    return () => {
      drawerStore.removeDrawer(FILE_SAVING_DRAWER_ID)
      modalStore.removeModal(NEW_FILE_MODAL_ID)
      Mousetrap.unbind('ctrl+shift+s')
    }
  })

  useEffect(() => {
    if (drawerStore.activeDrawer === FILE_SAVING_DRAWER_ID) {
      sessionStore.updateSessionList()
    }

    selectFile(null)
  }, [drawerStore.activeDrawer])

  return (
    <>
      <NewFileModal
        fileNameList={Array.from(sessionStore.sessions.values()).map(f => f.name)}
        selectedFileName={selectedFile?.name}
        visible={modalStore.activeModal === NEW_FILE_MODAL_ID}
        onCancel={() => {
          modalStore.clearActiveModal()
        }}
        onConfirm={fileName => {
          modalStore.clearActiveModal()
          sessionStore.applySessionSavingAs(fileName)
          sessionStore.updateSessionList()
        }}
      />
      <Drawer
        id={FILE_SAVING_DRAWER_ID}
        width={DRAWER_WIDTH}
        visible={drawerStore.activeDrawer === FILE_SAVING_DRAWER_ID}
        header={(
          <>
            <h2>
              {t('Save current session as')}
            </h2>
            <p>
              {t('Select a file where the current session should be saved')}
            </p>
          </>
        )}
        footer={(
          <FlexRow justifyContent='space-between' alignItems='center'>
            <CreateNewFileButton
              onCreate={() => modalStore.setActiveModal(NEW_FILE_MODAL_ID)}
            />
            <SaveAsFileButton
              file={selectedFile}
              onClick={() => sessionStore.applySessionSavingAs(selectedFile.name)}
            />
          </FlexRow>
        )}
        onBackdropClick={() => {
          drawerStore.clearActiveDrawer()
        }}
      >
        <FileSavingList
          files={Array.from(sessionStore.sessions.values())}
          selectedFileId={selectedFile ? selectedFile.id : null}
          currentSessionId={sessionStore.currentSessionId}
          onSelect={f => selectFile(f)}
        />
      </Drawer>
    </>
  )
})

export default FileSavingDrawer

export {
  FILE_SAVING_DRAWER_ID,
  FileSavingEntry,
  SaveAsFileButton,
  FileSavingList,
  CreateNewFileButton
}

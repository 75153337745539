import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Layout, Inputs } from '@sat-mtl/ui-components'
import { MATRIX_INNER_GAP, MATRIX_BOX_PROPS } from '@components/matrix/Matrix'
import { PROPERTY_INSPECTOR_DRAWER_ID } from '@components/drawers/PropertyInspectorDrawer'
import { AppStoresContext } from '@components/App'

import HelpWrapper from '@components/wrappers/HelpWrapper'
import ShmdataBox from '@components/matrix/ShmdataBox'
import NicknameLabel from '@components/quiddity/NicknameLabel'
import RowEntry from '@components/entries/RowEntry'
import { Lock } from '@components/common/LockIcons'

import StatusEnum from '@models/common/StatusEnum'
import { EXTERNAL_SHMDATA_SOURCE_KIND_ID } from '@models/quiddity/specialQuiddities'

const { FlexRow, Cell } = Layout
const { Checkbox } = Inputs

/**
 * @constant {Object} ROW_PREFIX_STYLE - Properties shared by all row prefixes
 * @memberof module:components/matrix.Source
 */
export const ROW_PREFIX_STYLE = {
  margin: '8px',
  width: '20px'
}

/**
 * Renders a shmdata box related to a source
 * @selector `.SourceShmdata[data-shmdata="shmdata path"]`
 * @param {module:models/matrix.MatrixEntry} entry - The rendered matrix entry
 * @returns {module:components/matrix.ShmdataBox} The shmdata box
 */
function SourceShmdata ({ entry }) {
  const stores = useContext(AppStoresContext)
  const { t } = useTranslation()

  const help = t(
    "This source produces a stream of type '{{- type}}'",
    { type: entry?.defaultShmdata?.mediaType }
  )

  return (
    <div className='SourceShmdata' data-shmdata={entry.defaultShmdata?.path}>
      <HelpWrapper message={help}>
        <ShmdataBox
          shmdata={entry.defaultShmdata}
          entry={entry}
          {...stores}
        />
      </HelpWrapper>
    </div>
  )
}

/**
 * Renders an empty box that replaces a shmdata
 * @selector `.EmptyShmdata`
 * @returns {external:ui-components/Cell} An empty cell
 */
function EmptyShmdata () {
  return (
    <Cell
      className='EmptyShmdata'
      {...MATRIX_BOX_PROPS}
    />
  )
}

/**
 * Renders a lock on the source
 * @selector `.SourceLock`
 * @returns {external:react/Component} A row prefix with the lock icon
 */
function SourceLock () {
  const { t } = useTranslation()

  return (
    <div className='SourceLock' style={ROW_PREFIX_STYLE}>
      <HelpWrapper message={t('The source is locked by one or more connections')}>
        <Lock />
      </HelpWrapper>
    </div>
  )
}

/**
 * Renders a checkbox that starts or stops a source
 * @selector `.SourceStarter[data-source="source id"][data-nickname="source nickname"]`
 * @param {module:models/quiddity.Quiddity} quiddity - The source quiddity
 * @returns {external:mobx-react/ObserverComponent} The starter checkbox
 */
const SourceStarter = observer(({ quiddity }) => {
  const { t } = useTranslation()
  const { propertyStore, nicknameStore } = useContext(AppStoresContext)

  const isStarted = propertyStore.startableProperties.get(quiddity.id)
  const nickname = nicknameStore.nicknames.get(quiddity.id)

  let help

  if (isStarted) {
    help = t(
      "Click to stop the '{{name}}' source",
      { name: nickname || quiddity.id }
    )
  } else {
    help = t(
      "Click to start the '{{name}}' source",
      { name: nickname || quiddity.id }
    )
  }

  return (
    <div className='SourceStarter' style={{ margin: '8px' }} data-source={quiddity.id} data-nickname={nickname}>
      <HelpWrapper message={help}>
        <Checkbox
          status={StatusEnum.ACTIVE}
          shape='square'
          type='secondary'
          size='normal'
          checked={isStarted}
          onChange={() => {
            propertyStore.applyNewPropertyValue(quiddity.id, 'started', !isStarted)
          }}
        />
      </HelpWrapper>
    </div>
  )
})

/**
 * Renders an empty starter that does nothing
 * @selector `.EmptyStarter`
 * @returns {external:react/Component} An empty div
 */
function EmptyStarter () {
  return (
    <div className='EmptyStarter' style={ROW_PREFIX_STYLE} />
  )
}

/**
 * Renders the subtitle of a source
 * @param {module:models/quiddity.Quiddity} quiddity - The source quiddity
 * @param {module:models/shmdata.Shmdata} shmdata - The source shmdata
 * @returns {string} The source subtitle
 */
function SourceSubtitle ({ entry }) {
  const { quiddityMenuStore, contactStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const kindLabel = t(quiddityMenuStore.kindLabels.get(entry?.quiddity?.kindId))
  let subtitle = kindLabel || entry?.quiddity?.kindId

  if (entry?.shmdatas.length !== 0) {
    subtitle += ` (${entry?.encodedVideoShmdata ? entry?.encodedVideoShmdata?.mediaType : entry?.defaultShmdata?.mediaType})`

    if (kindLabel.toLowerCase() === 'sip' && entry?.quiddity.kindId === EXTERNAL_SHMDATA_SOURCE_KIND_ID) {
      const newSubtitle = contactStore.makeCallerLabel(entry?.quiddity.id)
      if (newSubtitle) subtitle = newSubtitle
    }
  }

  return subtitle
}

/**
 * Renders the head of the source
 * @selector `.SourceHead[data-source="source id"][data-nickname="source nickname"]`
 * @param {module:models/quiddity.Quiddity} quiddity - The source quiddity
 * @param {module:models/shmdata.Shmdata} shmdata - The source shmdata
 * @returns {external:mobx-react/ObserverComponent} An entry component
 */
const SourceHead = observer(({ entry }) => {
  const { t } = useTranslation()
  const { quiddity } = entry

  const {
    quiddityStore,
    quiddityStatusStore,
    drawerStore,
    nicknameStore
  } = useContext(AppStoresContext)

  const isSelected = quiddityStore.selectedQuiddityIds.has(quiddity?.id)
  const nickname = nicknameStore.nicknames.get(quiddity?.id)
  const status = quiddityStatusStore.sourceStatuses.get(quiddity?.id)

  const help = t(
    "Click to inspect '{{nickname}}''s properties",
    { nickname: nickname || quiddity?.id }
  )
  return (
    <div className='SourceHead' data-source={quiddity?.id} data-nickname={nickname}>
      <HelpWrapper message={help}>
        <RowEntry
          selected={isSelected}
          status={status}
          width={200}
          subtitle={(
            <SourceSubtitle
              entry={entry}
            />
          )}
          title={(
            <NicknameLabel
              quiddityId={quiddity?.id}
            />
          )}
          onClick={event => {
            if (!event.shiftKey) {
              quiddityStore.cleanSelectedQuiddities()
            }

            if (!isSelected) {
              quiddityStore.addSelectedQuiddity(quiddity?.id)
              drawerStore.setActiveDrawer(PROPERTY_INSPECTOR_DRAWER_ID)
            } else {
              drawerStore.cleanActiveDrawer(PROPERTY_INSPECTOR_DRAWER_ID)
            }
          }}
        />
      </HelpWrapper>
    </div>
  )
})

/**
 * Renders an empty source
 * @returns {external:react/Component} An empty div with the size of a source
 */
function EmptySource () {
  return (
    <div
      className='EmptySource'
      style={{
        width: 200 + 20 + MATRIX_BOX_PROPS.width
      }}
    />
  )
}

/**
 * Renders a source row in the matrix
 * @param {modules:models/matrix.MatrixEntry} entry - An entry of the matrix
 * @returns {external:mobx-react/ObserverComponent} A source component for the matrix
 */
const Source = observer(({ entry }) => {
  let $shmdata, $starter
  const { matrixStore } = useContext(AppStoresContext)

  if (entry.hasShmdata) {
    $shmdata = (
      <SourceShmdata entry={entry} />
    )
  } else {
    $shmdata = (
      <EmptyShmdata />
    )
  }

  if (matrixStore.startableSources.has(entry.id)) {
    if (matrixStore.lockedEntries.has(entry.id)) {
      $starter = (
        <SourceLock />
      )
    } else {
      $starter = (
        <SourceStarter quiddity={entry.quiddity} />
      )
    }
  } else {
    $starter = (
      <EmptyStarter />
    )
  }

  return (
    <FlexRow alignItems='center' columnGap={MATRIX_INNER_GAP}>
      {$starter}
      <SourceHead entry={entry} />
      {$shmdata}
    </FlexRow>
  )
})

export default Source

export {
  SourceShmdata,
  EmptyShmdata,
  SourceStarter,
  EmptyStarter,
  SourceSubtitle,
  SourceHead,
  EmptySource
}

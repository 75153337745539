import { observer } from 'mobx-react'
import React, { useContext } from 'react'

import PageEnum from '@models/common/PageEnum'

import { AppStoresContext } from '@components/App'

import MatrixPage from '@components/pages/MatrixPage'
import LivePage from '@components/pages/LivePage'
import SettingsPage from '@components/pages/SettingsPage'
import HelpPage from '@components/pages/HelpPage'

/** Renders a wrapper that selects and renders the active page
 * @returns {external:mobx-react/ObserverComponent} - The page wrapper for an active page
*/
const PageWrapper = observer(() => {
  const stores = useContext(AppStoresContext)
  const { pageStore: { activePage } } = useContext(AppStoresContext)

  switch (activePage) {
    case PageEnum.MATRIX:
      return <MatrixPage {...stores} />

    case PageEnum.LIVE:
      return <LivePage {...stores} />

    case PageEnum.SETTINGS:
      return <SettingsPage {...stores} />

    case PageEnum.HELP:
      return <HelpPage {...stores} />

    default:
      return <div>Not implemented</div>
  }
})

export default PageWrapper

import ConfigStore from '@stores/common/ConfigStore'
import DrawerStore from '@stores/common/DrawerStore'
import SessionStore from '@stores/common/SessionStore'
import HelpMessageStore from '@stores/common/HelpMessageStore'
import ModalStore from '@stores/common/ModalStore'
import PageStore from '@stores/common/PageStore'
import SettingStore from '@stores/common/SettingStore'
import NotificationStore from '@stores/common/NotificationStore'

import MatrixStore from '@stores/matrix/MatrixStore'
import LockStore from '@stores/matrix/LockStore'
import FilterStore from '@stores/matrix/FilterStore'

import { toJS } from 'mobx'

import QuiddityStatusStore from '@stores/quiddity/QuiddityStatusStore'
import ContactStatusStore from '@stores/sip/ContactStatusStore'

import NdiStore from '@stores/special/NdiStore'
import NicknameStore from '@stores/quiddity/NicknameStore'
import OrderStore from '@stores/quiddity/OrderStore'
import PropertyStore from '@stores/quiddity/PropertyStore'
import QuiddityMenuStore from '@stores/quiddity/QuiddityMenuStore'
import KindStore from '@stores/quiddity/KindStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import RtmpStore from '@stores/special/RtmpStore'
import SystemUsageStore from '@stores/special/SystemUsageStore'
import EncoderStore from '@stores/quiddity/EncoderStore'

import ContactStore from '@stores/sip/ContactStore'
import SipStore from '@stores/sip/SipStore'
import SipCredentialStore from '@stores/sip/SipCredentialStore'

import CapsStore from '@stores/shmdata/CapsStore'
import MaxLimitStore from '@stores/shmdata/MaxLimitStore'
import ShmdataStore from '@stores/shmdata/ShmdataStore'
import SpecStore from '@stores/shmdata/SpecStore'
import StatStore from '@stores/shmdata/StatStore'

import PreviewStore from '@stores/timelapse/PreviewStore'
import ThumbnailStore from '@stores/timelapse/ThumbnailStore'

import ConnectionStore from '@stores/userTree/ConnectionStore'
import SceneStore from '@stores/userTree/SceneStore'
import UserTreeStore from '@stores/userTree/UserTreeStore'

import SocketStore from '@stores/SocketStore'

import { bindStores } from '@utils/logger'

/**
 * Creates all the app's Stores
 * @returns {object} Object with all stores used by Scenic
 */
function populateStores () {
  const socketStore = new SocketStore()

  // Common Stores
  const configStore = new ConfigStore(socketStore)
  const helpMessageStore = new HelpMessageStore(socketStore)
  const modalStore = new ModalStore(socketStore)
  const pageStore = new PageStore(socketStore)
  const settingStore = new SettingStore(socketStore, configStore)
  const notificationStore = new NotificationStore(socketStore)

  // Binds notification store and socketStore to the logger
  const boundStores = { notificationStore, socketStore }
  bindStores(boundStores)

  // Quiddity Stores
  const kindStore = new KindStore(socketStore)
  const nicknameStore = new NicknameStore(socketStore)
  const specStore = new SpecStore(socketStore)

  const quiddityStore = new QuiddityStore(
    socketStore,
    configStore,
    kindStore,
    nicknameStore,
    specStore
  )

  const orderStore = new OrderStore(socketStore, quiddityStore)
  const propertyStore = new PropertyStore(socketStore, configStore, quiddityStore)
  const quiddityMenuStore = new QuiddityMenuStore(socketStore, configStore, kindStore, quiddityStore)

  // Shmdata Stores
  const shmdataStore = new ShmdataStore(socketStore, quiddityStore)
  const capsStore = new CapsStore(shmdataStore, specStore, configStore)
  const statStore = new StatStore(socketStore, quiddityStore, shmdataStore)
  const maxLimitStore = new MaxLimitStore(quiddityStore, specStore)

  // SIP Stores
  const sipStore = new SipStore(socketStore, configStore, quiddityStore, shmdataStore)
  const sipCredentialStore = new SipCredentialStore(socketStore)
  const drawerStore = new DrawerStore(socketStore, sipStore)
  const lockStore = new LockStore(quiddityStore, sipStore)

  const contactStore = new ContactStore(
    socketStore,
    configStore,
    quiddityStore,
    shmdataStore,
    sipStore,
    lockStore
  )

  // Timelapse Stores
  const thumbnailStore = new ThumbnailStore(socketStore, configStore, quiddityStore, shmdataStore, settingStore, capsStore, nicknameStore)
  const previewStore = new PreviewStore(socketStore, configStore, quiddityStore, shmdataStore)

  // UserTree Stores
  const userTreeStore = new UserTreeStore(socketStore, quiddityStore, configStore)
  const sceneStore = new SceneStore(socketStore, configStore, quiddityStore, lockStore)
  const sessionStore = new SessionStore(socketStore, quiddityStore, userTreeStore, lockStore)

  const connectionStore = new ConnectionStore(
    socketStore,
    configStore,
    quiddityStore,
    shmdataStore,
    sceneStore,
    maxLimitStore,
    lockStore,
    capsStore
  )

  // Matrix stores
  const encoderStore = new EncoderStore(socketStore, settingStore, capsStore)
  const quiddityStatusStore = new QuiddityStatusStore(socketStore, quiddityStore, propertyStore, shmdataStore, statStore)
  const contactStatusStore = new ContactStatusStore(socketStore, sipStore, contactStore, quiddityStore, quiddityStatusStore)
  const filterStore = new FilterStore(socketStore, quiddityStore, contactStore, sipStore)

  const matrixStore = new MatrixStore(
    quiddityStore,
    propertyStore,
    orderStore,
    shmdataStore,
    contactStore,
    sceneStore,
    connectionStore,
    lockStore,
    sipStore,
    settingStore,
    encoderStore,
    capsStore
  )

  // Quiddity Stores with Modals
  // @todo [swIO] support the NDI
  const ndiStore = new NdiStore(
    socketStore,
    configStore,
    quiddityStore,
    propertyStore,
    quiddityMenuStore,
    maxLimitStore,
    lockStore,
    modalStore
  )

  // @todo [swIO] support the RTMP
  const rtmpStore = new RtmpStore(
    socketStore,
    quiddityStore,
    propertyStore,
    maxLimitStore,
    lockStore,
    modalStore
  )

  const systemUsageStore = new SystemUsageStore(
    socketStore,
    configStore,
    quiddityStore
  )
  const stores = {
    socketStore,
    configStore,
    sessionStore,
    helpMessageStore,
    modalStore,
    pageStore,
    kindStore,
    quiddityStore,
    nicknameStore,
    orderStore,
    propertyStore,
    quiddityMenuStore,
    systemUsageStore,
    encoderStore,
    shmdataStore,
    capsStore,
    statStore,
    maxLimitStore,
    ndiStore,
    rtmpStore,
    sipStore,
    sipCredentialStore,
    drawerStore,
    contactStore,
    thumbnailStore,
    previewStore,
    userTreeStore,
    sceneStore,
    connectionStore,
    matrixStore,
    quiddityStatusStore,
    contactStatusStore,
    settingStore,
    notificationStore,
    filterStore,
    lockStore,
    specStore
  }
  window.stores = stores
  window.toJS = toJS
  return stores
}

export default populateStores

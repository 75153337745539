/**
 * Enum for all setting group ids
 * @readonly
 * @memberof models
 * @enum {string}
 */
const SettingEnum = Object.freeze({
  GENERAL: 'general',
  SIP: 'sip'
})

export default SettingEnum

import API from '@api/API'

/**
 * @classdesc API for managing all quiddities
 * @memberof module:api
 * @extends module:api.API
 */
class QuiddityAPI extends API {
  /**
   * Lists all available quiddity classes
   * @async
   */
  listKinds () {
    return new Promise((resolve, reject) => {
      this.socket.emit('switcher_kinds', (error, list) => {
        if (error) reject(error)
        if (list) resolve(list)
      })
    })
  }

  /**
   * Lists all currently created quiddities
   * @async
   */
  listCreated () {
    return new Promise((resolve, reject) => {
      this.socket.emit('switcher_quiddities', (error, list) => {
        if (error) reject(error)
        if (list) resolve(list)
      })
    })
  }

  /**
   * Creates a quiddity
   * @param {string} kind - Kind of quiddity
   * @param {string} [nickname] - Nickname of the quiddity (default will be quidName, or a generated ID)
   * @param {Object} [properties] - Initial properties of the new quiddity
   * @param {Object} [userData] - Initial user data of the new quiddity
   * @async
   */
  create (kind, nickname, properties = {}, userData = {}) {
    return new Promise((resolve, reject) => {
      this.socket.emit('quiddity_create', kind, nickname, properties, userData, (error, result) => {
        if (error) {
          reject(error)
        } else if (typeof result === 'string') {
          resolve(JSON.parse(result))
        } if (typeof result === 'object') {
          resolve(result)
        } else {
          reject(new Error('quiddity failed to be parsed!'))
        }
      })
    })
  }

  /**
   * Deletes a quiddity
   * @param {string} quiddityId - ID of the quiddity to delete
   */
  delete (quiddityId) {
    return new Promise((resolve, reject) => {
      this.socket.emit('quiddity_delete', quiddityId, (error, result) => {
        if (error) reject(error)
        if (result) resolve(result)
      })
    })
  }

  /**
   * Connects two quiddities
   * @param {string} srcId - ID of the source to connect
   * @param {string} dstId - ID of the destination to connect
   * @async
   */
  connect (srcId, dstId) {
    return new Promise((resolve, reject) => {
      this.socket.emit('quiddity_connect', srcId, dstId, (error, result) => {
        if (error) reject(error)
        if (result) resolve(result)
      })
    })
  }

  /**
   * Connects a writer quiddity to a specific sfid of a reader.
   * @param {string} srcId - ID of the source to connect
   * @param {string} dstId - ID of the destination to connect
   * @param {int} sfid - sfid to which the writer should be connected
   * @async
   */
  connectSfid (srcId, dstId, sfid) {
    return new Promise((resolve, reject) => {
      this.socket.emit('quiddity_connect_sfid', srcId, dstId, sfid, (error, result) => {
        if (error) reject(error)
        if (result) resolve(result)
      })
    })
  }

  /**
   * Protect quiddities from being removed by a call to session.reset
   * @param {string} [quiddityIds] - IDs of the quiddities to protect. Optionnal. If omitted,
     protect all existing quiddities.
   * @async
   */
  protect (quiddityIds) {
    return new Promise((resolve, reject) => {
      // if quiddityIds is omitted, this will call with data set to undefined which will
      // translate to None in switcherIO which will make the python wrapper call the C++
      // function without arguments.
      this.socket.emit('quiddities_protect', quiddityIds, (error, result) => {
        if (error) reject(error)
        if (result) resolve(result)
      })
    })
  }

  /**
   * Disconnects two quiddities
   * @param {string} dstId - ID of the destination to disconnect
   * @param {string} sfId - ID of the connected follower claw
   * @async
   */
  disconnect (dstId, sfId) {
    return new Promise((resolve, reject) => {
      this.socket.emit('quiddity_disconnect', dstId, sfId, (error, result) => {
        if (error) reject(error)
        if (result) resolve(result)
      })
    })
  }

  /**
   * Listens to quiddity creation events
   * @param {function} [onCreateAction=Function.prototype] - The function triggered when a quiddity is created
   * @param {(string|function)} [quiddityMatcher] - Matcher of the created quiddity ID
   */
  onCreated (onCreateAction = Function.prototype, quiddityMatcher) {
    this.socket.on('quiddity_created', (quiddity) => {
      if (this.match(quiddity.id, quiddityMatcher)) {
        onCreateAction(quiddity)
      }
    })
  }

  /**
   * Listens to quiddity deletion events
   * @param {function} [onRemoveAction=Function.prototype] - The function triggered when a quiddity is deleted
   * @param {(string|function)} [quiddityMatcher] - Matcher of the deleted quiddity ID
   */
  onDeleted (onDeleteAction = Function.prototype, quiddityMatcher) {
    this.socket.on('quiddity_deleted', (quidId) => {
      if (this.match(quidId, quiddityMatcher)) {
        onDeleteAction(quidId)
      }
    })
  }
}

export default QuiddityAPI

import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Common } from '@sat-mtl/ui-components'

const { NavTab } = Common
const { Tabs, Tab } = NavTab

/**
 * Navigation bar for Scenic Settings Tabs
 * @selector `#SettingTabBar`
 * @memberof {module:components/bars}
 */
const SettingTabBar = observer(({ settingStore }) => {
  const { t } = useTranslation()

  return (
    <div id='SettingTabBar'>
      <Tabs>
        {settingStore.groupIds.map(groupId => (
          <Tab
            key={groupId}
            onClick={() => settingStore.setActiveGroup(groupId)}
            selected={groupId === settingStore.activeGroupId}
          >
            {t(settingStore.groups.get(groupId))}
          </Tab>
        ))}
      </Tabs>
    </div>
  )
})

export default SettingTabBar

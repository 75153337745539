import API from '@api/API'

/**
 * @classdesc API for managing properties of quiddities
 * @memberof module:api
 * @extends module:api.API
 */
class PropertyAPI extends API {
  /**
   * Gets a property of a quiddity with its name
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} name - Name of the property
   * @async
   */
  get (quiddityId, name) {
    return new Promise((resolve, reject) => {
      this.socket.emit('property_get', quiddityId, name, (error, value) => {
        if (error) {
          reject(error)
        } else {
          resolve(value)
        }
      })
    })
  }

  /**
   * Sets a property of a quiddity with its name
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} name - Name of the property
   * @param {(string|number|boolean|Object)} value - New value to set
   * @async
   */
  set (quiddityId, name, value) {
    return new Promise((resolve, reject) => {
      this.socket.emit('property_set', quiddityId, name, value, (error, value) => {
        if (error) {
          reject(error)
        } else {
          resolve(value)
        }
      })
    })
  }

  /**
   * Handles the update of a property of a quiddity
   * @param {function} onUpdateAction - Callback triggered when the property is updated
   * @param {function|string} quiddityMatcher - Check if the update is related to a quiddityId
   * @param {function|string} propertyMatcher - Check if the update is related to a propertyName
   * @alias onUpdate
   * @async
   */
  onUpdated (onUpdateAction, quiddityMatcher, propertyMatcher) {
    this.socket.on('property_updated', (quidId, propertyName, value, propertyId) => {
      if (this.match(quidId, quiddityMatcher) && this.match(propertyName, propertyMatcher)) {
        onUpdateAction(quidId, propertyName, value, propertyId)
      }
    })
  }
}

export default PropertyAPI

import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { CancelButton } from '@components/common/Buttons'
import { Common, Feedback, Layout } from '@sat-mtl/ui-components'

import { AppStoresContext } from '@components/App'

const { Button } = Common
const { Modal } = Feedback
const { FlexRow } = Layout

/**
 * @constant {string} RENAME_NICKNAME_MODAL_ID - ID of the rename nickname modal
 * @memberof RenameNicknameModal
 */
export const RENAME_NICKNAME_MODAL_ID = 'RenameNicknameModal'

/**
 * Renders the footer of the RenameNicknameModal
 * @param {Function} props.onCancel - Function triggered when the user cancels the rename nickname action
 * @param {Function} props.onRename - Function triggered when the user confirms the rename nickname action
 * @memberof module:components/modals.RenameNicknameModal
 * @returns {external:react/Component} The footer of the Modal
 */
export const ModalFooter = ({ onRename, onCancel }) => {
  const { t } = useTranslation()

  return (
    <FlexRow justifyContent='space-between'>
      <CancelButton onCancel={onCancel} />
      <Button
        type='danger'
        className='RenameButton'
        shape='rectangle'
        onClick={onRename}
      >
        {t('Rename')}
      </Button>
    </FlexRow>
  )
}

/**
 * Scenic modal used to confirm renaming of a nickname
 * @selector `#RenameNicknameModal`
 * @memberof module:components/modals
 * @returns {external:mobx-react/ObserverComponent} The RenameNickname modal
 */
const RenameNicknameModal = observer(({ oldNickname, newNickname, onRename, onCancel }) => {
  const { t } = useTranslation()
  const { modalStore } = useContext(AppStoresContext)

  useEffect(() => {
    modalStore.addModal(RENAME_NICKNAME_MODAL_ID)
    modalStore.setActiveModal(RENAME_NICKNAME_MODAL_ID)

    return () => {
      modalStore.removeModal(RENAME_NICKNAME_MODAL_ID)
    }
  })

  return (
    <Modal
      id='RenameNicknameModal'
      visible={modalStore.activeModal === RENAME_NICKNAME_MODAL_ID}
      footer={(<ModalFooter onRename={onRename} onCancel={onCancel} />)}
    >
      {t("Rename '{{oldNickname}}' to '{{newNickname}}'?", { oldNickname, newNickname })}
    </Modal>
  )
})

export default RenameNicknameModal

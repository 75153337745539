import React, { useContext } from 'react'
import { observer } from 'mobx-react'

import { AppStoresContext } from '@components/App'

import Matrix from '@components/matrix/Matrix'
import QuiddityMenuPanel from '@components/panels/QuiddityMenuPanel'
// commented out until we reimplement useful filters
/* import FilterPanel from '@components/panels/FilterPanel' */
import SceneTabBar from '@components/bars/SceneTabBar'
import StatusBar from '@components/bars/StatusBar'
import FileControlPanel from '@components/panels/FileControlPanel'
import MatrixMenuPanel from '@components/panels/MatrixMenuPanel'
import SceneControlPanel from '@components/panels/SceneControlPanel'
import PreviewWrapper from '@components/matrix/PreviewWrapper'
// commented out until we make the order panel work again
/* import OrderPanel from '@components/panels/OrderPanel' */

import PropertyInspectorDrawer from '@components/drawers/PropertyInspectorDrawer'
import SipContactDrawer from '@components/drawers/SipContactDrawer'
import SipLoginDrawer from '@components/drawers/SipLoginDrawer'
import FileLoadingDrawer from '@components/drawers/FileLoadingDrawer'
import FileSavingDrawer from '@components/drawers/FileSavingDrawer'
import FileDeletionDrawer from '@components/drawers/FileDeletionDrawer'
import QuiddityCreationDrawer from '@components/drawers/QuiddityCreationDrawer'

import NotificationOverlay from '@components/panels/NotificationOverlay'

import '@styles/pages/MatrixPage.scss'

import { Layout, Context } from '@sat-mtl/ui-components'

const { FlexRow } = Layout
const { ThemeContext } = Context

/**
 * Renders all matrix panels
 * @selector `.PanelBar`
 * @returns {external:react/Component} The matrix page panels
 */
const PanelBar = observer(() => {
  // Commented out until we make the order panel work
  /* const { filterStore, sceneStore } = useContext(AppStoresContext) */
  const { sceneStore } = useContext(AppStoresContext)

  return (
    <div className='PanelBar'>
      {sceneStore.userScenes.size > 0 && <SceneControlPanel />}
      {/* Commented out until we make the order panel work
          {filterStore.categoryFilters.size === 0 && <OrderPanel />} */}
    </div>
  )
})

/**
 * Renders the menu bar
 * @selector `.MenuBar`
 * @todo Refactor FilterPanel and QuiddityMenuPanel as functionnal components
 * @returns {external:react/Component} The matrix page menu bar
 */
const MenuBar = observer(() => {
  // commented out until we reimplement useful filters
  /* const { filterStore } = useContext(AppStoresContext) */

  const $tools = [
    <QuiddityMenuPanel
      key='QuiddityMenuPanel'
    />
  ]

  // commented out until we reimplement useful filters
  /* if (filterStore.userCategories.size > 0) {
   *   $tools.push(
   *     <div key='Separator0' className='Separator' />,
   *     <FilterPanel key='FilterPanel' filterStore={filterStore} />
   *   )
   * } */

  return (
    <FlexRow className='MenuBar' columnGap={25}>
      {$tools}
    </FlexRow>
  )
})

/**
 * Renders the tab bar for the Matrix
 * @selector `.TabBar`
 * @todo Refactor SceneTabBar as functionnal components
 * @returns {external:react/Component} The matrix page Tab bar
 */
const TabBar = () => {
  const { modalStore, sceneStore } = useContext(AppStoresContext)

  return (
    <div className='TabBar'>
      <SceneTabBar
        sceneStore={sceneStore}
        modalStore={modalStore}
      />
    </div>
  )
}

/**
 * Renders the side bar
 * @selector `.SideBar`
 * @returns {external:react/Component} The matrix side bar
 */
const SideBar = () => {
  const { drawerStore, sipStore } = useContext(AppStoresContext)

  return (
    <div className='SideBar'>
      <MatrixMenuPanel
        className='top-control'
        drawerStore={drawerStore}
        sipStore={sipStore}
      />
      <FileControlPanel />
    </div>
  )
}

/**
 * Renders all drawers used in the Matrix
 * @selector `.DrawerOverlay`
 * @returns {external:react/Component} All wrapped drawers
 */
const DrawerOverlay = () => {
  return (
    <div className='DrawerOverlay'>
      <QuiddityCreationDrawer />
      <PropertyInspectorDrawer />
      <SipContactDrawer />
      <SipLoginDrawer />
      <FileLoadingDrawer />
      <FileSavingDrawer />
      <FileDeletionDrawer />
    </div>
  )
}

/**
 * Page that displays all the source and destination matrix
 * @memberof module:components/pages
 * @selector `#MatrixPage`
 * @returns {external:react/Component} The matrix page
 */
const MatrixPage = observer(() => {
  const theme = useContext(ThemeContext)

  return (
    <div id='MatrixPage' className={`page-${theme}`}>
      <PreviewWrapper />
      <TabBar />
      <MenuBar />
      <PanelBar />
      <Matrix />
      <StatusBar />
      <DrawerOverlay />
      <NotificationOverlay />
      <SideBar />
    </div>
  )
})

export default MatrixPage

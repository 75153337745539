import { observable, computed, action, reaction, makeObservable } from 'mobx'

import InitStateEnum from '@models/common/InitStateEnum'
import Scene, { DEFAULT_SCENE_ID } from '@models/userTree/Scene'

import ConfigStore from '@stores/common/ConfigStore'
import UserTreeStore from '@stores/userTree/UserTreeStore'
import { USER_TREE_NICKNAME } from '@models/quiddity/specialQuiddities'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the SceneStore
 * @memberof stores.SceneStore
 */
export const LOG = logger.child({ store: 'SceneStore' })

/**
 * @constant {string} USER_DATA_SCENE_REGEX - Matches all user data tree paths that represents a connection update
 * @memberof stores.SceneStore
 */
export const USER_DATA_SCENE_REGEX = /^.scenes.([\w/-]+)\.?(\w*)$/

/**
 * @classdesc Stores all scenes saved in the user data of the quiddity `userTree`
 *
 * The configuration (and its default assets) are always accurate
 * There is no need to fallback userTree values, they must be defined as default in the configuration
 * @extends stores.UserTreeStore
 * @memberof stores
 */
class SceneStore extends UserTreeStore {
  /** @property {stores.ConfigStore} configStore - Stores and manages the app's configuration */
  configStore = null

  /** @property {string} - Property used to temporary set the next scene when the user switches scenes */
  futureSceneId = null

  /** @property {Map<string, models.Scene>} - All saved scenes in the userTree */
  userScenes = new Map()

  /** @property {string} - ID of the selected scene */
  selectedSceneId = null

  /** @property {models.Scene} - The active scene */
  get activeScene () {
    return Array.from(this.userScenes.values())
      .find(scene => scene.isActive)
  }

  /** @property {models.Scene} - The default scene. It should always be loaded with the DEFAULT_SCENE_ID */
  get defaultScene () {
    return Array.from(this.userScenes.values())
      .find(scene => scene.id === DEFAULT_SCENE_ID)
  }

  /** @property {models.Scene} selectedScene - The selected scene */
  get selectedScene () {
    let selected = this.activeScene

    if (this.selectedSceneId) {
      selected = Array.from(this.userScenes.values())
        .find(scene => scene.id === this.selectedSceneId)
    } else if (!selected && !this.selectedSceneId) {
      selected = this.defaultScene
    }

    return selected
  }

  /** @property {boolean} isSelectedSceneActive - Flags true if the selected scene is active */
  get isSelectedSceneActive () {
    const { selectedScene, activeScene } = this
    let isSelectedSceneActive = false

    if (selectedScene && activeScene) {
      isSelectedSceneActive = selectedScene.id === activeScene.id
    }

    return isSelectedSceneActive
  }

  /** @property {?models.Scene} - Temporary scene that is used to anticipate a switch to a future scene */
  get futureScene () {
    let scene = null

    if (this.futureSceneId && this.userScenes.has(this.futureSceneId)) {
      scene = this.userScenes.get(this.futureSceneId)
    }

    return scene
  }

  /**
   * Instantiates a new SceneStore
   * @param {stores.SocketStore} socketStore - Socket manager
   * @param {stores.ConfigStore} configStore - Configuration manager
   * @param {stores.QuiddityStore} quiddityStore - Quiddity manager
   */
  constructor (socketStore, configStore, quiddityStore) {
    super(socketStore, quiddityStore, configStore)

    makeObservable(this, {
      futureSceneId: observable,
      userScenes: observable,
      selectedSceneId: observable,
      activeScene: computed,
      defaultScene: computed,
      selectedScene: computed,
      isSelectedSceneActive: computed,
      setSelectedScene: action,
      addUserScene: action,
      updateUserScene: action,
      removeUserScene: action,
      clear: action
    })

    if (configStore instanceof ConfigStore) {
      this.configStore = configStore
    } else {
      throw new TypeError('SceneStore requires ConfigStore')
    }

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )

    reaction(
      () => this.quiddityStore.quiddityIds,
      () => this.handleQuiddityChanges()
    )
  }

  /**
   * Handles every changes in quiddities from the QuiddityStore
   * It will check if the `userTree` is created and initialize itself
   * @param {string[]} quiddityIds - All stored quiddity IDs
   */
  handleQuiddityChanges () {
    const isUserTreeCreated = this.quiddityStore.quiddityByNames.has(USER_TREE_NICKNAME)
    if (isUserTreeCreated && this.isNotInitialized()) {
      this.initialize()
    } else if (!isUserTreeCreated && this.isInitialized()) {
      this.clear()
    }
  }

  /**
   * Initializes store by fetching current user data
   * @returns {boolean} Flags true if store is well initialized
   * @async
   */
  async initialize () {
    const { NOT_INITIALIZED, INITIALIZING, INITIALIZED } = InitStateEnum

    if (this.isInitialized()) {
      LOG.warn({
        msg: 'SceneStore is already initialized. Skipping.'
      })

      return false
    }

    this.setInitState(INITIALIZING)

    const initialData = await this.fetchUserTree()
    const isPopulated = this.populateScenesFromUserTree(initialData)

    try {
      if (!isPopulated) {
        await this.fallbackUserScenesInitialization()

        LOG.info({
          msg: 'Successfully fallen back default user scene'
        })
      }

      this.setInitState(INITIALIZED)

      LOG.info({
        msg: 'Scene store is initialized'
      })
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'Initialization failure',
        message: 'Failed to initialize scenes',
        error: error.message
      })

      this.setInitState(NOT_INITIALIZED)
    }

    return this.isInitialized()
  }

  /**
   * Creates the default user Scenes
   * @async
   */
  async fallbackUserScenesInitialization () {
    const { configStore: { defaultUserTree } } = this
    const defaultJson = defaultUserTree.scenes
    const defaultScene = Scene.fromJSON(defaultJson[DEFAULT_SCENE_ID])

    await this.applyUserTreeCreation('scenes', defaultJson)

    this.addUserScene(defaultScene)
  }

  /**
   * Populates scenes from user data
   * @param {Object} [json={}] - User data from userTree
   * @returns {boolean} Flags true if scenes were correctly populated
   */
  populateScenesFromUserTree (json = {}) {
    let isScenesPopulated = false

    try {
      if (Object.keys(json).length <= 0) {
        throw new Error('User data is empty')
      } else if (json.scenes && Object.keys(json.scenes).length <= 0) {
        throw new Error('No scenes exist in user data')
      } else {
        Object.values(json.scenes).map(json => Scene.fromJSON(json))
          .forEach(scene => this.addUserScene(scene), this)

        isScenesPopulated = true
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to populate scenes',
        error: error.message
      })
    }

    return isScenesPopulated
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()

    if (socket && this.socketStore.hasActiveAPIs) {
      const { userTreeAPI } = this.socketStore.APIs
      const { quiddityStore: { nicknameStore } } = this

      userTreeAPI.onGrafted(
        (quidId, path, data) => this.handleGraftedUserTreeScenes(quidId, path, data),
        quidId => nicknameStore.nicknames.get(quidId) === USER_TREE_NICKNAME,
        path => Array.isArray(path.match(USER_DATA_SCENE_REGEX))
      )

      userTreeAPI.onPruned(
        (quidId, path) => this.handlePrunedUserTreeScenes(path),
        quidId => nicknameStore.nicknames.get(quidId) === USER_TREE_NICKNAME,
        path => Array.isArray(path.match(USER_DATA_SCENE_REGEX))
      )
    }
  }

  /** Handles the loading of a new session by cleaning and reinitializing the scenes */
  async handleNewSession () {
    await this.clear()
    await this.initialize()

    this.setSelectedScene(this.activeScene.id)

    LOG.info({
      msg: 'Successfully loaded user scenes for new session'
    })
  }

  /**
   * Updates user scenes when the user data is grafted
   * @param {string} quiddityId - ID of the grafted quiddity
   * @param {string} path - Tree path of the grafted user data
   * @param {Object} data - Grafted data object
   */
  handleGraftedUserTreeScenes (quiddityId, path, data) {
    try {
      const [, sceneId, property] = path.match(USER_DATA_SCENE_REGEX)

      if (!property) {
        const newScene = Scene.fromJSON(data)

        this.addUserScene(newScene)

        if (this.selectedSceneId !== newScene.id) {
          this.setSelectedScene(newScene.id)
        }
      } else if (this.userScenes.has(sceneId)) {
        if (path.endsWith('active')) {
          this.updateActiveScene(sceneId, property, data)
        } else {
          this.updateUserScene(sceneId, property, data)
        }
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to add user data scene from grafted tree',
        error: error.message,
        quiddity: quiddityId,
        branch: path,
        tree: data
      })
    }
  }

  /**
   * Updates and parses a property for the active scene
   * @param {string} sceneId - The ID of the scene to update
   * @param {string} property - Name of the updated property
   * @param {(string|boolean|number|Object)} value - Value of the property
   */
  updateActiveScene (sceneId, property, data) {
    let isActive = data

    if (typeof data === 'string') {
      isActive = data === 'true'
    }

    this.updateUserScene(sceneId, property, isActive)
  }

  /**
   * Updates user scenes when the user data is pruned
   * @param {string} path - Tree path of the pruned user data
   */
  handlePrunedUserTreeScenes (path) {
    const [, sceneId, isProperty] = path.match(USER_DATA_SCENE_REGEX)

    if (!isProperty) {
      this.removeUserScene(sceneId)

      if (sceneId === this.selectedSceneId) {
        this.setSelectedScene(DEFAULT_SCENE_ID)
      }
    }
  }

  /**
   * Generates a new scene ID
   * @returns {number} - A numeric ID
   */
  computeSceneIndex () {
    if (this.userScenes.size === 0) return 0

    const indexes = Array.from(this.userScenes.values())
      .map(scene => scene.index)

    return Math.max(...indexes) + 1
  }

  /**
   * Generate a new scene from the stored scenes
   * @returns {models.Scene} - The new scene model
   */
  makeSceneModel () {
    const newScene = Scene.fromIndex(this.computeSceneIndex())

    if (this.selectedScene) {
      newScene.connections = [...this.selectedScene.connections]
    }

    return newScene
  }

  /**
   * Creates a scene in the userTree
   * @async
   */
  async applySceneCreation () {
    const newScene = this.makeSceneModel()

    try {
      await this.applyUserTreeCreation(newScene.branch, newScene.toJSON())

      LOG.debug({
        msg: 'Successfully created new scene',
        scene: newScene.id
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to create new scene',
        scene: newScene.id,
        error: error.message
      })
    }
  }

  /**
   * Removes a scene in the userTree
   * @param {string} sceneId - ID of the scene to remove
   * @async
   */
  async applySceneRemoval (sceneId) {
    const scene = this.userScenes.get(sceneId)

    try {
      await this.applyUserTreeRemoval(scene.branch)

      LOG.debug({
        msg: 'Successfully removed scene',
        scene: scene.id
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to remove scene',
        scene: scene.id,
        error: error.message
      })
    }
  }

  /**
   * Renames a scene
   * @param {string} sceneId - Scene's ID to rename
   * @param {string} name - New name of the scene
   * @async
   */
  async applySceneName (sceneId, name) {
    const renamedScene = this.userScenes.get(sceneId)

    try {
      await this.applyUserTreeCreation(`${renamedScene.branch}.name`, name)

      LOG.debug({
        msg: 'Successfully renamed scene',
        scene: sceneId,
        newName: name
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to rename scene',
        scene: sceneId,
        newName: name
      })
    }
  }

  /**
   * Toggles an active scene
   * @param {string} [sceneId=DEFAULT_SCENE_ID] - ID of the scene to toggle
   * @param {boolean} [toggle=true] - Flags if the scene is activated or not
   * @async
   */
  async applyActiveSceneSwitch (sceneId = DEFAULT_SCENE_ID, toggle = true) {
    const switchedScene = this.userScenes.get(sceneId)
    this.futureSceneId = switchedScene.id

    try {
      if (toggle && this.activeScene && this.activeScene.id !== switchedScene.id) {
        await this.applyUserTreeCreation(`${this.activeScene.branch}.active`, false)
      }

      await this.applyUserTreeCreation(`${switchedScene.branch}.active`, toggle)

      LOG.debug({
        msg: 'Successfully switched the active scene',
        scene: sceneId,
        active: toggle
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to switch the active scene',
        scene: sceneId,
        active: toggle
      })
    }

    this.futureSceneId = null
  }

  /**
   * Selects a scene
   * @param {string} [sceneId=DEFAULT_SCENE_ID] - ID of the scene to select
   */
  setSelectedScene (sceneId = DEFAULT_SCENE_ID) {
    if (this.userScenes.has(sceneId)) {
      this.selectedSceneId = sceneId

      LOG.debug({
        msg: 'Successfully changed selected scene',
        scene: sceneId
      })
    }
  }

  /**
   * Adds a new scene in the userTree scenes Map
   * @param {models.Scene} scene - The new scene to add
   */
  addUserScene (scene) {
    if (!this.userScenes.has(scene.id)) {
      this.userScenes.set(scene.id, scene)

      LOG.debug({
        msg: 'Added new scene to userTree',
        scene: scene.id
      })
    }
  }

  /**
   * Updates a scene in the userTree scenes Map
   * @todo Functions starting with 'update' should not be MobX actions and should not manipulate data structures directly
   * @param {string} sceneId - The  ID of the scene to update
   * @param {string} property - Name of the updated property
   * @param {(string|boolean|number|Object)} value - Value of the property
   */
  updateUserScene (sceneId, property, value) {
    if (this.userScenes.has(sceneId)) {
      const updatedScene = Scene.fromJSON({
        ...this.userScenes.get(sceneId).toJSON(),
        [property]: value
      })

      this.userScenes.set(sceneId, updatedScene)

      LOG.debug({
        msg: 'Updated scene from userTree',
        scene: sceneId,
        updatedProperty: property,
        updatedValue: value
      })
    }
  }

  /**
   * Deletes a scene from the userTree scenes Map
   * @param {string} sceneId - ID of the scene to delete
   */
  removeUserScene (sceneId) {
    this.userScenes.delete(sceneId)

    LOG.debug({
      msg: 'Deleted scene from userTree',
      scene: sceneId
    })
  }

  /** Cleans up SceneStore */
  clear () {
    const { NOT_INITIALIZED } = InitStateEnum
    this.setInitState(NOT_INITIALIZED)

    this.userScenes.clear()

    LOG.debug({
      msg: 'Successfully cleared all user scenes'
    })
  }
}

export default SceneStore

import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { AppStoresContext } from '@components/App'
import { Feedback, Context, Layout } from '@sat-mtl/ui-components'

import '@styles/tooltips/ShmdataInformationTooltip.scss'

const { Tooltip } = Feedback
const { ThemeContext } = Context
const { FlexRow } = Layout

/**
 * Renders a capability entry
 * @param {string} capsKey - The key of the capability
 * @param {string|number} value - The value of the capability
 * @returns {external:react/Component} A capability rendered as a HTML definition entry
 */
function Capability ({ capsKey, value }) {
  const { t } = useTranslation()

  return (
    <FlexRow key={capsKey} className='capability'>
      <dt className={capsKey}>
        {t(capsKey)}
      </dt>
      <dd className={capsKey}>
        {value}
      </dd>
    </FlexRow>
  )
}

/**
 * Renders a capability group
 * @selector `.CapabilityGroup`
 * @param {string} groupTitle - The title of the capability group
 * @param {Map<string, string>} groupValue - The capabilities of the group
 * @returns {external:react/Component} A capability rendered as a HTML definition entry
 */
function CapabilityGroup ({ title, groupValue }) {
  const { capsStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const $capabilities = []

  for (const [key, value] of groupValue) {
    if (!capsStore.isHidden(key)) {
      $capabilities.push(
        <Capability
          key={key}
          capsKey={key}
          value={value}
        />
      )
    }
  }

  return (
    <li className='CapabilityGroup'>
      {title && (
        <h4 className='CapabilityGroupTitle'>
          {t(title)}
        </h4>
      )}
      <dl>
        {$capabilities}
      </dl>
    </li>
  )
}

/**
 * Renders all capabilities in a HTML definition list
 * @selector `.Capabilities`
 * @param {models/shmdata.Shmdata} shmdata - The shmdata that will render capabilities
 * @returns {external:react/Component} A definition list of all capabilities
 */
const Capabilities = observer(({ shmdata }) => {
  const { capsStore, statStore, shmdataStore } = useContext(AppStoresContext)
  const quidId = shmdataStore?.writingQuiddities?.get(shmdata.path)
  const capability = capsStore.caps.get(quidId)?.capabilities || shmdata.capabilities

  const $capabilities = [
    <CapabilityGroup
      key='primaryCaps'
      groupValue={new Map([
        ['media-type', shmdata.mediaType],
        ['bitrate', statStore.bitrates.get(shmdata.path)]
      ])}
    />
  ]

  for (const [key, value] of capability.groups) {
    $capabilities.push(
      <CapabilityGroup
        key={key}
        title={key}
        groupValue={value}
      />
    )
  }

  return (
    <dl className='Capabilities'>
      {$capabilities}
    </dl>
  )
})

/**
 * Renders the tooltip
 * @selector `.ShmdataInformationTooltip[data-shmdata="shmdata id"][data-media="media type"]`
 * @param {models/shmdata.Shmdata} shmdata - The shmdata that will render capabilities
 * @param {external:react/Component} children - The children component of the tooltip
 * @returns {external:react/Component} A tooltip with the shmdata information
 */
const ShmdataInformationTooltip = observer(({ shmdata, children }) => {
  const theme = useContext(ThemeContext)

  const cl = classNames(
    'ShmdataInformationTooltip',
    `tooltip-shmdata-${theme}`
  )
  return (
    <div
      className={cl}
      data-shmdata={shmdata.id}
      data-media={shmdata.mediaType}
    >
      <Tooltip side='right' content={<Capabilities shmdata={shmdata} />}>
        {children}
      </Tooltip>
    </div>
  )
})

export default ShmdataInformationTooltip

export {
  Capability,
  Capabilities,
  CapabilityGroup
}

import React, { useState, useContext, useEffect } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import Mousetrap from 'mousetrap'
import { useTranslation } from 'react-i18next'

import { isStringEmpty } from '@utils/stringTools'
import StatusEnum from '@models/common/StatusEnum'

import { AppStoresContext } from '@components/App'
import { DRAWER_WIDTH } from '@stores/common/DrawerStore'
import HelpWrapper from '@components/wrappers/HelpWrapper'

import {
  Feedback,
  Layout,
  Common,
  Inputs
} from '@sat-mtl/ui-components'

import '@styles/drawers/FileDrawers.scss'

const { FlexBox } = Layout
const { Drawer } = Feedback
const { Button } = Common
const { Field, InputText } = Inputs

/**
 * A button that creates a new quiddity
 * @memberof module:components/drawers.QuiddityCreationDrawer
 * @param {string[]} classIds - All quiddity IDs
 * @param {string} kindId - kind ID of the quiddity
 * @param {function} onCreate - Function triggered when the user creates the quiddity
 * @returns {external:ui-components/Common/Button} A button
 */
function CreateQuiddityButton ({ kindId, quidName, onCreate, disabled }) {
  const { kindStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  let help = t('No resource found')

  if (kindId) {
    help = t('Create a new "{{kindId}}"', { kindId })
  } else if (kindId && quidName) {
    help = t('Create a new "{{kindId}}" named "{{quidName}}"', { kindId, quidName })
  }

  let status = 'primary'

  if (!kindStore.kindIds.includes(kindId)) {
    status = StatusEnum.DANGER
  }

  return (
    <HelpWrapper message={help}>
      <Button onClick={onCreate} disabled={disabled} type={status} shape='rectangle'>
        {t('Create')}
      </Button>
    </HelpWrapper>
  )
}

/**
 * An input to set a quiddity attribute
 * @selector `.QuiddityInput`
 * @memberof module:components/drawers.QuiddityCreationDrawer
 * @property {string} className - Extra class name of the input
 * @property {string} inputValue - The attribute value
 * @property {string} status - The status of the field
 * @property {string} title - Title of the input
 * @property {string} warning - Warning message to display
 * @property {string} help - Help message to display
 * @property {string} placeholder - Placeholder of the input
 * @property {function} onChange - Function triggered when the input is changed
 * @property {function} onCheck - Function triggered when the input is checked
 * @returns {external:ui-components/Inputs/Field} A field component
 */
function QuiddityInput ({
  className,
  inputValue,
  status,
  title,
  warning,
  help,
  placeholder,
  onChange,
  onCheck
}) {
  return (
    <HelpWrapper message={help}>
      <Field
        status={status}
        title={title}
        description={status !== StatusEnum.FOCUS ? warning : help}
      >
        <InputText
          className={classNames('QuiddityInput', className)}
          placeholder={placeholder}
          value={inputValue}
          onBlur={() => onCheck(inputValue)}
          onChange={(e) => {
            const inputted = e?.target?.value || ''

            onCheck(inputted)
            onChange(inputted)
          }}
          onPressEnter={() => onCheck(inputValue)}
        />
      </Field>
    </HelpWrapper>
  )
}

/**
 * An input to set the quiddity kind
 * @selector `.QuiddityKindInput`
 * @memberof module:components/drawers.QuiddityCreationDrawer
 * @param {string} kind - Kind of the quiddity
 * @param {function} onChange - Function triggered when the user changes the quiddity kind
 * @returns {external:react/Component} An input
 */
function QuiddityKindInput ({ kind, onChange }) {
  const { t } = useTranslation()
  const { kindStore } = useContext(AppStoresContext)
  const [status, setStatus] = useState(StatusEnum.FOCUS)

  return (
    <QuiddityInput
      className='QuiddityKindInput'
      inputValue={kind}
      status={status}
      title={t('Resource class')}
      help={t('Type in the class name of the resource to create')}
      warning={t("This class doesn't exist")}
      placeholder='audioTestInput'
      onChange={onChange}
      onCheck={inputtedKind => {
        if (!isStringEmpty(inputtedKind) && !kindStore.kindIds.includes(inputtedKind)) {
          setStatus(StatusEnum.DANGER)
        } else {
          setStatus(StatusEnum.FOCUS)
        }
      }}
    />
  )
}

/**
 * An input to set the quiddity nickname
 * @memberof module:components/drawers.QuiddityCreationDrawer
 * @selector `.QuiddityNicknameInput`
 * @param {string} nickname - Nickname of the quiddity
 * @param {function} onChange - Function triggered when the user changes the name
 * @returns {external:react/Component} An input
 */
function QuiddityNicknameInput ({ nickname, onChange }) {
  const { t } = useTranslation()
  const [status, setStatus] = useState(StatusEnum.FOCUS)

  return (
    <QuiddityInput
      className='QuiddityNicknameInput'
      inputValue={nickname}
      status={status}
      title={t('Resource name')}
      help={t('Type in the desired name of the resource to create')}
      warning={t('The name will be set to a default value')}
      placeholder={t('My Resource')}
      onChange={onChange}
      onCheck={inputtedNickname => {
        if (isStringEmpty(inputtedNickname)) {
          setStatus(StatusEnum.BUSY)
        } else {
          setStatus(StatusEnum.FOCUS)
        }
      }}
    />
  )
}

/**
 * @constant {string} QUIDDITY_CREATION_DRAWER_ID - ID of the creation quiddity drawer
 * @memberof module:components/drawers.QuiddityCreationDrawer
 */
const QUIDDITY_CREATION_DRAWER_ID = 'QuiddityCreationDrawer'

/**
 * Drawer that creates a quiddity from a class ID
 * @memberof module:components/drawers
 * @returns {external:ui-components/Feedback/Drawer} A drawer component
 */
const QuiddityCreationDrawer = observer(() => {
  const { t } = useTranslation()
  const { drawerStore, quiddityStore, kindStore } = useContext(AppStoresContext)
  const [userKind, setKind] = useState('')
  const [userNickname, setNickname] = useState('')

  useEffect(() => {
    drawerStore.addDrawer(QUIDDITY_CREATION_DRAWER_ID)

    Mousetrap.bind('ctrl+a', (event) => {
      event.preventDefault && event.preventDefault()
      drawerStore.toggleActiveDrawer(QUIDDITY_CREATION_DRAWER_ID)
    })

    return () => {
      drawerStore.removeDrawer(QUIDDITY_CREATION_DRAWER_ID)
      Mousetrap.unbind('ctrl+a')
    }
  })

  return (
    <Drawer
      id={QUIDDITY_CREATION_DRAWER_ID}
      width={DRAWER_WIDTH}
      visible={drawerStore.activeDrawer === QUIDDITY_CREATION_DRAWER_ID}
      onBackdropClick={() => drawerStore.clearActiveDrawer()}
      header={
        <>
          <h2>{t('Create a new resource')}</h2>
          <p>{t('Search and create a new resource')}</p>
        </>
      }
      footer={
        <FlexBox flexDirection='row-reverse' alignItems='center'>
          <CreateQuiddityButton
            disabled={!userKind || !kindStore.kindIds.includes(userKind)}
            kindId={userKind}
            onCreate={() => {
              const creationNickname = isStringEmpty(userNickname) ? null : userNickname
              quiddityStore.applyQuiddityCreation(userKind, creationNickname)
            }}
          />
        </FlexBox>
      }
    >
      <QuiddityKindInput
        kind={userKind}
        onChange={(inputtedKind) => setKind(inputtedKind)}
      />
      <QuiddityNicknameInput
        nickname={userNickname}
        onChange={(inputtedNickname) => setNickname(inputtedNickname)}
      />
    </Drawer>
  )
})

export default QuiddityCreationDrawer

export {
  QUIDDITY_CREATION_DRAWER_ID,
  CreateQuiddityButton,
  QuiddityInput,
  QuiddityKindInput,
  QuiddityNicknameInput
}

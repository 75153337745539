import { observable, computed, action, reaction, makeObservable } from 'mobx'

import InitStateEnum from '@models/common/InitStateEnum'
import Connection from '@models/userTree/Connection'

import ConfigStore from '@stores/common/ConfigStore'
import MaxLimitStore from '@stores/shmdata/MaxLimitStore'
import ShmdataStore from '@stores/shmdata/ShmdataStore'
import LockStore from '@stores/matrix/LockStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import ShmdataRoleEnum from '@models/shmdata/ShmdataRoleEnum'

import { RequirementError } from '@utils/errors'
import SceneStore from '@stores/userTree/SceneStore'
import UserTreeStore from '@stores/userTree/UserTreeStore'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the ConnectionStore
 * @memberof stores.ConnectionStore
 */
export const LOG = logger.child({ store: 'ConnectionStore' })

/**
 * @constant {string} USER_DATA_CONNECTION_REGEX - Matches all user data tree paths that represents a connection update
 * @memberof stores.ConnectionStore
 */
export const USER_DATA_CONNECTION_REGEX = /^.connections.([\w:@\-/]+)\.?([\w]+)?$/

/**
 * @classdesc Stores all connections saved in the user data of the quiddity `userTree`
 * @extends stores.UserTreeStore
 * @memberof stores
 */
class ConnectionStore extends UserTreeStore {
  /** @property {api.QuiddityAPI} quiddityAPI - API used to manage quiddities */
  quiddityAPI = null

  /** @property {Map<string, module:models/userTree.Connection>} userConnections - All saved connections in the userTree */
  userConnections = new Map()

  /** @property {Set<string>} sceneConnections - All connection IDs that depends on the scenes */
  get sceneConnections () {
    const { lockStore, userConnections } = this
    const sceneConnections = new Set()

    for (const [id, connection] of userConnections) {
      if (!lockStore.lockableQuiddities.has(connection.destinationId)) {
        sceneConnections.add(id)
      }
    }

    return sceneConnections
  }

  /** @property {Set<string>} armedConnections - All connections IDs of the selected scene */
  get armedConnections () {
    const { sceneStore, sceneConnections } = this
    const armedConnections = new Set()

    const selectedScene = sceneStore.selectedScene
    const selectedSceneConnections = selectedScene?.connections || []

    for (const id of sceneConnections) {
      if (selectedSceneConnections.includes(id)) {
        armedConnections.add(id)
      }
    }

    return armedConnections
  }

  /** @property {Set<string>} armedConnections - All connections IDs of the active scene */
  get activeConnections () {
    const { sceneStore, sceneConnections } = this
    const activeConnections = new Set()

    const activeScene = sceneStore.activeScene
    const activeSceneConnections = activeScene?.connections || []

    for (const id of sceneConnections) {
      if (activeSceneConnections.includes(id)) {
        activeConnections.add(id)
      }
    }

    return activeConnections
  }

  /** @property {Set<string>} connectedDestinations - All destination quiddity IDs that are connected by the user */
  get connectedDestinations () {
    const destinations = new Set()

    for (const connection of this.userConnections.values()) {
      destinations.add(connection.destinationId)
    }

    return destinations
  }

  /** @property {Set<string>} connectedSources - All source quiddity IDs that are connected by the user */
  get connectedSources () {
    const sources = new Set()

    for (const connection of this.userConnections.values()) {
      sources.add(connection.sourceId)
    }

    return sources
  }

  /** @property {Map<string, Set<string>>} connectedScenes - All connected scenes IDs hashed by connection IDs */
  get connectedScenes () {
    const connectedScenes = new Map()

    for (const [, scene] of this.sceneStore.userScenes) {
      for (const connectionId of scene.connections) {
        if (connectedScenes.has(connectionId)) {
          connectedScenes.get(connectionId).add(scene.id)
        } else {
          connectedScenes.set(connectionId, new Set([scene.id]))
        }
      }
    }

    return connectedScenes
  }

  /**
   * @property {Map<string, module:models/shmdata.Shmdata>} connectedShmdatas - All connected shmdatas hashed by connection IDs
   * The *connected shmdata* are connected, it means they are written and read by a source quiddity and a destination quiddity.
   * They must be associated with a connection model! If not, they can be produced by special quiddities that are not in the matrix.
   */
  get connectedShmdatas () {
    const { quiddityStore } = this
    const connectedShmdatas = new Map()

    for (const [connectionId, connection] of this.userConnections) {
      const { sourceId, destinationId } = connection
      const sourceQuid = quiddityStore.quiddities.get(sourceId)
      const destQuid = quiddityStore.quiddities.get(destinationId)
      const writerPaths = sourceQuid?.infoTree?.shmdata ? sourceQuid?.infoTree?.shmdata[ShmdataRoleEnum.WRITER] : new Set()
      const followingShmdatas = destQuid?.infoTree?.shmdata ? destQuid?.infoTree?.shmdata[ShmdataRoleEnum.READER] : new Set()

      for (const shmdatapath in followingShmdatas) {
        if (!Object.hasOwn(followingShmdatas, shmdatapath)) {
          continue
        }
        if (writerPaths[shmdatapath]) {
          connectedShmdatas.set(connectionId, shmdatapath)
        }
      }
    }

    return connectedShmdatas
  }

  /**
   * Instantiates a new ConnectionStore
   *
   * The ConnectionStore is used to synchronize the Scenic App cache
   * with the `connection` object for the userTree in the `userTree` quiddity.
   *
   * With the SceneStore it represents one of the main feature of Scenic : the
   * capacity to add scenes in order to stage the current telepresence setup.
   *
   * The ConnectionStore is synchronized with the `userTree` and updates all
   * its `userTree` tree any time a connection is updated. It only trusts the
   * Switcher signals : that's why it updates its cache only when Switcher notifies
   * a change.
   *
   * @param {module:stores/common.SocketStore} socketStore - Socket manager
   * @param {module:stores/common.ConfigStore} configStore - Configuration manager
   * @param {module:stores/quiddity.QuiddityStore} quiddityStore - Quiddity manager
   * @param {module:stores/shmdata.ShmdataStore} shmdataStore - Shmdata manager
   * @param {module:stores/userTree.SceneStore} sceneStore - UserTree scenes manager
   * @param {module:stores/shmdata.MaxLimitStore} maxLimitStore - MaxLimit manager
   * @param {module:stores/matrix.LockStore} lockStore - Lock manager
   * @constructor
   */
  constructor (socketStore, configStore, quiddityStore, shmdataStore, sceneStore, maxLimitStore, lockStore) {
    super(socketStore, quiddityStore, configStore)

    makeObservable(this, {
      userConnections: observable,
      sceneConnections: computed,
      armedConnections: computed,
      activeConnections: computed,
      connectedDestinations: computed,
      connectedSources: computed,
      connectedScenes: computed,
      connectedShmdatas: computed,
      addUserConnection: action,
      removeUserConnection: action,
      clear: action
    })

    if (configStore instanceof ConfigStore) {
      this.configStore = configStore
    } else {
      throw new RequirementError(this.constructor.name, 'ConfigStore')
    }

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new RequirementError(this.constructor.name, 'QuiddityStore')
    }

    if (shmdataStore instanceof ShmdataStore) {
      this.shmdataStore = shmdataStore
    } else {
      throw new TypeError('ConnectionStore requires a ShmdataStore')
    }

    if (sceneStore instanceof SceneStore) {
      this.sceneStore = sceneStore
    } else {
      throw new TypeError('ConnectionStore requires a SceneStore')
    }

    if (maxLimitStore instanceof MaxLimitStore) {
      this.maxLimitStore = maxLimitStore
    } else {
      throw new TypeError('ConnectionStore requires a MaxLimitStore')
    }

    if (lockStore instanceof LockStore) {
      this.lockStore = lockStore
    } else {
      throw new TypeError('ConnectionStore requires a LockStore')
    }

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )

    reaction(
      () => this.sceneStore.initState,
      state => this.handleSceneStoreInitialization(state)
    )

    reaction(
      () => this.sceneStore.activeScene?.id,
      activeSceneId => this.handleActiveScene(activeSceneId, this.sceneStore.futureSceneId)
    )

    reaction(
      () => this.quiddityStore.quiddityIds,
      () => this.handleQuiddityChanges()
    )
  }

  /**
   * Initializes store by fetching current user data
   * @returns {boolean} Flags true if store is well initialized
   * @async
   *
   * @mermaid
   *  sequenceDiagram
   *      participant U as User
   *      participant C as ConnectionStore
   *      participant Sw as Switcher
   *      U->>C: initialize
   *      activate C
   *      C-xSw: fetchUserTree
   *      Sw--xC: userTree tree of connections
   */
  async initialize () {
    const { NOT_INITIALIZED, INITIALIZING, INITIALIZED } = InitStateEnum

    if (this.isInitialized()) {
      LOG.warn({
        msg: 'ConnectionStore is already initialized. Skipping.'
      })

      return false
    }

    this.setInitState(INITIALIZING)

    const initialData = await this.fetchUserTree()
    const isPopulated = this.populateConnectionsFromUserTree(initialData)

    try {
      if (!isPopulated) {
        await this.fallbackUserConnectionsInitialization()

        LOG.info({
          msg: 'Successfully fallen back default user connections'
        })
      }

      LOG.info({
        msg: 'Connection store is initialized'
      })

      this.setInitState(INITIALIZED)
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'Initialization failure',
        message: 'Failed to initialize connections',
        error: error.message
      })

      this.setInitState(NOT_INITIALIZED)
    }

    return this.isInitialized()
  }

  /**
   * Creates the default quiddity connections in the userTree
   * @async
   */
  async fallbackUserConnectionsInitialization () {
    const { configStore: { defaultUserTree } } = this
    const defaultJson = defaultUserTree.connections
    await this.applyUserTreeCreation('connections', defaultJson)
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()

    if (socket && this.socketStore.hasActiveAPIs) {
      const { userTreeAPI } = this.socketStore.APIs

      userTreeAPI.onGrafted(
        (quidId, path, data) => this.handleGraftedUserTreeConnections(quidId, path, data),
        quidId => this.isUserTreeQuiddity(quidId),
        path => Array.isArray(path.match(USER_DATA_CONNECTION_REGEX))
      )

      userTreeAPI.onPruned(
        (quidId, path) => this.handlePrunedUserTreeConnections(quidId, path),
        quidId => this.isUserTreeQuiddity(quidId),
        path => Array.isArray(path.match(USER_DATA_CONNECTION_REGEX))
      )
    }
  }

  /**
   * Initializes or clears the connections when the sceneStore's initialization state changes
   * @param {models.InitStateEnum} sceneStoreState - State of the sceneStore's initialization
   * @async
   */
  async handleSceneStoreInitialization (sceneStoreState) {
    const { INITIALIZED, NOT_INITIALIZED } = InitStateEnum

    if (sceneStoreState === INITIALIZED) {
      await this.initialize()
    } else if (sceneStoreState === NOT_INITIALIZED) {
      this.clear()
    }
  }

  /**
   * Handles loading of a new Session file
   * @param {string} filename - Loaded file's name
   * @async
   */
  async handleLoadedFile (filename) {
    await this.clear()
    await this.initialize()

    await this.fallbackSessionLoading()

    LOG.info({
      msg: 'Successfully loaded file for user connections',
      file: filename
    })
  }

  /**
   * @summary Handle the toggle of the active scene
   * @description
   * It monitors and checks all statements triggered by the switch of the active scene:
   * 1. The user wants to desactivate the current active scene
   *    + The handler will be called once with an `undefined` active scene
   * 2. The user wants to change the active scene, he switches from the *scene 1* to the *scene 2*
   *    + The handler is firstly called with an `undefined` active scene but the future scene ID is `scene 2`
   *    + Secondly, the handler is called with the active scene `scene 2` only.
   * The handler mainly prevents the application of all the active scene connections twice when
   * the user switches between two active scenes.
   * @param {?string} activeSceneId - ID of the active scene, undefined if no scene is active
   * @param {?string} futureSceneId - ID of the future active scene, it is selected by the user but it was not saved in the user tree
   */
  handleActiveScene (activeSceneId, futureSceneId) {
    if (!activeSceneId && futureSceneId) {
      LOG.debug({
        msg: 'Toggle statement is ignored',
        activeScene: activeSceneId,
        futureScene: futureSceneId
      })
    } else {
      this.applyActiveSceneConnections()
    }
  }

  /**
   * @summary Apply all connections and disconnections for the active scene
   * @async
   * @description
   * All following operations must be performed asynchronously in order to avoid conflicts:
   * + Every connections that are not related to the active scene will be disconnected
   * + Every connections of the active scene that are not connected will be connected
   * + Every connections of the active scene that are still connected will not be changed
   * Eventually, all disconnections are performed before all connections.
   */
  async applyActiveSceneConnections () {
    const { lockStore, userConnections, connectedShmdatas, activeConnections } = this

    for (const [id] of connectedShmdatas) {
      const connection = userConnections.get(id)

      if (!activeConnections.has(id) && !lockStore.isLockableConnection(connection)) {
        await this.quiddityStore.applyQuiddityDisconnection(connection.destinationId, connection.sfId)
      }
    }

    for (const id of activeConnections) {
      const connection = userConnections.get(id)
      if (!connectedShmdatas.has(id) && !lockStore.isLockableConnection(connection)) {
        await this.quiddityStore.applyQuiddityConnection(connection.sourceId, connection.destinationId)
      }
    }
  }

  /**
   * Updates user connections when the user data is grafted
   * @param {string} quiddityId - ID of the grafted quiddity
   * @param {string} path - Tree path of the grafted user data
   * @param {Object} data - Grafted data object
   */
  handleGraftedUserTreeConnections (quiddityId, path, data) {
    const [,, isProperty] = path.match(USER_DATA_CONNECTION_REGEX)

    if (!isProperty) {
      try {
        let json = data

        if (typeof data === 'string') json = JSON.parse(data)
        this.addUserConnection(Connection.fromJSON(json))
      } catch (error) {
        LOG.error({
          msg: 'Failed to add user data connection from grafted tree',
          error: error.message,
          quiddity: quiddityId,
          branch: path,
          tree: data
        })
      }
    }
  }

  /**
   * Updates user connections when the user data is pruned
   * @param {string} quiddityId - ID of the pruned quiddity
   * @param {string} path - Tree path of the pruned user data
   */
  handlePrunedUserTreeConnections (quiddityId, path) {
    const [, connectionId, isProperty] = path.match(USER_DATA_CONNECTION_REGEX)

    if (!isProperty) {
      try {
        this.removeUserConnection(connectionId)
      } catch (error) {
        LOG.debug({
          msg: 'User data connections\' tree is pruned',
          error: error.message,
          quiddity: quiddityId,
          connection: connectionId,
          branch: path
        })
      }
    }
  }

  /**
   * @summary Updates user connections when a quiddity is removed
   * @description
   * It will handle quiddity removals by **disconnecting** and **disarming**
   * all connections that depends on the removed source.
   * This process isn't called when a quiddity is stopped, so all its associated
   * connection will remain until it is removed.
   * @async
   */
  async handleQuiddityChanges () {
    const { quiddityStore } = this
    const unsyncConnections = []

    for (const [, connection] of this.userConnections) {
      const { sourceId, destinationId } = connection

      const isSourceExist = quiddityStore.quiddities.has(sourceId)
      const isDestinationExist = quiddityStore.quiddities.has(destinationId)

      if (!isSourceExist || !isDestinationExist) {
        unsyncConnections.push(connection)
      }
    }

    for (const connection of unsyncConnections) {
      await this.applyConnectionDisarmament(connection)
    }
  }

  /**
   * Populates connections from user data
   * @param {Object} json - User data from userTree
   * @returns {boolean} Flags true if connections were correctly populated
   */
  populateConnectionsFromUserTree (json) {
    let isConnectionsPopulated = false

    if (Object.keys(json).length > 0) {
      const { connections } = json

      try {
        if (connections && Object.keys(connections).length > 0) {
          Object.values(connections).map(json => Connection.fromJSON(json))
            .forEach(connection => this.addUserConnection(connection), this)

          isConnectionsPopulated = true
        } else {
          LOG.info({
            msg: 'No connections exist in user data'
          })
        }
      } catch (error) {
        LOG.error({
          msg: 'Error while creating connections',
          error: error.message
        })
      }
    } else {
      LOG.info({
        msg: 'User data is empty'
      })
    }

    return isConnectionsPopulated
  }

  /**
   * Applies a connection from a user action
   * @param {models.Connection} connection - The connection from which all shmdatas will be connected
   */
  async applyUserConnection (connection) {
    const { isSelectedSceneActive } = this.sceneStore

    if (this.lockStore.isLockedConnection(connection)) {
      LOG.warn({
        msg: 'Prevented connection of locked quiddities',
        connection: connection.id
      })
    } else if (isSelectedSceneActive) {
      const { sourceId, destinationId } = connection
      const sfId = await this.quiddityStore.applyQuiddityConnection(sourceId, destinationId)

      if (sfId) {
        connection.activate(sfId)
        this.addUserConnection(connection)
        await this.applyUserTreeCreation(
          connection.branch,
          connection.toJSON()
        )
      }
    }

    return connection.isActive
  }

  /**
   * Applies a disconnection from a user action
   * @param {module:models/userTree.Connection} connection - The connection from which all shmdatas will be disconnected
   */
  async applyUserDisconnection (connection) {
    const { isSelectedSceneActive } = this.sceneStore

    if (this.lockStore.isLockedConnection(connection)) {
      LOG.warn({
        msg: 'Prevented disconnection of locked quiddities',
        connection: connection.id
      })
    } else if (isSelectedSceneActive) {
      const { destinationId, sfId } = connection

      if (await this.quiddityStore.applyQuiddityDisconnection(destinationId, sfId)) {
        connection.deactivate()
      }
    }

    return !connection.isActive
  }

  /**
   * Arms a connection in the connection entry of the userTree
   * @param {module:models/userTree.Connection} connection - The connection to arm
   * @async
   */
  async applyUserConnectionArmament (connection) {
    try {
      if (!this.userConnections.has(connection.id)) {
        await this.applyUserTreeCreation(
          connection.branch,
          connection.toJSON()
        )
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to arm a connection',
        connection: connection.id,
        error: error.message
      })
    }
  }

  /**
   * Disarms a connection in the connection entry of the userTree
   * @param {module:models/userTree.Connection} connection - The connection to arm
   * @async
   */
  async applyUserConnectionDisarmament (connection) {
    try {
      if (this.userConnections.has(connection.id)) {
        await this.applyUserTreeRemoval(connection.branch)
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to disarm a connection',
        connection: connection.id,
        error: error.message
      })
    }
  }

  /**
   * Arms a connection in a scene
   * @param {models.Scene} scene - The scene where the connection is armed
   * @param {models.Connection} connection - The connection to arm
   * @async
   */
  async applySceneConnectionArmament (scene, connection) {
    // We call pop on findConcurrentSceneConnections so that we replace the last connection
    const concurrent = this.findConcurrentSceneConnections(scene, connection).pop()
    let sceneConnections = scene.connections

    if (concurrent && this.isSceneMaxReaderLimitExceed(scene, connection.destinationId)) {
      sceneConnections = sceneConnections.filter(id => id !== concurrent.id)
      // explicitly disconnect the quiddity so we don't encounter bugs with switcher.
      await this.quiddityStore.applyQuiddityDisconnection(concurrent.destinationId, concurrent.sfId)
    }

    if (!sceneConnections.includes(connection.id)) {
      await this.applyUserTreeCreation(
        `${scene.branch}.connections`,
        sceneConnections.concat([connection.id])
      )
    }

    LOG.debug({
      msg: 'Armed a connection',
      connection: connection.id
    })
  }

  /**
   * Disarms a connection in the scene userTree
   * @param {module:models/userTree.Connection} connection - The connection to disarm
   * @async
   */
  async applySceneConnectionDisarmament (scene, connection) {
    try {
      await this.applyUserTreeCreation(
        `${scene.branch}.connections`,
        scene.connections.filter(id => id !== connection.id)
      )

      LOG.debug({
        msg: 'Disarmed a connection',
        connection: connection.id
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to disarm a connection',
        connection: connection.id,
        error: error.message
      })
    }
  }

  /**
   * Arms a connection by updating the scenes and the connections in the userTree
   * @param {module:models/userTree.Connection} connection - The connection to arm
   * @async
   *
   * @mermaid
   * sequenceDiagram
   *     participant U as User
   *     participant C as ConnectionStore
   *     participant S as SceneStore
   *     participant Sw as Switcher
   *     U->>+C: Click on a disarmed connection
   *     opt The max reader limit of the destination quiddity is exceed
   *       C->>C: Find all connections that reads the destination with the same caps
   *       C->>C: Remove the concurrent Connection of the current Scene connections
   *     end
   *     opt The connection doesn't exist in another Scene
   *       C-xSw: Request an update with the new Connection
   *       Sw-x+C: Notify that the update succeeded
   *       C->>-C: Add the Connection in the userConnection map
   *     end
   *     C-x-Sw: Request an update of the selected Scene with the armed Connection
   *     Sw-x+S: Notify that the update succeeded
   *     S->>S: Add the connection into the selected Scene
   *     S->>-U: Update the UI by arming the connection in the selected Scene
   */
  async applyConnectionArmament (connection) {
    const { sceneStore, lockStore } = this

    if (lockStore.lockableQuiddities.has(connection.destinationId)) {
      for (const [, scene] of sceneStore.userScenes) {
        await this.applySceneConnectionArmament(scene, connection)
      }
    } else {
      await this.applySceneConnectionArmament(sceneStore.selectedScene, connection)
    }

    await this.applyUserConnectionArmament(connection)
  }

  /**
   * Disarms a connection by updating the scenes and the connections in the userTree
   * @param {module:models/userTree.Connection} connection - The connection to disarm
   * @async
   *
   * @mermaid
   * sequenceDiagram
   *     participant U as User
   *     participant C as ConnectionStore
   *     participant S as SceneStore
   *     participant Sw as Switcher
   *     U->>+C: Click on an armed connection
   *     C--x-Sw: Request the update of the selected Scene with the Connection removed
   *     opt The connection does't exist in another Scene
   *       Sw--x+C: Notify that the update succeeded
   *       C--xSw: Request the removal of the Connection in the userTree
   *       Sw--xC: Notify that the removal succeeded
   *       C->>-C: Remove the connection of the userConnection map
   *     end
   *     Sw--x+S: Notify that the update succeeded
   *     S->>S: Remove the Connection of the selected Scene
   *     S->>-U: Update the UI by disarming the connection of the selected Scene
   */
  async applyConnectionDisarmament (connection) {
    const { sceneStore, lockStore } = this

    if (lockStore.lockableQuiddities.has(connection.destinationId)) {
      for (const [, scene] of sceneStore.userScenes) {
        await this.applySceneConnectionDisarmament(scene, connection)
      }

      await this.applyUserConnectionDisarmament(connection)
    } else {
      const connectedScenes = this.connectedScenes.get(connection.id) || new Set()
      const isOnlyArmedOnSelectedScene = connectedScenes.size === 1 &&
                                         connectedScenes.has(sceneStore.selectedSceneId)

      await this.applySceneConnectionDisarmament(sceneStore.selectedScene, connection)

      if (isOnlyArmedOnSelectedScene) {
        await this.applyUserConnectionDisarmament(connection)
      }
    }
  }

  /**
   * Checks if the current connections of the quiddity exceeds the max reader limit in a scene
   * @param {models.Scene} scene - The scene to check
   * @param {string} quiddityId - The destination quiddity to connect
   * @returns {boolean} Flags true if the connection count is lesser than the maxReader property
   */
  isSceneMaxReaderLimitExceed (scene, quiddityId) {
    const { maxReaderLimits } = this.maxLimitStore
    const readerLimit = maxReaderLimits.get(quiddityId)
    let connectionCount = 0

    for (const connectionId of scene.connections) {
      const connection = this.userConnections.get(connectionId)
      if (connection && connection.destinationId === quiddityId) {
        connectionCount += 1
      }
    }

    const isLimitExceeded = connectionCount >= readerLimit

    if (isLimitExceeded) {
      LOG.warn({
        msg: 'Quiddity is exceeding its max reader limit',
        quiddity: quiddityId,
        maxReaderLimit: readerLimit,
        connectionCount: connectionCount
      })
    }

    return isLimitExceeded
  }

  /**
   * Finds all concurrent connections in a scene
   * @param {models.Scene} scene - The scene to check
   * @param {models.Connection} connection - The connection to find concurrents with
   * @returns {models.Connection[]} All concurent connections
   */
  findConcurrentSceneConnections (scene, connection) {
    const concurrents = []

    for (const possibleId of scene.connections) {
      const possible = this.userConnections.get(possibleId)

      if (possible && connection.isConcurrent(possible)) {
        concurrents.push(possible)
      }
    }

    return concurrents
  }

  /**
   * Finds all shmdata that could be linked to a connection
   * @param {module:models/userTree.Connection} connection - A connection model
   * @returns {module:models/shmdata.Shmdata[]} Returns an array of all compatible shmdatas
   */
  findCompatibleShmdatas (connection) {
    const { writingShmdatas } = this.shmdataStore
    const { sourceId, mediaType } = connection

    const compatibleShmdatas = []
    const sourceShmdatas = writingShmdatas.get(sourceId) || new Set()

    for (const shmdata of sourceShmdatas) {
      if (shmdata.mediaType === mediaType) {
        compatibleShmdatas.push(shmdata)
      }
    }

    return compatibleShmdatas
  }

  /**
   * Adds a new connection in the userConnections Map
   * @param {models.Connection} connection - The new connection to add
   */
  addUserConnection (connection) {
    if (!this.userConnections.has(connection.id)) {
      this.userConnections.set(connection.id, connection)

      LOG.debug({
        msg: 'Added new connection to userTree',
        connection: connection.id
      })
    }
  }

  /**
   * Deletes a connection from the userConnections Map
   * @param {string} connectionId - ID of the connection to delete
   */
  removeUserConnection (connectionId) {
    this.userConnections.delete(connectionId)

    LOG.debug({
      msg: 'Deleted connection from userTree',
      connection: connectionId
    })
  }

  /** Cleans up user connections of the store */
  clear () {
    const { NOT_INITIALIZED } = InitStateEnum
    this.setInitState(NOT_INITIALIZED)

    this.userConnections.clear()

    LOG.debug({
      msg: 'Successfully cleared all user connections'
    })
  }
}

export default ConnectionStore

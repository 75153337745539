import React, { useContext } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import '@styles/tooltips/LockedDestinationTooltip.scss'
import { Common, Layout, Context, Feedback } from '@sat-mtl/ui-components'

const { ThemeContext } = Context
const { Icon } = Common
const { FlexRow } = Layout
const { Tooltip } = Feedback

/**
 * Displays the content of the tooltip
 * @param {string} categoryId - Name of the category
 * @returns {external:react/Component} The content of the tooltip
 */
function LockedDestinationWarning ({ categoryId }) {
  const { t } = useTranslation()

  const description = t(
    'Some {{group}} destinations are locked, their assignations are read only!',
    { group: categoryId.toUpperCase() }
  )

  const advise = t('You must unlock them in order to change their connections.')

  return (
    <FlexRow columnGap={4}>
      <Icon type='alert' />
      <p>
        {description}<br />
        {advise}
      </p>
    </FlexRow>
  )
}

/**
 * Displays a tooltip with information about locked destinations
 * @param {external:react/Component} children - Wrapped component by the tooltip
 * @param {string} categoryId - Name of the category
 * @returns {external:ui-components/Feedback.Tooltip} A tooltip
 */
function LockedDestinationTooltip ({ children, categoryId }) {
  const theme = useContext(ThemeContext)

  const cl = classNames(
    `LockedDestinationTooltip-${theme}`,
    'LockedDestinationTooltip'
  )

  return (
    <div className={cl}>
      <Tooltip
        side='left'
        content={<LockedDestinationWarning categoryId={categoryId} />}
      >
        {children}
      </Tooltip>
    </div>
  )
}

export default LockedDestinationTooltip

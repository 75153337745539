import SpecSchema from '@models/schemas/spec.schema.json'
import Ajv from 'ajv'

const ajv = new Ajv()

/**
 * Modelizes a connection spec attached to a quiddity
 * @memberof module:models/shmdata
 */
class Spec {
  /** @property {string} label - Unique label from the spec of a quiddity */
  label = null

  /** @property {string} description - Description of the spec */
  description = null

  /** @property {Set<string>} can_do - List of all compatible caps for connection */
  canDo = new Set()

  /**
   * Instantiates a Spec model
   * @param {string} label - Unique label from the spec of a quiddity
   * @param {string} description - Description of the spec
   * @param {Set<string>} can_do - List of all compatible caps for connection
   * @param {number} [sfId] - ID of the shmdata follower
   * @param {number} [swId] - ID of the shmdata writer
   */
  constructor (label, description, canDo, sfId, swId) {
    this.label = label
    this.description = description
    this.canDo = canDo
    this.sfId = sfId
    this.swId = swId
  }

  get isWriter () {
    return !!this.swId
  }

  get isFollower () {
    return !!this.sfId
  }

  /**
   * Parses a connection spec
   * @param {object} json - json representation of the connection spec
   * @returns {?module:models/shmdata.Spec} A modelized GstObject for Scenic, null if an error occurs
   * @static
   */
  static fromJSON (json) {
    let spec = null

    if (ajv.validate(SpecSchema, json)) {
      spec = new Spec(
        json.label,
        json.description,
        new Set(json.can_do),
        json.sfid,
        json.swid
      )
    } else {
      throw new TypeError(`Failed to parse an invalid spec format: ${ajv.errorsText()}`)
    }

    return spec
  }

  /**
   * Transforms spec to a JSON object
   * @returns {object} An object representation of the spec
   */
  toJSON () {
    const { label, description, canDo } = this

    const json = {
      label,
      description,
      can_do: Array.from(canDo)
    }

    if (this.isWriter) {
      json.swid = this.swId
    }

    if (this.isFollower) {
      json.sfid = this.sfId
    }

    return json
  }
}

export default Spec

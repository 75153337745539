import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { isStringEmpty } from '@utils/stringTools'
import { AppStoresContext } from '@components/App'
import StatusEnum from '@models/common/StatusEnum'

import { Feedback, Layout, Inputs } from '@sat-mtl/ui-components'

import HelpWrapper from '@components/wrappers/HelpWrapper'
import { CancelButton, ConfirmButton } from '@components/common/Buttons'

const { Modal } = Feedback
const { FlexRow, FlexColumn } = Layout
const { Field, InputText, InputPassword } = Inputs

/**
 * @constant {string} RTMP_MODAL_ID - ID of the RTMP modal
 * @memberof RtmpModal
 */
export const RTMP_MODAL_ID = 'RtmpModal'

/**
 * Renders the InputText for the RTMP URL
 * @selector `.UrlInput`
 * @memberof module:components/modals.RtmpModal
 * @returns {external:react/Component} The RTMP URL text input
 */
export const UrlInput = () => {
  const { rtmpStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const label = t('RTMP URL')

  const editUrl = e => rtmpStore.setUrl(e.target.value)

  return (
    <InputText
      className='UrlInput'
      placeholder={label}
      value={rtmpStore.url || ''}
      onBlur={editUrl}
      onChange={editUrl}
      onPressEnter={editUrl}
    />
  )
}

/**
 * Renders the field for the RTMP URL
 * @memberof module:components/modals.RtmpModal
 * @returns {external:mobx-react/ObserverComponent} The RTMP URL text field
 */
export const UrlField = observer(() => {
  const { rtmpStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const label = t('RTMP URL')

  const isWrong = rtmpStore.url !== null && isStringEmpty(rtmpStore.url)

  return (
    <HelpWrapper message={t('Enter the RTMP URL (as provided by the hosting platform)')}>
      <Field
        label={t(label)}
        status={isWrong ? StatusEnum.DANGER : StatusEnum.FOCUS}
        description={isWrong && t('RTMP url is required')}
      >
        <UrlInput />
      </Field>
    </HelpWrapper>
  )
})

/**
 * Renders the InputPassword for the RTMP stream key
 * @selector `.StreamKeyInput`
 * @memberof module:components/modals.RtmpModal
 * @returns {external:react/Component} The RTMP stream key text input
 */
export const StreamKeyInput = () => {
  const { rtmpStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const label = t('Stream key')

  const editKey = e => rtmpStore.setKey(e.target.value)

  return (
    <InputPassword
      className='StreamKeyInput'
      placeholder={label}
      value={rtmpStore.key || ''}
      onBlur={editKey}
      onChange={editKey}
      onPressEnter={editKey}
    />
  )
}

/**
 * Renders the field for the RTMP stream key
 * @memberof module:components/modals.RtmpModal
 * @returns {external:mobx-react/ObserverComponent} The RTMP stream key input field
 */
const StreamKeyField = observer(() => {
  const { rtmpStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const label = t('Stream key')

  const isWrong = rtmpStore.key !== null && isStringEmpty(rtmpStore.key)

  return (
    <HelpWrapper message={t('Enter the RTMP stream key (as provided by the hosting platform)')}>
      <Field
        label={label}
        status={isWrong ? StatusEnum.DANGER : StatusEnum.FOCUS}
        description={isWrong && t('RTMP key is required')}
      >
        <StreamKeyInput />
      </Field>
    </HelpWrapper>
  )
})

/**
 * Renders the Cancel Button for the Modal
 * @memberof module:components/modals.RtmpModal
 * @returns {module:components/common.CancelButton} The cancel button of the Modal
 */
export const RtmpCancelButton = () => {
  const { rtmpStore } = useContext(AppStoresContext)

  return (
    <CancelButton
      onCancel={() => {
        rtmpStore.setRtmpRequestFlag(false)
        rtmpStore.cleanRtmpCredentials()
      }}
    />
  )
}

/**
 * Renders the Confirm Button for the Modal
 * @memberof module:components/modals.RtmpModal
 * @returns {module:components/common.ConfirmButton} The confirm button of the Modal
 */
export const RtmpConfirmButton = observer(() => {
  const { rtmpStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <ConfirmButton
      disabled={!rtmpStore.hasValidCredentials}
      onConfirm={() => {
        rtmpStore.applyRtmpQuiddityCreation()
        rtmpStore.setRtmpRequestFlag(false)
        rtmpStore.cleanRtmpCredentials()
      }}
    >
      {t('Create')}
    </ConfirmButton>
  )
})

/**
 * Renders the Footer for the Modal
 * @memberof module:components/modals.RtmpModal
 * @returns {external:react/Component} The footer of the Modal
 */
export const RtmpFooter = () => {
  return (
    <FlexRow justifyContent='space-between'>
      <RtmpCancelButton />
      <RtmpConfirmButton />
    </FlexRow>
  )
}

/**
 * Scenic modal used to create an RTMP quiddity
 * @selector `#RtmpModal`
 * @memberof module:components/modals
 * @returns {external:mobx-react/ObserverComponent} The RTMP modal
 */
const RtmpModal = observer(() => {
  const { rtmpStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <Modal
      id='RtmpModal'
      visible={rtmpStore.isRtmpRequested}
      header={t('Create an RTMP Destination')}
      footer={<RtmpFooter />}
    >
      <FlexColumn columnGap={8}>
        <div className='caption'>
          {t('Enter your RTMP settings')}
        </div>
        <UrlField />
        <StreamKeyField />
      </FlexColumn>
    </Modal>
  )
})

export default RtmpModal

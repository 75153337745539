import API from '@api/API'

/**
 * @classdesc API for managing session interactions
 * @memberof module:api
 * @extends module:api.API
 */
class SessionAPI extends API {
  resetActions = []

  /**
   * Resets the current session. This will only remove quiddities that
   * were not protected.
   * @returns {Promise} A promise that returns API error or success
   * @async
   */
  reset () {
    return new Promise((resolve, reject) => {
      this.socket.emit('session_reset', (error, success) => {
        if (error) reject(error)
        if (success) {
          this.resetActions.forEach(action => action())
          resolve(success)
        }
      })
    })
  }

  /**
   * Clears all quiddities in the current session. This will unconditionnally
   * remove all quiddities.
   * @returns {Promise} A promise that returns API error or success
   * @async
   */
  clear () {
    return new Promise((resolve, reject) => {
      this.socket.emit('session_clear', (error, success) => {
        if (error) {
          reject(error)
        } else {
          resolve(success)
        }
      })
    })
  }

  /**
   * Internal callback when the session is reset
   * @param {Function} onResetAction - Callback executed when the session is reset
   */
  onSessionReset (onResetAction) {
    this.resetActions.push(onResetAction)
  }

  /**
   * Gets a list of all session files
   * @returns {Promise} A promise that should return an array of all saved sessions
   */
  list () {
    return new Promise((resolve, reject) => {
      this.socket.emit('session_list', (error, success) => {
        if (error) {
          reject(error)
        } else {
          resolve(success)
        }
      })
    })
  }

  /**
   * Saves the current session as a file
   * @param {string} sessionName - Name of the session
   * @returns {Promise} A promise that returns the full path of the saved session if successful
   */
  saveAs (sessionName) {
    return new Promise((resolve, reject) => {
      this.socket.emit('session_save_as', sessionName, (error, success) => {
        if (error) {
          reject(error)
        } else {
          resolve(success)
        }
      })
    })
  }

  /**
   * Loads a session file
   * @param {string} sessionName - Name of the session
   * @returns {Promise} A promise that returns true if the session was loaded
   */
  load (sessionName) {
    return new Promise((resolve, reject) => {
      this.socket.emit('session_load', sessionName, (error, success) => {
        if (error) {
          reject(error)
        } else {
          resolve(success)
        }
      })
    })
  }

  /**
   * Reads a session file
   * @param {string} sessionName - Name of the session
   * @returns {Promise} A promise that returns the session content
   */
  read (sessionName) {
    return new Promise((resolve, reject) => {
      this.socket.emit('session_read', sessionName, (error, success) => {
        if (error) {
          reject(error)
        } else {
          resolve(success)
        }
      })
    })
  }

  /**
   * Write content to a session file
   * @param {string} sessionContent - Content to write to session file
   * @param {string} sessionName - Name of the session
   * @returns {Promise} A promise that returns true if the session file was written properly
   */
  write (sessionContent, sessionName) {
    return new Promise((resolve, reject) => {
      this.socket.emit('session_write', sessionContent, sessionName, (error, success) => {
        if (error) {
          reject(error)
        } else {
          resolve(success)
        }
      })
    })
  }

  /**
   * Removes a session
   * @param {string} sessionName - Name of the session
   * @returns {Promise} - A promise that returns true if the session was removed
   */
  remove (sessionName) {
    return new Promise((resolve, reject) => {
      this.socket.emit('session_remove', sessionName, (error, success) => {
        if (error) {
          reject(error)
        } else {
          resolve(success)
        }
      })
    })
  }
}

export default SessionAPI

import API from '@api/API'

/**
 * @classdesc API for managing the info tree API
 * @memberof module:api
 * @extends module:api.API
 */
class InfoTreeAPI extends API {
  /**
   * Gets the info tree of a quiddity with its name
   * @param {string} quidId - ID of the quiddity
   * @param {string} [treePath] - Path of the tree to query
   * @async
   */
  get (quidId, treePath) {
    return new Promise((resolve, reject) => {
      this.socket.emit('info_tree_get', quidId, treePath, (error, result) => {
        if (error) reject(error)
        if (!result || result === 'null') resolve({})
        if (result) resolve(result)
      })
    })
  }

  /**
   * Listens to all grafts in info tree
   * @param {function} onGraftAction - Function triggered when the quiddity tree is grafted by Switcher
   * @param {(string|function)} [quiddityMatcher] - ID of the quiddity (if empty, listen to all tree grafts for all quiddities)
   * @param {(string|function)} [pathMatcher] - Match the path of the updated element
   */
  onGrafted (onGraftAction, quiddityMatcher, pathMatcher) {
    this.socket.on('info_tree_grafted', (quidId, path, value) => {
      if (this.match(quidId, quiddityMatcher) && this.match(path, pathMatcher)) {
        onGraftAction(quidId, path, JSON.parse(value))
      }
    })
  }

  /**
   * Listens to all deletions in the quiddity tree
   * @param {function} onPruneAction - Function triggered when the quiddity tree is pruned by Switcher
   * @param {string} [quiddityId] - ID of the quiddity (if empty, listen to all tree prunes for all quiddities)
   * @param {(string|function)} [pathMatcher] - Match the path of the updated element
   */
  onPruned (onGraftAction, quiddityMatcher, pathMatcher) {
    this.socket.on('info_tree_pruned', (quidId, path) => {
      if (this.match(quidId, quiddityMatcher) && this.match(path, pathMatcher)) {
        onGraftAction(quidId, path)
      }
    })
  }
}

export default InfoTreeAPI

import { toMatrixCategory } from '@models/matrix/MatrixCategoryEnum'

/**
 * @classdesc Model for displaying a Switcher quiddity
 * @memberof models
 */
class Quiddity {
  /**
   * Instantiates a new Quiddity model
   * @param {string} [id] - ID of the quiddity
   * @param {string} nickname - Name of the quiddity
   * @param {string} kindId - Kind of the quiddity
   * @param {string} [name] - Name of the quiddity (default is ID)
   * @param {string} [infoTree={}] - Info tree of the quiddity
   * @param {string} [userTree={}] - User tree of the quiddity
   * @param {string} [isHidden=false] - If set, it hides the quiddity from the user
   * @param {string} [isProtected=false] - If set, it disables modification of the quiddity
   */
  constructor (id, nickname, kindId, infoTree = {}, userTree = {}, connectionSpecs = {}, isHidden = false, isProtected = false) {
    this.id = id
    this.nickname = nickname

    if (typeof id === 'undefined' && typeof nickname === 'undefined') {
      throw new TypeError('Each quiddity must have an unique identifier')
    }

    if (typeof kindId === 'undefined' || typeof kindId !== 'string') {
      throw new TypeError('Attribute `kindId` is required and must be a string')
    }

    this.kindId = kindId

    if (typeof infoTree !== 'object') {
      throw new TypeError('Attribute `infoTree` must be an object')
    }

    this.infoTree = infoTree

    if (typeof userTree !== 'object') {
      throw new TypeError('Attribute `userTree` must be an object')
    }

    this.userTree = userTree

    if (typeof connectionSpecs !== 'object') {
      throw new TypeError('Attribute `connectionSpecs` must be an object')
    }

    this.connectionSpecs = connectionSpecs

    if (typeof isHidden !== 'boolean') {
      throw new TypeError('Attribute `isHidden` must be a boolean')
    }

    this.isHidden = isHidden

    if (typeof isProtected !== 'boolean') {
      throw new TypeError('Attribute `isProtected` must be a boolean')
    }

    this.isProtected = isProtected
  }

  /**
   * Gets the matrix category of a quiddity from its kindId
   * @returns {string} Returns the category of a quiddity
   */
  get matrixCategory () {
    return toMatrixCategory(this.kindId)
  }

  /**
   * Set quiddity attributes from configuration parameters
   * @param {object} [config=null] - A configuration object per quiddity
   */
  configure (config = null) {
    if (config) {
      const { isHidden, isProtected } = config

      this.isHidden = isHidden
      this.isProtected = isProtected
    }
  }

  /**
   * Checks if the quiddity is private
   * @returns {boolean} Returns true if the quiddity is private
   */
  get isPrivate () {
    return this.isHidden || this.isProtected
  }

  get followerSpec () {
    return this.connectionSpecs?.follower
  }

  get isFollower () {
    return Array.isArray(this.followerSpec) &&
      this.followerSpec.length > 0
  }

  get writerSpec () {
    return this.connectionSpecs?.writer
  }

  get isWriter () {
    return Array.isArray(this.writerSpec) &&
      this.writerSpec.length > 0
  }

  /**
   * Checks if a quiddity model has all its attributes
   * @param {object} json - the JSON model to check
   * @returns {boolean} true if some attributes are missing
   */
  static isModelIncomplete (json) {
    return typeof json.nickname === 'undefined' ||
      typeof json.infoTree === 'undefined' ||
      typeof json.infoTree?.property === 'undefined' ||
      typeof json.userTree === 'undefined' ||
      typeof json.connectionSpecs === 'undefined'
  }

  /**
   * Parses a JSON representation of a Quiddity
   * @static
   * @param {Object} json - A JSON quiddity
   * @returns {models.Quiddity} The parsed quiddity
   */
  static fromJSON (json) {
    const {
      id,
      nickname,
      infoTree,
      userTree,
      kindId,
      kind,
      connectionSpecs,
      isHidden,
      isProtected
    } = json

    return new Quiddity(
      id,
      nickname,
      kindId || kind,
      infoTree,
      userTree || {},
      connectionSpecs || {},
      isHidden,
      isProtected
    )
  }

  /**
   * Returns the JSON representation of a quiddity
   * @returns {Object} The JSON quiddity
   */
  toJSON () {
    const {
      id,
      nickname,
      kindId,
      infoTree,
      userTree,
      connectionSpecs,
      isHidden,
      isProtected
    } = this

    return {
      id,
      nickname,
      kindId,
      infoTree,
      userTree,
      connectionSpecs,
      isHidden,
      isProtected
    }
  }
}

export default Quiddity

import React, { useContext } from 'react'

import { observer } from 'mobx-react'

import { AppStoresContext } from '@components/App'

import QuiddityMenu from '@components/menus/QuiddityMenu'
import { Layout } from '@sat-mtl/ui-components'

import '@styles/panels/Menu.scss'

const { FlexRow } = Layout

/** Displays a menu panel for quiddities (sources, destinations and compressions)
 * @Selector `#QuiddityMenuPanel`
 * @returns {external:mobx-react/ObserverComponent} - The nickname label of a quiddity
*/
const QuiddityMenuPanel = observer(() => {
  const { quiddityStore, quiddityMenuStore } = useContext(AppStoresContext)
  const { userMenus } = quiddityMenuStore

  let quiddityMenus = null

  if (quiddityStore.isInitialized()) {
    const menus = []

    for (const [menuId, menu] of userMenus) {
      menus.push(
        <QuiddityMenu
          key={menuId}
          store={quiddityMenuStore}
          collection={menu}
        />
      )
    }

    if (menus.length > 0) {
      quiddityMenus = (
        <FlexRow columnGap={14}>
          {menus}
        </FlexRow>
      )
    }
  }

  return (
    <div id='QuiddityMenuPanel'>
      {quiddityMenus}
    </div>
  )
})

export default QuiddityMenuPanel

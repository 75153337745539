import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import FileBridge from '@models/common/FileBridge'
import StatusEnum from '@models/common/StatusEnum'

import RowEntry from '@components/entries/RowEntry'

/**
 * Renders information about the file stats
 * @private
 * @param {string} props.lastModificationDate - The date of the last modification of the file
 * @param {boolean} props.isCurrent - Flags if the file represents the current session
 * @todo Require missing API from switcher3 that displays the `Last Modification Date`
 * @see https://gitlab.com/sat-mtl/tools/scenic/scenic/-/issues/297
 * @returns {React.PureComponent} A subtitle
 */
function FileStatSubtitle ({ lastModificationDate, isCurrent }) {
  const { t } = useTranslation()

  const statLabels = [
    // `${t('Last modification')}: ${lastModificationDate}`
  ]

  if (isCurrent) {
    statLabels.unshift(t('Current session'))
  }

  return (
    <>
      {statLabels.join(', ')}
    </>
  )
}

/**
 * Renders an entry that represents a file
 * @param {models.FileBridge} props.file - A file model
 * @param {Function} props.onClick - Function triggered when the entry is clicked
 * @param {boolean} props.isCurrent - Flags if the file represents the current session
 * @param {boolean} props.selected -  Flags if the entry is selected
 * @param {models.StatusEnum} props.status - Status of the entry
 * @returns {React.PureComponent} An entry
 */
function FileEntry ({ file, onClick, isCurrent, selected, status }) {
  const $subtitle = (
    <FileStatSubtitle
      isCurrent={isCurrent}
      lastModificationDate={file.date.toLocaleString()}
    />
  )

  return (
    <RowEntry
      className='FileEntry'
      title={file.name}
      subtitle={$subtitle}
      onClick={onClick}
      status={status}
      selected={selected}
    />
  )
}

FileEntry.propTypes = {
  file: PropTypes.instanceOf(FileBridge),
  description: PropTypes.string,
  onClick: PropTypes.func,
  isCurrent: PropTypes.bool,
  selected: PropTypes.bool,
  status: PropTypes.oneOf(Object.values(StatusEnum))
}

export default FileEntry

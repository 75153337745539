/* global Blob */

import { observable, action, reaction, makeObservable } from 'mobx'

import InitStateEnum from '@models/common/InitStateEnum'

import Quiddity from '@models/quiddity/Quiddity'

import Store from '@stores/Store'

import ConfigStore from '@stores/common/ConfigStore'
import { TIMELAPSE_FILENAME_REGEX } from '@stores/quiddity/PropertyStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'

import ShmdataStore, { SHMPATH_ID_REGEX } from '@stores/shmdata/ShmdataStore'
import SettingStore, { DISPLAY_THUMBNAILS_ID } from '@stores/common/SettingStore'
import CapsStore from '@stores/shmdata/CapsStore'

import { InitializationError, RequirementError } from '@utils/errors'
import { logger } from '@utils/logger'

const { INITIALIZING } = InitStateEnum

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for ThumbnailStore
 * @memberof stores.ThumbnailStore
 */
export const LOG = logger.child({ store: 'ThumbnailStore' })

/**
 * @constant {string} THUMBNAIL_KIND_ID - Kind ID of the thumbnail quiddity
 * @memberof stores.ThumbnailStore
 */
export const THUMBNAIL_KIND_ID = 'scenicThumbnail'

/**
 * @constant {string} THUMBNAIL_NICKNAME - Unique Nickname of the thumbnail quiddity
 * @memberof stores.ThumbnailStore
 */
export const THUMBNAIL_NICKNAME = 'thumbnails'

/**
 * @constant {RegExp} THUMBNAIL_PATH_REGEX - Extract the shmdata path from a quiddity's grafted shmdata.writer branch
 * @memberof stores.ThumbnailStore
 */
export const THUMBNAIL_PATH_REGEX = /^\.shmdata\.writer\.([\w-/]+)$/

/**
 * @classdesc Stores all thumbnail subscriptions
 * @extends stores.Store
 * @memberof stores
 */
class ThumbnailStore extends Store {
  /** @property {stores.ConfigStore} configStore - Stores and manages the app's configuration */
  configStore = null

  /** @property {stores.QuiddityStore} quiddityStore - Stores and manages all quiddities */
  quiddityStore = null

  /** @property {stores.ShmdataStore} shmdataStore - Stores and manages all shmdatas */
  shmdataStore = null

  /** @property {stores.SettingStore} settingStore - Stores and manages all settings */
  settingStore = null

  /** @property {stores.CapsStore} CapsStore - Stores and manages all shmdata caps */
  capsStore = null

  /** @property {Map<string, string>} thumbnailUrls - All thumbnail URLs hashed by shmdata ID */
  thumbnailUrls = new Map()

  /** @property {string} thumbnailId - The ID of the thumbnail quiddity */
  get thumbnailId () {
    return this.quiddityStore.quiddityByNames?.get(THUMBNAIL_NICKNAME)?.id
  }

  /**
   * Instantiates a new ThumbnailStore
   * @param {stores.SocketStore} socketStore - Socket manager
   * @param {stores.ConfigStore} configStore - Configuration manager
   * @param {stores.QuiddityStore} quiddityStore - Quiddity manager
   * @param {stores.ShmdataStore} shmdataStore - Shmdata manager
   * @param {stores.SettingStore} settingStore - Setting manager
   * @param {stores.capsStore} capsStore - Caps manager
   * @constructor
   */
  constructor (socketStore, configStore, quiddityStore, shmdataStore, settingStore, capsStore, nicknameStore) {
    super(socketStore)

    makeObservable(this, {
      thumbnailUrls: observable,
      addThumbnail: action,
      removeThumbnail: action,
      clear: action
    })

    if (configStore instanceof ConfigStore) {
      this.configStore = configStore
    } else {
      throw new RequirementError(this.constructor.name, 'ConfigStore')
    }

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new RequirementError(this.constructor.name, 'QuiddityStore')
    }

    if (shmdataStore instanceof ShmdataStore) {
      this.shmdataStore = shmdataStore
    } else {
      throw new RequirementError(this.constructor.name, 'ShmdataStore')
    }

    if (settingStore instanceof SettingStore) {
      this.settingStore = settingStore
    } else {
      throw new RequirementError(this.constructor.name, 'SettingStore')
    }

    if (capsStore instanceof CapsStore) {
      this.capsStore = capsStore
    } else {
      throw new RequirementError(this.constructor.name, 'CapsStore')
    }

    this.nicknameStore = nicknameStore

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )

    reaction(
      () => this.quiddityStore.initState,
      state => this.handleQuiddityStoreInitialization(state)
    )

    reaction(
      () => this.shmdataStore.allWriterPaths.length,
      () => this.applyThumbnailConnection()
    )

    reaction(
      () => settingStore.settings.get(DISPLAY_THUMBNAILS_ID),
      () => this.handleSettingChanges()
    )
  }

  /**
   * Initializes Store by fetching the Thumbnail quiddity
   * @returns {boolean} Flags true if store is well initialized
   * @throws {Error} Will throw an error if the quiddity store is not initialized
   * @async
   */
  async initialize () {
    if (!this.isNotInitialized()) {
      return this.isInitialized()
    } else if (this.quiddityStore.isNotInitialized()) {
      throw new InitializationError('QuiddityStore')
    }

    this.setInitState(INITIALIZING)
    this.handleSettingChanges()

    return this.applySuccessfulInitialization()
  }

  /**
   * Creates the timelapse quiddity for thumbnails
   * @async
   */
  async fallbackThumbnailQuiddity () {
    try {
      const config = this.configStore.findInitialConfiguration(THUMBNAIL_KIND_ID, this.thumbnailId)
      let thumbnailQuid
      if (config) {
        thumbnailQuid = await this.quiddityStore.applyQuiddityCreation(
          THUMBNAIL_KIND_ID,
          THUMBNAIL_NICKNAME,
          config.properties,
          config.userTree
        )
      } else {
        thumbnailQuid = await this.quiddityStore.applyQuiddityCreation(
          THUMBNAIL_KIND_ID,
          THUMBNAIL_NICKNAME
        )
      }
      // makes a proper model out of the json
      thumbnailQuid = Quiddity.fromJSON(thumbnailQuid)
      // hide the quiddity
      thumbnailQuid.isHidden = true
      // forces the quiddity store to update itself with the quiddity right now
      // so that the call to applyThumbnailConnection can go through.
      this.quiddityStore.addQuiddity(thumbnailQuid)
      // force the nickname store to update the nickname for the new quiddity, otherwise
      // this.thumbnailId won't be in a good state when applyThumbnailConnection is called.
      await this.nicknameStore.updateNickname(thumbnailQuid.id)
      LOG.info({
        msg: 'Successfully fallen back quiddity creation',
        quiddity: this.thumbnailId
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to fallback quiddity creation',
        quiddity: this.thumbnailId
      })
    }
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()
    if (socket && this.socketStore.hasActiveAPIs) {
      const { propertyAPI, infoTreeAPI } = this.socketStore.APIs

      propertyAPI.onUpdated(
        (quidId, property, value) => this.handleLastImage(value),
        quidId => quidId === this.thumbnailId,
        propertyId => propertyId.includes('last_image')
      )

      infoTreeAPI.onPruned(
        (quidId, path) => this.handleShmdataDisconnection(path),
        quidId => quidId === this.thumbnailId,
        path => Array.isArray(path.match(THUMBNAIL_PATH_REGEX))
      )
    }
  }

  /**
   * Handles settingStore's display thumbnails changes
   */
  async handleSettingChanges () {
    const { displayThumbnails } = this.settingStore
    const { quiddities } = this.quiddityStore

    if (displayThumbnails && !quiddities.has(this.thumbnailId)) {
      await this.fallbackThumbnailQuiddity()
      this.applyThumbnailConnection()
    } else if (!displayThumbnails && quiddities.has(this.thumbnailId)) {
      this.quiddityStore.applyQuiddityRemoval(this.thumbnailId)
      this.thumbnailUrls.clear()
    }
  }

  /**
   * Handles QuiddityStore's initialization state changes
   * @param {models.InitStateEnum} initState - State of the QuiddityStore's initialization
   */
  handleQuiddityStoreInitialization (initState) {
    const { NOT_INITIALIZED } = InitStateEnum

    if (initState === NOT_INITIALIZED) {
      this.clear()
    }
  }

  /**
   * Handles shmdata disconnection from Thumbnail quiddity
   * @param {string} path - JSON path that was pruned
   */
  handleShmdataDisconnection (path) {
    const [, , shmdataId] = path.match(SHMPATH_ID_REGEX)

    const url = this.thumbnailUrls.get(shmdataId)
    if (url) {
      URL.revokeObjectURL(url)
      this.removeThumbnail(shmdataId)
    }
  }

  /**
   * Handles updates for 'last_image' property of Thumbnail quiddity
   * @param {string} filename - Updated value of 'last_image' property
   */
  async handleLastImage (fileName) {
    if (this.settingStore.displayThumbnails) {
      const [, , shmdataId] = fileName.match(TIMELAPSE_FILENAME_REGEX)
      const url = await this.makeLastImage(fileName, shmdataId)
      if (url) {
        this.addThumbnail(shmdataId, url)
      }
    }
  }

  /**
   * Creates the last image of the Thumbnail quiddity
   * @param {string} thumbnailFilePath - Updated value of 'last_image' property
   * @param {string} shmdataId - Shmdata ID
   * @returns {?string} url - The thumbnail image encoded in an URL
   * @async
   */
  async makeLastImage (thumbnailFilePath, shmdataId) {
    let data = null
    let blob = null
    let newUrl = null

    try {
      data = await this.socketStore.APIs.imageAPI.readImage(thumbnailFilePath)
      const url = this.thumbnailUrls.get(shmdataId)

      if (url) {
        URL.revokeObjectURL(url)
      }

      blob = new Blob([new Int8Array(data)], { type: 'image/jpeg' })
      newUrl = URL.createObjectURL(blob)

      LOG.trace({
        msg: `Updated thumbnail url for ${shmdataId}`,
        shmdata: shmdataId
      })
    } catch (error) {
      LOG.warn({
        msg: `Failed to update thumbnail from file path ${thumbnailFilePath}`,
        error: error.message
      })
    }

    // This is done to prevent a memory leak
    data = null
    blob = null

    return newUrl
  }

  /**
   * Requests connection to the thumbnail quiddity for each writing video shmdatas
   * Note : this doesn't support quiddities with multiple raw video shmdata
   */
  applyThumbnailConnection () {
    if (this.isInitialized()) {
      const quiddityAPI = this.socketStore.APIs.quiddityAPI
      const writerQuiddities = this.quiddityStore.sources
      const thumbnailQuid = this.quiddityStore.quiddities.get(this.thumbnailId)
      for (const writerQuiddity of writerQuiddities) {
        // find a shmdata that can do video/x-raw in the quiddity
        let rawVideoShmpaths = []
        if (writerQuiddity.infoTree?.shmdata?.writer) {
          rawVideoShmpaths = Object.keys(writerQuiddity.infoTree.shmdata.writer).filter(
            shmpath => writerQuiddity.infoTree?.shmdata?.writer[shmpath].caps.split(',')[0] === 'video/x-raw'
          )
        }
        // if we dont have raw video, go look at the next source.
        if (rawVideoShmpaths.length === 0) {
          continue
        }
        // if our thumbnail has one of the paths in its reader, don't connect again.
        if (thumbnailQuid.infoTree?.shmdata?.reader && rawVideoShmpaths.some(
          shmpath => Object.keys(thumbnailQuid.infoTree.shmdata.reader).includes(shmpath)
        )) {
          continue
        }
        // we specifically want to connect to the meta sfid. We don't want switcher to try all
        // the possibly sfids if a connection refuses to be made.
        quiddityAPI.connectSfid(writerQuiddity.id, this.thumbnailId, 1)
      }
    }
  }

  /**
   * Adds a new thumbnail URL in the thumbnailUrls Map
   * @param {string} shmdataId - Shmdata ID
   * @param {string} url - Thumbnail URL
   */
  addThumbnail (shmdataId, url) {
    this.thumbnailUrls.set(shmdataId, url)

    LOG.debug({
      msg: 'Successfully added thumbnail url',
      shmdata: shmdataId,
      url: url.toString()
    })
  }

  /**
   * Deletes a thumbnail URL from the thumbnailUrls Map
   * @param {string} shmdataId - Shmdata ID of the thumbnail URL to delete
   */
  removeThumbnail (shmdataId) {
    this.thumbnailUrls.delete(shmdataId)

    LOG.debug({
      msg: 'Successfully deleted thumbnail url',
      shmdata: shmdataId
    })
  }

  /**
   * Cleans up ThumbnailStore
   */
  clear () {
    const { NOT_INITIALIZED } = InitStateEnum
    this.setInitState(NOT_INITIALIZED)

    this.thumbnailUrls.clear()

    LOG.info({
      msg: 'Successfully cleared ThumbnailStore'
    })
  }
}

export default ThumbnailStore

/**
 * @classdesc Describes a quiddity
 * @memberof models
 */
class Kind {
  /**
   * Instantiates a new Quiddity kind
   * @param {string} id - Id of the quiddity kind
   * @param {string} name - Name of the quiddity kind
   * @param {string} category - Category of the kind
   * @param {string[]} [tags=[]] - Tags of the kind
   * @param {string} [description=''] - Description of the kind
   * @param {boolean} [readOnly=false] - Flag which defines the kind as read-only
   */
  constructor (id, name, category, tags = [], description = '', readOnly = false) {
    if (typeof id === 'undefined' || typeof id !== 'string') {
      throw new TypeError('Attribute `id` is required and must be a string')
    }

    this.id = id

    if (typeof name === 'undefined' || typeof name !== 'string') {
      throw new TypeError('Attribute `name` is required and must be a string')
    }

    this.name = name

    this.category = category
    // tags do not exist in switcher. I'm keeping this here because removing them breaks a ton
    // of tests but don't rely on this.
    this.tags = tags

    if (typeof description !== 'string') {
      throw new TypeError('Attribute `description` must be a string')
    }

    this.description = description

    if (typeof readOnly !== 'boolean') {
      throw new TypeError('Attribute `readOnly` must be boolean')
    }

    this.readOnly = readOnly
  }

  /**
   * Checks if a quiddity matches with this kind
   *
   * This is never used except in tests
   * @param {string} quidId - ID of the quiddity
   */
  isMatchingQuiddity (quidId) {
    const regex = new RegExp(`${this.id}\\d*$`)
    return regex.test(quidId)
  }

  /** @property {boolean} isFollower - Flag if the quiddity is a follower.
   * Note that tags do not exist in switcher. This will not work except in mocked tests
   */
  get isFollower () {
    return this.tags.includes('follower')
  }

  /** @property {boolean} isWriter - Flag if the quiddity is a writer.
   * Note that tags do not exist in switcher. This will not work except in mocked tests
   */
  get isWriter () {
    return this.tags.includes('writer')
  }

  /** @property {string} type - Return the type of the kind
   * Note that tags do not exist in switcher. This will not work except in mocked tests
   */
  get type () {
    if (this.isWriter) {
      return 'Source'
    } else if (this.isFollower) {
      return 'Destination'
    } else {
      return 'Resource'
    }
  }

  /**
   * Parses a JSON element to a new Kind
   * Warning: This implementation considers that the kind, name and the id attributes are the same information
   * @param {Object} json - A JSON element
   * @param {string} json.id - ID of the quiddity kind
   * @param {string} [json.kind] - Alias for `id` of the quiddity kind
   * @param {string} json.name - Name of the quiddity kind
   * @param {string} json.category - Category of the quiddity kind
   * @static
   */
  static fromJSON ({ kind, name, category, description, tags, readOnly }) {
    return new Kind(kind, name, category, tags, description, readOnly)
  }

  toJSON () {
    const { id, name, category, description, tags } = this

    return {
      id: name,
      kind: id,
      name,
      category,
      description,
      tags
    }
  }
}

export default Kind

import { logger } from '@utils/logger'
import { cleanParenthesis } from '@utils/stringTools'
import CapabilityGroupEnum from './CapabilityGroupEnum'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the Capabilities
 * @memberof models.Capabilities
 */
export const LOG = logger.child({ store: 'Capabilities' })

/**
 * @constant {Regex} GSTCAPS_REGEX - Regex used to parse the GstCaps format
 * @memberof models.Capabilities
 */
export const GSTCAPS_REGEX = /^([\w-]+)=(\(\w+\))?(.*)$/

/**
 * Modelizes a GstCaps attached to a Shmdata
 * @see [GstCaps Reference]{@link https://gstreamer.freedesktop.org/documentation/gstreamer/gstcaps.html?gi-language=javascript}
 * @memberof models
 */
class Capabilities {
  /**
   * @property {string} mediaType - Equivalent of the MIME Type for media data
   * @see [MediaTypes Concept]{@link https://gstreamer.freedesktop.org/documentation/plugin-development/advanced/media-types.html}
   */
  mediaType = null

  /** @property {Map<string, string|number|boolean>} properties - All properties of the capabilities by all property keys */
  properties = new Map()

  /** @property {Map<string, string>} types - All types of the capabilities */
  types = new Map()

  /**
   * Instantiates a Capabilities model
   * @param {string} mediaType - Media type of the capabilities
   * @param {Map<string, string|number|boolean>} properties - All properties of the capabilities
   */
  constructor (mediaType, properties, types) {
    this.mediaType = mediaType
    this.properties = properties
    this.types = types
  }

  /**
   * Gets an integer property
   * @param {string} key - Key of the property
   * @returns {number|NaN} Returns the parsed integer or NaN in any other cases
   */
  getInt (key) {
    let int = null

    if (this.types.has(key) && this.types.get(key) !== 'int') {
      int = NaN
    } else {
      int = Number.parseInt(this.properties.get(key))
    }

    return int
  }

  /** @property {number} width - Width capability of the shmdata (0 by default) */
  get width () {
    return this.getInt('width') || 0
  }

  /** @property {number} height - Height capability of the shmdata (0 by default) */
  get height () {
    return this.getInt('height') || 0
  }

  /** @property {Map<string, Map<string, string>>} groups - All capabilities hashed by groups */
  get groups () {
    const groups = new Map()
    const others = new Map()

    if (this.properties.has('width') && this.properties.has('height')) {
      groups.set(
        CapabilityGroupEnum.DIMENSIONS,
        new Map([
          ['width', this.properties.get('width')],
          ['height', this.properties.get('height')]
        ])
      )
    }

    for (const [key, value] of this.properties.entries()) {
      let isGrouped = false

      for (const groupValue of groups.values()) {
        if (groupValue.has(key)) {
          isGrouped = true
          break
        }
      }

      if (!isGrouped) {
        others.set(key, value)
      }
    }

    if (others.size > 0) groups.set(CapabilityGroupEnum.OTHERS, others)

    return groups
  }

  /**
   * Parses a GstCaps object from an array of strings
   * @param {string[]} caps - The GstCaps representation
   * @returns {models.Capabilities} A modelized GstObject for Scenic
   * @static
   */
  static fromArray (array) {
    const caps = [...array]
    const mediaType = caps.shift()
    const types = new Map()
    const properties = new Map()

    for (const entry of caps) {
      const [, key, type, value] = entry.trim().match(GSTCAPS_REGEX)

      properties.set(key, value)

      if (type) {
        types.set(key, cleanParenthesis(type))
      }
    }

    return new Capabilities(mediaType, properties, types)
  }

  /**
   * Parses a GstCaps object from a string
   * @param {string} caps - The GstCaps representation
   * @returns {models.Capabilities} A modelized GstObject for Scenic
   * @static
   */
  static fromString (caps) {
    return Capabilities.fromArray(caps.split(','))
  }

  /**
   * Safely parses a GstCaps
   * @param {string|string[]} caps - The GstCaps representation
   * @returns {?models.Capabilities} A modelized GstObject for Scenic, null if an error occurs
   * @static
   */
  static fromCaps (caps) {
    let capabilities = null

    try {
      if (Array.isArray(caps)) {
        capabilities = Capabilities.fromArray(caps)
      } else if (typeof caps === 'string') {
        capabilities = Capabilities.fromString(caps)
      } else {
        throw new TypeError('Failed to parse an invalid caps format, it must be an array or a string')
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to parse capabilities',
        error: error.message
      })
    }

    return capabilities
  }

  /**
   * Transforms a GstCaps as a Switcher caps representation
   * @returns {string} A string representation of the GstCaps
   */
  toCaps () {
    return this.toString()
  }

  /**
   * Transforms a GstCaps to an array representation
   * @returns {string[]} An array representation of the GstCaps
   */
  toArray () {
    const caps = [this.mediaType]

    for (const [key, value] of this.properties) {
      let type = ''

      if (this.types.has(key)) {
        type = `(${this.types.get(key)})`
      }

      caps.push(`${key}=${type}${value}`)
    }

    return caps
  }

  /**
   * Transforms the GstCaps model to a string
   * @returns {string} A string representation of the GstCaps
   */
  toString () {
    return this.toArray().join(', ')
  }
}

export default Capabilities

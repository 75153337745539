/**
 * @classdesc Menu item used to generate custom menus from a JSON configuration
 * @memberof models
 */
class MenuItem {
  /**
   * Instantiates a new Menu item with configurable properties
   * @param {string} name - Name of the menu, used as label
   * @param {string} quiddity - Represents a switcher quiddity, used for action mapping
   * @param {string[]} categories - Category of the quiddity, used for action mapping
   * @param {boolean} [exclusive=false] - Property which makes the menu hidden when the mapped quiddity is created
   * @param {boolean} [created=false] - Property which is updated when the mapped quiddity is created
   * @param {boolean} [hidden=false] - Menu property which makes this item hidden by default
   */
  constructor (name, quiddity, categories = [], exclusive = false, created = false, hidden = false) {
    if (typeof name === 'undefined') {
      throw new TypeError('Attribute name is required and must be a string')
    }

    if (typeof quiddity === 'undefined') {
      throw new TypeError('Attribute quiddity is required and must be a string')
    }

    this.name = name
    this.quiddity = quiddity
    this.categories = categories
    this.exclusive = exclusive
    this.created = created
    this.hidden = hidden
  }

  /** @property {string} id - Id of the menu */
  get id () {
    return this.quiddity
  }

  /** @property {string} itemKey - Unique key for an item */
  get itemKey () {
    return `${this.quiddity}-menu-item`
  }

  /**
   * @property {string} kindId - Explicit reference between the menu quiddity and a quiddity kindId
   * It is useless but it dissipates all doubts.
   */
  get kindId () {
    return this.quiddity
  }

  /**
   * Parses a JSON element to a new MenuItem
   * @static
   * @param {Object} json - JSON object which is parsed to a MenuItem
   * @param {string} json.name - Name of the menu
   * @param {string} json.quiddity - Represents a switcher quiddity, used for action mapping
   * @param {string[]} [json.categories=[]] - Categories of the quiddity
   * @param {boolean} [json.exclusive=false] - Property which makes the menu hidden when the mapped quiddity is created
   * @param {boolean} [json.created=false] - Property which is updated when the mapped quiddity is created
   * @param {boolean} [json.hidden=false] - Menu property which makes this item hidden by default
   * @returns {MenuItem} A new MenuItem which matches with the JSON properties
   */
  static fromJSON ({ name, quiddity, categories = [], exclusive = false, created = false, hidden = false }) {
    return new MenuItem(name, quiddity, categories, exclusive, created, hidden)
  }

  /**
   * Shows the menu item only if the menu is `showable`
   * @returns {?MenuItem} If the menu is `showable` it returns `this`, else it returns `null`
   */
  show () {
    return this.showable ? this : null
  }

  /**
   * Updates the menu model when a quiddity state has changed
   * @param {Object} properties - Set of updated properties
   * @param {boolean} [properties.created=false] - Updated creation status of the quiddity
   * @param {boolean} [properties.exclusive=false] - Updated exclusive property of the quiddity
   */
  updateItem (quidId, properties = { created: false }) {
    if (this.quiddity === quidId) {
      for (const key in properties) {
        if (typeof this[key] !== 'undefined') {
          this[key] = properties[key]
        }
      }
    }
  }

  /** @property {string} showable - Flag which notifies if the menu should be displayed or not */
  get showable () {
    return !this.hidden && (!this.exclusive || !this.created)
  }
}

export default MenuItem

import React from 'react'

import CredentialField from '@components/fields/CredentialField'
import BooleanField from '@components/fields/BooleanField'

/**
 * Field used to input the SIP server address
 * @memberof module:components/fields
 * @property {object} credentialProps - All properties accepted by a CredentialField
 * @returns {module:components/fields.CredentialField} A configured credential field
 */
function SipServerField (credentialProps) {
  return (
    <CredentialField
      id='sipServer'
      title='SIP server'
      description="Set the SIP server's address"
      placeholder='Server address'
      {...credentialProps}
    />
  )
}

/**
 * Field used to input the SIP user
 * @memberof module:components/fields
 * @property {object} credentialProps - All properties accepted by a CredentialField
 * @returns {module:components/fields.CredentialField} A configured credential field
 */
function SipUserField (credentialProps) {
  return (
    <CredentialField
      id='sipUser'
      title='SIP user name'
      description='Set your SIP user name'
      placeholder='User name'
      {...credentialProps}
    />
  )
}

/**
 * Field used to input the SIP password
 * @memberof module:components/fields
 * @property {object} credentialProps - All properties accepted by a CredentialField
 * @returns {module:components/fields.CredentialField} A configured credential field
 */
function SipPasswordField (credentialProps) {
  return (
    <CredentialField
      id='sipPassword'
      title='SIP password'
      description='Set your SIP password'
      placeholder='Password'
      isPassword
      {...credentialProps}
    />
  )
}

/**
 * Field used to input the SIP destination port
 * @memberof module:components/fields
 * @property {object} credentialProps - All properties accepted by a CredentialField
 * @returns {module:components/fields.CredentialField} A configured credential field
 */
function SipDestinationPortField (credentialProps) {
  return (
    <CredentialField
      id='sipDestinationPort'
      title='Destination Port'
      description="Set the SIP server's destination port"
      placeholder='Port number'
      {...credentialProps}
    />
  )
}

/**
 * Field used to input the SIP port
 * @memberof module:components/fields
 * @property {object} credentialProps - All properties accepted by a CredentialField
 * @returns {module:components/fields.CredentialField} A configured credential field
 */
function SipPortField (credentialProps) {
  return (
    <CredentialField
      id='sipPort'
      title='Source Port'
      description="Set the SIP server's source port"
      placeholder='Port number'
      {...credentialProps}
    />
  )
}

/**
 * Field used to input the TURN server address
 * @memberof module:components/fields
 * @property {object} credentialProps - All properties accepted by a CredentialField
 * @returns {module:components/fields.CredentialField} A configured credential field
 */
function TurnServerField (credentialProps) {
  return (
    <CredentialField
      id='turnServer'
      title='TURN server'
      description="Set the TURN server's address"
      placeholder='Server address'
      {...credentialProps}
    />
  )
}

/**
 * Field used to input the TURN user
 * @memberof module:components/fields
 * @property {object} credentialProps - All properties accepted by a CredentialField
 * @returns {module:components/fields.CredentialField} A configured credential field
 */
function TurnUserField (credentialProps) {
  return (
    <CredentialField
      id='turnUser'
      title='TURN user name'
      description='Set your TURN user name'
      placeholder='User name'
      {...credentialProps}
    />
  )
}

/**
 * Field used to input the TURN password
 * @memberof module:components/fields
 * @property {object} credentialProps - All properties accepted by a CredentialField
 * @returns {module:components/fields.CredentialField} A configured credential field
 */
function TurnPasswordField (credentialProps) {
  return (
    <CredentialField
      id='turnPassword'
      title='TURN password'
      description='Set your TURN password'
      placeholder='Password'
      {...credentialProps}
      isPassword
    />
  )
}

/**
 * Field used to input the STUN server address
 * @memberof module:components/fields
 * @property {object} credentialProps - All properties accepted by a CredentialField
 * @returns {module:components/fields.CredentialField} A configured credential field
 */
function StunServerField (credentialProps) {
  return (
    <CredentialField
      id='stunServer'
      title='STUN server'
      description="Set the STUN server's address"
      placeholder='Server address'
      {...credentialProps}
    />
  )
}

/**
 * Renders the switch that enables the login forms for the STUN/TURN servers
 * @memberof module:components/fields
 * @property {boolean} isSameStunTurnLogin - Flag if the STUN/TURN credentials are the same as SIP
 * @property {function} onToggle - Function triggered when the user clicks on the switch
 * @returns {module:components/fields.CredentialField} A configured credential field
 */
function SameStunTurnLoginField ({ isSameStunTurnLogin, onToggle }) {
  return (
    <BooleanField
      id='isSameStunTurnLogin'
      title='Same login for STUN/TURN server?'
      description='Reuse the SIP credentials for the STUN/TURN server'
      onChange={onToggle}
      value={isSameStunTurnLogin}
    />
  )
}

export {
  SipServerField,
  SipUserField,
  SipPasswordField,
  SipPortField,
  TurnServerField,
  TurnUserField,
  TurnPasswordField,
  StunServerField,
  SameStunTurnLoginField,
  SipDestinationPortField
}

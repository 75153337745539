/**
 * @classdesc Mother class for all API classes
 * @memberof module:api
 */
class API {
  /**
   * Instantiates API with a socket
   * @param {external:socketIO/Socket} socket - The shared socket used to call Switcher
   */
  constructor (socket) {
    /** @property {external:socketIO/Socket} socket - Connected websocket with Switcher (Scenic Core) */
    this.socket = socket
  }

  /**
   * Helper to match a parameter with a string or a function
   * @param {string} parameter - The parameter to match
   * @param {(string|function)} [matcher] - Used to validate the parameter, the string will match equality
   * @returns {boolean}
   */
  match (parameter, matcher) {
    if (typeof matcher === 'string' && parameter !== matcher) {
      return false
    } else if (typeof matcher === 'function' && !matcher(parameter)) {
      return false
    } else {
      return true
    }
  }
}

export default API

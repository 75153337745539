import React, { useContext } from 'react'
import classNames from 'classnames'

import { Context } from '@sat-mtl/ui-components'

import '@styles/matrix/SourceSeparator.scss'

const { ThemeContext } = Context

/**
 * Renders the UI separator between matrix columns
 * @param {object} style - Custom style of the separator
 * @param {string} header - Custom header at the top of the separator
 * @returns {external:react/Component} The rendered separator
 */
function SourceSeparator ({ style, header }) {
  const theme = useContext(ThemeContext)

  const cl = classNames(
    'SourceSeparator',
    `SourceSeparator-${theme}`
  )

  return (
    <div className={cl} style={style}>
      <div className='SeparatorHeader'>
        {header}
      </div>
    </div>
  )
}

export default SourceSeparator

export {
  SourceSeparator
}

import Ajv from 'ajv'
import sceneSchema from '@models/schemas/scene.schema.json'

/**
 * @constant {string} DEFAULT_SCENE_ID - Sets the default ID for the default scene
 * @memberof stores.SceneStore
 */
export const DEFAULT_SCENE_ID = 'defaultScene'

/**
 * @classdesc Scene model used to display switcher `userTree`
 * @memberof models
 */
class Scene {
  constructor (id, name, active = false, connections = []) {
    if (typeof id !== 'string') {
      throw new TypeError('Attribute id is required and must be a string')
    } else {
      /** @property {string} id - ID of the Scene */
      this.id = id
    }

    /** @property {string} [name=id] - Name of the Scene */
    this.name = name || this.id
    /** @property {boolean} [active=false] - Flag to set the scene active */
    this.active = active
    /** @property {string[]} [connections=[]] - References to connections */
    this.connections = connections
  }

  get isActive () {
    return this.active
  }

  /**
   * Gets the index of the Scene
   * @returns {number} The index of the scene or -1 if the scene has no index
   */
  get index () {
    let index = this.id.replace(/^\D+/g, '')

    if (index) {
      index = parseInt(index)
    } else if (this.id === DEFAULT_SCENE_ID) {
      index = 0
    } else {
      index = -1
    }

    return index
  }

  get branch () {
    return `.scenes.${this.id}`
  }

  /**
   * Parses a JSON element to a Scene model
   * @static
   * @param {JSON} json - The JSON data to parse
   * @return {Scene}
   */
  static fromJSON (json) {
    const ajv = new Ajv()
    const isValid = ajv.validate(sceneSchema, json)

    if (isValid) {
      const { id, name, active, connections } = json
      return new Scene(id, name, active, connections)
    } else {
      throw new TypeError(ajv.errorsText())
    }
  }

  static fromUserTree (json) {
    const scenes = []

    if (json.scenes) {
      Object.values(json.scenes)
        .forEach(obj => scenes.push(Scene.fromJSON(obj)))
    }

    return scenes
  }

  /**
   * Transforms the current model to JSON
   * @returns {JSON}
   */
  toJSON () {
    const { id, name, active, connections } = this
    return { id, name, active, connections }
  }

  /**
   * Create a scene from a given index (ex: 1 gives `scene1`)
   * @static
   * @param {number} index - The index of the scene
   * @return {Scene}
   */
  static fromIndex (index) {
    return new Scene(`scene${index}`, `Scene ${index}`)
  }
}

export default Scene

import React from 'react'
import { Trans } from 'react-i18next'

import StatusEnum from '@models/common/StatusEnum'

import { CancelButton, ConfirmButton } from '@components/common/Buttons'
import { Feedback, Layout } from '@sat-mtl/ui-components'

const { FlexRow } = Layout
const { Modal } = Feedback

/**
 * Warns the user that unsaved changes will be lost
 * @private
 * @returns {React.PureComponent} A warning content
 */
function LostUnsavedChangesWarning () {
  return (
    <Trans>
      Unsaved changes will be lost if you continue.
    </Trans>
  )
}

/**
 * Warns the user that the saved changes will be lost
 * @private
 * @returns {React.PureComponent} A warning content
 */
function LostSavedChangesWarning () {
  return (
    <Trans>
      The current session will be lost if you delete this file.
    </Trans>
  )
}

/**
 * @constant {string} OPEN_SESSION_MODAL_ID - ID of the open session modal
 * @memberof OpenSessionModal
 */
export const OPEN_SESSION_MODAL_ID = 'OpenSessionModal'

/**
 * Scenic modal used to open a new session
 * @param {boolean} props.visible - Flags if the modal is visible
 * @param {Function} props.onCancel - Function triggered when the user cancels the session action
 * @param {Function} props.onConfirm - Function triggered when the user confirms the session action
 * @returns {React.PureComponent} A modal
 */
export function OpenSessionModal ({ visible, onCancel, onConfirm }) {
  return (
    <Modal
      id={OPEN_SESSION_MODAL_ID}
      visible={visible}
      header={(
        <Trans>
          Are you sure you wish to discard changes to the current session?
        </Trans>
      )}
      footer={(
        <FlexRow justifyContent='space-around'>
          <CancelButton onCancel={onCancel} />
          <ConfirmButton status='primary' onConfirm={onConfirm}>
            <Trans>
              Yes
            </Trans>
          </ConfirmButton>
        </FlexRow>
      )}
    />
  )
}

/**
 * @constant {string} RESET_SESSION_MODAL_ID - ID of the reset session modal
 * @memberof ResetSessionModal
 */
export const RESET_SESSION_MODAL_ID = 'ResetSessionModal'

/**
 * Scenic modal used to reset the current session
 * @param {boolean} props.visible - Flags if the modal is visible
 * @param {Function} props.onCancel - Function triggered when the user cancels the session reset
 * @param {Function} props.onReset - Function triggered when the user resets the current session
 * @returns {React.PureComponent} A modal
 */
export function ResetSessionModal ({ visible, onCancel, onReset }) {
  return (
    <Modal
      id={RESET_SESSION_MODAL_ID}
      visible={visible}
      header={(
        <Trans>
          Reset the current session?
        </Trans>
      )}
      footer={(
        <FlexRow justifyContent='space-around'>
          <CancelButton onCancel={onCancel} />
          <ConfirmButton status={StatusEnum.DANGER} onConfirm={onReset}>
            <Trans>
              Reset
            </Trans>
          </ConfirmButton>
        </FlexRow>
      )}
    >
      <LostUnsavedChangesWarning />
    </Modal>
  )
}

/**
 * @constant {string} TRASH_CURRENT_SESSION_MODAL_ID - ID of the trash current file modal
 * @memberof TrashCurrentFileModal
 */
export const TRASH_CURRENT_SESSION_MODAL_ID = 'TrashCurrentSessionModal'

/**
 * Renders the modal that alerts the deletion of current session's file
 * @param {boolean} props.visible - Flags if the current session's file is trashed
 * @param {Function} props.onCancel - Function triggered when the action is canceled
 * @param {Function} props.onConfirm - Function triggered when the user tashes the current file
 * @returns {React.PureComponent} The alert modal
 */
export function TrashCurrentSessionModal ({ visible, onCancel, onConfirm }) {
  return (
    <Modal
      id={TRASH_CURRENT_SESSION_MODAL_ID}
      visible={visible}
      header={(
        <Trans>
          Select the current session's file?
        </Trans>
      )}
      footer={(
        <FlexRow justifyContent='space-around'>
          <CancelButton onCancel={onCancel} />
          <ConfirmButton status={StatusEnum.DANGER} onConfirm={onConfirm}>
            <Trans>
              Select
            </Trans>
          </ConfirmButton>
        </FlexRow>
      )}
    >
      <LostSavedChangesWarning />
    </Modal>
  )
}

/**
 * Enum for all page ids
 * @readonly
 * @memberof models
 * @enum {string}
 */
const PageEnum = Object.freeze({
  MATRIX: 'matrix',
  /* LIVE: 'live', */
  SETTINGS: 'settings',
  HELP: 'help'
})

/**
 * Gets the icon type of a page
 * @param {string} pageId - ID of the page
 * @returns {string} Type of the icon's page
 */
function toPageIcon (pageId) {
  switch (pageId) {
    case PageEnum.MATRIX:
      return 'matrix'

      /* case PageEnum.LIVE: */
      /* return 'switcher' */

    case PageEnum.SETTINGS:
      return 'settings'

    case PageEnum.HELP:
      return 'info'

    default:
      return 'info'
  }
}

export default PageEnum

export { toPageIcon }

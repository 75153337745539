import { observable, action, computed, makeObservable } from 'mobx'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import SipStore from '@stores/sip/SipStore'
import MatrixEntry from '@models/matrix/MatrixEntry'
import { logger } from '@utils/logger'
import { SIP_KIND_ID } from '@models/quiddity/specialQuiddities'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the LockStore
 * @memberof module:stores/quiddity.LockStore
 */
export const LOG = logger.child({ store: 'LockStore' })

/**
 * @classdesc Stores all locked quiddities
 * @memberof module:stores/quiddity
 */
class LockStore {
  /** @property {Map<string, module:models/matrix.MatrixEntry>} lockedEntries - All locked matrix entries by entry IDs */
  lockedEntries = new Map()

  /** @property {Set<string>} lockableKindIds - All lockable kind IDs */
  lockableKindIds = new Set()

  /**
   * Instantiates a new LockStore
   * @param {module:stores/quiddity.QuiddityStore} quiddityStore - Quiddity manager
   * @constructor
   */
  constructor (quiddityStore, sipStore) {
    makeObservable(this, {
      lockedEntries: observable,
      lockableKindIds: observable,
      lockableQuiddities: computed,
      lockedQuiddities: computed,
      lockedContacts: computed,
      addLockableKind: action,
      deleteLockableKind: action,
      addLock: action,
      deleteLock: action
    })

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new TypeError('LockStore requires a QuiddityStore')
    }

    if (sipStore instanceof SipStore) {
      this.sipStore = sipStore
    } else {
      throw new TypeError('LockStore requires a SipStore')
    }
  }

  /** @property {Set<string>} lockableQuiddities - Set of quiddity IDs that shouldn't be synchronized with scenes */
  get lockableQuiddities () {
    const lockableQuiddities = new Set()

    for (const quiddityId of this.quiddityStore.quiddityIds) {
      if (this.isLockableQuiddity(quiddityId)) {
        lockableQuiddities.add(quiddityId)
      }
    }

    return lockableQuiddities
  }

  /**
   * @property {Set<string>} lockedQuiddities - Set of quiddity IDs that are locked
   * Warning: It won't work with the SIP quiddity
   */
  get lockedQuiddities () {
    const lockedQuiddities = new Set()

    for (const [, entry] of this.lockedEntries) {
      if (entry.quiddityId !== this.sipStore.sipId) {
        lockedQuiddities.add(entry.quiddityId)
      }
    }

    return lockedQuiddities
  }

  /** @property {Set<Contact>} lockedContacts - Set of contact names that are locked */
  get lockedContacts () {
    const lockedContacts = new Set()

    for (const [, entry] of this.lockedEntries) {
      if (entry.contactName) {
        lockedContacts.add(entry.contactName)
      }
    }

    return lockedContacts
  }

  /**
   * Registers a lockable quiddity kind ID
   * @param {string} kindId - A quiddity kind ID
   * @mermaid
   * sequenceDiagram
   *    User->>QuiddityStore: start Scenic
   *    activate QuiddityStore
   *    QuiddityStore->>QuiddityStore: discover classes
   *    QuiddityStore->>RtmpStore: react to class discovery
   *    activate RtmpStore
   *    deactivate QuiddityStore
   *    RtmpStore-->>LockStore: signal the RTMP class as lockable
   *    activate LockStore
   *    deactivate RtmpStore
   *    LockStore->>LockStore: update lockable classes
   *    deactivate LockStore
   *    User->>ConnectionStore: add a scene
   *    activate ConnectionStore
   *    ConnectionStore->>LockStore: inspect lockable quiddities
   *    activate LockStore
   *    LockStore-->>ConnectionStore: return lockable classes
   *    deactivate LockStore
   *    ConnectionStore->>ConnectionStore: get all connections from lockable quiddities
   *    ConnectionStore-->>User: Fix the assignation of the connections
   *    deactivate ConnectionStore
   */
  addLockableKind (kindId) {
    this.lockableKindIds.add(kindId)

    LOG.debug({
      msg: `The kind ${kindId} is registered as lockable`,
      kindId: kindId
    })
  }

  /**
   * Unregisters a lockable quiddity kind
   * @param {string} kindId - A quiddity kind
   */
  deleteLockableKind (kindId) {
    this.lockableKindIds.delete(kindId)

    LOG.debug({
      msg: `The class ${kindId} is unregistered as lockable`,
      kindId: kindId
    })
  }

  /**
   * Checks if a quiddity can be locked
   * @param {string} quiddityId - ID of the quiddity
   * @returns {boolean} Returns true if a quiddity is lockable
   */
  isLockableQuiddity (quiddityId) {
    let isLockable = false
    const quiddity = this.quiddityStore.quiddities.get(quiddityId)

    if (quiddity) {
      isLockable = this.lockableKindIds.has(quiddity.kindId)
    }

    return isLockable
  }

  /**
   * Checks if a connection is lockable by one of its quiddity
   * @param {module:models/userTree.Connection} connection - A connection model
   * @returns {boolean} Returns true if a connection is lockable
   */
  isLockableConnection (connection) {
    return this.isLockableQuiddity(connection.sourceId) ||
           this.isLockableQuiddity(connection.destinationId)
  }

  /**
   * Checks if a connection is locked
   * @param {module:models/userTree.Connection} connection - A connection model
   * @returns {boolan} Returns true if the connection is locked
   */
  isLockedConnection (connection) {
    // When a sip call is ended, it can take a bit of time for the userConnections map to update its connection. If
    // a connection exists but the quiddities are no longer in the quiddity store, the connection is invalid so we ignore it.
    if (!this.quiddityStore.quiddities.has(connection.sourceId) || !this.quiddityStore.quiddities.has(connection.destinationId)) {
      return false
    }
    // otherwise, if the lockstore has one of the component of the connection, it is a locked connection.
    return this.lockedQuiddities.has(connection.sourceId) ||
           this.lockedQuiddities.has(connection.destinationId) ||
           this.lockedContacts.has(connection.contactId)
  }

  /**
   * Registers a locked quiddity ID
   * @param {module:models/matrix.MatrixEntry} matrixEntry - A matrix entry
   */
  addLock (matrixEntry) {
    this.lockedEntries.set(matrixEntry.id, matrixEntry)

    LOG.info({
      msg: `The entry ${matrixEntry.id} is locked`,
      matrixId: matrixEntry.id
    })
  }

  /**
   * Clears the lockedEntries. Used on a session reset.
   */
  clear () {
    this.lockedEntries.clear()
  }

  /**
   * Unregisters a locked quiddity ID
   * @param {module:models/matrix.MatrixEntry} matrixEntry - A matrix entry
   */
  deleteLock (matrixEntry) {
    this.lockedEntries.delete(matrixEntry.id)

    LOG.info({
      msg: `The entry ${matrixEntry.id} is unlocked`,
      matrixId: matrixEntry.id
    })
  }

  getLockableReaders (matrixEntry) {
    let readers = []

    if (matrixEntry.contact) {
      readers = matrixEntry.contact.connections
    } else {
      const entryQuiddity = this.quiddityStore.quiddities.get(matrixEntry.id)
      const readersObject = entryQuiddity.infoTree?.shmdata?.reader
      if (readersObject) {
        readers = Object.keys(readersObject)
      }
    }
    return readers
  }

  /**
   * Creates and delete lock entries for the passed matrixEntry depending
   * on the desired lockStatus. This will also recursively lock or unlock all connected entries which are themselves
   * destinations.
   * @param {module:models/matrix.MatrixEntry} matrixEntry - A matrix entry
   * @param {boolean} [lockStatus=false] - Flags a locked quiddity
   */
  setLock (matrixEntry, lockStatus) {
    if (this.lockedEntries.has(matrixEntry.id) === lockStatus) {
      // if we want the entry to be locked and it is in the locked entries
      // or if we want the entry to be unlocked and it is not in the locked entries */
      // there is nothing to do.
      return
    }

    // we need to fetch the quiddity from the quiddity store because otherwise the properties of the quiddity
    // are not guaranteed to be up to date
    const readers = this.getLockableReaders(matrixEntry)
    // The lock mechanism is designed in a way that makes it so we only want to lock destinations.
    // sources connected to the destinations are marked as being part of a locked connection.
    const followerSpecs = matrixEntry.quiddity?.connectionSpecs?.follower
    if (readers.length === 0 && (followerSpecs === undefined || followerSpecs.length === 0)) {
      return
    }

    // this next bit recursively calls setLock on all the quiddities connected to our matrix entry that
    // are also destinations. This is to prevent inderection from breaking the lock mechanism.
    // Example : We put a raw video in a video encoder and then connect the output of that video encoder
    // to a sip contact and we then call. If we didn't recursively lock sources that are also destinations, it
    // would be possible to connect something else in the destination part of the encoder while it is locked.

    // go find all the quiddities except the sip quiddity.
    // We exclude the sip quiddity because even though it exposes the same shmpaths as the extshmsrcs
    // associated to sip sources, we never directly connect it to anything and we don't want the lockstore
    // to create a lock for it.
    const quiddities = Array.from(this.quiddityStore.quiddities.values()).filter(quid => quid.kindId !== SIP_KIND_ID)
    // for every shmpath we read
    readers.forEach((shmpath) => {
      // get a list of the quiddities that have that path in its writers
      quiddities.filter((quid) => {
        // writers can be an object or undefined so we default it to the empty object
        const writerShmpaths = quid.infoTree?.shmdata?.writer || {}
        // if our read shmpath is in the writers of the quiddity, we need to call setLock on that quiddity.
        return Object.keys(writerShmpaths).filter(writerShmpath => writerShmpath === shmpath).length > 0
      }).map(
        // create a list of MatrixEntries from those quiddities
        quid => new MatrixEntry(quid)
      ).forEach(
        // recursively call this function with the right lockStatus for every other connected quiddity.
        matrixEntry => this.setLock(matrixEntry, lockStatus)
      )
    })

    if (lockStatus) {
      this.addLock(matrixEntry)
    } else if (!lockStatus) {
      this.deleteLock(matrixEntry)
    }
  }
}

export default LockStore

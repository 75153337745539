/**
 * @classdesc Model for monitoring the status of a shmdata
 * @memberof models
 */
class Stat {
  /**
   * Instantiates a new Stat model
   * @param {number} byteRate - Number of bytes in the shmdata by second (rate * size of a shmdata frame)
   * @param {number} rate - Number of data frames in the shmdata by second
   */
  constructor (byteRate, rate) {
    if (typeof byteRate === 'undefined' || typeof byteRate !== 'number') {
      throw new TypeError('Attribute `byteRate` is required and must be a number')
    } else if (typeof rate === 'undefined' || typeof rate !== 'number') {
      throw new TypeError('Attribute `rate` is required and must be a number')
    }

    this.byteRate = byteRate
    this.rate = rate
  }

  /** @property {number} bitRate - Number of bits in the shmdata by second */
  get bitRate () {
    return this.byteRate * 8
  }

  /** @property {number} mbpsRate - Number of megabits in the shmdata by second */
  get mbpsRate () {
    return this.bitRate / Math.pow(10, 6)
  }

  /** @property {string} mbpsLabel - Label for the Shmdata tooltip */
  get mbpsLabel () {
    return `${this.mbpsRate} Mbps`
  }

  /** @property {boolean} isActive - Status of the shmdata  */
  get isActive () {
    return this.byteRate > 0
  }

  /* eslint-disable camelcase */
  static fromJSON (json) {
    const { byte_rate, rate } = json
    let { byteRate } = json

    if (typeof byteRate === 'undefined') {
      byteRate = byte_rate
    }

    return new Stat(byteRate, rate)
  }
  /* eslint-enable camelcase */
}

export default Stat

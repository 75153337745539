import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import HelpWrapper from '@components/wrappers/HelpWrapper'

import { Inputs } from '@sat-mtl/ui-components'

const { Field, Select } = Inputs

/**
 * Field component that lets users select an option
 * @memberof module:components/fields
 * @param {string} title - Title of the field
 * @param {Object} option - Value of the property
 * @param {Object[]} options - All selectable options of the field
 * @param {boolean} disabled - Flags a disabled value
 * @param {function} onChange - Function triggered when the property is changed by the user
 * @param {string} description - Description of the field
 * @param {string} status - Status of the form
 * @returns {external:ui-components/Inputs/Field} A field component
 */
function SelectionField ({
  title,
  option,
  options,
  disabled,
  onChange,
  description,
  status
}) {
  const { t } = useTranslation()
  const [selected, setSelection] = useState({
    ...option,
    label: t(option.label)
  })

  const defaultLabel = t('Select value for "{{title}}" property', {
    title: t(title)
  })
  // this can happen if the change to the selection was not made directly by the user
  if (selected !== option) {
    setSelection(option)
  }
  return (
    <div className='SelectionField'>
      <HelpWrapper message={description ? t(description) : defaultLabel}>
        <Field
          status={status}
          disabled={disabled}
          title={t(title)}
          description={description ? t(description) : defaultLabel}
        >
          <Select
            options={options.map(option => ({
              ...option,
              label: t(option.label)
            }))}
            selected={selected}
            onSelection={label => {
              const newSelection = options.find(opt => t(opt.label) === label)

              if (newSelection) {
                onChange(newSelection.value)
                setSelection({
                  label: t(newSelection.label),
                  id: newSelection.value
                })
              }
            }}
          />
        </Field>
      </HelpWrapper>
    </div>
  )
}

export default SelectionField

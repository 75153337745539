import API from '@api/API'

/**
 * @classdesc API for managing image file operations
 * @memberof module:api
 * @extends module:api.API
 */
class ImageAPI extends API {
  /**
   * Asks to Reads an image file
   * @param {string} imageFilePath - Full path of image file to read
   * @returns {Promise} A promise that returns the binary image file content
   */
  readImage (imageFilePath) {
    return new Promise((resolve, reject) => {
      this.socket.emit('image_read', imageFilePath, (error, success) => {
        if (error) {
          reject(error)
        } else {
          resolve(success)
        }
      })
    })
  }
}

export default ImageAPI

import React, { useState, useContext, useEffect } from 'react'
import Mousetrap from 'mousetrap'
import { observer } from 'mobx-react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import StatusEnum from '@models/common/StatusEnum'

import { DRAWER_WIDTH } from '@stores/common/DrawerStore'
import FileEntry from '@components/entries/FileEntry'
import { AppStoresContext } from '@components/App'

import HelpWrapper from '@components/wrappers/HelpWrapper'
import { OpenSessionModal, OPEN_SESSION_MODAL_ID } from '@components/modals/SessionModals'
import { UploadButton, DownloadButton } from '@components/common/Buttons'
import { Feedback, Layout, Common, Inputs } from '@sat-mtl/ui-components'

import '@styles/drawers/FileDrawers.scss'

const { FlexRow } = Layout
const { Drawer } = Feedback
const { Button } = Common
const { Checkbox } = Inputs

/**
 * @constant {string} FILE_LOADING_DRAWER_ID - ID of the file loading drawer
 * @memberof module:components/drawers.FileLoadingDrawer
 */
const FILE_LOADING_DRAWER_ID = 'FileLoadingDrawer'

/**
 * Renders a file entry
 * @memberof module:components/drawers.FileLoadingDrawer
 * @param {module:models/common.FileBridge} file - A file model
 * @param {Function} onOpen - Function triggered when a file is opened
 * @param {boolean} isCurrent - Flag if this is the current session's file
 * @param {boolean} isSelected - Flag a selected entry
 * @returns {external:react/Component} The entry
 */
function FileLoadingEntry ({ file, onOpen, isCurrent, isSelected }) {
  const { t } = useTranslation()

  const help = t('Select {{name}}', { name: file.name })
  const onEntryClick = () => onOpen(file)

  const classes = classNames(
    'FileDeletionEntry',
    { 'FileDeletionEntry-selected': isSelected }
  )

  return (
    <HelpWrapper message={help}>
      <li className={classes}>
        <FileEntry
          file={file}
          isCurrent={isCurrent}
          description={help}
          onClick={onEntryClick}
          status={isCurrent ? StatusEnum.FOCUS : StatusEnum.INACTIVE}
          selected={isSelected}
        />
        <Checkbox
          checked={isSelected}
          status={StatusEnum.FOCUS}
          onChange={onEntryClick}
          shape='circle'
        />
      </li>
    </HelpWrapper>
  )
}

/**
 * Renders a a list of file entries
 * @memberof module:components/drawers.FileLoadingDrawer
 * @param {module:models/common.FileBridge[]} files - Array of available files
 * @param {Function} onOpen - Function triggered when a file is opened
 * @param {string} currentSessionId - ID of the current session
 * @param  {string} selectedFileId - ID of the selected file
 * @returns {external:react/Component} The drawer's content
 */
function FileLoadingList ({ files, onOpen, currentSessionId, selectedFileId }) {
  const { t } = useTranslation()
  let $files = t('No sessions found')

  if (files.length > 0) {
    $files = (
      <ul>
        {files.map(f => (
          <FileLoadingEntry
            key={f.id}
            isCurrent={f.id === currentSessionId}
            isSelected={f.id === selectedFileId}
            file={f}
            onOpen={onOpen}
          />
        ))}
      </ul>
    )
  }

  return $files
}

/**
 * Renders a button that opens a session file
 * @memberof module:components/drawers.FileLoadingDrawer
 * @param {module:models/common.FileBridge} selectedFile - The current selected file
 * @param {Function} onOpen - Function triggered when the button is clicked
 * @returns {external:react/Component} A button
 */
function OpenFileButton ({ selectedFile, onOpen }) {
  const { t } = useTranslation()
  let help = t('No file is selected')

  if (selectedFile) {
    help = t('Open {{name}} session file', { name: selectedFile.name })
  }

  return (
    <HelpWrapper message={help}>
      <Button className='OpenFileButton' shape='rectangle' onClick={onOpen} disabled={!selectedFile}>
        {t('Open')}
      </Button>
    </HelpWrapper>
  )
}

/**
 * Renders the local file button
 * @memberof module:components/drawers.FileLoadingDrawer
 * @param {Function} onImport - Function triggered when the user loads a new file
 * @todo Requires missing APIs and security analysis in order to add this feature in Scenic > 4.1
 * @see https://gitlab.com/sat-mtl/tools/scenic/scenic/-/issues/298
 * @returns {external:react/Component} The local file button
 */
function OpenLocalFileButton ({ onImport }) {
  const { t } = useTranslation()
  const help = t('Open a session from a local file')

  return (
    <HelpWrapper message={help}>
      <UploadButton onUpload={onImport}>
        {t('Local file')}
      </UploadButton>
    </HelpWrapper>
  )
}

/**
 * Drawer that lets the user load sessions from files
 * This component observes the stores SessionStore, ModalStore
 * It also observes the store DrawerStore as a React.Context
 * @memberof module:components/drawers
 * @returns {external:mobx-react/ObserverComponent} The file loading drawer
 */
const FileLoadingDrawer = observer(() => {
  const { t } = useTranslation()
  const { sessionStore, modalStore, drawerStore, matrixStore } = useContext(AppStoresContext)
  const [selectedFile, selectFile] = useState(null)

  useEffect(() => {
    drawerStore.addDrawer(FILE_LOADING_DRAWER_ID)
    modalStore.addModal(OPEN_SESSION_MODAL_ID)

    Mousetrap.bind('ctrl+o', event => {
      event.preventDefault && event.preventDefault()
      drawerStore.toggleActiveDrawer(FILE_LOADING_DRAWER_ID)
    })

    return () => {
      drawerStore.removeDrawer(FILE_LOADING_DRAWER_ID)
      modalStore.removeModal(OPEN_SESSION_MODAL_ID)
      Mousetrap.unbind('ctrl+o')
    }
  })

  useEffect(() => {
    if (drawerStore.activeDrawer === FILE_LOADING_DRAWER_ID) {
      sessionStore.updateSessionList()
    }

    selectFile(null)
  }, [drawerStore.activeDrawer])

  return (
    <>
      <OpenSessionModal
        visible={modalStore.activeModal === OPEN_SESSION_MODAL_ID}
        onConfirm={() => {
          sessionStore.applySessionLoading(selectedFile)
          modalStore.clearActiveModal()
          drawerStore.clearActiveDrawer()
        }}
        onCancel={() => {
          selectFile(null)
          modalStore.clearActiveModal()
        }}
      />
      <Drawer
        id={FILE_LOADING_DRAWER_ID}
        visible={drawerStore.activeDrawer === FILE_LOADING_DRAWER_ID}
        width={DRAWER_WIDTH}
        onBackdropClick={() => {
          drawerStore.clearActiveDrawer()
        }}
        header={(
          <>
            <h2>
              {t('Open an existing session')}
            </h2>
            <p>
              {t('Select a file to load as a new session')}
            </p>
          </>
        )}
        footer={(
          <FlexRow justifyContent='space-around' alignItems='center'>
            <OpenLocalFileButton
              onImport={blob => sessionStore.applySessionUpload(blob)}
            />
            <DownloadButton
              fileName={selectedFile?.fileName}
              onDownload={$ref => sessionStore.applySessionDownload(selectedFile, $ref)}
            />
            <OpenFileButton
              selectedFile={selectedFile}
              onOpen={() => {
                if (sessionStore.currentSessionId || matrixStore?.matrixEntries.size !== 0) {
                  modalStore.setActiveModal(OPEN_SESSION_MODAL_ID)
                } else {
                  sessionStore.applySessionLoading(selectedFile)
                  drawerStore.clearActiveDrawer(FILE_LOADING_DRAWER_ID)
                }
              }}
            />
          </FlexRow>
        )}
      >
        <FileLoadingList
          files={Array.from(sessionStore.sessions.values())}
          selectedFileId={selectedFile ? selectedFile.id : null}
          currentSessionId={sessionStore.currentSessionId}
          onOpen={f => selectFile(f)}
        />
      </Drawer>
    </>
  )
})

export default FileLoadingDrawer

export {
  FILE_LOADING_DRAWER_ID,
  FileLoadingEntry,
  FileLoadingList,
  OpenFileButton,
  OpenLocalFileButton
}

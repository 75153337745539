import { observable, computed, action, reaction, makeObservable } from 'mobx'

import Property from '@models/quiddity/Property'
import UnitEnum from '@models/common/UnitEnum'

import ConfigStore from '@stores/common/ConfigStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import Store from '@stores/Store'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the PropertyStore
 * @memberof stores.PropertyStore
 */
export const LOG = logger.child({ store: 'PropertyStore' })

/**
 * @constant {RegExp} TIMELAPSE_FILENAME_REGEX - Extract the shmdata path from a Timelapse's last_image property
 * @memberof stores.PropertyStore
 */
export const TIMELAPSE_FILENAME_REGEX = /^(.*)\/(.*)_/

/**
 * @constant {RegExp} PROPERTY_ID_REGEX - Matches prefix and suffix pattern of property IDs
 */
export const PROPERTY_ID_REGEX = /^(?<prefix>\w+)\/(?<suffix>\w+)$/

/**
 * @constant {RegExp} STARTED_PROPERTY_REGEX - Matches all property IDs that represent a `started` property
 * @memberof stores.PropertyStore
 */
export const STARTED_PROPERTY_REGEX = /^.*\/?started$/

/**
 * @constant {string} DEFAULT_PROPERTY_GROUP_ID - ID of the default property group
 * @memberof stores.PropertyStore
 */
export const DEFAULT_PROPERTY_GROUP_ID = 'common'

/**
 * @constant {string} DEFAULT_PROPERTY_GROUP_TITLE - Name of the default property group
 * @memberof PropertyDrawer
 */
export const DEFAULT_PROPERTY_GROUP_TITLE = 'Common Configuration'

/**
 * @constant {Object} PROPERTY_UNIT_MAPPING - Map of the units by quiddity class and property ID
 * @todo Make this map configurable?
 * @memberof PropertyDrawer
 */
export const PROPERTY_UNIT_MAPPING = Object.freeze({
  h264Encoder: {
    'Encoder/bitrate': UnitEnum.KILOBIT_PER_SECOND
  },
  sdiInput1: {
    'SDI1/bitrate': UnitEnum.BIT_PER_SECOND
  },
  sdiInput2: {
    'SDI2/bitrate': UnitEnum.BIT_PER_SECOND
  },
  sdiInput3: {
    'SDI3/bitrate': UnitEnum.BIT_PER_SECOND
  },
  hdmiInput: {
    'HDMI/bitrate': UnitEnum.BIT_PER_SECOND
  },
  webcamInput: {
    'Webcam/bitrate': UnitEnum.BIT_PER_SECOND
  },
  nvencEncoder: {
    'Encoder/bitrate': UnitEnum.BIT_PER_SECOND
  }
})

/**
 * @classdesc Stores all quiddity properties
 * @extends stores.Store
 * @memberof stores
 *
 * Note : Due to how mobx handles dynamic nested objects, this store contains *THREE* sources of truth for every property.
 * property is a map that stores the entire property for a quiddity but only the property's id and existence in the map is usable
 * to trigger react rerenders
 * values is a map that stores the values for every of the property. With this we can trigger react rerenders on value changes.
 * statuses is a map that stores the enabled status of every property. With this we can trigger react rerenders when a property is enabled or disabled
 *
 * please please please try to consistently update all three of these in the code, inconsitencies will cause bugs.
 */
class PropertyStore extends Store {
  /** @property {Map<string, Map<string, models.Property>>} properties - Map of all property models by quiddity IDs and property IDs */
  properties = new Map()

  /**
   * @property {Map<string, Map<string, (string|boolean|number)>>} values - Map of all property values by quiddity IDs and property IDs
   * How to get current values: propertyStore.values.get(quiddityId)?.get(propertyId)
   */
  values = new Map()

  /** @property {Map<string, Map<string, boolean>>} statuses - Map of all properties' activation status by quiddity ID and property ID. A 'false' status means that a property is disabled and cannot be edited */
  statuses = new Map()

  errors = new Map()

  /** @property {Map<string, Map<string, models.Property>>} sortedProperties - All sorted property maps hashed by quiddity ID */
  get sortedProperties () {
    const sorted = new Map()

    for (const [quiddityId, properties] of this.properties) {
      sorted.set(quiddityId, this.sortPropertyMap(properties))
    }

    return sorted
  }

  /** @property {Map<string, module:models/quiddity.Property>} startableProperties - All startable properties hashed by quiddity ID */
  get startableProperties () {
    const startableProperties = new Map()

    for (const [quiddityId] of this.properties) {
      if (this.isStartable(quiddityId)) {
        startableProperties.set(quiddityId, this.isStarted(quiddityId))
      }
    }

    return startableProperties
  }

  /** @property {Set<string>} startedQuiddities - All started quiddity IDs */
  get startedQuiddities () {
    const startedQuiddities = new Set()

    for (const [quiddityId, values] of this.values) {
      const startableProperty = this.getStartableProperty(quiddityId)

      if (values.get(startableProperty?.id)) {
        startedQuiddities.add(quiddityId)
      }
    }

    return startedQuiddities
  }

  /**
   * @property {Map<string, Map<string, Map<string, models.Property>>>} groupedProperties - All grouped properties hashed by quiddity ID, property group ID and property ID
   * All properties are also sorted
   */
  get groupedProperties () {
    const grouped = new Map()

    for (const quiddityId of this.properties.keys()) {
      const properties = new Map()

      for (const group of this.getPropertyGroups(quiddityId)) {
        const children = this.getPropertyChildren(quiddityId, group.id)

        if (children.size > 0) {
          properties.set(group.id, children)
        }
      }

      const leaves = this.getPropertyChildren(quiddityId)

      const isEmptyGroup = leaves.size === 0 ||
        (leaves.size === 1 && this.isStartableProperty([...leaves][0].id))

      if (!isEmptyGroup) {
        properties.set(DEFAULT_PROPERTY_GROUP_ID, leaves)
      }

      if (properties.size > 0) {
        grouped.set(quiddityId, properties)
      }
    }

    return grouped
  }

  /**
   * Instantiates a new PropertyStore
   * @param {stores.SocketStore} socketStore - Stores and manages the current event-driven socket
   * @param {stores.ConfigStore} configStore - Configuration manager
   * @param {stores.QuiddityStore} - Quiddity manager
   * @constructor
   */
  constructor (socketStore, configStore, quiddityStore) {
    super(socketStore)

    makeObservable(this, {
      properties: observable,
      values: observable,
      statuses: observable,
      errors: observable,
      sortedProperties: computed,
      startableProperties: computed,
      startedQuiddities: computed,
      groupedProperties: computed,
      setPropertyValue: action,
      setPropertyStatus: action,
      addProperty: action,
      removeQuiddityEntries: action,
      removeProperty: action,
      addPropertyError: action,
      removePropertyError: action
    })

    if (configStore instanceof ConfigStore) {
      this.configStore = configStore
    } else {
      throw new TypeError('PropertyStore requires a ConfigStore')
    }

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new TypeError('PropertyStore requires a QuiddityStore')
    }

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )

    reaction(
      () => this.quiddityStore.userQuiddityIds,
      quiddityIds => this.handleUpdatedQuiddities(quiddityIds),
      { fireImmediately: true }
    )
  }

  /**
   * Parses all property models from a quiddity tree
   * @param {string} quiddityId - ID of the quiddity
   * @param {models.Quiddity} quiddity - The quiddity model
   * @returns {models.Property[]} - Array of all the quiddity's properties
   */
  makePropertyModels (quiddityId, quiddity) {
    const properties = []
    const tree = quiddity.infoTree

    if (tree && Array.isArray(tree.property)) {
      for (const jsonProperty of tree.property) {
        const property = this.makePropertyModel(quiddityId, jsonProperty)

        if (property) {
          properties.push(property)
        }
      }
    } else {
      LOG.warn({
        msg: 'The fetched quiddity has no tree !',
        quiddity: quiddityId
      })
    }

    return properties
  }

  /**
   * Parse a quiddity tree
   * @param {string} quiddityId - ID of the quiddity
   * @param {object} jsonProperty - Tree of the quiddity
   */
  makePropertyModel (quiddityId, jsonProperty) {
    let model = null

    try {
      model = Property.fromJSON({
        ...jsonProperty,
        unit: this.getPropertyUnit(quiddityId, jsonProperty.id)
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to parse a property',
        property: jsonProperty.id,
        quiddity: jsonProperty.id,
        error: error.message
      })
    }

    return model
  }

  /**
   * Initializes all properties of a quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @async
   */
  async initializeProperties (quiddityId) {
    if (this.properties.has(quiddityId)) return

    try {
      const quiddity = this.quiddityStore.quiddities.get(quiddityId)
      const properties = this.makePropertyModels(quiddityId, quiddity)

      for (const property of properties) {
        this.addProperty(quiddityId, property)

        await this.updatePropertyValue(quiddityId, property)

        if (property.isUndefined() && this.isFalsy(quiddityId, property.id)) {
          this.fallbackUndefinedPropertyValue(quiddityId, property)
        }

        LOG.debug({
          msg: 'Successfully added a new property',
          quiddity: quiddityId,
          property: property.id
        })
      }

      LOG.info({
        msg: 'Successfully added the quiddity\'s properties',
        quiddity: quiddityId
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to initialize properties',
        quiddity: quiddityId,
        error: error.message
      })
    }
  }

  /**
   * Fallbacks a property with an undefined value
   * @param {string} quiddityId - ID of the quiddity
   * @param {models.Property} property - Property to fallback
   * @async
   */
  async fallbackUndefinedPropertyValue (quiddityId, property) {
    if (property.isBoolean()) {
      await this.applyNewPropertyValue(quiddityId, property.id, false)
    } else if (property.isString()) {
      await this.applyNewPropertyValue(quiddityId, property.id, '')
    }

    LOG.warn({
      msg: 'Fallen back a property with an undefined value',
      property: property.id,
      quiddity: quiddityId
    })
  }

  /**
   * Fetches a property's value
   * @param {string} quiddityId - ID of the quiddity to fetch
   * @param {models.property} property - The property object model
   * @returns {(boolean|string|number)} Value of the property
   * @async
   */
  async fetchPropertyValue (quiddityId, property) {
    const { propertyAPI } = this.socketStore.APIs
    let value = null

    try {
      if (property.type !== 'group') {
        const fetchedProperty = await propertyAPI.get(quiddityId, property.id)
        if (typeof fetchedProperty === 'object') {
          value = fetchedProperty.value
        } else {
          value = fetchedProperty
        }
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to fetch the property value',
        quiddity: quiddityId,
        property: property.id
      })
    }

    return value
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    if (socket && this.socketStore.hasActiveAPIs) {
      const { propertyAPI, infoTreeAPI } = this.socketStore.APIs

      infoTreeAPI.onGrafted(
        (quidId, path, value) => this.handleGraftedProperty(quidId, path, value),
        quidId => this.properties.has(quidId),
        path => path.includes('property')
      )

      infoTreeAPI.onPruned(
        (quidId, path, value) => this.handleDeletedProperty(quidId, path),
        quidId => this.properties.has(quidId),
        path => path.includes('property')
      )

      propertyAPI.onUpdated(
        (quidId, propId, value) => this.handleUpdatedProperty(quidId, propId, value),
        quidId => this.quiddityStore.userQuiddityIds.includes(quidId)
      )
    }
  }

  /**
   * Handles the update of a quiddity's property
   * @param {string} quiddityId - ID of the handled quiddity
   * @param {string} propertyId - ID of the handled property
   * @param {(string|number|boolean)} updatedValue - Updated value of the property
   * @async
   */
  async handleUpdatedProperty (quiddityId, propertyId, updatedValue) {
    const property = this.properties.get(quiddityId).get(propertyId)
    await this.updatePropertyValue(quiddityId, property, updatedValue)
  }

  /**
   * Handles the graft of a quiddity's property
   * @param {string} quiddityId - ID of the handled quiddity
   * @param {string} graftedPath - Grafted path of the quiddity's tree
   * @param {string} graftedValue - New value of the grafted tree
   */
  handleGraftedProperty (quiddityId, graftedPath, graftedValue) {
    const [, propertyId, attributeId] = graftedPath.split('.')
    // This handles the specific use case of the update of the "enabled" field of a property.
    if (attributeId === 'enabled') {
      this.setPropertyStatus(quiddityId, propertyId, graftedValue)

      LOG.debug({
        msg: 'A property attribute is grafted',
        quiddity: quiddityId,
        path: graftedPath,
        value: graftedValue
      })
    } else if (graftedPath.split('.').length === 2 && graftedPath.startsWith('property.')) {
      // if an entire property was grafted, we need to do replace the old properties property and value
      // A changed property may have changed a lot so its simpler to throw away and rebuild.
      const prop = this.makePropertyModel(quiddityId, graftedValue)
      this.addProperty(quiddityId, prop)
    }
  }

  /**
   * Handles the deletion of a quiddity's property
   * @param {string} quiddityId - ID of the handled quiddity
   * @param {string} prunedPath - pruned path of the quiddity's tree
   */
  handleDeletedProperty (quiddityId, prunedPath) {
    if (prunedPath.split('.').length === 2 && prunedPath.startsWith('property.')) {
      const propertyId = prunedPath.split('.')[1]
      this.removeProperty(quiddityId, propertyId)
    }
  }

  /**
   * Handles the change of a set of quiddities by initializing the new ones and removing the dead ones
   * @param {string[]} [quiddityIds=[]] - Set of updated quiddities
   * @async
   */
  async handleUpdatedQuiddities (quiddityIds = []) {
    for (const quiddityId of quiddityIds) {
      await this.initializeProperties(quiddityId)
    }

    this.cleanUselessProperties()
  }

  /**
   * Applies a new value for a given property
   * @param {string} quiddityId - ID of the quiddity to update
   * @param {string} propertyId - ID of the property to update
   * @param {(string|number|boolean)} newValue - New value of the property
   * @async
   */
  async applyNewPropertyValue (quiddityId, propertyId, newValue) {
    const { propertyAPI } = this.socketStore.APIs
    this.removePropertyError(quiddityId, propertyId)

    try {
      await propertyAPI.set(quiddityId, propertyId, newValue)

      LOG.debug({
        msg: 'Successfully set a new property value',
        quiddity: quiddityId,
        property: propertyId,
        value: newValue
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to set a new property value',
        quiddity: quiddityId,
        property: propertyId,
        value: newValue,
        error: error.message
      })

      this.addPropertyError(quiddityId, propertyId, error.message)
    }
  }

  /**
   * Resets property with its default value
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} propertyId - ID of the property
   * @async
   */
  async applyPropertyReset (quiddityId, propertyId) {
    if (this.hasProperty(quiddityId, propertyId)) {
      const property = this.properties.get(quiddityId).get(propertyId)

      if (property.isUndefined()) {
        await this.fallbackUndefinedPropertyValue(quiddityId, property)
      } else {
        await this.applyNewPropertyValue(quiddityId, propertyId, property.value)
      }
    }
  }

  /**
   * Applies the default value to all properties of a quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @async
   */
  async applyPropertyResetAll (quiddityId) {
    const properties = this.properties.get(quiddityId)

    try {
      if (properties) {
        for (const [propertyId] of properties) {
          await this.applyPropertyReset(quiddityId, propertyId)
        }
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to reset all property values',
        error: error.message
      })
    }
  }

  /**
   * Updates a property with a new value, if the value is not specified it will be fetched
   * @param {string} quiddityId - ID of the updated quiddity
   * @param {models.property} property - The property object model
   * @param {(string|number|boolean)} [newValue=null] - Updated value of the property
   * @async
   */
  async updatePropertyValue (quiddityId, property, newValue = null) {
    let defaultValue = null
    let value = newValue
    const propertyId = property?.id

    if (this.hasProperty(quiddityId, propertyId)) {
      defaultValue = this.getPropertyValue(quiddityId, propertyId)
    }

    if (typeof value === 'undefined' || value === null) {
      value = await this.fetchPropertyValue(quiddityId, property)
    }

    if (value !== defaultValue && value !== null) {
      this.setPropertyValue(quiddityId, propertyId, value)

      LOG.info({
        msg: 'Successfully updated a property',
        property: propertyId,
        quiddity: quiddityId,
        value: value
      })
    }
  }

  /**
   * Sorts a map of properties according to each property's order
   * @param {Map<string, models.Property>} map - The property map to sort
   * @returns {Map<string, models.Property>} A sorted property map
   */
  sortPropertyMap (map) {
    return new Map(
      [...map.entries()].sort(
        ([, a], [, b]) => b.order - a.order
      )
    )
  }

  /**
   * Checks if the property is falsy
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} propertyId - ID of the property
   * @see [JavaScript Falsy values]{@link https://developer.mozilla.org/en-US/docs/Glossary/Falsy}
   * @returns {boolean} Flags true if the property value is falsy
   */
  isFalsy (quiddityId, propertyId) {
    return this.values.has(quiddityId) &&
      !this.values.get(quiddityId).get(propertyId)
  }

  /**
   * Checks if the property ID refers to a startable property
   * @param {string} propertyId - ID of the property
   * @returns {boolean} Returns true if the property is startable
   */
  isStartableProperty (propertyId) {
    return STARTED_PROPERTY_REGEX.test(propertyId)
  }

  /**
   * Gets the startable property for a given quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @returns {?module:models/quiddity.Property} The model of the startable property
   */
  getStartableProperty (quiddityId) {
    let property

    if (this.properties.has(quiddityId)) {
      property = Array.from(this.properties.get(quiddityId).values())
        .find(property => this.isStartableProperty(property.id))
    }

    return property
  }

  /**
   * Checks if a quiddity is startable
   * @param {string} quiddityId - ID of the quiddity
   * @returns {boolean}
   */
  isStartable (quiddityId) {
    return this.getStartableProperty(quiddityId) !== undefined
  }

  /**
   * Checks if a quiddity is started
   * @param {string} quiddityId - ID of the quiddity
   * @returns {boolean}
   */
  isStarted (quiddityId) {
    const startableProperty = this.getStartableProperty(quiddityId)
    let isStarted = false

    if (startableProperty && this.values.has(quiddityId)) {
      isStarted = this.values.get(quiddityId).get(startableProperty.id) || false
    }

    return isStarted
  }

  /**
   * Checks if a quiddity is enabled (all properties of started quiddities are disabled)
   * by checking if the quiddity is started and if the property is enabled and writable
   * @param {string} quiddityId - ID of the quiddity to check
   * @param {string} propertyId - ID of the property to check
   * @returns {boolean} - Flags if the property is enabled
   */
  isEnabled (quiddityId, propertyId) {
    let isEnabled = false

    const isWritable = this.hasProperty(quiddityId, propertyId) &&
          this.properties.get(quiddityId).get(propertyId).writable

    if (isWritable && this.statuses.has(quiddityId)) {
      isEnabled = this.statuses.get(quiddityId).get(propertyId) || false
    }

    return isEnabled
  }

  /**
   * Checks if the property is hidden by the configuration, if the property in question is a width or a height it checks if the property is hidden according to resolution label value
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} propertyId - ID of the property
   * @returns {boolean} Flags true if the property is hidden
   */
  isHidden (quiddityId, propertyId) {
    const { configStore: { scenicConfiguration } } = this
    const hiddenProperties = scenicConfiguration.hiddenProperties || []

    let isHidden = hiddenProperties.includes(propertyId)

    if (!isHidden && PROPERTY_ID_REGEX.test(propertyId)) {
      const [, prefix, suffix] = propertyId.match(PROPERTY_ID_REGEX)

      const parsedId = `${prefix?.toLowerCase()}/${suffix?.toLowerCase()}`

      switch (parsedId) {
        case 'capture/width':
        case 'capture/height':
        case 'webcam/width':
        case 'webcam/height':
        case 'generator/width':
        case 'generator/height': {
          const id = `${prefix}/resolution`
          const property = this.properties.get(quiddityId)?.get(id)
          const value = this.values.get(quiddityId)?.get(id)
          const label = property.labels.get(value)?.toUpperCase()

          isHidden = label !== 'CUSTOM'
          break
        }
        default: isHidden = false
      }
    }

    return isHidden
  }

  /**
   * Checks if the store contains a specific quiddity property
   * @param {string} quiddityId - ID of the quiddity to check
   * @param {string} propertyId - ID of the property to check
   * @returns {boolean} Flags true if the quiddity has the requested property
   */
  hasProperty (quiddityId, propertyId) {
    return this.properties.has(quiddityId) &&
      this.properties.get(quiddityId).has(propertyId)
  }

  /**
   * Checks if the store contains a value for a specific property
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} propertyId - ID of the property
   * @returns {boolean} Flags true if there is a value stored for the property
   */
  hasValue (quiddityId, propertyId) {
    return this.values.has(quiddityId) &&
      this.values.get(quiddityId).has(propertyId)
  }

  /**
   * Checks if the quiddity has property groups
   * All properties without a group are grouped under the DEFAULT_PROPERTY_GROUP_ID key
   * @param {string} quiddityId - ID of the quiddity
   * @returns {boolean} Flags true if the quiddity has property groups
   */
  hasGroupedProperties (quiddityId) {
    const grouped = this.groupedProperties.get(quiddityId)
    let hasGroup = false

    if (grouped) {
      if (grouped.has(DEFAULT_PROPERTY_GROUP_ID)) {
        hasGroup = grouped.size > 1
      } else {
        hasGroup = grouped.size > 0
      }
    }

    return hasGroup
  }

  /**
   * Checks if a quiddity has properties without a parent group
   * @param {string} quiddityId - ID of the quiddity
   * @returns {boolean} Flags true if the quiddity has a property without a group
   */
  hasOrphanedProperties (quiddityId) {
    const grouped = this.groupedProperties.get(quiddityId)
    let hasLeaves = false

    if (grouped) {
      hasLeaves = grouped.has(DEFAULT_PROPERTY_GROUP_ID)
    }

    return hasLeaves
  }

  /**
   * Safely gets a property's value, returns null if there is none
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} propertyId - ID of the property
   * @returns {?(string|number|boolean)} The value of the property
   */
  getPropertyValue (quiddityId, propertyId) {
    let value = null

    if (this.hasValue(quiddityId, propertyId)) {
      value = this.values.get(quiddityId).get(propertyId)
    } else if (this.hasProperty(quiddityId, propertyId)) {
      value = this.properties.get(quiddityId).get(propertyId)
    }

    return value
  }

  /**
   * Gets the unit of the property
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} propertyId - ID of the property
   * @returns {?models.UnitEnum} Unit of the property, null if the unit is not defined
   */
  getPropertyUnit (quiddityId, propertyId) {
    const { quiddityStore: { usedKinds } } = this
    const kind = usedKinds.get(quiddityId)
    const mapping = PROPERTY_UNIT_MAPPING[kind?.id]

    let unit = null

    if (mapping && mapping[propertyId]) {
      unit = mapping[propertyId]
    }

    return unit
  }

  /**
   * Safely gets a property's model, returns null if there is none
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} propertyId - ID of the property
   * @returns {?model.Property} The model of the property
   */
  getPropertyModel (quiddityId, propertyId) {
    const properties = this.properties.get(quiddityId)
    let model = null

    if (properties && properties.has(propertyId)) {
      model = properties.get(propertyId)
    }

    return model
  }

  /**
   * Extracts all groups from a property map
   * @param {string} quiddityId - ID of the quiddity's properties
   * @returns {Set<models.Property>} Set of all property groups
   */
  getPropertyGroups (quiddityId) {
    const properties = this.sortedProperties.get(quiddityId)
    const groups = new Set()

    if (properties) {
      for (const [, property] of properties) {
        if (property.isGroup()) {
          groups.add(property)
        }
      }
    }

    return groups
  }

  /**
   * Gets all children of a property group
   * @param {string} quiddityId - ID of the quiddity's property group
   * @param {string} [groupId=''] - ID of the property group, when it is empty it gets all properties without a group
   * @returns {Set<models.Property>} Set of all child properties
   */
  getPropertyChildren (quiddityId, groupId = '') {
    const properties = this.sortedProperties.get(quiddityId)
    const propertyChildren = new Set()

    if (properties) {
      for (const [, property] of properties) {
        if (!property.isGroup() && property.parent === groupId) {
          propertyChildren.add(property)
        }
      }
    }

    return propertyChildren
  }

  /** Cleans all properties from removed quiddities */
  cleanUselessProperties () {
    const { quiddities } = this.quiddityStore

    for (const quiddityId of this.properties.keys()) {
      if (!quiddities.has(quiddityId)) {
        this.removeQuiddityEntries(quiddityId)
      }
    }
  }

  /**
   * Updates the value of a property
   * @param {string} quiddityId - ID of the quiddity to update
   * @param {string} propertyId - ID of the property to update
   * @param {(string|number|boolean)} newValue - New value of the property
   */
  setPropertyValue (quiddityId, propertyId, newValue) {
    if (this.values.has(quiddityId)) {
      this.values.get(quiddityId).set(propertyId, newValue)
    } else {
      this.values.set(quiddityId, new Map([[propertyId, newValue]]))
    }
  }

  /**
   * Sets a new status for a property
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} propertyId - ID of the property
   * @param {boolean} isEnabled - Is the property enabled or not
   */
  setPropertyStatus (quiddityId, propertyId, isEnabled) {
    if (this.statuses.has(quiddityId)) {
      this.statuses.get(quiddityId).set(propertyId, isEnabled)
    } else {
      this.statuses.set(quiddityId, new Map([[propertyId, isEnabled]]))
    }
  }

  /**
   * Adds a new property
   * @param {string} quiddityId - ID of the quiddity to update
   * @param {models.Property} property - The new property to add
   */
  addProperty (quiddityId, property) {
    if (this.properties.has(quiddityId)) {
      this.properties.get(quiddityId).set(property.id, property)
    } else {
      this.properties.set(quiddityId, new Map([[property.id, property]]))
    }

    if (property.value !== null) {
      this.setPropertyValue(quiddityId, property.id, property.value)
    }
    this.setPropertyStatus(quiddityId, property.id, property.enabled)
  }

  /**
   * Deletes all properties of a quiddity
   * @param {string} quiddityId - ID of the quiddity to remove
   */
  removeQuiddityEntries (quiddityId) {
    this.properties.delete(quiddityId)
    this.values.delete(quiddityId)
  }

  /**
   * Deletes a property from the properties, values and statuses for a given quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} propertyId - ID of the property
   */
  removeProperty (quiddityId, propertyId) {
    if (this.properties.has(quiddityId)) {
      this.properties.get(quiddityId).delete(propertyId)
    }

    if (this.values.has(quiddityId)) {
      this.values.get(quiddityId).delete(propertyId)
    }

    if (this.statuses.has(quiddityId)) {
      this.statuses.get(quiddityId).delete(propertyId)
    }
  }

  addPropertyError (quiddityId, propertyId, errorMessage) {
    if (!this.errors.has(quiddityId)) {
      this.errors.set(quiddityId, new Map([[propertyId, errorMessage]]))
    } else {
      this.errors.get(quiddityId).set(propertyId, errorMessage)
    }
  }

  removePropertyError (quiddityId, propertyId) {
    if (this.errors.has(quiddityId)) {
      this.errors.get(quiddityId).delete(propertyId)

      if (this.errors.get(quiddityId).size === 0) {
        this.errors.delete(quiddityId)
      }
    }
  }
}

export default PropertyStore

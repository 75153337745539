import { logger } from '@utils/logger'
import { clamp } from '@utils/numberTools'

import UnitEnum from '@models/common/UnitEnum'

export const LOG = logger.child({ store: 'Property' })

/**
 * @classdesc Model for displaying a Switcher property
 * @memberof models
 */
class Property {
  /** @property {string} id - Required ID of the property */
  id = null

  /** @property {string} type - Required type of the property */
  type = null

  /** @property {(string|boolean|number)} [value=undefined] - Default value of the property according to its type */
  value = undefined

  /** @property [label] - Label of the property (default is id) */
  label = null

  /** @property [description] - Description of the property */
  description = null

  /** @property {boolean} [writable=true] - If set, property can be updated by the user */
  writable = true

  /** @property {boolean} [enabled=true] - Default status of the property. If set, the property is enabled */
  enabled = true

  /** @property {number} [order=0] - Order of the property */
  order = 0

  /** @property {string} [parent=''] - Group ID of the parent of the property */
  parent = ''

  /** @property {number} [max=Number.MAX_SAFE_INTEGER] - Maximum of the property, should be used with numeric types */
  max = Number.MAX_SAFE_INTEGER

  /** @propertyproperty{number} [min=Number.MIN_SAFE_INTEGER] - Minimum of the property, should be used with numeric types */
  min = Number.MIN_SAFE_INTEGER

  /** @property{Object[]} [values=[]] - Selectable values of the property, should be used with the `selection` type */
  values = []

  /** @property{models.UnitEnum} [unit=null] - Unit of the property */
  unit = null

  /**
   * Instantiates a new Property model
   * @param {string} id - Id of the property
   * @param {string} type - Type of the property
   * @param {(string|boolean|number)} value - Value of the property according to its type
   * @param {string} [label] - Label of the property (default is id)
   * @param {string} [description] - Description of the property (default is id)
   * @param {boolean} [writable=true] - If set, property can be updated by the user
   * @param {boolean} [enabled=true] - If set, the property is enabled
   * @param {number} [order=0] - Order of the property
   * @param {string} [parent=''] - Parent of the property
   * @param {number} [max=Number.MAX_SAFE_INTEGER] - Maximum of the property
   * @param {number} [min=Number.MIN_SAFE_INTEGER] - Minimum of the property
   * @param {Object[]} [values=[]] - Selectable values of the property
   */
  constructor (
    id,
    type,
    value = null,
    label = '',
    description = '',
    writable = true,
    enabled = true,
    order = 0,
    parent = '',
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    values = [],
    unit = null
  ) {
    if (typeof id === 'undefined' || typeof id !== 'string') {
      throw new TypeError('Attribute `id` is required and must be a string')
    } else {
      this.id = id
    }

    if (typeof type === 'undefined' || typeof type !== 'string') {
      throw new TypeError('Attribute `type` is required and must be a string')
    } else {
      this.type = type
    }

    if (typeof value === 'undefined') {
      throw new TypeError('Attribute `value` is required')
    } else {
      this.value = value
    }

    if (typeof label !== 'string') {
      throw new TypeError('Attribute `label` must be a string')
    } else {
      this.label = label || id
    }

    if (typeof description !== 'string') {
      throw new TypeError('Attribute `description` must be a string')
    } else {
      this.description = description
    }

    if (typeof writable !== 'boolean') {
      throw new TypeError('Attribute `writable` must be a boolean')
    } else {
      this.writable = writable
    }

    if (typeof enabled !== 'boolean') {
      throw new TypeError('Attribute `enabled` must be a boolean')
    } else {
      this.enabled = enabled
    }

    if (typeof order !== 'number') {
      throw new TypeError('Attribute `order` must be a number')
    } else {
      this.order = order
    }

    if (typeof parent !== 'string') {
      this.parent = ''
    } else {
      this.parent = parent
    }

    if (this.isNumber() && typeof max !== 'number') {
      throw new TypeError('Attribute `max` must be a number for a Number property')
    } else {
      this.max = max
    }

    if (this.isNumber() && typeof min !== 'number') {
      throw new TypeError('Attribute `min` must be a number for a Number property')
    } else {
      this.min = min
    }

    if (this.isSelection() && !Array.isArray(values)) {
      throw new TypeError('Attribute `values` must be an array for a Selection property')
    } else {
      this.values = values
    }

    if (Object.values(UnitEnum).includes(unit)) {
      this.unit = unit
    }
  }

  /**
   * Checks if the property default value is undefined
   * @returns {boolean} Flag an undefined value
   */
  isUndefined () {
    return typeof this.value === 'undefined'
  }

  /**
   * Checks if the property is read-only
   * @returns {boolean} Flag a read-only value
   */
  isReadOnly () {
    return this.disabled || !this.writable
  }

  /**
   * Checks if the property is a group
   * @returns {boolean} Flag a group type
   */
  isGroup () {
    return this.type === 'group'
  }

  /**
   * Checks if the property is a color
   * @returns {boolean} Flag a color type
   */
  isColor () {
    return this.type === 'color'
  }

  /**
   * Checks if the property is a number
   * @returns {boolean} Flag a number type
   */
  isNumber () {
    return [
      'float',
      'int',
      'int64',
      'short',
      'long',
      'double',
      'long double',
      'long long',
      'uint',
      'unsigned int',
      'unsigned short',
      'unsigned long',
      'unsigned long long'
    ].includes(this.type)
  }

  /**
   * Checks if the property has a double precision
   * @returns {boolean} Returns true if the property has a double precision
   */
  hasDoublePrecision () {
    return [
      'float',
      'double',
      'long double'
    ].includes(this.type) || this.isBitrate()
  }

  /**
   * Checks if the property is a selection
   * @returns {boolean} Flag a selection type
   */
  isSelection () {
    return [
      'enum',
      'selection'
    ].includes(this.type)
  }

  /**
   * Checks if the property is a string
   * @returns {boolean} Flag a string type
   */
  isString () {
    return this.type === 'string'
  }

  /**
   * Checks if the property is a password
   * @returns {boolean} Flag a password type
   * @todo We should remove the hardcoded property ID when switcher will support the password type
   * @see [Feature request: Add a `password` type]{@link https://gitlab.com/sat-mtl/tools/switcher/-/issues/26}
   */
  isPassword () {
    return this.type === 'password' || this.id === 'RTMP/stream_key'
  }

  /**
   * Checks if the property is a boolean
   * @returns {boolean} Flag a boolean type
   */
  isBoolean () {
    return [
      'bool',
      'boolean'
    ].includes(this.type)
  }

  /**
   * Checks if the property indicates a bitrate
   * @todo It is broken because the bitrate values lack of consistency
   * @see {stores.PropertyStore/PROPERTY_UNIT_MAPPING} instead
   * @returns {boolean} Flag a bitrate property
   */
  isBitrate () {
    return this.id !== 'bitrate' && this.id.includes('bitrate')
  }

  /**
   * Checks if the property indicates a frequency
   * @returns {boolean} Flag a frequency property
   */
  isFrequency () {
    return this.id !== 'frequency' && this.id.includes('frequency')
  }

  /**
   * Checks if the property indicates a time delay in milliseconds
   * @returns {boolean} Flag a delay property
   */
  isMsDelay () {
    return this.id !== 'time_delay' && this.id.includes('time_delay')
  }

  /**
   * @property {?number} number - The converted numeric value or null if it is not a number
   * Gets the converted numeric value
   * Uses the "bitrate" flag to convert the value
   */
  get number () {
    let value = null

    if (this.isNumber()) {
      value = this.value
    }

    return value
  }

  /**
   * @property {number} step - A step used to increment or decrement the field
   * Estimates the appropriate step of the numeric property
   */
  get step () {
    let step = 1

    if (this.isFrequency() || this.isMsDelay()) {
      step = 1
    } else if (this.hasDoublePrecision()) {
      step = 0.01
    }

    return step
  }

  /**
   * @property {number} maximum - A maximum value between the switcher value and the JavaScript maximum
   * @see [Number.MAX_SAFE_INTEGER documentation]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER}
   */
  get maximum () {
    let maximum = this.max || Number.MAX_SAFE_INTEGER

    if (this.isNumber()) {
      maximum = clamp(maximum, Number.MAX_SAFE_INTEGER)
    }

    return maximum
  }

  /**
   * @property {number} minimum - A minimum value between the switcher value and the JavaScript minimum
   * @see [Number.MIN_SAFE_INTEGER documentation]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MIN_SAFE_INTEGER}
   */
  get minimum () {
    let minimum = this.min || 0

    if (this.isNumber()) {
      minimum = clamp(minimum, Number.MIN_SAFE_INTEGER)
    }

    return minimum
  }

  /** @property {string} title - A formatted title from the property's label */
  get title () {
    return this.label.charAt(0).toUpperCase() + this.label.slice(1)
  }

  /** @property {Object[]} options - An `Option` object used in the Form components */
  get options () {
    const options = []

    if (this.isSelection()) {
      for (const value of this.values) {
        options.push({
          id: `${value.id}`,
          value: value.id,
          label: value.label
        })
      }
    }

    return options
  }

  /** @property {Map<string, string>} labels - A map containing the id and label of selection properties */
  get labels () {
    const labels = new Map()

    if (this.isSelection) {
      for (const value of this.values) {
        labels.set(value.id, value.label)
      }
    }

    return labels
  }

  /**
   * Gets the a selectable value of the the property
   * @param {string} value - Value of the option
   * @returns {Object} The option that corresponds to the value
   */
  getSelection (value) {
    return this.options[Number(value)]
  }

  /**
   * Parses a JSON object to a Property model
   * @param {Object} json - The JSON object
   * @returns {models.Property} - A Property model
   * @static
   */
  static fromJSON (json) {
    const {
      id,
      type,
      value,
      label,
      description,
      writable,
      enabled,
      order,
      parent,
      min,
      max,
      values,
      unit
    } = json

    return new Property(
      id,
      type,
      value,
      label,
      description,
      writable,
      enabled,
      order,
      parent,
      min,
      max,
      values,
      unit
    )
  }

  /**
   * Transforms the model to a JSON object
   * @returns {Object} - A generic JSON object
   */
  toJSON () {
    const {
      id,
      type,
      value,
      label,
      description,
      writable,
      enabled,
      order,
      parent,
      unit
    } = this

    let json = {
      id,
      type,
      value,
      label,
      description,
      writable,
      enabled,
      order,
      parent,
      unit
    }

    if (this.isNumber()) {
      json = {
        ...json,
        min: this.min,
        max: this.max
      }
    } else if (this.isSelection()) {
      json = {
        ...json,
        values: this.values
      }
    }

    return json
  }

  /**
   * Transforms the model to the props of a Form component
   * @returns {Object} - All props as a JS object
   */
  toFieldProps () {
    const {
      value,
      number,
      title,
      description,
      minimum,
      maximum,
      step,
      options,
      unit
    } = this

    let props = {
      value,
      title,
      description
    }

    if (this.isNumber()) {
      props = {
        ...props,
        value: number,
        min: minimum,
        max: maximum,
        step: step,
        unit: unit
      }
    } else if (this.isSelection()) {
      props = {
        ...props,
        options: options
      }
    }

    return props
  }

  /**
   * Parses a quiddity model to generate all tree-based properties
   * @param {models.Quiddity} quiddity - A quiddity model
   * @returns {models.Property[]} Array of property models
   */
  static fromQuiddity (quiddity) {
    const tree = quiddity.infoTree
    const properties = []

    if (Array.isArray(tree.property)) {
      for (const property of tree.property) {
        try {
          properties.push(Property.fromJSON(property))
        } catch (error) {
          LOG.error({
            msg: 'Failed to parse a property',
            property: property.id,
            quiddity: quiddity.id,
            error: error.message
          })
        }
      }
    }

    return properties
  }
}

export default Property

import React, { useEffect, useContext } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { AppStoresContext } from '@components/App'
import Connection from '@models/userTree/Connection'
import { MATRIX_BOX_PROPS } from '@components/matrix/Matrix'

import { useHover } from '@utils/uiTools'
import { Layout, Common, Context } from '@sat-mtl/ui-components'
import HelpWrapper from '@components/wrappers/HelpWrapper'
import { ConnectionLock } from '@components/common/LockIcons'

import '@styles/matrix/ConnectionBox.scss'

const { Cell } = Layout
const { Icon } = Common
const { ThemeContext } = Context

/**
 * Displays a connection box that is locked
 * @memberof module:components/matrix
 * @selector `.LockedBox`
 * @param {boolean} isConnected - Flag a connected locked box
 * @returns {external:ui-components/Layout.Cell} A connection box
 */
function LockedBox ({ isConnected }) {
  const cl = classNames('LockedBox', { ConnectedBox: isConnected })
  const { t } = useTranslation()

  return (
    <HelpWrapper message={t('The connection between this source and destination is locked.')}>
      <Cell className={cl} {...MATRIX_BOX_PROPS}>
        <ConnectionLock />
      </Cell>
    </HelpWrapper>
  )
}

/**
 * Displays a connection box that is armed
 * @memberof module:components/matrix
 * @selector `.ArmedBox`
 * @param {Function} onBoxClick - Function triggered when the box is clicked
 * @returns {external:ui-components/Layout.Cell} A connection box
 */
function ArmedBox ({ onBoxClick }) {
  const cl = classNames('ArmedBox')
  const { t } = useTranslation()

  return (
    <HelpWrapper message={t('The connection between this source and destination is assigned, but is not yet active.')}>
      <Cell className={cl} {...MATRIX_BOX_PROPS} onClick={onBoxClick}>
        <Icon type='flux' color='#fff' />
      </Cell>
    </HelpWrapper>
  )
}

/**
 * Displays a connection box that is connected
 * @memberof module:components/matrix
 * @selector `.ConnectedBox`
 * @param {Function} onBoxClick - Function triggered when the box is clicked
 * @returns {external:ui-components/Layout.Cell} A connection box
 */
function ConnectedBox ({ onBoxClick }) {
  const [hoverRef, isHovered] = useHover()
  const cl = classNames('ConnectedBox', { EndangeredBox: isHovered })
  const { t } = useTranslation()

  return (
    <HelpWrapper message={t('This source and destination are connected.')}>
      <Cell ref={hoverRef} className={cl} {...MATRIX_BOX_PROPS} onClick={onBoxClick}>
        <Icon type={isHovered ? 'close' : 'flux'} color='#fff' />
      </Cell>
    </HelpWrapper>
  )
}

/**
 * Displays a connection box that is disabled
 * @memberof module:components/matrix
 * @selector `.DisabledBox`
 * @returns {external:ui-components/Layout.Cell} A connection box
 */
function DisabledBox () {
  const cl = classNames('DisabledBox')
  const { t } = useTranslation()

  return (
    <HelpWrapper message={t('The connection between this source and destination is not possible.')}>
      <Cell className={cl} {...MATRIX_BOX_PROPS} />
    </HelpWrapper>
  )
}

/**
 * Displays a connection box that is connectable
 * @memberof module:components/matrix
 * @selector `.ConnectableBox`
 * @param {Function} onBoxClick - Function triggered when the box is clicked
 * @returns {external:ui-components/Layout.Cell} A connection box
 */
function ConnectableBox ({ onBoxClick }) {
  const [hoverRef, isHovered] = useHover()
  const cl = classNames('ConnectableBox')
  const { t } = useTranslation()

  return (
    <HelpWrapper message={t('This source and destination can be connected together.')}>
      <Cell {...MATRIX_BOX_PROPS} ref={hoverRef} className={cl} onClick={onBoxClick}>
        {isHovered && <Icon type='flux' color='#fff' />}
      </Cell>
    </HelpWrapper>
  )
}

/**
 * Displays a connection between a Source and a Destination
 * @memberof module:components/matrix
 * @selector `.ConnectionBox[data-source="source id"][data-destination="destination id"]`
 * @selector `.ConnectionBox[data-nickname="source nickname_destination nickname"]`
 * @selector `.ConnectionBox[data-connection="source id_destination id_contact"]`
 * @selector `.ConnectionBox[data-nickname="sourceNickname_destinationNickname_contactName"]`
 * @param {module:models/matrix.MatrixEntry} sourceEntry - Source entry from the matrix
 * @param {module:models/matrix.MatrixEntry} destinationEntry - Destination entry from the matrix
 * @returns {external:mobx-react/ObserverComponent} A connection box
 */
const ConnectionBox = observer(({ sourceEntry, destinationEntry }) => {
  const theme = useContext(ThemeContext)

  const {
    connectionStore,
    shmdataStore,
    contactStore,
    lockStore,
    matrixStore,
    encoderStore
  } = useContext(AppStoresContext)

  const compatibleShmdata = matrixStore.findCompatibleShmdata(sourceEntry, destinationEntry)

  const cl = classNames('ConnectionBox', `ConnectionBox-${theme}`)
  const connection = Connection.fromMatrixEntries(sourceEntry, destinationEntry, compatibleShmdata)

  const { contact } = destinationEntry

  useEffect(() => {
    (async function () {
      await shmdataStore.fallbackFollowingShmdatas(destinationEntry)
    })()
  })

  let $box

  if (!encoderStore.areEntriesConnectable(sourceEntry, destinationEntry)) {
    $box = (
      <DisabledBox />
    )
  } else if (lockStore.lockedEntries.has(destinationEntry.id)) {
    $box = (
      <LockedBox
        isConnected={matrixStore.activeMatrixConnections.has(connection?.id)}
      />
    )
  } else if (matrixStore.activeMatrixConnections.has(connection?.id)) {
    $box = (
      <ConnectedBox
        onBoxClick={() => {
          if (destinationEntry.isContact) {
            contactStore.applyDetachShmdata(contact.uri, compatibleShmdata?.path)
          } else {
            // recreating the connection fromMatrixEntries here solves some bugs where the sfid isn't found because the reference to
            // the quiddity object in the destinationEntry has the time to be updated
            connectionStore.applyUserDisconnection(Connection.fromMatrixEntries(sourceEntry, destinationEntry, compatibleShmdata))
          }

          connectionStore.applyConnectionDisarmament(connection)
        }}
      />
    )
  } else if (connectionStore.armedConnections.has(connection?.id)) {
    $box = (
      <ArmedBox
        onBoxClick={() => connectionStore.applyConnectionDisarmament(connection)}
      />
    )
  } else {
    $box = (
      <ConnectableBox
        onBoxClick={() => {
          connectionStore.applyConnectionArmament(connection)

          if (destinationEntry.isContact) {
            contactStore.applyAttachShmdata(contact, compatibleShmdata?.path)
          } else {
            connectionStore.applyUserConnection(connection)
          }
        }}
      />
    )
  }

  return (
    <div
      className={cl}
      data-connection={connection?.id}
      data-nickname={connection?.nickname}
      data-source={sourceEntry?.quiddityId}
      data-destination={destinationEntry?.quiddityId}
    >
      {$box}
    </div>
  )
})

export default ConnectionBox
export { LockedBox, ArmedBox, ConnectedBox, DisabledBox, ConnectableBox }

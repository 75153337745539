import { CONFIGURATION_MODAL_ID } from '@components/modals/ConfigurationModal'
import { SWITCHER_CONNECTION_MODAL_ID } from '@components/modals/SwitcherConnectionModal'

import { logger } from '@utils/logger'

import {
  populateStoreCreationBindings,
  populateModalCreationBindings
} from '@stores/populateBindings.js'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the App component
 * @memberof components.App
 */
export const LOG = logger.child({ component: 'App' })

/**
 * Initializes the main stores: ConfigStore and QuiddityStore.
 *
 * ConnectionStore and QuiddityMenuStore will be initialized in reaction to
 * the SceneStore and QuiddityStore's initialization, respectively.
 *
 * Most other Stores will be initialized when their associated quiddity is created.
 * @param {object} stores - All populated stores
 * @returns {boolean} Flags true if app is well initialized
 * @async
 */
async function initializeStores (stores) {
  const { configStore, kindStore, pageStore, quiddityStore, quiddityMenuStore } = stores

  let isConfigInit = false
  let isQuidditiesInit = false
  let isKindsInit = false

  try {
    isConfigInit = await configStore.initialize()

    // If user configuration is invalid, do not initialize the rest of the app
    if (isConfigInit) {
      pageStore.setActivePage(configStore.scenicConfiguration.defaultPage)

      isKindsInit = await kindStore.initialize(true)
    }

    if (isKindsInit) {
      quiddityStore.setCreationBindings(
        populateStoreCreationBindings(stores)
      )

      isQuidditiesInit = await quiddityStore.initialize()
    }

    if (isQuidditiesInit) {
      for (const [key, binding] of populateModalCreationBindings(stores)) {
        quiddityMenuStore.addMenuBinding(key, binding)
      }
    }

    LOG.info({
      notification: true,
      title: 'App initialization successful',
      msg: 'Scenic is now ready to go!',
      NODE_ENV: process.env.NODE_ENV,
      logLevel: LOG.level
    })
  } catch (error) {
    let reasonWhy = 'Unknown error'

    if (!isConfigInit) {
      reasonWhy = 'Could not initialize ConfigStore'
    } else if (!isKindsInit) {
      reasonWhy = 'Could not initialize KindStore'
    } else if (!isQuidditiesInit) {
      reasonWhy = 'Could not initialize QuiddityStore'
    }

    LOG.error({
      notification: true,
      title: 'App initialization failure',
      msg: reasonWhy,
      error: error.message
    })
  }

  return isConfigInit && isQuidditiesInit && isKindsInit
}

/**
 * Cleans up the application stores
 * @param {object} stores - All populated stores
 */
function deinitializeStores (stores) {
  const { lockStore, configStore, quiddityStore, sceneStore, drawerStore, userTreeStore } = stores

  sceneStore.clear()
  quiddityStore.clear()
  configStore.clear()
  drawerStore.clearActiveDrawer()
  userTreeStore.clear()
  lockStore.clear()
}

/**
 * Initializes the socket handlers
 * @param {object} stores - All populated stores
 */
function initializeSocket (stores) {
  const { socketStore, modalStore } = stores

  socketStore.onConnection(async () => {
    modalStore.cleanActiveModal(SWITCHER_CONNECTION_MODAL_ID)
    modalStore.cleanActiveModal(CONFIGURATION_MODAL_ID)

    const isInitialized = await initializeStores(stores)

    if (!isInitialized) {
      modalStore.setActiveModal(CONFIGURATION_MODAL_ID)
    }
  })

  socketStore.onDisconnection(() => {
    deinitializeStores(stores)
  })

  socketStore.onReconnectionFailed(() => {
    modalStore.setActiveModal(SWITCHER_CONNECTION_MODAL_ID)
  })

  socketStore.applyConnection()
}

export default initializeSocket

export {
  initializeStores,
  initializeSocket,
  deinitializeStores
}

/**
 * Enum for all order directions
 * @readonly
 * @memberof models
 * @enum {string}
 */
const OrderEnum = Object.freeze({
  RIGHT: 'OrderRight',
  LEFT: 'OrderLeft',
  UP: 'OrderUp',
  DOWN: 'OrderDown'
})

/**
 * Transform order directions
 * @param {string} orderId - Unique order direction
 * @returns {string} ID for the order buttons
 */
function toOrderButtonId (orderId) {
  switch (orderId) {
    case OrderEnum.RIGHT:
      return 'OrderButtonRight'
    case OrderEnum.LEFT:
      return 'OrderButtonLeft'
    case OrderEnum.UP:
      return 'OrderButtonUp'
    case OrderEnum.DOWN:
      return 'OrderButtonDown'
  }
}

export default OrderEnum
export { toOrderButtonId }

import { observable, computed, action, reaction, makeObservable } from 'mobx'

import InitStateEnum from '@models/common/InitStateEnum'
import SipCredentials from '@models/sip/SipCredentials'

import { SIP_KIND_ID, SIP_NICKNAME } from '@models/quiddity/specialQuiddities'

import ConfigStore from '@stores/common/ConfigStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'

import ShmdataStore, { TREE_PATH_REGEX } from '@stores/shmdata/ShmdataStore'
import Shmdata from '@models/shmdata/Shmdata'

import Store from '@stores/Store'
import { InitializationError, RequirementError } from '@utils/errors'

import { logger } from '@utils/logger'
import { setCookie } from '../../utils/cookies'

const { INITIALIZING } = InitStateEnum

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the SipStore
 * @memberof stores.SipStore
 */
export const LOG = logger.child({ store: 'SipStore' })

/**
 * @constant {RegExp} SELF_URI_REGEX - Matches all changes to the SIP quiddity's own URI
 * @memberof stores.SipStore
 */
export const SELF_URI_REGEX = /^\.self/

/**
 * @constant {RegExp} URI_REGEX - Matches a valid uri
 * @memberof stores.SipStore
 * @see [the SIP URL schema]{@link https://en.wikipedia.org/wiki/SIP_URI_scheme}
 */
export const URI_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/

/**
 * @constant {RegExp} CREDENTIALS_REGEX - Extract the user name and the SIP server name from a SIP URI
 * @memberof stores.SipStore
 */
export const CREDENTIALS_REGEX = /^([\w.]+)@([\w.]+)$/

/**
 * @classdesc Stores SIP quiddity
 * @extends stores.Store
 * @memberof stores
 */
class SipStore extends Store {
  /** @property {stores.ConfigStore} configStore - Stores and manages the app's configuration */
  configStore = null

  /** @property {stores.QuiddityStore} quiddityStore - Stores and manages all quiddities */
  quiddityStore = null

  /** @property {models.SipCredentials} currentCredentials - SIP credentials used to log on the SIP server */
  currentCredentials = null

  /** @property {Map<string, module:models/shmdata.Shmdata>} sipShmdatas - SIP shmdata models hashed by the shmdata path */
  sipShmdatas = new Map()

  /** @property {string} currentUri - Current URI logged in the SIP server */
  get currentUri () {
    return this.currentCredentials ? this.currentCredentials.uri : null
  }

  /** @property {boolean} isConnected - Flag the SIP connection */
  get isConnected () {
    return this.currentUri !== null
  }

  /** @property {string} sipId - The ID of the sip quiddity */
  get sipId () {
    return this.quiddityStore.quiddityByNames?.get(SIP_NICKNAME)?.id
  }

  /** @property {module:models/quiddity.Quiddity} sipQuiddity - Current SIP quiddity
   * note: This is the SIP quiddity associated with the current user, not any of the
   * other SIP_CLASS quiddities that may be received.
   */
  get sipQuiddity () {
    return this.quiddityStore.quiddities.get(this.sipId)
  }

  /**
   * Instantiates a new SipStore
   * @param {stores.SocketStore} socketStore - Socket manager
   * @param {stores.ConfigStore} configStore - Configuration manager
   * @param {stores.QuiddityStore} quiddityStore - Quiddity manager
   * @constructor
   */
  constructor (socketStore, configStore, quiddityStore, shmdataStore) {
    super(socketStore)

    makeObservable(this, {
      currentCredentials: observable,
      sipShmdatas: observable,
      currentUri: computed,
      isConnected: computed,
      sipId: computed,
      setCredentials: action,
      setSipShmdatas: action
    })

    if (configStore instanceof ConfigStore) {
      this.configStore = configStore
    } else {
      throw new RequirementError('SipStore', 'ConfigStore')
    }

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new RequirementError('SipStore', 'QuiddityStore')
    }

    if (shmdataStore instanceof ShmdataStore) {
      this.shmdataStore = shmdataStore
    } else {
      throw new RequirementError('SipStore', 'ShmdataStore')
    }

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )

    reaction(
      () => this.quiddityStore.initState,
      state => this.handleQuiddityStoreInitialization(state)
    )
  }

  /**
   * Initializes Store by fetching SIP quiddity
   * @returns {boolean} Flags true if store is well initialized
   * @throws {Error} Will throw an error if the quiddity store is not initialized
   * @async
   */
  async initialize () {
    if (!this.isNotInitialized()) {
      return this.isInitialized()
    } else if (this.quiddityStore.isNotInitialized()) {
      throw new InitializationError('QuiddityStore')
    }

    this.setInitState(INITIALIZING)

    if (!this.quiddityStore.quiddities.has(this.sipId)) {
      await this.fallbackSIPQuiddity()
    }

    const uri = await this.fetchRegisteredUri()

    if (uri) {
      this.handleSipRegistration(uri)
    }

    return this.applySuccessfulInitialization()
  }

  /**
   * Creates the SIP quiddity
   * @async
   */
  async fallbackSIPQuiddity () {
    try {
      const config = this.configStore.findInitialConfiguration(SIP_KIND_ID, this.sipId)

      if (config) {
        await this.quiddityStore.applyQuiddityCreation(
          SIP_KIND_ID,
          SIP_NICKNAME,
          config.properties,
          config.userTree
        )
      } else {
        await this.quiddityStore.applyQuiddityCreation(
          SIP_KIND_ID,
          SIP_NICKNAME
        )
      }

      LOG.info({
        msg: 'Successfully fallen back quiddity creation',
        quiddity: this.sipId
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to fallback quiddity creation',
        quiddity: this.sipId
      })
    }
  }

  /**
   * Fetches registration status of the SIP quiddity to a SIP server
   * @returns {?string} - Current URI registered to SIP server, null if not registered
   * @async
   */
  async fetchRegisteredUri () {
    const { infoTreeAPI } = this.socketStore.APIs
    let uri = null

    try {
      uri = await infoTreeAPI.get(this.sipId, '.self')

      if (uri === 'null') {
        uri = null

        LOG.warn({
          notification: true,
          title: 'You are not connected to SIP',
          msg: 'You must be logged in to a SIP server before sending sources to others'
        })
      } else if (uri) {
        // When not registered to SIP server, .self returns 'null' (as a string)
        LOG.info({
          msg: 'Successfully fetched current SIP URI',
          uri: uri
        })
      } else {
        throw new Error('Fetched a null SIP URI')
      }
    } catch (error) {
      uri = null

      LOG.error({
        notification: true,
        title: 'Error on SIP login',
        msg: 'Unable to fetch the current SIP URI',
        error: error.message
      })
    }

    return uri
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()

    if (socket && this.socketStore.hasActiveAPIs) {
      const { infoTreeAPI } = this.socketStore.APIs

      // When SIP is logged in/out from a server (.self is grafted)
      infoTreeAPI.onGrafted(
        (quidId, path, value) => this.handleSipRegistration(value),
        quidId => quidId === this.sipId,
        path => Array.isArray(path.match(SELF_URI_REGEX))
      )

      // When a SIP source is created
      infoTreeAPI.onGrafted(
        (quidId, path, value) => this.handleGraftedShmdata(quidId, path, value),
        (quidId) => quidId === this.sipId,
        path => Array.isArray(path.match(TREE_PATH_REGEX))
      )
    }
  }

  /**
   * Handles a `graftedTree` Switcher signal for a shmdata
   * @param {string} quiddityId - ID of the updated quiddity
   * @param {string} treePath - Path of the updated quiddity tree
   * @param {Object} json - Shmdata model as a plain JSON
   * @return {boolean} Flags a handled shmdata update
   */
  async handleGraftedShmdata (quiddityId, treePath, json) {
    const [, role, shmdataPath, isStat, uri] = treePath.match(TREE_PATH_REGEX)

    if (!this.hasShmdataPath(quiddityId, shmdataPath)) {
      const shmdata = await this.makeShmdataModel(quiddityId, role, shmdataPath, isStat ? null : json, uri)
      this.setSipShmdatas(shmdataPath, shmdata)
    }
  }

  /**
   * Checks if a shmdata path exists in the store
   * @param {string} shmdataPath - Shmdata Path
   * @returns {boolean} True if the shmdata path exists in the store
   */
  hasShmdataPath (quiddityId, shmdataPath) {
    let isPresent = false
    if (shmdataPath) {
      isPresent = this.sipShmdatas?.has(shmdataPath)
    }
    return isPresent
  }

  /**
   * Crafts a shmdata model for a SIP quiddity
   * @param {string} quiddityId - ID of the shmdata's SIP quiddity
   * @param {string} role - Role of the updated shmdata
   * @param {string} shmdataPath - Path of the updated shmdata
   * @param {?Object} [json=null] - Shmdata model as a plain JSON (fetches the quiddity tree if null)
   * @todo Refactor the makeShmdataModel methods and share it between the ShmdataStore and the SipStore
   * @returns {?module:models/shmdata.Shmdata} The new shmdata model (null if it failed)
   */
  async makeShmdataModel (quiddityId, role, shmdataPath, json = null) {
    const { infoTreeAPI } = this.socketStore.APIs
    let shmdata = null

    try {
      if (this.sipShmdatas?.has(shmdataPath)) {
        shmdata = this.sipShmdatas.get(shmdataPath)
      } else if (!json) {
        json = await infoTreeAPI.get(quiddityId, `.shmdata.${role}.${shmdataPath}`)
        shmdata = Shmdata.fromJSON(shmdataPath, json)
      } else {
        shmdata = Shmdata.fromJSON(shmdataPath, json)
      }
    } catch (error) {
      LOG.error({
        msg: 'Received an invalid SIP Shmdata',
        error: error,
        json: json,
        shmdata: shmdataPath
      })
    }

    return shmdata
  }

  /**
   * Handles QuiddityStore's initialization state changes
   * @param {models.InitStateEnum} initState - State of the quiddityStore's initialization
   */
  handleQuiddityStoreInitialization (initState) {
    const { NOT_INITIALIZED } = InitStateEnum

    if (initState === NOT_INITIALIZED) {
      this.clear()
    }
  }

  /**
   * Handles SIP registration status
   * @param {string} value - Registered URI
   * @async
   */
  async handleSipRegistration (uri) {
    if (this.currentUri !== uri) {
      let credentials = null

      if (typeof uri === 'string' && uri !== 'null') {
        const [, user, domain] = uri.match(CREDENTIALS_REGEX)
        try {
          let port = await this.propertyAPI.get(this.sipId, 'port')

          if (typeof port === 'object') {
            port = Number.parseInt(port.value)
          } else if (typeof port === 'string') {
            port = Number.parseInt(port)
          }

          credentials = SipCredentials.fromJSON({
            sipUser: user,
            sipServer: domain,
            port: port
          })
        } catch (error) {
          LOG.warn({
            msg: 'Failed to fetch current SIP port',
            error: error.message
          })

          credentials = SipCredentials.fromJSON({
            sipUser: user,
            sipServer: domain
          })
        }
      }

      this.setCredentials(credentials)
    }
  }

  /**
   * Sets SIP port
   * @param {number} port - Local SIP port to use for connection
   * @returns Flags true if port was successfully set
   * @async
   */
  async applySipPort (port) {
    const { propertyAPI } = this.socketStore.APIs
    let isPortSet = false

    try {
      isPortSet = await propertyAPI.set(this.sipId, 'port', port)

      if (isPortSet) {
        LOG.info({
          msg: 'Successfully set SIP port',
          port: port
        })
      } else {
        throw new Error('SIP port configuration failed')
      }
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'Error on SIP login',
        msg: 'Error while setting SIP port',
        error: error.message,
        port: port
      })
    }

    return isPortSet
  }

  /**
   * Sets STUN/TURN configuration
   * @param {string} stunServer - STUN server address or domain name
   * @param {string} turnServer - TURN server address or domain name
   * @param {string} turnUser - TURN user
   * @param {string} turnPassword - TURN password
   * @returns Flags true if configuration was successfully applied
   * @async
   */
  async applyStunTurnConfiguration (stunServer, turnServer, turnUser, turnPassword) {
    const { methodAPI } = this.socketStore.APIs
    let isConfigured = false

    try {
      isConfigured = await methodAPI.setStunTurn(
        this.sipId,
        stunServer,
        turnServer,
        turnUser,
        turnPassword
      )

      if (isConfigured) {
        LOG.info({
          msg: 'Successfully set STUN/TURN configuration',
          stunServer: stunServer,
          turnServer: turnServer,
          turnUser: turnUser
        })
      } else {
        throw new Error('STUN/TURN configuration failed')
      }
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'Error on SIP login',
        msg: 'Error while setting STUN/TURN configuration',
        error: error.message,
        stunServer: stunServer,
        turnServer: turnServer,
        turnUser: turnUser
      })
    }

    return isConfigured
  }

  /**
   * Registers to SIP server
   * @param {models.SipCredentials} credentials - A SipCredentials model
   * @returns Flags true if registration was successful
   * @async
   */
  async applySipRegistration (credentials) {
    const { methodAPI } = this.socketStore.APIs
    const { sipServer, sipUser, sipPassword, destinationPort } = credentials
    let isRegistered = false

    try {
      isRegistered = await methodAPI.register(
        this.sipId,
        sipServer,
        sipUser,
        sipPassword,
        destinationPort
      )

      if (isRegistered) {
        const sipCredentials = { sipServer: sipServer, sipPort: credentials.port, sipUser: sipUser, sipPassword: sipPassword }
        this.setSipCookies(sipCredentials)

        LOG.info({
          notification: true,
          title: 'SIP login success',
          msg: 'Successfully authenticated to SIP server',
          uri: `${sipUser}@${sipServer}:${destinationPort}`
        })
      } else {
        throw new Error('SIP registration failed')
      }
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'Error on SIP login',
        msg: 'Error while registering to SIP server',
        error: error.message,
        uri: `${sipUser}@${sipServer}`
      })
    }

    return isRegistered
  }

  /**
   * Unregisters from SIP server
   * @returns Flags true if unregistration was successful
   * @async
   */
  async applySipUnregistration () {
    const { methodAPI, propertyAPI } = this.socketStore.APIs
    let sipRegistration = null
    let isLoggedOut = false

    try {
      await methodAPI.unregister(this.sipId)
      // Query SIP quiddity property 'sip-registration' to validate if we are
      // now unregistered
      sipRegistration = await propertyAPI.get(this.sipId, 'sip-registration')
      isLoggedOut = !sipRegistration
      if (isLoggedOut) {
        LOG.info({
          notification: true,
          title: 'SIP logout success',
          msg: 'Successfully logged out of SIP server'
        })
      } else {
        throw new Error('SIP log out failed')
      }
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'Error on SIP logout',
        msg: 'Failed to unregister from SIP server',
        error: error.message
      })
    }

    return isLoggedOut
  }

  /**
   * Hangs up all incoming and outgoing SIP calls
   * @returns {boolean} Flags true if all calls were successfully terminated
   * @async
   */
  async applyTerminateCalls () {
    const { methodAPI } = this.socketStore.APIs
    let isHungUp = false

    try {
      isHungUp = await methodAPI.invoke(this.sipId, 'hang_up_all', [])

      if (isHungUp) {
        this.setCredentials(null)

        LOG.info({
          msg: 'Successfully hung up all calls'
        })
      } else {
        throw new Error('Could not hang up all calls')
      }
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'Error on SIP logout',
        msg: 'Error while hanging up calls',
        error: error.message
      })
    }

    return isHungUp
  }

  /**
   * Logs to designated SIP server
   * @param {models.SipCredentials} credentials - A SipCredentials model
   * @returns {boolean} Flags true if login was successful
   * @async
   */
  async applyLogin (credentials) {
    let isPortSet = false
    let isConfigured = false
    let isRegistered = false

    if (this.currentUri === null) {
      // Set SIP port
      isPortSet = await this.applySipPort(credentials.port)

      if (isPortSet) {
        // Set STUN/TURN
        isConfigured = await this.applyStunTurnConfiguration(
          credentials.stunServer,
          credentials.turnServer,
          credentials.turnUser,
          credentials.turnPassword
        )

        if (isConfigured) {
          // Register to SIP server
          isRegistered = await this.applySipRegistration(credentials)

          if (isRegistered) {
            this.setCredentials(credentials)
          }
        }
      }
    } else {
      isPortSet = true
      isConfigured = true
      isRegistered = true
      LOG.warn({
        notification: true,
        title: 'Skipping SIP login',
        msg: 'Already logged in to SIP server',
        currentUri: this.currentUri
      })
    }

    return isPortSet && isConfigured && isRegistered
  }

  /**
   * Logs out of current SIP server
   * @returns {boolean} Flags true if logout was successful
   * @async
   */
  async applyLogout () {
    let isLoggedOut = false

    if (this.currentCredentials !== null) {
      await this.applyTerminateCalls()
      isLoggedOut = await this.applySipUnregistration()
    } else {
      isLoggedOut = true

      LOG.warn({
        notification: true,
        title: 'Skipping SIP logout',
        msg: 'Already logged out of SIP server'
      })
    }

    return isLoggedOut
  }

  /**
   * Sets the current SipCredentials model
   * @param {models.SipCredentials} credentials - SipCredentials model
   */
  setCredentials (credentials) {
    this.currentCredentials = credentials
  }

  /**
   * Sets the sip shmdatas model
   * @param {string} shmdataPath - The path of the shmdata
   * @param {Object} shmdata - The shmdata object
   */
  setSipShmdatas (shmdataPath, shmdata) {
    this.sipShmdatas.set(shmdataPath, shmdata)
  }

  /**
   * Tries to find the uri of the caller associated with the quiddity id. If it does not find it,
   * it assumes the quiddity does not yet has a shmdata associated with it and thus returns "Waiting for data stream"
   * In the future, switcher should surface a uri even for extshmsrc quiddities that don't have a shmdata yet.
   * (ticket for the switcher feature : https://gitlab.com/sat-mtl/tools/switcher/-/issues/138 )
   * @param {string} quidId - the id of the quiddity to get the caller URI from
   * @returns {string} uri of the caller associated with the quiddity or "Waiting for data stream"
   */
  getCallerURI (quidId) {
    const { writingShmdatas } = this.shmdataStore

    let uri = null

    if (writingShmdatas.has(quidId)) {
      const shmdatas = Array.from(writingShmdatas.get(quidId))
      // for every shmdata, we first look to see if it has a uri shmdata. We also look inside the SIP quiddity to get the corresponding shmdata object
      // The different state representation are in a jumbled state right now so we look at both places to be sure we find something.
      for (const shmdata of shmdatas) {
        const shmdataFromSIPQuiddity = this.sipQuiddity?.infoTree.shmdata?.writer
        if (shmdata.uri) {
          uri = shmdata.uri
        } else if (shmdataFromSIPQuiddity) {
          uri = shmdataFromSIPQuiddity[shmdata.path].uri
        }
      }
    }
    return uri || 'Waiting for data stream'
  }

  /**
   * Cleans up the Store
   */
  clear () {
    const { NOT_INITIALIZED } = InitStateEnum
    this.setInitState(NOT_INITIALIZED)

    this.setCredentials(null)

    LOG.info({
      msg: 'Successfully cleared SipStore'
    })
  }

  /**
  * Saves the sip crendentials in cookies
  * @param {Object} credentials - Object with the different credentials feed
  */
  setSipCookies (credentials) {
    setCookie('sipServer', credentials.sipServer)
    setCookie('sipPort', credentials.sipPort)
    setCookie('sipUser', credentials.sipUser)
    setCookie('sipPassword', credentials.sipPassword)
  }
}

export default SipStore

import API from '@api/API'

/**
 * @classdesc API for managing the connection specs API
 * @memberof module:api
 * @extends module:api.API
 */
class ConnectionSpecsAPI extends API {
  get (quidId) {
    return new Promise((resolve, reject) => {
      this.socket.emit('connection_specs_get', quidId, (error, result) => {
        if (error) reject(error)
        if (!result || result === 'null') resolve({})
        if (result) resolve(result)
      })
    })
  }
}

export default ConnectionSpecsAPI

import API from '@api/API'

/**
 * @classdesc API for invoking quiddity methods
 * @memberof module:api
 * @extends module:api.API
 */
class MethodAPI extends API {
  /**
   * Invokes a method of a quiddity from the method's name
   * @param {string} quiddityId - ID of the quiddity
   * @param {string} methodName - Name of the method to invoke
   * @param {string[]} methodArgs - All required arguments of the method as strings
   * @async
   */
  invoke (quiddityId, methodName, methodArgs) {
    return new Promise((resolve, reject) => {
      this.socket.emit('quiddity_invoke', quiddityId, methodName, methodArgs, (error, output) => {
        if (error) reject(error)

        if (typeof output === 'undefined') {
          reject(new Error(`Method ${methodName} failed to be invoked`))
        } else {
          resolve(output)
        }
      })
    })
  }

  /**
   * Checks if a quiddity can be synchronized with specific caps
   * @param {string} quiddityId - The quiddity to test
   * @param {string} caps - Caps provided from a shmdata
   * @deprecated
   * @async
   * @returns {boolean} Returns true if the caps are compatibles with the quiddity
   */
  canSinkCaps (quiddityId, caps) {
    return this.invoke(quiddityId, 'can-sink-caps', [caps])
  }

  /**
   * Registers to SIP server
   * @param {number} quidId - ID of the SIP quiddity
   * @param {string} sipUri - SIP login URI
   * @param {string} sipUser - Name of the SIP user
   * @param {string} sipPassword - SIP password
   * @param {string} sipDestinationPort - SIP destination port
   * @returns Flags true if registration was successful
   * @async
   */
  register (quidId, sipUri, sipUser, sipPassword, sipDestinationPort) {
    sipDestinationPort = sipDestinationPort === undefined ? '9060' : sipDestinationPort
    return this.invoke(quidId, 'register', [`${sipUser}@${sipUri}:${sipDestinationPort}`, sipPassword])
  }

  /**
   * Unregisters from SIP server
   * @param {number} quidId - ID of the SIP quiddity
   * @returns Flags true if unregistration was successful
   * @async
   */
  unregister (quidId) {
    return this.invoke(quidId, 'unregister', [])
  }

  /**
   * Sets the STUN/TURN params
   * @param {number} quidId - ID of the SIP quiddity
   * @param {string} [turnUser=''] - TURN user. If left empty, the SIP quiddity will use the user supplied for the SIP server login.
   * @param {string} [turnServer=''] - TURN server address or domain name. If left empty, the SIP quiddity will use the URI of the SIP server it is currently registered to.
   * @param {string} [stunServer=''] - STUN server address or domain name. If left empty, the SIP quiddity will use the URI of the SIP server it is currently registered to.
   * @param {string} [turnPassword=''] - TURN password. If left empty, the SIP quiddity will use the password supplied for the SIP server login.
   * @returns Flags true if stun/turn configuration was successful
   * @async
   */
  setStunTurn (quidId, stunServer, turnServer, turnUser, turnPassword) {
    return this.invoke(quidId, 'set_stun_turn', [stunServer, turnServer, turnUser, turnPassword])
  }

  /**
   * Adds a new contact to the sip quiddity
   * @param {number} quidId - ID of the SIP quiddity
   * @param {string} sipUri - SIP server URI of the buddy
   * @param {string} buddyName - Name of the buddy
   * @returns Flags true if contact is added successfully
   * @async
   */
  addBuddy (quidId, sipUri, buddyName) {
    return this.invoke(quidId, 'add_buddy', [`${buddyName}@${sipUri}`])
  }

  /**
   * Removes a contact from the sip quiddity
   * @param {number} quidId - ID of the SIP quiddity
   * @param {string} sipUri - SIP server URI of the buddy
   * @param {string} buddyName - Name of the buddy
   * @returns Flags true if contact is deleted successfully
   * @async
   */
  delBuddy (quidId, sipUri, buddyName) {
    return this.invoke(quidId, 'del_buddy', [`${buddyName}@${sipUri}`])
  }

  /**
   * Renames a contact
   * @param {number} quidId - ID of the SIP quiddity
   * @param {string} sipUri - SIP server URI of the buddy
   * @param {string} oldName - Old name of the SIP contact
   * @param {string} newName - New name of the SIP contact
   * @returns Flags true if contact name is set successfully
   * @async
   */
  nameBuddy (quidId, sipUri, oldName, newName) {
    return this.invoke(quidId, 'name_buddy', [newName, `${oldName}@${sipUri}`])
  }

  /**
   * Applies the authorization of a contact
   * @param {number} quidId - ID of the SIP quiddity
   * @param {string} sipUri - SIP server URI of the buddy
   * @param {string} buddyName - Name of the buddy
   * @param {boolean} authorization - New authorization value for the SIP contact
   * @returns Flags true if authorization was successfully set
   * @async
   */
  authorize (quidId, sipUri, buddyName, authorization) {
    return this.invoke(quidId, 'authorize', [`${buddyName}@${sipUri}`, authorization])
  }

  /**
   * Attaches a shmdata to a contact
   * @param {number} quidId - ID of the SIP quiddity
   * @param {string} sipUri - SIP server URI of the buddy
   * @param {string} buddyName - Name of the buddy
   * @param {string} shmPath - Path of a shmdata
   * @returns Flags true if shmdata was successfully attached
   * @async
   */
  attachShmdataToContact (quidId, sipUri, buddyName, shmPath) {
    return this.invoke(quidId, 'attach_shmdata_to_contact', [shmPath, `${buddyName}@${sipUri}`, true])
  }

  /**
   * Detaches a shmdata from a contact
   * @param {number} quidId - ID of the SIP quiddity
   * @param {string} sipUri - SIP server URI of the buddy
   * @param {string} buddyName - Name of the buddy
   * @param {string} shmPath - Path of a shmdata
   * @returns Flags true if shmdata was successfully detached
   * @async
   */
  detachShmdataFromContact (quidId, sipUri, buddyName, shmPath) {
    return this.invoke(quidId, 'attach_shmdata_to_contact', [shmPath, `${buddyName}@${sipUri}`, false])
  }

  /**
   * Sends all attached shmdatas to a contact
   * @param {number} quidId - ID of the SIP quiddity
   * @param {string} sipUri - SIP server URI of the buddy
   * @param {string} buddyName - Name of the buddy
   * @returns Flags true if the contact is in session
   * @async
   */
  send (quidId, sipUri, buddyName) {
    return this.invoke(quidId, 'send', [`${buddyName}@${sipUri}`])
  }
}

export default MethodAPI

import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'

import { AppStoresContext } from '@components/App'

/** Displays a nickname label for a source entry
 * @param {string} quiddityId - The ID of the quiddity to be labeled
 * @param {?string} [defaultLabel='NO NICKNAME] - Fallback label if there is no label returned
 * @returns {external:mobx-react/ObserverComponent} - The nickname label of a quiddity
*/
const NicknameLabel = observer(({ quiddityId, defaultLabel = 'NO NICKNAME' }) => {
  const { nicknameStore } = useContext(AppStoresContext)

  useEffect(() => {
    if (!nicknameStore.nicknames.has(quiddityId)) {
      nicknameStore.initializeNickname(quiddityId)
    }
  }, [])

  let label = defaultLabel

  if (nicknameStore.nicknames.has(quiddityId)) {
    label = nicknameStore.nicknames.get(quiddityId)
  }

  return (
    <>{label}</>
  )
})

export default NicknameLabel

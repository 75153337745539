import { computed, makeObservable } from 'mobx'

import { escapeToSafeCss } from '@utils/stringTools'

import MatrixStore from '@stores/matrix/MatrixStore'
import MatrixCategoryEnum from '@models/matrix/MatrixCategoryEnum'

/**
 * @classdesc Helper for all CSS grid properties of the matrix layout
 * @memberof module:stores/matrix
 */
class LayoutHelper {
  /** @property {number} innerGap - The inner gap between all matrix elements */
  innerGap = 0

  /** @property {module:stores/matrix.MatrixStore} matrixStore - Store for all matrix properties */
  matrixStore = null

  /**
   * Instantiates a new LayoutHelper
   * @param {module:stores/matrix.MatrixStore} matrixStore - Store for all matrix properties
   * @param {number} innerGap - The inner gap between all matrix elements
   * @constructor
   */
  constructor (matrixStore, innerGap = 0) {
    makeObservable(this, {
      columnHeadingsAreasTemplate: computed,
      gridTemplateAreasStyleProperty: computed,
      gridTemplateColumnsStyleProperty: computed,
      gridLayoutStyle: computed
    })

    if (matrixStore instanceof MatrixStore) {
      this.matrixStore = matrixStore
    } else {
      throw new TypeError('LayoutHelper requires a MatrixStore')
    }

    this.innerGap = innerGap
  }

  /**
   * Gets the key for a source area
   * @param {string} sourceCategoryId - The source category
   * @returns {string} The generated area key
   */
  getSourceAreaKey (sourceCategoryId) {
    const cssSafeSourceCategoryId = escapeToSafeCss(sourceCategoryId)
    return `${cssSafeSourceCategoryId}-sources`
  }

  /**
   * Gets the key for the matrix header area
   * @returns {string} A string key
   */
  getMatrixHeaderAreaKey () {
    return 'MatrixHeader'
  }

  /**
   * Gets the key for a destination area
   * @param {string} categoryId - The destination category
   * @returns {string} The generated area key
   */
  getDestinationAreaKey (categoryId) {
    return `${categoryId}-destinations`
  }

  /**
   * Gets the key for a destination separator area
   * @param {string} categoryId - The destination category
   * @returns {string} The generated area key
   */
  getDestinationSeparatorAreaKey (categoryId) {
    return `${categoryId}-destinations-separator`
  }

  /**
   * Gets the key for a connection area
   * @param {string} sourceCategoryId - The source category
   * @param {string} destinationCategoryId - The destination category
   * @returns {string} The generated area key
   */
  getConnectionAreaKey (sourceCategoryId, destinationCategoryId) {
    const cssSafeSourceCategoryId = escapeToSafeCss(sourceCategoryId)
    return `${cssSafeSourceCategoryId}-${destinationCategoryId}-connections`
  }

  /**
   * Gets the style of each grid areas
   * @param {string} areaKey - The key of the area
   * @returns {Object} A CSS style representation
   */
  getGridAreaStyle (areaKey) {
    return {
      gridArea: areaKey
    }
  }

  /**
   * Gets the column headings template of CSS grid areas
   * @returns {string[]} A list of `grid-area` properties
   * @see [grid-areas documentation]{@link https://developer.mozilla.org/en-US/docs/Web/CSS/grid-area}
   */
  get columnHeadingsAreasTemplate () {
    const { matrixStore: { destinationCategories } } = this
    const headerRow = [this.getMatrixHeaderAreaKey()]

    for (const [destinationCategory] of destinationCategories) {
      if (destinationCategory !== MatrixCategoryEnum.COMMON) {
        headerRow.push(
          this.getDestinationSeparatorAreaKey(destinationCategory)
        )
      }

      headerRow.push(
        this.getDestinationAreaKey(destinationCategory)
      )
    }

    return headerRow
  }

  /**
   * Gets a row template of CSS grid areas
   * @param {module:models/matrix.MatrixCategoryEnum} sourceCategory - A matrix category
   * @returns {string[]} A list of `grid-area` properties
   * @see [grid-areas documentation]{@link https://developer.mozilla.org/en-US/docs/Web/CSS/grid-area}
   */
  getRowAreasTemplate (sourceCategory) {
    const row = [this.getSourceAreaKey(sourceCategory)]
    const { destinationCategories } = this.matrixStore

    for (const [destinationCategory] of destinationCategories) {
      if (destinationCategory !== MatrixCategoryEnum.COMMON) {
        row.push(
          this.getDestinationSeparatorAreaKey(destinationCategory)
        )
      }

      row.push(
        this.getConnectionAreaKey(sourceCategory, destinationCategory)
      )
    }

    return row
  }

  /**
   * Gets the area key for a source separator
   * @param {string} sourceCategoryId - the identifier of the source category
   * @returns {string} A CSS safe string to be used as a grid-area css property
   */
  getSourceSeparatorAreaKey (sourceCategoryId) {
    const cssSafeSourceCategoryId = escapeToSafeCss(sourceCategoryId)
    return `row-separator-${cssSafeSourceCategoryId}`
  }

  /** A '.' represents an empty cell in the grid, we need to push one for every destination category. We can't push this.getSourceSeparatorAreaKey(sourceCategory) because
  * our row separator cannot be contiguous because of the destinationSeparators that span entire columns
     @returns {string} a '.'
  */
  getEmptyRowTemplate () {
    return '.'
  }

  /**
   * Gets the grid-template-areas entry for a source separator
   * @returns {string[]} A list of `grid-area` properties
   * @see [grid-areas documentation]{@link https://developer.mozilla.org/en-US/docs/Web/CSS/grid-area}
   */
  getSourceSeparatorTemplate (sourceCategory) {
    const { destinationCategories } = this.matrixStore
    const row = [this.getSourceSeparatorAreaKey(sourceCategory)]
    for (const [destinationCategory] of destinationCategories) {
      // We need to push the destinationSeparator area key because otherwise our grid-template-areas
      // becomes invalid because of non contiguous elements. (destinationSeparators are elements that span an entire column of the grid.)
      if (destinationCategory !== MatrixCategoryEnum.COMMON) {
        row.push(
          this.getDestinationSeparatorAreaKey(destinationCategory)
        )
      }
      row.push(
        this.getEmptyRowTemplate()
      )
    }
    return row
  }

  /**
   * Gets the grid-template-areas style of the matrix
   * + It represents a string matrix of area keys
   * + All areas are named with the source and the destination categories
   * @returns {string} The computed `grid-template-areas` style property
   * @see [grid-template-areas documentation]{@link https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-areas}
   */
  get gridTemplateAreasStyleProperty () {
    const { matrixStore: { sourceCategories } } = this
    const areaLayout = []

    areaLayout.push(
      this.columnHeadingsAreasTemplate.join(' ')
    )

    for (const [sourceCategory] of sourceCategories) {
      // For every source category that are not our local sources, add a separator
      if (sourceCategory !== MatrixCategoryEnum.COMMON) {
        areaLayout.push(this.getSourceSeparatorTemplate(sourceCategory).join(' '))
      }
      areaLayout.push(
        this.getRowAreasTemplate(sourceCategory).join(' ')
      )
    }

    return areaLayout.map(row => `'${row}'`).join(' ')
  }

  /**
   * Gets the `grid-template-columns` style of the matrix
   * + It repeats the `max-reader` property for every destination categories
   * + It counts each category twice because it also counts separators
   * + It computes the `matrix-header` area as a separator for the COMMON category
   * @returns {string} The computed `grid-template-columns` style property
   * @see [grid-template-columns documentation]{@link https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns}
   * @see [repeat CSS function]{@link https://developer.mozilla.org/en-US/docs/Web/CSS/repeat}
   */
  get gridTemplateColumnsStyleProperty () {
    const { matrixStore: { destinationCategories } } = this

    if (destinationCategories.size > 0) {
      return `repeat(${destinationCategories.size * 2}, max-content)`
    } else {
      return 'min-content'
    }
  }

  /**
   * Gets the grid-layout style of the matrix
   * @returns {Object} The computed grid-layout styles
   * @see [The CSS Grid Layout guide]{@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout}
   * @see [A complete guide about the Grid Layout]{@link https://css-tricks.com/snippets/css/complete-guide-grid/}
   */
  get gridLayoutStyle () {
    return {
      display: 'grid',
      columnGap: `${this.innerGap}px`,
      rowGap: `${this.innerGap}px`,
      gridTemplateAreas: this.gridTemplateAreasStyleProperty,
      gridTemplateColumns: this.gridTemplateColumnsStyleProperty
    }
  }
}

export default LayoutHelper

import Quiddity from '@models/quiddity/Quiddity'
import Contact from '@models/sip/Contact'
import Shmdata from '@models/shmdata/Shmdata'

import { EXTERNAL_SHMDATA_SOURCE_KIND_ID } from '@models/quiddity/specialQuiddities'

/**
 * @classdesc Wraps switcher models in a single Matrix entry
 * @memberof models
 */
class MatrixEntry {
  /** @property {models.Quiddity} [quiddity=null] A quiddity that implies a matrix entry */
  quiddity = null

  /** @property {models.Contact} [contact=null] A contact that implies a matrix entry */
  contact = null

  /** @property {models.Shmdata} [shmdata=null] An array of shmdatas */
  shmdatas = null

  /**
   * Instantiates a new MatrixEntry from a quiddity or a sip contact
   * @param {models.Quiddity} [quiddity] - A matrix quiddity
   * @param {models.Contact} [contact] - A matrix contact
   * @param {models.Shmdata} [shmdatas] - An array of shmdatas produced by the attached quiddity
   * @throws {TypeError} Will throw an error if the quiddity is not set
   */
  constructor (quiddity, contact = null, shmdatas = []) {
    if (quiddity instanceof Quiddity) {
      this.quiddity = quiddity
    } else {
      throw new TypeError('A matrix entry must contain a quiddity')
    }

    this.contact = contact
    this.shmdatas = shmdatas
  }

  /**
   * Generates the ID for a MatrixEntry
   * @static
   * @param {module:models/quiddity.Quiddity|string} [quiddity] - A quiddity model or its ID
   * @param {module:models/sip.Contact|string} [contact] - A contact model or its ID
   * @param {module:models/shmdata.Shmdata|string} [shmdata] - A shmdata model or its ID
   * @returns {string} An unique matrix ID
   */
  static getId (quiddity, contact, shmdatas) {
    const ids = []

    if (quiddity instanceof Quiddity) {
      ids.push(quiddity.id)
    } else if (typeof quiddity === 'string') {
      ids.push(quiddity)
    }

    if (contact instanceof Contact) {
      ids.push(contact.userName)
    } else if (typeof contact === 'string') {
      ids.push(contact)
    }

    for (const shmdata of shmdatas) {
      if (shmdata instanceof Shmdata) {
        ids.push(shmdata.path)
      } else if (typeof shmdata === 'string') {
        ids.push(shmdata)
      }
    }

    return ids.join('-')
  }

  /**
   * Checks if an entry is a video entry and that it is encodable
   * @returns {boolean} Returns true if the entry is encodable
   */
  get isEncodableVideoSource () {
    let isEncodable = false
    for (const shmdata of this.shmdatas) {
      if (shmdata.caps.includes('video')) isEncodable = true
    }
    return isEncodable
  }

  /**
   * Wraps the id from the quiddity or from the contact
   * @returns {string} The id of the matrix entry
   */
  get id () {
    return MatrixEntry.getId(
      this.quiddity,
      this.contact,
      this.shmdatas
    )
  }

  /**
   * Gets the shmdata paths of the entry
   * @returns {Object?} An array containing the paths of each shmdata
   */
  get shmdataPaths () {
    const paths = []
    for (const shmdata of this.shmdatas) {
      paths.push(shmdata.path)
    }
    return paths
  }

  /**
   * Gets the media types of each shmdata of the entry
   * @returns {Object?} An array containing the media types of each of the wrapped shmdata
   */
  get mediaTypes () {
    const mediaTypes = []
    for (const shmdata of this.shmdatas) {
      mediaTypes.push(shmdata.mediaType)
    }
    return mediaTypes
  }

  /**
   * Gets the ID of the wrapped quiddity
   * @returns {string} ID of the quiddity
   */
  get quiddityId () {
    return this.quiddity.id
  }

  /**
   * Gets the contact URI of the contact entry
   * @returns {?string} URI of the wrapped contact
   */
  get contactUri () {
    return this.contact?.uri
  }

  /**
   * Gets the contact ID of the contact entry
   * @returns {?string} ID of the wrapped contact
   */
  get contactId () {
    return this.contact?.id
  }

  /**
   * Gets the contact name of the contact entry
   * @returns {?string} Name of the wrapped contact
   */
  get contactName () {
    return this.contact?.userName
  }

  /**
   * Gets the kind ID of the wrapped quiddity
   * @returns {string} Kind ID of the quiddity
   */
  get kindId () {
    return this.quiddity.kindId
  }

  /**
   * Checks if the matrix entry is a sip source
   * @returns {boolean} Returns true if the matrix entry is a SIP source
   */
  get isSipSource () {
    return this.quiddity.kindId === EXTERNAL_SHMDATA_SOURCE_KIND_ID
  }

  /**
   * Checks if the matrix entry is attached to a SIP contact
   * @returns {boolean} Returns true if the matrix entry is a SIP contact
   */
  get isContact () {
    return this.contact !== null
  }

  /**
   * Checks if the matrix entry has a shmdata
   * @returns {boolean} Returns true if the matrix entry has a shmdata
   */
  get hasShmdata () {
    return this.shmdatas.length !== 0
  }

  /**
   * Gets the default shmdata of an entry
   * @returns {?module:models/shmdata.Shmdata} The default shmdata model of the entry
   */
  get defaultShmdata () {
    return this.shmdatas[0]
  }

  /**
   * Gets the encoded video shmdata of an entry
   * @returns {?module:models/shmdata.Shmdata} The encoded shmdata model of the entry
   * @see https://gitlab.com/sat-mtl/tools/scenic/scenic/-/issues/379
   */
  get encodedVideoShmdata () {
    // gets only the first encoded shmdata. Right now we don't have anything that outputs two encoded
    // video streams but if it changes, it will invalidate this function.
    return this.shmdatas.filter(
      (shmdata) => shmdata.category === 'compressed video'
    )[0]
  }

  /**
   * Gets the default mediaType of an entry
   * @returns {string} The default mediaType of the entry
   */
  get defaultMediaType () {
    return this.mediaTypes[0]
  }

  /**
   * Gets the encoded mediaType of an entry
   * @returns {string} The encoded mediaType model of the entry
   */
  get encodedMediaType () {
    return this.mediaTypes.find(type => type.includes('h264'))
  }

  /**
   * Creates a MatrixEntry from a JSON object
   * @param {object} json - A JSON object
   * @returns {models.MatrixEntry} The MatrixEntry association
   */
  static fromJSON (json) {
    const { quiddity, contact, shmdatas } = json
    return new MatrixEntry(quiddity, contact, shmdatas)
  }
}

export default MatrixEntry

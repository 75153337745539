import React from 'react'
import { useTranslation } from 'react-i18next'

import HelpWrapper from '@components/wrappers/HelpWrapper'

import { Inputs } from '@sat-mtl/ui-components'

const { Field, Switch } = Inputs

/**
 * Field component that lets users toggle a boolean value
 * @memberof module:components/fields
 * @param {string} title - Title of the field
 * @param {string} description - Description of the field
 * @param {string} value - Value of the property
 * @param {boolean} disabled - Flags a disabled value
 * @param {function} onChange - Function triggered when the property is changed by the user
 * @param {string} status - Status of the form
 * @returns {external:ui-components/Inputs/Field} A field component
 */
function BooleanField ({ title, description, value, disabled, onChange, status }) {
  const { t } = useTranslation()

  const defaultLabel = t(
    'Toggle "{{- title}}" property',
    { title: t(title) }
  )

  return (
    <div className='BooleanField'>
      <HelpWrapper message={description ? t(description) : defaultLabel}>
        <Field
          status={status}
          disabled={disabled}
          title={t(title)}
          description={description ? t(description) : defaultLabel}
        >
          <Switch
            checked={value}
            onChange={toggle => onChange(toggle)}
          />
        </Field>
      </HelpWrapper>
    </div>
  )
}

export default BooleanField

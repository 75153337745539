class Memory {
  constructor (total, free, cached, buffers) {
    this.total = parseInt(total)
    this.free = parseInt(free)
    this.cached = parseInt(cached)
    this.buffers = parseInt(buffers)
  }

  get usagePercent () {
    return 100 - Math.round(100 * (this.cached + this.buffers + this.free) / this.total)
  }

  get readableUsagePercent () {
    return `${this.usagePercent}%`
  }

  static fromJSON (json) {
    const { total, free, cached, buffers } = json
    return new Memory(total, free, cached, buffers)
  }
}

export default Memory

import React, { useState, useContext, useEffect } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'

import { AppStoresContext } from '@components/App'

import { DELETE_SCENE_MODAL_ID, DeleteSceneModal } from '@components/modals/SceneModals'
import { DEFAULT_SCENE_ID } from '@models/userTree/Scene'

import StatusEnum from '@models/common/StatusEnum'
import { Common, Context, Inputs } from '@sat-mtl/ui-components'

const { Checkbox } = Inputs
const { ThemeContext } = Context
const { NavTab, Button, Icon } = Common
const { ScrollTabs, Tab, ExtraButton } = NavTab

/**
 * Special button used to display an Arrow to scroll on the right or on the left
 * @memberof module:components/bars.SceneTabBar
 * @param {boolean} disabled - Flag to disable the button (when the scrolling is unavailable)
 * @param {function} onClick - Function triggered when the button is clicked (executes the scrolling action)
 * @param {string} type - Type of the icon to display (arrow left or arrow right)
 * @returns {external:react/Component} The bi-directional scrolling arrow
 */
function ArrowButton ({ disabled, onClick, type }) {
  const cn = classNames(
    'arrow-button',
    { disabled: disabled }
  )

  const onArrowClick = () => {
    if (!disabled) onClick()
  }

  return (
    <div className={cn} onClick={onArrowClick}>
      <Icon type={type} />
    </div>
  )
}

/** Renders a blank addon */
function BlankAddon () {
  return (
    <div style={{ width: '20px' }} />
  )
}

/**
 * Renders the left arrow Button that allows the user to scroll tabs to the left
 * @memberof module:components/bars.SceneTabBar
 * @param {function} onClick - A function triggered when the user clicks on the button
 * @param {boolean} disabled - Flag that disables the button
 * @returns {external:react/Component} The scrolling left arrow button
 */
function LeftArrow ({ disabled, onClick }) {
  return (
    <ExtraButton disabled={disabled}>
      <ArrowButton
        type='left'
        disabled={disabled}
        onClick={onClick}
      />
    </ExtraButton>
  )
}

/**
 * Renders the right arrow Button that allows the user to scroll tabs to the right
 * @memberof module:components/bars.SceneTabBar
 * @param {function} onClick - A function triggered when the user clicks on the button
 * @param {boolean} disabled - Flag that disables the button
 * @returns {external:react/Component} The scrolling right arrow button
 */
function RightArrow ({ disabled, onClick }) {
  return (
    <ExtraButton disabled={disabled}>
      <ArrowButton
        type='right'
        disabled={disabled}
        onClick={onClick}
      />
    </ExtraButton>
  )
}

/**
 * Renders the Delete scene Button that allows the user to delete a scene
 * @memberof module:components/bars.SceneTabBar
 * @Selector `.DeleteSceneButton`
 * @param {function} onClick - A function triggered when the user clicks on the button
 * @returns {external:react/Component} The delete scene button
 */
function DeleteSceneButton ({ onClick }) {
  return (
    <div className='DeleteSceneButton close-button' onClick={onClick}>
      <Icon type='close' />
    </div>
  )
}

/**
 * Renders the Add scene Button that allows the user to add a scene
 * @memberof module:components/bars.SceneTabBar
 * @Selector `.AddSceneButton`
 * @param {function} onClick - A function triggered when the user clicks on the button
 * @returns {external:react/Component} The add scene button
 */
function AddSceneButton ({ onClick }) {
  return (
    <ExtraButton>
      <Button className='AddSceneButton' shape='circle' type='subtle' onClick={onClick}>
        <div style={{ width: '32px', height: '32px' }}>
          <Icon type='plus' />
        </div>
      </Button>
    </ExtraButton>
  )
}

/**
 * Renders the Addon that should be placed before a scene Tab
 * @memberof module:components/bars.SceneTabBar
 * @param {object} scene - The respective scene
 * @returns {external:react/Component} The scene addon before element
 */
const SceneAddonBefore = ({ scene }) => {
  const { sceneStore } = useContext(AppStoresContext)
  return (
    scene.id !== sceneStore.activeScene?.id
      ? <BlankAddon />
      : (
        <Checkbox
          status={StatusEnum.ACTIVE}
          size='tiny'
          shape='circle'
          checked
        />
        )
  )
}

/**
 * Renders the Addon that should be placed after a scene Tab
 * @memberof module:components/bars.SceneTabBar
 * @param {object} scene - The respective scene
 * @param {function} setSceneToDelete - A function that is triggered when a scene close button is clicked. It sets which scene needs to be deleted
 * @returns {external:react/Component} The scene addon before element
 */
const SceneAddonAfter = ({ scene, setSceneToDelete }) => {
  const { sceneStore, modalStore } = useContext(AppStoresContext)
  return (
    scene.id === DEFAULT_SCENE_ID || scene.id === sceneStore.activeScene?.id
      ? <BlankAddon />
      : (
        <DeleteSceneButton
          onClick={event => {
            event.stopPropagation()
            modalStore.setActiveModal(DELETE_SCENE_MODAL_ID)
            setSceneToDelete(scene)
          }}
        />
        )
  )
}

/**
 * Renders the Addon that should be placed before a Tab bar
 * @memberof module:components/bars.SceneTabBar
 * @param {number} scrollLeft - The amount of scrolling that needs to be applied
 * @returns {external:react/Component} The Tab addon before element
 */
const TabAddonBefore = ({ $overflow, scrollLeft, setScrollLeft }) => {
  return (
    $overflow && (
      <LeftArrow
        disabled={scrollLeft <= 0}
        onClick={() => setScrollLeft($overflow?.scrollLeft - 100)}
      />
    )
  )
}

/**
 * Renders the Addon that should be placed after a Tab bar
 * @memberof module:components/bars.SceneTabBar
 * @param {number} scrollLeft - The amount of scrolling that needs to be applied
 * @returns {external:react/Component} The Tab addon before element
 */
const TabAddonAfter = ({ $overflow, scrollLeft, setScrollLeft }) => {
  const { sceneStore } = useContext(AppStoresContext)
  return (
    $overflow && (
      <>
        <RightArrow
          disabled={scrollLeft + $overflow?.offsetWidth >= $overflow?.scrollWidth}
          onClick={() => setScrollLeft($overflow?.scrollLeft + 100)}
        />
        <AddSceneButton
          onClick={() => sceneStore.applySceneCreation()}
        />
      </>
    )
  )
}

/**
 * Renders the scene tab bar that contains all tabs of user created scenes and the default scene tab
 * @selector `#SceneTabBar`
 * @returns {?external:mobx-react/ObserverComponent} The scene tab bar
 */
const SceneTabBar = observer(() => {
  const { sceneStore, modalStore } = useContext(AppStoresContext)
  const theme = useContext(ThemeContext)

  const [scrollLeft, setScrollLeft] = useState(0)
  const [$overflow, setOverflow] = useState(null)
  const [sceneToDelete, setSceneToDelete] = useState(null)

  useEffect(() => {
    setScrollLeft($overflow?.scrollWidth || 0)
  }, [sceneStore.userScenes.size])

  return (
    <div id='SceneTabBar' className={`SceneTabBar-${theme}`} style={{ display: 'none' }}>
      <DeleteSceneModal
        sceneToDelete={sceneToDelete}
        onSceneDelete={() => {
          sceneStore.applySceneRemoval(sceneToDelete.id)
          modalStore.cleanActiveModal(DELETE_SCENE_MODAL_ID)
        }}
      />

      <ScrollTabs
        scrollLeft={scrollLeft}
        onOverflowChange={(hasOverflown, $el) => {
          if (hasOverflown) {
            setOverflow($el)
          } else {
            setOverflow(null)
          }
        }}
        addonBefore={<TabAddonBefore $overflow={$overflow} scrollLeft={scrollLeft} setScrollLeft={setScrollLeft} />}
        addonAfter={<TabAddonAfter $overflow={$overflow} scrollLeft={scrollLeft} setScrollLeft={setScrollLeft} />}
      >
        {Array.from(sceneStore.userScenes.values()).map(scene => (
          <Tab
            key={scene.id}
            addonBefore={<SceneAddonBefore scene={scene} />}
            addonAfter={<SceneAddonAfter scene={scene} setSceneToDelete={setSceneToDelete} />}
            onClick={() => sceneStore.setSelectedScene(scene.id)}
            selected={scene.id === sceneStore.selectedScene?.id}
          >
            <Trans>
              {scene.name}
            </Trans>
          </Tab>
        ))}
        {$overflow
          ? null
          : (
            <ExtraButton>
              <Button className='AddSceneButton' shape='circle' type='subtle' onClick={() => sceneStore.applySceneCreation()}>
                <div style={{ width: '32px', height: '32px' }}>
                  <Icon type='plus' />
                </div>
              </Button>
            </ExtraButton>
            )}
      </ScrollTabs>
    </div>
  )
})

export default SceneTabBar

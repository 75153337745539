import { observable, computed, action, reaction, makeObservable } from 'mobx'

import QuiddityStore from '@stores/quiddity/QuiddityStore'
import OrderStore from '@stores/quiddity/OrderStore'
import ShmdataStore from '@stores/shmdata/ShmdataStore'
import ContactStore from '@stores/sip/ContactStore'
import SceneStore from '@stores/userTree/SceneStore'
import ConnectionStore from '@stores/userTree/ConnectionStore'
import LockStore from '@stores/matrix/LockStore'
import PropertyStore from '@stores/quiddity/PropertyStore'
import SipStore from '@stores/sip/SipStore'
import SettingStore from '@stores/common/SettingStore'
import EncoderStore from '@stores/quiddity/EncoderStore'
import CapsStore from '@stores/shmdata/CapsStore'

import { RTMP_KIND_ID, NDI_OUTPUT_KIND_ID, SIP_KIND_ID, EXTERNAL_SHMDATA_SOURCE_KIND_ID } from '@models/quiddity/specialQuiddities'

import MatrixCategoryEnum, { toDestinationKind } from '@models/matrix/MatrixCategoryEnum'
import MediaTypeEnum from '@models/shmdata/MediaTypeEnum'
import MatrixEntry from '@models/matrix/MatrixEntry'
import Connection from '@models/userTree/Connection'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the MatrixStore
 * @memberof module:stores/matrix.MatrixStore
 */
export const LOG = logger.child({ store: 'MatrixStore' })

/**
 * @classdesc Extra store used to build the Matrix component by adding abstractions on shmdatas, quiddities and contacts
 * This is the highest-level store for everything matrix-related. It should be the first store
 * you look at when trying to add another layer of complexity on top of the matrix.
 * This is one of the last store to be instantiated during the app's initialization.
 * @todo Wraps the categories as bindings
 * @memberof module:stores/matrix
 **/
class MatrixStore {
  /** @property {Map<string, module:models/matrix.MatrixEntry[]>} destinationCategories - All destinations mapped by category IDs */
  destinationCategories = new Map()

  /** @property {Map<string, module:models/matrix.MatrixEntry[]>} sourceCategories - All sources mapped by category IDs */
  sourceCategories = new Map()

  /** @property {module:models/matrix.MatrixEntry[]} sourceEntries - All the source entries */
  get sourceEntries () {
    const sources = []

    for (const [, entries] of this.sourceCategories) {
      sources.push(...entries)
    }

    return sources
  }

  /** @property {module:models/matrix.MatrixEntry[]} destinationEntries - All the destination entries */
  get destinationEntries () {
    const destinations = []

    for (const [, entries] of this.destinationCategories) {
      destinations.push(...entries)
    }

    return destinations
  }

  /** @property {module:models/matrix.MatrixEntry} matrixEntries - All the matrix entries hashed by entry ID */
  get matrixEntries () {
    const allEntries = [...this.destinationCategories, ...this.sourceCategories]
    const matrixEntries = new Map()

    for (const [, entries] of allEntries) {
      for (const entry of entries) {
        matrixEntries.set(entry.id, entry)
      }
    }
    return matrixEntries
  }

  /** @property {Set<string>} startableSources - All entries that are startable */
  get startableSources () {
    const { propertyStore } = this
    const startableSources = new Set()

    for (const [quiddityId, entrySet] of this.sourceQuiddities) {
      const entries = [...entrySet]

      if (propertyStore.isStartable(quiddityId) && entries.length > 0) {
        startableSources.add(entries[0])
      }
    }

    return startableSources
  }

  /** @property {Map<string, module:models/matrix.MatrixEntry[]>} matrixConnections - All connected entries of the matrix
   * @TODO : this does way too much work. This should look into the connection store to get existing connection
   * instead of querying the whole stack to recreate Connections objects
   */
  get matrixConnectionsFromEntries () {
    const { shmdataStore: { followerPaths } } = this
    const { contactStore: { contacts } } = this
    const matrixConnections = new Map()

    for (const srcEntry of this.sourceEntries) {
      for (const destEntry of this.destinationEntries) {
        const compatibleShmdata = this.findCompatibleShmdata(srcEntry, destEntry)
        const connection = Connection.fromMatrixEntries(srcEntry, destEntry, compatibleShmdata)

        if (!connection) continue

        const { quiddityId, contactId } = destEntry

        let isConnected = false

        if (destEntry.isContact) {
          isConnected = contacts.get(contactId)?.connections.includes(compatibleShmdata?.path)
        } else {
          isConnected = followerPaths.get(quiddityId)?.has(compatibleShmdata?.path)
        }

        if (isConnected) {
          matrixConnections.set(connection.id, [srcEntry, destEntry])
        }
      }
    }

    return matrixConnections
  }

  get activeMatrixConnections () {
    const { isSelectedSceneActive } = this.sceneStore
    const { lockableQuiddities } = this.lockStore

    const activeConnections = new Set()

    for (const [connectionId, entries] of this.matrixConnectionsFromEntries) {
      const [, destEntry] = entries

      if (isSelectedSceneActive || lockableQuiddities.has(destEntry.quiddityId)) {
        activeConnections.add(connectionId)
      }
    }

    return activeConnections
  }

  /** @property {Set<string>} lockableCategories - All categories that represents a lockable quiddity class */
  get lockableCategories () {
    const { lockStore: { lockableKindIds } } = this
    const lockableCategories = new Set()

    for (const [categoryId] of this.destinationCategories) {
      const destinationKind = toDestinationKind(categoryId)

      if (lockableKindIds.has(destinationKind)) {
        lockableCategories.add(categoryId)
      }
    }

    return lockableCategories
  }

  /** @property {Set<string>} lockedCategories - All categories that contains a locked entry */
  get lockedCategories () {
    const { lockStore: { lockedEntries } } = this
    const lockedCategories = new Set()

    for (const [categoryId, entries] of this.destinationCategories) {
      if (entries.some(entry => lockedEntries.has(entry.id))) {
        lockedCategories.add(categoryId)
      }
    }

    return lockedCategories
  }

  /** @property {Set<Connection>} lockedConnections - All connections that are locked in the matrix */
  get lockedConnections () {
    const { lockStore, connectionStore } = this
    const lockedConnections = new Set()

    for (const [, connection] of connectionStore.userConnections) {
      if (lockStore.isLockedConnection(connection)) {
        lockedConnections.add(connection)
      }
    }

    return lockedConnections
  }

  /** @property {Set<string>} lockedEntries - All IDs of all destinations that are locked and all sources that are connected to locked destinations */
  get lockedEntries () {
    const { lockStore: { lockedEntries } } = this
    const implicitLockedEntries = new Set()

    for (const connection of this.lockedConnections) {
      for (const entryId of this.sourceQuiddities.get(connection.sourceId)) {
        implicitLockedEntries.add(entryId)
      }
    }

    return new Set([
      ...lockedEntries.keys(),
      ...implicitLockedEntries
    ])
  }

  /** @property {Set<string>} lockedQuiddities - All locked quiddity IDs */
  get lockedQuiddities () {
    const lockedQuiddities = new Set()

    for (const entryId of this.lockedEntries) {
      const entry = this.matrixEntries.get(entryId)

      if (this.quiddityStore.userQuiddityIds.includes(entry.quiddityId)) {
        lockedQuiddities.add(entry.quiddityId)
      }
    }

    return lockedQuiddities
  }

  /** @property {Map<string, Set<string>>} destinationQuiddities - All entries hashed by destination quiddity IDs */
  get destinationQuiddities () {
    const { quiddityStore } = this
    const destinationQuiddities = new Map()

    for (const entry of this.destinationEntries) {
      if (!destinationQuiddities.has(entry.quiddityId)) {
        destinationQuiddities.set(entry.quiddityId, new Set())
      }

      if (quiddityStore.userQuiddityIds.includes(entry.quiddityId)) {
        destinationQuiddities.get(entry.quiddityId).add(entry.id)
      }
    }

    return destinationQuiddities
  }

  /** @property {Map<string, Set<string>>} sourceQuiddities - All entries ids hashed by source quiddity IDs */
  get sourceQuiddities () {
    const { quiddityStore } = this
    const sourceQuiddities = new Map()

    for (const entry of this.sourceEntries) {
      if (!sourceQuiddities.has(entry.quiddityId)) {
        sourceQuiddities.set(entry.quiddityId, new Set())
      }

      if (quiddityStore.userQuiddityIds.includes(entry.quiddityId)) {
        sourceQuiddities.get(entry.quiddityId).add(entry.id)
      }
    }

    return sourceQuiddities
  }

  /** @property {Map<string, Set<string>>} matrixQuiddities - All entries hashed by quiddity IDs */
  get matrixQuiddities () {
    return new Map([...this.sourceQuiddities].concat([...this.destinationQuiddities]))
  }

  // only called in tests
  /** @property {Map<string, Set<string>>} connectedQuiddities - All connections hashed by quiddity IDs */
  get connectedQuiddities () {
    const quiddityConnections = new Map()

    for (const [quiddityId, entryIds] of this.matrixQuiddities) {
      quiddityConnections.set(quiddityId, new Set())

      for (const [, [srcEntry, destEntry]] of this.matrixConnectionsFromEntries) {
        if (entryIds.has(srcEntry.id) || entryIds.has(destEntry.id)) {
          const compatibleShmdata = this.findCompatibleShmdata(srcEntry, destEntry)
          quiddityConnections.get(quiddityId).add(
            Connection.fromMatrixEntries(srcEntry, destEntry, compatibleShmdata)
          )
        }
      }
    }

    return quiddityConnections
  }

  /**
   * Instantiates a new MatrixStore
   * @param {module:stores/quiddity.QuiddityStore} quiddityStore - Quiddity manager
   * @param {module:stores/quiddity.PropertyStor} propertyStore - Property manager
   * @param {module:stores/quiddity.OrderStore} orderStore - Manages all order of entries
   * @param {module:stores/shmdata.ShmdataStore} shmdataStore - Shmdata manager
   * @param {module:stores/sip.ContactStore} contactStore - SIP contact manager
   * @param {module:stores/userTree.SceneStore} sceneStore - Scene manager
   * @param {module:stores/matrix.LockStore} lockStore - Lock manager
   * @param {module:stores/quiddity.EncoderStore} encoderStore - Encoders manager
   * @constructor
   * @throws {TypeError} Will throw an error if a dependency store is not set
   */
  constructor (quiddityStore, propertyStore, orderStore, shmdataStore, contactStore, sceneStore, connectionStore, lockStore, sipStore, settingStore, encoderStore, capsStore) {
    makeObservable(this, {
      destinationCategories: observable,
      sourceCategories: observable,
      sourceEntries: computed,
      destinationEntries: computed,
      matrixEntries: computed,
      startableSources: computed,
      matrixConnectionsFromEntries: computed,
      activeMatrixConnections: computed,
      lockableCategories: computed,
      lockedCategories: computed,
      lockedConnections: computed,
      lockedEntries: computed,
      lockedQuiddities: computed,
      setSourceCategories: action,
      setDestinationCategories: action
    })

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new TypeError('QuiddityStore is required')
    }

    if (propertyStore instanceof PropertyStore) {
      this.propertyStore = propertyStore
    } else {
      throw new TypeError('PropertyStore is required')
    }

    if (orderStore instanceof OrderStore) {
      this.orderStore = orderStore
    } else {
      throw new TypeError('OrderStore is required')
    }

    if (shmdataStore instanceof ShmdataStore) {
      this.shmdataStore = shmdataStore
    } else {
      throw new TypeError('ShmdataStore is required')
    }

    if (contactStore instanceof ContactStore) {
      this.contactStore = contactStore
    } else {
      throw new TypeError('ContactStore is required')
    }

    if (sceneStore instanceof SceneStore) {
      this.sceneStore = sceneStore
    } else {
      throw new TypeError('SceneStore is required')
    }

    if (connectionStore instanceof ConnectionStore) {
      this.connectionStore = connectionStore
    } else {
      throw new TypeError('ConnectionStore is required')
    }

    if (lockStore instanceof LockStore) {
      this.lockStore = lockStore
    } else {
      throw new TypeError('LockStore is required')
    }

    if (sipStore instanceof SipStore) {
      this.sipStore = sipStore
    } else {
      throw new TypeError('SipStore is required')
    }

    if (settingStore instanceof SettingStore) {
      this.settingStore = settingStore
    } else {
      throw new TypeError('SettingStore is required')
    }

    if (encoderStore instanceof EncoderStore) {
      this.encoderStore = encoderStore
    } else {
      throw new TypeError('EncoderStore is required')
    }

    if (capsStore instanceof CapsStore) {
      this.capsStore = capsStore
    } else {
      throw new TypeError('CapsStore is required')
    }

    reaction(
      () => [...this.orderStore.sortedSources.values()],
      () => this.populateSourceCategories()
    )

    reaction(
      () => [...this.quiddityStore.sources],
      () => this.populateSourceCategories()
    )

    reaction(
      () => [...this.orderStore.destinationOrders.values()],
      () => this.populateDestinationCategories()
    )

    /** @todo Fix broken reactions with Map iterators */
    reaction(
      () => this.shmdataStore.writingShmdatas.values(),
      () => this.populateSourceCategories()
    )

    reaction(
      () => this.contactStore.partners.size,
      () => this.populateDestinationCategories()
    )

    reaction(
      () => this.settingStore.displayEncoders,
      () => this.populateSourceCategories()
    )
  }

  /** Populates the source categories */
  populateSourceCategories () {
    this.setSourceCategories(this.makeSourceCategories())
  }

  /** Populates the destination categories */
  populateDestinationCategories () {
    this.setDestinationCategories(this.makeDestinationCategories())
  }

  /**
   * Sets the source categories
   * @param {Map<string, module:models/matrix.MatrixEntry[]>} categories - A map for the source categories
   */
  setSourceCategories (categories) {
    this.sourceCategories = categories
  }

  /**
   * Sets the destination categories
   * @param {Map<string, module:models/matrix.MatrixEntry[]>} categories - A map for the destination categories
   */
  setDestinationCategories (categories) {
    this.destinationCategories = categories
  }

  /**
   * Gets the compatible mediaType of an entry
   * @returns {string} The compatible mediaType of the entry
   */
  findCompatibleMediaType (sourceEntry, destinationEntry) {
    return this.capsStore.findCompatibleCaps(sourceEntry.quiddityId, destinationEntry.quiddityId)?.mediaType
  }

  /**
   * Gets the compatible shmdata of an entry
   * @returns {?module:models/shmdata.Shmdata} The compatible shmdata model of the entry
   */
  findCompatibleShmdata (sourceEntry, destinationEntry) {
    let compatibleShmdata = sourceEntry.defaultShmdata
    if (sourceEntry.isEncodableVideoSource && !sourceEntry.isSipSource) {
      if ([RTMP_KIND_ID, SIP_KIND_ID].includes(destinationEntry.kindId)) {
        compatibleShmdata = sourceEntry.encodedVideoShmdata
      }
    }
    return compatibleShmdata
  }

  /**
   * Gets all entries associated with a destination quiddity
   * @returns {module:models/matrix.MatrixEntry[]} All associated entries
   */
  getAllDestinationQuiddityEntries () {
    return this.orderStore.sortedDestinations
      .map(quiddity => MatrixEntry.fromJSON({ quiddity }))
  }

  /**
   * Gets all destination entries associated with the SIP id
   * @returns {module:models/matrix.MatrixEntry[]} All associated entries
   */
  getDestinationContactEntries () {
    const { quiddityStore: { quiddities } } = this
    const quiddity = quiddities.get(this.sipStore.sipId)

    let entries = []

    try {
      entries = Array.from(this.contactStore.sessionContacts.values())
        .map(contact => MatrixEntry.fromJSON({ quiddity, contact }))
    } catch (error) {
      LOG.error({
        msg: 'Failed to create entries from contacts without the SIP quiddity',
        error: error.message
      })
    }

    return entries
  }

  /**
   * Makes all destination categories
   * @returns {Map<string, module:models/matrix.MatrixEntry[]>} A map of all destinations by categories
   */
  makeDestinationCategories () {
    const { orderStore, contactStore } = this
    const categories = new Map()

    if (orderStore.sortedDestinations.length > 0) {
      const commonEntries = []
      const ndiEntries = []
      const rtmpEntries = []

      for (const entry of this.getAllDestinationQuiddityEntries()) {
        switch (entry.quiddity.kindId) {
          case NDI_OUTPUT_KIND_ID:
            ndiEntries.push(entry)
            break
          case RTMP_KIND_ID:
            rtmpEntries.push(entry)
            break
          default:
            commonEntries.push(entry)
        }
      }

      if (commonEntries.length > 0) {
        categories.set(MatrixCategoryEnum.COMMON, commonEntries)
      }

      if (ndiEntries.length > 0) {
        categories.set(MatrixCategoryEnum.NDI, ndiEntries)
      }

      if (rtmpEntries.length > 0) {
        categories.set(MatrixCategoryEnum.RTMP, rtmpEntries)
      }
    }

    if (contactStore.sessionContacts.size > 0) {
      categories.set(MatrixCategoryEnum.SIP, this.getDestinationContactEntries())
    }

    return categories
  }

  /**
   * Makes all source categories. Contrary to what the name might imply, this is called everytime a quiddity changes
   * @todo Refactor the matrix cycle in order to add a better control on the UI updates
   * @returns {Map<string, module:models/matrix.MatrixEntry[]>} A map of all sources by categories
     This is not the best place for this but its the best one we have right now.
     We would need to be able to categorize sources in a store that is hierarchically much
     closer to the quiddity store but we cannot at this moment because we have inconsistent
     state everywhere. See https://gitlab.com/sat-mtl/tools/scenic/scenic/-/issues/340 and
     the https://gitlab.com/sat-mtl/tools/scenic/scenic/-/tree/feat/refactor-order-store-for-classified-sources branch
     for more details.
   */
  makeSourceCategories () {
    const categories = new Map()

    if (this.orderStore.sortedSources.length > 0) {
      const allSources = this.getAllSourceQuiddityEntries()
      // non external sources are common
      categories.set(
        MatrixCategoryEnum.COMMON, allSources.filter(source => source.quiddity.kindId !== EXTERNAL_SHMDATA_SOURCE_KIND_ID)
      )
      // We need this because we always want to present external source in the same order so
      // we apply some sorting before putting them back in the categories map.
      const tempCategorizedSources = new Map()
      // external sources are classified by caller uri
      const externalSources = allSources.filter(source => source.quiddity.kindId === EXTERNAL_SHMDATA_SOURCE_KIND_ID)
      for (const externalSource of externalSources) {
        const sourceCallerURI = this.sipStore.getCallerURI(externalSource.quiddity.id)
        if (!tempCategorizedSources.has(sourceCallerURI)) {
          tempCategorizedSources.set(sourceCallerURI, [])
        }
        tempCategorizedSources.get(sourceCallerURI).push(externalSource)
      }

      for (const category of Array.from(tempCategorizedSources.keys()).sort()) {
        // This sorts by extshmsrc category. It should group similar source together
        const sortedExternalSources = tempCategorizedSources.get(category).sort(
          (a, b) => {
            let aCategory = null
            let bCategory = null
            for (const shmdatapath of a.shmdataPaths) {
              aCategory = a.quiddity.infoTree.shmdata?.writer[shmdatapath]?.category
            }
            for (const shmdatapath of b.shmdataPaths) {
              bCategory = b.quiddity.infoTree.shmdata?.writer[shmdatapath]?.category
            }
            if (aCategory === bCategory) {
              return 0
            } else if (aCategory < bCategory) {
              return -1
            } else {
              return 1
            }
          })

        categories.set(category, sortedExternalSources)
      }
    }
    return categories
  }

  /**
   * Checks if a shmdata should be displayed
   * @returns {Boolean} - Returns true if the shmdata is that of an encoder with displayEncoders params set to true or if the shmdata is that of non encoder
   */
  isShmdataDisplayed (shmdata) {
    return (shmdata.mediaType === MediaTypeEnum.VIDEO_H264 && this.settingStore.displayEncoders) ||
           (shmdata.mediaType !== MediaTypeEnum.VIDEO_H264)
  }

  /**
   * Gets all entries associated with a source quiddity
   * @param {module:models/quiddity.Quiddity} quiddity - The source quiddity
   * @returns {module:models/matrix.MatrixEntry[]} All associated entries
   */
  getSourceQuiddityEntries (quiddity) {
    const { writingShmdatas } = this.shmdataStore
    const entries = []

    if (writingShmdatas.has(quiddity.id)) {
      const shmdatas = Array.from(writingShmdatas.get(quiddity.id))

      if (this.encoderStore?.isEncoderDisplayed(quiddity)) {
        for (const shmdata of shmdatas) {
          entries.push(
            MatrixEntry.fromJSON({ quiddity, shmdatas: [shmdata] })
          )
        }
      } else {
        entries.push(
          MatrixEntry.fromJSON({ quiddity, shmdatas })
        )
      }
    } else {
      entries.push(
        MatrixEntry.fromJSON({ quiddity })
      )
    }

    return this.applyEntrySorting(entries)
  }

  /**
   * Gets all entries produced by the quiddity sources
   * @returns {module:models/models.MatrixEntry[]} All source matrix entries
   */
  getAllSourceQuiddityEntries () {
    const entries = []

    for (const quiddity of this.orderStore.sortedSources) {
      entries.push(...this.getSourceQuiddityEntries(quiddity))
    }

    return entries
  }

  /**
   * Sorts entries by setting the VIDEO_RAW media type in first position
   * @param {module:models/matrix.MatrixEntry[]} entries - All the entries to sort
   * @returns {module:models/matrix.MatrixEntry[]} All sorted entries
   */
  applyEntrySorting (entries) {
    return entries.sort((entryA, entryB) => {
      const { VIDEO_RAW } = MediaTypeEnum
      const mediaTypeA = entryA.mediaType

      if (mediaTypeA !== VIDEO_RAW) {
        return 1
      } else if (mediaTypeA === VIDEO_RAW) {
        return -1
      } else {
        return 0
      }
    })
  }
}

export default MatrixStore

import React, { useContext } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import LockedDestinationTooltip from '@components/tooltips/LockedDestinationTooltip'
import { Context, Layout } from '@sat-mtl/ui-components'
import { Lock } from '@components/common/LockIcons'

import '@styles/matrix/DestinationSeparator.scss'

const { ThemeContext } = Context
const { FlexRow } = Layout

/**
 * Renders a lock icon at the top of the separator
 * @param {module:stores/matrix.MatrixStore} matrixStore - Store all matrix properties
 * @param {string} categoryId - The destination category
 * @returns {external:mobx-react/ObserverComponent} The separator lock icon
 */
const SeparatorLockIcon = observer(({ matrixStore, categoryId }) => {
  const theme = useContext(ThemeContext)

  const cl = classNames(
    `SeparatorIcon-${theme}`,
    'SeparatorIcon'
  )

  return (
    <div className={cl}>
      <Lock />
    </div>
  )
})

/**
 * Renders the separator title
 * @param {string} title - Title of the separator
 * @returns {external:react/Component} The rendered title
 */
function SeparatorTitle ({ title }) {
  const { t } = useTranslation()

  return (
    <span>
      {t(title)}
    </span>
  )
}

/**
 * Renders the header at the top of the separator
 * @param {module:stores/matrix.MatrixStore} matrixStore - Store all matrix properties
 * @param {string} categoryId - The destination category
 * @returns {external:mobx-react/ObserverComponent} The separator header
 */
const SeparatorHeader = observer(({ matrixStore, categoryId }) => {
  let $icon = null

  if (matrixStore.lockedCategories.has(categoryId)) {
    $icon = (
      <SeparatorLockIcon
        matrixStore={matrixStore}
        categoryId={categoryId}
      />
    )
  }

  let $header = (
    <FlexRow columnGap={4}>
      <SeparatorTitle title={categoryId} />
      {$icon}
    </FlexRow>
  )

  if (matrixStore.lockedCategories.has(categoryId)) {
    $header = (
      <LockedDestinationTooltip categoryId={categoryId}>
        {$header}
      </LockedDestinationTooltip>
    )
  }

  return $header
})

/**
 * Renders the UI separator between matrix columns
 * @param {object} style - Custom style of the separator
 * @param {string} header - Custom header at the top of the separator
 * @param {string} color - Custom color of the separator
 * @returns {external:react/Component} The rendered separator
 */
function ColumnSeparator ({ style, header, color }) {
  const theme = useContext(ThemeContext)

  const cl = classNames(
    'ColumnSeparator',
    `ColumnSeparator-${theme}`
  )

  const topLineStyle = {}
  const bottomLineStyle = {}

  if (color) {
    topLineStyle.borderTopColor = color
    bottomLineStyle.borderLeftColor = color
  }

  return (
    <div className={cl} style={style}>
      <div className='SeparatorTop'>
        <div className='SeparatorTopLine' style={topLineStyle} />
        <div className='SeparatorHeader'>
          {header}
        </div>
      </div>
      <div className='SeparatorBottom'>
        <div className='SeparatorBottomLine' style={bottomLineStyle} />
      </div>
    </div>
  )
}

/**
 * Renders a ColumnSeparator for a destination category
 * @memberof module:components/matrix
 * @param {module:stores/matrix.MatrixStore} matrixStore - Manage all matrix properties
 * @param {string} categoryId - The destination category
 * @param {object} style - Custom CSS properties
 * @returns {external:react/Component} The rendered ColumnSeparator wrapped in an area
 */
function DestinationSeparator ({ matrixStore, categoryId, style }) {
  return (
    <ColumnSeparator
      style={style}
      header={
        <SeparatorHeader
          matrixStore={matrixStore}
          categoryId={categoryId}
        />
      }
    />
  )
}

export default DestinationSeparator

export {
  SeparatorLockIcon,
  SeparatorTitle,
  SeparatorHeader,
  ColumnSeparator
}

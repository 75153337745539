/* global */
import i18next from 'i18next'
import { observable, action, reaction, computed, makeObservable } from 'mobx'

import QuiddityStore from '@stores/quiddity/QuiddityStore'
import UserTreeStore from '@stores/userTree/UserTreeStore'

import FileBridge from '@models/common/FileBridge'
import Store from '@stores/Store'

import { logger } from '@utils/logger'
import { downloadFile, uploadFile } from '@utils/fileTools'
import { RequirementError } from '@utils/errors'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the SessionStore
 * @memberof stores.SessionStore
 */
export const LOG = logger.child({ store: 'SessionStore' })

/**
 * @classdesc Stores and manages sessions
 * @extends module:stores/common.Store
 * @memberof module:stores/common
 */
class SessionStore extends Store {
  /** @property {stores.NotificationStore} notificationStore - Stores the notifications */
  notificationStore = null

  /** @property {string} currentSessionId - ID of the currently selected session file */
  currentSessionId = null

  /** @property {Map<string, models.FileBridge>} sessions - Map of existing sessions hashed by ID */
  sessions = new Map()

  /** @property {Set<string>} sessionTrash - Set of all sessions to delete */
  sessionTrash = new Set()

  /** @property {?models.FileBridge} currentSession - File model of the current session */
  get currentSession () {
    return this.sessions.get(this.currentSessionId)
  }

  /**
   * Instantiates a new FileStore
   * @param {stores.SocketStore} socketStore - Stores and manages the current event-driven socket
   * @constructor
   */
  constructor (socketStore, quiddityStore, userTreeStore, lockStore) {
    super(socketStore)

    makeObservable(this, {
      currentSessionId: observable,
      sessions: observable,
      sessionTrash: observable,
      currentSession: computed,
      addSession: action,
      removeSession: action,
      setCurrentSession: action,
      clearCurrentSession: action,
      addSessionToTrash: action,
      removeSessionFromTrash: action,
      clearSessionTrash: action
    })

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new RequirementError('SessionStore', 'QuiddityStore')
    }

    if (userTreeStore instanceof UserTreeStore) {
      this.userTreeStore = userTreeStore
    } else {
      throw new RequirementError('SessionStore', 'UserTreeStore')
    }
    this.lockStore = lockStore

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   * @todo resolve the session mechanism
   */
  handleSocketChange (socket) {
    if (socket && this.socketStore.hasActiveAPIs) {
      const { sessionAPI } = this.socketStore.APIs

      sessionAPI.onSessionReset(
        () => this.handleSessionReset()
      )
    }
  }

  /**
   * Handles the import of a session file
   * @param {object} jsonFile - The JSON model of the session file
   */
  handleSessionImported (jsonFile) {
    this.updateSessionList()

    if (jsonFile) {
      LOG.info({
        notification: true,
        title: 'Success',
        msg: i18next.t("Session '{{id}}' has been imported from '{{- path}}'.", { id: jsonFile.id, path: jsonFile.path }),
        file: jsonFile
      })
    }
  }

  /** Handles a session file reset */
  handleSessionReset () {
    this.clearCurrentSession()
    this.quiddityStore.handleSessionChange(this.currentSession)
    this.userTreeStore.clear()
    this.lockStore.clear()

    LOG.info({
      notification: true,
      title: 'Success',
      msg: 'Session has been reset. All sources and destinations are now cleared.'
    })
  }

  /**
   * Handles the load of the session file
   * @param {object} jsonFile - The JSON model of the session file
   */
  handleSessionLoaded (name) {
    const loadedFile = this.sessions.get(name)

    if (loadedFile) {
      this.setCurrentSession(loadedFile.id)

      LOG.info({
        notification: true,
        title: 'Success',
        msg: i18next.t("Session '{{id}}' has been loaded. All sources and destinations are now created.", { id: loadedFile.id }),
        fileName: name
      })
    } else {
      LOG.error({
        notification: true,
        title: 'File loading failure',
        msg: 'The loaded file is not recognized by Scenic. Try to load another file.',
        fileName: name
      })
    }
  }

  /**
   * Handles the save of a session file
   * @param {object} jsonFile - The JSON model of the session file
   */
  handleSessionSaved (jsonFile) {
    try {
      const fileBridge = FileBridge.fromJSON(jsonFile)

      this.setCurrentSession(fileBridge.id)
      this.updateSessionList()

      LOG.info({
        notification: true,
        title: 'Success',
        msg: i18next.t("Session '{{id}}' has been saved.", { id: fileBridge.id }),
        file: jsonFile
      })
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'File saving failure',
        msg: i18next.t("An error occurred while saving '{{id}}' to '{{- path}}'.", { id: jsonFile.id, path: jsonFile.path }),
        error: error.message,
        file: jsonFile
      })
    }
  }

  /**
   * Handles the deletion of a session file
   * @param {string} fileName - The name of the file
   */
  handleSessionDeleted (fileName) {
    this.updateSessionList()

    LOG.info({
      notification: true,
      title: 'Success',
      msg: i18next.t("Session '{{filename}}' has been deleted.", { filename: fileName }),
      file: fileName
    })
  }

  /**
   * Updates the session file list
   * @async
   */
  async updateSessionList () {
    const fileNames = await this.fetchSessionList()

    for (const [sessionId] of this.sessions) {
      if (!fileNames.includes(sessionId)) {
        this.removeSession(sessionId)
      }
    }

    for (const fileName of fileNames) {
      this.addSession(fileName)
    }
  }

  /**
   * Fetches session file list on the server
   * @returns {model.FileBridge[]} List of all sessions in the server
   * @async
   */
  async fetchSessionList () {
    const { sessionAPI } = this.socketStore.APIs
    const bridges = []

    try {
      const fileNames = await sessionAPI.list()

      for (const fileName of fileNames) {
        bridges.push(FileBridge.fromJSON({ id: fileName }))
      }

      LOG.debug({
        msg: 'Successfully listed all saved sessions',
        fileNames: fileNames
      })
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'File management failure',
        msg: 'A server error occurred while fetching the file list.',
        error: error.message
      })
    }

    return bridges
  }

  /**
   * Fetches a session file content
   * @param {model.FileBridge} fileBridge - A file model
   * @returns {string} The content of the file
   * @async
   */
  async fetchSessionContent (fileBridge) {
    const { sessionAPI } = this.socketStore.APIs
    let data = ''

    try {
      data = await sessionAPI.read(fileBridge.name)

      LOG.debug({
        msg: 'Successfully read the file content',
        path: fileBridge.path,
        data: data
      })
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'File management failure',
        msg: 'A server error occurred while fetching the file.',
        path: fileBridge.path,
        error: error.message
      })
    }

    return data
  }

  /**
   * Requests the reset of the current Scenic session
   * @async
   */
  async applySessionReset () {
    const { sessionAPI } = this.socketStore.APIs

    try {
      await sessionAPI.reset()
      this.handleSessionReset()
    } catch (error) {
      LOG.error({
        msg: 'Failed to reset the session',
        error: error.message
      })
    }
  }

  /**
   * Requests the load of a session file
   * @param {models.FileBridge} fileBridge - File model to load
   * @async
   */
  async applySessionLoading (fileBridge) {
    const { sessionAPI } = this.socketStore.APIs

    try {
      // reset previous session before loading the new one
      await sessionAPI.reset()
      await sessionAPI.load(fileBridge.id)

      this.setCurrentSession(fileBridge.id)
      this.handleSessionLoaded(fileBridge.id)
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'File management failure',
        msg: 'A server error occurred while loading the file.',
        file: fileBridge.name,
        error: error.message
      })
    }
  }

  /**
   * Requests the save of the current session file
   * @async
   */
  async applySessionSaving () {
    const { sessionAPI } = this.socketStore.APIs

    try {
      if (!this.currentSessionId || !this.sessions.has(this.currentSessionId)) {
        throw new Error('Failed to save unknown file')
      } else {
        const currentFile = this.sessions.get(this.currentSessionId)
        await sessionAPI.saveAs(currentFile.name)
      }
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'File management failure',
        msg: 'A server error occurred while saving the file.',
        file: this.currentFileName,
        error: error.message
      })
    }
  }

  /**
   * Requests the deletion of all sessions
   * @async
   */
  async applyClearTrash () {
    for (const sessionId of this.sessionTrash) {
      await this.applySessionDeletion(sessionId)
    }

    this.clearSessionTrash()
  }

  /**
   * Downloads a session file stored on the server
   * @param {FileBridge} fileBridge - Model of the file to download
   * @param {HTMLElement} $linkRef - Reference to the download link
   */
  async applySessionDownload (fileBridge, $linkRef) {
    try {
      const content = await this.fetchSessionContent(fileBridge)
      downloadFile($linkRef, content)
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'File management failure',
        msg: 'A server error occurred while downloading the file.',
        error: error.message
      })
    }
  }

  /**
   * Requests the deletion of a session file
   * @param {string} sessionId - ID of the file
   * @async
   */
  async applySessionDeletion (sessionId) {
    const { sessionAPI } = this.socketStore.APIs

    try {
      if (this.sessions.has(sessionId)) {
        await sessionAPI.remove(sessionId)
        this.handleSessionDeleted(sessionId)
      } else {
        LOG.warn({
          notification: true,
          title: 'Skipping file deletion',
          msg: i18next.t("The file '{{sessionId}}' is not recognized by Scenic and can't be deleted.", { file: sessionId }),
          file: sessionId
        })
      }
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'File management failure',
        msg: 'A server error occurred while deleting the file.',
        file: sessionId,
        error: error.message
      })
    }
  }

  /**
   * Requests the save of a session file as
   * @param {string} fileName - Name of the new file
   * @todo Send a file model and force the use of the id instead of the name (sessions can't have the same name)
   * @async
   */
  async applySessionSavingAs (fileName) {
    const { sessionAPI } = this.socketStore.APIs

    try {
      await sessionAPI.saveAs(fileName)
      this.handleSessionSaved({ id: fileName })
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'File management failure',
        msg: 'A server error occurred while saving the file.',
        saveAs: fileName,
        error: error.message
      })
    }
  }

  /**
   * Uploads a session file and imports it on the server
   * @param {File} fileBlob - A generated blob from the WebAPI
   * @async
   */
  async applySessionUpload (fileBlob) {
    let bridge

    try {
      bridge = await uploadFile(fileBlob)
      await this.applySessionImport(bridge, bridge.content)
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'File management failure',
        msg: 'A server error occurred while uploading the file.',
        error: error.message
      })
    }
  }

  /**
   * Imports a session file onto the server
   * @param {models.FileBridge} fileBridge - Model of the file
   * @param {string} fileContent - Content of the file
   * @async
   */
  async applySessionImport (fileBridge, fileContent) {
    const { sessionAPI } = this.socketStore.APIs

    try {
      await sessionAPI.write(fileContent, fileBridge.name)
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'File management failure',
        msg: 'A server error occurred while importing the file.',
        file: fileBridge.id,
        error: error.message
      })
    }
  }

  /**
   * Adds a FileBridge model in the sessions Map
   * @param {models.FileBridge} session - Session file to add
   */
  addSession (session) {
    this.sessions.set(session.id, session)
  }

  /**
   * Deletes a stored FileBridge
   * @param {string} sessionId - ID of the session file bridge
   */
  removeSession (sessionId) {
    this.sessions.delete(sessionId)
  }

  /**
   * Set the current file ID
   * @param {string} sessionId - Session ID to set as current
   */
  setCurrentSession (sessionId) {
    this.currentSessionId = sessionId
  }

  /** Clears the current session file ID */
  clearCurrentSession () {
    this.currentSessionId = null
  }

  /**
   * Adds a file to the trash
   * @param {string} sessionId - ID of the session file
   */
  addSessionToTrash (sessionId) {
    this.sessionTrash.add(sessionId)
  }

  /**
   * Deletes a file from the trash
   * @param {string} sessionId - ID of the session file
   */
  removeSessionFromTrash (sessionId) {
    this.sessionTrash.delete(sessionId)
  }

  /** Clears the trash of sessions */
  clearSessionTrash () {
    this.sessionTrash.clear()
  }
}

export default SessionStore

import { Common } from '@sat-mtl/ui-components'
import { SendStatusEnum, RecvStatusEnum, BuddyStatusEnum } from '@models/sip/SipEnums'

/**
 * Define all adapters for the StatusEnum
 * @memberof module:models/common
 * @namespace StatusEnum
 */
const StatusEnum = Common.StatusEnum

/**
 * Makes the availability status of a contact
 * @memberof module:models/common.StatusEnum
 * @param {models.BuddyStatusEnum} buddyStatus - Status of a buddy
 * @returns {external:ui-components/Common/StatusEnum} The UI status
 */
function fromBuddyStatus (buddyStatus) {
  switch (buddyStatus) {
    case BuddyStatusEnum.ONLINE:
      return StatusEnum.ACTIVE
    case BuddyStatusEnum.OFFLINE:
      return StatusEnum.INACTIVE
    case BuddyStatusEnum.AWAY:
    case BuddyStatusEnum.BUSY:
      return StatusEnum.BUSY
    case BuddyStatusEnum.UNKNOWN:
    default:
      return StatusEnum.DANGER
  }
}

/**
 * Makes the receiving status of a contact
 * @memberof module:models/common.StatusEnum
 * @param {models.RecvStatusEnum} receivingStatus - Receiving status of a contact
 * @returns {external:ui-components/Common/StatusEnum} The UI status
 */
function fromRecvStatus (recvStatus) {
  switch (recvStatus) {
    case RecvStatusEnum.DISCONNECTED:
      return StatusEnum.INACTIVE
    case RecvStatusEnum.CONNECTING:
      return StatusEnum.BUSY
    case RecvStatusEnum.RECEIVING:
      return StatusEnum.ACTIVE
    case RecvStatusEnum.UNKNOWN:
    default:
      return StatusEnum.DANGER
  }
}

/**
 * Makes the sending status of a contact
 * @memberof module:models/common.StatusEnum
 * @param {models.SendStatusEnum} sendingStatus - Sending status of a contact
 * @returns {external:ui-components/Common/StatusEnum} The UI status
 */
function fromSendStatus (sendStatus) {
  switch (sendStatus) {
    case SendStatusEnum.DISCONNECTED:
      return StatusEnum.INACTIVE
    case SendStatusEnum.CONNECTING:
      return StatusEnum.BUSY
    case SendStatusEnum.CALLING:
      return StatusEnum.ACTIVE
    case SendStatusEnum.UNKNOWN:
    default:
      return StatusEnum.DANGER
  }
}

/**
 * Makes a status from a logging level
 * @memberof module:models/common.StatusEnum
 * @param {external:pino/level} logLevel - A logging level
 * @returns {external:ui-components/Common/StatusEnum} The UI status
 */
function fromLogLevel (logLevel) {
  switch (logLevel) {
    case 'info':
      return StatusEnum.FOCUS
    case 'error':
      return StatusEnum.DANGER
    case 'warn':
      return StatusEnum.BUSY
    default:
      return StatusEnum.INACTIVE
  }
}

export default StatusEnum

export {
  fromBuddyStatus,
  fromRecvStatus,
  fromSendStatus,
  fromLogLevel
}

/**
 * Enum for CPU statuses
 * @readonly
 * @memberof enums
 * @enum {string}
 */
const CpuStatusEnum = Object.freeze({
  FATAL: 'fatal',
  ALERT: 'alert',
  WARNING: 'warning',
  NORMAL: 'normal'
})

export default CpuStatusEnum

import { observable, computed, action, reaction, makeObservable } from 'mobx'

import InitStateEnum from '@models/common/InitStateEnum'
import CpuCore from '@models/systemUsage/CpuCore'
import Memory from '@models/systemUsage/Memory'
import NetworkInterface from '@models/systemUsage/NetworkInterface'

import ConfigStore from '@stores/common/ConfigStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import Store from '@stores/Store'

import { InitializationError, RequirementError } from '@utils/errors'
import { logger } from '@utils/logger'

import {
  SYSTEM_USAGE_NAME,
  SYSTEM_USAGE_KIND
} from '@models/quiddity/specialQuiddities'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the SystemUsageStore
 * @memberof stores.SystemUsageStore
 */
export const LOG = logger.child({ store: 'SystemUsageStore' })

/**
 * @constant {RegExp} SYSTEM_USAGE_TREE_REGEX - Matches all `top` changes of the SystemUsage quiddity
 * @memberof stores.SystemUsageStore
 */
export const SYSTEM_USAGE_TREE_REGEX = /^\.top\./

/**
 * @constant {string} LOCAL_INTERFACE - ID of the local network interface
 * @memberof stores.SystemUsageStore
 */
export const LOCAL_INTERFACE = 'lo'

/**
 * @classdesc Stores and manages the SystemUsage quiddity
 * @extends stores.Store
 * @memberof stores
 */
class SystemUsageStore extends Store {
  /** @property {models.Memory} memory - Current memory state */
  memory = null

  /**
   * @property {Object} selectedInterfaceOption - Current selected interface option
   * @property {string} selectedInterfaceOption.value - Value of the interface option
   * @property {string} selectedInterfaceOption.label - Label of the interface option
   */
  selectedInterfaceOption = {}

  /** @property {Map<models.NetworkInterface>} networkInterfaces - Current state of all network interfaces */
  networkInterfaces = new Map()

  /** @property {Map<models.CpuCore>} cpuCore - Current state of all cpu cores */
  cpuCores = new Map()

  /** @property {number} quiddityId - ID of the system usage quiddity */
  quiddityId = null

  /** @property {Object[]} interfaceOptions - All available options to select for the network interfaces */
  get interfaceOptions () {
    return Array.from(this.networkInterfaces)
      .filter(([id]) => id !== this.selectedInterfaceOption.value)
      .map(([id, inet]) => ({ value: id, label: inet.name }))
  }

  /** @property {models.NetworkInterface} selectedInterface - Current state of the selected network interface */
  get selectedInterface () {
    const { value } = this.selectedInterfaceOption
    let selectedInterface = null

    if (value) {
      selectedInterface = this.networkInterfaces.get(value)
    }

    return selectedInterface
  }

  /** @property {boolean} hasInterface - Checks if a network interface is available */
  get hasInterface () {
    return this.networkInterfaces.size > 0 && this.selectedInterface
  }

  /** @property {boolean} hasCpu - Checks if a CPU core is available */
  get hasCpu () {
    return this.cpuCores.size > 0
  }

  /**
   * Instantiates a new SystemUsageStore
   * @todo Reset system usage maps with 0 values
   * @param {stores.SocketStore} socketStore - Stores and manages the current event-driven socket
   * @param {stores.ConfigStore} configStore - Configuration manager
   * @param {stores.QuiddityStore} quiddityStore - Quiddity manager
   * @constructor
   */
  constructor (socketStore, configStore, quiddityStore) {
    super(socketStore)

    makeObservable(this, {
      memory: observable,
      selectedInterfaceOption: observable,
      networkInterfaces: observable,
      cpuCores: observable,
      interfaceOptions: computed,
      selectedInterface: computed,
      hasInterface: computed,
      hasCpu: computed,
      addCpuCore: action,
      addNetworkInterface: action,
      updateInterfaceSelection: action,
      updateMemoryUsage: action,
      clear: action
    })

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )

    if (configStore instanceof ConfigStore) {
      /** @property {stores.ConfigStore} configStore - Stores and manages app's configuration */
      this.configStore = configStore
    } else {
      throw new RequirementError(this.constructor.name, 'ConfigStore')
    }

    if (quiddityStore instanceof QuiddityStore) {
      /** @property {stores.QuiddityStore} quiddityStore - Stores and manages all quiddities */
      this.quiddityStore = quiddityStore

      reaction(
        () => this.quiddityStore.initState,
        state => this.handleQuiddityStoreInitialization(state)
      )
    } else {
      throw new RequirementError(this.constructor.name, 'QuiddityStore')
    }
  }

  /** @property {boolean} shouldBeInitialized - Checks if the SystemUsageStore should be initialized */
  get shouldBeInitialized () {
    return super.shouldBeInitialized && !this.quiddityStore.isNotInitialized()
  }

  /**
   * Initializes the system usage by fetching all states of the memory, cpu cores and network interfaces
   * @returns {boolean} Flags true if store is well initialized
   * @throws {Error} Will throw an error if the quiddity store is not initialized
   * @async
   */
  async initialize () {
    if (!this.isNotInitialized()) {
      return this.isInitialized()
    } else if (this.quiddityStore.isNotInitialized()) {
      throw new InitializationError('QuiddityStore')
    }

    if (!this.quiddityStore.usedKindIds.has(SYSTEM_USAGE_KIND)) {
      await this.fallbackSystemUsageQuiddity()
    }

    return this.applySuccessfulInitialization()
  }

  /**
   * Creates the systemusage quiddity
   * @async
   */
  async fallbackSystemUsageQuiddity () {
    try {
      const config = this.configStore.findInitialConfiguration(
        SYSTEM_USAGE_KIND,
        SYSTEM_USAGE_NAME
      )

      if (config) {
        this.quiddityId = await this.quiddityStore.applyQuiddityCreation(
          SYSTEM_USAGE_KIND,
          SYSTEM_USAGE_NAME,
          config.properties,
          config.userTree
        )
      } else {
        this.quiddityId = await this.quiddityStore.applyQuiddityCreation(
          SYSTEM_USAGE_KIND,
          SYSTEM_USAGE_NAME
        )
      }

      LOG.debug({
        msg: 'Successfully fallen back quiddity creation',
        quiddity: SYSTEM_USAGE_NAME
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to fallback quiddity creation',
        quiddity: SYSTEM_USAGE_NAME
      })
    }
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    const { quiddityStore, socketStore } = this

    this.clear()

    if (socket && socketStore.hasActiveAPIs) {
      const { infoTreeAPI } = socketStore.APIs

      infoTreeAPI.onGrafted(
        (quidId, path, tree) => this.handleGraftedTop(tree),
        quidId => quiddityStore.usedKinds.get(quidId)?.id === SYSTEM_USAGE_KIND,
        path => Array.isArray(path.match(SYSTEM_USAGE_TREE_REGEX))
      )
    }
  }

  /**
   * Handles quiddityStore's initialization state changes
   * @param {models.InitStateEnum} initState - State of the quiddityStore's initialization
   */
  handleQuiddityStoreInitialization (initState) {
    const { NOT_INITIALIZED } = InitStateEnum

    if (initState === NOT_INITIALIZED) {
      this.clear()
    }
  }

  /**
   * Updates all states when the tree of the quiddity SystemUsage is grafted
   * @param {Object} payload - The grafted tree
   */
  handleGraftedTop (payload) {
    if (payload.mem) {
      this.updateMemoryUsage(payload.mem)
    }

    if (payload.net) {
      this.updateNetworkInterfacesUsage(payload.net)
    }

    if (payload.cpu) {
      this.updateCpuUsage(payload.cpu)
    }
  }

  /**
   * Updates the state of all cpu cores
   * @param {Object} payload -  The JSON representation of the cpu cores
   */
  updateCpuUsage (payload) {
    try {
      const cpuCores = Object.keys(payload)
        .filter(key => key !== 'cpu')
        .map(key => CpuCore.fromJSON(key, payload[key]))

      for (const cpuCore of cpuCores) {
        this.addCpuCore(cpuCore)
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to update CPU cores',
        error: error.msg
      })
    }
  }

  /**
   * Updates the state of all network interfaces
   * @param {Object} payload - The JSON representation of the network interfaces
   */
  updateNetworkInterfacesUsage (payload) {
    const isFirstItem = this.networkInterfaces.size === 0

    try {
      for (const inet in payload) {
        const ipAddress = payload[inet].ip_address || this.socketStore.activeHost

        this.addNetworkInterface(
          NetworkInterface.fromJSON(inet, ipAddress, payload[inet])
        )

        if (isFirstItem && inet !== LOCAL_INTERFACE) {
          this.updateInterfaceSelection(inet)
        }
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to update network interfaces',
        error: error.message
      })
    }
  }

  /**
   * Adds a new cpu core in the store
   * @param {models.CpuCore} cpuCore - The new cpu core to store
   */
  addCpuCore (cpuCore) {
    const isFirstUpdate = !this.cpuCores.has(cpuCore.name)
    let shouldUpdate = true

    if (!isFirstUpdate) {
      const lastValue = this.cpuCores.get(cpuCore.name)
      shouldUpdate = lastValue.lastPercent !== cpuCore.percent
    }

    if (shouldUpdate) {
      this.cpuCores.set(cpuCore.name, cpuCore)

      if (isFirstUpdate) {
        LOG.debug({
          msg: 'Added a new CPU Core',
          name: cpuCore.name,
          cpuCore: cpuCore
        })
      }
    }
  }

  /**
   * Adds a new network interface in the store
   * @param {models.NetworkInterface} networkInterface - The new network interface to store
   */
  addNetworkInterface (networkInterface) {
    const isFirstUpdate = !this.networkInterfaces.has(networkInterface.name)

    this.networkInterfaces.set(networkInterface.name, networkInterface)

    if (isFirstUpdate) {
      LOG.info({
        msg: 'Added a new network interface',
        name: networkInterface.name,
        ipAddress: networkInterface.ipAddress,
        interface: networkInterface
      })
    }
  }

  /**
   * Updates the selected network interface
   * @param {string} id - ID of the selected network interface option
   */
  updateInterfaceSelection (id) {
    const oldSelected = this.selectedInterface

    if (this.networkInterfaces.has(id)) {
      const net = this.networkInterfaces.get(id)

      this.selectedInterfaceOption = {
        value: id,
        label: net.name
      }

      LOG.debug({
        msg: 'Switched network interface',
        old: oldSelected,
        new: this.selectedInterface
      })
    }
  }

  /**
   * Updates the current state of the memory
   * @param {Object} payload - The JSON representation of the memory
   */
  updateMemoryUsage (payload) {
    const isFirstUpdate = !this.memory
    this.memory = Memory.fromJSON(payload)

    if (isFirstUpdate) {
      LOG.debug({
        msg: 'Added memory usage',
        memory: this.memory
      })
    }
  }

  /**
   * Cleans up SystemUsageStore
   */
  clear () {
    const { NOT_INITIALIZED } = InitStateEnum
    this.setInitState(NOT_INITIALIZED)

    this.networkInterfaces.clear()
    this.cpuCores.clear()
    this.memory = null

    LOG.debug({
      msg: 'Successfully cleared all top data'
    })
  }
}

export default SystemUsageStore

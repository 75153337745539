/* global FileReader */

import FileBridge from '@models/common/FileBridge'

/**
 * Helper that uploads a selected local file
 * @param {Blob} localFile - The local file's blob
 * @param {Function} onUploadFinished - Function triggered when the upload is finished
 */
export async function uploadFile (fileBlob) {
  const reader = new FileReader()
  const bridge = FileBridge.fromFileBlob(fileBlob)

  bridge.content = await new Promise((resolve, reject) => {
    try {
      reader.onload = (event) => {
        resolve(event.target.result)
      }

      reader.readAsText(fileBlob)
    } catch (error) {
      reject(error)
    }
  })

  return bridge
}

/**
 * Helper that downloads some content from a link
 * @param {React.Ref} linkRef - A reference to the component that starts the download process
 * @param {Function} onDownloadStarted - Function triggered when the donwload is started. It must return the downloadable content
 * @param {string} [dataType='text/plain'] - Type of the downloaded data
 */
export async function downloadFile (linkRef, content, dataType = 'text/plain') {
  linkRef.setAttribute(
    'href',
    `data:${dataType};charset=utf-8,${encodeURIComponent(content)}`
  )

  linkRef.click()
}

import { observable, action, reaction, computed, makeObservable } from 'mobx'

import Stat from '@models/shmdata/Stat'

import Store from '@stores/Store'

import ShmdataStore, { TREE_PATH_REGEX } from '@stores/shmdata/ShmdataStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the StatStore
 * @memberof stores.StatStore
 */
export const LOG = logger.child({ store: 'StatStore' })

/**
 * @constant {models.Stat} DEFAULT_STAT - Default stat when it is initialized or null
 * @memberof stores.StatStore
 */
export const DEFAULT_STAT = new Stat(0, 0)

/**
 * @classdesc Store all shmdata stats
 * @extends stores.Store
 * @memberof stores
 */
class StatStore extends Store {
  /** @property {Map<string, models.Stat>} stats - All stats by shmdata path */
  stats = new Map()

  /** @property {Map<string, string>} bitrates - All bitrates of shmdatas */
  get bitrates () {
    const bitrates = new Map()

    for (const shmPath of this.shmdataStore.allShmdataPaths) {
      bitrates.set(shmPath, this.stats.get(shmPath)?.mbpsLabel ?? DEFAULT_STAT.mbpsLabel)
    }

    return bitrates
  }

  /**
   * Instantiates a new StatStore
   * @param {stores.SocketStore} socketStores - Socket manager
   * @param {stores.QuiddityStore} quiddityStore - Quiddity manager
   * @param {stores.ShmdataStore} shmdataStore - Shmdata manager
   * @constructor
   */
  constructor (socketStore, quiddityStore, shmdataStore) {
    super(socketStore)

    makeObservable(this, {
      stats: observable,
      bitrates: computed,
      removeStat: action,
      setStat: action,
      clear: action
    })

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new TypeError('StatStore requires a QuiddityStore')
    }

    if (shmdataStore instanceof ShmdataStore) {
      this.shmdataStore = shmdataStore
    } else {
      throw new TypeError('StatStore requires a ShmdataStore')
    }

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()

    if (socket && this.socketStore.hasActiveAPIs) {
      const { infoTreeAPI } = this.socketStore.APIs

      infoTreeAPI.onGrafted(
        (quiddityId, path, value) => this.handleGraftedStat(path, value),
        () => true,
        (path) => Array.isArray(path.match(TREE_PATH_REGEX))
      )

      infoTreeAPI.onPruned(
        (quiddityId, path) => this.handlePrunedStat(path),
        () => true,
        (path) => Array.isArray(path.match(TREE_PATH_REGEX))
      )
    }
  }

  /**
   * Checks if a shmdata path has an active stat
   * @param {string} shmdataPath - Path of the shmdata
   * @returns {boolean} Returns true if the shmdata has an active stat
   */
  isStatActive (shmdataPath) {
    return this.stats.has(shmdataPath) &&
      this.stats.get(shmdataPath).isActive
  }

  /**
   * Gets all inactive shmdatas written or read by a quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @returns {Array<models.Shmdata>} All the inactive shmdata
   */
  getInactiveShmdatas (quiddityId) {
    const { shmdataStore } = this
    const shmdataSet = shmdataStore.getShmdataSet(quiddityId)
    const inactiveShmdatas = []

    for (const shmdata of shmdataSet) {
      if (!this.isStatActive(shmdata.path)) {
        inactiveShmdatas.push(shmdata)
      }
    }

    return inactiveShmdatas
  }

  /**
   * Handles the grafted tree
   * @param {string} treePath - Path of the grafted tree
   * @param {Object} json - Grafted value
   * @returns {boolean} Returns true if a stat is added
   */
  handleGraftedStat (treePath, json) {
    const [,, shmdataPath, isStat] = treePath.match(TREE_PATH_REGEX)
    let jsonStat = null

    if (isStat) {
      jsonStat = json
    } else if (json && json.stat) {
      jsonStat = json.stat
    }

    if (jsonStat) {
      const stat = this.makeStatModel(jsonStat, shmdataPath)

      if (this.isStatUpdated(shmdataPath, stat)) {
        this.setStat(shmdataPath, stat)
      }
    }
  }

  /**
   * Handles the pruned tree
   * @param {string} treePath - Path of the pruned tree
   * @returns {boolean} Returns true if a stat is deleted
   */
  handlePrunedStat (treePath) {
    const [,, shmdataPath] = treePath.match(TREE_PATH_REGEX)

    if (this.stats.has(shmdataPath)) {
      this.removeStat(shmdataPath)
    }
  }

  /**
   * Creates a Stat model from a json tree branch
   * @param {Object} json - Branch of the tree
   * @param {string} [shmdataPath] - The path of the shmdata
   * @returns {Stat} Current stat of the quiddity's shmdata
   */
  makeStatModel (json, shmdataPath) {
    let stat = DEFAULT_STAT

    try {
      stat = Stat.fromJSON(json)
    } catch (error) {
      LOG.error({
        msg: 'Received an invalid shmdata Stat',
        json: json,
        shmdata: shmdataPath
      })
    }

    return stat
  }

  /**
   * Flags if a status update has to be done
   * @param {string} quiddityId - Updated quiddity's shmdata
   * @param {Stat} newStat - Updated shmdata's Stat
   * @returns {boolean} True if the update is accepted
   */
  isStatUpdated (shdmataPath, newStat) {
    if (this.stats.has(shdmataPath)) {
      return newStat !== this.stats.get(shdmataPath)
    } else {
      return true
    }
  }

  /**
   * Clean a shmdata status
   * @param {string} shmdataPath - Path of the updated shmdata
   * @returns {boolean} - Flags true if the shmdata status is cleaned
   */
  removeStat (shmdataPath) {
    this.stats.delete(shmdataPath)

    LOG.info({
      msg: 'Deleted a shmdata stat',
      shmdata: shmdataPath
    })
  }

  /**
   * Updates a shmdata status
   * @param {string} shmdataPath - Path of the updated shmdata
   * @param {model.Stat} newStat - The updated stat
   * @returns {boolean} - Flags true if the stat has been added
   */
  setStat (shmdataPath, newStat) {
    this.stats.set(shmdataPath, newStat)

    LOG.debug({
      msg: `Updated stat for shmdata ${shmdataPath}`,
      shmdata: shmdataPath,
      stat: newStat
    })
  }

  /** Cleans up stats */
  clear () {
    this.stats.clear()

    LOG.info({
      msg: 'Successfully cleared all stats'
    })
  }
}

export default StatStore

import defaultScenic from '@assets/json/scenic.default.json'

/**
 * @constant {string} NDI_SNIFFER_KIND_ID - Kind ID of the NDI Sniffer quiddity
 * @memberof module:models/quiddity.specialQuiddities
 */
export const NDI_SNIFFER_KIND_ID = 'scenicNdiSniffer'

/**
 * @constant {string} NDI_SNIFFER_NICKNAME - Nickname of the NDI Sniffer quiddity
 * @memberof module:models/quiddity.specialQuiddities
 */
export const NDI_SNIFFER_NICKNAME = 'ndiSniffer'

/**
 * @constant {string} NDI_INPUT_KIND_ID - Kind ID of the NDI Input quiddity
 * @memberof module:models/quiddity.specialQuiddities
 */
export const NDI_INPUT_KIND_ID = 'ndiInput'

/**
 * @constant {string} NDI_OUTPUT_KIND_ID - Kind ID of the NDI Output quiddity
 * @memberof module:models/quiddity.specialQuiddities
 */
export const NDI_OUTPUT_KIND_ID = 'ndiOutput'

/**
 * @constant {string} RTMP_NICKNAME - Nickname of the RTMP quiddity
 * @memberof module:models/quiddity.specialQuiddities
 */
export const RTMP_NICKNAME = 'RTMP'

/**
 * @constant {string} RTMP_KIND_ID - Kind ID of the RTMP quiddity
 * @memberof module:models/quiddity.specialQuiddities
 */
export const RTMP_KIND_ID = 'rtmpOutput'

/**
 * @constant {string} SIP_KIND_ID - Kind ID of the SIP quiddity
 * @memberof module:models/quiddity.specialQuiddities
 */
export const SIP_KIND_ID = 'sip'

/**
 * @constant {string} SIP_NICKNAME - Nickname of the SIP quiddity
 * @memberof module:models/quiddity.specialQuiddities
 */
export const SIP_NICKNAME = 'sip'

/**
 * @constant {string} SIP_ID - ID of the SIP quiddity
 * @memberof module:models/quiddity.specialQuiddities
 */
export const SIP_ID = 'sip'

/**
 * @constant {string} EXTERNAL_SHMDATA_SOURCE_KIND_ID - Nickname of the sip source quiddity
 * @memberof module:models/quiddity.specialQuiddities
 */
export const EXTERNAL_SHMDATA_SOURCE_KIND_ID = 'extshmsrc'

/**
 * @constant {string} SYSTEM_USAGE_KIND - Kind of the SystemUsage quiddity
 * @memberof stores.SystemUsageStore
 */
export const SYSTEM_USAGE_KIND = 'systemusage'

/**
 * @constant {string} SYSTEM_USAGE_NAME - Name of the SystemUsage quiddity
 * @memberof stores.SystemUsageStore
 */
export const SYSTEM_USAGE_NAME = 'systemUsage'

/**
 * @constant {string} USER_TREE_KIND_ID - Kind of the userTree quiddity
 * @memberof module:stores/userTree.UserTreeStore
 */
export const USER_TREE_KIND_ID = 'emptyquid'

/**
 * @constant {string} USER_TREE_NICKNAME - Name of the userTree quiddity
 * @memberof module:stores/userTree.UserTreeStore
 */
export const USER_TREE_NICKNAME = 'userTree'

/**
 * @constant {string} USER_TREE_CONFIG - Config of the userTree quiddity
 * @memberof module:stores/userTree.UserTreeStore
 */
export const USER_TREE_CONFIG = defaultScenic.initQuiddities
  .find(q => q.nickname === USER_TREE_NICKNAME)

/**
 * @constant {string} FILE_PATH_REGEX - Matches file paths and get the filename and the extension
 * @memberof module:models/common.ConfigEnum
 */
export const FILE_PATH_REGEX = /\/(\w+\.\w+)$/

/**
 * Enum for all configuration file names
 * @readonly
 * @memberof module:models/common
 * @enum {string}
 */
const ConfigEnum = Object.freeze({
  SCENIC: 'scenic.json',
  BUNDLES: 'bundles.json',
  MENUS: 'menus.json',
  CONTACTS: 'contacts.json'
})

/**
 * Get a config name from its paths
 * @param {string} path - Path of the configuration file
 * @returns {string} The configuration name
 */
function fromPath (path) {
  const [, fileName] = path.match(FILE_PATH_REGEX)

  return [...Object.values(ConfigEnum)]
    .find(c => c === fileName)
}

export default ConfigEnum
export { fromPath }

import { observable, action, computed, reaction, makeObservable } from 'mobx'

import Capabilities from '@models/shmdata/Capabilities'
import Shmdata from '@models/shmdata/Shmdata'
import ShmdataRoleEnum from '@models/shmdata/ShmdataRoleEnum'
import MediaTypeEnum from '@models/shmdata/MediaTypeEnum'
import QuiddityTagEnum from '@models/quiddity/QuiddityTagEnum'

import QuiddityStore from '@stores/quiddity/QuiddityStore'
import Store from '@stores/Store'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the ShmdataStore
 * @memberof module:stores/shmdata.ShmdataStore
 */
export const LOG = logger.child({ store: 'ShmdataStore' })

/**
 * @constant {string} TREE_PATH_REGEX - Matches all tree paths that represents a shmdata update
 * @memberof module:stores/shmdata.ShmdataStore
 */
export const TREE_PATH_REGEX = /^\.shmdata\.(writer|reader)\.([\w/-]+)(\.stat)?/

/**
 * @constant {RegExp} SHMPATH_ID_REGEX - Extract the shmdata id and the shmdata's parent directory from a shmdata path
 * @memberof module:stores/shmdata.ShmdataStore
 */
export const SHMPATH_ID_REGEX = /^(.*)\/(.*)/

/**
 * @constant {RegExp} SHMDATA_PATH_REGEX - Matches shmdata paths
 * @memberof module:stores/shmdata.ShmdataStore
 */
export const SHMDATA_PATH_REGEX = /^\[?([\w/-]+)\]?$/

/**
 * @classdesc Stores and manages all shmdatas
 * @extends module:stores/common.Store
 * @memberof module:stores/shmdata
 */
class ShmdataStore extends Store {
  /** @property {Map<string, module:models/shmdata.Shmdata>} shmdatas - All shmdata models hashed by shmdata path */
  shmdatas = new Map()

  /** @property {Map<string, Set<string>>} followerPaths - All follower shmdata paths hashed by quiddity ID */
  followerPaths = new Map()

  /** @property {Map<string, Set<string>>} writerPaths - All writer shmdata paths hashed by quiddity ID */
  writerPaths = new Map()

  /** @property {string[]} allWriterPaths - All writer shmdata paths */
  get allWriterPaths () {
    const allPaths = []

    if (this.writerPaths.size > 0) {
      for (const writerPaths of this.writerPaths.values()) {
        allPaths.push(...Array.from(writerPaths))
      }
    }

    return allPaths
  }

  /** @property {string[]} allReaderPaths - All follower shmdata paths */
  get allFollowerPaths () {
    const allPaths = []

    if (this.followerPaths.size > 0) {
      for (const paths of this.followerPaths.values()) {
        allPaths.push(...Array.from(paths))
      }
    }

    return allPaths
  }

  /** @property {string[]} - All shmdata paths */
  get allShmdataPaths () {
    return [...this.allWriterPaths, ...this.allFollowerPaths]
  }

  /** @property {Map<string, Set<module:models/shmdata.Shmdata>>} writingShmdatas - All writer shmdata models hashed by quiddity ID */
  get writingShmdatas () {
    const { shmdatas, writerPaths } = this
    const writingShmdatas = new Map()

    for (const [quiddityId, paths] of writerPaths) {
      const writers = Array.from(paths)
        .filter(path => shmdatas.has(path))
        .map(path => shmdatas.get(path))
        .sort((a, b) => this.sortShmdatas(a, b))

      if (writers.length > 0) {
        writingShmdatas.set(quiddityId, new Set(writers))
      }
    }

    return writingShmdatas
  }

  /** @property {Map<string, string>} writingQuiddities - All writing quiddity IDs hashed by shmdata paths */
  get writingQuiddities () {
    const writingQuiddities = new Map()

    try {
      for (const [quiddityId, writerPaths] of this.writerPaths) {
        for (const path of writerPaths) {
          if (this.shmdatas.has(path)) {
            if (writingQuiddities.has(path)) {
              throw new Error('A shmdata is written by more than one quiddity')
            } else {
              writingQuiddities.set(path, quiddityId)
            }
          }
        }
      }
    } catch (error) {
      LOG.error({
        msg: 'Error while trying to get the writing quiddities',
        error: error.message
      })
    }

    return writingQuiddities
  }

  /** @property {Map<string, Set<module:models/shmdata.Shmdata>>} followingShmdatas - All follower shmdata models hashed by quiddity ID */
  get followingShmdatas () {
    const { shmdatas, followerPaths } = this
    const followingShmdatas = new Map()

    for (const [quiddityId, paths] of followerPaths) {
      const readers = Array.from(paths)
        .filter(path => shmdatas.has(path))
        .map(path => shmdatas.get(path))
        .sort((a, b) => this.sortShmdatas(a, b))

      if (readers.length > 0) {
        followingShmdatas.set(quiddityId, new Set(readers))
      }
    }

    return followingShmdatas
  }

  /** @property {Map<string, Set<string>>} followingQuiddities - All following quiddity IDs hashed by shmdata paths */
  get followingQuiddities () {
    const followingQuiddities = new Map()

    for (const [quiddityId, paths] of this.followerPaths) {
      for (const path of paths) {
        if (this.shmdatas.has(path)) {
          if (followingQuiddities.has(path)) {
            followingQuiddities.get(path).add(quiddityId)
          } else {
            followingQuiddities.set(path, new Set([quiddityId]))
          }
        }
      }
    }

    return followingQuiddities
  }

  /** @property {Map<string, Set<string>>} shmdataSuffixes - All shmdata paths hashed by shmdata suffix */
  get shmdataSuffixes () {
    const suffixes = new Map()

    for (const [path, shmdata] of this.shmdatas) {
      if (suffixes.has(shmdata.suffix)) {
        suffixes.get(shmdata.suffix).add(path)
      } else {
        suffixes.set(shmdata.suffix, new Set([path]))
      }
    }

    return suffixes
  }

  /**
   * Instantiates a new ShmdataStore
   * @param {module:stores/common.SocketStore} socketStore - Socket manager
   * @param {module:stores/quiddity.QuiddityStore} quiddityStore - Quiddity manager
   * @constructor
   */
  constructor (socketStore, quiddityStore) {
    super(socketStore)

    makeObservable(this, {
      shmdatas: observable,
      followerPaths: observable,
      writerPaths: observable,
      allWriterPaths: computed,
      allFollowerPaths: computed,
      allShmdataPaths: computed,
      writingShmdatas: computed,
      writingQuiddities: computed,
      followingShmdatas: computed,
      followingQuiddities: computed,
      shmdataSuffixes: computed,
      addShmdata: action,
      removeShmdata: action,
      addFollowingShmdata: action,
      removeFollowingShmdata: action,
      addWritingShmdata: action,
      removeWritingShmdata: action,
      clear: action
    })

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new TypeError('ShmdataStore requires a QuiddityStore')
    }

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )

    reaction(
      () => this.quiddityStore.destinationIds,
      (ids) => ids.forEach(id => this.updateFollowingShmdatas(id))
    )
  }

  /**
   * Sorts a map of shmdata according to each shmdata's media type
   * @param {Map<string, module:models/shmdata.Shmdata[]>} map - The shmdata map to sort
   * @returns {Map<string, module:models/shmdata.Shmdata[]>} A sorted shmdata map
   */
  sortShmdataMap (map) {
    return new Map(
      [...map.entries()].sort(
        ([, a], [, b]) => this.sortShmdatas(a, b)
      )
    )
  }

  /**
   * Compares shmdata suffixes in order to display them in a coherent order
   * @see [Sort function prototype]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort}
   * @param {module:models/shmdata.Shmdata} shmdataA - Shmdata A to compare
   * @param {module:models/shmdata.Shmdata} shmdataB - Shmdata B to compare
   * @returns {number} Comparison between the order of the two shmdata
   */
  sortShmdatas (shmdataA, shmdataB) {
    switch (shmdataA.mediaType) {
      case MediaTypeEnum.VIDEO_RAW:
        return shmdataB.mediaType === MediaTypeEnum.VIDEO_H264 ? -1 : 0

      case MediaTypeEnum.VIDEO_H264:
        return shmdataB.suffix === MediaTypeEnum.VIDEO_RAW ? 1 : 0

      default: return 0
    }
  }

  /**
   * Gets a writing or following shmdata Set from a quiddity
   * @param {module:models/quiddity.QuiddityTagEnum} quiddityTag - Tag of the quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @returns {Set<module:models/shmdata.Shmdata>} The Set of shmdatas
   */
  getShmdataSet (quiddityTag, quiddityId) {
    const { SOURCE, DESTINATION } = QuiddityTagEnum
    const { writingShmdatas, followingShmdatas } = this
    let shmdatas = new Set()

    if (quiddityTag === SOURCE && writingShmdatas.has(quiddityId)) {
      shmdatas = writingShmdatas.get(quiddityId)
    } else if (quiddityTag === DESTINATION && followingShmdatas.has(quiddityId)) {
      shmdatas = followingShmdatas.get(quiddityId)
    }

    return shmdatas
  }

  /**
   * Fetches the `shmdata.${shmdataRole}` branch of the given quiddity tree
   * @param {string} quiddityId - ID of the quiddity
   * @param {module:models/shmdata.ShmdataRoleEnum} role - Role of the shmdatas to fetch
   * @returns {Object} The JSON from the `shmdata.${shmdataRole}` branch
   * @async
   */
  async fetchShmdatas (quiddityId, role) {
    const { infoTreeAPI } = this.socketStore.APIs
    let json = []

    try {
      if (this.quiddityStore.quiddities.has(quiddityId)) {
        json = await infoTreeAPI.get(quiddityId, `.shmdata.${role}`)
      } else {
        LOG.warn({
          msg: 'Tried to fetch shmdatas of an invalid quiddity',
          reason: 'The quiddity was removed during the asynchronous call',
          quiddity: quiddityId
        })
      }

      if (typeof json === 'object' && json.length > 0) {
        json = Object.keys(json)
          .filter(path => this.isShmdataPath(path))
          .map(path => path.match(SHMDATA_PATH_REGEX)[1])
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to fetch all shmdata followers',
        quiddity: quiddityId,
        error: error.message
      })
    }

    return json
  }

  /**
   * Fetches a shmdata from its path
   * @async
   * @param {string} quiddityId - ID of the quiddity that writes or reads the shmdata
   * @param {string} role - Role of the shmdata
   * @param {string} path - Path of the shmdata
   * @returns {Object} The JSON representation of a shmdata
   */
  async fetchShmdata (quiddityId, role, path) {
    const { infoTreeAPI } = this.socketStore.APIs
    let json = null

    try {
      json = await infoTreeAPI.get(quiddityId, `.shmdata.${role}.${path}`)
    } catch {
      LOG.error({
        msg: 'Failed to fetch a shmdata',
        quiddity: quiddityId,
        shmdata: path,
        role: role
      })
    }

    return json
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()

    if (socket && this.socketStore.hasActiveAPIs) {
      const { infoTreeAPI, sessionAPI } = this.socketStore.APIs

      infoTreeAPI.onGrafted(
        (quidId, path, value) => this.handleGraftedShmdata(quidId, path, value),
        () => true,
        path => Array.isArray(path.match(TREE_PATH_REGEX))
      )

      infoTreeAPI.onPruned(
        (quidId, path) => this.handlePrunedShmdata(quidId, path),
        () => true,
        path => Array.isArray(path.match(TREE_PATH_REGEX))
      )

      sessionAPI.onSessionReset(() => this.clear())
    }
  }

  /**
   * Handles a `graftedTree` Switcher signal for a shmdata
   * @param {string} quiddityId - ID of the updated quiddity
   * @param {string} treePath - Path of the updated quiddity tree
   * @param {Object} json - Shmdata model as a plain JSON
   * @return {boolean} Flags a handled shmdata update
   */
  async handleGraftedShmdata (quiddityId, treePath, json) {
    const [, role, shmdataPath, isStat] = treePath.match(TREE_PATH_REGEX)

    if (!this.hasShmdataPath(quiddityId, shmdataPath)) {
      const shmdata = await this.makeShmdataModel(quiddityId, role, shmdataPath, isStat ? null : json)

      if (shmdata && !this.shmdatas.has(shmdataPath)) {
        this.addShmdata(shmdata)
      }
    }

    if (this.hasShmdataPath(shmdataPath)) {
      if (role === ShmdataRoleEnum.READER) {
        this.addFollowingShmdata(quiddityId, shmdataPath)
      } else if (role === ShmdataRoleEnum.WRITER) {
        this.addWritingShmdata(quiddityId, shmdataPath)
      }
      // If we already have the shmdata but the caps were possibly modified,
      // look if the caps really changed and update them
      if (json.caps !== undefined) {
        const shmdata = this.shmdatas.get(shmdataPath)
        // we use the fromString and then toString because the toString method of a capabilities
        // object doesn't return exactly the same string as the string it was built with ...
        const newCapabilities = Capabilities.fromString(json.caps)
        if (newCapabilities.toString() !== shmdata.capabilities.toCaps()) {
          shmdata.capabilities = newCapabilities
        }
      }
    }

    return this.shmdatas.has(shmdataPath)
  }

  /**
   * Handles a `prunedTree` Switcher signal for a shmdata
   * @param {string} quiddityId - ID of the updated quiddity
   * @param {string} treePath - Path of the updated quiddity tree
   * @return {boolean} Flags a handled shmdata update
   */
  handlePrunedShmdata (quiddityId, treePath) {
    const [, role, shmdataPath] = treePath.match(TREE_PATH_REGEX)

    if (this.hasShmdataPath(shmdataPath)) {
      if (role === ShmdataRoleEnum.READER) {
        this.removeFollowingShmdata(quiddityId, shmdataPath)
      } else if (role === ShmdataRoleEnum.WRITER) {
        this.removeWritingShmdata(quiddityId, shmdataPath)
      }
    }

    if (!this.allShmdataPaths.includes(shmdataPath)) {
      this.removeShmdata(quiddityId, shmdataPath)
    }

    return !this.shmdatas.has(shmdataPath)
  }

  /**
   * Crafts a shmdata model
   * @param {string} quiddityId - ID of the shmdata's quiddity
   * @param {string} role - Role of the updated shmdata
   * @param {string} shmdataPath - Path of the updated shmdata
   * @param {?Object} [json=null] - Shmdata model as a plain JSON (fetches the quiddity tree if null)
   * @returns {?module:models/shmdata.Shmdata} The new shmdata model (null if it failed)
   */
  async makeShmdataModel (quiddityId, role, shmdataPath, json = null) {
    const { infoTreeAPI } = this.socketStore.APIs
    let shmdata = null

    try {
      if (this.shmdatas.has(shmdataPath)) {
        shmdata = this.shmdatas.get(shmdataPath)
      } else if (!json) {
        json = await infoTreeAPI.get(quiddityId, `.shmdata.${role}.${shmdataPath}`)
        shmdata = Shmdata.fromJSON(shmdataPath, json)
      } else {
        shmdata = Shmdata.fromJSON(shmdataPath, json)
      }
    } catch (error) {
      LOG.error({
        msg: 'Received an invalid Shmdata',
        error: error,
        json: json,
        shmdata: shmdataPath
      })
    }

    return shmdata
  }

  /**
   * Connects a shmdata with a destination quiddity
   * @deprecated
   * @see https://jira.sat.qc.ca/browse/SCENIC-1465
   * @param {string} destinationId - The destination quiddity to connect
   * @param {module:models/shmdata.Shmdata} shmdata - The shmdata to connect
   * @async
   * @returns {boolean} Flags true when the shmdata is disconnected
   */
  async applyShmdataConnection (destinationId, shmdata) {
    let isConnected = this.isFollowingShmdata(destinationId, shmdata.path)
    const { methodAPI } = this.socketStore.APIs

    try {
      isConnected = await methodAPI.connect(destinationId, shmdata.path)

      LOG.info({
        msg: 'Successfully connected shmdata',
        destination: destinationId,
        shmdata: shmdata.path
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to connect shmdata',
        destination: destinationId,
        error: error.message
      })
    }

    return isConnected
  }

  /**
   * Disconnects all shmdatas from a destination quiddity
   * @param {string} destinationId - The destination quiddity to disconnect shmdatas from
   * @async
   * @returns {boolean} Flags true when the shmdatas are disconnected
   */
  async applyDisconnectAll (destinationId) {
    const { methodAPI } = this.socketStore.APIs
    let isDisconnected = false

    try {
      isDisconnected = await methodAPI.disconnectAll(destinationId)

      LOG.info({
        msg: 'Successfully disconnected from all shmdatas',
        destination: destinationId
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to disconnect all shmdatas',
        destination: destinationId,
        error: error.message
      })
    }

    return isDisconnected
  }

  /**
   * Checks if a path is a valid shmdata path
   * @param {string} path - The absolute path of a shmdata
   * @returns {boolean} Flags a valid shmdata
   */
  isShmdataPath (path) {
    const match = path.match(SHMDATA_PATH_REGEX)
    return Array.isArray(match) && match[1] && match[1] !== 'null'
  }

  /**
   * Updates following shmdata map for a given quiddity
   * @param {string} quidId - ID of the quiddity
   * @async
   */
  async updateFollowingShmdatas (quidId) {
    try {
      const storedPaths = this.followerPaths.get(quidId) || new Set()
      const realShmdatas = await this.fetchShmdatas(quidId, ShmdataRoleEnum.READER)

      if (realShmdatas.length !== storedPaths.size) {
        this.populateFollowingShmdatas(quidId, realShmdatas)

        LOG.info({
          msg: 'Successfully populated follower shmdatas',
          quiddity: quidId
        })
      }

      if (storedPaths.size > realShmdatas.length) {
        this.cleanFollowingShmdatas(quidId, realShmdatas)

        LOG.info({
          msg: 'Successfully cleaned follower shmdatas',
          quiddity: quidId
        })
      }
    } catch (error) {
      LOG.warn({
        msg: 'Failed to update follower shmdatas',
        error: error.message,
        quiddity: quidId
      })
    }
  }

  /**
   * Updates the following shmdatas of the destination quiddity
   * @param {module:models/matrix.MatrixEntry} entry - The matrix entry to fallback
   * @async
   */
  async fallbackFollowingShmdatas (entry) {
    try {
      await this.updateFollowingShmdatas(entry.quiddityId)
    } catch (error) {
      LOG.error({
        msg: 'Failed to update the following shmdata',
        destination: entry.quiddityId,
        error: error.message
      })
    }
  }

  /**
   * Populates the followerPaths map with a quiddity's shmdata follower' paths
   * @param {string} quiddityId - ID of the quiddity
   * @param {string[]} currentFollowers - Array of follower shmdata paths
   */
  populateFollowingShmdatas (quiddityId, currentFollowers) {
    if (typeof currentFollowers === 'object' && !Array.isArray(currentFollowers)) {
      for (const key in currentFollowers) {
        if (Object.hasOwn(key)) {
          this.addFollowingShmdata(quiddityId, key)
        }
      }
    } else {
      for (const path of currentFollowers) {
        this.addFollowingShmdata(quiddityId, path)
      }
    }
  }

  /**
   * Cleans the followerPaths map using the quiddity's shmdata follower' paths
   * @param {string} quiddityId - ID of the quiddity
   * @param {string[]} currentFollowers - Array of following shmdata paths
   */
  cleanFollowingShmdatas (quiddityId, currentFollowers) {
    if (this.followerPaths.has(quiddityId)) {
      for (const oldFollower of this.followerPaths.get(quiddityId)) {
        if (!currentFollowers.includes(oldFollower)) {
          this.removeFollowerShmdata(quiddityId, oldFollower)
        }
      }
    }
  }

  /**
   * Checks if a quiddity is following a shmdata path
   * @param {string} quiddityId - ID of the destination quiddity
   * @param {string} shmdataPath - Shmdata path
   */
  isFollowingShmdata (quiddityId, shmdataPath) {
    return this.followerPaths.has(quiddityId) &&
      this.followerPaths.get(quiddityId).has(shmdataPath)
  }

  /**
   * Checks if a shmdata path is currently being written
   * @param {string} shmdataPath - Shmdata path
   * @returns Flags true if shmdata is currently being written
   */
  isShmdataWriting (shmdataPath) {
    let result = false
    for (const [, shmdatas] of this.writingShmdatas) {
      for (const shmdata of shmdatas) {
        if (shmdata.path === shmdataPath) {
          result = true
          break
        }
      }
    }

    return result
  }

  /**
   * Checks if a specific quiddity is writing a specific shmdata path
   * @param {string} quiddityId - ID of the writer quiddity
   * @param {string} shmdataPath - Shmdata path
   */
  isQuiddityWritingShmdata (quiddityId, shmdataPath) {
    return this.writerPaths.has(quiddityId) &&
      this.writerPaths.get(quiddityId).has(shmdataPath)
  }

  /**
   * Checks if a shmdata path exists in the store
   * @param {string} shmdataPath - Shmdata Path
   * @returns {boolean} True if the shmdata path exists in the store
   */
  hasShmdataPath (shmdataPath) {
    let isPresent = false
    if (shmdataPath) {
      isPresent = this.shmdatas.has(shmdataPath)
    }
    return isPresent
  }

  /**
   * Checks if a source and a destination are sharing a shmdata
   * @param {string} sourceId - ID of a source quiddity
   * @param {string} destinationId - ID of a destination quiddity
   * @returns {boolean} Returns true if a shmdata is shared
   */
  hasConnectedShmdata (sourceId, destinationId) {
    const writing = this.writerPaths.get(sourceId) || new Set()
    const following = this.followingPath.get(destinationId) || new Set()

    const connections = new Set()

    for (const path of writing) {
      if (following.has(path)) {
        connections.add(path)
      }
    }

    return connections.size > 0
  }

  /**
   * Adds a shmdata path in the shmdatas Map
   * @param {module:models/shmdata.Shmdata} shmdata - Shmdata to add
   * @returns {boolean} Flags true if the shmdata is added
   */
  addShmdata (shmdata) {
    this.shmdatas.set(shmdata.path, shmdata)

    LOG.info({
      msg: 'Added shmdata',
      shmdata: shmdata.path
    })

    return this.shmdatas.has(shmdata.path)
  }

  /**
   * Deletes a shmdata from the shmdatas Map
   * @param {module:models/shmdata.Shmdata} shmdata - Shmdata model to remove
   * @returns {boolean} Flags true if the shmdata is removed
   */
  removeShmdata (shmdata) {
    this.shmdatas.delete(shmdata.path)

    LOG.debug({
      msg: 'Removed shmdata',
      shmdata: shmdata.path
    })

    return !this.shmdatas.has(shmdata.path)
  }

  /**
   * Adds a follower shmdata in the followerPaths Map
   * @param {string} quiddityId - ID of the following quiddity
   * @param {string} shmdataPath - Path of the follower shmdata
   * @returns {boolean} Flags true if the shmdata path has been added
   */
  addFollowingShmdata (quiddityId, shmdataPath) {
    if (this.isFollowingShmdata(quiddityId, shmdataPath)) return false

    if (this.followerPaths.has(quiddityId)) {
      this.followerPaths.get(quiddityId).add(shmdataPath)
    } else {
      this.followerPaths.set(quiddityId, new Set([shmdataPath]))
    }

    LOG.info({
      msg: `Shmdata ${shmdataPath} starts following for ${quiddityId}`,
      quiddity: quiddityId,
      shmdata: shmdataPath
    })

    return this.isFollowingShmdata(quiddityId, shmdataPath)
  }

  /**
   * Deletes a following shmdata from the readerPaths Map
   * @param {string} quiddityId - ID of the following quiddity
   * @param {string} shmdataPath - Path of the follower shmdata
   * @returns {boolean} Flags true if the shmdata path has been deleted
   */
  removeFollowingShmdata (quiddityId, shmdataPath) {
    if (!this.isFollowingShmdata(quiddityId, shmdataPath)) return false

    this.followerPaths.get(quiddityId).delete(shmdataPath)

    LOG.info({
      msg: `Shmdata ${shmdataPath} stops following for ${quiddityId}`,
      quiddity: quiddityId,
      shmdata: shmdataPath
    })

    if (this.followerPaths.get(quiddityId).size === 0) {
      this.followerPaths.delete(quiddityId)
    }

    return !this.isFollowingShmdata(quiddityId, shmdataPath)
  }

  /**
   * Adds a writing shmdata in the writerPaths Map
   * @param {string} quiddityId - ID of the writing quiddity
   * @param {string} shmdataPath - Path of the writer shmdata
   * @returns {boolean} Flags true if the shmdata path has been added
   */
  addWritingShmdata (quiddityId, shmdataPath) {
    if (this.isQuiddityWritingShmdata(quiddityId, shmdataPath)) return false

    if (this.writerPaths.has(quiddityId)) {
      this.writerPaths.get(quiddityId).add(shmdataPath)
    } else {
      this.writerPaths.set(quiddityId, new Set([shmdataPath]))
    }

    LOG.info({
      msg: `Shmdata ${shmdataPath} starts writing for ${quiddityId}`,
      quiddity: quiddityId,
      shmdata: shmdataPath
    })

    return this.isQuiddityWritingShmdata(quiddityId, shmdataPath)
  }

  /**
   * Deletes a writing shmdata from the writerPaths Map
   * @param {string} quiddityId - ID of the writing
   * @param {string} shmdataPath - Path of the writer shmdata
   * @returns {boolean} Flags true if the shmdata path has been deleted
   */
  removeWritingShmdata (quiddityId, shmdataPath) {
    if (!this.isQuiddityWritingShmdata(quiddityId, shmdataPath)) return false

    this.writerPaths.get(quiddityId).delete(shmdataPath)

    LOG.info({
      msg: `Shmdata ${shmdataPath} stops writing for ${quiddityId}`,
      quiddity: quiddityId,
      shmdata: shmdataPath
    })

    if (this.writerPaths.get(quiddityId).size === 0) {
      this.writerPaths.delete(quiddityId)
    }

    return !this.isQuiddityWritingShmdata(quiddityId, shmdataPath)
  }

  /** Cleans up shmdatas */
  clear () {
    this.shmdatas.clear()
    this.followerPaths.clear()
    this.writerPaths.clear()

    LOG.info({
      msg: 'Successfully cleared all shmdatas'
    })
  }
}

export default ShmdataStore

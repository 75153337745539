import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { DRAWER_WIDTH } from '@stores/common/DrawerStore'
import { AppStoresContext } from '@components/App'

import { SipServerField, SipPortField, SipUserField, SipPasswordField } from '@components/fields/SipFields'
import { SIP_CONTACT_DRAWER_ID } from '@components/drawers/SipContactDrawer'

import PageEnum from '@models/common/PageEnum'
import SettingEnum from '@models/common/SettingEnum'

import { Common, Feedback, Layout } from '@sat-mtl/ui-components'

import '@styles/drawers/SipLoginDrawer.scss'

const { Drawer } = Feedback
const { FlexRow } = Layout
const { Button } = Common

/**
 * @constant {string} SIP_LOGIN_DRAWER_ID - ID of the sip login drawer
 * @memberof module:components/drawers.SipLoginDrawer
 */
const SIP_LOGIN_DRAWER_ID = 'SipLoginDrawer'

/**
 * Renders the Button that logs the user to the SIP service
 * @memberof module:components/drawers.SipLoginDrawer
 * @param {boolean} disabled - Disable the login button
 * @param {Function} onLogin - Function triggered when the user clicks on the button
 * @returns {external:react/Component} A button
 */
function LoginButton ({ disabled, onLogin }) {
  const { t } = useTranslation()

  return (
    <Button className='LoginButton' disabled={disabled} onClick={onLogin} shape='rectangle'>
      {t('Log in')}
    </Button>
  )
}

/**
 * Renders the Button that resets the credential fields
 * @memberof module:components/drawers.SipLoginDrawer
 * @param {Function} onReset - Function triggered when the user clicks on the button
 * @returns {external:react/Component} A button
 */
function ResetFieldsButton ({ onReset }) {
  const { t } = useTranslation()

  return (
    <Button className='ResetButton' type='secondary' onClick={onReset} shape='rectangle' outlined>
      {t('Reset')}
    </Button>
  )
}

/**
 * Renders a link to the SIP settings page
 * @memberof module:components/drawers.SipLoginDrawer
 * @param {Function} onClick - Function triggered when the user click on the link
 * @returns {external:react/Component} A link to the SIP settings page
 */
function AdvancedSettingsLink ({ onClick }) {
  const { t } = useTranslation()

  return (
    <a onClick={onClick}>
      {t('Advanced SIP settings...')}
    </a>
  )
}

/**
 * Drawer that displays all required fields for SIP login
 * @memberof module:components/drawers
 * @returns {external:mobx-react/ObserverComponent} All SIP fields
 */
const SipCredentialForm = observer(() => {
  const { drawerStore, sipStore, pageStore, settingStore, sipCredentialStore } = useContext(AppStoresContext)

  const onPressEnter = (e) => {
    if (sipCredentialStore.areCredentialsValid && e.key === 'Enter') {
      sipStore.applyLogin(sipCredentialStore.sipCredentials)
    }
  }

  return (
    <form className='SipCredentialForm' onKeyPress={onPressEnter}>
      <SipServerField
        value={sipCredentialStore.credentials.get('sipServer')}
        error={sipCredentialStore.errors.get('sipServer')}
        onChange={value => sipCredentialStore.setCredential('sipServer', value)}
        onCheck={() => sipCredentialStore.applyCredentialValidation('sipServer')}
      />
      <SipPortField
        value={sipCredentialStore.credentials.get('sipPort')}
        error={sipCredentialStore.errors.get('sipPort')}
        onChange={value => sipCredentialStore.setCredential('sipPort', value)}
        onCheck={() => sipCredentialStore.applyCredentialValidation('sipPort')}
      />
      <SipUserField
        value={sipCredentialStore.credentials.get('sipUser')}
        error={sipCredentialStore.errors.get('sipUser')}
        onChange={value => sipCredentialStore.setCredential('sipUser', value)}
        onCheck={() => sipCredentialStore.applyCredentialValidation('sipUser')}
      />
      <SipPasswordField
        value={sipCredentialStore.credentials.get('sipPassword')}
        error={sipCredentialStore.errors.get('sipPassword')}
        onChange={value => sipCredentialStore.setCredential('sipPassword', value)}
        onCheck={() => sipCredentialStore.applyCredentialValidation('sipPassword')}
      />
      <AdvancedSettingsLink
        onClick={() => {
          drawerStore.clearActiveDrawer()
          pageStore.setActivePage(PageEnum.SETTINGS)
          settingStore.setActiveGroup(SettingEnum.SIP)
        }}
      />
    </form>
  )
})

/**
 * Drawer that connects to SIP server
 * @memberof module:components/drawers
 * @returns {external:mobx-react/ObserverComponent} The SIP login drawer
 */
const SipLoginDrawer = observer(() => {
  const { t } = useTranslation()
  const { drawerStore, sipStore, sipCredentialStore } = useContext(AppStoresContext)

  useEffect(() => {
    drawerStore.addDrawer(SIP_LOGIN_DRAWER_ID)

    return () => {
      drawerStore.removeDrawer(SIP_LOGIN_DRAWER_ID)
    }
  })

  useEffect(() => {
    if (drawerStore.activeDrawer === SIP_LOGIN_DRAWER_ID && sipStore.isConnected) {
      drawerStore.setActiveDrawer(SIP_CONTACT_DRAWER_ID)
    }
  }, [sipStore.isConnected])

  return (
    <Drawer
      id={SIP_LOGIN_DRAWER_ID}
      width={DRAWER_WIDTH}
      visible={drawerStore.activeDrawer === SIP_LOGIN_DRAWER_ID}
      onBackdropClick={() => {
        drawerStore.clearActiveDrawer()
      }}
      header={(
        <>
          <h2>
            {t('SIP server login')}
          </h2>
          <p>
            {t('Enter your credentials to log in to your SIP server')}
          </p>
        </>
      )}
      footer={(
        <FlexRow justifyContent='space-around'>
          <ResetFieldsButton
            onReset={() => sipCredentialStore.cleanCredentials()}
          />
          <LoginButton
            disabled={!sipCredentialStore.areCredentialsValid}
            onLogin={() => sipStore.applyLogin(sipCredentialStore.sipCredentials)}
          />
        </FlexRow>
      )}
    >
      <SipCredentialForm />
    </Drawer>
  )
})

export default SipLoginDrawer

export {
  SIP_LOGIN_DRAWER_ID,
  LoginButton,
  ResetFieldsButton,
  AdvancedSettingsLink,
  SipCredentialForm
}

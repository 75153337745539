import { observable, action, reaction, computed, makeObservable } from 'mobx'

import QuiddityStore from '@stores/quiddity/QuiddityStore'
import Store from '@stores/Store'

import QuiddityTagEnum from '@models/quiddity/QuiddityTagEnum'

import { logger } from '@utils/logger'
import OrderEnum from '@models/common/OrderEnum'

import { specialMatrixCategories } from '@models/matrix/MatrixCategoryEnum'

import { EXTERNAL_SHMDATA_SOURCE_KIND_ID } from '@models/quiddity/specialQuiddities'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the OrderStore
 * @memberof stores.OrderStore
 */
export const LOG = logger.child({ store: 'OrderStore' })

/**
 * @constant {RegExp} ORDER_PROPERTY_PATHS - Matches all property paths with suffix or without suffix for common quiddities
 * @memberof module:stores/quiddity.OrderStore
 */
export const ORDER_PROPERTY_PATHS = /^order\.(source|destination):?(ndi|rtmp|sip)?$/

/**
 * @constant {number} DEFAULT_ORDER - A default order
 * @memberof stores.OrderStore
 */
export const DEFAULT_ORDER = 0

/**
 * @classdesc Stores and manages the display order of each quiddity
 * @extends stores.Store
 * @memberof stores
 */
class OrderStore extends Store {
  /** @property {module:stores/quiddity.QuiddityStore} quiddityStore - Stores and manages all quiddities */
  quiddityStore = null

  /** @property {Map<string, number>} sourceOrders - All orders hashed by source quiddity IDs */
  sourceOrders = new Map()

  /** @property {Map<string, number>} destinationOrders - All orders hashed by destination quiddity IDs */
  destinationOrders = new Map()

  /** @property {bool} isRightButtonDisplayed - Get the display status of the right order button */
  get isRightButtonDisplayed () {
    let isDisplayed = false
    const { selectedQuiddity } = this.quiddityStore
    const categorizedDestinations = this.getCategorizedOrders(selectedQuiddity?.id)

    if (selectedQuiddity && categorizedDestinations?.has(selectedQuiddity?.id)) {
      const quidOrder = categorizedDestinations.get(selectedQuiddity?.id)
      isDisplayed = quidOrder < categorizedDestinations.size - 1
    }

    return isDisplayed
  }

  /** @property {bool} isLeftButtonDisplayed - Get the display status of the left order button */
  get isLeftButtonDisplayed () {
    let isDisplayed = false
    const { selectedQuiddity } = this.quiddityStore
    const categorizedDestinations = this.getCategorizedOrders(selectedQuiddity?.id)

    if (selectedQuiddity && categorizedDestinations?.has(selectedQuiddity?.id)) {
      const quidOrder = categorizedDestinations.get(selectedQuiddity.id)
      isDisplayed = quidOrder > 0
    }

    return isDisplayed
  }

  /** @property {bool} isDownButtonDisplayed - Get the display status of the downward order button */
  get isDownButtonDisplayed () {
    let isDisplayed = false
    const { selectedQuiddity } = this.quiddityStore

    if (selectedQuiddity && selectedQuiddity !== null) {
      const quidOrder = this.sourceOrders.get(selectedQuiddity?.id)
      isDisplayed = quidOrder < this.sourceOrders.size - 1
    }

    return isDisplayed
  }

  /** @property {bool} isUpButtonDisplayed - Get the display status of the upward order button */
  get isUpButtonDisplayed () {
    let isDisplayed = false
    const { selectedQuiddity } = this.quiddityStore

    if (selectedQuiddity && selectedQuiddity !== null) {
      const quidOrder = this.sourceOrders.get(selectedQuiddity?.id)
      isDisplayed = quidOrder > 0
    }

    return isDisplayed
  }

  /** @property {Object} categorizedDestinationOrders - A map of all orders hashed by matrix categories for destination quiddities */
  get categorizedDestinationOrders () {
    const categorizedOrders = new Map()

    for (const [quidId, order] of this.destinationOrders.entries()) {
      const category = this.quiddityStore.getQuiddityCategory(quidId)

      if (categorizedOrders.has(category)) {
        categorizedOrders.get(category).set(quidId, order)
      } else {
        categorizedOrders.set(category, new Map([[quidId, order]]))
      }
    }

    return categorizedOrders
  }

  /**
   * Instantiates a new OrderStore
   * @param {stores.SocketStore} socketStore - Socket manager
   * @param {stores.QuiddityStore} quiddityStore - Quiddity manager
   * @constructor
   */
  constructor (socketStore, quiddityStore) {
    super(socketStore)

    makeObservable(this, {
      sourceOrders: observable,
      destinationOrders: observable,
      localSources: computed,
      localSourcesIds: computed,
      isRightButtonDisplayed: computed,
      isLeftButtonDisplayed: computed,
      isDownButtonDisplayed: computed,
      isUpButtonDisplayed: computed,
      categorizedDestinationOrders: computed,
      sortedDestinations: computed,
      sortedSources: computed,
      setOrder: action,
      removeOrder: action,
      clear: action
    })

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new TypeError('OrderStore requires a QuiddityStore')
    }

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )
  }

  /**
   * Sorts the quiddity destinations according to their user-defined orders
   * @returns {models.Quiddity[]} The sorted list of destinations
   */
  get sortedDestinations () {
    const orders = this.destinationOrders
    return this.quiddityStore.destinations.sort(
      (a, b) => this.getQuiddityRank(orders, a, b)
    )
  }

  /**
   * Sorts the quiddity sources according to their user-defined orders
   * @returns {models.Quiddity[]} The sorted list of sources
   */
  get sortedSources () {
    return this.quiddityStore.sources.sort(
      (a, b) => this.getQuiddityRank(this.sourceOrders, a, b)
    )
  }

  /**
   * Gets quiddities' orders according to their category
   * @param {string} quidId - The id of the quiddity
   * @returns {Map<string, number>} The destination orders map according to the selected quiddity id
   */
  getCategorizedOrders (quidId) {
    return this.categorizedDestinationOrders.get(
      this.quiddityStore.quiddities.get(quidId)?.matrixCategory
    )
  }

  /**
   * Compares quiddities according to their orders
   * @param {Map<string, number>} orderMap - Orders mapped by quiddity IDs
   * @param {models.Quiddity} quiddityA - First quiddity to sort
   * @param {models.Quiddity} quiddityB - Second quiddity to sort
   * @returns {Number} Returns a rank between quiddities A and B
   */
  getQuiddityRank (orderMap, quiddityA, quiddityB) {
    if (orderMap.has(quiddityA.id) && orderMap.has(quiddityB.id)) {
      return orderMap.get(quiddityA.id) - orderMap.get(quiddityB.id)
    } else {
      return 0
    }
  }

  /**
   * Fetches the order of a given quiddity
   * @param {models.QuiddityTagEnum} quidTag - Type of the quiddity
   * @param {string} quidId - ID of the quiddity
   * @returns {?number} Order value of the quiddity, returns null if it fails
   * @async
   */
  async fetchOrder (quidTag, quidId) {
    const { userTreeAPI } = this.socketStore.APIs
    let order = null

    try {
      const data = await userTreeAPI.get(quidId, `order.${quidTag}`)

      if (this.isValidOrder(data)) {
        order = Number.parseInt(data)
        LOG.debug({
          msg: 'Successfully fetched quiddity\'s order',
          quiddity: quidId,
          order: order
        })
      } else {
        LOG.warn({
          msg: 'Failed to fetch a valid order: it is not an integer',
          quiddity: quidId
        })
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to fetch quiddity\'s order',
        quiddity: quidId,
        error: error.message
      })
    }

    return order
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()

    if (this.disposeQuiddityReaction) {
      this.disposeQuiddityReaction()
    }

    if (socket && this.socketStore.hasActiveAPIs) {
      const { userTreeAPI } = this.socketStore.APIs

      userTreeAPI.onGrafted(
        (quidId, path, data) => this.handleGraftedOrder(quidId, path, data),
        quidId => this.quiddityStore.userQuiddityIds.includes(quidId),
        path => ORDER_PROPERTY_PATHS.test(path)
      )

      this.disposeQuiddityReaction = reaction(
        () => this.quiddityStore.userQuiddityIds,
        () => this.handleQuiddityCreation()
      )
    }
  }

  /**
   * Handles the update of a quiddity's order
   * @param {string} quiddityId - ID of the updated quiddity
   * @param {string} treePath - Path of the updated order
   * @param {number} treeData - Updated order value
   */
  handleGraftedOrder (quiddityId, treePath, treeData) {
    const order = Number.parseInt(treeData)
    let quiddityTag

    if (treePath.includes('order.source')) {
      quiddityTag = QuiddityTagEnum.SOURCE
    } else if (treePath.includes('order.destination')) {
      quiddityTag = QuiddityTagEnum.DESTINATION
    }

    const hasValidTag = !!quiddityTag
    const isValidOrder = this.isValidOrder(order)
    const isNewOrder = this.isUpdatedOrder(quiddityTag, quiddityId, order)

    if (hasValidTag && isValidOrder && isNewOrder) {
      this.setOrder(quiddityTag, quiddityId, order)

      LOG.debug({
        msg: 'Successfully updated user data order',
        quiddity: quiddityId,
        treePath: treePath,
        order: treeData
      })
    }
  }

  /** @property {module:models/quiddity.Quiddity[]} All local source quiddities */
  get localSources () {
    return this.quiddityStore.sources.filter(source => source.kindId !== EXTERNAL_SHMDATA_SOURCE_KIND_ID)
  }

  /** @property {module:models/quiddity.Quiddity[]} All local source quiddity ids */
  get localSourcesIds () {
    return this.localSources.map(source => source.id)
  }

  /** Handles the creation of a quiddity */
  handleQuiddityCreation () {
    const { quiddityStore: { sourceIds, destinationIds } } = this

    if (sourceIds.length > 0) {
      this.populateOrders(QuiddityTagEnum.SOURCE, this.localSourcesIds)
    }

    if (destinationIds.length > 0) {
      this.populateOrders(QuiddityTagEnum.DESTINATION, destinationIds)
    }

    const quidIds = [
      ...this.sourceOrders.keys(),
      ...this.destinationOrders.keys()
    ]

    for (const quidId of quidIds) {
      this.cleanOrders(quidId)
    }
  }

  /**
   * Populates order for a set of quiddities
   * @param {models.QuiddityTagEnum} quiddityTag - Tag of the quiddities
   * @param {string[]} [quiddityIds=[]] - All quiddities to process
   * @async
   */
  async populateOrders (quiddityTag, quiddityIds = []) {
    if (quiddityTag === QuiddityTagEnum.DESTINATION) {
      const allCategorizedDestinations = this.quiddityStore.categorizedQuiddityIds

      for (const categorizedDestination of allCategorizedDestinations.values()) {
        for (let index = 0; index < categorizedDestination.length; index++) {
          await this.updateOrder(quiddityTag, categorizedDestination[index], index)
        }
      }
    } else {
      for (let index = 0; index < quiddityIds.length; index++) {
        await this.updateOrder(quiddityTag, quiddityIds[index], index)
      }
    }
  }

  /**
   * Checks if a quiddity has a stored order
   * @param {string} quiddityId - ID of the quiddity
   * @param {models.QuiddityTagEnum} [quiddityTag] - Tag of the quiddity
   * @returns {boolean} Returns true if the quiddity has a stored order
   */
  hasOrder (quiddityId, quiddityTag) {
    const inSource = this.sourceOrders.has(quiddityId)
    const inDestination = this.destinationOrders.has(quiddityId)

    if (!quiddityTag) {
      return inSource || inDestination
    } else if (quiddityTag === QuiddityTagEnum.SOURCE) {
      return inSource
    } else if (quiddityTag === QuiddityTagEnum.DESTINATION) {
      return inDestination
    } else {
      return false
    }
  }

  /**
   * Updates the order for a quiddity
   * @param {models.QuiddityTagEnum} quiddityTag - Tag of the quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @param {number} [userOrder=DEFAULT_ORDER] - Quiddity's order
   * @async
   */
  async updateOrder (quidTag, quidId, userOrder = DEFAULT_ORDER) {
    const order = await this.fetchOrder(quidTag, quidId)

    if (!this.isValidOrder(order)) {
      this.applyOrderChange(quidTag, quidId, userOrder)
    } else if (this.isUpdatedOrder(quidTag, quidId, order)) {
      this.setOrder(quidTag, quidId, order)

      LOG.debug({
        msg: 'Successfully populate user data order',
        quiddityTag: quidTag,
        quiddity: quidId,
        order: order
      })
    }
  }

  /**
   * Updates the order of a destination quiddity
   * @param {string} quidId - ID of the quiddity
   * @param {number} userOrder - Quiddity's order
   * @async
   */
  async updateDestinationOrder (quidId, userOrder, buttonType) {
    const quidCategory = this.quiddityStore.getQuiddityCategory(quidId)

    for (const [destinationId, order] of this.destinationOrders.entries()) {
      const neighborCategory = this.quiddityStore.getQuiddityCategory(destinationId)

      if (destinationId !== quidId &&
          order === userOrder &&
          quidCategory === neighborCategory) {
        // updates the order of the destination that precedes/follows the selected destination that the user is reordering
        const neighborOrder = +`${buttonType === OrderEnum.RIGHT ? userOrder - 1 : userOrder + 1}`
        await this.applyOrderChange(QuiddityTagEnum.DESTINATION, destinationId, neighborOrder)
      }
      await this.applyOrderChange(QuiddityTagEnum.DESTINATION, quidId, userOrder)
    }
  }

  /**
   * Updates the order for a source quiddity
   * @param {string} quidId - ID of the quiddity
   * @param {number} userOrder - Quiddity's order
   * @async
   */
  async updateSourceOrder (quidId, userOrder, buttonType) {
    for (const [sourceId, order] of this.sourceOrders.entries()) {
      if (sourceId !== quidId && order === userOrder) {
        // updates the order of the source that precedes/follows the selected source that the user is reordering
        const neighborOrder = +`${buttonType === OrderEnum.DOWN ? userOrder - 1 : userOrder + 1}`
        await this.applyOrderChange(QuiddityTagEnum.SOURCE, sourceId, neighborOrder)
      }
      await this.applyOrderChange(QuiddityTagEnum.SOURCE, quidId, userOrder)
    }
  }

  /**
   * Cleans all useless orders if a quiddity was removed
   * @param {string} quiddityId - The quiddity ID to clean
   */
  cleanOrders (quiddityId) {
    const { userQuiddityIds } = this.quiddityStore

    if (!userQuiddityIds.includes(quiddityId)) {
      this.removeOrder(quiddityId)

      LOG.debug({
        msg: 'Successfully removed quiddity order',
        quiddity: quiddityId
      })
    }
  }

  /**
   * Gets the order path from the quiddity tag
   * @param {models.QuiddityTagEnum} quidTag - Tag of the quiddity
   * @param {string} category - Matrix category of the quiddity
   * @async
   */
  getOrderPath (quidTag, category) {
    if (specialMatrixCategories.includes(category)) {
      return `order.${quidTag}:${category}`
    } else {
      return `order.${quidTag}`
    }
  }

  /**
   * Requests a change to the quiddity's order
   * @param {models.QuiddityTagEnum} quiddityTag - Tag of the quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @param {number} order - Order of the quiddity
   * @async
   */
  async applyOrderChange (quiddityTag, quiddityId, order) {
    const { userTreeAPI } = this.socketStore.APIs

    const category = this.quiddityStore.getQuiddityCategory(quiddityId)
    const orderPath = this.getOrderPath(quiddityTag, category)

    if (ORDER_PROPERTY_PATHS.test(orderPath)) {
      try {
        await userTreeAPI.graft(quiddityId, orderPath, order)

        LOG.debug({
          msg: 'Successfully applied new quiddity order',
          quiddity: quiddityId,
          order: order
        })
      } catch (error) {
        LOG.error({
          msg: 'Failed to apply new quiddity order',
          quiddity: quiddityId,
          order: order,
          error: error.message
        })
      }
    } else {
      LOG.error({
        msg: 'The quiddity tag cannot be applied to orders',
        quiddityTag: quiddityTag,
        quiddity: quiddityId
      })
    }
  }

  /**
   * Checks if the new order is different from the stored one
   * @param {string} quiddityTag - Tag of the quiddity
   * @param {string} quiddityId - Id of the quiddity
   * @param {number} order - Order of the quiddity
   * @returns {boolean} Returns true if the new order is different
   */
  isUpdatedOrder (quiddityTag, quiddityId, order) {
    let isUpdated = false

    if (quiddityTag === QuiddityTagEnum.SOURCE) {
      isUpdated = !this.sourceOrders.has(quiddityId) ||
                  this.sourceOrders.get(quiddityId) !== order
    } else if (quiddityTag === QuiddityTagEnum.DESTINATION) {
      isUpdated = !this.destinationOrders.has(quiddityId) ||
                  this.destinationOrders.get(quiddityId) !== order
    }

    return isUpdated
  }

  /**
   * Checks if the order value is valid
   * @param {string|number} order - The order value
   * @returns {boolean} Returns true if the order is valid
   */
  isValidOrder (order) {
    return !Number.isNaN(Number.parseInt(order))
  }

  /**
   * Sets the order of a given quiddity
   * @param {models.QuiddityTagEnum} quiddityTag - Tag of the quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @param {(number|string)} newOrder - New order of the quiddity
   */
  setOrder (quiddityTag, quiddityId, newOrder) {
    if (quiddityTag === QuiddityTagEnum.SOURCE) {
      this.sourceOrders.set(quiddityId, newOrder)

      LOG.info({
        msg: 'Successfully set a source order',
        quiddity: quiddityId,
        order: newOrder
      })
    } else if (quiddityTag === QuiddityTagEnum.DESTINATION) {
      this.destinationOrders.set(quiddityId, newOrder)

      LOG.info({
        msg: 'Successfully set a destination order',
        quiddity: quiddityId,
        order: newOrder
      })
    }
  }

  /**
   * Deletes all orders of a quiddity
   * @param {string} quiddityId - ID of the quiddity
   */
  removeOrder (quiddityId) {
    if (this.sourceOrders.has(quiddityId)) {
      this.sourceOrders.delete(quiddityId)
      this.resetSourceOrders()
    }

    if (this.destinationOrders.has(quiddityId)) {
      this.destinationOrders.delete(quiddityId)
      this.resetDestinationOrders()
    }
  }

  /**
   * Cleans OrderStore
   */
  clear () {
    this.sourceOrders.clear()
    this.destinationOrders.clear()
  }

  /**
   * Resets the order for all destination quiddities when a destination is removed
   */
  resetDestinationOrders () {
    const keys = [...this.destinationOrders.keys()]
    keys.map((quiddityId, index) => this.applyOrderChange(QuiddityTagEnum.DESTINATION, quiddityId, index))
  }

  /**
   * Resets the order for all source quiddities when a source is removed
   */
  resetSourceOrders () {
    const keys = [...this.sourceOrders.keys()]
    keys.map((quiddityId, index) => this.applyOrderChange(QuiddityTagEnum.SOURCE, quiddityId, index))
  }
}

export default OrderStore

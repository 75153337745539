import React, { useContext } from 'react'
import { observer } from 'mobx-react'

import { AppStoresContext } from '@components/App'

import { Feedback } from '@sat-mtl/ui-components'
import { useTranslation } from 'react-i18next'

const { Preview } = Feedback

/**
 * A preview windows for all video shmdatas
 * @selector `#PreviewWrapper[data-quiddity="quiddity id"][data-shmdata="shmdata path"]`
 */
const PreviewWrapper = observer(() => {
  const { previewStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  let $preview = null

  const positions = {
    left: '140px',
    bottom: '40px'
  }

  if (previewStore.url) {
    $preview = (
      <Preview
        {...positions}
        src={previewStore.url}
        alt={t('Video preview for selected stream')}
        onBackdropClick={() => previewStore.applyPreviewDisconnection()}
      />
    )
  }

  return (
    <div
      id='PreviewWrapper'
      style={{ position: 'absolute' }}
      data-shmdata={previewStore.previewShmdataPath}
      data-quiddity={previewStore.previewQuiddityId}
    >
      {$preview}
    </div>
  )
})

export default PreviewWrapper

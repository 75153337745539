import { observable, action, computed, reaction, makeObservable } from 'mobx'

import InitStateEnum from '@models/common/InitStateEnum'
import Quiddity from '@models/quiddity/Quiddity'
import QuiddityTagEnum from '@models/quiddity/QuiddityTagEnum'
import StatusEnum from '@models/common/StatusEnum'

import Store from '@stores/Store'
import KindStore from '@stores/quiddity/KindStore'
import ConfigStore from '@stores/common/ConfigStore'

import NicknameStore from '@stores/quiddity/NicknameStore'
import SpecStore from '@stores/shmdata/SpecStore'

import { getMutatedFromSwitcherTreePath, getPrunedFromSwitcherTreePath } from '@utils/objectTools'
import { logger } from '@utils/logger'
import { RequirementError } from '@utils/errors'

import i18next from 'i18next'

const { INITIALIZING, NOT_INITIALIZED } = InitStateEnum

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the QuiddityStore
 * @memberof module:stores/quiddity.QuiddityStore
 */
export const LOG = logger.child({ store: 'QuiddityStore' })

export const H264ENCODER_CLASS = 'h264Encoder'

/**
 * @classdesc Stores all special quiddities
 * @extends module:stores/common.Store
 * @memberof module:stores/quiddity
 */
class QuiddityStore extends Store {
  /** @property {Map<string, Function>} creationBindings - Functions used to create a specific quiddity, hashed by quiddity class */
  creationBindings = new Map()

  /** @property {Map<string, module:models/quiddity.Quiddity>} quiddities - All quiddities hashed by their IDs */
  quiddities = new Map()

  /** @property {Set<string>} selectedQuiddityIds - All selected quiddity ids by the user */
  selectedQuiddityIds = new Set()

  /** @property {string[]} quiddityIds - All quiddity IDs */
  get quiddityIds () {
    return Array.from(this.quiddities.keys())
  }

  /** @property {string} selectedQuiddity - ID of the first selected quiddity by the user */
  get selectedQuiddity () {
    let selected = null

    if (this.selectedQuiddityIds.size > 0) {
      const selectedId = Array.from(this.selectedQuiddityIds)[0]
      selected = this.quiddities.get(selectedId)
    }

    return selected
  }

  /** @property {Map<string, module:models/quiddity.Kind>} usedKinds - All currently instantiated kind ordered by quiddity ID */
  get usedKinds () {
    const { kinds } = this.kindStore

    return new Map(
      Array.from(this.quiddities.values())
        .map(quid => [quid.id, kinds.get(quid.kindId)])
        .filter(kindId => kindId)
    )
  }

  /** @property {Set<string>} usedKindIds - All instantiated quiddity kind IDs */
  get usedKindIds () {
    return new Set(
      Array.from(this.usedKinds.values())
        .map(kind => kind?.id)
        .filter(kindId => kindId)
    )
  }

  /** @property {module:models/quiddity.Quiddity[]} userQuiddities - All quiddities editable by the user */
  get userQuiddities () {
    return Array.from(this.quiddities.values())
      .filter(quid => !quid.isPrivate)
      .filter(quid => quid.isFollower || quid.isWriter)
  }

  /** @property {Map<string, module:models/quiddity.Quiddity>} quiddityByNames - All quiddities hashed by their unique nickname */
  get quiddityByNames () {
    return new Map(
      Array.from(this.quiddities.values())
        .map(quid => [this.nicknameStore.nicknames.get(quid.id), quid])
    )
  }

  /** @property {Map<string, number>} usedIndexes - All latest quiddity indexes for each quiddity class */
  get usedIndexes () {
    const indexes = new Map()

    for (const [, quiddity] of this.quiddities) {
      const { kindId, userTree } = quiddity

      if (kindId && Number.isFinite(userTree?.index)) {
        if (indexes.has(kindId)) {
          indexes.get(kindId).add(userTree.index)
        } else {
          indexes.set(kindId, new Set([userTree.index]))
        }
      }
    }

    return indexes
  }

  /** @property {string[]} userQuiddityIds - All IDs of the matrix's quiddities */
  get userQuiddityIds () {
    return this.userQuiddities.map(q => q.id)
  }

  /** @property {module:models/quiddity.Quiddity[]} destinations - All reader quiddities */
  get destinations () {
    return this.userQuiddities.filter(quid => quid.isFollower)
  }

  /** @property {string[]} destinationIds - All destination IDs */
  get destinationIds () {
    return this.destinations.map(quiddity => quiddity.id)
  }

  /** @property {module:models/quiddity.Quiddity[]} sources - All writer quiddities */
  get sources () {
    return this.userQuiddities.filter(quid => quid.isWriter)
  }

  /** @property {string[]} sourceIds - All source IDs */
  get sourceIds () {
    return this.sources.map(quiddity => quiddity.id)
  }

  /** @property {Map<string, string[]>} quiddityTags - Hashes all quiddity ids with their corresponding tags */
  get quiddityTags () {
    const quiddityTags = new Map()

    for (const id of this.quiddityIds) {
      const tags = []

      if (this.destinationIds.includes(id)) {
        tags.push(QuiddityTagEnum.DESTINATION)
      }

      if (this.sourceIds.includes(id)) {
        tags.push(QuiddityTagEnum.SOURCE)
      }

      quiddityTags.set(id, tags)
    }

    return quiddityTags
  }

  /** @property {Map<string, string[]>} quiddityLabels - Hashes all quiddity ids with their corresponding labels */
  get quiddityLabels () {
    const quiddityLabels = new Map()

    for (const [id, tags] of this.quiddityTags) {
      let label = 'resource'

      if (tags.length > 0) {
        label = tags[0]
      }

      quiddityLabels.set(id, label)
    }

    return quiddityLabels
  }

  /** @property {Map<string, string[]>} categorizedQuiddityIds - Hashes all quiddity categories with their corresponding quiddity Ids
   *
   * note : This is only ever used in the order store for destination quiddities. If this is ever populated with source quiddities, some
   * things will break. This and other functions related to destination quiddities (like matrixCategory and the related function in the
   * MatrixCategoryEnum file) should probably be renamed now that there is some categorisation for the sources and that the categorisation
   * logic is different for both.
   * * @todo Rename the `MatrixCategoryEnum` to `QuiddityCategoryEnum`
   */
  get categorizedQuiddityIds () {
    const categorizedQuiddityIds = new Map()

    for (const quidId of this.destinationIds) {
      const category = this.quiddities.get(quidId)?.matrixCategory

      if (categorizedQuiddityIds.has(category)) {
        categorizedQuiddityIds.get(category).push(quidId)
      } else {
        categorizedQuiddityIds.set(category, [quidId])
      }
    }

    return categorizedQuiddityIds
  }

  /**
   * Instantiates a new QuiddityStore
   * @param {module:stores/common.SocketStore} socketStore - Manages the websockets
   * @param {module:stores/common.ConfigStore} configStore - Configuration manager
   * @param {module:stores/quiddity.KindStore} kindStore - Quiddity classes manager
   * @param {module:stores/shmdata.SpecStore} SpecStore - ConnectionSpec manager
   * @param {module:stores/common.SessionStore} SessionStore - Sessions manager
   * @constructor
   */
  constructor (socketStore, configStore, kindStore, nicknameStore, specStore, sessionStore) {
    super(socketStore)
    this.missedInfoTreeUpdates = {}
    makeObservable(this, {
      quiddities: observable,
      selectedQuiddityIds: observable,
      quiddityIds: computed,
      selectedQuiddity: computed,
      usedKinds: computed,
      quiddityByNames: computed,
      usedKindIds: computed,
      userQuiddities: computed,
      usedIndexes: computed,
      userQuiddityIds: computed,
      destinations: computed,
      destinationIds: computed,
      sources: computed,
      sourceIds: computed,
      quiddityLabels: computed,
      categorizedQuiddityIds: computed,
      addQuiddity: action,
      addQuiddities: action,
      removeQuiddity: action,
      addSelectedQuiddity: action,
      cleanSelectedQuiddities: action,
      clear: action,
      handleGraftedInfoTree: action,
      handlePrunedInfoTree: action
    })

    if (configStore instanceof ConfigStore) {
      /** @property {module:stores/common.ConfigStore} configStore - Manages the configuration */
      this.configStore = configStore
    } else {
      throw new RequirementError(this.constructor.name, 'ConfigStore')
    }

    if (kindStore instanceof KindStore) {
      /** @property {module:stores/quiddity.KindStore} kindStore - Stores kinds */
      this.kindStore = kindStore
    } else {
      throw new RequirementError(this.constructor.name, 'KindStore')
    }

    if (nicknameStore instanceof NicknameStore) {
      /** @property {module:stores/quiddity.NicknameStore} nicknameStore - Stores nicknames */
      this.nicknameStore = nicknameStore

      reaction(
        () => this.quiddityIds,
        () => nicknameStore.handleQuiddityChange(this.quiddityIds),
        { fireImmediately: true }
      )
    } else {
      throw new RequirementError(this.constructor.name, 'NicknameStore')
    }

    if (specStore instanceof SpecStore) {
      /** @property {module:stores/shmdata.SpecStore} specStore - Stores connection specs */
      this.specStore = specStore
    } else {
      throw new RequirementError(this.constructor.name, 'SpecStore')
    }

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )
  }

  /**
   * Initializes all stored quiddities by fetching Switcher
   * @returns {boolean} Flags true if it is well initialized
   * @throws {Error} Throw the initialization failure
   * @async
   */
  async initialize () {
    if (!this.isNotInitialized()) return false
    this.setInitState(INITIALIZING)

    try {
      await this.initializeCurrentQuiddities()
    } catch (error) {
      this.clear()

      LOG.error({
        msg: 'Failed to initialize the current quiddities',
        error: error.message
      })

      throw error
    }

    try {
      await this.initializeInitQuiddities()
    } catch (error) {
      this.clear()

      LOG.error({
        msg: 'Failed to initialize the initQuiddities',
        error: error.message
      })

      throw error
    }

    return this.applySuccessfulInitialization()
  }

  /**
   * Initializes all pre-existing quiddities in the server
   * @async
   */
  async initializeCurrentQuiddities () {
    const jsonQuiddities = await this.fetchQuiddities()
    const quiddities = []
    for (const json of jsonQuiddities) {
      // Always parse the current quiddities but do not add it yet.
      const quiddity = await this.handleQuiddityCreation(json, false)
      quiddities.push(quiddity)
    }
    // Add all the created quiddities to this stores quiddities list.
    // we do this after the handleQuiddityCreation because otherwise handleQuiddityChange is
    // called too soon and starts disconnecting existing quiddities because it thinks the source or the destination
    // don't exist anymore
    this.addQuiddities(quiddities)
    /* then we apply creation bindings  */
    for (const quiddity of quiddities) {
      // Initialized the bound store if it exists
      this.applyCreationBindings(quiddity)
    }

    LOG.info({
      msg: 'All pre-existing quiddities are initialized',
      quiddities: jsonQuiddities.filter(q => q.id)
    })
  }

  /**
   * Initializes all initial quiddities specified in the user-defined configuration
   * @async
   */
  async initializeInitQuiddities () {
    const { quiddityAPI } = this.socketStore.APIs
    // list of the quiddities switcher returns us
    const quidditiesToProtect = []
    for (const initQuiddity of this.configStore.initQuiddities) {
      const { kindId, nickname } = initQuiddity

      const config = this.configStore.findInitialConfiguration(kindId, nickname)
      let quiddity
      if (config) {
        const { properties, userTree } = config
        quiddity = await this.applyQuiddityCreation(kindId, nickname, properties, userTree)
      } else {
        quiddity = await this.applyQuiddityCreation(kindId, nickname)
      }
      if (config.isProtected) {
        quidditiesToProtect.push(quiddity)
      }
      await this.applyCreationBindings(initQuiddity)
    }
    // Gets list of created quiddity ids. Filter out empty objects in case something went wrong
    const quidIds = quidditiesToProtect.filter(quid => quid && quid.id).map(quid => quid.id)
    // Tells switcher that these quiddity are never to be removed by a call
    // to reset_state (sessionApi.reset in the context of scenic)
    await quiddityAPI.protect(quidIds)
    LOG.info({
      msg: 'All initial quiddities are initialized',
      quiddities: this.configStore.initQuiddities.filter(q => q.nickname)
    })
  }

  /**
   * Applies the creation of a bound quiddity
   * @param {module:models/quiddity.Quiddity} quiddity - A bound quiddity
   * @returns {boolean} Returns true if the binding is applied
   * @async
   */
  async applyCreationBindings (quiddity) {
    let isApplied = false

    if (this.creationBindings.has(quiddity?.kindId)) {
      try {
        await this.creationBindings.get(quiddity.kindId)()
        isApplied = true

        LOG.info({
          msg: `Executed the creation binding for ${quiddity.kindId}`,
          quiddity: quiddity.nickname,
          kindId: quiddity.kindId
        })
      } catch (error) {
        LOG.error({
          msg: `Skipped the binding execution for ${quiddity.kindId}`,
          quiddity: quiddity.nickname,
          error: error.message
        })
      }
    }

    return isApplied
  }

  /**
   * Fetches all created quiddities
   * @returns {object[]} Returns all created quiddities on the server
   * @async
   */
  async fetchQuiddities () {
    const { quiddityAPI } = this.socketStore.APIs
    let jsonQuiddities = []

    try {
      const jsonResult = await quiddityAPI.listCreated()

      if (Array.isArray(jsonResult)) {
        jsonQuiddities = jsonResult
      } else {
        throw new Error('Failed to list all quiddities as an array')
      }
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'Created resources fetching failure',
        msg: 'A server error occurred while fetching all created resources.',
        error: error.message
      })
    }

    return jsonQuiddities
  }

  /**
   * Fetches all created quiddities by their IDs
   * @returns {string[]} Array of all created quiddity IDs
   * @async
   */
  async fetchAllQuiddityIds () {
    return (await this.fetchQuiddities()).map(json => json.id)
  }

  /**
   * Fetches a specific quiddity
   * @param {string} quiddityId - ID of the quiddity to fetch
   * @returns {?module:models/quiddity.Quiddity} Returns the fetched quiddity or null if the quiddity was not created
   */
  async fetchQuiddity (quidId) {
    return (await this.fetchQuiddities()).find(q => q.id === quidId)
  }

  /**
   * Fetches the InfoTree, UserTree and the ConnectionSpecs of the quiddity
   * @param {string} quidId - ID of the quiddity
   * @returns {object} InfoTree of the quiddity
   */
  async fetchQuiddityTrees (quidId) {
    const { userTreeAPI, infoTreeAPI, connectionSpecsAPI } = this.socketStore.APIs
    let jsonTrees = {}

    try {
      jsonTrees = {
        infoTree: await infoTreeAPI.get(quidId),
        userTree: await userTreeAPI.get(quidId),
        connectionSpecs: await connectionSpecsAPI.get(quidId)
      }

      LOG.info({
        msg: 'Successfully fetch all trees',
        quiddity: quidId,
        trees: jsonTrees
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to fetch all the quiddity trees',
        error: error.message
      })
    }

    return jsonTrees
  }

  /**
   * Pushes a thunk function that will be evaluated when the quiddity identified by quidId is created
   * by the handleQuiddityCreation callback.
   * This exists to make sure the quiddity store's state is up to date with the backend.
   * @param {string} quidId : Id of the quiddity
   * @param {function} thunk : parameterless function to be evaluated in handleQuiddityCreation
   */
  pushToMissedInfoTreeUpdates (quidId, thunk) {
    const updates = this.missedInfoTreeUpdates[quidId] || []
    updates.push(thunk)
    this.missedInfoTreeUpdates[quidId] = updates
  }

  /**
   * Updates a quiddity's infoTree to reflect the changes received from switcher.
   *  @param {string} quidId - Id of the quiddity
   *  @param {string} path - The switcher path of the change in the format "keys.in.the.tree" with nested keys
   *      separated by a .
   *  @param {null|string|number|Object} value - The value of the removed part of the tree
   *  @return the value of the pruned value
   */
  handlePrunedInfoTree = (quidId, path, value) => {
    // We need this because sometimes we receive signals from switcher when quiddities are not loaded yet into scenic.
    if (!this.quiddities.get(quidId)) {
      this.pushToMissedInfoTreeUpdates(quidId, () => this.handlePrunedInfoTree(quidId, path, value))
      return
    }
    const quid = this.quiddities.get(quidId)
    const prunedInfotree = getPrunedFromSwitcherTreePath(this.quiddities.get(quidId).infoTree, path, value)
    quid.infoTree = prunedInfotree
    return prunedInfotree
  }

  /**
   * Updates a quiddity's infoTree to reflect the changes received from switcher.
   *  @param {number} quidId - Id of the quiddity
   *  @param {string} path - The switcher path of the change in the format "keys.in.the.tree" with nested keys
   *      separated by a .
   *  @param {null|string|number|Object} value - The value of the part added to the tree
   */
  handleGraftedInfoTree = (quidId, path, value) => {
    // We need this because sometimes we receive signals from switcher when quiddities are not loaded yet into scenic.
    if (!this.quiddities.get(quidId)) {
      this.pushToMissedInfoTreeUpdates(quidId, () => this.handleGraftedInfoTree(quidId, path, value))
      return
    }
    const quid = this.quiddities.get(quidId)

    quid.infoTree = getMutatedFromSwitcherTreePath(this.quiddities.get(quidId).infoTree, path, value)
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   * @todo resolve session api
   */
  handleSocketChange (socket) {
    this.clear()

    if (socket && this.socketStore.hasActiveAPIs) {
      const { quiddityAPI, infoTreeAPI } = this.socketStore.APIs

      quiddityAPI.onCreated(
        json => this.handleQuiddityCreation(json),
        () => true
      )

      quiddityAPI.onDeleted(
        id => this.handleQuiddityRemoval(id),
        () => true
      )

      infoTreeAPI.onGrafted(
        this.handleGraftedInfoTree,
        () => true,
        () => true
      )

      infoTreeAPI.onPruned(
        this.handlePrunedInfoTree,
        () => true,
        () => true
      )
    }
  }

  /**
   * Handles new session file loading
   * @param {string} [sessionName=''] - Loaded file's name
   */
  async handleSessionChange (sessionName = '') {
    this.clear()

    if (await this.initialize()) {
      LOG.info({
        msg: 'Successfully initialized quiddities for new session',
        file: sessionName,
        quiddities: this.quiddityIds
      })
    }
  }

  /**
   * Handles a `quiddity.created` Switcher signal
   * @param {Object} json - Quiddity representation in JSON
     @param {boolean} [shouldAddQuiddity=true] - Quiddity representation in JSON
     @return {module:models/quiddity.Quiddity} The quiddity model created from the json
   */
  async handleQuiddityCreation (json, shouldAddQuiddity = true) {
    let quid = null

    try {
      if (Quiddity.isModelIncomplete(json)) {
        json = await this.fallbackQuiddityModel(json)
      }

      quid = Quiddity.fromJSON({ ...json })

      quid.configure(
        this.configStore.findInitialConfiguration(quid.kindId, quid.nickname)
      )

      if (!this.kindStore.kinds.get(quid.kindId)) {
        throw new Error(
          'The resource \'{{quid.id}}\' could not be created because the class \'{{quid.kindId}}\' doesn\'t exist.'
        )
      }

      if (!quid.isPrivate && !Number.isFinite(quid.userTree?.index)) {
        const index = this.makeQuiddityKindIndex(quid.kindId)
        await this.applyQuiddityIndex(quid.id, index)
        quid.userTree.index = index
      }

      if (this.quiddities.has(quid.id)) {
        LOG.warn({
          title: 'Skipped resource creation',
          msg: `The action was canceled because the resource '${quid.id}' already exists.`,
          quiddity: quid.id
        })
      } else {
        this.nicknameStore.addNickname(quid.id, quid.nickname)
        this.specStore.addConnectionSpecs(quid.id, quid.connectionSpecs)
        if (shouldAddQuiddity) {
          this.addQuiddity(quid)
        }
      }
      if (this.missedInfoTreeUpdates[quid.id]) {
        // eval all the stored thunk, sequentially replaying the events that were caught too soon.
        this.missedInfoTreeUpdates[quid.id].forEach(thunk => thunk())
        // delete the key from the object since we are done for this quiddity.
        delete this.missedInfoTreeUpdates[quid.id]
      }
    } catch (error) {
      LOG.error({
        notification: !quid?.isPrivate,
        title: 'Skipped resource creation',
        msg: i18next.t(error.msg, {
          nickname: quid?.nickname,
          kind: quid?.kindId,
          quiddity: quid?.id
        }),
        error: error.message,
        model: json
      })
    }

    return quid
  }

  /**
   * Fallbacks an incomplete quiddity modelby fetching missing attributes
   * @async
   * @param {object} json - the incomplete JSON model
   * @returns {object} a completed JSON model
   */
  async fallbackQuiddityModel (json) {
    const nickname = await this.nicknameStore.fetchNickname(json.id)
    const trees = await this.fetchQuiddityTrees(json.id)

    return { ...json, ...trees, nickname }
  }

  /**
   * Handles a `quiddity.removed` Switcher signal
   * @param {string} quiddityId - ID of the removed quiddity
   */
  handleQuiddityRemoval (quiddityId) {
    const quiddity = this.quiddities.get(quiddityId)

    if (quiddity) {
      this.removeQuiddity(quiddity)
    }

    for (const id of this.selectedQuiddityIds) {
      if (!this.quiddities.has(id)) {
        this.selectedQuiddityIds.delete(id)
      }
    }
  }

  /**
   * Creates the index of a quiddity by checking all indexes of quiddities with the same kind
   * @param {string} kindId - ID of the quiddity's kind to index
   * @returns {number} The quiddity's index
   */
  makeQuiddityKindIndex (kindId) {
    let index = 0

    if (this.usedIndexes.has(kindId)) {
      const usedIndexes = this.usedIndexes.get(kindId)
      const maxIndex = Math.max(...usedIndexes)

      if (Number.isFinite(maxIndex)) {
        index = maxIndex + 1
      }
    }

    return index
  }

  /**
   * Requests the creation of a quiddity
   * @param {string} kindId - Kind ID of the quiddity
   * @param {string} [quidName=null] - Name of the quiddity (default will be a generated ID)
   * @param {string} [nickname=''] - Nickname of the quiddity (default will be quiddityId, or a generated ID)
   * @param {Object} [properties={}] - Initial properties of the new quiddity
   * @param {Object} [userTree={}] - Initial user data of the new quiddity
   * @returns {Object} The model of the created quiddity
   * @async
   */
  async applyQuiddityCreation (kindId, nickname, properties, userTree) {
    const { quiddityAPI } = this.socketStore.APIs
    let quid = null

    try {
      quid = await quiddityAPI.create(kindId, nickname, properties, userTree)
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'Resource creation failure',
        msg: i18next.t("A server error occurred while creating the '{{kindId}}' resource.", { kindId }),
        kind: kindId,
        nickname: nickname,
        initProperties: properties,
        initUserTree: userTree,
        error: error
      })
    }
    return quid
  }

  /**
   * Requests a change to the quiddity's index
   * @param {string} quiddityId - ID of the quiddity
   * @param {number} index - Index of the quiddity
   * @async
   */
  async applyQuiddityIndex (quiddityId, index) {
    const { userTreeAPI } = this.socketStore.APIs

    try {
      await userTreeAPI.graft(quiddityId, 'index', index)
      LOG.debug({
        msg: 'Successfully applied new quiddity index',
        quiddity: quiddityId,
        index: index
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to apply new quiddity index',
        quiddity: quiddityId,
        index: index,
        error: error.message
      })
    }
  }

  /**
   * Requests the removal of a quiddity
   * @param {string} quiddityId - ID of the quiddity
   * @async
   */
  async applyQuiddityRemoval (quiddityId) {
    const { quiddityAPI } = this.socketStore.APIs

    try {
      await quiddityAPI.delete(quiddityId)
    } catch (error) {
      LOG.error({
        notification: true,
        title: 'Resource removal failure',
        msg: i18next.t("A server error occurred while removing resource '{{quiddityId}}'.", { quiddityId: quiddityId }),
        quiddity: quiddityId,
        error: error.message
      })
    }
  }

  /**
   * Requests a connection between two quiddities
   * @param {number} srcId - ID of the source quiddity
   * @param {number} destId - ID of the destination quiddity
   * @returns {Promise<number>} ID of the created connection if succeed
   * @async
   */
  async applyQuiddityConnection (srcId, destId) {
    const { quiddityAPI } = this.socketStore.APIs
    let sfId = null

    try {
      sfId = await quiddityAPI.connect(srcId, destId)

      LOG.info({
        msg: 'Successfully connected quiddities',
        source: srcId,
        destination: destId
      })
    } catch (error) {
      LOG.trace({
        msg: 'Failed to connect quiddities',
        source: srcId,
        destination: destId,
        error: error.message
      })
    }

    return sfId
  }

  /**
   * Requests a disconnection between a destination and a specific connection
   * @param {number} dstId - ID of the destination
   * @param {number} sfId - ID of the connection
   * @returns {Promise<boolean>} True if the disconnection succeeded
   * @async
   */
  async applyQuiddityDisconnection (dstId, sfId) {
    const { quiddityAPI } = this.socketStore.APIs
    let isDisconnected = false

    try {
      isDisconnected = await quiddityAPI.disconnect(dstId, sfId)
    } catch (error) {
      LOG.error({
        msg: 'Failed to disconnect quiddities',
        destination: dstId,
        followerShmdata: sfId,
        error: error.message
      })
    }

    return isDisconnected
  }

  /**
   * Provides the category of a quiddity
   * @param {string} quiddityId - The id of the quiddity
   * @returns {string} The quiddity's category
   */
  getQuiddityCategory (quiddityId) {
    return this.quiddities.get(quiddityId)?.matrixCategory
  }

  /**
   * Sets the quiddity creation bindings Map
   * @param {Map<string, Function>} bindings - Map containing quiddity classes as keys and functions as values
   */
  setCreationBindings (bindings) {
    this.creationBindings = bindings
  }

  /**
   * Adds a new quiddity creation binding in the creationBindings Map
   * @param {string} kindId - Quiddity kind ID to bind a function to
   * @param {Function} boundFunction - Function to call when creating associated quiddity class
   */
  addCreationBinding (kindId, boundFunction) {
    this.creationBindings.set(kindId, boundFunction)

    LOG.debug({
      msg: 'Added new quiddity creation binding',
      kindId: kindId,
      boundFunction: boundFunction
    })
  }

  /**
   * Removes a new quiddity creation binding from the creationBindings Map
   * @param {string} kindId - The bound quiddity kind
   */
  removeCreationBinding (kindId) {
    this.creationBindings.delete(kindId)

    LOG.debug({
      msg: 'Deleted quiddity creation binding',
      kindId: kindId
    })
  }

  /**
   * Adds a new quiddity to the store from a JSON model
   * @param {module:models/quiddity.Quiddity} quiddity - JSON-based model of a quiddity
   */
  addQuiddity (quiddity) {
    this.quiddities.set(quiddity.id, quiddity)

    LOG.info({
      notification: !quiddity.isPrivate,
      status: StatusEnum.ACTIVE,
      title: 'Success',
      msg: i18next.t("{{kindId}} '{{nickname}}' has been created.", {
        kindId: quiddity.kindId || 'Resource',
        nickname: quiddity.nickname
      }),
      quiddity: quiddity.toJSON()
    })
  }

  /**
   * Adds a lot of quiddities at once. Does this by first getting all the existing entries
     from the quiddities. map and then creating a new map with the existing entries and the
     new ones and assigning it to this.quiddities
   * @param {module:models/quiddity.Quiddity[]} quiddities - The quiddity models to add
   */
  addQuiddities (quiddities) {
    // creates a concatenation of the already existing entries in the quiddities map
    // and the new entries to be added
    const quids = Array.from(this.quiddities.entries()).concat(quiddities.map(quid => [quid.id, quid]))
    this.quiddities = this.quiddities.replace(new Map(quids))
    LOG.info({
      status: StatusEnum.ACTIVE,
      title: 'Success',
      msg: i18next.t("Successfully added {{num}} quiddities.'", {
        num: quids.length
      })
    })
  }

  /**
   * Deletes a quiddity from the store
   * @param {module:models/quiddity.Quiddity} quiddity - The quiddity model to remove
   */
  removeQuiddity (quiddity) {
    this.quiddities.delete(quiddity.id)

    LOG.info({
      notification: !quiddity.isPrivate,
      title: 'Success',
      msg: i18next.t("{{kindId}} '{{id}}' has been removed.", {
        kindId: quiddity.kindId || 'Resource',
        id: quiddity.id
      }),
      quiddity: quiddity.id
    })
  }

  /**
   * Adds a new quiddity in the selection batch
   * @param {string} quiddityId - ID of the selected quiddity
   */
  addSelectedQuiddity (quiddityId) {
    if (this.quiddities.has(quiddityId)) {
      this.selectedQuiddityIds.add(quiddityId)

      LOG.debug({
        msg: `Selected quiddity ${quiddityId}`,
        quiddity: quiddityId
      })
    } else {
      LOG.error({
        notification: true,
        title: 'Selection failure',
        msg: i18next.t("Could not select '{{quiddityId}}' because it is not handled by Scenic.", { quiddityId: quiddityId }),
        quiddity: quiddityId
      })
    }
  }

  /** Clears the selected quiddities batch */
  cleanSelectedQuiddities () {
    this.selectedQuiddityIds.clear()

    LOG.debug({
      msg: 'Successfully cleared selected quiddities'
    })
  }

  /**  Cleans up quiddities */
  clear () {
    this.setInitState(NOT_INITIALIZED)

    this.quiddities.clear()
    this.usedIndexes.clear()

    LOG.debug({
      msg: 'Successfully cleared all quiddities'
    })
  }
}

export default QuiddityStore

import React, { useContext } from 'react'
import classNames from 'classnames'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import '@styles/pages/LivePage.scss'

import { Layout, Context } from '@sat-mtl/ui-components'

const { FlexRow, Cell } = Layout
const { ThemeContext } = Context

/**
 * Renders a scene as a Cell
 * @param {module:models/userTree.Scene} scene - A scene model
 * @param {function} onClick - Function triggered when the user click on the Cell
 * @returns {external:ui-components/Layout.Cell} A Cell component
 */
function Scene ({ scene, onClick }) {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  const cl = classNames(
    'Scene',
    `Scene-${theme}`,
    { ActiveScene: scene.active }
  )

  return (
    <Cell
      className={cl}
      onClick={onClick}
    >
      {t(scene.name)}
    </Cell>
  )
}

/**
 * Displays all scenes created by the user
 * @param {module:stores/userTree.SceneStore} sceneStore - Store all scenes
 * @returns {external:mobx-react/ObserverComponent} All scenes rendered as Cells
 */
const Scenes = observer(({ sceneStore }) => {
  const userScenes = Array.from(sceneStore.userScenes.values())

  return (
    <FlexRow flexWrap>
      {userScenes.map(scene => (
        <Scene
          key={scene.id}
          scene={scene}
          onClick={() => {
            sceneStore.applyActiveSceneSwitch(scene.id, !scene.active)
          }}
        />
      ))}
    </FlexRow>
  )
})

/**
 * Page that displays all scenes
 * @memberof module:components/pages
 * @selector `#LivePage`
 * @param {module:stores/userTree.SceneStore} sceneStore - Store all scenes
 * @returns {external:react/Component} The live page
 */
function LivePage ({ sceneStore }) {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  return (
    <div
      id='LivePage'
      className={`page-${theme}`}
      style={{ height: '100%' }}
    >
      <header>
        {t('Click on a scene to activate it')}
      </header>
      <main>
        <Scenes sceneStore={sceneStore} />
      </main>
    </div>
  )
}

export default LivePage

export {
  Scene
}

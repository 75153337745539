/* global Blob */

import { observable, action, reaction, makeObservable } from 'mobx'

import ConfigStore from '@stores/common/ConfigStore'
import { TIMELAPSE_FILENAME_REGEX } from '@stores/quiddity/PropertyStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import ShmdataStore, { TREE_PATH_REGEX } from '@stores/shmdata/ShmdataStore'
import Store from '@stores/Store'

import { InitializationError, RequirementError } from '@utils/errors'
import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the PreviewStore
 * @memberof stores.PreviewStore
 */
export const LOG = logger.child({ store: 'PreviewStore' })

/**
 * @constant {string} PREVIEW_KIND_ID - Kind ID of the preview quiddity
 * @memberof stores.PreviewStore
 */
export const PREVIEW_KIND_ID = 'scenicPreview'

/**
 * @constant {string} PREVIEW_NICKNAME - Unique Nickname of the preview quiddity
 * @memberof stores.PreviewStore
 */
export const PREVIEW_NICKNAME = 'preview'

/**
 * @classdesc Stores preview subscription
 * @extends stores.Store
 * @memberof stores
 */
class PreviewStore extends Store {
  /** @property {stores.ConfigStore} configStore - Stores and manages the app's configuration */
  configStore = null

  /** @property {stores.QuiddityStore} quiddityStore - Stores and manages all quiddities */
  quiddityStore = null

  /** @property {stores.ShmdataStore} shmdataStore - Stores and manages all shmdatas */
  shmdataStore = null

  /** @property {boolean} acceptingUpdates - Flags if new images for the preview must be handled or not */
  acceptingUpdates = false

  /** @property {string} url - Local URL to the Blob which represents the preview */
  url = null

  /** @property {module:models/matrix.MatrixEntry} previewEntry - Stores the matrix entry that is currently previewed */
  previewEntry = null

  connectedSfid = null

  /** @property {?string} previewShmdataPath - Preview shmdata path */
  get previewShmdataPath () {
    return this.previewEntry?.shmdataPath
  }

  /** @property {?string} previewQuiddityId - Preview quiddity id */
  get previewQuiddityId () {
    return this.previewEntry?.quiddityId
  }

  get previewId () {
    return this.quiddityStore.quiddityByNames?.get(PREVIEW_NICKNAME)?.id
  }

  /**
   * Instantiates a new PreviewStore
   * @param {stores.SocketStore} socketStore - Socket manager
   * @param {stores.ConfigStore} configStore - Configuration manager
   * @param {stores.QuiddityStore} quiddityStore - Quiddity manager
   * @param {stores.ShmdataStore} shmdataStore - Shmdata manager
   * @constructor
   */
  constructor (socketStore, configStore, quiddityStore, shmdataStore) {
    super(socketStore)

    makeObservable(this, {
      url: observable,
      setPreview: action
    })

    if (configStore instanceof ConfigStore) {
      this.configStore = configStore
    } else {
      throw new RequirementError(this.constructor.name, 'ConfigStore')
    }

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new RequirementError(this.constructor.name, 'QuiddityStore')
    }

    if (shmdataStore instanceof ShmdataStore) {
      this.shmdataStore = shmdataStore
    } else {
      throw new RequirementError(this.constructor.name, 'ShmdataStore')
    }

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )
  }

  /**
   * Initializes the store by checking the QuiddityStore state
   * @returns {boolean} Flags true if store is well initialized
   * @throws {Error} Will throw an error if the quiddity store is not initialized
   */
  async initialize () {
    if (!this.isNotInitialized()) {
      return this.isInitialized()
    } else if (this.quiddityStore.isNotInitialized()) {
      throw new InitializationError('QuiddityStore')
    }

    if (!this.quiddityStore.quiddities.has(this.previewId)) {
      await this.fallbackPreviewQuiddity()
    }
  }

  /**
   * Creates the timelapse quiddity for video previews
   * @async
   */
  async fallbackPreviewQuiddity () {
    try {
      const config = this.configStore.findInitialConfiguration(PREVIEW_KIND_ID, this.previewId)

      if (config) {
        await this.quiddityStore.applyQuiddityCreation(
          PREVIEW_KIND_ID,
          PREVIEW_NICKNAME,
          config.properties,
          config.userTree
        )
      } else {
        await this.quiddityStore.applyQuiddityCreation(
          PREVIEW_KIND_ID,
          PREVIEW_NICKNAME
        )
      }

      LOG.info({
        msg: 'Successfully fallen back quiddity creation',
        quiddity: this.previewId
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to fallback quiddity creation',
        quiddity: this.previewId
      })
    }
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    if (socket && this.socketStore.hasActiveAPIs) {
      const { propertyAPI, infoTreeAPI } = this.socketStore.APIs

      propertyAPI.onUpdated(
        (quidId, property, value) => { if (this.acceptingUpdates) this.handleLastImage(value) },
        quidId => quidId === this.previewId,
        property => property.includes('last_image')
      )

      infoTreeAPI.onPruned(
        (quidId, path) => this.handleShmdataDisconnection(),
        quidId => quidId === this.previewId,
        path => Array.isArray(path.match(TREE_PATH_REGEX))
      )
    }
  }

  /**
   * Handles shmdata disconnection from Preview quiddity
   */
  handleShmdataDisconnection () {
    if (this.url) {
      URL.revokeObjectURL(this.url)
    }
    this.setPreview(null)
  }

  /**
   * Handles updates for 'last_image' property of Preview quiddity
   * @param {string} filename - Updated value of 'last_image' property
   * @async
   */
  async handleLastImage (filename) {
    const { imageAPI } = this.socketStore.APIs

    let data = null
    let blob = null

    try {
      data = await imageAPI.readImage(filename)

      const [, , shmdataId] = filename.match(TIMELAPSE_FILENAME_REGEX)

      if (this.url) {
        URL.revokeObjectURL(this.url)
      }

      blob = new Blob([new Int8Array(data)], { type: 'image/jpeg' })
      const newUrl = URL.createObjectURL(blob)
      this.setPreview(newUrl)

      LOG.trace({
        msg: `Updated preview url for ${shmdataId}`,
        shmdata: shmdataId
      })
    } catch (error) {
      LOG.error({
        msg: `Failed to update preview from file ${filename}`,
        error: error.message
      })
    }

    // This is done to prevent a memory leak
    data = null
    blob = null
  }

  /**
   * Requests connection of a matrix entry with the Preview quiddity
   * @param {module:models/matrix.MatrixEntry} entry - The matrix entry to preview
   */
  async applyPreviewConnection (entry) {
    if (entry.defaultShmdata.category === 'video') {
      if (this.url) {
        this.applyPreviewDisconnection()
      }

      this.acceptingUpdates = true
      this.connectedSfid = await this.socketStore.APIs.quiddityAPI.connectSfid(entry.quiddity.id, this.previewId, 1)

      LOG.info({
        msg: 'Successfully requested preview creation for video shmdata',
        shmdata: entry.defaultShmdata.id
      })
    }
  }

  /**
   * Requests preview of the matrix entry
   * @param {module:models/MatrixEntry.MatrixEntry} entry - A matrix entry
   */
  applyPreviewEntry (entry) {
    this.previewEntry = entry
    this.applyPreviewConnection(entry)
  }

  /**
   * Requests disconnection of all shmdatas from the Preview quiddity
   */
  applyPreviewDisconnection () {
    this.acceptingUpdates = false
    this.quiddityStore.applyQuiddityDisconnection(this.previewId, this.connectedSfid)

    LOG.info({
      msg: 'Successfully requested preview disconnection for all shmdatas'
    })
  }

  /**
   * Sets the preview URL
   * @param {string} url - Preview URL
   */
  setPreview (url) {
    this.url = url
  }
}

export default PreviewStore

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import StatusEnum from '@models/common/StatusEnum'
import HelpWrapper from '@components/wrappers/HelpWrapper'

import { Inputs } from '@sat-mtl/ui-components'

const { Field, InputText, InputPassword } = Inputs

/**
 * Field component for all credential input types
 * @memberof module:components/fields
 * @property {string} id - Unique ID of the field
 * @property {?string|number} [value=''] - Value of the input
 * @property {string} [label=''] - Label of the field
 * @property {string} [description=''] - Description of the field
 * @property {?string} [error=null] - Error message of the field
 * @property {boolean} [isPassword=false] - Flags a password credential
 * @property {boolean} [disabled=false] - Flags a disabled field
 * @property {function} [onChange=Function.prototype] - Function triggered when the field is changed
 * @property {function} [onCheck=Function.prototype] - Function triggered when the field is checked
 * @returns {external:ui-components/Inputs/Field} A field component
 */
function CredentialField ({ id, error, value, title, description, placeholder, isPassword, disabled, onChange, onCheck }) {
  const { t } = useTranslation()
  const status = error ? StatusEnum.DANGER : StatusEnum.FOCUS

  const $help = t(description)
  const $placeholder = t(placeholder)
  const $description = error ? t(error) : $help
  const $title = t(title)

  const updateValue = e => {
    onChange(e?.target?.value || '')
  }

  const checkValue = e => {
    onCheck(e?.target?.value || '')
  }

  const inputProps = {
    placeholder: $placeholder,
    name: id,
    value: value,
    onChange: updateValue,
    onBlur: checkValue,
    onPressEnter: checkValue
  }

  return (
    <div className='CredentialField'>
      <HelpWrapper message={$help}>
        <Field
          status={status}
          title={$title}
          description={$description}
          disabled={disabled}
        >
          {isPassword ? <InputPassword {...inputProps} /> : <InputText {...inputProps} />}
        </Field>
      </HelpWrapper>
    </div>
  )
}

CredentialField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  error: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  isPassword: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onCheck: PropTypes.func
}

CredentialField.defaultProps = {
  error: null,
  value: '',
  title: '',
  description: '',
  placeholder: '',
  isPassword: false,
  disabled: false,
  onChange: Function.prototype,
  onCheck: Function.prototype
}

export default CredentialField

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Inputs } from '@sat-mtl/ui-components'
import { toNumber, fromNumber } from '@models/common/UnitEnum'
import HelpWrapper from '@components/wrappers/HelpWrapper'

const { Field, Slider, InputNumber } = Inputs

/**
 * GridLayout adapted for the NumberField
 * @param {external:react/Component} children - All components in the grid
 * @returns {external:react/Component} A grid layout
 */
function GridLayout ({ children }) {
  return (
    <div style={{
      width: '100%',
      display: 'inline-grid',
      gridTemplateColumns: '[slider] auto [input] 160px',
      gridColumnGap: '8px'
    }}
    >
      {children}
    </div>
  )
}

/**
 * Field component that lets users input a numeric value
 * It validates its parameters by ensuring it won't break sub-components.
 * For example, it decreases the `min` property when it is equal to `max`.
 * @memberof module:components/fields
 * @param {string} title - Title of the field
 * @param {string} description - Description of the field
 * @param {string} value - Value of the property
 * @param {number} min - Minimum value of the field
 * @param {number} max - Maximum value of the field
 * @param {number} step - Specify the step used to increment or decrement the value
 * @param {models.UnitEnum} unit - Convert a value to display
 * @param {boolean} disabled - Flags a disabled value
 * @param {function} onChange - Function triggered when the property is changed by the user
 * @param {string} status - Status of the form
 * @returns {external:ui-components/Inputs/Field} - A field component
 */
function NumberField ({ title, description, value, min, max, step, unit, disabled, onChange, status }) {
  const { t } = useTranslation()

  const defaultLabel = t(
    'Input numeric value for "{{title}}" property',
    { title: t(title) }
  )

  // Ugly patch that prevents nvenc properties
  if (min === max) {
    min = max - 1
  }

  const convertedProps = {
    value: toNumber(unit, value),
    max: toNumber(unit, max),
    min: toNumber(unit, min),
    onChange: newValue => onChange(fromNumber(unit, newValue))
  }

  return (
    <div className='NumberField'>
      <HelpWrapper message={description ? t(description) : defaultLabel}>
        <Field
          status={status}
          disabled={disabled}
          title={t(title)}
          description={description ? t(description) : defaultLabel}
        >
          <GridLayout>
            <span className='FieldSlider' style={{ gridArea: 'slider' }}>
              <Slider
                {...convertedProps}
                disabled={disabled}
                step={step}
                status={status}
              />
            </span>
            <span style={{ gridArea: 'input' }}>
              <InputNumber
                {...convertedProps}
                disabled={disabled}
                step={step}
                status={status}
              />
            </span>
          </GridLayout>
        </Field>
      </HelpWrapper>
    </div>
  )
}

export default NumberField

import { observable, action, computed, reaction, makeObservable } from 'mobx'

import { NDI_STREAM_MODAL_ID } from '@components/modals/NdiStreamModal'

import {
  NDI_SNIFFER_KIND_ID,
  NDI_SNIFFER_NICKNAME,
  NDI_INPUT_KIND_ID,
  NDI_OUTPUT_KIND_ID
} from '@models/quiddity/specialQuiddities'

import NdiStream from '@models/quiddity/NdiStream'
import MatrixEntry from '@models/matrix/MatrixEntry'

import ConfigStore from '@stores/common/ConfigStore'
import InitStateEnum from '@models/common/InitStateEnum'
import ModalStore from '@stores/common/ModalStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import QuiddityMenuStore from '@stores/quiddity/QuiddityMenuStore'
import PropertyStore from '@stores/quiddity/PropertyStore'
import LockStore from '@stores/matrix/LockStore'
import MaxLimitStore from '@stores/shmdata/MaxLimitStore'

import Store from '@stores/Store'

import { InitializationError, RequirementError } from '@utils/errors'
import { logger } from '@utils/logger'

const { NOT_INITIALIZED } = InitStateEnum

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the NdiStore
 * @memberof module:stores/quiddity.NdiStore
 */
export const LOG = logger.child({ store: 'NdiStore' })

/**
 * @constant {RegExp} NDI_SNIFFER_OUTPUT_REGEX - Matches all changes to NDI Sniffer's stdout output
 * @memberof module:stores/quiddity.NdiStore
 */
export const NDI_SNIFFER_OUTPUT_REGEX = /^\.output.stdout/

/**
 * @constant {number} NDI_OUTPUT_MAX_LIMIT - Fixed max reader limit of the NDI Output
 * @memberof module:stores/quiddity.NdiStore
 */
export const NDI_OUTPUT_MAX_LIMIT = 2

/**
 * @classdesc Stores all current NDI streams on the network
 * @extends module:stores/common.Store
 * @memberof module:stores/quiddity
 */
class NdiStore extends Store {
  /** @property {module:stores/common.ConfigStore} configStore - Stores and manages the app's configuration */
  configStore = null

  /** @property {module:stores/quiddity.QuiddityStore} quiddityStore - Stores and manages all quiddities */
  quiddityStore = null

  /** @property {module:stores/quiddity.PropertyStore} propertyStore - Stores and manages all quiddities */
  propertyStore = null

  /** @property {module:stores/shmdata.MaxLimitStore} maxLimitStore - Stores and manages all quiddities connection limits */
  maxLimitStore = null

  /** @property {module:stores/common.ModalStore} modalStore - Stores and manages all the app's modals */
  modalStore = null

  /** @property {boolean} isNetworkScanned - Flag to notify that the local network has been scanned at least once for NDI streams */
  isNetworkScanned = false

  /** @property {boolean} isNdiInputRequested - Flag to notify a user request */
  isNdiInputRequested = false

  /** @property {models.NdiStream[]} streams - Observable NDI streams on the local network */
  ndiStreams = []

  /** @property {Object} selectedNdiStream - Selected NDI Stream option */
  selectedNdiStream = null

  /** @property {Object[]} ndiStreamOptions - Available NDI streams as options for Select components */
  get ndiStreamOptions () {
    return this.ndiStreams.map(stream => stream.toOption())
  }

  /** @property {string[]} ndiStreamLabels - Available NDI streams' labels */
  get ndiStreamLabels () {
    return this.ndiStreams.map(stream => stream.label)
  }

  /** @property {boolean} hasSelectedStreams - Flag to notify if a selected stream can be found in the selected ndi stream array */
  get hasSelectedStreams () {
    return this.selectedNdiStream !== null &&
      Object.keys(this.selectedNdiStream).length > 0
  }

  /** @property {boolean} hasSelectionError - Flag to notify if a selected stream's label cannot be found */
  get hasSelectionError () {
    return this.selectedNdiStream &&
      !this.ndiStreamLabels.includes(this.selectedNdiStream.label)
  }

  /** @property {string} ndiSnifferQuiddityId - The ID of the ndi sniffer quiddity */
  get ndiSnifferId () {
    return this.quiddityStore.quiddityByNames?.get(NDI_SNIFFER_NICKNAME)?.id
  }

  /**
   * Instantiates a new NdiStore
   * @param {module:stores/common.SocketStore} socketStore - Stores and manages the current event-driven socket
   * @param {module:stores/common.ConfigStore} configStore - Configuration manager
   * @param {module:stores/quiddity.QuiddityStore} quiddityStore - Quiddity manager
   * @param {module:stores/quiddity.PropertyStore} propertyStore - Property manager
   * @param {module:stores/quiddity.QuiddityMenuStore} quiddityMenuStore - Quiddity menus manager
   * @param {module:stores/shmdata.MaxLimitStore} maxLimitStore - MaxLimit manager
   * @param {module:stores/matrix.LockStore} lockStore - Lock manager
   * @param {module:stores/common.ModalStore} modalStore - Modal manager
   * @constructor
   */
  constructor (socketStore, configStore, quiddityStore, propertyStore, quiddityMenuStore, maxLimitStore, lockStore, modalStore) {
    super(socketStore)

    makeObservable(this, {
      isNetworkScanned: observable,
      isNdiInputRequested: observable,
      ndiStreams: observable,
      selectedNdiStream: observable,
      ndiStreamOptions: computed,
      ndiStreamLabels: computed,
      hasSelectedStreams: computed,
      hasSelectionError: computed,
      ndiSnifferId: computed,
      setNdiStreamSelection: action,
      setNdiInputRequestFlag: action,
      setIsNetworkScanned: action,
      setNdiStreams: action,
      clear: action
    })

    if (configStore instanceof ConfigStore) {
      this.configStore = configStore
    } else {
      throw new RequirementError('NdiStore', 'ConfigStore')
    }

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new RequirementError('NdiStore', 'QuiddityStore')
    }

    if (propertyStore instanceof PropertyStore) {
      this.propertyStore = propertyStore
    } else {
      throw new RequirementError('NdiStore', ' PropertyStore')
    }

    if (maxLimitStore instanceof MaxLimitStore) {
      this.maxLimitStore = maxLimitStore
    } else {
      throw new RequirementError('NdiStore', 'MaxLimitStore')
    }

    if (lockStore instanceof LockStore) {
      this.lockStore = lockStore
    } else {
      throw new RequirementError('NdiStore', 'LockStore')
    }

    if (modalStore instanceof ModalStore) {
      this.modalStore = modalStore
    } else {
      throw new RequirementError('NdiStore', 'ModalStore')
    }

    if (quiddityMenuStore instanceof QuiddityMenuStore) {
      this.quiddityMenuStore = quiddityMenuStore
    } else {
      throw new RequirementError('NdiStore', 'QuiddityMenuStore')
    }

    reaction(
      () => this.socketStore.activeSocket,
      socket => this.handleSocketChange(socket)
    )

    reaction(
      () => this.quiddityStore.initState,
      state => this.handleQuiddityStoreInitialization(state)
    )

    reaction(
      () => this.propertyStore.startableProperties,
      () => this.handleStartedPropertyChange()
    )

    reaction(
      () => this.isNdiInputRequested,
      state => this.handleNdiInputRequest(state)
    )

    lockStore.addLockableKind(NDI_OUTPUT_KIND_ID)
    quiddityMenuStore.addKindLabelBindings(NDI_INPUT_KIND_ID, 'NDI® Input')
  }

  /**
   * Initializes the store by checking the QuiddityStore state
   * @returns {boolean} Flags true if store is well initialized
   * @throws {Error} Will throw an error if the quiddity store is not initialized
   * @async
   */
  async initialize () {
    if (!this.isNotInitialized()) {
      return this.isInitialized()
    } else if (this.quiddityStore.isNotInitialized()) {
      throw new InitializationError('QuiddityStore')
    }

    if (!this.quiddityStore.quiddities.has(this.ndiSnifferId)) {
      await this.createNdiSnifferQuiddity()
    }

    return this.applySuccessfulInitialization()
  }

  /* Creates a NDI Sniffer executor quiddity if it doesn't exist yet.
   * This is called every refresh of scenic but should create the quiddity only
   * when it does not exist e.g. : A new switcher session was started.
   */
  async createNdiSnifferQuiddity () {
    try {
      const config = this.configStore.findInitialConfiguration(NDI_SNIFFER_KIND_ID, NDI_SNIFFER_NICKNAME)

      if (config) {
        await this.quiddityStore.applyQuiddityCreation(
          NDI_SNIFFER_KIND_ID,
          NDI_SNIFFER_NICKNAME,
          config.properties,
          config.userTree
        )
      } else {
        await this.quiddityStore.applyQuiddityCreation(
          NDI_SNIFFER_KIND_ID,
          NDI_SNIFFER_NICKNAME
        )
      }

      LOG.debug({
        msg: 'Successfully fallen back quiddity creation',
        quiddity: this.ndiSnifferId
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to fallback quiddity creation',
        quiddity: this.ndiSnifferId
      })
    }
  }

  /**
   * Matches a selected label with the available NDI streams
   * @param {string} label - Label of the selected NDI stream from UI
   * @returns {?module:models/quiddity.NdiStream} The NdiStream with the parametrized label
   */
  findNdiStream (label) {
    return this.ndiStreams.find(item => item.label === label) || null
  }

  /**
   * Handles changes to the app's socket
   * @param {external:socketIO/Socket} socket - Event-driven socket
   */
  handleSocketChange (socket) {
    this.clear()

    if (socket && this.socketStore.hasActiveAPIs) {
      const { infoTreeAPI } = this.socketStore.APIs

      infoTreeAPI.onGrafted(
        (quidId, path, value) => this.handleSnifferOutputUpdate(value),
        quidId => quidId === this.ndiSnifferId,
        path => path.match(NDI_SNIFFER_OUTPUT_REGEX)
      )
    }
  }

  /** Handle each changes to startable properties */
  handleStartedPropertyChange () {
    const { lockStore, quiddityStore, propertyStore } = this
    const ndiDestinations = quiddityStore.destinations
      .filter(q => q.kindId === NDI_OUTPUT_KIND_ID)

    for (const quiddity of ndiDestinations) {
      lockStore.setLock(
        new MatrixEntry(quiddity),
        propertyStore.isStarted(quiddity.id)
      )
    }
  }

  /**
   * Handles quiddityStore's initialization state changes
   * @param {module:models/common.InitStateEnum} initState - State of the quiddityStore's initialization
   */
  handleQuiddityStoreInitialization (initState) {
    const { NOT_INITIALIZED } = InitStateEnum

    if (initState === NOT_INITIALIZED) {
      this.clear()
    }
  }

  /**
   * Handles changes to the isNdiInputRequested flag
   * @param {boolean} state - State of the isNdiInputRequested flag
   * @async
   */
  async handleNdiInputRequest (state) {
    if (state) {
      if (!this.isNetworkScanned) await this.populateNdiStreams()
      this.modalStore.setActiveModal(NDI_STREAM_MODAL_ID)
    } else {
      this.modalStore.cleanActiveModal(NDI_STREAM_MODAL_ID)
    }
  }

  /**
   * Populates all available NDI streams
   * @async
   */
  async populateNdiStreams () {
    const { infoTreeAPI } = this.socketStore.APIs
    // this can be called before the infoTreeAPI is populated or before there is an ndiSniffer quiddity
    if (!infoTreeAPI || !this.ndiSnifferId) {
      const message = infoTreeAPI
        ? 'Tried to populate NDI streams before the ndiSniffer was created'
        : 'Tried to populate NDI streams before the infoTreeApi was initialized'
      LOG.warn({
        msg: message
      })
      return
    }
    try {
      const output = await infoTreeAPI.get(this.ndiSnifferId, '.output.stdout')
      this.handleSnifferOutputUpdate(output)
    } catch (error) {
      LOG.error({
        msg: 'Failed to populate NDI streams',
        error: error.message
      })
    }
  }

  /**
   * Updates the selection when NDI streams are updated
   * @param {string} label - Label of the selected NdiStream
   */
  updateNdiStreamSelection (label) {
    const stream = this.findNdiStream(label)

    if (stream) {
      this.setNdiStreamSelection(stream.toOption())
    } else {
      this.setNdiStreamSelection()
    }
  }

  /**
   * Updates all available streams from NDI Sniffer's output
   * @param {Object} output - NDI Sniffer's stdout which lists all available NDI streams
   * @see {@link https://gitlab.com/sat-mtl/tools/ndi2shmdata ndi2shmdata}
   */
  handleSnifferOutputUpdate (output) {
    try {
      if (output && output !== 'null') {
        const streams = NdiStream.fromOutput(output)

        this.setNdiStreams(streams)

        LOG.info({
          msg: 'NDI® streams detected',
          streams: streams
        })
      } else {
        LOG.info({
          msg: 'No NDI® streams currently detected'
        })
        this.setNdiStreams([])
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to parse the available NDI® streams',
        error: error.message
      })

      this.setNdiStreams([])
    }

    this.setIsNetworkScanned(true)

    /** @todo Reset selection when the ndi2shmdata output will be safe */
    // this.setNdiStreamSelection()
  }

  /**
   * Converts the selected NDI stream to an NDI input quiddity
   * @param {?string} [ndiLabel=null] - Label of the NDI stream to use as source
   * @async
   */
  async applyNdiInputQuiddityCreation (ndiLabel = null) {
    const { quiddityStore } = this

    if (quiddityStore.isInitialized()) {
      try {
        if (!ndiLabel) {
          ndiLabel = this.selectedNdiStream.label
        }
        const stream = this.findNdiStream(ndiLabel)

        if (stream) {
          const { properties } = stream
          await quiddityStore.applyQuiddityCreation(
            NDI_INPUT_KIND_ID,
            null,
            properties
          )
        } else {
          LOG.error({
            msg: 'Unable to match NDI stream from selected NDI label',
            label: ndiLabel,
            quiddity: 'NDI® input'
          })
        }
      } catch (error) {
        LOG.error({
          msg: 'Failed to create quiddity',
          quiddity: 'NDI® input',
          error: error
        })
      }
    }
  }

  /**
   * Sets (or resets) a new selected option
   * @param {?Object} [ndiOption=null] - The NdiStream selection
   */
  setNdiStreamSelection (ndiOption = null) {
    this.selectedNdiStream = ndiOption

    LOG.debug({
      msg: 'An NDI stream is selected',
      option: ndiOption
    })
  }

  /**
   * Flags the stream request
   * @param {boolean} isNdiInputRequested - Flag the stream request
   */
  setNdiInputRequestFlag (isNdiInputRequested) {
    this.isNdiInputRequested = isNdiInputRequested
  }

  /**
   * Flags the network scan status
   * @param {boolean} value - Flag the network scan
   */
  setIsNetworkScanned (value) {
    this.isNetworkScanned = value
  }

  /**
   * Sets new NDI Streams
   * @param {module:stores/quiddity.NdiStream[]} ndiStreams
   */
  setNdiStreams (ndiStreams) {
    this.ndiStreams.replace(ndiStreams)
  }

  /** Clears all NDI streams */
  clear () {
    this.ndiStreams.clear()
    this.setInitState(NOT_INITIALIZED)
  }
}

export default NdiStore

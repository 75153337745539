import { observable, action, makeObservable } from 'mobx'

import Store from '@stores/Store'

/**
 * @classdesc Stores all help messages
 * @extends stores.Store
 * @memberof stores
 */
class HelpMessageStore extends Store {
  /** @property {?string} helpMessage - The current help message */
  helpMessage = ''

  constructor (socketStore) {
    super(socketStore)

    makeObservable(this, {
      helpMessage: observable,
      setHelpMessage: action,
      cleanHelpMessage: action
    })
  }

  /**
   * Sets a new help message
   * @param {string} message - The help message
   */
  setHelpMessage (message) {
    this.helpMessage = message
  }

  /** Resets the help message  */
  cleanHelpMessage () {
    this.helpMessage = ''
  }
}

export default HelpMessageStore

class NetworkInterface {
  constructor (name, ipAddress, rxRate, txRate) {
    if (typeof name === 'undefined' || typeof name !== 'string') {
      throw new TypeError('Attribute `name` is required and must be a string')
    }

    this.name = name

    if (typeof ipAddress === 'undefined' || typeof ipAddress !== 'string') {
      throw new TypeError('Attribute `ipAddress` is required and must be a string')
    }

    this.ipAddress = ipAddress

    if (typeof rxRate === 'undefined' || typeof rxRate !== 'string') {
      throw new TypeError('Attribute `rxRate` is required and must be a string')
    }

    this.rxRate = rxRate

    if (typeof txRate === 'undefined' || typeof txRate !== 'string') {
      throw new TypeError('Attribute `txRate` is required and must be a string')
    }

    this.txRate = txRate
  }

  convertToReadableBitRate (totalBytes) {
    const total = parseFloat(totalBytes) * 8
    let size = null

    if (total >= 1000000000000) {
      const terabits = total / 1000000000000
      size = terabits.toFixed(2) + 'Tb'
    } else if (total >= 1000000000) {
      const gigabits = total / 1000000000
      size = gigabits.toFixed(2) + 'Gb'
    } else if (total >= 1000000) {
      const megabits = total / 1000000
      size = megabits.toFixed(2) + 'Mb'
    } else if (total >= 1000) {
      const bits = total / 1000
      size = bits.toFixed(2) + 'Kb'
    } else {
      size = total.toFixed(2) + 'b'
    }

    return size
  }

  /** @property Readable received rate (in byte rate) */
  get readableRxRate () {
    return this.convertToReadableBitRate(this.rxRate)
  }

  /** @property Readable transmited rate (in byte rate) */
  get readableTxRate () {
    return this.convertToReadableBitRate(this.txRate)
  }

  get readableInterface () {
    return `${this.name}(${this.ipAddress}) RX: ${this.readableRxRate} TX: ${this.readableTxRate}`
  }

  static fromJSON (name, ipAddress, json) {
    const rxRate = json.rxRate || json.rx_rate
    const txRate = json.txRate || json.tx_rate

    return new NetworkInterface(
      name,
      ipAddress,
      rxRate,
      txRate
    )
  }

  toJSON () {
    const { name, ipAddress, rxRate, txRate } = this

    return {
      name,
      ipAddress,
      rxRate,
      txRate
    }
  }
}

export default NetworkInterface

import CpuStatusEnum from '@models/systemUsage/CpuStatusEnum'

class CpuCore {
  constructor (name, idle, nice, system, total, user) {
    if (typeof name !== 'string' || name === '') {
      throw new TypeError('Attribute `name` is required and must be a string')
    }

    this.name = name
    this.idle = idle
    this.nice = nice
    this.system = system
    this.total = total
    this.user = user
  }

  get percent () {
    return Math.round(this.total * 100)
  }

  /** @property {models.CpuStatusEnum}  */
  get status () {
    let status = CpuStatusEnum.NORMAL

    if (this.percent >= 95) {
      status = CpuStatusEnum.FATAL
    } else if (this.percent >= 85) {
      status = CpuStatusEnum.ALERT
    } else if (this.percent >= 75) {
      status = CpuStatusEnum.WARNING
    }

    return status
  }

  static fromJSON (name, json) {
    const { idle, nice, system, total, user } = json
    return new CpuCore(name, idle, nice, system, total, user)
  }

  toJSON () {
    const { idle, nice, system, total, user } = this

    return {
      idle,
      nice,
      system,
      total,
      user
    }
  }
}

export default CpuCore

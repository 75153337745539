import React, { useRef, useEffect, useContext } from 'react'

import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'

import MenuCollection from '@models/menus/MenuCollection'
import MenuItem from '@models/menus/MenuItem'
import { AppStoresContext } from '@components/App'

import { Common } from '@sat-mtl/ui-components'

const { Menu: { Menu, Group, Item } } = Common

/**
 * A menu item that creates a quiddity
 * @memberof module:components/menus
 * @selector `.MenuItem[data-menu="<name of the item>"]`
 * @param {module:models/menus.MenuItem} menuItem - Model of the menu item
 * @returns {external:ui-components/Menu.Item} A menu item
 */
function QuiddityMenuItem ({ menuItem }) {
  const { quiddityMenuStore } = useContext(AppStoresContext)
  const { name, quiddity, itemKey } = menuItem

  return (
    <Item
      key={itemKey}
      dataMenu={name}
      onClick={() => {
        quiddityMenuStore.handleQuiddityRequest(quiddity)()
        quiddityMenuStore.clearAllMenus()
      }}
    >
      <Trans>
        {name}
      </Trans>
    </Item>
  )
}

/**
 * A group of items in the quiddity menu
 * @memberof module:components/menus
 * @selector `.MenuGroup[data-menu="<name of the collection>"]`
 * @param {module:models/menus.MenuGroup} menuGroup - Model of the menu group
 * @returns {external:ui-components/Menu.Group} A group of menu items
 */
const QuiddityMenuGroup = observer(({ menuGroup }) => {
  const { quiddityMenuStore } = useContext(AppStoresContext)
  const { name, items, groupKey } = menuGroup

  return (
    <Group
      accordion
      key={groupKey}
      dataMenu={name}
      title={<Trans>{name}</Trans>}
      onClick={() => quiddityMenuStore.toggleMenu(groupKey)}
      showGroup={quiddityMenuStore.openedMenus.has(groupKey)}
    >
      {items.map(item => (
        <QuiddityMenuFactory
          key={item.name}
          menuModel={item}
        />
      ))}
    </Group>
  )
})

/**
 * Abstract wrapper that builds a menu item or group
 * @param {module:models/menus.MenuGroup|module:models/menus.MenuItem} menuModel - Model for menu group or a menu item
 * @returns {external:react/Component} A menu group or a menu item
 * @throws {TypeError} Argument `menuModel` must be a MenuItem or a MenuGroup model
 */
function QuiddityMenuFactory ({ menuModel }) {
  if (menuModel instanceof MenuCollection) {
    return (
      <QuiddityMenuGroup menuGroup={menuModel} />
    )
  } else if (menuModel instanceof MenuItem) {
    return (
      <QuiddityMenuItem menuItem={menuModel} />
    )
  } else {
    throw new TypeError('The QuiddityMenuFactory expects a MenuItem or a MenuGroup model')
  }
}

/**
 * @constant {external:react/Component} DEFAULT_MENU_ADDON - Default addon for all quiddity menus
 * @memberof module:components/menus.QuiddityMenu
 */
export const DEFAULT_MENU_ADDON = <span>+</span>

/**
 * A whole quiddity menu built from a menu collection
 * @param {module:models/menus.MenuGroup} collection - A collection of menu models
 * @param {external:react/Component} addonBefore - A component that prefixes the menu name
 * @returns {external:ui-components/Menu} A menu collection of menus that creates quiddities
 */
const QuiddityMenu = observer(({ collection, addonBefore }) => {
  const { quiddityMenuStore } = useContext(AppStoresContext)
  const { name, items, collectionKey } = collection
  const menuRef = useRef(null)

  useEffect(() => {
    if (menuRef && menuRef.current) {
      menuRef.current.focus()
    }
  })

  return (
    <Menu
      accordion
      key={collectionKey}
      title={<Trans>{name}</Trans>}
      expanded={quiddityMenuStore.openedCollections.has(collectionKey)}
      onClick={() => quiddityMenuStore.toggleCollection(collectionKey)}
      onBlur={() => quiddityMenuStore.clearCollection(collectionKey)}
      addonBefore={addonBefore || DEFAULT_MENU_ADDON}
      menuRef={menuRef}
      dataMenu={name}
      width={120}
    >
      {items.map(menu => (
        <QuiddityMenuFactory
          key={`${collectionKey}-${menu.name}`}
          menuModel={menu}
        />
      ))}
    </Menu>
  )
})

export default QuiddityMenu

export { QuiddityMenuFactory, QuiddityMenuGroup, QuiddityMenuItem }

import React, { useContext } from 'react'
import classNames from 'classnames'

import { Context } from '@sat-mtl/ui-components'

import '@styles/entries/RowEntry.scss'

const { ThemeContext } = Context

/**
 * A generic entry that should be display in a column or as a row header
 * @selector `.RowEntry[data-entry="entry title"]`
 * @param {string} className - Extra class name of the component
 * @param {string} title - Title of the entry
 * @param {string} subtitle - Subtitle of the entry
 * @param {string} status - Status of the entry
 * @param {boolean} selected - Flags a selected entry
 * @param {boolean} disabled - Flags a disabled entry
 * @param {Function} onClick - Function triggered when the entry is clicked
 * @param {number} width - Set a custom width of the entry
 * @returns {external:react/Component} A row entry
 */
function RowEntry ({ className, title, subtitle, status, selected, disabled, onClick, width }) {
  const theme = useContext(ThemeContext)

  const cl = classNames(
    'RowEntry',
    `RowEntry-${theme}`,
    { SelectedEntry: selected },
    { DisabledEntry: disabled },
    className
  )

  const statusCl = classNames(
    'EntryStatus',
    `EntryStatus-${status}`
  )

  const onEntryClick = e => {
    if (!disabled) onClick(e)
  }

  return (
    <div
      className={cl}
      onClick={onEntryClick}
      style={{ width: width }}
      data-entry={title}
    >
      {status && <div className={statusCl} />}
      <div className='EntryHeader'>
        {title && <span className='EntryTitle'>{title}</span>}
        {subtitle && <span className='EntrySubtitle'>{subtitle}</span>}
      </div>
    </div>
  )
}

export default RowEntry

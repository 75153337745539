import React, { useEffect, useContext } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Mousetrap from 'mousetrap'

import './FileControlPanel.scss'

import { FILE_LOADING_DRAWER_ID } from '@components/drawers/FileLoadingDrawer'
import { FILE_SAVING_DRAWER_ID } from '@components/drawers/FileSavingDrawer'
import { FILE_DELETION_DRAWER_ID } from '@components/drawers/FileDeletionDrawer'

import { ResetSessionModal, RESET_SESSION_MODAL_ID } from '@components/modals/SessionModals'
import { AppStoresContext } from '@components/App'

import { MenuButton } from '@components/common/Buttons'
import { Layout, Common } from '@sat-mtl/ui-components'

import { EXTERNAL_SHMDATA_SOURCE_KIND_ID } from '@models/quiddity/specialQuiddities'

const { FlexColumn } = Layout
const { Icon } = Common

/**
 * Renders the button that resets the session
 * @selector `#NewSessionMenu`
 * @returns {module.components/common.MenuButton} A menu button
 */
function NewSessionMenu ({ disabled }) {
  const { modalStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const message = disabled ? t('Session actions are disabled while sending or receiving media using SIP') : t('Create a new session')

  return (
    <MenuButton
      menuName='NewSessionMenu'
      help={message}
      onClick={() => modalStore.setActiveModal(RESET_SESSION_MODAL_ID)}
      disabled={disabled}
      disabledMesage={t('Session actions are disabled while sending or receiving media using SIP')}
    >
      <Icon type='new-file' />
    </MenuButton>
  )
}

/**
 * Renders the button that opens the FileSavingDrawer
 * @selector `#SaveAsSessionMenu`
 * @returns {module.components/common.MenuButton} A menu button
 */
const SaveSessionAsMenu = observer(({ disabled }) => {
  const { drawerStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const message = disabled ? t('Session actions are disabled while sending or receiving media using SIP') : t('Save current session as a file')
  // if this is disable and the drawer is active, we close it asap before the
  // user can create a broken state.
  if (drawerStore.activeDrawer === FILE_SAVING_DRAWER_ID && disabled) {
    drawerStore.toggleActiveDrawer(FILE_SAVING_DRAWER_ID)
  }

  return (
    <MenuButton
      menuName='SaveAsSessionMenu'
      help={message}
      isActive={drawerStore.activeDrawer === FILE_SAVING_DRAWER_ID}
      onClick={() => {
        drawerStore.toggleActiveDrawer(FILE_SAVING_DRAWER_ID)
      }}
      disabled={disabled}
      disabledMesage={t('Session actions are disabled while sending or receiving media using SIP')}
    >
      <Icon type='save-file-as' />
    </MenuButton>
  )
})

/**
 * Save the current session if it was already saved or open the FileSavingDrawer
 * @param {module:stores/common.SessionStore} sessionStore - Store that manages sessions
 * @param {module:stores/common.DrawerStore} drawerStore - Store that manages drawers
 */
function saveCurrentSession (sessionStore, drawerStore) {
  if (sessionStore.currentSessionId) {
    sessionStore.applySessionSaving()
  } else {
    drawerStore.toggleActiveDrawer(FILE_SAVING_DRAWER_ID)
  }
}

/**
 * Renders the button that saves the current session
 * @selector `#SaveSessionMenu`
 * @returns {module.components/common.MenuButton} A menu button
 */
function SaveSessionMenu ({ disabled }) {
  const { drawerStore, sessionStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const message = disabled ? t('Session actions are disabled while sending or receiving media using SIP') : t('Save last modifications for the current ')

  return (
    <MenuButton
      menuName='SaveSessionMenu'
      help={message}
      onClick={() => saveCurrentSession(sessionStore, drawerStore)}
      disabled={disabled}
    >
      <Icon type='save-file' />
    </MenuButton>
  )
}

/**
 * Renders the button that opens the FileLoadingDrawer
 * @selector `#OpenSessionMenu`
 * @returns {module.components/common.MenuButton} A menu button
 */
const OpenSessionMenu = observer(({ disabled }) => {
  const { drawerStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const message = disabled ? t('Session actions are disabled while sending or receiving media using SIP') : t('Open an existing session')
  // if this is disable and the drawer is active, we close it asap before the
  // user can create a broken state.
  if (drawerStore.activeDrawer === FILE_LOADING_DRAWER_ID && disabled) {
    drawerStore.toggleActiveDrawer(FILE_LOADING_DRAWER_ID)
  }

  return (
    <MenuButton
      menuName='OpenSessionMenu'
      help={message}
      isActive={drawerStore.activeDrawer === FILE_DELETION_DRAWER_ID}
      onClick={() => drawerStore.toggleActiveDrawer(FILE_LOADING_DRAWER_ID)}
      disabled={disabled}
      disabledMesage={t('Session actions are disabled while sending or receiving media using SIP')}
    >
      <Icon type='open-folder' />
    </MenuButton>
  )
})

/**
 * Renders the button that opens the FileDeletionDrawer
 * @selector `#DeleteMenuButton`
 * @returns {module.components/common.MenuButton} A menu button
 */
const DeleteSessionMenu = observer(({ disabled }) => {
  const { drawerStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const message = disabled ? t('Session actions are disabled while sending or receiving media using SIP') : t('Delete existing sessions')
  // if this is disable and the drawer is active, we close it asap before the
  // user can create a broken state.
  if (drawerStore.activeDrawer === FILE_DELETION_DRAWER_ID && disabled) {
    drawerStore.toggleActiveDrawer(FILE_DELETION_DRAWER_ID)
  }

  return (
    <MenuButton
      menuName='DeleteMenuButton'
      help={message}
      isActive={drawerStore.activeDrawer === FILE_DELETION_DRAWER_ID}
      onClick={() => drawerStore.toggleActiveDrawer(FILE_DELETION_DRAWER_ID)}
      disabled={disabled}
      disabledMesage={t('Session actions are disabled while sending or receiving media using SIP')}
    >
      <Icon type='delete' />
    </MenuButton>
  )
})

/** The file control panel provides all the actions for the session files */
const FileControlPanel = observer(() => {
  const { modalStore, drawerStore, sessionStore, quiddityStore, contactStatusStore } = useContext(AppStoresContext)

  useEffect(() => {
    modalStore.addModal(RESET_SESSION_MODAL_ID)

    Mousetrap.bind('ctrl+s', event => {
      event.preventDefault && event.preventDefault()
      saveCurrentSession(sessionStore, drawerStore)
    })

    return () => {
      modalStore.removeModal(RESET_SESSION_MODAL_ID)
      Mousetrap.unbind('ctrl+s')
    }
  })
  // number of extshmsrcs is a good proxy for SIP sources
  const externalSources = Array.from(quiddityStore.quiddities.values()).filter(quid => quid.kindId === EXTERNAL_SHMDATA_SOURCE_KIND_ID).length
  // if we have any extshmsrc or a contact that we are currently calling, disable session interaction.
  const sessionDisabled = contactStatusStore.calledContacts.length + externalSources > 0

  if (modalStore.activeModal === RESET_SESSION_MODAL_ID && sessionDisabled) {
    modalStore.clearActiveModal()
  }
  return (
    <>
      <ResetSessionModal
        visible={modalStore.activeModal === RESET_SESSION_MODAL_ID}
        onReset={() => {
          sessionStore.applySessionReset()
          modalStore.clearActiveModal()
        }}
        onCancel={() => {
          modalStore.clearActiveModal()
        }}
      />
      <FlexColumn id='FileControlPanel' justifyContent='flex-end'>
        <NewSessionMenu disabled={sessionDisabled} />
        <OpenSessionMenu disabled={sessionDisabled} />
        <SaveSessionMenu disabled={sessionDisabled} />
        <SaveSessionAsMenu disabled={sessionDisabled} />
        <DeleteSessionMenu disabled={sessionDisabled} />
      </FlexColumn>
    </>
  )
})

export default FileControlPanel

import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { CancelButton } from '@components/common/Buttons'
import { Inputs, Common, Feedback, Layout } from '@sat-mtl/ui-components'

import { AppStoresContext } from '@components/App'
import { URI_REGEX } from '@stores/sip/SipStore'

const { Button } = Common
const { Modal } = Feedback
const { FlexRow } = Layout
const { InputText } = Inputs

/**
 * @constant {string} NEW_CONTACT_MODAL_ID - ID of the new contact modal
 * @memberof NewContactModal
 */
export const NEW_CONTACT_MODAL_ID = 'NewContactModal'

/**
 * Renders the footer of the NewContactModal
 * @param {Function} props.onCancel - Function triggered when the user cancels the contact creation action
 * @param {Function} props.onConfirm - Function triggered when the user confirms the contact creation action
 * @memberof module:components/modals.NewContactModal
 * @returns {external:react/Component} The footer of the Modal
 */
export const ModalFooter = ({ onConfirm, onCancel, disabled }) => {
  const { t } = useTranslation()

  return (
    <FlexRow justifyContent='space-between'>
      <CancelButton onCancel={onCancel} />
      <Button
        type='primary'
        className='AddContactButton'
        shape='rectangle'
        onClick={onConfirm}
        disabled={disabled}
      >
        {t('Add contact')}
      </Button>
    </FlexRow>
  )
}

/**
 * Scenic modal used to create a new contact
 * @selector `#NewContactModal`
 * @memberof module:components/modals
 * @returns {external:mobx-react/ObserverComponent} The modal
 */
export const NewContactModal = observer(({ onCancel, onConfirm }) => {
  const { t } = useTranslation()
  const { modalStore } = useContext(AppStoresContext)

  const [contactName, setContactName] = useState('')
  const [contactUri, setContactUri] = useState('')

  const isValidUrl = contactUri.match(URI_REGEX)

  return (
    <Modal
      id='NewContactModal'
      visible={modalStore.activeModal === NEW_CONTACT_MODAL_ID}
      onBackdropClick={onCancel}
      footer={(
        <ModalFooter
          disabled={!contactName.trim() || !isValidUrl}
          onConfirm={() => {
            onConfirm(contactName, contactUri)
          }}
          onCancel={onCancel}
        />)}
    >
      {t('Create a new temporary contact')}
      <div class='newContactModalContent'>
        <InputText
          className='newContactInput'
          placeholder={t('Contact name')}
          value={contactName}
          onChange={e => setContactName(e.target.value)}
        />
        <InputText
          className='newContactInput'
          placeholder={t('Contact uri')}
          value={contactUri}
          onChange={e => setContactUri(e.target.value)}
        />
      </div>
    </Modal>
  )
})

export default NewContactModal

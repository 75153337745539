import Ajv from 'ajv'

import connectionSchema from '@models/schemas/connection.schema.json'
import sceneSchema from '@models/schemas/scene.schema.json'
import userTreeSchema from '@models/schemas/userTree.schema.json'

const ajv = new Ajv()
  .addSchema(sceneSchema, 'scene')
  .addSchema(connectionSchema, 'connection')
  .addSchema(userTreeSchema, 'userTree')

export const Revision1 = {
  VERSION: 'rev1',

  /**
   * Migrates all connections from a scene to `rev1`
   * @param {Object} [connections={}] - Connections map to migrate
   */
  migrateConnections (connections = {}) {
    const connectionMap = {}

    for (const [connectionId, connection] of Object.entries(connections)) {
      const { id, source, destination, type } = connection
      connectionMap[connectionId] = { id, source, destination, type }
    }

    return connectionMap
  },

  /**
   * Migrates all scenes from a userTree to `rev1`
   * @param {Object} [scenes={}] - Scenes map to migrate
   */
  migrateScenes (scenes = {}) {
    const sceneMap = {}
    let connectionMap = {}

    for (const [sceneId, scene] of Object.entries(scenes)) {
      const { name, active } = scene.properties || {}
      let connections = {}

      if (scene.connections) {
        connections = this.migrateConnections(scene.connections)
      }

      connectionMap = { ...connectionMap, ...connections }
      sceneMap[sceneId] = { id: sceneId, name, active, connections: Object.keys(connections) }
    }

    return {
      scenes: sceneMap,
      connections: connectionMap
    }
  },

  /**
   * Migrates a userTree to `rev1`
   * @param {Object} [userTree={}]
   */
  migrate (userTree = {}) {
    const migrated = this.migrateScenes(userTree.scenes)

    return {
      version: Revision1.VERSION,
      ...migrated
    }
  }
}

export const VERSION = Revision1.VERSION

/**
 * Migrates `userTree` to the latest schema version
 * @param {JSON} json - The JSON `userTree` to migrate
 */
export default function migrate (json) {
  let userTree = json

  while (!ajv.validate('userTree', userTree)) {
    switch (userTree.version) {
      default: {
        userTree = Revision1.migrate(userTree)
        break
      }
    }
  }

  return userTree
}

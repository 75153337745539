import React, { useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import HelpWrapper from '@components/wrappers/HelpWrapper'
import { isStringEmpty } from '@utils/stringTools'

import { Common, Context } from '@sat-mtl/ui-components'
import '@styles/common/MenuButton.scss'

const { Button, Icon } = Common
const { ThemeContext } = Context

/**
 * Button used to cancel an action
 * @memberof module:components/common
 * @selector `.CancelButton`
 * @param {Function} onCancel - Function triggered when the button is clicked
 * @returns {external:ui-components/Inputs.Button} The cancel Button
 */
function CancelButton ({ onCancel }) {
  const { t } = useTranslation()
  const label = t('Cancel')

  return (
    <Button type='secondary' shape='rectangle' className='CancelButton' onClick={onCancel}>
      {label}
    </Button>
  )
}

/**
 * Button used to reset some actions
 * @memberof module:components/common
 * @selector `.ResetButton`
 * @param {Function} onReset - Function triggered when the button is clicked
 * @param {boolean} disabled - Flag that disables the button
 * @returns {external:ui-components/Inputs.Button} The reset Button
 */
function ResetButton ({ onReset, disabled }) {
  const { t } = useTranslation()
  const label = t('Reset')

  return (
    <Button
      outlined
      type='secondary'
      className='ResetButton'
      disabled={disabled}
      onClick={onReset}
      shape='rectangle'
    >
      {label}
    </Button>
  )
}

/**
 * Button used to confirm an action
 * @memberof module:components/common
 * @selector `.ConfirmButton`
 * @param {Function} onConfirm - Function triggered when the button is clicked
 * @param {React.Fragment} children - Text content of the button
 * @param {string} status - Status of the button
 * @param {boolean} disabled - Flag that disables the button
 * @returns {external:ui-components/Inputs.Button} The confirm Button
 */
function ConfirmButton ({ onConfirm, children, status, disabled }) {
  return (
    <Button
      className='ConfirmButton'
      disabled={disabled}
      type={status}
      onClick={onConfirm}
      shape='rectangle'
    >
      {children}
    </Button>
  )
}

/**
 * Button used to upload something
 * @memberof module:components/common
 * @selector `.UploadButton`
 * @param {Function} onUpload - Function triggered when the button is clicked
 * @param {boolean} disabled - Flag that disables the button
 * @param {React.Fragment} children - Text content of the button
 * @returns {external:ui-components/Inputs.Button} The upload Button
 */
function UploadButton ({ onUpload, disabled, children }) {
  const inputRef = useRef(null)

  return (
    <>
      <input
        ref={inputRef}
        className='UploadInput'
        type='file'
        accept='*.json'
        style={{ display: 'none' }}
        onChange={(e) => onUpload(e.target.files[0])}
      />
      <Button
        outlined
        disabled={disabled}
        className='UploadButton'
        type='primary'
        onClick={() => inputRef.current.click()}
        shape='rectangle'
      >
        {children}
      </Button>
    </>
  )
}

/**
 * Button used to download something
 * @memberof module:components/common
 * @selector `.DownloadButton`
 * @param {Function} onDownload - Function triggered when the button is clicked
 * @param {string} fileName - Name of te file to download
 * @param {boolean} disabled - Flag that disables the button
 * @param {React.Fragment} children - Text content of the button
 * @returns {external:ui-components/Inputs.Button} The download Button
 */
function DownloadButton ({ onDownload, fileName = '', disabled, children }) {
  const linkRef = useRef(null)
  const { t } = useTranslation()

  const isDownloadable = !isStringEmpty(fileName)
  let help = t('No file is selected')

  if (isDownloadable) {
    help = t('Download file {{name}}', { name: fileName })
  }

  return (
    <HelpWrapper message={help}>
      <a
        ref={linkRef}
        className='DownloadLink'
        style={{ display: 'none' }}
        download={fileName}
      />
      <Button
        outlined
        disabled={!isDownloadable}
        className='DownloadButton'
        type='primary'
        onClick={() => onDownload(linkRef.current)}
        shape='rectangle'
      >
        {t('Download')}
      </Button>
    </HelpWrapper>
  )
}

/**
 * Button used in a menu bar
 * @memberof module:components/common
 * @selector `.MenuButton`
 * @param {string} help - Help message displayed whe the button is hovered
 * @param {Function} onClick - Function triggered when the button is clicked
 * @param {boolean} isActive - Flag if the menu target is active (ex: an opened drawer)
 * @param {string} menuName - Name of the menu
 * @param {external:react/Component} children - Text content of the button
 * @returns {external:ui-components/Inputs.Button} The menu Button
 * @todo Add HTML *name* property for button components
 */
function MenuButton ({ help, onClick, children, isActive, menuName, disabled }) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  return (
    <HelpWrapper message={isActive ? t('Close opened drawer') : help}>
      <Button
        className={
        classNames(
          'MenuButton',
          { 'MenuButton-active': isActive },
          `MenuButton-${theme}`,
          `${menuName}`
        )
        }
        type='subtle'
        shape='square'
        onClick={onClick}
        name={menuName}
        disabled={disabled && !isActive}
      >
        {isActive ? <Icon type='close' /> : children}
      </Button>
    </HelpWrapper>
  )
}

export {
  MenuButton,
  DownloadButton,
  UploadButton,
  ConfirmButton,
  ResetButton,
  CancelButton
}

import migrate, { VERSION } from '@models/schemas/migration'

import QuiddityStore from '@stores/quiddity/QuiddityStore'
import ConfigStore from '@stores/common/ConfigStore'
import Store from '@stores/Store'

import { RequirementError, InitializationError } from '@utils/errors'
import InitStateEnum from '@models/common/InitStateEnum'
import { logger } from '@utils/logger'

import {
  USER_TREE_NICKNAME,
  USER_TREE_KIND_ID
} from '@models/quiddity/specialQuiddities'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the UserTreeStore
 * @memberof module:stores/userTree.UserTreeStore
 */
export const LOG = logger.child({ store: 'UserTreeStore' })

/**
 * @classdesc Error that signals a synchronization problem with the userTree quiddity
 * @extends Error
 * @memberof module:stores/userTree.UserTreeStore
 */
export class UserTreeSyncError extends Error {
  /**
   * Instantiates a new UserTreeSyncError
   * @constructor
   */
  constructor () {
    super('UserTree scenes and connections are not synchronized. Are stores initialized?')
    this.name = 'UserTreeSyncError'
  }
}

/**
 * @classdesc Stores the `userTree` emptyquid quiddity
 * @extends stores.Store
 * @memberof stores
 */
class UserTreeStore extends Store {
  /** @property {module:models/quiddity.Quiddity} userTreeQuiddity - The userTree quiddity model */
  get userTreeQuiddity () {
    return this.quiddityStore.quiddityByNames.get(USER_TREE_NICKNAME)
  }

  /** @property {string} userTreeId - ID of the userTree quiddity */
  get userTreeId () {
    return this.userTreeQuiddity?.id
  }

  isUserTreeQuiddity (quidId) {
    return this.userTreeId === quidId
  }

  /**
   * Instantiates a new UserTreeStore
   * @param {stores.SocketStore} socketStore - Socket manager
   * @param {stores.QuiddityStore} quiddityStore - A quiddity store
   * @constructor
   */
  constructor (socketStore, quiddityStore, configStore) {
    super(socketStore)

    if (quiddityStore instanceof QuiddityStore) {
      /** @property {module:stores/quiddity.QuiddityStore} quiddityStore - Stores and manages all quiddities */
      this.quiddityStore = quiddityStore
    } else {
      throw new RequirementError(this.constructor.name, 'QuiddityStore')
    }

    if (configStore instanceof ConfigStore) {
      /** @property {module:stores/quiddity.QuiddityStore} quiddityStore - Stores and manages all quiddities */
      this.configStore = configStore
    } else {
      throw new RequirementError(this.constructor.name, 'ConfigStore')
    }
  }

  async initialize () {
    let userTreeQuiddity = this.userTreeQuiddity

    if (this.isInitialized()) {
      return true
    }

    if (this.quiddityStore.isNotInitialized()) {
      throw new InitializationError('QuiddityStore')
    }

    if (!userTreeQuiddity) {
      userTreeQuiddity = await this.fallbackUserTreeQuiddity()
    }

    if (userTreeQuiddity) {
      this.applySuccessfulInitialization()
    } else {
      throw new InitializationError('UserTreeStore')
    }

    return this.isInitialized()
  }

  /**
   * Creates the userTree quiddity
   * Note that by default, the userTree quiddity is the only quiddity created by Scenic
   * @async
   */
  async fallbackUserTreeQuiddity () {
    let userTreeQuiddity = null

    try {
      const config = this.configStore.findInitialConfiguration(
        USER_TREE_KIND_ID,
        USER_TREE_NICKNAME
      )

      if (config) {
        userTreeQuiddity = await this.quiddityStore.applyQuiddityCreation(
          USER_TREE_KIND_ID,
          USER_TREE_NICKNAME,
          config.properties,
          config.userTree
        )
      } else {
        userTreeQuiddity = await this.quiddityStore.applyQuiddityCreation(
          USER_TREE_KIND_ID,
          USER_TREE_NICKNAME
        )
      }

      LOG.debug({
        msg: 'Successfully fallen back user tree creation',
        quiddity: USER_TREE_NICKNAME
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to fallback user tree creation',
        quiddity: USER_TREE_NICKNAME,
        error: error.message
      })
    }

    return userTreeQuiddity
  }

  /**
   * Fetches user data from the userTree quiddity
   * @param {string} [branch='.'] - Path in the userTree tree to fetch
   * @returns {(string|number|boolean|Object)} Returns the fetched user data (by default it returns an empty object)
   * @async
   */
  async fetchUserTree (branch = '.') {
    const { userTreeAPI } = this.socketStore.APIs
    let data = {}

    try {
      if (this.userTreeQuiddity) {
        data = await userTreeAPI.get(this.userTreeId, branch)
      } else {
        LOG.error({
          msg: 'Failed to fetch the userTree quiddity',
          reason: 'It seems the quiddity has been removed'
        })
      }

      if (data) {
        LOG.debug({
          msg: 'Successfully fetched user data as JSON',
          branch: branch,
          data: data
        })
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to fetch user data as JSON',
        error: error.message,
        data: data
      })
    }

    return data
  }

  /**
   * Requests change in the userTree tree of the userTree quiddity
   * @param {string} branch - Path of the data in the userTree tree
   * @param {(string|number|boolean|Object)} userTree - The user data to set
   * @async
   */
  async applyUserTreeCreation (branch, userTree) {
    const { userTreeAPI } = this.socketStore.APIs

    try {
      await userTreeAPI.graft(this.userTreeId, branch, userTree)

      LOG.debug({
        msg: 'Successfully created user data',
        branch: branch,
        data: userTree
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to create user data',
        branch: branch,
        data: userTree,
        error: error.message
      })
    }
  }

  /**
   * Requests the deletion of a userTree branch
   * @param {string} branch - Path of the branch to remove
   * @async
   */
  async applyUserTreeRemoval (branch) {
    const { userTreeAPI } = this.socketStore.APIs

    try {
      await userTreeAPI.prune(this.userTreeId, branch)

      LOG.debug({
        msg: 'Successfully removed user data',
        branch: branch
      })
    } catch (error) {
      LOG.error({
        msg: 'Failed to remove user data',
        branch: branch,
        error: error.message
      })
    }
  }

  /**
   * Migrates the userTree tree to the latest schema version
   * @param {string} branch - Branch of the userTree to migrate
   * @param {Object} userTree - User data tree to migrate
   * @returns {Object} The migrated user data tree, returns an empty object if migration failed
   * @async
   */
  async migrateUserTreeData (branch, userTree) {
    let migrated = userTree

    try {
      if (userTree.version !== VERSION) {
        migrated = migrate(userTree)

        for (const key of Object.keys(migrated)) {
          if (key !== 'version') {
            await this.applyUserTreeCreation(key, migrated[key])
          }
        }

        await this.applyUserTreeCreation('version', VERSION)

        LOG.debug({
          msg: 'Successfully migrated user tree data',
          version: VERSION,
          branch: branch
        })
      }
    } catch (error) {
      LOG.error({
        msg: 'Failed to migrate user tree data',
        version: VERSION,
        branch: branch
      })
    }

    return migrated[branch] || {}
  }

  /** Cleans up userTreeStore */
  clear () {
    const { NOT_INITIALIZED } = InitStateEnum
    this.setInitState(NOT_INITIALIZED)

    LOG.debug({
      msg: 'Successfully cleared all user scenes'
    })
  }
}

export default UserTreeStore

import { observable, action, makeObservable, toJS } from 'mobx'

import InitStateEnum from '@models/common/InitStateEnum'
import SocketStore from '@stores/SocketStore'

import { logger } from '@utils/logger'

const { NOT_INITIALIZED, INITIALIZING, INITIALIZED } = InitStateEnum

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the Store
 * @memberof module:stores/common.Store
 */
export const LOG = logger.child({ store: 'Store' })

/**
 * @classdesc Base store with shared utility functions
 * @memberof module:stores/common
 */
class Store {
  /** @property {stores.SocketStore} - Socket manager */
  socketStore = null

  /** @property {number} initState - Initialization state of the store */
  initState = NOT_INITIALIZED

  /**
   * Instantiates a new Store
   * @param {stores.SocketStore} socketStore - Socket manager
   * @constructor
   */
  constructor (socketStore) {
    makeObservable(this, {
      initState: observable,
      setInitState: action
    })

    if (socketStore instanceof SocketStore) {
      this.socketStore = socketStore
    } else {
      throw new TypeError('Store requires a SocketStore')
    }
  }

  /**
   * Check if the store's current initialization state is INITIALIZED
   * @returns {boolean} Flags true if store is INITIALIZED
   */
  isInitialized () {
    return this.initState === INITIALIZED
  }

  /**
   * Check if the store's current initialization state is INITIALIZING
   * @returns {boolean} Flags true if store is INITIALIZING
   */
  isInitializing () {
    return this.initState === INITIALIZING
  }

  /**
   * Check if the store's current initialization state is NOT_INITIALIZED
   * @returns {boolean} Flags true if store is NOT_INITIALIZED
   */
  isNotInitialized () {
    return this.initState === NOT_INITIALIZED
  }

  /**
   * Applies the INITIALIZED statement to the store
   * Should be called when everything is allright!
   * @returns {boolean} Returns true if the store is well-initialized
   */
  applySuccessfulInitialization () {
    this.setInitState(INITIALIZED)

    LOG.info({
      msg: `Successfully initialized ${this.constructor.name} store`
    })

    return this.isInitialized()
  }

  /** @property {boolean} hasActiveSocket - Checks if the active socket exists */
  get hasActiveSocket () {
    return this.socketStore.hasActiveSocket
  }

  /**
   * Sets a new initialization state for the store
   * @param {models.InitStateEnum} state - New initialization state
   * @returns {boolean} Flags true if the initialization state was updated
   */
  setInitState (state) {
    const oldState = this.initState
    const isUpdated = oldState !== state

    if (isUpdated) {
      this.initState = state
    }

    return isUpdated
  }

  /**
   * Prints the store as a JSON object for debug purpose
   * @returns {object} The store state in a JSON object
   */
  toJSON () {
    const model = {}
    const descriptors = Object.getOwnPropertyDescriptors(this)
    const getters = Object.entries(descriptors).filter(([key, value]) => !!value.get)

    for (const [key] of getters) {
      model[key] = toJS(this[key])
    }

    return model
  }
}

export default Store

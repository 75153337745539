import { observable, action, reaction, computed, makeObservable } from 'mobx'

import { RTMP_MODAL_ID } from '@components/modals/RtmpModal'

import MatrixEntry from '@models/matrix/MatrixEntry'

import { RTMP_KIND_ID } from '@models/quiddity/specialQuiddities'

import { isStringEmpty } from '@utils/stringTools'

import ModalStore from '@stores/common/ModalStore'
import QuiddityStore from '@stores/quiddity/QuiddityStore'
import PropertyStore from '@stores/quiddity/PropertyStore'
import MaxLimitStore from '@stores/shmdata/MaxLimitStore'

import LockStore from '@stores/matrix/LockStore'

import Store from '@stores/Store'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the RtmpStore
 * @memberof stores.RtmpStore
 */
export const LOG = logger.child({ store: 'RtmpStore' })

/**
 * @constant {number} RTMP_MAX_LIMIT - Fixed max reader limit of RTMP
 * @memberof stores.RtmpStore
 */
export const RTMP_MAX_LIMIT = 2

/**
 * @classdesc Manages all RTMP quiddities
 * @extends stores.Store
 * @memberof stores
 */
class RtmpStore extends Store {
  /** @property {stores.QuiddityStore} quiddityStore - Stores and manages all quiddities */
  quiddityStore = null

  /** @property {stores.MaxLimitStore} maxLimitStore - Stores and manages all quiddities connection limits */
  maxLimitStore = null

  /** @property {stores.ModalStore} modalStore - Stores and manages all the app's modals */
  modalStore = null

  /** @property {boolean} isStreamRequested - Flag to notify a user request */
  isRtmpRequested = false

  /** @property {string} url - URL of the RTMP platform */
  url = null

  /** @property {string} key - Key provided by the RTMP platform */
  key = null

  /** @property {boolean} hasValidCredentials - Checks if the url and the key are valid */
  get hasValidCredentials () {
    return !isStringEmpty(this.url) && !isStringEmpty(this.key)
  }

  /**
   * Instantiates a new RtmpStore
   * @param {stores.SocketStore} socketStore - Stores and manages the current event-driven socket
   * @param {stores.QuiddityStore} quiddityStore - Quiddity manager
   * @param {stores.ShmdataStore} shmdataStore - Shmdata manager
   * @param {stores.MaxLimitStore} maxLimitStore - MaxLimit manager
   * @param {stores.LockStore} lockStore - Lock manager
   * @param {stores.ModalStore} modalStore - Modal manager
   * @constructor
   */
  constructor (socketStore, quiddityStore, propertyStore, maxLimitStore, lockStore, modalStore) {
    super(socketStore)

    makeObservable(this, {
      isRtmpRequested: observable,
      url: observable,
      key: observable,
      hasValidCredentials: computed,
      setRtmpRequestFlag: action,
      setUrl: action,
      setKey: action,
      cleanRtmpCredentials: action
    })

    if (quiddityStore instanceof QuiddityStore) {
      this.quiddityStore = quiddityStore
    } else {
      throw new TypeError('RtmpStore requires a QuiddityStore')
    }

    if (propertyStore instanceof PropertyStore) {
      this.propertyStore = propertyStore
    } else {
      throw new TypeError('RtmpStore requires a PropertyStore')
    }

    if (maxLimitStore instanceof MaxLimitStore) {
      this.maxLimitStore = maxLimitStore
    } else {
      throw new TypeError('RtmpStore requires a MaxLimitStore')
    }

    if (lockStore instanceof LockStore) {
      this.lockStore = lockStore
    } else {
      throw new TypeError('RtmpStore requires a LockStore')
    }

    if (modalStore instanceof ModalStore) {
      this.modalStore = modalStore
    } else {
      throw new TypeError('RtmpStore requires a ModalStore')
    }

    reaction(
      () => this.isRtmpRequested,
      state => this.handleRtmpRequest(state)
    )

    reaction(
      () => this.propertyStore.startableProperties,
      () => this.handleStartedPropertyChange()
    )

    // All RTMP quiddities are lockable everytime, no need for runtime checks
    lockStore.addLockableKind(RTMP_KIND_ID)
  }

  /** Updates the RTMP max reader limit */
  fallbackRtmpMaxLimit () {
    const { maxReaderLimits } = this.maxLimitStore

    if (maxReaderLimits.has(RTMP_KIND_ID) && maxReaderLimits.get(RTMP_KIND_ID) !== RTMP_MAX_LIMIT) {
      this.maxLimitStore.addMaxReaderLimit(RTMP_KIND_ID, RTMP_MAX_LIMIT)
    }
  }

  /**
   * Handles each startable properties changes
   * It locks every RTMP quiddities that are started
   * @mermaid
   * sequenceDiagram
   *     User->>QuiddityStore: create a RTMP destination
   *     activate QuiddityStore
   *     QuiddityStore->>ConfigStore: ask for configured quiddities
   *     activate ConfigStore
   *     ConfigStore-->>QuiddityStore: return initQuiddities configuration
   *     deactivate ConfigStore
   *     QuiddityStore->>QuiddityStore: process a quiddity
   *     QuiddityStore-->>User: display the destination
   *     deactivate QuiddityStore
   *     opt The RTMP starting requirements are fullfiled
   *         User->>PropertyStore: start the destination
   *         activate PropertyStore
   *         PropertyStore->>PropertyStore: process the started property
   *         PropertyStore-->>RtmpStore: react to started quiddity and shmdata updates
   *         deactivate PropertyStore
   *         activate RtmpStore
   *         RtmpStore->>LockStore: lock the RTMP quiddity
   *         deactivate RtmpStore
   *         activate LockStore
   *         LockStore->>LockStore: update lockers
   *         LockStore-->>User: display the RTMP destination as locked
   *         deactivate LockStore
   *     end
   */
  handleStartedPropertyChange () {
    const { lockStore, quiddityStore, propertyStore } = this

    quiddityStore.destinations
      .filter(q => q.kindId === RTMP_KIND_ID)
      .forEach(q => lockStore.setLock(
        new MatrixEntry(q),
        propertyStore.isStarted(q.id)
      ))
  }

  /**
   * Handles changes to the isRtmpRequested flag
   * @param {boolean} state - State of the isRtmpRequested flag
   * @async
   */
  async handleRtmpRequest (state) {
    if (state) {
      this.modalStore.setActiveModal(RTMP_MODAL_ID)
    } else {
      this.modalStore.cleanActiveModal(RTMP_MODAL_ID)
    }
  }

  /**
   * Creates an RTMP quiddity with the provided RTMP settings from the Modal
   * @param {string} [url] - RTMP URL to stream to
   * @param {string} [key] - RTMP stream key
   * @async
   */
  async applyRtmpQuiddityCreation (url, key) {
    const { quiddityStore } = this

    const rtmpUrl = url || this.url
    const rtmpKey = key || this.key

    if (quiddityStore.isInitialized()) {
      try {
        await quiddityStore.applyQuiddityCreation(
          RTMP_KIND_ID,
          null,
          {
            'RTMP/stream_app_url': rtmpUrl,
            'RTMP/stream_key': rtmpKey
          }
        )
      } catch (error) {
        LOG.error({
          msg: 'Failed to create quiddity',
          quiddity: RTMP_KIND_ID,
          error: error
        })
      }
    }
  }

  /**
   * Sets the RTMP request flag
   * @param {boolean} state - Is the RTMP requested or not
   */
  setRtmpRequestFlag (state) {
    this.isRtmpRequested = state
  }

  /**
   * Sets the RTMP URL
   * @param {string} url - The URL of the RTMP platform
   */
  setUrl (url) {
    this.url = url
  }

  /**
   * Sets the RTMP key
   * @param {string} key - The key provided by the RTMP platform
   */
  setKey (key) {
    this.key = key
  }

  /** Clean the RTMP credentials */
  cleanRtmpCredentials () {
    this.url = null
    this.key = null
  }
}

export default RtmpStore

import { SendStatusEnum, RecvStatusEnum, BuddyStatusEnum, SubStateEnum } from '@models/sip/SipEnums'

import { CREDENTIALS_REGEX } from '@stores/sip/SipStore'
import { replaceAll } from '@utils/stringTools'

/**
 * @classdesc Model for displaying a SIP contact
 * @memberof models
 */
class Contact {
  /** @property {string} sipStatus - Connection status of the contact */
  status = BuddyStatusEnum.INACTIVE

  /**
   * Instantiates a new Contact model
   * @param {string|undefined} id - ID of the contact (given by Switcher)
   * @param {string} uri - URI of the contact
   * @param {string} [name] - Name of the contact (default is URI)
   * @param {string} [status] - Connection status of the contact. Must be one of models.BuddyStatusEnum's values (default is models.BuddyStatusEnum.UNKNOWN)
   * @param {string} [statusText] - Connection status description of the contact (default is 'unknown')
   * @param {models.SubStateEnum} [subState=models.SubStateEnum.UNKNOWN] - Subscription state of the contact with the SIP server.
   * @param {models.SubStateEnum} [sendStatus=models.SendStatusEnum.UNKNOWN] - Status of the outgoing call to this contact.
   * @param {models.SubStateEnum} [recvStatus=models.SendStatusEnum.UNKNOWN] - Status of the incoming call from this contact.

   * @param {string} [authorized=true] - If set, it whitelists the contact
   * @param {string[]} [connections] - Array of shmdata paths connected to the contact
   */
  constructor (id, uri, name, status = BuddyStatusEnum.UNKNOWN, statusText = 'unknown', subState = SubStateEnum.UNKNOWN, sendStatus = SendStatusEnum.UNKNOWN, recvStatus = SendStatusEnum.UNKNOWN, authorized = true, connections = []) {
    /** @property {string} id - ID of the contact */
    this.id = id

    if (typeof uri === 'undefined' || typeof uri !== 'string') {
      throw new TypeError('Attribute `uri` is required and must be a string')
    }

    /** @property {string} uri - URI of the contact */
    this.uri = uri

    if (typeof name !== 'undefined' && typeof name !== 'string') {
      throw new TypeError('Attribute `name` must be a string')
    }

    /** @property {string} name - Name of the contact */
    this.name = name || uri

    if (typeof status !== 'undefined' && typeof status !== 'string') {
      throw new TypeError('Attribute `status` must be a string')
    }

    this.status = Object.values(BuddyStatusEnum).includes(status) ? status : BuddyStatusEnum.UNKNOWN

    if (typeof statusText !== 'undefined' && typeof statusText !== 'string') {
      throw new TypeError('Attribute `statusText` must be a string')
    }

    /** @property {string} statusText - Connection status description of the contact */
    this.statusText = statusText || 'unknown'

    if (typeof subState !== 'undefined' && typeof subState !== 'string') {
      throw new TypeError('Attribute `subState` must be a string')
    }

    /** @property {string} subState - Subscription state of the contact with the SIP server */
    this.subState = Object.values(SubStateEnum).includes(subState) ? subState : SubStateEnum.UNKNOWN

    if (typeof sendStatus !== 'undefined' && typeof sendStatus !== 'string') {
      throw new TypeError('Attribute `sendStatus` must be a string')
    }

    /** @property {string} sendStatus - Status of the outgoing call to this contact */
    this.sendStatus = Object.values(SendStatusEnum).includes(sendStatus) ? sendStatus : SendStatusEnum.UNKNOWN

    if (typeof recvStatus !== 'undefined' && typeof recvStatus !== 'string') {
      throw new TypeError('Attribute `recvStatus` must be a string')
    }

    /** @property {string} recvStatus - Status of the incoming call from this contact */
    this.recvStatus = Object.values(RecvStatusEnum).includes(recvStatus) ? recvStatus : RecvStatusEnum.UNKNOWN

    if (typeof authorized !== 'boolean') {
      throw new TypeError('Attribute `authorized` must be a boolean')
    }

    /** @property {string} authorized - If set, contact is whitelisted and may call the currently logged in SIP user */
    this.authorized = authorized

    if (!Array.isArray(connections)) {
      throw new TypeError('Attribute `connections` must be an array of strings')
    }

    /** @property {string[]} connections - Array of shmdata paths connected to the contact */
    this.connections = connections
  }

  /**
   * Creates a model for each user contact
   * @param {object} userContacts - User-defined contact list fetched from the server
   * @returns {object[]} contacts - An array of all the user contacts models.
   * @static
   */
  static fromUserContacts (userContacts) {
    const contacts = []

    for (const [uri, values] of Object.entries(userContacts)) {
      contacts.push(new Contact(
        uri,
        uri,
        values.name
      ))
    }

    return contacts
  }

  /**
   * Gets the sip uri of a contact
   * @returns {string} Returns the sip uri
   */
  get sipUri () {
    return this.uri.split('@').pop()
  }

  /**
   * Gets the name of a contact
   * @returns {string} Returns the contact's name
   */
  get sipUser () {
    return this.uri.split('@').shift()
  }

  /**
   * Checks if the contact has shmdata connections
   * @returns {boolean} Returns true if the contact has connections
   */
  get hasShmdataConnections () {
    return this.connections &&
      this.connections.length > 0
  }

  /**
   * Checks if the contact is sending shmdatas
   * @returns {boolean} Returns true if the sending status is active
   */
  get isSendingShmdatas () {
    return this.sendStatus &&
      this.sendStatus !== 'unknown' &&
      this.sendStatus !== 'disconnected'
  }

  /**
   * Checks if the contact is receiving shmdatas
   * @returns {boolean} Returns true if the receiving status is active
   */
  get isReceivingShmdatas () {
    return this.recvStatus &&
      this.recvStatus !== 'unknown' &&
      this.recvStatus !== 'disconnected'
  }

  /**
   * Checks if the contact is part of the SIP session (if we are currently sending to or receiving from him)
   * This method doesn't check if the contact is actually part of the sessionContacts Map.
   * Use 'ContactStore.hasSessionContact' for this purpose
   * @returns {boolean} True if the contact is part of the current SIP session
   */
  get isInSession () {
    return this.hasShmdataConnections ||
      this.isSendingShmdatas ||
      this.isReceivingShmdatas
  }

  /** @property {string} userName - Username of the contact on the SIP server */
  get userName () {
    let userName

    if (Array.isArray(this.uri.match(CREDENTIALS_REGEX))) {
      [, userName] = this.uri.match(CREDENTIALS_REGEX)
    } else {
      userName = replaceAll(this.uri, '.', '_')
    }

    return userName
  }

  /** @property {?string} sipServer - Address of the SIP server where the contact is registered */
  get sipServer () {
    let sipServer = null

    if (Array.isArray(this.uri.match(CREDENTIALS_REGEX))) {
      [,, sipServer] = this.uri.match(CREDENTIALS_REGEX)
    }

    return sipServer
  }

  /**
   * @property {string} title - Parse the name of the contact for the title of UI elements
   * @todo Make this property more agnostic with the Contact name formating
   */
  get title () {
    let title = ''

    if (this.name) {
      [title] = this.name.split('|')
    }

    return title.trim()
  }

  /**
   * @property {string} subtitle - Parse the name of the contact for the subtitle of UI elements
   * @todo Make this property more agnostic with the Contact name formating
   */
  get subtitle () {
    let subtitle = ''

    if (this.name) {
      [, subtitle] = this.name.split('|')
    }

    return subtitle.trim()
  }

  /**
   * Creates a Contact model from a JSON object
   * @static
   * @param {Object} json - JSON object that modelizes a contact
   * @returns {models.Contact}
   */
  static fromJSON (json) {
    const { id, uri, name, status, connections } = json

    return new Contact(
      id,
      uri,
      name,
      status,
      json.statusText || json.status_text,
      json.subState || json.subscription_state,
      json.sendStatus || json.send_status,
      json.recvStatus || json.recv_status,
      json.authorized || json.whitelisted,
      connections
    )
  }

  /**
   * Creates an array of contacts from a tree of buddies
   * @param {Object} tree - The tree to parse
   * @returns {models.Contact[]} An array of contacts
   * @static
   */
  static fromTree (tree) {
    const contacts = []

    for (const [key, values] of Object.entries(tree)) {
      contacts.push(Contact.fromJSON({ id: key, ...values }))
    }

    return contacts
  }

  /**
   * Exports a Contact model to a JSON object
   * @returns {Object} JSON object that modelizes a contact
   */
  toJSON () {
    const { id, uri, name, status, statusText, subState, sendStatus, recvStatus, authorized, connections } = this

    return {
      id,
      uri,
      name,
      status,
      statusText,
      subState,
      sendStatus,
      recvStatus,
      authorized,
      connections
    }
  }
}

export default Contact

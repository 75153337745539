import React, { useEffect, useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { AppStoresContext } from '@components/App'

import { CancelButton, ConfirmButton } from '@components/common/Buttons'
import { Common, Inputs, Feedback, Layout } from '@sat-mtl/ui-components'

import './Modal.scss'

const { Spinner } = Common
const { Modal } = Feedback
const { FlexRow, FlexColumn } = Layout
const { Select } = Inputs

/**
 * @constant {string} NDI_STREAM_MODAL_ID - ID of the NDI stream modal
 * @memberof NdiStreamModal
 */
export const NDI_STREAM_MODAL_ID = 'NdiStreamModal'

/**
 * Renders the Cancel Button for the Modal
 * @memberof module:components/modals.NdiStreamModals
 * @returns {external:react/Component} The cancel button of the Modal
 */
export const NdiCancelButton = () => {
  const { ndiStore } = useContext(AppStoresContext)

  return (
    <CancelButton
      className='CancelButton'
      onCancel={() => ndiStore.setNdiInputRequestFlag(false)}
    />
  )
}

/**
 * Renders the Confirm Button for the Modal
 * @memberof module:components/modals.NdiStreamModals
 * @selector `.ConfirmButton`
 * @returns {module:components/common.ConfirmButton} The confirm button of the Modal
 */
export const NdiConfirmButton = () => {
  const { ndiStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  return (
    <ConfirmButton
      className='ConfirmButton'
      disabled={!ndiStore.hasSelectedStreams}
      onConfirm={() => {
        ndiStore.applyNdiInputQuiddityCreation()
        ndiStore.setNdiInputRequestFlag(false)
      }}
    >
      {t('Add')}
    </ConfirmButton>
  )
}

/**
 * Renders the Select that will select the NdiStream to process
 * @memberof module:components/modals.NdiStreamModals
 * @returns {external:mobx-react/ObserverComponent} The ndi stream selector
 */
export const NdiStreamSelect = observer(() => {
  const { ndiStore } = useContext(AppStoresContext)
  const { t } = useTranslation()
  const defaultLabel = t('No NDI® stream selected')

  return (
    <Select
      className='NdiStreamSelect'
      style={{ margin: 'auto' }}
      options={ndiStore.ndiStreamOptions}
      defaultOption={defaultLabel}
      placeholder={defaultLabel}
      selected={ndiStore.selectedNdiStream || {}}
      hasError={ndiStore.hasSelectionError}
      onSelection={(label) => ndiStore.updateNdiStreamSelection(label)}
    />
  )
})

/**
 * Renders the Modal used to show all NDI streams available
 * @memberof module:components/modals.NdiStreamModals
 * @selector `#NdiStreamModal`
 * @returns {external:mobx-react/ObserverComponent} The NdiStream Modal
 */
const NdiStreamModal = observer(() => {
  const { ndiStore } = useContext(AppStoresContext)
  const { t } = useTranslation()

  useEffect(() => {
    ndiStore.populateNdiStreams()
  }, [ndiStore.hasSelectedStreams])

  return (
    <Modal
      id='NdiStreamModal'
      visible={ndiStore.isNdiInputRequested}
      header={t('Create an NDI® source')}
      footer={(
        <FlexRow justifyContent='space-between'>
          <NdiCancelButton />
          <NdiConfirmButton />
        </FlexRow>
      )}
      onClick={() => ndiStore.setNdiInputRequestFlag(false)}
    >
      {!ndiStore.isNetworkScanned && (
        <Spinner backdropPos='relative' />
      )}
      {ndiStore.isNetworkScanned && ndiStore.ndiStreams.length === 0 && (
        t('No NDI® streams currently available on the local network')
      )}
      {ndiStore.isNetworkScanned && ndiStore.ndiStreams.length > 0 && (
        <FlexColumn columnGap={8}>
          <div className='caption'>
            {t('Select an NDI® stream:')}
          </div>
          <NdiStreamSelect />
        </FlexColumn>
      )}
    </Modal>
  )
})

export default NdiStreamModal

import React, { useContext, useEffect, useRef, createContext } from 'react'
import { observer } from 'mobx-react'

import { useTranslation } from 'react-i18next'
import { useHover } from '@utils/uiTools'

import { SendStatusEnum } from '@models/sip/SipEnums'
import StatusEnum, { fromBuddyStatus } from '@models/common/StatusEnum'

import { DRAWER_WIDTH } from '@stores/common/DrawerStore'
import { SIP_LOGIN_DRAWER_ID } from '@components/drawers/SipLoginDrawer'

import { NEW_CONTACT_MODAL_ID, NewContactModal } from '@components/modals/NewContactModal'

import HelpWrapper from '@components/wrappers/HelpWrapper'
import RowEntry from '@components/entries/RowEntry'
import SipContactInformationTooltip from '@components/tooltips/SipContactInformationTooltip'

import { Common, Feedback, Layout } from '@sat-mtl/ui-components'

import { AppStoresContext } from '@components/App'

import '@styles/drawers/SipContactDrawer.scss'

const { Drawer } = Feedback
const { FlexColumn, FlexRow, FlexBox } = Layout
const { Button, Icon } = Common

/**
 * @constant {string} SIP_CONTACT_DRAWER_ID - ID of the SIP contact drawer
 * @memberof module:components/drawers.SipContactDrawer
 */
const SIP_CONTACT_DRAWER_ID = 'SipContactDrawer'

/**
 * Renders a button that hangups all called contacts
 * @selector `.HangupAllButton`
 * @memberof module:components/drawers.SipContactDrawer
 * @param {Function} onHangupAll - Function triggered when the button is clicked
 * @returns {external:react/Component} The HangupAll button
 */
function HangupAllButton ({ onHangupAll }) {
  const { t } = useTranslation()
  const { contactStore } = useContext(AppStoresContext)

  return (
    <Button
      className='HangupAllButton'
      outlined
      type={StatusEnum.DANGER}
      shape='rectangle'
      onClick={() => contactStore.applyHangupAllCalls()}
    >
      {t('Hang up all')}
    </Button>
  )
}

/**
 * Renders a button that logouts the user from SIP
 * @selector `.LogoutButton`
 * @memberof module:components/drawers.SipContactDrawer
 * @param {Function} onLogout - Function triggered when the button is clicked
 * @returns {external:react/Component} The Logout button
 */
function LogoutButton ({ onLogout }) {
  const { t } = useTranslation()

  return (
    <Button
      className='LogoutButton'
      type={StatusEnum.DANGER}
      shape='rectangle'
      onClick={onLogout}
    >
      {t('Log out')}
    </Button>
  )
}

/**
 * Renders the Header of the SipContact drawer
 * @memberof module:components/drawers.SipContactDrawer
 * @param {string} status - A StatusEnum status
 * @param {module:models/sip.SipCredential} credentials - The SIP credentials of the user
 * @returns {external:react/Component} The header of the drawer
 */
function SipContactHeader ({ status, credentials }) {
  const { t } = useTranslation()

  let userName = t('Unknown')
  let userUri

  if (credentials && credentials.uri) {
    userName = credentials.sipUser
    userUri = credentials.uri
  }

  return (
    <RowEntry
      status={status}
      title={userName}
      subtitle={userUri}
    />
  )
}

/**
 * Renders the Footer of the SipContact drawer
 * @memberof module:components/drawers.SipContactDrawer
 * @returns {external:react/Component} The footer of the drawer
 */
const SipContactFooter = observer(() => {
  const { modalStore, sipStore, drawerStore, contactStore } = useContext(AppStoresContext)

  return (
    <FlexRow
      alignItems='center'
      justifyContent='space-between'
      flexDirection='row-reverse'
    >
      {modalStore.activeModal &&
        <NewContactModal
          onConfirm={async (contactName, contactUri) => {
            await contactStore.applyCompleteContactCreation(contactUri, contactName, contactStore.defaultAuthorization)
            modalStore.clearActiveModal()
          }}
          onCancel={() => {
            modalStore.clearActiveModal()
          }}
        />}

      <AddContactButton
        onAddContact={event => {
          modalStore.addModal(NEW_CONTACT_MODAL_ID)
          modalStore.setActiveModal(NEW_CONTACT_MODAL_ID)
        }}

      />
      <LogoutButton
        onLogout={() => {
          sipStore.applyLogout()
          drawerStore.setActiveDrawer(SIP_LOGIN_DRAWER_ID)
        }}
      />
    </FlexRow>
  )
})

/**
 * Renders a contact entry
 * @selector `.SipContact`
 * @memberof module:components/drawers.SipContactDrawer
 * @param {module:models/sip.Contact} contact - A contact model
 * @returns {HTMLElement} A contact of the SIP session
 */
function SipContact ({ contact }) {
  const { contactStatusStore } = useContext(AppStoresContext)
  const { buddyStatuses } = contactStatusStore
  const { name, uri, id } = contact

  return (
    <RowEntry
      className='SipContact'
      status={fromBuddyStatus(buddyStatuses.get(id))}
      title={name}
      subtitle={uri}
    />
  )
}

/**
 * Renders a button that triggers a contextual action with a contact
 * @selector `.SipContactButton`
 * @memberof module:components/drawers.SipContactDrawer
 * @param {Function} onClick - Function triggered when the button is clicked
 * @param {external:react/Component} children - A contextual content
 * @returns {external:react/Component} A contact button
 */
function SipContactButton ({ onClick, children }) {
  return (
    <Button
      className='SipContactButton'
      shape='square'
      type='heavy'
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

/**
 * Renders a button that calls a partner
 * @memberof module:components/drawers.SipContactDrawer
 * @param {module:models/sip.Contact} contact - The partner as a contact model
 * @returns {external:react/Component} A button that calls a partner
 */
function CallPartnerButton ({ contact }) {
  const { t } = useTranslation()
  const { contactStore } = useContext(AppStoresContext)

  const help = t('Send connected stream to \'{{- name}}\'', { name: contact.name })

  return (
    <HelpWrapper message={help}>
      <SipContactButton onClick={() => contactStore.applyCall(contact.uri)}>
        <Icon type='call' withTheme />
      </SipContactButton>
    </HelpWrapper>
  )
}

/**
 * Renders a button to hang up a call with a partner
 * @memberof module:components/drawers.SipContactDrawer
 * @param {module:models/sip.Contact} contact - The partner as a contact model
 * @returns {external:react/Component} Button that hangups a partner
 */
function HangupPartnerButton ({ contact }) {
  const { t } = useTranslation()
  const { contactStore } = useContext(AppStoresContext)

  const help = t('Stop sending connected streams to \'{{- name}}\'', { name: contact.name })

  return (
    <HelpWrapper message={help}>
      <SipContactButton onClick={() => contactStore.applyHangup(contact.uri)}>
        <Icon type='hangup' withTheme />
      </SipContactButton>
    </HelpWrapper>
  )
}

/**
 * Renders a button that removes a partner
 * @memberof module:components/drawers.SipContactDrawer
 * @param {module:models/sip.Contact} contact - The contact model
 * @returns {external:react/Component} Button that removes a partner
 */
function RemovePartnerButton ({ contact }) {
  const { t } = useTranslation()
  const { contactStore } = useContext(AppStoresContext)

  const help = t('Remove \'{{- name}}\' from the current session', { name: contact.name })

  return (
    <HelpWrapper message={help}>
      <FlexBox alignItems='center' justifyContent='center'>
        <SipContactButton onClick={() => contactStore.removeContactFromSession(contact.id)}>
          <Icon type='minus' />
        </SipContactButton>
      </FlexBox>
    </HelpWrapper>
  )
}

/**
 * Renders a button that adds a partner
 * @memberof module:components/drawers.SipContactDrawer
 * @param {module:models/sip.Contact} contact - The contact model
 * @returns {external:mobx-react/Component} Button that adds a partner
 * @see [Element.scrollIntoView() documentation]{@link https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView}
 */
function AddPartnerButton ({ contact }) {
  const { t } = useTranslation()

  const { contactStore } = useContext(AppStoresContext)
  const { partnerRef } = useContext(SectionRefContext)

  const help = t('Add \'{{- name}}\' to the current session', { name: contact.name })

  const onChangeHandler = () => {
    contactStore.addContactToSession(contact)

    if (partnerRef) {
      partnerRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  return (
    <HelpWrapper message={help}>
      <FlexBox alignItems='center' justifyContent='center'>
        <SipContactButton onClick={onChangeHandler}>
          <Icon type='plus' />
        </SipContactButton>
      </FlexBox>
    </HelpWrapper>
  )
}

/**
 * Renders a button that shows a modal that asks the user information to create a contact
 * @memberof module:components/drawers.SipContactDrawer
 * @returns {external:mobx-react/Component} Button that adds a partner
 * @see [Element.scrollIntoView() documentation]{@link https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView}
 */
function AddContactButton ({ onAddContact }) {
  const { t } = useTranslation()

  return (
    <Button
      className='AddContactButton'
      type='primary'
      shape='rectangle'
      disabled='true'
      onClick={onAddContact}
    >
      {t('Add contact')}
    </Button>
  )
}

/**
 * Renders a partner
 * @selector `.SessionPartner[data-contact="contact uri"]`
 * @memberof module:components/drawers.SipContactDrawer
 * @param {module:models/sip.Contact} contact - The partner as a contact model
 * @returns {external:mobx-react/ObserverComponent} A partner
 */
const SessionPartner = observer(({ contact }) => {
  const { contactStatusStore } = useContext(AppStoresContext)
  const { buddyStatuses, sendingStatuses, receivingStatuses } = contactStatusStore

  let $button

  if (sendingStatuses.get(contact.id) === SendStatusEnum.CALLING) {
    $button = (
      <HangupPartnerButton contact={contact} />
    )
  } else if (contact.hasShmdataConnections) {
    $button = (
      <CallPartnerButton contact={contact} />
    )
  } else {
    $button = (
      <RemovePartnerButton contact={contact} />
    )
  }

  return (
    <SipContactInformationTooltip
      buddyStatus={buddyStatuses.get(contact.id)}
      sendStatus={sendingStatuses.get(contact.id)}
      recvStatus={receivingStatuses.get(contact.id)}
    >
      <div
        className='SessionPartner'
        data-buddy={contact.id}
        data-contact={contact.uri}
      >
        <SipContact contact={contact} />
        {$button}
      </div>
    </SipContactInformationTooltip>
  )
})

/**
 * Renders the heading of the Partner section
 * @memberof module:components/drawers.SipContactDrawer
 * @param {number} calledContactsLength - Number of called contacts
 * @returns {external:react/Component} A heading
 */
function PartnerSectionHeader ({ calledContactsLength }) {
  const { t } = useTranslation()

  return (
    <h3 id='PartnerSectionHeader'>
      <div>{t('Session partners')}</div>
      {calledContactsLength > 1 && <HangupAllButton />}
    </h3>
  )
}

/**
 * Renders partners' section
 * @selector `#SessionPartnerSection`
 * @memberof module:components/drawers.SipContactDrawer
 * @params {external:react/Ref} forwardedRef - contains the current object of SessionPartnerSection
 * @returns {external:mobx-react/ObserverComponent} The partners' section
 */
const SessionPartnerSection = observer(() => {
  const { partnerRef } = useContext(SectionRefContext)
  const { t } = useTranslation()

  const { contactStore, contactStatusStore } = useContext(AppStoresContext)
  const { sessionContacts } = contactStore
  const { calledContacts } = contactStatusStore

  let $allPartners = (
    <aside>
      {t('Click on a \'+\' button to add a contact to the session')}
    </aside>
  )

  if (sessionContacts.size > 0) {
    $allPartners = Array.from(sessionContacts.values())
      .map(contact => (
        <SessionPartner
          key={contact.id}
          contact={contact}
        />
      ))
  }

  return (
    <section id='SessionPartnerSection' ref={partnerRef}>
      <FlexColumn rowGap={6}>
        <PartnerSectionHeader
          calledContactsLength={calledContacts.length}
        />
        {$allPartners}
      </FlexColumn>
    </section>
  )
})

/**
 * Renders a contact
 * @selector `.SessionContact[data-contact="contact uri"]`
 * @memberof module:components/drawers.SipContactDrawer
 * @param {module:models/sip.Contact} contact - The contact as a contact model
 * @returns {external:mobx-react/ObserverComponent} A contact
 */
const SessionContact = observer(({ contact }) => {
  const { contactStore, contactStatusStore } = useContext(AppStoresContext)
  const { buddyStatuses, sendingStatuses, receivingStatuses } = contactStatusStore

  let $button

  if (contactStore.partners.has(contact.id)) {
    $button = (
      <RemovePartnerButton contact={contact} />
    )
  } else {
    $button = (
      <AddPartnerButton contact={contact} />
    )
  }

  return (
    <SipContactInformationTooltip
      buddyStatus={buddyStatuses.get(contact.id)}
      sendStatus={sendingStatuses.get(contact.id)}
      recvStatus={receivingStatuses.get(contact.id)}
    >
      <div
        className='SessionContact'
        data-buddy={contact.id}
        data-contact={contact.uri}
      >
        <SipContact contact={contact} />
        {$button}
      </div>
    </SipContactInformationTooltip>
  )
})

/**
 * Renders the contacts section
 * @selector `#SessionContactSection`
 * @memberof module:components/drawers.SipContactDrawer
 * @returns {external:mobx-react/ObserverComponent} The contacts' section
 */
const SessionContactSection = observer(() => {
  const { t } = useTranslation()
  const { contactStore, contactStatusStore } = useContext(AppStoresContext)

  let $allContacts = (
    <aside>
      {t('Contact list is empty')}
    </aside>
  )

  if (contactStore.contacts.size > 0) {
    $allContacts = Array.from(contactStatusStore.sortedContacts.values())
      .filter(contact => (!contactStore.partners.has(contact.id)))
      .map(contact => (
        <SessionContact
          key={contact.id}
          contact={contact}
        />
      ))
  }

  return (
    <section id='SessionContactSection'>
      <FlexColumn rowGap={6}>
        <h3>
          {t('Contact directory')}
        </h3>
        {$allContacts}
      </FlexColumn>
    </section>
  )
})

/**
 * @constant {external:react/Context} SectionRefContext - Dispatches the section ref
 * @memberof module:components/drawers/SipContactDrawer
 */
export const SectionRefContext = createContext({})

/**
 * Drawer that controls all the SIP contacts
 * @selector `#SipContactDrawer`
 * @memberof module:components/drawers
 * @returns {external:mobx-react/ObserverComponent} The contacts' drawer
 */
const SipContactDrawer = observer(() => {
  const { sipStore, contactStatusStore, drawerStore, modalStore } = useContext(AppStoresContext)
  const [hoverRef, isHovered] = useHover()

  const { currentCredentials } = sipStore
  const { selfStatus } = contactStatusStore

  const partnerRef = useRef(null)
  const isActive = drawerStore.activeDrawer === SIP_CONTACT_DRAWER_ID

  useEffect(() => {
    drawerStore.addDrawer(SIP_CONTACT_DRAWER_ID)

    return () => {
      drawerStore.removeDrawer(SIP_CONTACT_DRAWER_ID)
    }
  })

  return (
    <div id={SIP_CONTACT_DRAWER_ID} ref={hoverRef}>
      {isActive && isHovered && <div className={!modalStore.activeModal ? 'DrawerOverflow' : ''} />}
      <Drawer
        visible={isActive}
        width={DRAWER_WIDTH}
        withBackdrop={false}
        header={
          <SipContactHeader
            status={selfStatus}
            credentials={currentCredentials}
          />
        }
        footer={
          <SipContactFooter />
        }
        onBackdropClick={() => drawerStore.clearActiveDrawer()}
      >
        <SectionRefContext.Provider value={{ partnerRef }}>
          <FlexColumn rowGap={40}>
            <SessionPartnerSection />
            <SessionContactSection />
          </FlexColumn>
        </SectionRefContext.Provider>
      </Drawer>
    </div>
  )
})

export default SipContactDrawer

export {
  SIP_CONTACT_DRAWER_ID,
  HangupAllButton,
  LogoutButton,
  SipContactHeader,
  SipContactFooter,
  SipContact,
  SipContactButton,
  CallPartnerButton,
  HangupPartnerButton,
  RemovePartnerButton,
  AddPartnerButton,
  SessionPartner,
  PartnerSectionHeader,
  SessionPartnerSection,
  SessionContact,
  SessionContactSection
}

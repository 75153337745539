
    import React, { Fragment } from 'react';
    
    
    const Markdown = () => (<Fragment><ul>
<li>Alexandre Gauthier</li>
<li>David Ledoux</li>
<li>Jacques Bournival</li>
<li>Matthew Hills</li>
<li>Valentin Foch</li>
</ul>
</Fragment>);
    export default Markdown;
  
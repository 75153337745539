/* global Date */

import { parseDate } from '@utils/stringTools'

/**
 * @constant {string} FILE_NAME_REGEX
 * @description Matches a file path in order to extract name, extension or base directory
 * @memberof models.FileBridge
 */
const FILE_NAME_REGEX = /^(.*\/)?(.+)\.(\w+)/i

/**
 * @constant {string} DEFAULT_FILE_NAME
 * @description Default name of files
 * @memberof models.FileBridge
 */
const DEFAULT_FILE_NAME = 'session'

/**
 * @constant {string} DEFAULT_FILE_EXTENSION
 * @description Default extension of files
 * @memberof models.FileBridge
 */
const DEFAULT_FILE_EXTENSION = 'json'

/**
 * @classdesc Model use to manipulate files between the server and the WebAPIs
 * @memberof models
 */
class FileBridge {
  /**
   * Instantiates a new File bridge
   * @param {string} id - ID of the file, should be unique
   * @param {Date} [date=new Date()] - Date of the last modification
   * @param {string} content - Content of the file
   */
  constructor (id, date = new Date(), content = '') {
    if (typeof id === 'undefined' || typeof id !== 'string') {
      throw new TypeError('Attribute `id` is required and must be a string')
    }

    /** @property {string} id - ID of the file, should be unique */
    this.id = id

    /** @property {Date} date - Date of the last modification */
    this.date = parseDate(date)

    /** @property {string} content - Content of the file */
    this.content = content
  }

  /** @property {Date} lastModified - Date of the last modification */
  get lastModified () {
    return this.date.toLocaleString()
  }

  /** @property {(string|undefined)} name - Name of the file */
  get name () {
    return this.id.match(FILE_NAME_REGEX)[2]
  }

  /** @property {(string|undefined)} extension - Extension of the file */
  get extension () {
    return this.id.match(FILE_NAME_REGEX)[3]
  }

  /** @property {string} fileName - Displayed name of the file */
  get fileName () {
    const elements = []

    if (this.name) {
      elements.push(this.name)
    } else {
      elements.push(DEFAULT_FILE_NAME)
    }

    if (this.extension) {
      elements.push(this.extension)
    } else {
      elements.push(DEFAULT_FILE_EXTENSION)
    }

    return elements.join('.')
  }

  /**
   * Convert a Blob from WebAPIs to a FileBridge
   * @param {(Blob|File)} blob - WebAPIs' blob
   * @returns {models.FileBridge} - A new FileBridge
   * @static
   */
  static fromFileBlob (blob) {
    return new FileBridge(blob.name, blob.name, blob.lastModified)
  }

  /**
   * Convert a Switcher server file representation to a FileBridge
   * @param {Object} json - The JSON representation of the file
   * @returns {models.FileBridge} - A new FileBridge
   * @static
   */
  static fromJSON (json) {
    return new FileBridge(json.id, json.date)
  }

  /**
   * Convert a FileBridge to JSON
   * @returns {Object} - A json representation of the File
   */
  toJSON () {
    return {
      id: this.id,
      name: this.name,
      date: this.date.toISOString()
    }
  }
}

export default FileBridge

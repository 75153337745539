import React from 'react'
import ReactDOM from 'react-dom'
import App from '@components/App'
import { logger } from '@utils/logger'

import { toJS } from 'mobx'

import populateStores from '@stores/populateStores'
import { hasDebugMode, debugStores } from '@utils/debugTools'

const stores = populateStores()

if (hasDebugMode()) {
  logger.warn('debug tools are enabled!')

  window.toJS = toJS
  window.stores = stores
  window.debugStores = () => debugStores(stores)
}

ReactDOM.render(
  <App stores={stores} />,
  document.getElementById('scenic')
)

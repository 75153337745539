import { useContext } from 'react'
import { AppStoresContext } from '@components/App'

import Ajv from 'ajv'
import connectionSchema from '@models/schemas/connection.schema.json'

import Quiddity from '@models/quiddity/Quiddity'
import Contact from '@models/sip/Contact'
import Shmdata from '@models/shmdata/Shmdata'

import ShmdataRoleEnum from '@models/shmdata/ShmdataRoleEnum'

import { SIP_ID } from '@models/quiddity/specialQuiddities'

/**
 * @classdesc Connection model used to display switcher `userTree`
 * @memberof models
 */
class Connection {
  /**
   * Instantiate a new userTree Connection
   * @param {string} sourceId - ID of the source quiddity
   * @param {string} destinationId - ID of the destination quiddity
   * @param {string} type - Type of the connected shmdata
   * @param {string} [sfId=null] - ID of the follower shmdata when connection is effective
   * @param {string} [contactId=null] - ID of the destination contact
   * @param {number} [rank=0] - Rank of the connection
   */
  constructor (sourceId, destinationId, type, sfId = null, contactId = null, rank = 0) {
    if (typeof sourceId === 'undefined') {
      throw new TypeError('Attribute sourceId is required')
    }

    /** @property {string} sourceId - ID of the quiddity source */
    this.sourceId = sourceId

    if (typeof destinationId === 'undefined') {
      throw new TypeError('Attribute sourceId is required')
    }

    /** @property {string} destinationId - ID of the quiddity destination */
    this.destinationId = destinationId

    if (this.destinationId === SIP_ID && typeof contactId !== 'string') {
      throw new TypeError('A SIP destination must be associated with a contactId')
    } else {
      this.contactId = contactId
    }

    /** @property {string} type - Type of the connected shmdata */
    this.type = type

    /** @property {number} - Rank of the connection (used to set connection priority) */
    this.rank = rank

    if (typeof sfId !== 'undefined' && typeof sfId === 'number') {
      this.sfId = sfId
    }
  }

  /**
   * Generates the ID for a SIP Contact
   * @static
   * @param {module:models/quiddity.Quiddity|string} [srcQuiddity] - The quiddity source or its ID
   * @param {module:models/quiddity.Quiddity|string} [destQuiddity] - The quiddity destination or its ID
   * @param {module:models/shmdata.Shmdata|string} [shmdata] - A shmdata model or its mediaType
   * @param {module:models/sip.Contact|string} [contact] - A contact model or its ID
   *
   * @returns {string} An unique connection ID
   * @note : switcher ids are number so we need to transform them to string.
   */
  static getId (srcQuiddity, destQuiddity, shmdata, contact) {
    const ids = []

    if (srcQuiddity instanceof Quiddity) {
      ids.push(srcQuiddity.id)
    } else {
      ids.push(srcQuiddity)
    }

    if (destQuiddity instanceof Quiddity) {
      ids.push(destQuiddity.id)
    } else {
      ids.push(destQuiddity)
    }

    if (contact instanceof Contact) {
      ids.push(contact.userName)
    } else if (typeof contact === 'string') {
      ids.push(contact)
    }

    if (shmdata instanceof Shmdata) {
      ids.push(shmdata.mediaType)
    } else if (typeof shmdata === 'string') {
      ids.push(shmdata)
    }
    return ids.join('_')
  }

  get id () {
    return Connection.getId(
      this.sourceId,
      this.destinationId,
      this.contactId
    )
  }

  /**
   * Generates the nickname for a connection
   * @static
   * @param {string} [srcQuiddityId] - The source quiddity ID
   * @param {string} [destQuiddityId] - The destination quiddity ID
   * @param {module:models/sip.Contact|string} [contact] - A contact model or its ID
   *
   * @returns {string} An connection nickname
   */
  static getNickname (srcQuiddityId, destQuiddityId, contact) {
    const { nicknameStore } = useContext(AppStoresContext)
    const nicknames = []

    nicknames.push(nicknameStore.nicknames.get(srcQuiddityId))
    nicknames.push(nicknameStore.nicknames.get(destQuiddityId))

    if (contact instanceof Contact) {
      nicknames.push(contact.sipUser)
    } else if (typeof contact === 'string') {
      nicknames.push(contact)
    }

    return nicknames.join('_')
  }

  /**
   * Get the nickname of the connection from the nicknames of the destination, the source and the contact
   * @returns {string} An connection nickname
   */
  get nickname () {
    return Connection.getNickname(
      this.sourceId,
      this.destinationId,
      this.contactId
    )
  }

  get branch () {
    return `.connections.${this.id}`
  }

  /** @todo Transform type to mediaType */
  get mediaType () {
    return this.type
  }

  /**
   * Checks if a connection is concurrent with itself
   * @param {models.Connection} connection - The concurrent connection
   * @returns {boolean} Returns true if the connection is concurrent
   */
  isConcurrent (connection) {
    return this.sourceId !== connection.sourceId &&
           this.destinationId === connection.destinationId &&
           this.type === connection.type
  }

  /**
   * Checks if a source, a destination and a shmdata matches with itself
   * @param {models.Quiddity} source - A source quiddity
   * @param {models.Quiddity} destination - A destination quiddity
   * @param {models.Shmdata} shmdata - A shmdata
   * @returns {boolean} Returns true if the arguments are contained in self
   */
  match (source, destination, shmdata) {
    return this.sourceId === source.id &&
           this.destinationId === destination.id &&
           this.type === shmdata.mediaType
  }

  activate (sfId, swId) {
    this.sfId = sfId
    this.swId = swId
  }

  get isActive () {
    return !!this.sfId
  }

  deactivate () {
    this.sfId = null
  }

  /**
   * Parses JSON data to a Connection model
   * @static
   * @param {JSON} json - JSON data to parse
   * @returns {Scene}
   */
  static fromJSON (json) {
    const ajv = new Ajv()
    const isValid = ajv.validate(connectionSchema, json)

    if (isValid) {
      return new Connection(
        json.source,
        json.destination,
        json.type,
        json.sfId,
        json.contact,
        json.rank,
        json.id
      )
    } else {
      throw new TypeError(ajv.errorsText())
    }
  }

  /**
   * Extracts all connection from a JSON user tree
   * @static
   * @param {Object} json - A quiddity user tree
   * @returns {models.Connection[]} Returns all connections
   */
  static fromUserTree (json) {
    const connections = []

    if (json.connections) {
      Object.values(json.connections)
        .forEach(obj => connections.push(Connection.fromJSON(obj)))
    }

    return connections
  }

  /**
   * Extracts all possible connections from matrix entries
   * @static
   * @param {models.MatrixEntry} sourceEntry - A source MatrixEntry
   * @param {models.MatrixEntry} destinationEntry - A destination MatrixEntry
   * @param {models.Shmdata} [compatibleShmdata=null] - A compatible shmdata model
   * @returns {?models.Connection} Returns a connection model
   */
  static fromMatrixEntries (sourceEntry, destinationEntry, compatibleShmdata = null) {
    const srcId = sourceEntry.quiddity.id

    const destId = destinationEntry.quiddity.id
    const contactId = destinationEntry.contact?.sipUser
    // TODO : create helpers to get these now that we bypass the shmdata store and go directly in the quiddities
    const compatibleMediaType = compatibleShmdata?.capabilities.mediaType || sourceEntry.defaultMediaType
    const readers = destinationEntry.quiddity.infoTree?.shmdata

    let sfid = null
    // See the TODO above
    if (compatibleShmdata && readers && ShmdataRoleEnum.READER in readers && readers[ShmdataRoleEnum.READER] && compatibleShmdata.path in readers[ShmdataRoleEnum.READER]) {
      sfid = readers[ShmdataRoleEnum.READER][compatibleShmdata.path]?.sfid
    }
    let connection
    try {
      connection = new Connection(srcId, destId, compatibleMediaType, sfid, contactId)
    } catch {
      connection = null
    }
    return connection
  }

  /**
   * Transforms the current model to JSON
   * @returns {JSON}
   */
  toJSON () {
    const { sfId, id, type, sourceId, destinationId, contactId, rank } = this
    const json = {
      id,
      source: sourceId,
      destination: destinationId,
      sfId: sfId,
      type: type
    }

    if (contactId) json.contact = contactId
    if (rank > 0) json.rank = rank

    return json
  }
}

export default Connection

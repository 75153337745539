import pino from 'pino'

/**
 * Gets the level of the logger according to the app context
 * The checked contexts are (in order):
 * - URL parameter (url?level=debug)
 * - `NODE_ENV` variable (default values are configured by jest or by webpack configuration)
 * @memberof module:utils/logger
 * @see [jest configuration of environment variables]{@link https://jestjs.io/docs/environment-variables}
 * @returns {external:pino/level} The logger level
 */
function getLoggerLevel () {
  const currentURL = new URL(document.location)
  const urlLevel = currentURL.searchParams?.get('level')

  let level = 'info'

  // mute the log level during tests
  if (process.env.NODE_ENV === 'quiet') {
    level = 'silent'
  }

  if (urlLevel) {
    level = urlLevel
  }

  return level
}

/**
 * Gets the store name from a log event
 * @param {external:pino/logBindings} logBindings - All bindings transmitted by a child logger
 * @returns {?string} The store emitter
 */
function getLogStore (logBindings) {
  let storeName

  if (Array.isArray(logBindings) && logBindings.length > 0) {
    storeName = logBindings[0]?.store
  }

  return storeName
}

/**
 * Attached notification store to the logger
 * @memberof module:utils/logger
 * @member {module:stores/common.NotificationStore} notificationStore
 */
let notificationStore = null

/**
 * Attached socket store to the logger
 * @memberof module:utils/logger
 * @member {module:stores/common.SocketStore} socketStore
 */
let socketStore = null

/**
 * Binds the notification store and the socketStore to the logger
 * @memberof module:utils/logger
 * @param {Object} [stores=null] - The stores that should be bound to the logger
 * @param {string} stores.notificationStore - The notification store
 * @param {string} stores.socketStore - The socket store
*/
const bindStores = stores => {
  notificationStore = stores.notificationStore
  socketStore = stores.socketStore
}

/**
 * Global getter for the notification store
 * @returns {?module:stores/common.NotificationStore} The global notification store of Scenic
 */
const useNotificationStore = () => {
  return notificationStore
}

/**
 * Sends a log message to the notification store
 * @param {external:pino/level} logLevel - Level of the logging
 * @param {external:pino/logEvent} logEvent - Transmitted log event
 */
const applyNotificationLog = (logLevel, logEvent) => {
  if (notificationStore) {
    notificationStore.applyNotificationLog(logLevel, logEvent)
  }
}

/**
 * Cleans the logEvent object to be sent via the active socket and adds the sessionId property
 * {external:pino/logEvent} logEvent - Transmitted log event
 * @returns {object} cleanedLogEvent - The cleaned log object
 */
const cleanLogEvent = (logEvent) => {
  const cleanedLogEvent = {
    ...logEvent,
    messages: [],
    sessionId: socketStore.sessionId
  }
  // v0 - only transport the msg property, the error property, and the quiddity name (if any) from the object in messages array
  for (const logObject of logEvent.messages) {
    const cleanedObj = {
      msg: logObject.msg
    }
    if (logObject.quiddity) {
      cleanedObj.quiddity = logObject.quiddity
    }
    if (logObject.error?.message) {
      cleanedObj.errorMsg = logObject.error.message
    }

    cleanedLogEvent.messages.push(cleanedObj)
  }
  return cleanedLogEvent
}

/**
 * Sends all clean logs through the active socket
 * @param {external:pino/logEvent} logEvent - Transmitted log event
 */
const applySocketLog = (logEvent) => {
  if (socketStore && socketStore.hasActiveSocket) {
    const cleanedLogEvent = loggerFunctions.cleanLogEvent(logEvent)

    socketStore.APIs.switcherAPI.sendLog(
      cleanedLogEvent.level?.label || 'info',
      cleanedLogEvent.messages[0]?.msg || 'missing log message'
    )
  }
}

/**
 * Creates a singleton logger for the webapp
 * @memberof module:utils/logger
 * @member {external:pino/logger} logger
 */
const logger = pino({
  level: getLoggerLevel(),
  browser: {
    asObject: true,
    transmit: {
      level: 'info',
      send: (logLevel, logEvent) => {
        applyNotificationLog(logLevel, logEvent)
        applySocketLog(logEvent)
      }
    }
  }
})

/**
 * Allows mocking of a function called by another function in the same module
 * @type {Object.<string, function>}
 * @see [How to mock specific module function in jest]{@link https://medium.com/@qjli/how-to-mock-specific-module-function-in-jest-715e39a391f4}
 */
const loggerFunctions = {
  applySocketLog,
  cleanLogEvent,
  getLogStore,
  bindStores,
  useNotificationStore,
  applyNotificationLog
}

export { logger, bindStores, useNotificationStore, applyNotificationLog, getLogStore, applySocketLog, cleanLogEvent }
export default loggerFunctions

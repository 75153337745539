/**
 * Convert a decimal value to a lower metric in the SI
 * @param {number} number - The number value to convert
 * @param {number} exponent - The base 10 exponent of the conversion
 * @returns {number} The converted value
 */
export function dropDecimalUnit (number, exponent) {
  return number / Math.pow(10, exponent)
}

/**
 * Convert a decimal value to a greater metric in the SI
 * @param {number} number - The number value to convert
 * @param {number} exponent - The base 10 exponent of the conversion
 * @returns {number} The converted value
 */
export function raiseDecimalUnit (number, exponent) {
  return number * Math.pow(10, exponent)
}

/**
 * Constrains a number to a specific maximum value.
 * @param {number} number - The number to clamp
 * @param {number} threshold - Maximum possible value for the number
 * @returns {number} The new clamped number
 */
export function clamp (number, threshold) {
  return Math.abs(number) > Math.abs(threshold) ? threshold : number
}

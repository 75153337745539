/**
 * Enum for media types
 * @readonly
 * @memberof module:models/shmdata
 * @enum {string}
 * @see [List of media types]{@link https://gstreamer.freedesktop.org/documentation/plugin-development/advanced/media-types.html?gi-language=c#list-of-defined-types}
 */
const MediaTypeEnum = Object.freeze({
  AUDIO_RAW: 'audio/x-raw',
  VIDEO_RAW: 'video/x-raw',
  VIDEO_H264: 'video/x-h264'
})

/**
 * Checks if a media type is a video
 * @memberof module:models/shmdata.MediaTypeEnum
 * @param {module} mediaType - A media type
 * @returns {boolean} Returns true if the media type represents a video
 */
function isVideo (mediaType = '') {
  return mediaType.startsWith('video')
}

/**
 * Checks if a media type is an encoded video
 * @memberof module:models/shmdata.MediaTypeEnum
 * @param {module} mediaType - A media type
 * @returns {boolean} Returns true if the media type represents an encoded video
 */
function isVideoEncoded (mediaType = '') {
  return isVideo(mediaType) && mediaType !== MediaTypeEnum.VIDEO_RAW
}

/** Gets the icon type for the shmdata
 * @memberof module:models/shmdata.MediaTypeEnum
 * @param {string} mediaType - A media type
 * @returns {string} Returns the icon type of the shmdata
 */
const getShmdataIconType = (mediaType) => {
  let type

  switch (mediaType) {
    case MediaTypeEnum.VIDEO_RAW:
    case MediaTypeEnum.VIDEO_H264:
      type = 'video'
      break

    case MediaTypeEnum.AUDIO_RAW:
      type = 'audio'
      break

    default:
      type = 'data'
  }

  return type
}

export default MediaTypeEnum

export {
  isVideo,
  isVideoEncoded,
  getShmdataIconType
}

import Store from '@stores/Store'
import SettingStore from '@stores/common/SettingStore'
import CapsStore from '@stores/shmdata/CapsStore'

import { RequirementError } from '@utils/errors'
import { NDI_INPUT_KIND_ID, NDI_OUTPUT_KIND_ID } from '@models/quiddity/specialQuiddities'

import { logger } from '@utils/logger'

/**
 * @constant {external:pino/logger} LOG - Dedicated logger for the EncoderStore
 * @memberof module:stores/quiddity.EncoderStore
 */
export const LOG = logger.child({ store: 'EncoderStore' })

/**
 * @const List of all destinations that are compatible with video SIP sources
 * @todo Find another way to handle these use cases instead of hardcoding this stuff
 */
export const VIDEO_SIP_SOURCE_COMPATIBILITIES = [
  NDI_OUTPUT_KIND_ID,
  'videoOutput',
  'videoOutput2000',
  'nvencEncoder',
  'h264Encoder',
  'glfwin',
  'videnc',
  'videoEncoder'
]

/**
 * @classdesc Stores all managed encoders
 * @extends module:stores/common.Store
 * @memberof module:stores/quiddity
 */
class EncoderStore extends Store {
  /**
   * Instantiates a new EncoderStore
   * @param {stores.SocketStore} socketStore - Stores and manages the current event-driven socket
   * @param {stores.SettingStore} settingStore - Stores app's settings
   * @param {stores.CapsStore} CapsStore - Stores quiddities' capabilities
   * @constructor
   */
  constructor (socketStore, settingStore, capsStore) {
    super(socketStore)

    if (settingStore instanceof SettingStore) {
      this.settingStore = settingStore
    } else {
      throw new RequirementError(this.constructor.name, 'SettingStore')
    }

    if (capsStore instanceof CapsStore) {
      this.capsStore = capsStore
    } else {
      throw new RequirementError(this.constructor.name, 'CapsStore')
    }
  }

  /**
   * Checks if the encoder, if any, should be available.
   * @param {module:models/quiddity.Quiddity} quiddity - The quiddity model
   * @returns {boolean} - Flags true if the encoder should be displayed
   */
  isEncoderDisplayed (quiddity) {
    // For ndi sources that can have multiples non-encoded shmdatas, we need to allow display these sources at all times.
    return this.settingStore.displayEncoders || quiddity.kindId === NDI_INPUT_KIND_ID
  }

  /**
   * Checks if a connection box should be disabled
   * @param {module:models/matrix.MatrixEntry} sourceEntry - The row entry of the matrix
   * @param {module:models/matrix.MatrixEntry} destinationEntry - The column entry of the matrix
   * @returns {boolean} Returns false if the caps are incompatible
   */
  areEntriesConnectable (sourceEntry, destinationEntry) {
    let areConnectable = false

    const { kindId } = destinationEntry
    const srcId = sourceEntry.quiddityId
    const dstId = destinationEntry.quiddityId

    if (sourceEntry.isEncodableVideoSource) {
      for (const shmdata of sourceEntry.shmdatas) {
        if (sourceEntry.isSipSource) {
          areConnectable = !!VIDEO_SIP_SOURCE_COMPATIBILITIES.includes(kindId)
        } else if (this.capsStore.areConnectable(srcId, dstId, shmdata.mediaType)) {
          areConnectable = true
        }
      }
    } else {
      for (const shmdata of sourceEntry.shmdatas) {
        // TODO : Use already existing helpers for this and possibly adapt them.
        areConnectable = sourceEntry.hasShmdata && this.capsStore.areConnectable(srcId, dstId, shmdata.caps.split(',')[0])
      }
    }

    return areConnectable
  }
}

export default EncoderStore
